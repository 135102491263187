<template>
  <v-card class="ma-3">
    <v-card-title class="text-h5"> Create an Informal Inquiry </v-card-title>
    <v-divider />
    <v-card flat tile>
      <v-card-title> Agents </v-card-title>
      <v-card-text>
        <v-row class="ma-0" dense>
          <v-col cols="12">
            <agent-search
              v-model="informal.agent"
              better-return
              label="Agent"
              data-testid="informal-agent"
              :clearable="false"
              :success="agentValidation.success"
              :error-messages="agentValidation.errorMessages"
            />

            <v-row
              v-for="(agent, agentUuid) in informal.additionalAgents"
              :key="agentUuid"
              dense
              align="center"
              class="ma-0 mb-6"
            >
              <agent-search
                v-model="informal.additionalAgents[agentUuid]"
                better-return
                success
                label="Agent"
                :data-testid="`informal-agent-${agent.name}`"
                :clearable="false"
                hide-details
              />
              <app-button
                variant="flat"
                :data-testid="`informal-agent-${agent.name}-delete`"
                class="text-none"
                :icon="mdiDelete"
                color="error"
                @click="deleteAgent(agentUuid)"
              />
            </v-row>

            <v-row
              v-if="informal.agent?.id"
              class="ma-0"
              style="gap: 9px"
              dense
            >
              <agent-search
                :key="agentKey"
                better-return
                label="Additional Agent"
                data-testid="informal-new-agent"
                @update:model-value="addAgent"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card flat tile>
      <v-card-title>Contract Party</v-card-title>
      <v-card-text>
        <v-row class="ma-0" dense>
          <v-col cols="12" md="6">
            <contract-party-search
              v-model="informal.party"
              hint=" "
              persistent-hint
              data-testid="informal-party"
              :success="partyValidation.success"
              :error-messages="partyValidation.errorMessages"
            >
              <template #message>
                <a
                  class="text-none"
                  data-testid="informal-create-party"
                  @click="createContractParty"
                >
                  Can't find your contract party?
                </a>
              </template>
            </contract-party-search>
          </v-col>
          <v-col cols="12" md="6">
            <select-field
              v-model="informal.status"
              :prepend-inner-icon="mdiListStatus"
              placeholder="Select Status"
              label="Status"
              data-testid="informal-status"
              :items="INFORMAL_STATUSES"
              :success="statusValidation.success"
              :error-messages="statusValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <currency-input
              v-model="informal.faceAmount"
              :prepend-inner-icon="mdiCurrencyUsd"
              label="Death Benefit"
              data-testid="informal-face-amount"
              :success="faceAmountValidation.success"
              :error-messages="faceAmountValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <autocomplete-field
              v-model="informal.state"
              :prepend-inner-icon="mdiSignRealEstate"
              placeholder="Select State"
              label="State"
              data-testid="informal-state"
              :items="STATES"
              :success="stateValidation.success"
              :error-messages="stateValidation.errorMessages"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card flat tile>
      <v-card-title>
        <v-row dense align="center" class="ma-0">
          Carriers
          <v-spacer />
          <app-button
            class="text-none"
            color="accent"
            data-testid="informal-select-qna-carriers"
            :prepend-icon="quoteAndApplyIcon"
            text="Enable All Quote & Apply Carriers"
            @click="selectAllQuoteAndApplyCarriers"
          />
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-chip-group
          v-if="carriers.length"
          v-model="informal.carriers"
          multiple
          column
          selected-class="bg-primary"
        >
          <v-chip
            v-for="({ name, id }, index) in carriers"
            :key="`item-${index}`"
            :value="id"
            :text="name"
            :data-testid="`carrier-${name}`"
          />
        </v-chip-group>
        <template v-else>
          <p style="text-align: center">Loading Carriers</p>
          <v-progress-linear indeterminate />
        </template>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card-actions>
      <app-button
        color="primary"
        class="text-none"
        data-testid="informal-create"
        :loading="loading"
        :prepend-icon="mdiPlus"
        text="Create Informal"
        @click="save"
      />
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ContractPartySearch from "@/components/shared/ContractPartySearch.vue";
import NewPartyDialog from "@/components/cases/case-dialogs/NewPartyDialog.vue";

import { STATES } from "@/data/states";
import {
  computedValidation,
  getRouterLink,
  parseErrorMessage,
  uuidv4
} from "@/util/helpers";
import { getCarriers } from "@/api/carrier.service";
import { createCase } from "@/api/cases.service";
import {
  InformalInquiry,
  InformalInquiryToCreateRequest,
  INFORMAL_STATUSES
} from "@/factories/InformalInquiry";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
import { markRaw, ref } from "vue";

import {
  mdiDelete,
  mdiListStatus,
  mdiCurrencyUsd,
  mdiSignRealEstate,
  mdiPlus
} from "@mdi/js";

import useVuelidate from "@vuelidate/core";
import { useRouter } from "vue-router";
import { getHttpClient } from "@/http-client"
import { useQuoteAndApplyIcon } from "@/composables/quote-and-apply-icon.composable";

useHead({ title: "Create Informal Case" });

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();

const quoteAndApplyIcon = useQuoteAndApplyIcon();

const informal = ref(InformalInquiry());
const carriers = ref([]);
const loading = ref(false);
const agentKey = ref(null);

const v$ = useVuelidate(
  {
    informal: {
      agent: {
        required: Boolean
      },
      status: {
        required: Boolean
      },
      faceAmount: {
        required: Boolean
      },
      state: {
        required: Boolean
      },
      party: {
        required: Boolean
      }
    }
  },
  { informal },
  { $autoDirty: true, $scope: null }
);

const agentValidation = computedValidation(v$.value.informal.agent, {
  required: "Required"
});
const statusValidation = computedValidation(v$.value.informal.status, {
  required: "Required"
});
const faceAmountValidation = computedValidation(v$.value.informal.faceAmount, {
  required: "Required"
});
const stateValidation = computedValidation(v$.value.informal.state, {
  required: "Required"
});
const partyValidation = computedValidation(v$.value.informal.party, {
  required: "Required"
});

function selectAllQuoteAndApplyCarriers() {
  informal.value.carriers.splice(0, informal.value.carriers.length);
  carriers.value.forEach(c => {
    if (c.strifeApproved) informal.value.carriers.push(c.id);
  });
}

let carrierCancelToken = null;
async function fetchCarriers() {
  try {
    const params = new URLSearchParams();
    params.append("informal", "true");
    if (carrierCancelToken !== null) {
      carrierCancelToken.cancel("Operation canceled due to new request.");
    }
    carrierCancelToken = getHttpClient().CancelToken.source();
    const allCarriers = await getCarriers(params, carrierCancelToken);
    carriers.value.push(
      ...allCarriers.map(({ name, id, availableInQuoteAndApply }) => ({
        name,
        id,
        strifeApproved: availableInQuoteAndApply
      }))
    );
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    loading.value = true;
    const id = await createCase(InformalInquiryToCreateRequest(informal.value));
    router.push(getRouterLink("Case", id));
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      duration: 5000
    });
  } finally {
    loading.value = false;
  }
}

function deleteAgent(uuid) {
  delete informal.value.additionalAgents[uuid];
}

function addAgent(value) {
  if (!value) return;
  informal.value.additionalAgents[uuidv4()] = value;
  agentKey.value = uuidv4();
}

async function createContractParty() {
  const result = await dialog.showDialog({
    component: markRaw(NewPartyDialog),
    hideRole: true,
    hideNetWorth: true,
    line: "life",
    scrollable: true,
    onlyType: "Individual"
  });

  if (!result.party) return;
  informal.value.party = result.party;
}

fetchCarriers();
</script>
