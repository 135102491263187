<template>
  <div>
    <app-button
      v-if="availablePdfFieldTypes.length === 1"
      :icon="mdiPlusCircle"
      class="ml-1 default-btn"
      variant="text"
      density="comfortable"
      data-testid="create-application-question-link"
      :loading="creatingField"
      @click="addField(availablePdfFieldTypes[0].value)"
    />
    <v-menu v-else>
      <template #activator="{ props: templateProps }">
        <app-button
          :icon="mdiPlusCircle"
          class="ml-1 default-btn"
          variant="text"
          density="comfortable"
          v-bind="templateProps"
          data-testid="create-application-question-link"
          :loading="creatingField"
        />
      </template>
      <v-card>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="({ title, value }, index) in availablePdfFieldTypes"
              :key="index"
              @click="addField(value)"
            >
              {{ title }}
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <template v-if="canDuplicate">
      <app-button
        v-if="isNotOnPdfType"
        :icon="mdiContentDuplicate"
        variant="text"
        density="comfortable"
        class="ml-1 default-btn"
        data-testid="duplicate-application-question-link"
        :loading="creatingField"
        @click="duplicateField()"
      />
      <v-menu
        v-else
        v-model="dupeMenu"
        :close-on-content-click="false"
        width="200"
      >
        <template #activator="{ props: templateProps }">
          <app-button
            :icon="mdiContentDuplicate"
            class="ml-1 default-btn"
            variant="text"
            density="comfortable"
            data-testid="duplicate-application-question-link"
            v-bind="templateProps"
            :loading="creatingField"
          />
        </template>
        <v-card>
          <v-card-text>
            <text-field
              v-model="dupeOffsetY"
              label="Up/Down Offset"
              hide-details
              class="ma-2 no-tick"
              type="text"
              inputmode="numeric"
              @click.prevent
            />
            <text-field
              v-model="dupeOffsetX"
              label="Left/Right Offset"
              hide-details
              class="ma-2 no-tick"
              type="text"
              inputmode="numeric"
              @click.prevent
            />
            <v-list>
              <v-list-item @click="duplicateField('down')"> Down </v-list-item>
              <v-list-item @click="duplicateField('right')">
                Right
              </v-list-item>
              <v-list-item @click="duplicateField('up')"> Up </v-list-item>
              <v-list-item @click="duplicateField('left')"> Left </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>

    <app-button
      v-if="!isChild"
      :icon="mdiDelete"
      color="error"
      class="default-btn opacity-100"
      variant="text"
      density="comfortable"
      data-testid="delete-application-question-link"
      @click="deleteCurrentField"
    />
  </div>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import {
  NOT_ON_PDF_OPTION_TYPE,
  RADIO_OPTION_TYPE,
  CHECKBOX_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  pdfFieldTypes,
  ApplicationQuestion,
  ApplicationQuestionLink
} from "@/factories/FormMapping";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import { mdiPlusCircle, mdiContentDuplicate, mdiDelete } from "@mdi/js";
import { computed, ref, markRaw } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { parseErrorMessage } from "@/util/helpers";
const props = defineProps({
  formId: {
    type: [String, Number],
    required: true
  },
  isChild: Boolean
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const dupeMenu = ref(false);
const formStore = useMappedFormStore(props.formId);

const {
  isContracting,
  dupeOffsetY,
  dupeOffsetX,
  isEappSubmission,
  activeField
} = storeToRefs(formStore);

const creatingField = ref(false);
const canDuplicate = computed(() => !isEappSubmission.value && !props.isChild);

const availablePdfFieldTypes = computed(() => {
  if (isEappSubmission.value) {
    return pdfFieldTypes.filter(
      ({ value }) => value === NOT_ON_PDF_OPTION_TYPE
    );
  } else if (isContracting.value) {
    return pdfFieldTypes.filter(value =>
      [
        RADIO_OPTION_TYPE,
        CHECKBOX_OPTION_TYPE,
        TEXT_OPTION_TYPE,
        NOT_ON_PDF_OPTION_TYPE
      ].includes(value.value)
    );
  }
  return pdfFieldTypes;
});

const isNotOnPdfType = computed(
  () => activeField.value.pdfFieldType === NOT_ON_PDF_OPTION_TYPE
);

function addField(fieldType) {
  return props.isChild ? addChild(fieldType) : addStandardField(fieldType);
}

async function addStandardField(fieldType) {
  creatingField.value = true;
  try {
    const res = await formStore.addPdfField(fieldType);
    await formStore.changeActiveField(res);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingField.value = false;
  }
}
async function addChild(fieldType) {
  creatingField.value = true;
  try {
    const fieldId = await formStore.insertChild(
      activeField.value.id,
      fieldType
    );
    await formStore.changeActiveField(fieldId);
    activeField.value.applicationQuestion = ApplicationQuestion({
      id: 87,
      name: "Verbatim Question",
      required_of: null,
      verbatim_question: true
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingField.value = false;
  }
}

async function duplicateField(direction = null) {
  dupeMenu.value = false;
  creatingField.value = true;
  try {
    const currentActiveField = ApplicationQuestionLink(activeField.value);
    const res = await formStore.addPdfField(activeField.value.pdfFieldType);
    await formStore.changeActiveField(res);
    formStore.copyToActiveField(currentActiveField, direction);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingField.value = false;
  }
}

async function deleteCurrentField() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Question",
    subtitle: "Please confirm that you'd like to delete this question",
    func: () => formStore.deleteApplicationQuestionLink(activeField.value.id)
  });
}
</script>
