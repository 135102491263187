<template>
  <v-row dense class="ma-0">
    <v-col v-if="!props.onlyType" cols="12" class="pb-0">
      <select-field
        v-model="partyType"
        :prepend-inner-icon="mdiAccount"
        label="Type"
        data-testid="new-party-type"
        :success="Boolean(partyType)"
        :items="PARTY_TYPES"
      />
    </v-col>
    <v-slide-x-transition mode="out-in">
      <new-entity
        v-if="isEntity"
        key="entity"
        ref="entityRef"
        :line="props.line"
        :case-id="props.caseId"
        :hide-role="hideRole"
      />
      <new-individual
        v-else-if="isIndividual"
        key="indiv"
        ref="individualRef"
        :line="props.line"
        :case-id="props.caseId"
        :insured-address="props.insuredAddress"
        :hide-role="hideRole"
      />
    </v-slide-x-transition>
  </v-row>
</template>
<script setup>
import NewIndividual from "@/components/cases/case-dialogs/NewIndividual.vue";
import NewEntity from "@/components/cases/case-dialogs/NewEntity.vue";
import { computed,  ref } from "vue";
import { mdiAccount } from "@mdi/js";
const props = defineProps({
  insuredAddress: {
    type: Object,
    required: false,
    default: () => null
  },
  caseId: { type: Number, required: false, default: 0 },
  hideRole: Boolean,
  line: { type: String, required: true },
  onlyType: {
    type: String,
    required: false,
    default: null,
    validates: v => ["Individual", "Entity", null].includes(v)
  }
});
const PARTY_TYPES = ["Individual", "Entity"];
const partyType = ref(props.onlyType);
const isEntity = computed(() => partyType.value === "Entity");
const isIndividual = computed(() => partyType.value === "Individual");

const entityRef = ref(null); //templateref
const individualRef = ref(null); //templateref

function create() {
  if (isEntity.value) {
    return entityRef.value.create();
  } else if (isIndividual.value) {
    return individualRef.value.create();
  }
  return null;
}

defineExpose({ create });
</script>
