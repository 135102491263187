<template>
  <div>
    <v-row align="start" class="ma-0">
      <v-col class="pa-0">
        <v-card variant="flat" class="pa-3 border-thin">
          <v-list-item-title data-testid="message-title">
            <v-row class="ma-0" align="center">
              <div style="height: 36px; width: 36px">
                <v-img
                  data-testid="avatar-url"
                  class="rounded-circle"
                  cover
                  :data-src="message.ownable.avatarUrl"
                  :src="message.ownable.avatarUrl"
                />
              </div>
              <div class="mx-2 darker-color">
                {{ message.ownable.name }}
              </div>
              <div
                v-if="message.inbound && message.inbound.via"
                class="text-caption text-grey font-weight-medium"
              >
                via {{ message.inbound.via }}
              </div>
              <timestamp-formatter
                class="text-caption text-grey font-weight-medium"
                format="date-time"
                :model-value="message.created_at || message.createdAt"
              />
              <chat-message-email-indicator
                v-if="message.email || message.carrierEmail"
                :full-email="message.fullEmail"
                :message-id="message.id"
              />
              <template v-if="deletable">
                <v-spacer />
                <app-button
                  size="small"
                  density="comfortable"
                  :icon="mdiDelete"
                  color="error"
                  variant="text"
                  data-testid="delete-message"
                  @click="deleteMessage"
                />
              </template>
            </v-row>
          </v-list-item-title>
          <div class="mx-n3 my-3">
            <v-divider />
          </div>
          <v-list-item-subtitle
            v-if="message.safe_html"
            data-testid="message-text"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div
              class="wrap-text opacity-100 pb-1 darker-color better-line-height"
              style="font-size: 1rem"
              v-html="sanitize(message.note)"
            ></div>
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-else
            class="wrap-text opacity-100 pb-1 darker-color better-line-height"
            data-testid="message-text"
            style="font-size: 1rem"
          >
            <include-links-in-text :text="message.note" full-opacity />
          </v-list-item-subtitle>
          <v-list-item-subtitle class="opacity-100" style="font-size: 1rem">
            <a
              v-for="(document, index) in message.documents"
              :key="document.uid"
              class="mr-1"
              :data-testid="`message-document-${index}`"
              v-bind="download(document.uid)"
            >
              {{ document.name }}
              <span class="text-grey">({{ document.fileSize }})</span>
              <v-icon color="primary" :icon="mdiDownload" />
            </a>
          </v-list-item-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import IncludeLinksInText from "@/components/shared/IncludeLinksInText.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import ChatMessageEmailIndicator from "@/components/shared/chat/ChatMessageEmailIndicator.vue";
import { downloadFileAsLink } from "@/util/helpers";
import { sanitize } from "@/html-sanitizer";
import { getDocumentUrl } from "@/api/documents.service";

import { mdiDelete, mdiDownload } from "@mdi/js";
import { useUserStore } from "@/stores/user";
import { useDisplay } from "vuetify/lib/framework.mjs";

const emit = defineEmits(["delete-message"]);
const props = defineProps({
  message: {
    type: Object,
    required: true
  }
});
const user = useUserStore();
const { mdAndDown } = useDisplay();

function download(uid) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}

function deleteMessage() {
  emit("delete-message", props.message);
}

function isDeletable() {
  if (!props.message.id) return false;
  const sameId = props.message.ownable.id === user.loginable.id;
  const sameType = props.message.ownable.type === user.loginable.type;
  if (sameId && sameType) return true;
  return user.isGroupTwoPlus;
}

const deletable = isDeletable();
</script>

<style lang="scss">
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: unset;
}
</style>
