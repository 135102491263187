<template>
  <v-breadcrumbs
    v-if="crumbs.length > 1 && !fullscreen"
    divider="/"
    :items="crumbs"
    :class="{ 'pa-0': currentMeta?.useBreadcrumbSlot }"
    class="px-1 pb-1 pt-3 d-print-none"
  >
    <template #item="{ item }">
      <v-breadcrumbs-item
        exact
        :to="item.to"
        :class="{ 'text-grey': !item.to }"
        data-testid="breadcrumb-item"
      >
        <v-progress-circular
          v-if="item.text === 'loading'"
          indeterminate
          size="12"
          width="2"
        />
        <span v-else style="font-size: 14px">
          {{ item.text }}
        </span>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useUserStore } from "@/stores/user";

const { breadcrumb, fullscreen } = storeToRefs(useInstanceStore());

const router = useRouter();

const currentMeta = computed(() => {
  if (!router) return null;
  return router.currentRoute.value.meta;
});

const crumbs = computed(() => {
  let breadcrumbs = currentMeta.value?.breadcrumbs;
  if (!currentMeta.value || !breadcrumbs) return [];

  let text = currentMeta.value.breadcrumb;
  if (currentMeta.value.useStateVal) text = breadcrumb.value || "loading";

  if (!text && currentMeta.value.useStateVal !== true) return [];

  let resolvedBreadcrumbs = breadcrumbs;
  if (typeof breadcrumbs === "function") {
    resolvedBreadcrumbs = breadcrumbs(useUserStore());
  }
  return [...resolvedBreadcrumbs, { text }];
});
</script>
