<template>
  <v-card>
    <v-card-title> {{ title }} </v-card-title>
    <v-card-subtitle>
      Vendors are currently utilized when creating new requirements,
      specifically exams and physician statements.
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <text-field
            v-model="vendor.name"
            data-testid="vendor-name"
            data-lpignore="true"
            outlined
            dense
            hide-details="auto"
            label="Name"
            :prepend-inner-icon="mdiDomain"
            :success="nameValidation.success"
            :error-messages="nameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <basic-email-input
            v-model="vendor.email"
            data-testid="vendor-email"
            data-lpignore="true"
            outlined
            dense
            hide-details="auto"
            label="E-Mail"
            :prepend-inner-icon="mdiEmail"
            :success="Boolean(emailValidation.success && vendor.email)"
            :error-messages="emailValidation.errorMessages"
            @valid="validEmail = $event"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <div class="checkbox-width">
            <checkbox-field
              v-model="vendor.default"
              data-testid="vendor-default"
              label="Default"
              hide-details
              :success="Boolean(vendor.default)"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="checkbox-width">
            <checkbox-field
              v-model="vendor.available"
              data-testid="vendor-available"
              label="Available"
              hide-details
              :success="Boolean(vendor.available)"
            />
          </div>
        </v-col>
      </v-row>
      <div class="mx-n6 mt-3">
        <v-divider />
      </div>
      <v-row dense>
        <v-col cols="12">
          <h3 class="text-h6 pt-3">Case Task Requirements</h3>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="checkbox-width">
            <checkbox-field
              v-model="vendor.requireDoctorInfo"
              data-testid="require-doctor-info"
              label="Require Doctor Info"
              hide-details
              :success="Boolean(vendor.requireDoctorInfo)"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="checkbox-width">
            <checkbox-field
              v-model="vendor.requireOrderNumber"
              data-testid="require-order-number"
              label="Require Order Number"
              hide-details
              :success="Boolean(vendor.requireOrderNumber)"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="text"
        density="comfortable"
        text="Cancel"
        @click="dialog.closeDialog()"
      />
      <app-button
        class="text-none"
        color="primary"
        data-testid="vendor-save"
        :loading="saving"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { setRequestFromVendor, Vendor } from "@/factories/VendorFactory";
import { mdiDomain, mdiEmail } from "@mdi/js";
import { saveVendor } from "@/api/vendor.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation } from "@/util/helpers";

const props = defineProps({ modelValue: { type: Object, default: () => {} } });

const vendor = ref(Vendor(props.modelValue));
const saving = ref(false);
const validEmail = ref(vendor.value.email ? true : null);

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const v$ = useVuelidate(
  {
    vendor: {
      name: {
        required: Boolean
      },
      type: {
        required: Boolean
      },
      email: {
        optional: v => !v || validEmail.value
      }
    }
  },
  {
    vendor
  },
  {
    $autoDirty: true,
    $scope: false
  }
);

const title = `Edit ${vendor.value.name}`;

const nameValidation = computedValidation(v$.value.vendor.name, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.vendor.email, {
  optional: "An email must be valid if provided"
});

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  saving.value = true;

  try {
    await saveVendor(vendor.value.id, setRequestFromVendor(vendor.value));

    snackbar.showSuccessSnackbar({
      message: `Successfully updated ${vendor.value.name}`
    });
    dialog.closeDialog({ saved: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
