<template>
  <v-card>
    <v-card-title>Create New Article</v-card-title>
    <v-card-text>
      <text-field
        v-model="articleName"
        data-lpignore="true"
        :prepend-inner-icon="mdiFormatTitle"
        data-testid="article-name"
        label="Article Name"
        hide-details
        :success="Boolean(articleName)"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :disabled="creatingArticle"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        color="primary"
        class="text-none"
        data-testid="create-article"
        :loading="creatingArticle"
        @click="newArticle"
      >
        Create
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { mdiFormatTitle } from "@mdi/js";
import { createArticle } from "@/api/help-desk.service";
import { useRouter } from "vue-router";

import { parseErrorMessage } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";

import { ref } from "vue";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const articleName = ref("");
const creatingArticle = ref(false);
const router = useRouter();

async function newArticle() {
  if (!articleName.value) return;
  creatingArticle.value = true;
  try {
    const res = await createArticle(articleName.value);
    if (router) {
      router.push({
        name: "HelpCenterArticleEditor",
        params: { id: res.id }
      });
    }
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: "Could not create article:" + parseErrorMessage(e)
    });
  } finally {
    creatingArticle.value = false;
  }
}
</script>
