<template>
  <v-card>
    <v-card-title data-testid="create-review-title">
      Write a performance evaluation for {{ props.name }}
    </v-card-title>
    <v-card-text>
      <date-input
        v-model="review.reviewDate"
        data-testid="create-review-date"
        data-lpignore="true"
        label="Review Date"
        :prepend-inner-icon="mdiCalendar"
        :success="reviewDateValidation.success"
        :error-messages="reviewDateValidation.errorMessages"
      />
      <textarea-field
        v-model="review.review"
        data-testid="create-review-content"
        auto-grow
        label="Evaluation"
        :prepend-inner-icon="mdiText"
        :success="reviewValidation.success"
        :error-messages="reviewValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :disabled="creating"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        data-testid="create-review"
        color="primary"
        class="text-none"
        :loading="creating"
        @click="submit"
      >
        Submit
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import DateInput from "@/components/shared/DateInput.vue";
import { dateIsBeforeNow } from "@/util/vuelidateValidators";
import { createEmployeeReview } from "@/api/employees.service";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {  ref, computed } from "vue";
import { EmployeeReview } from "@/factories/Employee";
import { mdiCalendar, mdiText } from "@mdi/js";

import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  id: { type: [Number, String], required: true },
  name: { type: [String], required: true }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const review = ref(EmployeeReview());
const creating = ref(false);

const rules = {
  review: { required },
  reviewDate: { required, dateIsBeforeNow }
};

const v$ = useVuelidate(rules, review, { $autoDirty: true, $scope: false });

const reviewDateValidation = computed(() => {
  const model = v$.value.reviewDate;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "dateIsBeforeNow", message: "Cannot be in the future" }
  ]);
});

const reviewValidation = computed(() => {
  const model = v$.value.review;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

async function submit() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  creating.value = true;
  try {
    const newReview = await createEmployeeReview(props.id, review.value);
    dialog.closeDialog({ newReview });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creating.value = false;
  }
}
</script>
