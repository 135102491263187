<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    class="transparent-data-table"
    data-testid="advisor-profile-pay-periods-table"
    must-sort
    :mobile="null"
    hide-default-footer
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #[`item.id`]="{ item }">
      <router-link
        :to="{
          name: 'PayPeriodView',
          params: { id: item.id }
        }"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template #[`item.amount`]="{ item }">
      <currency-formatter :model-value="item.amount" :decimal-length="2" />
    </template>
    <template #[`item.date`]="{ item }">
      <timestamp-formatter
        v-if="item.date"
        format="date-time"
        :model-value="item.date"
      />
      <span v-else>Pending</span>
    </template>
    <template #bottom>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <app-button
          data-testid="advisor-profile-pay-periods-table-view-all"
          color="primary"
          class="text-none"
          variant="text"
          :prepend-icon="mdiOpenInApp"
          :text="`View ${name}'s Pay Periods`"
          @click="viewAll"
        />
      </v-row>
    </template>
  </v-data-table-server>
</template>

<script setup>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { useSnackbarStore } from "@/stores/snackbar";

import { getPayPeriods } from "@/api/pay-periods.service";
import { useTable } from "@/composables/table.composable";
import { parseErrorMessage } from "@/util/helpers";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "vue-router";
import { mdiOpenInApp } from "@mdi/js";

const router = useRouter();
const tableStore = useTableStore();
const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);

const table = useTable({
  getData: getPayPeriods,
  shouldIncludeCancelToken: true,
  options: TableOptions({ sortBy: [{ key: "id", order: "desc" }] }),
  headers: [
    new TableHeader({
      text: "Statement ID",
      value: "id",
      map: "id",
      sortFilterMap: "pay_periods.id",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Paid Date",
      value: "date",
      map: "paidDate",
      sortFilterMap: "pay_periods.paid_date",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Amount",
      value: "amount",
      map: "paidAmount",
      sortFilterMap: "paid_amount_at",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Payment Method",
      value: "paymentMethod",
      map: "payingTo"
    })
  ]
});

async function getData() {
  try {
    const additionalFilter = {
      payable_id: id.value,
      payable_type: type.value,
      view: "all"
    };
    await table.getData(additionalFilter);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function viewAll() {
  tableStore.payPeriodTable.filter = {
    paidTo: tableFilterData.value
  };
  router.push({
    name: "Commissions",
    query: { page: "pay-periods" }
  });
}
</script>
