<template>
  <v-row class="bg-section mx-0 py-3">
    <v-col cols="12">
      <v-card class="mb-0" flat>
        <v-card-title>Change Agent</v-card-title>
        <v-card-subtitle>
          {{ agentHint }}
        </v-card-subtitle>
        <v-card-text>
          <select-field
            v-model="owner"
            style="max-width: 35rem"
            success
            return-object
            item-title="name"
            label="Agent"
            data-testid="accesses-agent"
            :prepend-inner-icon="mdiAccountTie"
            :disabled="!allowCommissionChange"
            :items="agentOptions"
            @update:model-value="updateOwnerAttribute"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.owner.controller.value"
              />
            </template>
          </select-field>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <electronic-application-view-commission-assignment />
    </v-col>
    <v-col cols="12">
      <electronic-application-view-commission-splits />
    </v-col>
    <v-col cols="12">
      <v-card class="mt-0" flat>
        <v-card-title>Add or Remove Accesses</v-card-title>
        <v-card-text>
          <access-table
            v-model="electronicApplicationAccesses"
            v-model:new-access="newAccess"
            data-testid="eapp-access-table"
            :checkboxes="checkboxes"
            :delete-func="deleteAccess"
            :add-func="addAccess"
            :update-func="updateAccess"
          >
            <template #new-access>
              <advisor-search
                v-model="newAccess"
                hide-details
                data-testid="access-advisor-search"
              />
            </template>
          </access-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import ElectronicApplicationViewCommissionAssignment from "@/components/electronic-applications/ElectronicApplicationViewCommissionAssignment.vue";
import ElectronicApplicationViewCommissionSplits from "@/components/electronic-applications/ElectronicApplicationViewCommissionSplits.vue"
import AccessTable from "@/components/shared/AccessTable.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";

import { storeToRefs } from "pinia";

import { useEappViewStore } from "@/stores/eapp-view";
import { useActiveSave } from "@/composables/active-save.composable";
import { mdiAccountTie } from "@mdi/js";
import { computed, ref } from "vue";

const eapp = useEappViewStore();
const { allowCommissionChange, electronicApplicationAccesses, owner } =
  storeToRefs(eapp);

const savingBuffer = {
  owner: useActiveSave()
};

const newAccess = ref(null);
const checkboxes = [
  {
    text: "Email",
    value: "email"
  },
  {
    text: "Case Access",
    value: "additionalCaseAccess"
  }
];

const agentOptions = computed(() => {
  return electronicApplicationAccesses.value.filter(access =>
    access.ownerType?.toLowerCase()?.includes("agent")
  );
});

const agentHint = computed(() => {
  if (allowCommissionChange.value) {
    return "Click the dropdown to change the agent on this eApp. Add the agent as an access if they do not appear in the list.";
  }
  return 'You cannot change the agent because the DocuSign has been generated. Click "Edit eApp" to change the agent.';
});

function updateOwnerAttribute() {
  savingBuffer.owner.update(eapp.updateOwner);
}
function deleteAccess(access) {
  return eapp.deleteEappAccess(access.id);
}
function addAccess(advisor) {
  return eapp.createEappAccess(advisor);
}
function updateAccess(access, attribute) {
  return eapp.updateEappAccess(access.id, attribute);
}
</script>
