import { getHttpClient } from "@/http-client";
import { setPhysicianStatementFromRequest } from "@/factories/Task";

const baseUrl = "api/boss/physician_statements";
export async function getPhysicianStatement(caseId, id) {
  const params = new URLSearchParams();
  params.append("case_id", caseId);
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`, { params });
  return setPhysicianStatementFromRequest(data.physician_statement);
}

export async function updatePhysicianStatement(id, body) {
  const { data } = await getHttpClient().put(`${baseUrl}/${id}`, body);

  return setPhysicianStatementFromRequest(data);
}
export function deletePhysicianStatement(caseId, taskId) {
  const params = new URLSearchParams();
  params.append("case_id", caseId);
  return getHttpClient().delete(`${baseUrl}/${taskId}`, { params });
}
