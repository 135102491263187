<template>
  <v-card flat class="tasks-card">
    <v-card-title>
      <v-row class="ma-0" dense justify="space-between" align="center">
        <slot v-if="$slots.title" name="title" />
        <span>
          <app-button
            v-if="onClick"
            :icon="mdiPlus"
            variant="text"
            density="comfortable"
            class="text-none"
            color="accent"
            data-testid="create-task"
            @click="onClick"
          />
          <app-button
            v-if="mdAndUp"
            class="text-none"
            color="primary"
            icon
            variant="text"
            density="comfortable"
            @click="emit('toggle-expand')"
          >
            <v-icon
              size="18"
              :icon="expanded ? mdiArrowExpandLeft : mdiArrowExpandRight"
            />
          </app-button>
        </span>
      </v-row>
    </v-card-title>
    <v-card-text class="px-0">
      <v-list v-model:opened="open" class="larger-list">
        <v-list-group
          v-for="{ icon, text, tasks } in tasksGroupedByCategory"
          :key="text"
          active-color="primary"
          class="ma-2"
          :data-testid="`task-list-${text}`"
          :value="text"
        >
          <template #activator="{ props: groupProps }">
            <v-list-item v-bind="groupProps">
              <template #prepend>
                <v-icon :icon="icon" />
              </template>

              <v-list-item-title :data-testid="`task-header-${text}`">
                {{ text }}
                <span class="text-grey"> ({{ tasks.length }}) </span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-if="tasks.length">
            <component
              :is="simple ? SimpleTaskItem : TaskItem"
              v-for="(task, index) in tasks"
              :key="task.key"
              style="padding-inline-start: 16px !important"
              :item="task"
              :data-testid="`task-item-${index}`"
              :active="task.key === activeTask"
              @click="setActiveTask(task)"
              @delete-message="emit('delete-message', $event)"
            />
          </template>
          <v-list-item v-else style="padding-inline-start: 16px !important">
            <v-list-item-subtitle>No {{ type }}</v-list-item-subtitle>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SimpleTaskItem from "@/components/shared/tasks/SimpleTaskItem.vue";
import TaskItem from "@/components/shared/tasks/TaskItem.vue";
import { ref, watch, toRefs } from "vue";
import { mdiPlus, mdiArrowExpandLeft, mdiArrowExpandRight } from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const emit = defineEmits([
  "update:model-value",
  "toggle-expand",
  "delete-message",
  "same-task-clicked"
]);

const props = defineProps({
  expanded: Boolean,
  hideDates: Boolean,
  modelValue: { type: String, required: false, default: null },
  tasksGroupedByCategory: {
    type: Array,
    required: true,
    validator: v => Boolean(v?.length)
  },
  type: {
    type: String,
    required: false,
    default: "Tasks"
  },
  simple: Boolean,
  onClick: { type: Function, required: false, default: null }
});

const open = ref([]);
const defaultOpen = props.tasksGroupedByCategory.find(t => t.defaultOpen);
if (defaultOpen) open.value.push(defaultOpen.text);
const { mdAndUp } = useDisplay();

const {
  modelValue: valueProp,
  tasksGroupedByCategory,
  expanded
} = toRefs(props);
const activeTask = ref(props.modelValue);

watch(activeTask, (newV, oldV) => {
  if (newV !== oldV) emit("update:model-value", newV);
});

watch(valueProp, v => {
  if (v !== activeTask.value) activeTask.value = v;
});

function setActiveTask(task) {
  if (activeTask.value === task.key) emit("same-task-clicked");
  else activeTask.value = task.key;
}
</script>
