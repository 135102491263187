<template>
  <autocomplete-field
    ref="siteInput"
    v-model="site"
    v-model:search="siteSearch"
    autocomplete="false"
    item-title="domain"
    return-object
    no-filter
    hide-no-data
    :disabled="disabled"
    :prepend-inner-icon="prependInnerIcon"
    :loading="loading"
    :placeholder="placeholder"
    :label="label"
    :success="success"
    :error-messages="errorMessages"
    :items="sites"
    :clearable="clearable"
    :hide-details="hideDetails"
    :solo="solo"
    :flat="flat"
    :persistent-hint="hasMessageSlot || persistentHint"
    :hint="hint || (hasMessageSlot ? ' ' : '')"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @click:clear="$emit('update:model-value', {})"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }} <v-spacer /> <slot name="message" />
      </v-row>
    </template>
  </autocomplete-field>
</template>

<script>
import { searchApprovedDomains } from "@/api/approved-domain.service";
import { mdiAccount } from "@mdi/js";
export default {
  name: "QuoteAndApplySiteSearch",
  props: {
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    modelValue: { type: [Object, String], default: null },
    disabled: Boolean,
    persistentHint: Boolean,
    hint: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    },
    label: {
      required: false,
      default: "Quote and Apply Site",
      type: String
    },
    placeholder: {
      required: false,
      default: "Search Quote and Apply Sites",
      type: String
    },
    hideDetails: Boolean,
    solo: Boolean,
    simple: Boolean,
    payment: Boolean,
    flat: Boolean,
    prependInnerIcon: {
      type: String,
      required: false,
      default: mdiAccount
    }
  },
  emits: ["update:model-value", "update:modelValue", "blur"],
  data() {
    let sites = [];
    let site = null;
    if (this.modelValue && Object.keys(this.modelValue).length) {
      site = this.modelValue;
      sites = [this.modelValue];
    }
    return {
      siteSearch: null,
      siteTimer: null,
      loading: false,
      sites,
      site
    };
  },
  computed: {
    hasMessageSlot() {
      return Boolean(this.$slots.message);
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.site = null;
        this.sites = [];
        return;
      }
      this.site = value;
      this.sites = [value];
    },
    site(value) {
      this.$emit("update:model-value", value);
    },
    siteSearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }

      if (
        this.sites.some(site => site.name === value) &&
        this.sites.length === 1
      ) {
        return;
      }

      if (this.siteTimer) {
        clearTimeout(this.siteTimer);
      }

      this.siteTimer = setTimeout(() => {
        this.loading = true;
        searchApprovedDomains(value)
          .then(response => {
            this.sites = response;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      if (!this.$refs.siteInput?.$el) return;
      const input = this.$refs.siteInput?.$el.querySelector("input");
      if (!input?.focus) return;
      input.focus();
    }
  }
};
</script>
