<template>
  <v-card>
    <v-card-title> Change Email </v-card-title>
    <v-divider class="mb-3" />

    <v-card-text>
      <basic-email-input
        v-model="email"
        label="Email"
        required
        dense
        autofocus
        data-testid="change-email-input"
        :clearable="props.clearable"
        :success="Boolean(email) && emailIsValid !== false"
        :error-messages="emailIsValid === false ? ['Invalid Email'] : []"
        @valid="emailIsValid = $event"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        :loading="loading"
        data-testid="change-email-save"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {  ref } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  storeFunc: {
    type: Function,
    required: true
  },
  saveFunc: {
    type: Function,
    required: true
  },
  clearable: Boolean
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const email = ref(props.modelValue);
const loading = ref(false);
const emailIsValid = ref(null);

async function save() {
  if (email.value === props.modelValue) {
    snackbar.showSuccessSnackbar({
      message: "Email is unchanged",
      timeout: 10000
    });
    dialog.closeDialog({ email: email.value });
    return;
  }
  if ((!email.value && !props.clearable) || !emailIsValid.value) return;
  loading.value = true;
  try {
    //gross...
    props.storeFunc(email.value);
    const res = props.saveFunc();
    props.storeFunc(props.modelValue);
    const saveRes = await res;
    props.storeFunc(email.value);
    if (saveRes?.data?.message) {
      snackbar.showSuccessSnackbar({
        message: saveRes.data.message,
        timeout: 10000
      });
    }
    dialog.closeDialog({ email: email.value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
