<template>
  <span>{{ displayedValue }}</span>
</template>

<script setup>
import { timestampFormatter } from "@/util/helpers";
import { computed } from "vue";

const props = defineProps({
  modelValue: { type: [String, Date], default: null },
  parser: {
    type: String,
    default: "none",
    validates: v =>
      ["sole-day", "date-time", "formatted-day", "none"].includes(v)
  },
  //formatter??
  format: {
    type: String,
    default: "basic",
    validates: v =>
      [
        "basic",
        "full-date-time",
        "full-date",
        "date-time",
        "time",
        "date",
        "full-localize"
      ].includes(v)
  }
});

const displayedValue = computed(() => {
  try {
    const v = timestampFormatter(props.modelValue, props.parser, props.format);
    return v;
  } catch (e) {
    return "invalid date";
  }
});
</script>
