<template>
  <v-row class="ma-0" align="center" justify="start">
    <div :id="props.canvasParentId">
      <slot name="explanation-text" />
    </div>
  </v-row>
</template>

<script setup>
import { onMounted, ref, toRefs, watch } from "vue";
import { createHighDPICanvas } from "@/util/helpers";
const props = defineProps({
  gradient: Boolean,
  canvasParentId: {
    type: String,
    required: true
  },
  scale: {
    type: Number,
    required: true
  },
  buttonText: {
    type: String,
    required: true
  },
  primaryColor: {
    type: String,
    required: true
  },
  accentColor: {
    type: String,
    required: true
  },
  innerButtonTextColor: {
    type: String,
    required: true
  },
  borderRadius: {
    type: [String, Number],
    required: true
  },
  fontSize: {
    type: Number,
    required: true
  }
});

const {
  gradient,
  canvasParentId,
  scale,
  buttonText,
  primaryColor,
  accentColor,
  innerButtonTextColor,
  borderRadius,
  fontSize
} = toRefs(props);

const canvasId = `${canvasParentId.value}-canvas`;

const widthCalcStage = ref(true);
const textWidth = ref(0);
const textHeight = ref(0);
function generateButton() {
  const currentCanvas = document.getElementById(canvasId);
  if (currentCanvas) currentCanvas.remove();

  const div = document.getElementById(canvasParentId.value);
  if (!div) return;

  const height = fontSize.value * 2.5;

  const width = Math.max(100, textWidth.value + 60);
  const radius = Math.min(+borderRadius.value, height / 2);
  const textOffsetX = Math.trunc(width / 2);
  const textOffsetY = Math.floor(height / 1.55 - 2);
  const canvas = createHighDPICanvas(
    Math.ceil(width * scale.value),
    Math.ceil(height * scale.value)
  );
  canvas.id = canvasId;
  div.prepend(canvas);

  const ctx = canvas.getContext("2d");
  ctx.scale(scale.value, scale.value);

  if (widthCalcStage.value) {
    const { width: wordWidth, height: wordHeight } = getTextSize(
      ctx,
      buttonText.value,
      fontSize.value
    );
    textWidth.value = Math.ceil(wordWidth);
    textHeight.value = wordHeight;
    widthCalcStage.value = false;
    return generateButton();
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  roundRect(ctx, width, height, radius);
  drawText(ctx, buttonText.value, textOffsetX, textOffsetY, fontSize.value);

  widthCalcStage.value = true;
}

function getTextSize(ctx, text, fontSize) {
  ctx.font = `${fontSize}px Roboto, Helvetica, Arial, sans-serif`;
  const dims = ctx.measureText(text);
  return {
    width: dims.width,
    height: Math.trunc(dims.actualBoundingBoxAscent)
  };
}

function roundRect(ctx, width, height, radius) {
  ctx.fillStyle = accentColor.value;
  if (gradient.value) {
    const grd = ctx.createLinearGradient(0, 0, 200, 0);
    grd.addColorStop(0, accentColor.value);
    grd.addColorStop(1, primaryColor.value);
    ctx.fillStyle = grd;
  }

  // Initial offset
  const x = 0;
  const y = 0;

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x + radius, y);

  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);

  const btnInnerHeight = y + height - 5;

  ctx.lineTo(x + width, btnInnerHeight - radius);
  ctx.quadraticCurveTo(
    x + width,
    btnInnerHeight,
    x + width - radius,
    btnInnerHeight
  );
  ctx.lineTo(x + radius, btnInnerHeight);
  ctx.quadraticCurveTo(x, btnInnerHeight, x, btnInnerHeight - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
  ctx.restore();
}

function drawText(ctx, text, textOffsetX, textOffsetY, fontSize) {
  ctx.font = `${fontSize}px Roboto, Helvetica, Arial, sans-serif`;
  ctx.textAlign = "center";
  ctx.fillStyle = innerButtonTextColor.value;
  ctx.fillText(text, textOffsetX, textOffsetY);
}

watch(
  [
    borderRadius,
    scale,
    buttonText,
    primaryColor,
    accentColor,
    innerButtonTextColor
  ],
  generateButton
);
onMounted(generateButton);
</script>
