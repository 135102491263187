<template>
  <v-skeleton-loader v-if="loading" type="card" />
  <v-col v-else class="pa-0">
    <app-breadcrumbs data-testid="breadcrumbs" class="pl-3" />
    <v-row class="ma-0" align="end" dense>
      <v-tabs
        v-model="activeTab"
        style="width: unset"
        background-color="transparent"
        color="primary"
      >
        <v-tab class="text-none">
          <v-icon class="mr-1" :icon="mdiMapOutline" />
          <v-badge
            color="error"
            :content="incompleteQuestionCount"
            :model-value="Boolean(incompleteQuestionCount)"
          >
            Field Mapping
          </v-badge>
        </v-tab>
        <v-tab class="text-none" data-testid="form-relations">
          <v-icon class="mr-1" :icon="mdiRelationOneToMany" />
          Form Relations
        </v-tab>
        <v-tab class="text-none" data-testid="form-details">
          <v-icon class="mr-1" :icon="mdiBookInformationVariant" />
          Form Details
        </v-tab>
      </v-tabs>
      <v-row class="ma-0" align="center">
        <form-search
          v-model="copiedFormObj"
          label="Form to Copy From"
          hide-details
          clearable
          data-testid="copy-form-search"
          :current-form-id="id"
          class="pb-1"
        />
        <form-mapping-view-actions :id="props.id" />
      </v-row>
    </v-row>

    <v-card tile flat color="section">
      <v-tabs-window v-model="activeTab" touchless>
        <v-tabs-window-item eager>
          <v-row dense class="ma-0 position-relative" no-gutters>
            <!-- New Form -->
            <v-col :cols="renderCopiedFormComponents ? 6 : 12">
              <mapped-form-view style="min-height: 40rem" :form-id="id" />
            </v-col>
            <!-- Copied Form -->
            <v-col v-if="renderCopiedFormComponents" cols="6">
              <v-skeleton-loader v-if="loadingCopiedForm" type="card" />
              <mapped-form-view
                v-else
                key="copied-form"
                :form-id="copyingFromFormId"
              />
            </v-col>
          </v-row>
        </v-tabs-window-item>

        <v-tabs-window-item eager>
          <mapped-form-view-relations :form-id="id" />
        </v-tabs-window-item>

        <v-tabs-window-item eager>
          <mapped-form-details :form-id="id" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card>
  </v-col>
</template>
<script setup>
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import FormMappingViewActions from "@/components/form-mapping/FormMappingViewActions.vue";
import MappedFormViewRelations from "@/components/form-mapping/MappedFormViewRelations.vue";
import MappedFormDetails from "@/components/form-mapping/MappedFormDetails.vue";
import MappedFormView from "@/components/form-mapping/MappedFormView.vue";
import FormSearch from "@/components/shared/FormSearch.vue";

import { parseErrorMessage } from "@/util/helpers";
import { useMappedFormStore } from "@/stores/mapped-form";
import { useFormMappingView } from "@/stores/form-mapping-view";
import { storeToRefs } from "pinia";
import { ref,  watch, toRefs, computed } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";
import {
  mdiMapOutline,
  mdiRelationOneToMany,
  mdiBookInformationVariant
} from "@mdi/js";
import { useRouter } from "vue-router";

const props = defineProps({
  id: { type: Number, required: true },
  aqlId: { type: Number, required: false, default: null },
  copiedForm: { type: Object, required: false, default: () => {} }
});

const { aqlId, id, copiedForm } = toRefs(props);

const head = useHead({ title: "Form Mapping" });
const router = useRouter();

const instance = useInstanceStore();
const snackbar = useSnackbarStore();
const formMappingView = useFormMappingView();
const formMapper = useMappedFormStore(id.value);

const { breadcrumb } = storeToRefs(instance);
const { copyingFromFormId, editingFormId } = storeToRefs(formMappingView);
const { status, incompleteQuestionCount, currentFieldId, verifyingForm } =
  storeToRefs(formMapper);

const loading = ref(false);
const loadingCopiedForm = ref(false);
const activeTab = ref(0);
const copiedFormObj = ref(copiedForm.value);

const renderCopiedFormComponents = computed(
  () => loadingCopiedForm.value || copyingFromFormId.value
);

async function instantiateForm() {
  loading.value = true;
  try {
    await formMapper.initializeForm(id.value);
    formMapper.color = "primary";
    editingFormId.value = formMapper.id;
    breadcrumb.value = formMapper.name;
    head.patch({ title: formMapper.name });

    let setField;
    if (aqlId.value) setField = await formMapper.changeActiveField(aqlId.value);

    if (!setField) await formMapper.focusFirstField();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    router.replace({ name: "FormMappingForms" });
  } finally {
    loading.value = false;
  }
}

async function instantiateCopiedForm() {
  if (!copiedFormObj.value?.id) {
    copyingFromFormId.value = null;
    return;
  }

  loadingCopiedForm.value = true;

  const copiedForm = useMappedFormStore(copiedFormObj.value?.id);
  try {
    await copiedForm.initializeForm();
    copiedForm.readonly = true;
    copiedForm.color = "accent";
    copiedForm.otherFormId = id.value;
    await copiedForm.focusFirstField();
    formMapper.otherFormId = copiedForm.id;
    copyingFromFormId.value = copiedForm.id;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingCopiedForm.value = false;
  }
}

watch([currentFieldId, copiedFormObj], () => {
  const query = {};
  if (currentFieldId.value) query["aql-id"] = currentFieldId.value;
  if (copiedFormObj.value?.id) {
    query["copied-form"] = JSON.stringify(copiedFormObj.value);
  }

  router.replace({ query });
});

let copyTimeout;
watch(
  copiedFormObj,
  () => {
    if (copyTimeout) clearTimeout(copyTimeout);
    copyTimeout = setTimeout(instantiateCopiedForm);
  },
  { immediate: true, deep: true }
);

watch(
  [status, verifyingForm],
  () => {
    formMapper.readonly = status.value !== "Incomplete" || verifyingForm.value;
  },
  { immediate: true }
);

instantiateForm();
</script>
