<template>
  <div class="pa-1">
    <v-card class="pa-3">
      <v-row class="ma-0 pb-1" align="center" style="gap: 4px">
        <app-breadcrumbs data-testid="breadcrumbs" />
        <v-spacer />
        <app-button
          v-for="button in actionButtons"
          :key="button.text"
          v-bind="button.buttonProps"
          @click="button.buttonProps.onClick"
        >
          <v-icon class="mr-1" :icon="button.icon" />
          {{ button.text }}
        </app-button>
      </v-row>
      <v-card variant="outlined">
        <div>
          <v-card-title> Custom Chat Template Creator </v-card-title>
          <v-card-subtitle>
            Create and modify Custom Chat Templates
          </v-card-subtitle>
        </div>
        <v-divider />
        <v-row dense class="ma-0">
          <v-col
            cols="12"
            :md="focus ? 12 : 6"
            :order="order(FOCUS.EDITOR)"
            class="section"
          >
            <v-card-title class="font-weight-regular">
              Template Details
            </v-card-title>
            <v-card-subtitle>
              Specify HTML, replaceable main content, and publish status.
            </v-card-subtitle>
            <div class="mx-n1">
              <v-divider />
            </div>
            <v-card-text class="px-1">
              <v-row dense>
                <v-col cols="12" md="8">
                  <text-field
                    v-model="customChatTemplate.name"
                    v-bind="titleValidation"
                    label="Custom Chat Template Name"
                    hide-details="auto"
                    data-testid="custom-chat-template-name"
                    :disabled="loadingTemplate"
                    :prepend-inner-icon="mdiFormatTitle"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <checkbox-field
                    v-model="customChatTemplate.published"
                    class="mt-1"
                    label="Published"
                    dense
                    data-testid="custom-chat-template-published"
                    :disabled="loadingTemplate"
                    :success="customChatTemplate.published"
                  />
                </v-col>
                <v-col cols="12">
                  <autocomplete-field
                    v-model="customChatTemplate.modelType"
                    label="Type"
                    v-bind="modelTypeValidation"
                    data-testid="custom-chat-template-model-type"
                    hide-details="auto"
                    :disabled="loadingTemplate"
                    :items="MODEL_TYPES"
                    :prepend-inner-icon="mdiCube"
                  />
                </v-col>

                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-title>
                        Dynamic Values
                      </v-expansion-panel-title>
                      <v-expansion-panel-text
                        class="no-expansion-panel-content-padding"
                      >
                        <v-divider />
                        <v-card-title class="font-weight-regular">
                          Dynamic Values
                        </v-card-title>
                        <v-card-subtitle>
                          Dynamic pieces of text that is replaced by context.
                          Click to copy.
                        </v-card-subtitle>
                        <v-card-text>
                          <v-row>
                            <v-col
                              v-for="(value, index) in templateTokens"
                              :key="index"
                              cols="6"
                              md="6"
                              class="clickable"
                              @click="copyToClipboard(value.token)"
                            >
                              <div class="mono-text">
                                {{ value.token }}
                              </div>
                              <div class="text-grey lighten-2">
                                {{ value.description }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <v-col cols="12">
                  <textarea-field
                    id="custom-chat-template"
                    v-model="customChatTemplate.html"
                    label="HTML"
                    rows="10"
                    auto-grow
                    v-bind="htmlValidation"
                    hide-details="auto"
                    data-testid="custom-chat-template-html"
                    :prepend-inner-icon="mdiCodeTags"
                    :disabled="loadingTemplate"
                  />
                  <v-alert v-if="isNotFullySanitized" type="error">
                    The provided contains tags that do not comply with our
                    security standards.
                    <app-button
                      class="text-none"
                      color="primary"
                      @click="cleanTemplate"
                    >
                      Sanitize HTML
                    </app-button>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <textarea-field
                    v-model="customChatTemplate.mainText"
                    label="Main Text"
                    rows="10"
                    auto-grow
                    hide-details="auto"
                    data-testid="custom-chat-template-main-text"
                    v-bind="mainTextValidation"
                    hint="This text is customizable by the user."
                    persistent-hint
                    :prepend-inner-icon="mdiText"
                    :disabled="loadingTemplate"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <div class="mx-n1">
              <v-divider />
            </div>

            <v-card-actions align="end">
              <app-button
                color="primary"
                class="text-none"
                data-testid="save-template"
                :loading="saving"
                :disabled="loadingTemplate"
                @click="save"
              >
                Save Template
              </app-button>
              <app-button
                v-if="customChatTemplate.id"
                color="error"
                class="text-none"
                data-testid="delete-template"
                :disabled="loadingTemplate"
                @click="destroy"
              >
                Delete
              </app-button>
            </v-card-actions>
          </v-col>

          <v-col cols="12" :md="focus ? 12 : 6" :order="order(FOCUS.PREVIEW)">
            <v-card tile flat>
              <v-card-title class="font-weight-regular"> Preview </v-card-title>
              <v-card-subtitle>
                View a rendered template
                <a
                  :class="{
                    'text-primary': !sendingPreviewEmail,
                    'text-grey': sendingPreviewEmail
                  }"
                  data-testid="send-preview-email"
                  @click="previewTemplate"
                >
                  Send a Preview to yourself.
                </a>
                <v-progress-circular
                  v-if="sendingPreviewEmail"
                  indeterminate
                  size="12"
                  width="1"
                  color="primary"
                />
              </v-card-subtitle>
              <v-card-text class="pa-1">
                <iframe
                  data-testid="preview-iframe"
                  :class="{
                    'transparency-indicator': usingTransparencyIndicator
                  }"
                  style="height: 100vh; width: 100%; border: 1px solid #ccc"
                  class="rounded"
                  :srcdoc="previewHTML"
                  sandbox
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>

<script setup>
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import {
  CustomChatTemplate,
  CustomChatTemplateToRequest,
  MODEL_TYPES
} from "@/factories/CustomChatTemplate";

import { customChatTemplateSanitizer } from "@/html-sanitizer";
import { computed, ref, watch, markRaw } from "vue";
import {
  mdiFullscreen,
  mdiFullscreenExit,
  mdiCodeBlockBraces,
  mdiPresentationPlay,
  mdiCardBulletedOff,
  mdiCardBulleted,
  mdiSquareOpacity,
  mdiSquareOffOutline,
  mdiFormatTitle,
  mdiText,
  mdiCodeTags,
  mdiCube
} from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { useHead } from "@unhead/vue";

import {
  getCustomChatTemplate,
  updateCustomChatTemplate,
  createCustomChatTemplate,
  deleteCustomChatTemplate,
  previewCustomChatTemplate
} from "@/api/chat-templates.service";

import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useRouter } from "vue-router";

const FOCUS = {
  EDITOR: "editor",
  PREVIEW: "preview",
  ALL: ""
};

const props = defineProps({
  id: { type: Number, required: false, default: null }
});

const head = useHead({ title: "Custom Chat Template Creator" });

const instance = useInstanceStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();

const { fullscreen, breadcrumb } = storeToRefs(instance);

const focus = ref(FOCUS.ALL);
const usingSampleData = ref(true);
const usingTransparencyIndicator = ref(true);
const saving = ref(false);
const sendingPreviewEmail = ref(false);
const loadingTemplate = ref(false);

const customChatTemplate = ref(CustomChatTemplate());

const actionButtons = computed(() => {
  const buttons = [
    {
      icon: mdiCodeBlockBraces,
      text: "Expand Editor",
      buttonProps: {
        variant: buttonVariant(notFocused(FOCUS.EDITOR)),
        color: actionColor(FOCUS.EDITOR),
        onClick: () => toggleFocus(FOCUS.EDITOR)
      }
    },
    {
      icon: mdiPresentationPlay,
      text: "Expand Preview",
      buttonProps: {
        variant: buttonVariant(notFocused(FOCUS.PREVIEW)),
        color: actionColor(FOCUS.PREVIEW),
        onClick: () => toggleFocus(FOCUS.PREVIEW)
      }
    },
    {
      icon: usingTransparencyIndicator.value
        ? mdiSquareOffOutline
        : mdiSquareOpacity,
      text:
        (usingTransparencyIndicator.value ? "Hide" : "Show") +
        " Transparency Indicator",
      buttonProps: {
        variant: buttonVariant(!usingTransparencyIndicator.value),
        color: usingTransparencyIndicator.value ? "primary" : null,
        onClick: () =>
          (usingTransparencyIndicator.value = !usingTransparencyIndicator.value)
      }
    },
    {
      icon: usingSampleData.value ? mdiCardBulletedOff : mdiCardBulleted,
      text: (usingSampleData.value ? "Hide" : "Show") + " Sample Values",
      buttonProps: {
        variant: buttonVariant(!usingSampleData.value),
        color: usingSampleData.value ? "primary" : null,
        onClick: () => (usingSampleData.value = !usingSampleData.value)
      }
    },
    {
      icon: fullscreen.value ? mdiFullscreen : mdiFullscreenExit,
      text: (fullscreen.value ? "Exit" : "Enter") + " Fullscreen",
      buttonProps: {
        variant: buttonVariant(!fullscreen.value),
        color: fullscreen.value ? "primary" : null,
        onClick: () => (fullscreen.value = !fullscreen.value)
      }
    }
  ];

  return buttons.map(b => ({
    ...b,
    buttonProps: {
      class: "text-none",
      size: "small",
      ...b.buttonProps
    }
  }));
});

function buttonVariant(notFocused) {
  return notFocused ? "flat" : "elevated";
}

const sanitizedHTML = computed(() => customChatTemplateSanitizer(customChatTemplate.value.html));
const isNotFullySanitized = computed(
  () => customChatTemplate.value.html !== sanitizedHTML.value
);

const v$ = useVuelidate(
  {
    customChatTemplate: {
      name: {
        isValid: v => /^[A-Za-z0-9\s,;:'"-.!?]+$/.test(v),
        required: v => someTextValidator(true, v, 2)
      },
      html: {
        isValid: v => v === sanitizedHTML.value,
        required: v => someTextValidator(true, v, 2)
      },
      mainText: {
        isValid: v => /^[A-Za-z0-9\s,;:'"-.!?]+$/.test(v),
        required: v => someTextValidator(true, v, 2)
      },
      modelType: {
        required: v => MODEL_TYPES.some(t => t.value === v)
      }
    }
  },
  { customChatTemplate },
  { $scope: null, $autoDirty: true }
);

const htmlValidation = computedValidation(v$.value.customChatTemplate.html, {
  required: "Required",
  isValid: "Must be sanitized and valid HTML. Click the alert banner."
});

const titleValidation = computedValidation(v$.value.customChatTemplate.name, {
  required: "Required",
  isValid: "May only contain letters, numbers, and ,;:-.!?"
});

const mainTextValidation = computedValidation(
  v$.value.customChatTemplate.mainText,
  {
    required: "Required",
    isValid: "May only contain letters, numbers, and ,;:-.!?"
  }
);

const modelTypeValidation = computedValidation(
  v$.value.customChatTemplate.modelType,
  { required: "Required" }
);

const templateTokens = computed(() => {
  return [
    {
      token: "[FirstName]",
      description: "The first name of the insured.",
      replace: v => v.replaceAll("[FirstName]", "John")
    },
    {
      token: "[LastName]",
      description: "The last name of the insured.",
      replace: v => v.replaceAll("[LastName]", "Doe")
    },
    {
      token: "[Link]",
      description: "The link to the eApp, used like <a href='[Link]'> ... </a>",
      replace: v => v.replaceAll("[Link]", "https://www.back9ins.com/eapp")
    },
    {
      token: "[MarketAsName]",
      description: 'The Quote & Apply website\'s "Market As" name',
      replace: v => v.replaceAll("[MarketAsName]", "Website Market As")
    },
    {
      token: "[AssignableName]",
      description: 'The Quote & Apply website\'s "Assignable" name',
      replace: v => v.replaceAll("[AssignableName]", "Website Assignable")
    },
    {
      token: "[MainText]",
      description: "Default plaintext that is customizable by the user",
      replace: v =>
        v.replaceAll("[MainText]", customChatTemplate.value.mainText),
      isSample: true
    },
    {
      token: "[AvatarUrl]",
      description:
        "The Company Name's Avatar URL, used like <img src='[AvatarUrl]' />",
      replace: v =>
        v.replaceAll(
          "[AvatarUrl]",
          "https://portal-pictures.back9ins.com/BOSS-Logo.png"
        )
    },
    {
      token: "[AgentName]",
      description: "The Agent's name",
      replace: v => v.replaceAll("[AgentName]", "Agent Doe")
    },
    {
      token: "[AgentPhoneWork]",
      description: "The Agent's phone number",
      replace: v => v.replaceAll("[AgentPhoneWork]", "(555) 555-5555")
    },
    {
      token: "[AgentEmail]",
      description: "The Agent's Email Address",
      replace: v => v.replaceAll("[AgentEmail]", "test@back9ins.com")
    },
    {
      token: "[AgentSchedulingLink]",
      description: "The Agent's scheduling link (Calendly, etc.)",
      replace: v =>
        v.replaceAll(
          "[AgentSchedulingLink]",
          "https://www.back9ins.com/schedule"
        )
    }
  ];
});

const previewHTML = computed(() => {
  if (isNotFullySanitized.value) return "Please sanitize the HTML first.";
  let preview = sanitizedHTML.value;
  templateTokens.value.forEach(t => {
    if (!usingSampleData.value && !t.isSample) return;
    preview = t.replace(preview);
  });
  return preview;
});

function cleanTemplate() {
  customChatTemplate.value.html = sanitizedHTML.value
    .replace(/\n\s*(\n\s*)+/g, "\n")
    .trim();
}

function copyToClipboard(value) {
  navigator.clipboard.writeText(value);
  snackbar.showSuccessSnackbar({ message: "Copied to clipboard" });
}

function toggleFocus(value) {
  if (focus.value === value) focus.value = "";
  else focus.value = value;
}

function actionColor(value) {
  return focus.value === value ? "primary" : null;
}

function order(value) {
  const isPreview = value === FOCUS.PREVIEW;
  if (focus.value === FOCUS.PREVIEW) {
    return isPreview ? 1 : 2;
  }

  return isPreview ? 2 : 1;
}

function notFocused(value) {
  return focus.value !== value;
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  if (customChatTemplate.value.id) update();
  else create();
}

async function update() {
  try {
    saving.value = true;
    await updateCustomChatTemplate(
      customChatTemplate.value.id,
      CustomChatTemplateToRequest(customChatTemplate.value)
    );
    snackbar.showSuccessSnackbar({ message: "Template updated successfully" });
    return true;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

async function create() {
  try {
    saving.value = true;
    const template = await createCustomChatTemplate(
      CustomChatTemplateToRequest(customChatTemplate.value)
    );
    customChatTemplate.value = template;
    cleanTemplate();
    snackbar.showSuccessSnackbar({ message: "Template created successfully" });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

async function loadExistingTemplate() {
  try {
    loadingTemplate.value = true;
    const template = await getCustomChatTemplate(props.id);
    customChatTemplate.value = template;
    cleanTemplate();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingTemplate.value = false;
  }
}

async function previewTemplate() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Send Preview Email",
    confirmText: "Save and Send Preview",
    subtitle:
      "Please save the template before sending a preview email. It will only show what has been saved and current changes are ignored.",
    func: async () => {
      const isValid = await v$.value.$validate();
      if (!isValid) {
        snackbar.showErrorSnackbar({ message: "Invalid Fields Detected" });
        return;
      }

      await save();
      await previewCustomChatTemplate(customChatTemplate.value.id);
    }
  });
}

async function destroy() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Custom Chat Template",
    subtitle: "Please confirm your intent",
    func: async () => {
      try {
        await deleteCustomChatTemplate(customChatTemplate.value.id);
        snackbar.showSuccessSnackbar({
          message: "Template deleted successfully"
        });
        if (router) {
          router.replace({
            name: "Tables",
            query: { page: "custom-chat-templates" }
          });
        }
      } catch (e) {
        snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  });
}

if (props.id) loadExistingTemplate();

watch(
  () => customChatTemplate.value.name,
  v => {
    if (!customChatTemplate.value.id) return;
    head.patch({ title: v || "Custom Chat Template Viewer" });
    breadcrumb.value = v || "Custom Chat Template Viewer";
  },
  { immediate: true }
);
</script>

<style lang="scss">
#custom-chat-template {
  white-space: nowrap;
  overflow-x: scroll;
  line-height: normal;
  font-family: monospace;
  font-size: 14px;
}

.transparency-indicator {
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M0 0h10v10H0z'  stroke-width='1' stroke='none' fill='hsla(259, 0%, 92%, 1)'/><path d='M10 10h10v10H10z'  stroke-width='1' stroke='none' fill='hsla(259, 0%, 92%, 1)'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
}

.mono-text {
  font-family: monospace;
}
</style>
