<template>
  <v-card v-if="electronicApplicationId" flat tile width="100%">
    <v-row class="ma-0 px-3">
      <v-spacer />
      <app-button
        class="text-none rounded-pill"
        variant="outlined"
        color="primary"
        data-testid="action-view-eapp"
        :to="getRouterLink('ElectronicApplication', electronicApplicationId)"
      >
        <v-icon :icon="mdiFileDocument" /> View Quote & Apply eApp
      </app-button>
    </v-row>
  </v-card>
</template>

<script setup>
import { usePartyView } from "@/stores/party-view";
import { mdiFileDocument } from "@mdi/js";
import { storeToRefs } from "pinia";
import { getRouterLink } from "@/util/helpers";

const partyView = usePartyView();

const { electronicApplicationId } = storeToRefs(partyView);
</script>
