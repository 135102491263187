<template>
  <v-row class="ma-0" align="center" dense>
    <v-col cols="12">
      <v-row>
        <v-spacer />
        <app-button
          class="text-none"
          variant="text"
          color="primary"
          text="Disable All Emails"
          @click="disableAllEmails"
        />
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <h3>Appointments</h3>
    </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <checkbox-field
          label="Accessible"
          :model-value="appointments"
          class="mt-0"
          hide-details
          @update:model-value="updateAppointments($event)"
        />
        <checkbox-field
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!appointments"
          :model-value="appointmentsEmails"
          hide-details
          @update:model-value="updateAppointmentEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Appointments"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="appointments"
        :resource-email-notifications="appointmentsEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>Cases</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <checkbox-field
          label="Accessible"
          :model-value="cases"
          class="mt-0"
          hide-details
          @update:model-value="updateCases($event)"
        />
        <checkbox-field
          label="Email Notifications"
          :disabled="!cases"
          :model-value="casesEmails"
          class="mt-0 ml-6"
          hide-details
          @update:model-value="updateCaseEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Cases"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="cases"
        :resource-email-notifications="casesEmails"
      />
    </v-col>
    <v-col cols="12" md="6">
      <h3>Clients</h3>
    </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <checkbox-field
          class="mt-0"
          label="Accessible"
          :model-value="contractParties"
          hide-details
          @update:model-value="updateContractParties($event)"
        />
        <checkbox-field
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!contractParties"
          :model-value="contractPartiesEmails"
          hide-details
          @update:model-value="updateContractPartyEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Clients"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="contractParties"
        :resource-email-notifications="contractPartiesEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>eApps</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <checkbox-field
          class="mt-0"
          label="Accessible"
          :model-value="eapps"
          hide-details
          @update:model-value="updateEapps($event)"
        />
        <checkbox-field
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!eapps"
          :model-value="eappsEmails"
          hide-details
          @update:model-value="updateEappEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="eApps"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="eapps"
        :resource-email-notifications="eappsEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>Payments</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <checkbox-field
          label="Accessible"
          :model-value="payments"
          class="mt-0"
          hide-details
          @update:model-value="updatePayments($event)"
        />
        <checkbox-field
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!payments"
          :model-value="paymentsEmails"
          hide-details
          @update:model-value="updatePaymentEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Payments"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="payments"
        :resource-email-notifications="paymentsEmails"
      />
    </v-col>

    <v-col cols="12" md="6"> <h3>Quick Quotes</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <checkbox-field
          label="Accessible"
          :model-value="quickQuotes"
          class="mt-0"
          hide-details
          @update:model-value="updateQuickQuotes($event)"
        />
        <checkbox-field
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!quickQuotes"
          :model-value="quickQuotesEmails"
          hide-details
          @update:model-value="updateQuickQuoteEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Quick Quotes"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="quickQuotes"
        :resource-email-notifications="quickQuotesEmails"
      />
    </v-col>
    <v-col cols="12" md="6"> <h3>Quotes</h3> </v-col>
    <v-col cols="12" md="6">
      <v-row class="ma-0" align="center" justify-md="end">
        <checkbox-field
          class="mt-0"
          label="Accessible"
          :model-value="quotes"
          hide-details
          @update:model-value="updateQuotes($event)"
        />
        <checkbox-field
          class="mt-0 ml-6"
          label="Email Notifications"
          :disabled="!quotes"
          :model-value="quotesEmails"
          hide-details
          @update:model-value="updateQuoteEmails($event)"
        />
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0">
      <connection-dialog-helper
        resource="Quotes"
        :connection-name="connectionName"
        :advisor-name="advisorName"
        :resource-access="quotes"
        :resource-email-notifications="quotesEmails"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import ConnectionDialogHelper from "@/dialogs/ConnectionDialogHelper.vue";
import { toRefs, watch } from "vue";
const props = defineProps({
  advisorName: { type: String, required: true },
  connectionName: { type: String, required: true },

  appointments: Boolean,
  appointmentsEmails: Boolean,

  cases: Boolean,
  casesEmails: Boolean,

  contractParties: Boolean,
  contractPartiesEmails: Boolean,

  eapps: Boolean,
  eappsEmails: Boolean,

  payments: Boolean,
  paymentsEmails: Boolean,

  quickQuotes: Boolean,
  quickQuotesEmails: Boolean,

  quotes: Boolean,
  quotesEmails: Boolean
});

const {
  appointments,
  cases,
  contractParties,
  eapps,
  payments,
  quickQuotes,
  quotes
} = toRefs(props);

const emit = defineEmits([
  "update:appointments",
  "update:appointments-emails",
  "update:cases",
  "update:cases-emails",
  "update:contract-parties",
  "update:contract-parties-emails",
  "update:eapps",
  "update:eapps-emails",
  "update:payments",
  "update:payments-emails",
  "update:quick-quotes",
  "update:quick-quotes-emails",
  "update:quotes",
  "update:quotes-emails"
]);

watch(appointments, v => {
  if (!v) updateAppointmentEmails(false);
});

watch(cases, v => {
  if (!v) updateCaseEmails(false);
});

watch(contractParties, v => {
  if (!v) updateContractPartyEmails(false);
});

watch(eapps, v => {
  if (!v) updateEappEmails(false);
});

watch(payments, v => {
  if (!v) updatePaymentEmails(false);
});

watch(quickQuotes, v => {
  if (!v) updateQuickQuoteEmails(false);
});

watch(quotes, v => {
  if (!v) updateQuoteEmails(false);
});

function updateAppointments(v) {
  emit("update:appointments", v);
}
function updateAppointmentEmails(v) {
  emit("update:appointments-emails", v);
}
function updateCases(v) {
  emit("update:cases", v);
}
function updateCaseEmails(v) {
  emit("update:cases-emails", v);
}
function updateContractParties(v) {
  emit("update:contract-parties", v);
}
function updateContractPartyEmails(v) {
  emit("update:contract-parties-emails", v);
}
function updateEapps(v) {
  emit("update:eapps", v);
}
function updateEappEmails(v) {
  emit("update:eapps-emails", v);
}
function updatePayments(v) {
  emit("update:payments", v);
}
function updatePaymentEmails(v) {
  emit("update:payments-emails", v);
}
function updateQuickQuotes(v) {
  emit("update:quick-quotes", v);
}
function updateQuickQuoteEmails(v) {
  emit("update:quick-quotes-emails", v);
}
function updateQuotes(v) {
  emit("update:quotes", v);
}
function updateQuoteEmails(v) {
  emit("update:quotes-emails", v);
}
function disableAllEmails() {
  updateAppointmentEmails(false);
  updateCaseEmails(false);
  updateContractPartyEmails(false);
  updateEappEmails(false);
  updatePaymentEmails(false);
  updateQuickQuoteEmails(false);
  updateQuoteEmails(false);
}
</script>
