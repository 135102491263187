import * as GROUPS from "../constants/group.constants";
import { useUserStore } from "@/stores/user";

function groupGuard(level, to, next) {
  const user = useUserStore();
  if (user.group < level) {
    return next({ name: "Home" });
  }
  next();
}

export function groupTwoGuard(to, from, next) {
  return groupGuard(GROUPS.GROUP_2, to, next);
}

export function groupThreeGuard(to, from, next) {
  return groupGuard(GROUPS.GROUP_3, to, next);
}

export function groupFourGuard(to, from, next) {
  return groupGuard(GROUPS.GROUP_4, to, next);
}
