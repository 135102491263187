<template>
  <v-data-table
    v-model:search="search"
    data-testid="vendors-table"
    filter-mode="union"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
  >
    <template #top>
      <v-row class="ma-0 px-3" align="center">
        <h1 class="text-h5">Vendors</h1>
        <v-spacer />
        <text-field
          v-model="search"
          data-testid="vendors-table-search"
          data-lpignore="true"
          hide-details
          label="Search"
          :prepend-inner-icon="mdiMagnify"
        />
        <app-button
          :icon="mdiRefresh"
          variant="text"
          density="comfortable"
          data-testid="vendors-table-refresh"
          @click="getData"
        />
      </v-row>
    </template>
    <template #[`item.email`]="{ item }">
      <a v-if="item.email" :href="`mailto:${item.email}`"> {{ item.email }}</a>
    </template>

    <template #[`item.actions`]="{ item }">
      <v-btn
        color="accent"
        data-testid="edit"
        variant="text"
        density="comfortable"
        :icon="mdiPencil"
        @click="editItem(item.additional)"
      >
        <v-icon> {{ mdiPencil }} </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script setup>
import EditVendorDialog from "@/dialogs/EditVendorDialog.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { mdiRefresh, mdiPencil } from "@mdi/js";
import { parseErrorMessage, listToSentence } from "@/util/helpers";
import { getVendors } from "@/api/vendor.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { ref, markRaw } from "vue";
import { VENDOR_DISPLAY } from "@/factories/VendorFactory";

const search = ref("");

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Email",
      value: "email",
      map: "email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Type",
      value: "formattedType",
      map: "formattedType",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Values",
      value: "tags",
      map: "tags",
      align: "center",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions"
    })
  ],
  getData: async () => {
    const items = await getVendors();

    return {
      items: items.map(v => ({
        ...v,
        formattedType: VENDOR_DISPLAY[v.type],
        tags: getTags(v)
      }))
    };
  }
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function editItem(item) {
  const res = await dialog.showDialog({
    component: markRaw(EditVendorDialog),
    modelValue: item
  });

  if (res?.saved) getData();
}

function getTags(vendor) {
  const values = [];
  if (vendor.available) values.push("Available");
  if (vendor.default) values.push("Default");
  return listToSentence(values);
}

getData();
</script>
