<template>
  <v-menu offset-y :top="text">
    <template #activator="{ props: menu }">
      <v-tooltip location="bottom" :disabled="text">
        <template #activator="{ props: tooltip }">
          <app-button
            v-bind="{ ...menu, ...tooltip }"
            :icon="mdiPlaylistPlus"
            :class="{ rounded: text }"
            variant="text"
            class="opacity-60"
            data-testid="model-create-menu"
            :style="width ? `width: ${width}` : null"
            :color="color || 'inherit'"
          />
        </template>
        <span>Create Menu </span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item
        v-for="object in models"
        :key="object.value"
        data-testid="model-create-menu-item"
        :to="{ name: object.value }"
        :prepend-icon="object.icon"
        :title="object.text"
      />
    </v-list>
  </v-menu>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { PERMISSION } from "@/constants/permissions.constants";
import {
  mdiAccount,
  mdiAccountCircle,
  mdiBarcode,
  mdiBookInformationVariant,
  mdiBriefcase,
  mdiBriefcaseVariant,
  mdiCalendar,
  mdiCashRegister,
  mdiChartLine,
  mdiClipboardAccount,
  mdiFileDocument,
  mdiHumanGreeting,
  mdiMagnetOn,
  mdiNewspaper,
  mdiPlaylistPlus,
  mdiPointOfSale
} from "@mdi/js";

defineProps({
  width: { type: String, required: false, default: null },
  color: { type: String, required: false, default: null },
  text: Boolean
});

const userStore = useUserStore();

const models = [
  {
    text: "Advisor",
    icon: mdiAccount,
    value: "AdvisorCreate"
  }
];

if (userStore.isGroupTwoPlus) {
  models.push(
    {
      text: "Appointment",
      icon: mdiCalendar,
      value: "AppointmentCreate"
    },
    {
      text: "Case",
      icon: mdiBriefcaseVariant,
      value: "CaseCreate"
    },
    {
      text: "Informal Inquiry",
      icon: mdiBookInformationVariant,
      value: "CaseCreateInformal"
    },
    {
      text: "Lead",
      icon: mdiMagnetOn,
      value: "LeadCreate"
    },
    {
      text: "Personnel",
      icon: mdiAccountCircle,
      value: "PersonnelCreate"
    }
  );
}

if (userStore.permissions[PERMISSION.ACCOUNTING]) {
  models.push(
    {
      text: "Payment",
      icon: mdiCashRegister,
      value: "PaymentCreate"
    },
    {
      text: "Product",
      icon: mdiBarcode,
      value: "ProductCreate"
    },
    {
      text: "Statement",
      icon: mdiNewspaper,
      value: "StatementCreate"
    },
    {
      text: "Transaction",
      icon: mdiPointOfSale,
      value: "TransactionCreate"
    }
  );
}

if (userStore.loginable?.show_backnine) {
  models.push(
    {
      text: "Application Request",
      icon: mdiFileDocument,
      value: "ApplicationRequest"
    },
    {
      text: "Client",
      icon: mdiHumanGreeting,
      value: "ContractPartyCreate"
    },
    {
      text: "Impaired Risk Quote",
      icon: mdiChartLine,
      value: "ImpairedRiskQuoteCreate"
    },
    {
      text: "Submit Case",
      icon: mdiBriefcase,
      value: "CaseSubmission"
    },
    {
      text: "Quote Request",
      icon: mdiClipboardAccount,
      value: "CreateQuote"
    }
  );
}

models.sort((a, b) => a.text.localeCompare(b.text));
</script>
