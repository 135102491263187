<template>
  <v-list-item
    lines="two"
    link
    :class="{ 'v-list-item--active': item.active }"
    @click="emit('click')"
  >
    <v-list-item-subtitle style="font-size: 1rem; line-height: 18px">
      {{ name }}
    </v-list-item-subtitle>

    <v-row class="pa-3">
      <v-col
        cols="12"
        align="end"
        style="font-size: 0.8rem"
        class="text-grey pa-0 pb-1"
      >
        <v-icon
          v-if="item.email"
          data-testid="is-emailed"
          size="small"
          class="text-grey mr-1"
          :icon="mdiEmail"
        />

        <span style="font-weight: 400">
          {{ dateStr }}
        </span>
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script setup>
import { mdiEmail } from "@mdi/js";
import differenceInDays from "date-fns/differenceInDays";
import { timestampFormatter } from "@/util/helpers";
import { computed, toRef } from "vue";

const emit = defineEmits(["click"]);
const props = defineProps({
  item: { type: Object, required: true }
});

const item = toRef(props, "item");

const txt = document.createElement("div");
txt.innerHTML = item.value.description;
const name = txt.textContent || txt.innerText;

const dateStr = computed(() => {
  if (item.value.completedAt) {
    const completedFormatted = timestampFormatter(item.value.completedAt);
    return `Completed: ${completedFormatted}`;
  }

  const dates = [];
  if (item.value.createdAt) {
    const elapsedFormatted = differenceInDays(new Date(), item.value.createdAt);
    dates.push(`${elapsedFormatted} Days Pending`);
  }
  if (item.value.followUp) {
    const followUpFormatted = timestampFormatter(item.value.followUp);
    dates.push(`Follow Up: ${followUpFormatted}`);
  }

  return dates.join(" | ");
});
</script>
