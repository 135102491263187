<template>
  <v-card class="ma-1">
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        must-sort
        data-testid="backnine-case-commissions-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :loading="table.loading.value"
        :footer-props="table.footerProps.value"
        :items-per-page-options="table.itemsPerPageOptions"
        :mobile="null"
        mobile-breakpoint="sm"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Case Commissions</h1>
            <v-spacer />
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  variant="text"
                  density="comfortable"
                  class="table-btn"
                  v-bind="templateProps"
                  data-testid="backnine-case-commissions-table-refresh"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.income`]="{ item }">
          <currency-formatter :model-value="item.income" :decimal-length="0" />
        </template>
        <template #[`item.expenses`]="{ item }">
          <currency-formatter
            :model-value="item.expenses"
            :decimal-length="0"
          />
        </template>
        <template #[`item.profit`]="{ item }">
          <currency-formatter :model-value="item.profit" :decimal-length="0" />
        </template>
        <template #[`item.inforceDate`]="{ item }">
          <timestamp-formatter
            :model-value="item.inforceDate"
            parser="sole-day"
          />
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { getBackNineCaseCommissions } from "@/api/commissions.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { useHead } from "@unhead/vue";
import { mdiRefresh } from "@mdi/js";

useHead({ title: "BackNine Case Commissions" });

const table = useTable({
  headers: [
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName",
      sortFilterMap: "carriers.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Policy",
      value: "policyNumber",
      map: "policyNumber",
      sortFilterMap: "cases.policy_number",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Income",
      value: "income",
      map: "income",
      sortFilterMap: "cases.income",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Expenses",
      value: "expenses",
      map: "expenses",
      sortFilterMap: "cases.expenses",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Profit",
      value: "profit",
      map: "profit",
      sortFilterMap: "cases.profit",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Inforce Date",
      value: "inforceDate",
      map: "inforceDate",
      sortFilterMap: "cases.inforce_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Manager",
      value: "manager",
      map: "marketer",
      sortFilterMap: "marketing_managers.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: getBackNineCaseCommissions,
  options: TableOptions({ sortBy: [{ key: "inforceDate", order: "desc" }] })
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
