<template>
  <v-card flat>
    <v-row class="ma-0">
      <v-card-title> Smoker Charts </v-card-title>
      <v-spacer />
      <app-button
        class="text-none ma-3"
        color="primary"
        :icon="mdiPlus"
        variant="text"
        density="comfortable"
        data-testid="add-product-smoker-chart"
        @click="add"
      />
    </v-row>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="item in productSmokerCharts"
        :key="item.id"
        data-testid="product-smoker-chart"
      >
        <v-list-item-title>{{ item.smokerChart.name }}</v-list-item-title>

        <template #append>
          <app-button
            class="text-none"
            :icon="mdiDelete"
            color="error"
            variant="text"
            density="comfortable"
            data-testid="remove"
            @click="remove(item)"
          />
        </template>
      </v-list-item>
      <v-list-item v-if="!productSmokerCharts.length">
        <v-list-item-title>No Smoker Charts</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script setup>
import AddProductSmokerChartDialog from "@/components/products/dialogs/AddProductSmokerChartDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { removeProductSmokerChart } from "@/api/product-smoker-chart.service";
import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiPlus, mdiDelete } from "@mdi/js";
import { useProductView } from "@/stores/product-view";
import { markRaw } from "vue";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const productView = useProductView();
const { productSmokerCharts, id, carrier } = storeToRefs(productView);

async function remove(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Remove Product Citizenship Profile",
    subtitle: "This cannot be undone",
    func: async () => {
      await removeProductSmokerChart(item.id);
      const index = productSmokerCharts.value.findIndex(v => v.id === item.id);
      productSmokerCharts.value.splice(index, 1);

      snackbar.showSuccessSnackbar({
        message: "Product Citizenship has been deleted",
        timeout: 6000
      });
    }
  });
}
async function add() {
  const res = await dialog.showDialog({
    component: markRaw(AddProductSmokerChartDialog),
    productId: id.value,
    carrierId: carrier.value.id
  });
  if (!res.productSmokerChart) return;

  productSmokerCharts.value.push(res.productSmokerChart);
  snackbar.showSuccessSnackbar({
    message: "Smoker Chart has been added",
    timeout: 6000
  });
}
</script>
