<template>
  <v-card flat tile>
    <v-row class="ma-0" align="center">
      <v-col data-testid="name">
        <strong style="font-size: 1.2rem">
          {{ name }}
        </strong>
        <br />
        <template v-if="title"> {{ title }} at </template>
        <router-link :to="getRouterLink('Carrier', carrier.id)">
          {{ carrier.name }}
        </router-link>
      </v-col>
      <v-spacer />
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit
            :id="id"
            type="Personnel"
            :editable="user.isGroupTwoPlus"
            :avatar="avatar"
            :show-avatar="showAvatar"
            @avatar-update="updateAvatar"
            @avatar-error="hideAvatar"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0">
      <card-items :items="cardDetails" />
    </v-row>
  </v-card>
</template>

<script setup>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import CardItems from "@/components/shared/card-items/CardItems.vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { computed, ref } from "vue";
import { usePersonnelView } from "@/stores/personnel-view";
import { formatAddress } from "@/factories/AddressFactory";
import { getRouterLink } from "@/util/helpers";
import { linkItem, textItem } from "@/components/shared/card-items/card-items";

const user = useUserStore();
const personnelView = usePersonnelView();
const {
  id,
  avatar: personnelAvatar,
  carrier,
  department,
  email,
  homeAddress,
  businessAddress,
  phoneWork,
  phoneMobile,
  phoneWorkExtension,
  name,
  title
} = storeToRefs(personnelView);

const avatar = ref(generateAvatarUrl(personnelAvatar.value));
const showAvatar = ref(true);

const cardDetails = computed(() => {
  const details = [];
  if (department.value) {
    details.push(
      textItem({
        title: "Department",
        text: department.value
      })
    );
  }
  if (phoneWork.value) {
    let phone = phoneWork.value;
    if (phoneWorkExtension.value) phone += `x${phoneWorkExtension.value}`;

    details.push(
      linkItem({
        title: "Work Phone",
        text: phone,
        href: `tel:${phone}`,
        dataOutboundId: id.value,
        dataOutboundType: "Personnel",
        dataOutboundNumber: phone
      })
    );
  }

  if (phoneMobile.value) {
    details.push(
      linkItem({
        title: "Mobile Phone",
        text: phoneMobile.value,
        href: `tel:${phoneMobile.value}`,
        dataOutboundId: id.value,
        dataOutboundType: "Personnel",
        dataOutboundNumber: phoneMobile.value
      })
    );
  }

  if (email.value) {
    details.push(
      linkItem({
        title: "Email",
        text: email.value,
        href: `mailto:${email.value}`
      })
    );
  }

  if (homeAddress.value?.id) {
    const text = formatAddress(homeAddress.value);
    if (text)
      details.push(
        textItem({
          title: "Home Address",
          text
        })
      );
  }

  if (businessAddress.value?.id) {
    const text = formatAddress(businessAddress.value);
    if (text)
      details.push(
        textItem({
          title: "Business Address",
          text
        })
      );
  }

  return details;
});

function generateAvatarUrl(uri) {
  const date = new Date();
  return `${uri}?time=${date.getTime()}`;
}
function updateAvatar(newUri) {
  avatar.value = generateAvatarUrl(newUri);
}
function hideAvatar() {
  showAvatar.value = false;
}
</script>
