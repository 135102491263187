import { CALL_DIRECTIONS, trackCall } from "@/api/calls.service";

import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { onMounted } from "vue";

export const useOutboundCallObserver = () => {
  const snackbar = useSnackbarStore();
  function observeAllATags() {
    const observer = new MutationObserver(() => {
      const links = document.querySelectorAll(
        "a[data-outbound-number], a[href^='tel:']"
      );
      links.forEach(link => {
        if (link.__is_outbound_call__) return;
        link.addEventListener("click", e => {
          e.preventDefault();

          let phone = link.href;
          if (link.dataset.outboundNumber) phone = link.dataset.outboundNumber;
          if (phone.startsWith("tel:")) phone = phone.replace("tel:", "");

          outboundCall({
            type: link.dataset.outboundType,
            id: link.dataset.outboundId,
            phone,
            href: link.href
          });
        });

        link.__is_outbound_call__ = true;
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true
    });
  }

  async function outboundCall({ type, id, phone }) {
    try {
      trackCall({
        type,
        id,
        callerNumber: phone,
        direction: CALL_DIRECTIONS.OUTBOUND
      });
    } catch (e) {
      snackbar.showErrorSnackbar({
        message: parseErrorMessage(e)
      });
    }

    if (phone && !window?.Cypress) {
      const a = document.createElement("a");
      a.href = `tel:${phone}`;
      a.click();
    }
  }

  onMounted(() => observeAllATags());
};
