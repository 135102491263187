<template>
  <v-card>
    <v-card-title>Edit {{ name }} </v-card-title>
    <v-card-subtitle>
      Select Case Manager, Case Concierge, and more.
    </v-card-subtitle>
    <v-card-text>
      <v-row dense class="py-3">
        <v-col cols="12">
          <case-manager-search
            v-model="caseManager"
            data-testid="case-manager"
            :success="caseManagerValidation.success"
            :error-messages="caseManagerValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <case-manager-search
            v-model="caseConcierge"
            label="Case Concierge"
            data-testid="case-concierge"
            :success="caseConciergeValidation.success"
            :error-messages="caseConciergeValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <advisor-search
            v-model="annuityWholesaler"
            label="Override Annuity Wholesaler"
            data-testid="annuity-wholesaler"
            employee
            clearable
            :disabled="!isGroupFour"
            :success="annuityWholesalerValidation.success"
            :error-messages="annuityWholesalerValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <advisor-search
            v-model="disabilityWholesaler"
            label="Override Disability Wholesaler"
            data-testid="disability-wholesaler"
            employee
            clearable
            :success="disabilityWholesalerValidation.success"
            :error-messages="disabilityWholesalerValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <advisor-search
            v-model="ltcWholesaler"
            label="Override LTC Wholesaler"
            data-testid="ltc-wholesaler"
            employee
            clearable
            :success="ltcWholesalerValidation.success"
            :error-messages="ltcWholesalerValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        :disabled="saving"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="save-marketing-manager"
        :loading="saving"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CaseManagerSearch from "@/components/shared/CaseManagerSearch.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";

import { updateMarketingManager } from "@/api/marketing-managers.service";

import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

const props = defineProps({
  id: { type: [Number, String], required: true },
  name: { type: String, required: true },
  caseConciergeId: { type: [Number, String], required: true },
  caseConciergeName: { type: String, required: true },
  caseManagerId: { type: [Number, String], required: true },
  caseManagerName: { type: String, required: true },
  annuityWholesalerName: { type: String, required: false, default: null },
  annuityWholesalerId: {
    type: [Number, String],
    required: false,
    default: null
  },
  disabilityWholesalerId: {
    type: [Number, String],
    required: false,
    default: null
  },
  disabilityWholesalerName: { type: String, required: false, default: null },
  ltcWholesalerId: { type: [Number, String], required: false, default: null },
  ltcWholesalerName: { type: String, required: false, default: null }
});
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const user = useUserStore();
const { isGroupFour } = storeToRefs(user);
const caseManager = ref({
  id: props.caseManagerId,
  name: props.caseManagerName
});
const caseConcierge = ref({
  id: props.caseConciergeId,
  name: props.caseConciergeName
});
const annuityWholesaler = ref({
  id: props.annuityWholesalerId,
  name: props.annuityWholesalerName
});
const disabilityWholesaler = ref({
  id: props.disabilityWholesalerId,
  name: props.disabilityWholesalerName
});
const ltcWholesaler = ref({
  id: props.ltcWholesalerId,
  name: props.ltcWholesalerName
});
const saving = ref(false);
const v$ = useVuelidate(
  {
    caseManager: {
      required: v => Boolean(v?.id)
    },
    caseConcierge: {
      required: v => Boolean(v?.id)
    },
    annuityWholesaler: {
      required: v => !isGroupFour.value || Boolean(v?.id) || !v?.id
    },
    disabilityWholesaler: {
      required: v => Boolean(v?.id) || !v?.id
    },
    ltcWholesaler: {
      required: v => Boolean(v?.id) || !v?.id
    }
  },
  {
    caseManager,
    caseConcierge,
    annuityWholesaler,
    disabilityWholesaler,
    ltcWholesaler
  },
  { $autoDirty: true, $scope: false }
);
const caseManagerValidation = computedValidation(v$.value.caseManager, {
  required: "Required"
});
const caseConciergeValidation = computedValidation(v$.value.caseConcierge, {
  required: "Required"
});
const annuityWholesalerValidation = computedValidation(
  v$.value.annuityWholesaler,
  { required: "Required" }
);
const disabilityWholesalerValidation = computedValidation(
  v$.value.disabilityWholesaler,
  {
    required: "Required"
  }
);
const ltcWholesalerValidation = computedValidation(v$.value.ltcWholesaler, {
  required: "Required"
});
async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return false;
  saving.value = true;

  const body = {
    case_manager_id: caseManager.value.id,
    case_concierge_id: caseConcierge.value.id,
    ltc_wholesaler_id: ltcWholesaler.value?.id || null,
    disability_wholesaler_id: disabilityWholesaler.value?.id || null,
    annuity_wholesaler_id: undefined
  };

  if (isGroupFour.value) {
    body.annuity_wholesaler_id = annuityWholesaler.value?.id || null;
  }

  try {
    await updateMarketingManager(props.id, body);
    snackbar.showSuccessSnackbar({
      message: `Successfully updated ${props.name}`
    });
    dialog.closeDialog({ saved: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
