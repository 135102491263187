<template>
  <v-card>
    <v-card-title>Template Picker</v-card-title>
    <v-card-text class="pt-3">
      <v-row dense>
        <v-col cols="12">
          <autocomplete-field
            v-model="selectedTemplate"
            class="mt-3"
            label="Template"
            item-title="name"
            return-object
            data-testid="template-picker"
            v-bind="templateIdValidation"
            :prepend-inner-icon="mdiFileDocument"
            :items="templateItems"
            :loading="loadingTemplates"
            :disabled="creatingNote"
            @update:model-value="updateSelectedTemplate"
          >
            <template #item="{ item, props: templateProps }">
              <v-list-item v-bind="templateProps">
                <v-list-item-title :class="{ 'text-error': item.errors }">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </autocomplete-field>
        </v-col>
        <v-fade-transition mode="out-in">
          <v-col v-if="loadingTemplate" key="loading" cols="12">
            <v-progress-linear indeterminate color="primary" />
          </v-col>
          <v-col
            v-else-if="Boolean(selectedTemplateErrors)"
            key="errors"
            cols="12"
          >
            <v-alert type="error">
              {{ selectedTemplateErrors }}
            </v-alert>
          </v-col>
          <v-col v-else-if="isPDF" key="is-pdf" cols="12">
            <v-card elevation="3">
              <iframe
                sandbox
                data-testid="pdf-viewer"
                :srcdoc="chatTemplateNote.message"
                class="w-100"
                style="min-height: 30rem; border: 0; background-color: white"
              />
            </v-card>
          </v-col>
          <v-col v-else-if="chatTemplateNote.isTemplate" key="wysiwyg">
            <template-editor
              v-model="chatTemplateNote.message"
              class="wysiwyg"
              data-testid="template-editor"
              :template-key="`template-${chatTemplateNote.templateId}`"
              :readonly="creatingNote"
              v-bind="messageValidation"
            />
          </v-col>
          <v-col
            v-else-if="chatTemplateNote.templateId"
            key="text-only"
            cols="12"
          >
            <textarea-field
              v-model="chatTemplateNote.message"
              label="Message"
              data-testid="text-editor"
              v-bind="messageValidation"
              :readonly="creatingNote"
            />
          </v-col>
        </v-fade-transition>
      </v-row>
      <v-row v-if="!isPDF && chatTemplateNote.templateId" dense>
        <v-col v-if="checkboxes && checkboxes.length" cols="12">
          <v-row class="ma-0">
            <checkbox-field
              v-for="checkbox in checkboxes"
              :key="checkbox.key"
              v-model="chatTemplateNote[checkbox.key]"
              class="mr-4"
              hide-details
              :label="checkbox.label"
              :disabled="creatingNote"
            />
          </v-row>
        </v-col>
        <v-col align="center" cols="12">
          <file-drag-and-drop
            v-model="chatTemplateNote.files"
            label="Additional Files"
            multiple
            chips
            :disabled="creatingNote"
            v-bind="fileValidation"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none mr-2"
        variant="outlined"
        :disabled="creatingNote"
        @click="dialog.closeDialog"
      >
        Cancel
      </app-button>
      <app-button
        v-if="isPDF"
        v-bind="pdfProps"
        data-testid="download-pdf"
        class="text-none"
        color="primary"
      >
        Download PDF
      </app-button>
      <app-button
        v-else
        class="text-none"
        color="primary"
        data-testid="create-note"
        :disabled="loadingTemplates || Boolean(selectedTemplateErrors)"
        :loading="creatingNote"
        @click="save"
      >
        Create Note
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import TemplateEditor from "@/components/shared/TemplateEditor.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import { mdiFileDocument } from "@mdi/js";
import { ChatTemplate } from "@/factories/ChatTemplate";
import {
  getAppointmentTemplates,
  getAppointmentTemplate,
  downloadAppointmentTemplateUrl
} from "@/api/appointment.service";
import {
  getCaseTemplates,
  getCaseTemplate,
  downloadCaseTemplateUrl
} from "@/api/cases.service";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps({
  type: {
    type: String,
    required: true,
    validates: v => ["Appointment", "Case"].includes(v)
  },
  checkboxes: { type: Array, required: false, default: () => [] },
  createFunc: { type: Function, required: true },
  identifier: { type: [Number, String], required: true }
});

const { smAndDown } = useDisplay();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const chatTemplateNote = ref({
  message: "",
  files: [],
  sendEmail: false,
  errors: null,
  isTemplate: false,
  pdf: false,
  templateId: null
});

if (props.checkboxes) {
  props.checkboxes.forEach(checkbox => {
    chatTemplateNote.value[checkbox.key] = checkbox.default || false;
  });
}

const selectedTemplate = ref(ChatTemplate());
const templateItems = ref([]);
const loadingTemplates = ref(false);
const loadingTemplate = ref(false);
const creatingNote = ref(false);

let getAllFunc;
let downloadFunc;
let getFunc;
if (props.type === "Appointment") {
  getAllFunc = getAppointmentTemplates;
  downloadFunc = downloadAppointmentTemplateUrl;
  getFunc = getAppointmentTemplate;
} else {
  getAllFunc = getCaseTemplates;
  downloadFunc = downloadCaseTemplateUrl;
  getFunc = getCaseTemplate;
}

const v$ = useVuelidate(
  {
    chatTemplateNote: {
      templateId: {
        required: v => Boolean(v)
      },
      files: {
        size: value => value.every(val => val.size > 0)
      },
      message: {
        required: v => someTextValidator(true, v, 2)
      },
      errors: {
        required: v => !v
      }
    }
  },
  {
    chatTemplateNote
  },

  { $autoDirty: true, $scope: null }
);

const pdfProps = computed(() => {
  if (!chatTemplateNote.value?.pdf) return null;

  if (selectedTemplateErrors.value) return { disabled: true };

  return {
    href: downloadFunc(props.identifier, chatTemplateNote.value.templateId),
    download: true,
    target: smAndDown.value ? "_blank" : "_self"
  };
});
const isPDF = computed(() => chatTemplateNote.value?.pdf);

const selectedTemplateErrors = computed(() => chatTemplateNote.value.errors);

const fileValidation = computedValidation(v$.value.chatTemplateNote.files, {
  size: "Please confirm these files have data or try re-uploading the files"
});

const messageValidation = computedValidation(
  v$.value.chatTemplateNote.message,
  {
    required: "Please enter a message"
  }
);

const templateIdValidation = computedValidation(
  v$.value.chatTemplateNote.templateId,
  {
    required: "Please select a template"
  }
);

async function updateSelectedTemplate() {
  chatTemplateNote.value.message = null;
  chatTemplateNote.value.sendEmail = null;
  chatTemplateNote.value.errors = null;
  chatTemplateNote.value.isTemplate = false;
  chatTemplateNote.value.pdf = false;
  chatTemplateNote.value.templateId = null;

  if (!selectedTemplate.value) return;

  let template = selectedTemplate.value;
  chatTemplateNote.value.errors = template.errors;
  chatTemplateNote.value.templateId = template.id;

  if (template.errors) return;

  template = await getTemplate(selectedTemplate.value.id);
  if (!template) return;

  chatTemplateNote.value.message = template.templateContents;
  chatTemplateNote.value.sendEmail = Boolean(template.email);
  chatTemplateNote.value.errors = template.errors;
  chatTemplateNote.value.isTemplate = !template.isTextOnly;
  chatTemplateNote.value.pdf = template.pdf;
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid || creatingNote.value) return;

  try {
    creatingNote.value = true;
    await props.createFunc(chatTemplateNote.value);
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    creatingNote.value = false;
  }
}

async function getTemplates() {
  loadingTemplates.value = true;

  try {
    templateItems.value = await getAllFunc(props.identifier);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingTemplates.value = false;
  }
}

async function getTemplate(templateId) {
  loadingTemplate.value = true;

  let template;
  try {
    template = await getFunc(props.identifier, templateId);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingTemplate.value = false;
  }
  return template;
}

getTemplates();
</script>
