<template>
  <autocomplete-field
    v-bind="$attrs"
    ref="inputSearch"
    v-model="item"
    v-model:search="searchText"
    autocomplete="false"
    return-object
    no-filter
    hide-no-data
    :clearable="clearable"
    :prepend-inner-icon="mdiAccount"
    :data-testid="dataTestid"
    :hint="$slots.message ? ' ' : null"
    :persistent-hint="Boolean($slots.message)"
    :item-title="itemTextGenerator"
    :items="items"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
    @click:clear="emit('update:model-value', {})"
  >
    <template #message="{ message }">
      <v-row class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
    </template>

    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { agentSearch } from "@/api/search.service";
import { appointmentManagerSearch } from "@/api/agents.service";
import { mdiAccount } from "@mdi/js";
import { ref, toRef, unref, watch } from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

const props = defineProps({
  includeAssignable: Boolean,
  appointmentManager: Boolean,
  modelValue: { type: [Object, String], default: null },
  betterReturn: Boolean,
  dataTestid: { type: String, default: null },
  clearable: Boolean
});

const modelValue = toRef(props, "modelValue");

const emit = defineEmits(["update:model-value", "blur"]);

const snackbar = useSnackbarStore();

const item = ref(null);
const items = ref([]);
const searchText = ref(null);
const loading = ref(false);
const inputSearch = ref(null); // templateref

if (modelValue.value?.id) {
  items.value.push(unref(modelValue));
  item.value = unref(modelValue);
}

function itemTextGenerator(val) {
  if (!val?.email) return val?.name;
  return `${val.name} · ${val.email}`;
}

function focus() {
  if (!inputSearch.value?.$el) return;
  const input = inputSearch.value.$el.querySelector("input");
  if (!input?.focus) return;
  input.focus();
}

let timer;
function debounceAndSearch() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(searchForAgent, 200);
}

async function searchForAgent() {
  if (!searchText.value) return;

  if (
    items.value.some(val => itemTextGenerator(val) === searchText.value) &&
    items.value === 1
  )
    return;

  if (itemTextGenerator(item.value) === searchText.value) return;
  loading.value = true;
  let func = v => agentSearch(v, props.includeAssignable);
  if (props.appointmentManager) func = appointmentManagerSearch;

  try {
    const response = await func(searchText.value);

    items.value.splice(0, items.value.length);
    let sorted = response.toSorted((a, b) => a.name.localeCompare(b.name));
    if (props.betterReturn) {
      sorted = sorted.map(a => {
        let assignable = a?.assignable;
        if (assignable) {
          assignable = {
            ...assignable,
            type: assignable?.type?.model || assignable?.type
          };
        }
        return {
          ...a,
          assignable,
          type: a?.type?.model || a?.type
        };
      });
    }

    items.value.push(...sorted);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

watch(item, value => {
  if (!value?.id && !props.clearable) return;
  if (value === props.value) return;
  emit("update:model-value", value);
});

watch(
  () => props.modelValue,
  value => {
    items.value.splice(0, items.value.length);
    if (value?.id) {
      items.value.push(value);
      item.value = value;
    } else {
      item.value = null;
    }
  }
);

watch(searchText, debounceAndSearch);

defineExpose({ focus });
</script>
