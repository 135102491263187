<template>
  <div v-if="saving || saved || error" data-testid="saving-indicator">
    <v-fade-transition mode="out-in">
      <v-progress-circular
        v-if="saving"
        key="saving"
        indeterminate
        :size="small ? 16 : 24"
        width="2"
      />
      <v-icon
        v-else-if="saved"
        key="saved"
        color="success"
        :icon="mdiCheckboxMarkedCircleOutline"
        :size="small ? 16 : 24"
      />
      <v-tooltip v-else-if="error" key="error" location="bottom">
        <template #activator="{ props: templateProps }">
          <div v-bind="templateProps">
            <v-icon
              color="error"
              :size="small ? 16 : 24"
              :icon="mdiAlertCircleOutline"
            />
          </div>
        </template>
        <span>Save failed because {{ errorMessage.substr(0, 200) }} </span>
      </v-tooltip>
    </v-fade-transition>
  </div>
</template>

<script>
import { mdiCheckboxMarkedCircleOutline, mdiAlertCircleOutline } from "@mdi/js";
export default {
  props: {
    controller: { type: Object, required: true },
    small: Boolean
  },
  data: () => ({ mdiCheckboxMarkedCircleOutline, mdiAlertCircleOutline }),
  computed: {
    saved() {
      return this.controller?.saved;
    },
    saving() {
      return this.controller?.saving;
    },
    error() {
      return this.controller?.error;
    },
    errorMessage() {
      return typeof this.controller?.errorMessage === "string"
        ? this.controller.errorMessage
        : "";
    }
  }
};
</script>
