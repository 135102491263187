<template>
  <img
    src="https://portal-pictures.back9ins.com/BOSS-Logo.png"
    :style="{ 'max-width': `${maxWidth}px` }"
    style="object-fit: contain; width: 100%"
  />
</template>
<script setup>
import { computed } from "vue";
const props = defineProps({
  large: Boolean
});

const maxWidth = computed(() => (props.large ? 125 : 85));
</script>
