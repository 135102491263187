<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="support-tickets-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row class="ma-0 pa-3" align="center">
        <h1 class="text-h5">Support Tickets</h1>
        <v-spacer />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiPlusCircle"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="support-tickets-table-create"
              @click="showNeedHelpDialog"
            />
          </template>
          <span>Create</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="support-tickets-table-refresh"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.description`]="{ item }">
      <router-link
        :to="{ name: 'SupportTicketView', params: { id: item.additional.id } }"
      >
        {{ item.description }}
      </router-link>
    </template>
    <template #[`item.createdAt`]="{ item }">
      {{ timestampFormatter(item.createdAt, null, "date-time") }}
    </template>
    <template #[`item.completedAt`]="{ item }">
      {{
        item.completedAt
          ? timestampFormatter(item.completedAt, null, "date-time")
          : ""
      }}
    </template>
    <template #[`item.ownable`]="{ item }">
      <router-link :to="getRouterLink(item.ownable.type, item.ownable.id)">
        {{ item.ownable.name }}
      </router-link>
    </template>
    <template #[`item.assignable`]="{ item }">
      <router-link
        :to="getRouterLink(item.assignable.type, item.assignable.id)"
      >
        {{ item.assignable.name }}
      </router-link>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import CreateSupportTicketDialog from "@/components/support-tickets/CreateSupportTicketDialog.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import {
  parseErrorMessage,
  timestampFormatter,
  getRouterLink
} from "@/util/helpers";
import { getSupportTickets } from "@/api/support-tickets.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { mdiPlusCircle, mdiRefresh } from "@mdi/js";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";

useHead({ title: "Support Tickets" });

const user = useUserStore();
const isEmployee = user.loginable.is_employee;
const emit = defineEmits(["total", "loading"]);
const dialog = useDialogStore();

const table = useTable({
  headers: [
    user.isGroupTwoPlus
      ? new TableHeader({
          text: "View",
          value: "view",
          map: "view",
          selectableOptions: [
            { title: "All Support Tickets", value: "all" },
            { title: "My Support Tickets", value: "my" }
          ],
          sortFilterMap: "view",
          filterOrder: 1,
          ...TableHeader.IS_SELECT_FILTER_TYPE,
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_ADDITIONAL,
          ...TableHeader.IS_QUERY_FILTER,
          ...TableHeader.IS_MANDATORY
        })
      : null,
    new TableHeader({
      text: "Description",
      value: "description",
      map: "description",
      sortFilterMap: "description",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    isEmployee
      ? new TableHeader({
          text: "Created By",
          value: "ownable",
          map: "ownable",
          sortFilterMap: [
            { key: "ownable_id", value: "id" },
            { key: "ownable_type", value: "type" }
          ],
          width: "200px",
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null,

    isEmployee
      ? new TableHeader({
          text: "Assigned To",
          value: "assignable",
          map: "assignable",
          width: "200px",
          sortFilterMap: [
            { key: "assignable_id", value: "id" },
            { key: "assignable_type", value: "type" }
          ],
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
          ...TableHeader.IS_QUERY_FILTER
        })
      : null,

    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "created_at",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Is Incomplete",
      value: "isIncomplete",
      map: "isIncomplete",
      sortFilterMap: "incomplete",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    })
  ].filter(Boolean),
  shouldIncludeCancelToken: true,
  getData: getSupportTickets,
  options: TableOptions({ sortBy: [{ key: "createdAt", order: "desc" }] })
});

if (user.isGroupTwoPlus) {
  table.filter.value.view = "my";
}
table.filter.value.isIncomplete = true;

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

let hasEmitted = false;
async function getData() {
  try {
    if (!hasEmitted) emit("loading", true);
    await table.getData();
    if (!hasEmitted) {
      emit("total", table.meta.value.total);
    }
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    if (!hasEmitted) {
      emit("loading", false);
      hasEmitted = true;
    }
  }
}

function showNeedHelpDialog() {
  dialog.showDialog({
    component: markRaw(CreateSupportTicketDialog)
  });
}
</script>
