<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    data-testid="approved-domain-products"
    :headers="table.tableHeaders.value"
    :items="items"
    :mobile="null"
    mobile-breakpoint="sm"
  >
    <template #top>
      <div class="pa-3">
        <table-filter
          :model-value="table.filter.value"
          :headers="table.filterHeaders.value"
          @update="updateFilter"
        />
      </div>
    </template>
    <template #[`item.categories`]="{ item }">
      {{ listToSentence(item.categories) }}
    </template>
  </v-data-table>
</template>

<script setup>
import TableFilter from "@/components/shared/data-table/TableFilter.vue";

import { listToSentence } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";

const approvedDomain = useApprovedDomainView();

const { products } = storeToRefs(approvedDomain);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Product Name",
      value: "name",
      sortFilterMap: "name",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      sortFilterMap: "carrierName",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      selectableOptions: getAvailableCarriers()
    }),
    new TableHeader({
      text: "Categories",
      value: "categories",
      sortFilterMap: "categories",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      selectableOptions: getAvailableCategories()
    })
  ],
  options: TableOptions({ sortBy: [{ key: "carrierName", order: "asc" }] })
});

function getAvailableCategories() {
  const categories = {};
  products.value.forEach(p => {
    p.categories.forEach(c => {
      if (categories[c]) return;
      categories[c] = true;
    });
  });
  const allCategories = Object.keys(categories);
  allCategories.sort((a, b) => a.localeCompare(b));
  return allCategories;
}

function getAvailableCarriers() {
  const carriers = {};
  products.value.forEach(p => {
    if (carriers[p.carrierName]) return;
    carriers[p.carrierName] = true;
  });
  const allCarriers = Object.keys(carriers);
  allCarriers.sort((a, b) => a.localeCompare(b));
  return allCarriers;
}

const items = computed(() => {
  const carriers = {};
  const filterCarriers = Boolean(table.filter.value.carrierName?.length);
  if (filterCarriers) {
    table.filter.value.carrierName.forEach(c => (carriers[c] = true));
  }

  const categories = {};
  const filterCategories = Boolean(table.filter.value.categories?.length);
  if (filterCategories) {
    table.filter.value.categories.forEach(c => (categories[c] = true));
  }

  const filterName = Boolean(table.filter.value.name?.length);
  let loweredName = "";
  if (filterName) {
    loweredName = table.filter.value.name.toLowerCase();
  }

  return products.value.filter(item => {
    if (filterCarriers) {
      if (!carriers[item.carrierName]) return false;
    }
    if (filterCategories) {
      if (!item.categories.some(c => categories[c])) return false;
    }
    if (filterName) {
      if (!item.name.toLowerCase().includes(loweredName)) return false;
    }

    return true;
  });
});

function updateFilter(filter) {
  table.filter.value = filter;
}
</script>
