<template>
  <v-card>
    <v-card-title v-if="type === 'document'">Add a Document</v-card-title>
    <v-card-title v-else-if="isLinkDialog">Add a Link</v-card-title>
    <v-card-title v-else>Add an Image</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <text-field
            v-if="showHref"
            v-model="href"
            data-lpignore="true"
            persistent-hint
            hint=" "
            data-testid="creator-image-dialog-href"
            :label="isLinkDialog ? 'URL' : 'Image URL'"
            :prepend-inner-icon="mdiLink"
          >
            <template v-if="!isLinkDialog" #message="{ message }">
              <v-row class="ma-0">
                {{ message }}
                <v-spacer />
                <a v-if="!uploadingImage" @click="showHref = false"
                  >Need to Upload an Image?</a
                >
              </v-row>
            </template>
          </text-field>
          <file-drag-and-drop
            v-else
            v-model="file"
            :loading="uploadingImage"
            :accept="uploadTypes"
            :error-messages="invalidFile"
            @update:model-value="uploadImageToS3"
          >
            <template #message>
              <a @click="showHref = true">
                <template v-if="type === 'document'">
                  Already have the File URL?
                </template>
                <template v-else> Already have the Image URL? </template>
              </a>
            </template>
          </file-drag-and-drop>
        </v-col>
        <v-col cols="12">
          <text-field
            v-model="text"
            data-testid="creator-image-dialog-text"
            data-lpignore="true"
            :label="isLinkDialog ? 'Text' : 'Alt Text'"
            :prepend-inner-icon="isLinkDialog ? mdiText : mdiImageText"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :disabled="uploadingImage"
        @click="closeDialog"
      >
        Close
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="creator-image-dialog-insert"
        :disabled="uploadingImage"
        @click="insertHref"
      >
        Insert Link
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiLink, mdiText, mdiImageText } from "@mdi/js";
export default {
  name: "CreatorImageDialog",
  components: { FileDragAndDrop },
  props: {
    type: {
      type: String,
      validator: v => ["image", "link", "document"].includes(v),
      default: "link"
    },
    articleImageUploadFunc: { type: Function, required: true }
  },
  data() {
    return {
      text: "",
      href: "",
      file: null,
      showHref: this.type === "link",
      uploadingImage: false,
      timer: null,
      mdiLink,
      mdiText,
      mdiImageText
    };
  },
  computed: {
    invalidFile() {
      if (this.file?.size === 0)
        return [
          "Please confirm this file has data or try Re-uploading this file"
        ];
      return [];
    },
    isLinkDialog() {
      return ["document", "link"].includes(this.type);
    },
    uploadTypes() {
      return this.type === "image"
        ? ".apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp"
        : "";
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    insertHref() {
      if (!this.text || !this.href) return;
      let suffix = "";
      if (!this.isLinkDialog) suffix = "!";
      this.closeDialog({
        markdown: `${suffix}[${this.text}](${this.href})`
      });
    },

    uploadImageToS3() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.showHref || !this.file || this.invalidFile.length) return;
        this.uploadingImage = true;
        this.articleImageUploadFunc(this.file)
          .then(res => {
            this.file = null;
            this.href = res;
            this.showHref = true;
          })
          .catch(e => {
            this.showErrorSnackbar({
              message: "Failed to Upload a Document" + parseErrorMessage(e)
            });
          })
          .finally(() => {
            this.uploadingImage = false;
          });
      }, 20);
    }
  }
};
</script>
