<template>
  <v-card v-cloak>
    <v-card-title>Change Avatar</v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col cols="12">
          <file-drag-and-drop
            v-model="file"
            data-testid="avatar-image"
            class="pt-0"
            label="Avatar Image"
            accept=".png, .jpg, .jpeg, .svg, .webp"
            :success="fileValidation.success"
            :error-messages="fileValidation.errorMessages"
          />
        </v-col>
        <v-col v-if="checkboxText" cols="12">
          <div class="checkbox-width">
            <checkbox-field v-model="checkboxChecked" :label="checkboxText" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="checkboxText" class="px-6">
      <v-spacer />
      <app-button
        class="text-none"
        color="primary"
        :loading="loading"
        :disabled="deletingAvatar"
        @click="uploadAvatar"
      >
        Upload
      </app-button>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :loading="loading"
        :disabled="deletingAvatar"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        v-if="deletable"
        class="text-none"
        variant="outlined"
        :disabled="loading"
        :loading="deletingAvatar"
        color="error"
        data-testid="change-avatar-delete"
        @click="deleteAvatar"
      >
        Delete Avatar
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="change-avatar-confirm"
        :loading="loading"
        :disabled="deletingAvatar"
        @click="uploadAvatar"
      >
        Upload
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import {
  uploadAgencyAvatar,
  useAgencyOwnersAvatar
} from "@/api/agencies.service";
import { uploadAgentAvatar, useAgentsOwnersAvatar } from "@/api/agents.service";
import { uploadCarrierAvatar } from "@/api/carrier.service";
import { uploadPersonnelAvatar } from "@/api/personnel.service";
import {
  uploadApprovedDomainAvatar,
  deleteApprovedDomainAvatar
} from "@/api/approved-domain.service";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  id: { type: Number, required: true },
  deletable: Boolean,
  type: { type: String, required: true },
  checkboxText: { type: String, default: null }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const file = ref(null);
const loading = ref(false);
const deletingAvatar = ref(false);
const checkboxChecked = ref(false);

const v$ = useVuelidate(
  {
    file: {
      required: false,
      validSize: val => {
        return val?.size > 0;
      }
    }
  },
  {
    file
  },
  { $autoDirty: true, $scope: null }
);
const fileValidation = computedValidation(v$.value.file, {
  required: "Required",
  validSize: "Please confirm this file has data or try re-uploading the file"
});

async function uploadAvatar() {
  if (loading.value || deletingAvatar.value) return;
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  loading.value = true;
  let func = () => {};
  if (props.type === "ApprovedDomain") {
    func = uploadApprovedDomainAvatar;
  } else if (props.type === "Agent") {
    func = uploadAgentAvatar;
  } else if (props.type === "Agency") {
    func = uploadAgencyAvatar;
  } else if (props.type === "Carrier") {
    func = uploadCarrierAvatar;
  } else if (props.type === "Personnel") {
    func = uploadPersonnelAvatar;
  } else {
    throw Error("Unimplemented Type in ChangeAvatarDialog");
  }

  const promises = [func(props.id, file.value)];
  if (checkboxChecked.value && props.checkboxText) {
    promises.push(useOwnersAvatar());
  }
  try {
    const response = await Promise.all(promises);
    dialog.closeDialog({ updated: true, newUrl: response[0].data.url });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function useOwnersAvatar() {
  let func = useAgentsOwnersAvatar;
  let root = "agent";
  if (props.type === "Agency") {
    func = useAgencyOwnersAvatar;
    root = "agency";
  }

  return func(props.id, { [root]: { use_owners_avatar: true } });
}

async function deleteAvatar() {
  if (deletingAvatar.value || loading.value) return;

  deletingAvatar.value = true;
  let func = () => {};
  if (props.type === "ApprovedDomain") {
    func = deleteApprovedDomainAvatar;
  } else {
    throw Error("Unimplemented Type in ChangeAvatarDialog");
  }

  try {
    await func(props.id);
    dialog.closeDialog({ deleted: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    deletingAvatar.value = false;
  }
}
</script>
