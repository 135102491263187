<template>
  <v-card>
    <v-card-title> Template Editor </v-card-title>
    <v-card-subtitle> Click to edit text </v-card-subtitle>
    <v-card-text>
      <div
        id="clickable"
        class="rounded"
        style="
          overflow-y: scroll;
          position: relative;
          outline: 1px solid grey;
          line-height: 0 !important;
        "
        :style="{ height: height + 'px' }"
      >
        <v-card
          style="position: absolute; z-index: 1001"
          :style="{
            display: activeText.show ? 'block' : 'none',
            top: activeText.top + 'px',
            left: activeText.left + 'px',
            width: activeText.width + 'px'
          }"
        >
          <textarea-field
            id="activeText"
            v-model="activeText.value"
            data-testid="template-editor-active-text"
            dense
            solo
            rows="1"
            auto-grow
            hide-details
            autofocus
            :disabled="!activeText.show"
            :label="activeText.show ? 'Text' : 'Click text to replace'"
            @update:model-value="handleTextChange"
            @blur="hideActiveText"
          />
        </v-card>
        <iframe
          id="preview"
          class="h-100 w-100"
          style="border: 0; z-index: 999"
          sandbox="allow-same-origin"
          :srcdoc="preview"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
// Template Editor is a temporary workaround for keeping a strict CSP + utilizing a WYSIWYG.
// The code it's iframing is held in https://github.com/back9ins/boss-widget-wysiwyg
import { uuidv4 } from "@/util/helpers";
import { onMounted, ref } from "vue";

const emit = defineEmits(["update:model-value"]);
const props = defineProps({
  modelValue: { type: String, default: null },
  templateKey: { type: String, default: null },
  success: Boolean,
  errorMessages: { type: Array, default: () => [] },
  id: {
    type: String,
    default: "editor"
  }
});

const values = {};
let root;
const preview = ref("");
const activeText = ref({
  value: "",
  show: false,
  key: "",
  top: 0,
  left: 0,
  width: 0
});
const height = ref(0);

onMounted(() => {
  const parser = new DOMParser();
  root = parser.parseFromString(props.modelValue, "text/html");
  recurse(root.querySelector("body"));
  renderPreview();
});

function recurse(element) {
  if (element.childNodes.length > 0) {
    for (let i = 0; i < element.childNodes.length; i++) {
      recurse(element.childNodes[i]);
    }
  }
  if (element.nodeType == Node.TEXT_NODE && element.nodeValue.trim() != "") {
    const id = "a" + uuidv4();
    const span = document.createElement("span");
    span.setAttribute("data-replacer", id);
    span.textContent = element.nodeValue;
    element.parentNode.replaceChild(span, element);
    values[id] = {
      text: element.nodeValue,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 0,
      height: 0
    };
  }
}

let timer;
function handleTextChange() {
  if (timer) clearTimeout(timer);
  if (!activeText.value.key) return;
  values[activeText.value.key].text = activeText.value.value;
  emitInput();

  // debounce preview to reduce iframe flickers
  timer = setTimeout(() => {
    renderPreview();
  }, 500);
}

function emitInput() {
  const xmlSerializer = new XMLSerializer();
  const liveClone = root.cloneNode(true);
  const liveSpans = liveClone.querySelectorAll("span[data-replacer]");
  liveSpans.forEach(span => {
    const textNode = document.createTextNode(
      values[span.getAttribute("data-replacer")].text
    );
    span.parentNode.replaceChild(textNode, span);
  });
  emit("update:model-value", xmlSerializer.serializeToString(liveClone).trim());
}

function renderPreview() {
  const spans = root.querySelectorAll("span[data-replacer]");
  spans.forEach(span => {
    span.textContent = values[span.getAttribute("data-replacer")].text;
  });
  const xmlSerializer = new XMLSerializer();
  preview.value = xmlSerializer.serializeToString(root);

  setTimeout(() => {
    setHelpers();
  }, 100);
}

function setHelpers() {
  calculateHeight();
  generateOffsets();
}

function calculateHeight() {
  const frame = document.getElementById("preview");
  if (!frame) return;
  const body = frame.contentWindow.document.querySelector("body");
  const html = frame.contentWindow.document.querySelector("html");

  let newHeight;
  if (body) {
    newHeight = Math.max(body.scrollHeight, body.offsetHeight);
  }

  if (html) {
    newHeight = Math.max(
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
      newHeight
    );
  }

  if (newHeight) height.value = newHeight;
}

function generateOffsets() {
  const frame = document.getElementById("preview");
  if (!frame) return;
  const spans = frame.contentWindow.document.body.querySelectorAll(
    "span[data-replacer]"
  );

  const clickable = document.getElementById("clickable");
  spans.forEach(span => {
    const rect = span.getBoundingClientRect();
    if (!rect) return;
    const id = span.getAttribute("data-replacer");
    values[id].top = rect.top;
    values[id].left = rect.left;
    values[id].right = rect.right;
    values[id].bottom = rect.bottom;
    values[id].width = rect.width;
    values[id].height = rect.height;
    setTimeout(() => {
      let div = document.getElementById(id);
      const creatingDiv = !div;

      if (creatingDiv) {
        div = document.createElement("div");
        div.id = id;
        div.style.position = "absolute";
        div.classList.add("clickable");
        div.setAttribute("data-testid", "template-editor-clickable");
        div.style.zIndex = 1000;
        div.tabIndex = 0;
        div.onclick = () => updateActiveTextPosition(id);
      }

      div.style.top = rect.top + "px";
      div.style.left = rect.left + "px";
      div.style.height = rect.height + "px";
      div.style.width = rect.width + "px";

      if (creatingDiv) clickable.appendChild(div);
    });
  });
}

function hideActiveText() {
  activeText.value.show = false;
  document.getElementById(activeText.value.key).classList.remove("focused");
}

function updateActiveTextPosition(id) {
  const el = document.getElementById(id);
  activeText.value.key = id;
  activeText.value.value = values[id].text;
  activeText.value.show = true;
  activeText.value.top = el.offsetTop + el.offsetHeight;
  activeText.value.left = el.offsetLeft;
  activeText.value.width = Math.max(el.offsetWidth, 200);
  document.getElementById(activeText.value.key).classList.add("focused");
  document.querySelector("#activeText").focus();
}
</script>
