import { getHttpClient } from "@/http-client";
import {
  setCustomChatTemplateFromRequest,
  setCustomChatTemplateTableItemFromRequest
} from "@/factories/CustomChatTemplate";

const baseUrl = "/api/boss/chat-templates";

export async function getCustomChatTemplates(params) {
  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.chat_templates.map(setCustomChatTemplateTableItemFromRequest);
}

export async function getCustomChatTemplate(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setCustomChatTemplateFromRequest(data.chat_template);
}

export function deleteCustomChatTemplate(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export async function createCustomChatTemplate(body) {
  const { data } = await getHttpClient().post(baseUrl, { chat_template: body });

  return setCustomChatTemplateFromRequest(data.chat_template);
}

export async function updateCustomChatTemplate(id, body) {
  const { data } = await getHttpClient().put(`${baseUrl}/${id}`, {
    chat_template: body
  });

  return setCustomChatTemplateFromRequest(data.chat_template);
}

export function sendCustomChatTemplate(id, body) {
  return getHttpClient().post(`${baseUrl}/${id}/email`, {
    chat_template: body
  });
}

export function previewCustomChatTemplate(id) {
  return getHttpClient().post(`${baseUrl}/${id}/email-sample-preview`);
}

export function previewLiveCustomChatTemplate(id, body) {
  return getHttpClient().post(`${baseUrl}/${id}/email-live-preview`, {
    chat_template: body
  });
}

export async function getHydratedCustomChatTemplate(id, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${id}/render-partially-live-preview`,
    {
      chat_template: body
    }
  );
  return setCustomChatTemplateFromRequest(data.chat_template);
}
