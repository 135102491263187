export const WEBHOOK_TYPES = {
  EAPP: "boss_electronic_applications",
  CASE: "boss_cases"
};

export function Webhook(model = {}) {
  return {
    createdAt: model?.createdAt || null,
    deletedAt: model?.deletedAt || null,
    id: model?.id || null,
    key: model?.key || null,
    ownableId: model?.ownableId || null,
    ownableType: model?.ownableType || null,
    updatedAt: model?.updatedAt || null,
    url: model?.url || null
  };
}

export function EAppWebhook() {
  return Webhook({ key: WEBHOOK_TYPES.EAPP });
}

export function CaseWebhook() {
  return Webhook({ key: WEBHOOK_TYPES.CASE });
}

export function setWebhookFromRequest(raw = {}) {
  const webhook = Webhook();

  webhook.authenticationKey = raw?.authentication_key;
  webhook.createdAt = raw?.created_at;
  webhook.deletedAt = raw?.deleted_at;
  webhook.id = raw?.id;
  webhook.key = raw?.key;
  webhook.ownableId = raw?.ownable_id;
  webhook.ownableType = raw?.ownable_type;
  webhook.updatedAt = raw?.updated_at;
  webhook.url = raw?.url.replace("https://", "").replace("http://", "");

  return webhook;
}

export function WebhookToCreateRequest(model = {}) {
  return {
    key: model.key,
    url: `https://${model.url}`
  };
}

export function WebhookToUpdateRequest(model = {}) {
  return {
    url: `https://${model.url}`
  };
}

export function RawWebhooksToObj(rawWebhooks) {
  const webhookObj = {};
  Object.values(WEBHOOK_TYPES).forEach(value => {
    webhookObj[value] = Webhook({ key: value });
  });

  rawWebhooks.forEach(rawWebhook => {
    const webhook = setWebhookFromRequest(rawWebhook);
    webhookObj[webhook.key] = webhook;
  });
  return webhookObj;
}
