<template>
  <autocomplete-field
    v-model="audience"
    v-model:search="search"
    autocomplete="false"
    :prepend-inner-icon="mdiDomain"
    item-title="displayText"
    return-object
    no-filter
    hide-no-data
    :placeholder="placeholder"
    :label="label"
    :hide-details="hideDetails"
    :success="success"
    :error-messages="errorMessages"
    :items="audiences"
    :loading="loading"
    :disabled="disabled"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
  />
</template>

<script>
import { audienceSearch } from "@/api/search.service";
import { mdiDomain } from "@mdi/js";

export default {
  props: {
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    carrierId: { type: Number, default: null },
    modelValue: { type: Object, default: () => {} },
    hideDetails: Boolean,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: "Search Audiences",
      required: false
    },
    label: {
      type: String,
      default: "Audience",
      required: false
    }
  },
  emits: ["update:model-value", "blur"],
  data() {
    let audiences = [];
    let audience = null;
    if (this.modelValue && Object.keys(this.modelValue).length) {
      audiences = [this.modelValue];
      audience = this.modelValue;
    }
    return {
      search: "",
      audienceTimer: null,
      loading: false,
      audiences,
      audience,
      mdiDomain
    };
  },
  watch: {
    modelValue(value) {
      if (!value || !Object.keys(value).length) {
        this.audience = null;
        this.audiences = [];
        return;
      }
      this.audience = value;
      this.audiences = [value];
    },
    audience(value) {
      this.$emit("update:model-value", value);
    },
    search(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.audiences.some(val => val?.name === value) &&
        this.audiences.length === 1
      ) {
        return;
      }

      if (this.audienceTimer) {
        clearTimeout(this.audienceTimer);
      }

      this.audienceTimer = setTimeout(() => {
        this.loading = true;
        audienceSearch(this.carrierId, value)
          .then(response => {
            const items = [...response].map(a => ({
              ...a,
              displayText: [a.name, a.email].filter(Boolean).join(" · ")
            }));
            items.sort((a, b) => a.name.localeCompare(b.name));
            this.audiences = items;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  }
};
</script>
