import { getHttpClient } from "@/http-client";
import { camelOrPascalToSnake } from "@/util/helpers";
import { getRouterLink } from "@/util/helpers.js";
const baseURL = "api/boss/calls";

function InboundCallResultItem(model = {}) {
  return {
    id: model?.id,
    displayType: model?.displayType,
    type: model?.type,
    name: model?.name,
    callableType: model?.callableType,
    callableId: model?.callableId,
    phones: model?.phones,
    routerLink: model?.routerLink,
    uniqueId: model?.uniqueId
  };
}

function getDisplayType(type) {
  return {
    ContractParty: "Contract Party",
    ElectronicApplication: "eApp",
    Agent: "Agent"
  }[type];
}

function setInboundCallResultItemFromRequest(req = {}) {
  const item = InboundCallResultItem();

  item.id = req.id;
  item.name = req.name;
  item.type = req.type;
  item.callableType = req.callable_type;
  item.callableId = req.callable_id;
  item.phones = [req.phone_mobile, req.phone_work].filter(Boolean).join(" | ");
  item.displayType = getDisplayType(req.type);
  item.routerLink = getRouterLink(req.type, req.id);
  item.uniqueId = `${req.id}-${req.type}`;

  return item;
}

export async function inboundCallSearch(phoneNumber, include = []) {
  const params = new URLSearchParams();
  params.append("number", phoneNumber);

  include.forEach(category => params.append(category, true));

  const { data } = await getHttpClient().get(`${baseURL}/inbound_call_search`, {
    params
  });

  return data.map(setInboundCallResultItemFromRequest);
}

export const CALL_DIRECTIONS = {
  OUTBOUND: "outbound",
  INBOUND: "inbound"
};

export async function trackCall({ type, id, direction, callerNumber }) {
  let body = {
    call: {
      direction,
      caller_number: callerNumber
    }
  };
  if (type) body.call[camelOrPascalToSnake(type) + "_id"] = +id;
  return getHttpClient().post(baseURL, body);
}
