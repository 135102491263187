<template>
  <text-field
    v-model="crd"
    data-lpignore="true"
    :prepend-inner-icon="mdiPound"
    class="no-tick"
    label="CRD Number"
    type="text"
    inputmode="numeric"
    :error-messages="errorMessages"
    :success="success"
    persistent-hint
    hint=" "
    @blur="$emit('blur')"
  >
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <a href="https://brokercheck.finra.org/" target="_blank">
          Don't know your CRD Number?
        </a>
      </v-row>
    </template>
    <template v-if="hasAppendOuterSlot" #append-inner>
      <slot name="append-inner" />
    </template>
  </text-field>
</template>

<script>
import { mdiPound } from "@mdi/js";
export default {
  props: {
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    modelValue: { type: [String, Number], default: null }
  },
  emits: ["blur", "update:model-value"],
  data() {
    return { crd: this.modelValue || null, mdiPound };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append"];
    }
  },
  watch: {
    crd(value) {
      this.$emit("update:model-value", value);
    }
  }
};
</script>
