export const BOSS_VIDEOS = {
  WELCOME: {
    url: "https://player.vimeo.com/video/759706298",
    title: "BOSS Welcome Video"
  },
  CONTRACTING: {
    url: "https://player.vimeo.com/video/838831382",
    title: "BOSS Contracting"
  }
};

export const QUOTE_AND_APPLY_VIDEOS = {
  AGENT_COMMERCIAL: {
    url: "https://player.vimeo.com/video/273972530",
    title: "Quote & Apply™ Commercial for Agents"
  },
  CLIENT_COMMERCIAL: {
    url: "https://player.vimeo.com/video/201049857",
    title: "Quote & Apply™ Commercial for Clients"
  },
  EAPPLICATION_PROCESS: {
    url: "https://player.vimeo.com/video/838837911",
    title: "Quote & Apply™ eApplication Process"
  }
};
