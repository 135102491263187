<template>
  <v-card flat tile>
    <v-row class="ma-0" align="center">
      <v-col data-testid="name">
        <strong style="font-size: 1.2rem">{{ payor.name }}</strong>
      </v-col>
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit :avatar="payor.avatar" :editable="false" />
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <card-items :items="cardDetails" />
  </v-card>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";

import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import { computed } from "vue";
import { textItem } from "@/components/shared/card-items/card-items";
import { useStatementView } from "@/stores/statement-view";
import { storeToRefs } from "pinia";
import { currencyFormat, timestampFormatter } from "@/util/helpers";

const statementView = useStatementView();

const {
  statementDate,
  statementType,
  premium,
  premiumSum,
  amount,
  commissionSum,
  status,
  payor
} = storeToRefs(statementView);

const cardDetails = computed(() => {
  return [
    textItem({
      title: "Statement Date",
      text: timestampFormatter(statementDate.value, "sole-day")
    }),
    textItem({
      title: "Statement Type",
      text: statementType.value
    }),
    textItem({
      title: "Stated Premium",
      text: currencyFormat(premium.value, 2)
    }),
    textItem({
      title: "Payments Premium Sum",
      text: currencyFormat(premiumSum.value, 2)
    }),
    textItem({
      title: "Stated Commission",
      text: currencyFormat(amount.value, 2)
    }),
    textItem({
      title: "Payments Commission Sum",
      text: currencyFormat(commissionSum.value, 2)
    }),
    textItem({
      title: "Status",
      text: status.value
    })
  ];
});
</script>
