<template>
  <div>
    <app-button
      :disabled="!canGoPrevious"
      class="text-none mr-1"
      variant="text"
      density="comfortable"
      color="white"
      :icon="mdiChevronLeft"
      data-testid="go-previous"
      @click="nextField(-1)"
    />
    <app-button
      :disabled="!canGoNext"
      class="text-none"
      variant="text"
      density="comfortable"
      color="white"
      :icon="mdiChevronRight"
      data-testid="go-next"
      @click="nextField(1)"
    />
  </div>
</template>
<script setup>
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps({
  formId: { type: [String, Number], required: true }
});

const formStore = useMappedFormStore(props.formId);

const { positionallySortedFields, currentFieldId, activeField, currentPage } =
  storeToRefs(formStore);

const traversableFields = computed(() =>
  positionallySortedFields.value
    .filter(({ coordinates }) => Boolean(coordinates?.length))
    .map(val => val.id)
);

const activeFieldIndex = computed(() =>
  traversableFields.value.indexOf(currentFieldId.value)
);
const canGoNext = computed(
  () => activeFieldIndex.value < traversableFields.value.length - 1
);
const canGoPrevious = computed(() => activeFieldIndex.value > 0);

function nextField(offset = 1) {
  if (!canGoNext.value && offset > 0) return;
  if (!canGoPrevious.value && offset < 0) return;

  const nextIndex = activeFieldIndex.value + offset;
  formStore.changeActiveField(traversableFields.value[nextIndex]);
  const firstPageValue = activeField.value.coordinates.find(
    ({ virtual, page }) => !virtual && page
  );
  currentPage.value = firstPageValue.page || 1;
}
</script>
