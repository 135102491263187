<template>
  <v-card v-if="user.id">
    <v-card-title> {{ user.email }}'s Settings</v-card-title>
    <v-card-text>
      <h3 style="font-size: 1.24em; font-weight: normal">Highest Group</h3>
      <p class="mb-3">
        Specify the highest group this user may become. Group 1 is standard for
        users. Group 2 is standard for marketing managers and BackNine Staff.
        Group 3 is standard for BackNine Managers. Group 4 is a super admin.
      </p>
      <select-field
        v-model="user.highestGroup"
        label="Highest Group"
        class="mb-3"
        success
        hide-details
        data-testid="highest-group"
        :items="GROUPS"
        :prepend-inner-icon="mdiAccountGroup"
      />
      <h3 style="font-size: 1.24em; font-weight: normal">Group</h3>
      <p class="mb-3">
        Specify the user's active group, which is less than or equal to their
        highest group.
      </p>
      <select-field
        v-model="user.group"
        label="Group"
        class="mb-3"
        success
        hide-details
        data-testid="group"
        :items="availableGroups"
        :prepend-inner-icon="mdiAccountGroup"
        v-bind="groupValidation"
      />
      <h3 style="font-size: 1.24em; font-weight: normal">Permissions</h3>
      <p v-if="isSuperUser" class="mb-3">
        Permissions are disabled for this user because they are in the "Super
        Admin" group.
      </p>
      <template v-else>
        <p class="mb-0">
          Define Permissions for users. Permissions are used to control access.
          Some require higher group levels.
        </p>
        <v-chip-group v-model="permissions" multiple class="mb-3">
          <v-chip
            v-for="permission in PERMISSION_FILTER_ITEMS"
            :key="permission.value"
            :value="permission.value"
            :text="permission.title"
            :data-testid="`permission-${permission.value}`"
            :color="user.permissions[permission.value] ? 'primary' : null"
          />
        </v-chip-group>
      </template>

      <h3 style="font-size: 1.24em; font-weight: normal">
        Require MFA for Login
      </h3>
      <template v-if="showRequireMfaForLogin">
        <p class="mb-0">
          Force this user to enroll in Multi-Factor Authentication by a
          specified date
        </p>
        <checkbox-field
          v-model="user.otpRequiredForLogin"
          class="mt-1"
          label="Required"
          data-testid="mfa-required-for-login"
          :success="user.otpRequiredForLogin"
          persistent-hint
        />
      </template>
      <p v-else class="mb-3">MFA is required for this user.</p>

      <template v-if="showMfaRequiredBy">
        <h3 style="font-size: 1.24em; font-weight: normal">MFA Required By</h3>
        <p class="mb-3">Set a time for MFA to be required by</p>
        <date-time-input
          v-model="user.otpRequiredOn"
          v-model:date="dateHint"
          v-model:time="timeHint"
          :date-props="{
            label: 'Date',
            clearable: true,
            hideDetails: 'auto',
            'data-testid': 'mfa-required-by-date',
            success: otpRequiredOnValidation.success,
            errorMessages:
              !dateHint || timeHint ? otpRequiredOnValidation.errorMessages : []
          }"
          :time-props="{
            label: 'Time',
            clearable: true,
            menuProps: { offsetY: true, auto: true },
            hideDetails: 'auto',
            'data-testid': 'mfa-required-by-time',
            success: otpRequiredOnValidation.success,
            errorMessages:
              !timeHint || dateHint ? otpRequiredOnValidation.errorMessages : []
          }"
        />
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="save"
        :loading="saving"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import DateTimeInput from "@/components/shared/DateTimeInput.vue";
import { updateUserDetails } from "@/api/users.service";
import { GROUPS } from "@/constants/group.constants";
import { PERMISSION_FILTER_ITEMS } from "@/constants/permissions.constants";
import { User } from "@/factories/User";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { ref, computed, watch } from "vue";
import { mdiAccountGroup } from "@mdi/js";
import useVuelidate from "@vuelidate/core";

const props = defineProps({ modelValue: { type: Object, required: true } });

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const user = ref(User(props.modelValue));
const saving = ref(false);
const dateHint = ref(null);
const timeHint = ref(null);

const availableGroups = computed(() =>
  GROUPS.filter(g => g <= user.value.highestGroup)
);

const permissions = computed({
  get: () => {
    if (!user.value) return [];
    return Object.keys(user.value?.permissions).filter(
      v => user.value.permissions[v]
    );
  },
  set: value => {
    if (!user.value) return;
    Object.keys(user.value?.permissions).forEach(p => {
      user.value.permissions[p] = value.includes(p);
    });
  }
});

const showRequireMfaForLogin = computed(() => user.value.highestGroup === 1);
const isSuperUser = computed(() => user.value.highestGroup === 4);

const showMfaRequiredBy = computed(
  () => !user.value.otpEnrolled && user.value.otpRequiredForLogin
);

const v$ = useVuelidate(
  {
    user: {
      group: {
        minValue: v => {
          if (!user.value.highestGroup) return false;
          return v <= user.value.highestGroup;
        }
      },
      otpRequiredOn: {
        required: v => {
          if (!showMfaRequiredBy.value) return true;
          if (dateHint.value && !timeHint.value) return false;
          if (!dateHint.value && timeHint.value) return false;
          return showMfaRequiredBy.value && Boolean(v);
        }
      }
    }
  },
  {
    user
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const otpRequiredOnValidation = computedValidation(
  v$.value.user.otpRequiredOn,
  { required: "Required" }
);

const groupValidation = computedValidation(v$.value.user.group, {
  minValue: "Group must be less than or equal to highest group"
});

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    saving.value = true;

    const body = {
      highest_group: user.value.highestGroup,
      group: user.value.group
    };

    if (showRequireMfaForLogin.value) {
      body.otp_required_for_login = user.value.otpRequiredForLogin;
    }

    if (showMfaRequiredBy.value) {
      body.otp_required_on = user.value.otpRequiredOn;
    }

    if (!isSuperUser.value) {
      body.permissions = user.value.permissions;
    }

    await updateUserDetails(user.value.id, body);
    dialog.closeDialog({ refresh: true });
  } catch (e) {
    snackbar.showErrorSnackbar(parseErrorMessage(e));
  } finally {
    saving.value = false;
  }
}

watch(
  () => user.value.highestGroup,
  () => {
    if (user.value.group > user.value.highestGroup) {
      user.value.group = user.value.highestGroup;
    }
  },
  { immediate: true }
);
</script>
