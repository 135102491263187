<template>
  <v-row class="actions-row" >
    <v-spacer />
    <app-button
      v-if="eappLink"
      :to="eappLink"
      variant="outlined"
      color="orange"
      class="text-none rounded-pill"
      data-testid="view-eapp-button"
      text="View eApp"
      :prepend-icon="mdiOpenInNew"
    />
    <app-button
      variant="outlined"
      color="primary"
      class="text-none rounded-pill"
      data-testid="snapshot-button"
      text="Snapshot"
      :href="snapshotHref"
      :prepend-icon="mdiCamera"
    />
    <app-button
      v-if="user.isGroupThreePlus"
      variant="outlined"
      color="accent"
      class="text-none rounded-pill"
      data-testid="paperclip-button"
      text="Paperclip"
      :href="paperclipHref"
      :prepend-icon="mdiPaperclip"
    />
    <app-button
      variant="outlined"
      color="primary"
      class="text-none rounded-pill"
      data-testid="request-quote-button"
      text="Request Quote"
      :prepend-icon="mdiClipboardAccount"
      @click="requestQuote"
    />
  </v-row>
</template>

<script setup>
import SynthesizeQuoteRequestDialog from "@/dialogs/SynthesizeQuoteRequestDialog.vue";
import { getPaperclipHref, getSnapshotHref } from "@/api/cases.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useCaseViewStore } from "@/stores/case-view";
import { computed, markRaw } from "vue";
import {
  mdiOpenInNew,
  mdiCamera,
  mdiPaperclip,
  mdiClipboardAccount
} from "@mdi/js";

const user = useUserStore();
const { id, electronicApplication } = storeToRefs(useCaseViewStore());
const dialog = useDialogStore();

const eappLink = computed(() => {
  if (!electronicApplication.value?.id) {
    return null;
  }
  return {
    name: "ElectronicApplicationView",
    params: {
      id: electronicApplication.value.id
    },
    query: {
      "from-case": true
    }
  };
});
const snapshotHref = computed(() => getSnapshotHref(id.value));
const paperclipHref = computed(() => getPaperclipHref(id.value));

function requestQuote() {
  dialog.showDialog({
    component: markRaw(SynthesizeQuoteRequestDialog),
    id: id.value,
    idBodyKey: "case_id",
    scrollable: true
  });
}
</script>
