<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="status"
            label="Status"
            data-testid="detail-status"
            :items="STATUSES"
            :prepend-inner-icon="mdiListStatus"
            :success="Boolean(status)"
            @update:model-value="saveAttribute('status')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.status.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { mdiListStatus } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
import { useActiveSave } from "@/composables/active-save.composable";

const STATUSES = ["Outstanding", "Completed"];

const impairedRiskQuote = useImpairedRiskQuoteView();
const { status } = storeToRefs(impairedRiskQuote);

const savingBuffer = {
  status: useActiveSave()
};

function saveAttribute(attribute) {
  savingBuffer[attribute].update(() =>
    impairedRiskQuote.updateAttribute(attribute)
  );
}
</script>
