<template>
  <v-dialog
    :model-value="activeDialogProps.enabled"
    :max-width="activeDialogProps.props.width || '50rem'"
    :max-height="activeDialogProps.props.maxHeight"
    :persistent="activeDialogProps.props.persistent"
    :fullscreen="activeDialogProps.props.fullscreen"
    :style="activeDialogProps.props.style"
    :scrollable="activeDialogProps.props.scrollable"
    @click:outside="outsideClick"
  >
    <component
      :is="stack.props.component"
      v-for="(stack, index) in dialogStack"
      v-show="index === dialogStack.length - 1"
      :key="`${stack.props.component}-${index}`"
      v-bind="{ ...stack.props, isDialog: true }"
      ref="activeDialog"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useDialogStore } from "@/stores/dialog";

import components from "@/dialogs/index";

export default {
  components,
  computed: {
    ...mapState(useDialogStore, ["dialogStack", "show"]),
    activeDialogProps() {
      const length = this.dialogStack.length;
      if (!length) return { enabled: false, props: {} };
      const activeDialog = this.dialogStack[length - 1];
      return {
        enabled: true,
        ...activeDialog
      };
    },
    dialogConfig() {
      if (!this.dialogStack.length) return {};
      return this.dialogStack[this.dialogStack.length - 1].props;
    },
    dialogEnable: {
      get() {
        return this.show;
      },
      set(val) {
        if (val) {
          throw new Error("Do not open a dialog from the Dialog Manager");
        }
        // Only used when the dialog is closed via escape
        if (this.$refs.activeDialog.escCloseFunc) {
          this.$refs.activeDialog.escCloseFunc();
        }
        this.closeDialog();
      }
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    outsideClick() {
      if (this.activeDialogProps.props.persistent) return;
      this.dialogEnable = false;
    }
  }
};
</script>
