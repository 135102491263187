<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="advisor-profile-eapps-table"
    :mobile="null"
    hide-default-footer
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #[`item.insuredName`]="{ item }">
      <router-link
        :to="{
          name: 'ElectronicApplicationView',
          params: {
            id: item.additional.eAppId
          }
        }"
        class="text-none"
      >
        {{ item.insuredName }}
      </router-link>
    </template>
    <template #[`item.updatedAt`]="{ item }">
      <timestamp-formatter :model-value="item.updatedAt" />
    </template>
    <template #[`item.benefit`]="{ item }">
      <currency-formatter :model-value="item.benefit" :decimal-length="0" />
    </template>
    <template #bottom>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <app-button
          data-testid="advisor-profile-eapps-table-view-all"
          color="primary"
          class="text-none"
          variant="text"
          :prepend-icon="mdiOpenInApp"
          :text="`View ${name}'s eApps`"
          @click="viewAll"
        />
      </v-row>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { useTable } from "@/composables/table.composable.js";

import { parseErrorMessage } from "@/util/helpers";
import { getElectronicApplications } from "@/api/electronic-application.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";

import { mdiOpenInApp } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);

const snackbar = useSnackbarStore();
const tableStore = useTableStore();
const router = useRouter();
const table = useTable({
  getData: getElectronicApplications,
  headers: [
    new TableHeader({
      text: "Insured",
      value: "insuredName",
      map: "insuredName"
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName"
    }),
    new TableHeader({
      text: "Product",
      value: "productName",
      map: "productName"
    }),
    new TableHeader({
      text: "Benefit",
      value: "benefit",
      map: "initialDeathBenefit"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status"
    }),
    new TableHeader({
      text: "Updated",
      value: "updatedAt",
      map: "updatedAt",
      sortFilterMap: "electronic_applications.updated_at",
      ...TableHeader.IS_SORTABLE
    })
  ],
  options: TableOptions({ sortBy: [{ key: "updatedAt", order: "desc" }] }),
  shouldIncludeCancelToken: true
});

async function getData() {
  try {
    const additionalFilter = {
      view: "all",
      shared_with_id: id.value,
      shared_with_type: type.value
    };
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function viewAll() {
  tableStore.electronicApplicationsTable.filter = {
    additionalAdvisor: tableFilterData.value
  };

  router.push({ name: "QuoteAndApply" });
}
</script>
