<template>
  <div>
    <div
      class="pa-2 ma-n2 rounded border-opacity-100 position-relative background-inherit"
      style="z-index: 2"
      :class="{
        'border-error': errorMessage,
        'border-md': errorMessage,
        'text-error': errorMessage
      }"
    >
      <h3 class="text-body-1">
        <div class="flex-row">
          {{ label }}

          <slot name="label-append" />
        </div>
      </h3>

      <v-chip-group
        column
        multiple
        selected-class="bg-success"
        :model-value="modelValue"
        @update:model-value="emit('update:model-value', $event)"
      >
        <slot name="chips" />
      </v-chip-group>
    </div>

    <v-slide-y-transition mode="out-in">
      <div
        v-if="errorMessage"
        class="v-messages opacity-100 text-error px-3 mt-2 position-relative"
        style="z-index: 1"
      >
        <span class="v-messages__message">
          {{ errorMessage }}
        </span>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Array],
    required: false,
    default: null
  },
  errorMessages: {
    type: Array,
    required: false,
    default: () => []
  },
  label: {
    type: String,
    required: true
  }
});
const emit = defineEmits(["update:model-value"]);

const { errorMessages } = toRefs(props);

const errorMessage = computed(() =>
  errorMessages.value.length ? errorMessages.value[0] : null
);
</script>
