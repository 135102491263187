export function AdvisorTableItem(model = {}) {
  return {
    model: model?.model || "",
    createdAt: model?.createdAt || "",
    email: model?.email || "",
    id: model?.id || 0,
    marketingManagerName: model?.marketingManagerName || "",
    name: model?.name || "",
    phone: model?.phone || "",
    state: model?.state || "",
    ytd: model?.ytd || 0,
    pending: model?.pending || 0,
    lastSubmit: model?.lastSubmit || null
  };
}

export function setAdvisorTableItemFromRequest(raw = {}) {
  const model = AdvisorTableItem();
  model.link = raw?.connection_type?.link;
  model.model = raw?.connection_type?.model;
  model.createdAt = raw?.loginable?.created_at;
  model.email = raw?.loginable?.email;
  model.id = raw?.loginable?.id;
  model.marketingManagerName = raw?.loginable?.marketing_manager?.name;
  model.name = raw?.loginable?.name;
  model.phone = raw?.loginable?.phone;
  model.state = raw?.loginable?.state;
  model.ytd = raw?.loginable?.stats?.ytd;
  model.pending = raw?.loginable?.stats?.pending;
  model.lastSubmit = raw?.loginable?.stats?.last_submit;
  return model;
}
