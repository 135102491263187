<template>
  <v-card>
    <v-card-title>Edit Advisor</v-card-title>
    <v-card-text>
      <integer-input
        v-model="appointmentCase.splitPercent"
        data-lpignore="true"
        :prepend-inner-icon="mdiPercent"
        type="text"
        inputmode="numeric"
        mask="###"
        label="Split Percent"
        data-testid="split-percent"
        :success="splitPercentValidation.success"
        :error-messages="splitPercentValidation.errorMessages"
      />
      <integer-input
        v-model="appointmentCase.splitRenewal"
        data-lpignore="true"
        :prepend-inner-icon="mdiPercent"
        type="text"
        inputmode="numeric"
        mask="###"
        label="Renewal Split Percent"
        data-testid="split-renewal"
        :success="splitRenewalValidation.success"
        :error-messages="splitRenewalValidation.errorMessages"
      />
      <marketing-manager-search
        v-model="marketingManager"
        :prepend-inner-icon="mdiAccountMultiple"
        label="Marketing Manager"
        data-testid="marketing-manager"
        :success="marketingManagerIdValidation.success"
        :error-messages="marketingManagerIdValidation.errorMessages"
      />
      <div class="checkbox-width">
        <checkbox-field
          v-model="appointmentCase.backNineAppointment"
          label="BackNine Appointment"
          readonly
          data-testid="back-nine-appointment"
          :success="appointmentCase.backNineAppointment"
          @click="toggleCommissionable"
        />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="save-advisor"
        :loading="loading"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";

import { parseErrorMessage, computedValidation } from "@/util/helpers";
import { updateAppointmentCase } from "@/api/appointment-cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { AppointmentCase } from "@/factories/Case";
import { computed, ref, markRaw, toRef, unref } from "vue";
import useVuelidate from "@vuelidate/core";

import { mdiPercent, mdiAccountMultiple } from "@mdi/js";

const props = defineProps({ modelValue: { type: Object, default: () => {} } });
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const modelValue = toRef(props, "modelValue");

const loading = ref(false);
const appointmentCase = ref(AppointmentCase(unref(modelValue)));

const v$ = useVuelidate(
  {
    appointmentCase: {
      splitPercent: {
        required: Boolean
      },
      splitRenewal: {
        required: Boolean
      },
      marketingManagerId: {
        required: Boolean
      }
    }
  },
  { appointmentCase },
  { $autoDirty: true, $scope: false }
);
const marketingManager = computed({
  get() {
    return {
      id: appointmentCase.value.marketingManagerId,
      name: appointmentCase.value.marketingManagerName
    };
  },
  set(v) {
    appointmentCase.value.marketingManagerId = v?.id;
    appointmentCase.value.marketingManagerName = v?.name;
  }
});

const splitPercentValidation = computedValidation(
  v$.value.appointmentCase.splitPercent,
  { required: "Required" }
);

const splitRenewalValidation = computedValidation(
  v$.value.appointmentCase.splitRenewal,
  {
    required: "Required"
  }
);

const marketingManagerIdValidation = computedValidation(
  v$.value.appointmentCase.marketingManagerId,
  { required: "Required" }
);

async function toggleCommissionable() {
  let subtitle = "Confirm that this appointment is through BackNine";
  if (!appointmentCase.value.backNineAppointment) {
    subtitle = "Confirm that this appointment is not through BackNine";
  }
  const result = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Confirm BackNine Appointment",
    subtitle
  });

  if (result?.confirm) {
    appointmentCase.value.backNineAppointment =
      !appointmentCase.value.backNineAppointment;
  }
}
async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid || loading.value) return;
  loading.value = true;
  try {
    const ac = await updateAppointmentCase(appointmentCase.value);
    snackbar.showSuccessSnackbar({
      message: `Successfuly Updated ${appointmentCase.value.advisorName}`,
      timeout: 3000
    });
    dialog.closeDialog({ appointmentCase: ac });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 3000
    });
  } finally {
    loading.value = false;
  }
}
</script>
