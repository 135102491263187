<template>
  <v-data-table
    v-model:search="search"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    v-model:sort-by="table.options.value.sortBy"
    data-testid="custom-chat-templates-table"
    filter-mode="union"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
  >
    <template #top>
      <v-row class="pa-3 ma-0" align="center">
        <h1 class="text-h5">Custom Chat Templates</h1>
        <v-spacer />
        <text-field
          v-model="search"
          data-testid="custom-chat-templates-table-search"
          data-lpignore="true"
          hide-details
          label="Search"
          :prepend-inner-icon="mdiMagnify"
        />
        <app-button
          :icon="mdiPlusCircle"
          variant="text"
          class="table-btn ml-3"
          density="comfortable"
          data-testid="custom-chat-templates-table-create"
          :to="{ name: 'CustomChatTemplateCreator' }"
        />
        <app-button
          :icon="mdiRefresh"
          variant="text"
          class="table-btn"
          density="comfortable"
          data-testid="custom-chat-templates-table-refresh"
          @click="getData"
        />
      </v-row>
    </template>
    <template #[`item.name`]="{ item }">
      <router-link
        :to="{
          name: 'CustomChatTemplateEditor',
          params: { id: item.additional.id }
        }"
      >
        {{ item.name }}
      </router-link>
    </template>

    <template #[`item.published`]="{ item }">
      {{ item.published ? "Yes" : "No" }}
    </template>

    <template #[`item.actions`]="{ item }">
      <app-button
        :icon="mdiDelete"
        variant="text"
        density="comfortable"
        color="error"
        data-testid="delete"
        @click="deleteItem(item.additional.id)"
      />
    </template>
  </v-data-table>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { mdiPlusCircle, mdiRefresh, mdiDelete, mdiMagnify } from "@mdi/js";
import TableHeader from "@/classes/data-table/TableHeader";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { ref, markRaw } from "vue";

import {
  getCustomChatTemplates,
  deleteCustomChatTemplate
} from "@/api/chat-templates.service";
import { TableOptions } from "@/classes/data-table/TableOptions";

const search = ref("");

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Type",
      value: "formattedModelType",
      map: "formattedModelType",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Published",
      value: "published",
      map: "published",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions"
    })
  ],
  options: TableOptions({ sortBy: [{ key: "name", order: "asc" }] }),
  getData: async () => {
    const items = await getCustomChatTemplates();
    return { items };
  }
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function deleteItem(id) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Custom Chat Template",
    subtitle: "Please confirm your intent",
    func: async () => {
      try {
        await deleteCustomChatTemplate(id);
        snackbar.showSuccessSnackbar({
          message: "Template deleted successfully"
        });
        await getData();
      } catch (e) {
        snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
      }
    }
  });
}

getData();
</script>
