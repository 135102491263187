import {
  Address,
  setAddressFromRaw,
  setRawFromAddress
} from "@/factories/AddressFactory";
import { setNoteFromRequest } from "@/factories/Note";
import { getRouterLink } from "@/util/helpers";

export const INDIVIDUAL_MARITAL_STATUSES = [
  "Married",
  "Single",
  "Domestic Partner"
];
export const ENTITY_IRREVOCABLES = [
  { title: "Revocable", value: 0 },
  { title: "Irrevocable", value: 1 }
];

export function ContractParty(model = {}) {
  return {
    assets: valOrUndefined(model?.assets),
    avatar: valOrUndefined(model?.avatar),
    businessAddress: Address({
      ...model?.businessAddress,
      addressType: "business"
    }),
    canDelete: valOrUndefined(model?.canDelete),
    cases: model?.cases || [],
    formationDate: valOrUndefined(model?.formationDate),
    birthdate: valOrUndefined(model?.birthdate),
    driversLicenseId: valOrUndefined(model?.driversLicenseId),
    driversLicenseNumber: valOrUndefined(model?.driversLicenseNumber),
    driversLicenseState: valOrUndefined(model?.driversLicenseState),
    email: valOrUndefined(model?.email),
    employerAddress: Address(model?.employerAddress),
    employerId: valOrUndefined(model?.employerId),
    employerName: valOrUndefined(model?.employerName),
    firstName: valOrUndefined(model?.firstName),
    gender: valOrUndefined(model?.gender),
    height: valOrUndefined(model?.height),
    homeAddress: Address({ ...model?.homeAddress, addressType: "home" }),
    id: valOrUndefined(model?.id),
    income: valOrUndefined(model?.income),
    lastName: valOrUndefined(model?.lastName),
    liabilities: valOrUndefined(model?.liabilities),
    maritalStatus: valOrUndefined(model?.maritalStatus),
    middleName: valOrUndefined(model?.middleName),
    phoneMobile: valOrUndefined(model?.phoneMobile),
    name: valOrUndefined(model?.name),
    notes: model?.notes || [],
    occupation: model?.occupation,
    occupationId: model?.occupationId,
    phoneWork: valOrUndefined(model?.phoneWork),
    irrevocable: valOrUndefined(model?.irrevocable),
    room: model?.room || [],
    ssn: valOrUndefined(model?.ssn),
    state: valOrUndefined(model?.state),
    suffix: valOrUndefined(model?.suffix),
    tin: valOrUndefined(model?.tin),
    type: valOrUndefined(model?.type),
    weight: valOrUndefined(model?.weight)
  };
}

export function Individual(model = {}) {
  return {
    assets: model?.assets || null,
    avatar: model?.avatar || null,
    canDelete: model?.canDelete || false,
    cases: model?.cases || [],
    birthdate: model?.birthdate || null,
    driversLicenseId: model?.driversLicenseId || null,
    driversLicenseNumber: model?.driversLicenseNumber || null,
    driversLicenseState: model?.driversLicenseState || null,
    email: model?.email || null,
    employerAddress: Address(model?.employerAddress),
    employerId: model?.employerId,
    employerName: model?.employerName || "",
    firstName: model?.firstName || null,
    gender: model?.gender || null,
    height: model?.height || null,
    homeAddress: Address({ ...model?.homeAddress, addressType: "home" }),
    id: model?.id || null,
    income: model?.income || null,
    lastName: model?.lastName || null,
    liabilities: model?.liabilities || null,
    maritalStatus: model?.maritalStatus || null,
    middleName: model?.middleName || null,
    phoneMobile: model?.phoneMobile || null,
    notes: model?.notes || [],
    occupation: model?.occupation,
    occupationId: model?.occupationId,
    phoneWork: model?.phoneWork || null,
    room: model?.room || [],
    ssn: model?.ssn || null,
    state: model?.state || null,
    suffix: model?.suffix || null,
    type: "Individual",
    weight: model?.weight || null
  };
}

export function setIndividualFromRequest(raw = {}) {
  const model = Individual();
  const numOrNull = v => (v || v === 0 ? v : null);
  model.avatar = raw?.avatar_url || null;
  model.id = numOrNull(raw?.id);
  model.canDelete = raw?.can_delete || false;
  model.income = numOrNull(raw?.income);
  model.assets = numOrNull(raw?.assets);
  model.liabilities = numOrNull(raw?.liabilities);
  model.firstName = raw?.first_name || null;
  model.middleName = raw?.middle_name || null;
  model.lastName = raw?.last_name || null;
  model.suffix = raw?.suffix || null;
  model.ssn = raw?.party_id || null;
  model.email = raw?.email || null;
  model.gender = raw?.gender !== "N/A" ? raw?.gender : null;
  model.birthdate = raw?.birthdate || null;
  model.phoneWork = raw?.phone_work || null;
  model.phoneMobile = raw?.phone_mobile || null;
  model.maritalStatus = raw?.marital_status || null;
  model.state = raw?.state || null;
  model.height = raw?.height || undefined;
  model.weight = raw?.weight || undefined;
  model.driversLicenseId = raw?.drivers_license?.id || null;
  model.driversLicenseState = raw?.drivers_license?.state || null;
  model.driversLicenseNumber = raw?.drivers_license?.number || null;

  model.homeAddress = setAddressFromRaw(raw?.addresses?.home);
  model.room = raw?.room?.map(setContractPartyAccessFromRequest);
  model.notes = raw?.notes?.map(setNoteFromRequest);
  model.cases = raw?.cases?.map(setContractPartyCaseFromRaw);

  if (raw?.occupation) {
    const occupation = raw.occupation;
    model.occupation = occupation?.occupation;
    model.occupationId = occupation?.id;

    const employer = occupation?.employer;
    if (employer) {
      model.employerId = employer.id;
      model.employerAddress = setAddressFromRaw(employer.business_address);
      model.employerName = employer.name;
    }
  }

  return model;
}

const INDIVIDUAL_INDICATE = "0";
const ENTITY_INDICATE = "1";

const valOrUndefined = v => {
  if (v || [null, 0, false].includes(v)) return v;
  return undefined;
};

export function IndividualToCreateRequest(model) {
  return {
    addresses_attributes: [setRawFromAddress(model.homeAddress)],
    assets: valOrUndefined(model.assets),
    birthdate: valOrUndefined(model.birthdate),
    drivers_license_attributes: {
      state: valOrUndefined(model.driversLicenseState),
      number: valOrUndefined(model.driversLicenseNumber)
    },
    email: valOrUndefined(model.email),
    first_name: model.firstName,
    gender: valOrUndefined(model.gender),
    height: valOrUndefined(model.height),
    income: valOrUndefined(model.income),
    last_name: model.lastName,
    liabilities: valOrUndefined(model.liabilities),
    marital_status: valOrUndefined(model.maritalStatus),
    middle_name: valOrUndefined(model.middleName),
    natural_non_natural_indicate: INDIVIDUAL_INDICATE,
    not_case: 1,
    phone_mobile: valOrUndefined(model.phoneMobile),
    ssn: valOrUndefined(model.ssn),
    weight: valOrUndefined(model.weight)
  };
}

export function Entity(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    canDelete: model?.canDelete || false,
    tin: model?.tin || null,
    formationDate: model?.formationDate || null,
    irrevocable: model?.irrevocable || 0,
    businessAddress: Address({
      ...model?.businessAddress,
      addressType: "business"
    }),
    notes: model?.notes || [],
    room: model?.room || [],
    avatar: model?.avatar || null,
    email: model?.email || null,
    phoneWork: model?.phoneWork || null,
    type: "Entity"
  };
}

export function setEntityFromRequest(raw = {}) {
  const model = Entity();
  model.id = raw?.id || 0;
  model.canDelete = raw?.can_delete || false;
  model.email = raw?.email || null;
  model.name = raw?.name || null;
  model.tin = raw?.party_id || null;
  model.formationDate = raw?.birthdate || null;
  model.avatar = raw?.avatar_url;
  model.irrevocable = raw?.irrevocable || 0;
  model.phoneWork = raw?.phone_work;

  model.businessAddress = setAddressFromRaw(raw?.addresses?.business);
  model.notes = raw?.notes?.map(setNoteFromRequest);
  model.room = raw?.room?.map(setContractPartyAccessFromRequest);
  model.cases = raw?.cases?.map(setContractPartyCaseFromRaw);

  return model;
}

export function EntityToCreateRequest(model) {
  return {
    addresses_attributes: [setRawFromAddress(model.businessAddress)],
    birthdate: valOrUndefined(model.formationDate),
    email: valOrUndefined(model.email),
    irrevocable: valOrUndefined(model.irrevocable),
    name: valOrUndefined(model.name),
    natural_non_natural_indicate: ENTITY_INDICATE,
    not_case: 1,
    phone_work: valOrUndefined(model.phoneWork),
    tin: valOrUndefined(model.tin)
  };
}

export function ContractPartyAccess(model = {}) {
  return {
    agent: model?.agent || null,
    avatar: model?.avatar || null,
    contractPartyAccessId: model?.contractPartyAccessId || null,
    userEmail: model?.userEmail || null,
    disabled: model?.disabled || null,
    email: model?.email || null,
    id: model?.id || null,
    name: model?.name || null,
    routerLink: model?.routerLink || null,
    type: model?.type || null
  };
}

export function setContractPartyAccessFromRequest(raw = {}) {
  const model = ContractPartyAccess();
  model.agent = raw?.agent;
  model.email = raw?.email;
  model.contractPartyAccessId = raw?.contract_party_access_id;
  model.disabled = raw?.disabled;
  model.id = raw?.id;
  model.name = raw?.name;
  model.type = raw?.type;
  model.avatar = raw?.avatar_url;
  model.userEmail = raw?.user_email;
  if (model.type && model.id) {
    model.routerLink = getRouterLink(model.type, model.id);
  }
  return model;
}

export function ContractPartyCase(model = {}) {
  return {
    insured: model?.insured || "",
    policyNumber: model?.policyNumber || "",
    caseId: model?.caseId || 0,
    carrier: model?.carrier || "",
    product: model?.product || "",
    line: model?.line || "",
    premium: model?.premium || 0,
    mode: model?.mode || 0,
    benefitAmount: model?.benefitAmount || 0,
    status: model?.status || ""
  };
}

export function setContractPartyCaseFromRaw(raw = {}) {
  const model = ContractPartyCase();
  model.insured = raw?.insured?.name || "";
  model.policyNumber = raw?.policy_number || "";
  model.caseId = raw?.id || 0;
  model.carrier = raw?.carrier?.name || "";
  model.product = raw?.product?.name || "";
  model.line = raw?.line?.value || "";
  model.premium = raw?.premium || 0;
  model.mode = raw?.mode || 0;
  model.benefitAmount = raw?.benefit || 0;
  model.status = raw?.status || "";
  return model;
}

export default function Employer(model = {}) {
  return {
    id: model?.id || null,
    address: Address(model?.address),
    name: model?.name || null,
    endDate: model?.endDate || null,
    occupation: model?.occupation || null,
    startDate: model?.startDate || null
  };
}

export function setEmployerFromRaw(raw = {}) {
  const model = Employer();
  model.id = raw?.employer?.id;
  let rawAddress = raw?.employer?.business_address;
  if (raw?.employer?.addresses) {
    rawAddress = raw?.employer?.addresses?.find(
      address => address.address_type === "business_address"
    );
  }
  model.address = setAddressFromRaw(rawAddress);
  model.name = raw?.employer?.name;
  model.endDate = raw?.end_date;
  model.occupation = raw?.occupation;
  model.startDate = raw?.start_date;
  return model;
}

export function ContractPartySearchItem(model = {}) {
  return {
    address: Address(model?.address),
    name: model?.name,
    id: model?.id,
    type: model?.type,
    lastFour: model?.lastFour
  };
}

export function setContractPartySearchItemFromRequest(raw = {}) {
  const item = ContractPartySearchItem();

  item.name = raw?.name;
  item.id = raw?.id;
  if (raw?.type === "individual") item.type = "Individual";
  else if (raw?.type === "entity") item.type = "Entity";
  item.lastFour = raw?.party_id_last_four;
  item.address = Address();

  if (raw?.address?.street_address) {
    item.address = setAddressFromRaw(raw.address);
  }

  return item;
}

export function ContractPartyTableItem(model = {}) {
  return {
    birthdate: model?.birthdate || null,
    email: model?.email || null,
    id: model?.id || null,
    income: model?.income || null,
    name: model?.name || null,
    type: model?.type || null,
    primaryPhone: model?.primaryPhone || null
  };
}

export function setContractPartyTableItemFromRequest(raw = {}) {
  const item = ContractPartyTableItem();

  item.primaryPhone = raw?.primary_phone;
  item.birthdate = raw?.birthdate;
  item.email = raw?.email;
  item.id = raw?.id;
  item.income = raw?.income;
  item.name = raw?.name;
  item.type = raw?.type;

  return item;
}
