<template>
  <v-card>
    <v-card-title id="v-step-3" style="letter-spacing: 1px">
      <v-row class="pa-3 pr-0">
        Tasks <v-spacer />
        <app-button
          variant="text"
          color="primary"
          class="text-none"
          :prepend-icon="mdiOpenInNew"
          text="View Tasks"
          :to="{ name: 'Tables', query: { page: 'tasks' } }"
        />
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-list>
        <template v-if="props.tasks.length > 0">
          <v-list-item
            v-for="(task, index) in props.tasks"
            :key="index"
            link
            lines="two"
            :to="
              getRouterLink('Case', task.caseId, {
                query: { task: task.id, type: task.type }
              })
            "
            class="purple-list-item rounded"
          >
            <v-list-item-title> {{ task.description }} </v-list-item-title>
            <v-list-item-subtitle class="pt-2">
              <v-row class="pa-3">
                {{ task.insuredName }} <v-spacer />
                <timestamp-formatter
                  format="date"
                  :model-value="task.updatedAt"
                />
              </v-row>
            </v-list-item-subtitle>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item lines="two" class="ma-1 secondary rounded">
            <v-list-item-title>
              Your recent tasks will appear here
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script setup>
import { getRouterLink } from "@/util/helpers";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { mdiOpenInNew } from "@mdi/js";
const props = defineProps({
  tasks: {
    type: Array,
    required: true
  }
});
</script>
