<template>
  <v-card flat tile>
    <v-card-title> Signature </v-card-title>
    <v-card-text v-if="!signatureAuthorized || changeSignature" class="pb-0">
      Provide an authorization signature
    </v-card-text>
    <v-fade-transition mode="out-in">
      <v-card-text v-if="!props.signatureReady" key="signature-not-ready">
        <v-alert type="info" variant="outlined">
          {{ agent.name }} Must complete all other sections first before
          providing a signature.
        </v-alert>
      </v-card-text>
      <v-card-text v-else-if="props.isViewingSelf" key="signing">
        <v-fade-transition mode="out-in">
          <v-row
            v-if="!signatureAuthorized || changeSignature"
            key="changing-signature"
            dense
          >
            <v-col cols="12">
              <h3 class="opacity-60">
                PLEASE READ THIS AUTHORIZATION, SIGN IN THE BOX BELOW AND SUBMIT
                THIS FORM BY FOLLOWING THESE INSTRUCTIONS
              </h3>
            </v-col>
            <v-col cols="12">
              <p style="line-height: 1.2" class="mb-0">
                I hereby authorize SuranceBay, LLC and its general agency
                customers (the 'Authorized Parties') to affix or append a copy
                of my signature, as set forth below, to any and all required
                signature fields on forms and agreements of any insurance
                carrier (a 'Carrier') designated by me through the SureLC
                software or through any other means, including without
                limitation, by e-mail or orally. The Authorized Parties shall be
                permitted to complete and submit all such forms and agreements
                on my behalf for the purpose of becoming authorized to sell
                Carrier insurance products. I hereby release, indemnify, and
                hold harmless the Authorized Parties against any and all claims,
                demands, losses, damages, and causes of action, including
                expenses, costs, and reasonable attorneys' fees which they may
                sustain or incur as a result of carrying out the authority
                granted hereunder. By my signature below, I certify that the
                information I have submitted to the Authorized Parties is
                correct to the best of my knowledge and acknowledge that I have
                read and reviewed the forms and agreements which the Authorized
                Parties have been authorized to affix my signature.
              </p>
            </v-col>
            <v-col v-if="!signatureAuthorized || displaySignaturePad" cols="12">
              <div v-if="!signatureAuthorized" class="checkbox-width">
                <checkbox-field
                  v-model="displaySignaturePad"
                  data-testid="signature-terms"
                  dense
                  class="mt-2"
                  hide-details
                  @update:model-value="toggleDisplaySignaturePad"
                >
                  <template #label>
                    <p class="mb-0">
                      I have read and agree with BackNine's
                      <a
                        href="https://github.com/back9ins/terms"
                        target="_blank"
                        style="z-index: 1"
                        @click.stop
                      >
                        Terms and Conditions,
                      </a>
                      <a
                        href="https://github.com/back9ins/privacy-policy"
                        target="_blank"
                        style="z-index: 1"
                        @click.stop
                      >
                        Privacy Policy,</a
                      >
                      and the above.
                    </p>
                  </template>
                </checkbox-field>
              </div>
              <v-row v-if="displaySignaturePad" class="ma-0" dense>
                <v-col cols="12">
                  Please click and drag your mouse (use your finger on
                  touchscreens) within the box below to draw your signature.
                  Your signature should take up at least half of the signature
                  box.
                </v-col>
                <v-col cols="12">
                  <a v-if="signatureAuthorized" @click="toggleSignature">
                    (Cancel)
                  </a>
                </v-col>

                <v-col cols="12">
                  <div
                    id="signature-pad-parent"
                    data-testid="signature-pad-parent"
                    style="
                      border: 1px solid grey;
                      border-radius: 4px;
                      position: relative;
                      width: 600px;
                      height: 120px;
                    "
                    :style="{
                      'border-color': invalidSignature ? 'red' : ''
                    }"
                  >
                    <div style="position: absolute; top: 0; right: 0">
                      <app-button
                        class="text-none"
                        variant="text"
                        :icon="mdiCloseCircle"
                        @click="clearSignature"
                      />
                    </div>
                  </div>
                  <div v-if="invalidSignature" class="v-text-field__details">
                    <div class="v-messages text-error">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message mx-3">
                          <template v-if="missingSignature">
                            Required.
                          </template>
                          <template v-else-if="signatureTooSmall">
                            Signature is too small.
                          </template>
                          <template v-else-if="unableToOutputSignature">
                            Unable to output signature. Please clear and try
                            again or contact support for assistance.
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <app-button
                    color="primary"
                    class="text-none"
                    :loading="loading"
                    data-testid="save-signature"
                    @click="saveSignature"
                  >
                    Save Signature
                  </app-button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div
            v-else-if="signatureAuthorized && !changeSignature"
            data-testid="signature-authorized"
          >
            You signed on
            <timestamp-formatter
              :model-value="signatureAuthorized"
              parser="none"
              format="full-date-time"
            />. If you would like to change your signature
            <a @click="toggleSignature">click here</a>.
          </div>
        </v-fade-transition>
      </v-card-text>
      <v-card-text v-else key="readonly">
        <v-alert v-if="signatureAuthorized" type="success" variant="outlined">
          {{ agent.name }} completed their signature on
          <timestamp-formatter
            :model-value="signatureAuthorized"
            parser="none"
            format="full-date-time"
          />.
        </v-alert>
        <v-alert v-else type="error" variant="outlined">
          {{ agent.name }} has not completed their signature. You cannot eSign
          because you are logged in as {{ user.loginable.name }}.
          <a @click="changeAccess"> Change your login and eSign. </a>
        </v-alert>
      </v-card-text>
    </v-fade-transition>
    <slot />
  </v-card>
</template>

<script setup>
import ChangeAccessDialog from "@/dialogs/ChangeAccessDialog.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import SignaturePad from "signature_pad";
import { parseErrorMessage, createHighDPICanvas } from "@/util/helpers";
import { updateSignature } from "@/api/save-signature.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed, ref, onMounted, markRaw } from "vue";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { mdiCloseCircle } from "@mdi/js";
const props = defineProps({
  signatureReady: Boolean,
  isViewingSelf: Boolean
});

const changeSignature = ref(false);
const loading = ref(false);
const displaySignaturePad = ref(false);
const signatureTooSmall = ref(false);
const missingSignature = ref(false);
const unableToOutputSignature = ref(false);
const user = useUserStore();
const agent = useAgentSettingsStore();
const dialog = useDialogStore();

const { signatureAuthorized } = storeToRefs(agent);
const invalidSignature = computed(
  () =>
    missingSignature.value ||
    signatureTooSmall.value ||
    unableToOutputSignature.value
);
const snackbar = useSnackbarStore();

function waitForElement(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

let signaturePad;
async function toggleDisplaySignaturePad() {
  if (!displaySignaturePad.value) return;
  await waitForElement("#signature-pad-parent");
  const parent = document.getElementById("signature-pad-parent");
  const canvas = createHighDPICanvas(600, 120);
  parent.appendChild(canvas);
  signaturePad = new SignaturePad(canvas);
  signaturePad.on();
}

function clearSignature() {
  signaturePad.clear();
}
function toggleSignature() {
  changeSignature.value = !changeSignature.value;
  displaySignaturePad.value = changeSignature.value;
  toggleDisplaySignaturePad();
}
function validate() {
  const valid = Boolean(signatureAuthorized.value);
  agent.setValidPage({ page: "signature", valid });
}

function validateSignature() {
  if (signaturePad) return true;
  signatureTooSmall.value = false;
  missingSignature.value = false;
  unableToOutputSignature.value = false;

  let pointLength = 0;
  signaturePad.toData().forEach(line => {
    pointLength += line.points.length;
  });

  if (!signaturePad.toDataURL()) {
    unableToOutputSignature.value = true;
    clearSignature();
    return;
  }

  if (pointLength > 30) {
    return;
  }

  if (pointLength > 0) {
    signatureTooSmall.value = true;
    clearSignature();
    return;
  }

  missingSignature.value = true;
}

async function saveSignature() {
  validateSignature();
  if (invalidSignature.value) return false;
  loading.value = true;

  const signatureImage = dataURItoBlob(signaturePad.toDataURL());

  try {
    const status = await updateSignature(signatureImage);
    if (!status) return;
    snackbar.showSuccessSnackbar({ message: "Successfully Added Signature" });
    displaySignaturePad.value = false;
    changeSignature.value = false;
    signatureAuthorized.value = new Date();
    validate();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: "Unable to Add Signature " + parseErrorMessage(e)
    });
  } finally {
    loading.value = false;
  }
}

function dataURItoBlob(dataURI) {
  let binary = atob(dataURI.split(",")[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
}

function changeAccess() {
  dialog
    .showDialog({
      component: markRaw(ChangeAccessDialog)
    })
    .then(result => {
      if (!result.applying) {
        return;
      }

      snackbar.showInfoSnackbar({
        message: "Applying Changes. Refreshing Page",
        timeout: 2000
      });
    });
}

validate();

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
