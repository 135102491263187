<template>
  <v-card>
    <v-card-title>Edit Statement</v-card-title>
    <v-card-text>
      <span v-if="!paid">
        <p>{{ errors }}</p>
        <p>
          Once this has been paid manually, you can enter a note below to close
          the period.
        </p>
      </span>
      <span v-else>
        <p>This period has been manually paid. You can only update the memo.</p>
      </span>

      <text-field
        v-model="memo"
        data-lpignore="true"
        label="Memo"
        data-testid="pay-period-memo"
        v-bind="memoValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        color="grey"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="save-pay-period"
        :loading="loading"
        @click="savePayPeriod"
      >
        {{ !paid ? "Mark as paid" : "Update" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { updatePayPeriod, payPayPeriod } from "@/api/pay-periods.service";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useSnackbarStore } from "@/stores/snackbar";

const props = defineProps({
  modelValue: { type: Object, default: () => {} }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const loading = ref(false);

const memo = ref(props.modelValue.memo);
const paid = props.modelValue.transactionId || props.modelValue.memo;
const errors = ref(props.modelValue.errors);

const v$ = useVuelidate(
  {
    memo: { required: v => someTextValidator(true, v, 2) }
  },
  {
    memo
  },
  { $scope: null, $autoDirty: true }
);

const memoValidation = computedValidation(v$.value.memo, {
  required: "Required"
});

async function savePayPeriod() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  if (paid) updateMemo();
  else executePayment();
}

async function updateMemo() {
  loading.value = true;
  try {
    await updatePayPeriod(props.modelValue.id, { memo: memo.value });
    dialog.closeDialog({ updated: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

async function executePayment() {
  loading.value = true;
  try {
    await payPayPeriod(props.modelValue.id, memo.value);
    dialog.closeDialog({ updated: true });
  } catch (e) {
    if (e.response.status === 422) {
      errors.value = e.response.data.message;
    } else {
      snackbar.showErrorSnackbar({
        message: parseErrorMessage(e),
        timeout: -1
      });
    }
  } finally {
    loading.value = false;
  }
}
</script>
