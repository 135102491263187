<template>
  <template v-if="tabs.length === 1">
    <v-divider />
    <component :is="tabs[0].component" v-bind="tabs[0].componentProps" />
  </template>
  <template v-else>
    <v-tabs
      :model-value="modelValue"
      show-arrows
      :grow="grow"
      class="no-bottom-radius"
      color="primary"
      @update:model-value="emit('update:model-value', $event)"
    >
      <v-tab
        v-for="tab in tabs"
        :key="'tab' + tab.title"
        class="text-none"
        :data-testid="`tab-${tab.title}`"
      >
        <v-icon class="pr-1" :icon="tab.icon" />
        <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
          {{ tab.title }}
        </v-badge>
        <template v-else>{{ tab.title }}</template>
      </v-tab>
    </v-tabs>
    <v-divider />
    <v-tabs-window :model-value="modelValue" touchless>
      <v-tabs-window-item
        v-for="tab in tabs"
        :key="'tab' + tab.title"
        :eager="tab.eager"
      >
        <component :is="tab.component" v-bind="tab.componentProps" />
      </v-tabs-window-item>
    </v-tabs-window>
  </template>
</template>

<script setup>
defineProps({
  grow: Boolean,
  modelValue: {
    type: Number,
    required: false,
    default: null
  },
  tabs: {
    type: Array,
    required: true
  }
});
const emit = defineEmits(["update:model-value"]);
</script>
