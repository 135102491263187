<template>
  <v-card tile flat color="section">
    <v-card-title>Personnel Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <text-field
            v-model="firstName"
            label="First Name"
            data-lpignore="true"
            data-testid="detail-first-name"
            :prepend-inner-icon="mdiAccount"
            :disabled="readonly"
            :success="firstNameValidation.success"
            :error-messages="firstNameValidation.errorMessages"
            @update:model-value="updateFirstName"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.firstName.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="lastName"
            label="Last Name"
            data-testid="detail-last-name"
            data-lpignore="true"
            :prepend-inner-icon="mdiAccountMultiple"
            :disabled="readonly"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
            @update:model-value="updateLastName"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.lastName.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12">
          <carrier-search
            v-model="carrier"
            data-testid="detail-carrier-search"
            :disabled="readonly"
            :success="carrierValidation.success"
            :error-messages="carrierValidation.errorMessages"
            @update:model-value="updateCarrier"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.carrier.controller.value"
              />
            </template>
          </carrier-search>
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="department"
            data-testid="detail-department"
            label="Department"
            placeholder="Select Department"
            :disabled="readonly"
            :prepend-inner-icon="mdiOfficeBuilding"
            :items="DEPARTMENTS"
            :success="departmentValidation.success"
            :error-messages="departmentValidation.errorMessages"
            @update:model-value="updateDepartment"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.department.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="title"
            data-lpignore="true"
            data-testid="detail-title"
            label="Title"
            :disabled="readonly"
            :prepend-inner-icon="mdiCardAccountDetails"
            :success="Boolean(title)"
            @update:model-value="updateTitle"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.title.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12">
          <text-field
            v-model="email"
            label="Email"
            data-testid="detail-email"
            data-lpignore="true"
            :prepend-inner-icon="mdiEmail"
            :disabled="readonly"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
            @update:model-value="updateEmail"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.email.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="8">
          <phone-input
            v-model="phoneWork"
            data-testid="detail-phone-work"
            data-lpignore="true"
            label="Work Phone"
            :disabled="readonly"
            :prepend-inner-icon="mdiDeskphone"
            :success="Boolean(phoneWork)"
            @update:model-value="updatePhoneWork"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.phoneWork.controller.value"
              />
            </template>
          </phone-input>
        </v-col>
        <v-col cols="12" md="4">
          <integer-input
            v-model="phoneWorkExtension"
            data-lpignore="true"
            data-testid="detail-phone-work-extension"
            label="Work Phone Extension"
            mask="######"
            :prepend-inner-icon="mdiDeskphone"
            :disabled="readonly"
            :success="Boolean(phoneWorkExtension)"
            @update:model-value="updatePhoneWorkExtension"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.phoneWorkExtension.controller.value"
              />
            </template>
          </integer-input>
        </v-col>

        <v-col cols="12">
          <phone-input
            v-model="phoneMobile"
            data-lpignore="true"
            label="Mobile Phone"
            data-testid="detail-phone-mobile"
            :prepend-inner-icon="mdiCellphone"
            :disabled="readonly"
            :success="Boolean(phoneMobile)"
            @update:model-value="updatePhoneMobile"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.phoneMobile.controller.value"
              />
            </template>
          </phone-input>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Home Address</v-card-title>
    <v-card-text>
      <basic-address-input
        key="personnel-home-address"
        v-model:street-address-model="homeAddress.street_address"
        v-model:city-model="homeAddress.city"
        v-model:state-model="homeAddress.state"
        v-model:zip-model="homeAddress.zip"
        type="home"
        autofill-label="Home Address"
        autofill-placeholder="Home Address"
        data-testid="detail-home-address"
        :disabled="readonly"
        :street-address-validation="homeStreetValidation"
        :city-validation="homeCityValidation"
        :state-validation="homeStateValidation"
        :zip-validation="homeZipValidation"
        @update:street-address-model="updateHomeStreet"
        @update:city-model="updateHomeCity"
        @update:state-model="updateHomeState"
        @update:zip-model="updateHomeZip"
        @autofill="handleHomeAutofill"
      >
        <template #append-inner-street-address>
          <active-save-indicator
            :controller="savingBuffer.homeStreet.controller.value"
          />
        </template>
        <template #append-inner-city>
          <active-save-indicator
            :controller="savingBuffer.homeCity.controller.value"
          />
        </template>
        <template #append-inner-state>
          <active-save-indicator
            :controller="savingBuffer.homeState.controller.value"
          />
        </template>
        <template #append-inner-zip>
          <active-save-indicator
            :controller="savingBuffer.homeZip.controller.value"
          />
        </template>
      </basic-address-input>
    </v-card-text>
    <v-divider />
    <v-card-title>Business Address</v-card-title>
    <v-card-text>
      <basic-address-input
        key="personnel-business-address"
        v-model:street-address-model="businessAddress.street_address"
        v-model:city-model="businessAddress.city"
        v-model:state-model="businessAddress.state"
        v-model:zip-model="businessAddress.zip"
        type="home"
        autofill-label="Business Address"
        autofill-placeholder="Business Address"
        data-testid="detail-business-address"
        :disabled="readonly"
        :street-address-validation="businessStreetValidation"
        :city-validation="businessCityValidation"
        :state-validation="businessStateValidation"
        :zip-validation="businessZipValidation"
        @update:street-address-model="updateBusinessStreet"
        @update:city-model="updateBusinessCity"
        @update:state-model="updateBusinessState"
        @update:zip-model="updateBusinessZip"
        @autofill="handleBusinessAutofill"
      >
        <template #append-inner-street-address>
          <active-save-indicator
            :controller="savingBuffer.businessStreet.controller.value"
          />
        </template>
        <template #append-inner-city>
          <active-save-indicator
            :controller="savingBuffer.businessCity.controller.value"
          />
        </template>
        <template #append-inner-state>
          <active-save-indicator
            :controller="savingBuffer.businessState.controller.value"
          />
        </template>
        <template #append-inner-zip>
          <active-save-indicator
            :controller="savingBuffer.businessZip.controller.value"
          />
        </template>
      </basic-address-input>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PhoneInput from "@/components/shared/PhoneInput.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";

import { DEPARTMENTS } from "@/factories/Personnel";

import { computedValidation, someTextValidator } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useActiveSave } from "@/composables/active-save.composable";
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiCardAccountDetails,
  mdiDeskphone,
  mdiEmail,
  mdiCellphone,
  mdiOfficeBuilding
} from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { usePersonnelView } from "@/stores/personnel-view";
import {  toRefs } from "vue";

const props = defineProps({
  readonly: Boolean
});

const { readonly } = toRefs(props);

const personnelView = usePersonnelView();

const {
  firstName,
  lastName,
  email,
  carrier,
  department,
  homeAddress,
  businessAddress,
  title,
  phoneWork,
  phoneWorkExtension,
  phoneMobile
} = storeToRefs(personnelView);

const v$ = useVuelidate(
  {
    firstName: { required: Boolean },
    lastName: { required: Boolean },
    email: { required: Boolean },
    carrier: { required: v => v?.id },
    department: { required: Boolean },
    homeAddress: {
      street_address: { required: v => !v || someTextValidator(true, v, 1) },
      state: { required: v => !v || someTextValidator(true, v, 1) },
      city: { required: v => !v || someTextValidator(true, v, 1) },
      zip: { validLength: v => !v || `${v}`.length === 5 }
    },
    businessAddress: {
      street_address: { required: v => !v || someTextValidator(true, v, 1) },
      state: { required: v => !v || someTextValidator(true, v, 1) },
      city: { required: v => !v || someTextValidator(true, v, 1) },
      zip: { validLength: v => !v || `${v}`.length === 5 }
    }
  },
  {
    firstName,
    lastName,
    email,
    carrier,
    department,
    homeAddress,
    businessAddress
  },
  { $autoDirty: true, $scope: null }
);

const savingBuffer = {
  firstName: useActiveSave(),
  lastName: useActiveSave(),
  email: useActiveSave(),
  title: useActiveSave(),
  carrier: useActiveSave(),
  department: useActiveSave(),
  phoneWork: useActiveSave(),
  phoneWorkExtension: useActiveSave(),
  phoneMobile: useActiveSave(),
  homeAddress: useActiveSave(),
  homeStreet: useActiveSave(),
  homeCity: useActiveSave(),
  homeState: useActiveSave(),
  homeZip: useActiveSave(),
  businessAddress: useActiveSave(),
  businessStreet: useActiveSave(),
  businessCity: useActiveSave(),
  businessState: useActiveSave(),
  businessZip: useActiveSave()
};

const firstNameValidation = computedValidation(v$.value.firstName, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.lastName, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.email, {
  required: "Required"
});
const carrierValidation = computedValidation(v$.value.carrier, {
  required: "Required"
});
const departmentValidation = computedValidation(v$.value.department, {
  required: "Required"
});
const homeStreetValidation = computedValidation(
  v$.value.homeAddress.street_address,
  { required: "Required" }
);
const homeCityValidation = computedValidation(v$.value.homeAddress.city, {
  required: "Required"
});
const homeStateValidation = computedValidation(v$.value.homeAddress.state, {
  required: "Required"
});
const homeZipValidation = computedValidation(v$.value.homeAddress.zip, {
  validLength: "Must be 5 numbers long"
});
const businessStreetValidation = computedValidation(
  v$.value.businessAddress.street_address,
  { required: "Required" }
);
const businessCityValidation = computedValidation(
  v$.value.businessAddress.city,
  { required: "Required" }
);
const businessStateValidation = computedValidation(
  v$.value.businessAddress.state,
  { required: "Required" }
);
const businessZipValidation = computedValidation(v$.value.businessAddress.zip, {
  validLength: "Must be 5 numbers long"
});

function updateFirstName() {
  savingBuffer.firstName.debounceUpdate(personnelView.updateFirstName);
}
function updateLastName() {
  savingBuffer.lastName.debounceUpdate(personnelView.updateLastName);
}
function updateTitle() {
  savingBuffer.title.debounceUpdate(personnelView.updateTitle);
}

function updatePhoneWork() {
  savingBuffer.phoneWork.debounceUpdate(personnelView.updatePhoneWork);
}
function updatePhoneWorkExtension() {
  savingBuffer.phoneWorkExtension.debounceUpdate(personnelView.updatePhoneWork);
}

function updateEmail() {
  savingBuffer.email.debounceUpdate(personnelView.updateEmail);
}

function updatePhoneMobile() {
  savingBuffer.phoneMobile.debounceUpdate(personnelView.updatePhoneMobile);
}

function updateCarrier() {
  savingBuffer.carrier.update(personnelView.updateCarrier);
}
function updateDepartment() {
  savingBuffer.department.update(personnelView.updateDepartment);
}

function updateHomeStreet() {
  savingBuffer.homeStreet.debounceUpdate(personnelView.updateHomeStreet);
}
function updateHomeCity() {
  savingBuffer.homeCity.debounceUpdate(personnelView.updateHomeCity);
}
function updateHomeState() {
  savingBuffer.homeState.debounceUpdate(personnelView.updateHomeState);
}
function updateHomeZip() {
  savingBuffer.homeZip.debounceUpdate(personnelView.updateHomeZip);
}

function handleHomeAutofill({ street_address, city, state, zip }) {
  homeAddress.value.street_address = street_address;
  homeAddress.value.city = city;
  homeAddress.value.state = state;
  homeAddress.value.zip = zip;

  savingBuffer.homeAddress.update(personnelView.updateHomeAddress);
}

function updateBusinessStreet() {
  savingBuffer.businessStreet.debounceUpdate(
    personnelView.updateBusinessStreet
  );
}
function updateBusinessCity() {
  savingBuffer.businessCity.debounceUpdate(personnelView.updateBusinessCity);
}
function updateBusinessState() {
  savingBuffer.businessState.debounceUpdate(personnelView.updateBusinessState);
}
function updateBusinessZip() {
  savingBuffer.businessZip.debounceUpdate(personnelView.updateBusinessZip);
}

function handleBusinessAutofill({ street_address, city, state, zip }) {
  businessAddress.value.street_address = street_address;
  businessAddress.value.city = city;
  businessAddress.value.state = state;
  businessAddress.value.zip = zip;
  savingBuffer.businessAddress.update(personnelView.updateBusinessAddress);
}
</script>
