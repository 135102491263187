<template>
  <v-card color="section" tile flat>
    <v-card-text class="transparent-tab-item pa-0">
      <v-row class="ma-0 mx-1 mt-1">
        <v-tabs v-model="activeTab" background-color="section" color="primary">
          <v-tab
            v-for="tab in tabs"
            :key="tab.text + 'tab'"
            class="text-none"
            :data-testid="`commission-tab-${tab.text}`"
          >
            {{ tab.text }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <text-field
          v-model="search"
          data-lpignore="true"
          class="mb-0 mt-1 mx-3"
          hide-details
          label="Search"
          data-testid="commission-search"
          :prepend-inner-icon="mdiMagnify"
        />
      </v-row>
      <v-divider />
      <v-tabs-window v-model="activeTab" touchless class="flex-grow-1">
        <v-tabs-window-item v-for="tab in tabs" :key="tab.text + 'body'">
          <component :is="tab.component" :search="search" :loading="loading" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CaseViewCommissionTable from "@/components/cases/case-view/CaseViewCommissionTable.vue";
import CaseViewRevenueTable from "@/components/cases/case-view/CaseViewRevenueTable.vue";
import CaseViewTotalsTable from "@/components/cases/case-view/CaseViewTotalsTable.vue";
import CaseViewTransactionsTable from "@/components/cases/case-view/CaseViewTransactionsTable.vue";

import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { markRaw, ref, watch, toRef, computed } from "vue";
import { useCaseViewStore } from "@/stores/case-view";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { mdiMagnify } from "@mdi/js";

const props = defineProps({ active: Boolean });
const pageIsActive = toRef(props, "active");

const snackbar = useSnackbarStore();
const caseView = useCaseViewStore();
const router = useRouter();
const user = useUserStore();

const loading = ref(false);

const tabs = [
  {
    text: "Commissions",
    component: markRaw(CaseViewCommissionTable),
    search: ref("")
  },
  {
    text: "Revenue",
    component: markRaw(CaseViewRevenueTable),
    search: ref("")
  },
  {
    text: "Totals",
    component: markRaw(CaseViewTotalsTable),
    search: ref("")
  }
];

if (user.isGroupThreePlus || user.isAccountingGroup)
  tabs.push({
    text: "Transactions",
    component: markRaw(CaseViewTransactionsTable),
    search: ref("")
  });

const activeTab = ref(syncQueryToTab());

const search = computed({
  get: () => tabs[activeTab.value].search.value,
  set: val => (tabs[activeTab.value].search.value = val)
});

async function getData() {
  try {
    loading.value = true;
    await caseView.getCaseCommissions();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function syncQueryToTab() {
  if (!router) return 0;
  const tab = router.currentRoute.value.query.tab;
  const index = tabs.findIndex(t => t.text.toLowerCase() === tab);
  return index === -1 ? 0 : index;
}

function syncTabToQuery() {
  if (!router) return;
  const tab = tabs[activeTab.value].text.toLowerCase();
  if (router.currentRoute.value.query.tab === tab) return;
  router.replace({
    query: {
      page: router.currentRoute.value.query.page,
      tab
    }
  });
}

watch(activeTab, syncTabToQuery);
watch(pageIsActive, () => {
  if (pageIsActive.value) {
    syncTabToQuery();
  }
});

getData();
</script>
