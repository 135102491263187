<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    class="transparent-data-table"
    data-testid="advisor-profile-campaigns-table"
    must-sort
    :mobile="null"
    hide-default-footer
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter format="date-time" :model-value="item.createdAt" />
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { useSnackbarStore } from "@/stores/snackbar";

import { getCampaigns } from "@/api/campaigns.service";
import { useTable } from "@/composables/table.composable";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";

const { id, type } = storeToRefs(useAdvisorProfileView());
const snackbar = useSnackbarStore();

const table = useTable({
  getData: getCampaigns,
  shouldIncludeCancelToken: true,
  options: TableOptions({ sortBy: [{ key: "createdAt", order: "desc" }] }),
  headers: [
    new TableHeader({ text: "Campaign", value: "campaign", map: "campaign" }),
    new TableHeader({
      text: "Recipients",
      value: "recipientCount",
      map: "recipient_count"
    }),
    new TableHeader({
      text: "Sent",
      value: "createdAt",
      map: "created_at",
      sortFilterMap: "created_at",
      ...TableHeader.IS_SORTABLE
    })
  ]
});

async function getData() {
  try {
    const additionalFilter = {
      advisor_id: id.value,
      advisor_type: type.value,
      view: "all"
    };
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}
</script>
