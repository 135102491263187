import { AVAILABLE_SERIES } from "@/composables/series-composable";

export function HomeGoal(model = {}) {
  const typeInfo = SITE_GOALS[model?.type];
  const to = typeInfo?.to;
  const href = typeInfo?.href;
  const text = typeInfo?.text;
  const color = typeInfo?.color;

  return {
    type: model?.type || null,
    completed: model?.completed || false,
    typeInfo,
    to,
    href,
    text,
    color
  };
}

function SiteGoalDisplayInfo({ text, color, to, href }) {
  return {
    text,
    color,
    to,
    href
  };
}

const TYPES = {
  COMPLETE_CONTRACTING: "complete_contracting",
  CREATE_IMPAIRED: "create_an_impaired_risk_quote",
  EARN_A_COMMISSION: "earn_a_commission",
  REQUEST_A_QUOTE: "request_a_quote",
  START_QUOTE_AND_APPLY_EAPP: "start_a_quote_and_apply_eapp",
  SUBMIT_A_CASE: "submit_a_case",
  COMPLETE_WALKTHROUGH: "complete_walkthrough"
};

const COLORS = {
  PRIMARY: "primary",
  GOLD: "#FFD700",
  COMPLETED: "grey"
};

const SITE_GOALS = {
  [TYPES.COMPLETE_WALKTHROUGH]: SiteGoalDisplayInfo({
    text: "Complete Walkthrough",
    color: COLORS.GOLD,
    to: {
      name: "HelpCenterSeries",
      params: { id: AVAILABLE_SERIES.WALKTHROUGH }
    }
  }),
  [TYPES.COMPLETE_CONTRACTING]: SiteGoalDisplayInfo({
    text: "Complete Contracting",
    color: COLORS.PRIMARY,
    to: { name: "LoggedInUserSettings" }
  }),
  [TYPES.CREATE_IMPAIRED]: SiteGoalDisplayInfo({
    text: "Create an Impaired Risk Quote",
    color: COLORS.GOLD,
    to: { name: "ImpairedRiskQuoteCreate" }
  }),
  [TYPES.EARN_A_COMMISSION]: SiteGoalDisplayInfo({
    text: "Earn a Commission",
    color: COLORS.PRIMARY,
    to: { name: "Commissions", query: { page: "payments" } }
  }),
  [TYPES.REQUEST_A_QUOTE]: SiteGoalDisplayInfo({
    text: "Create a Quote Request",
    color: COLORS.PRIMARY,
    to: { name: "CreateQuote" }
  }),
  [TYPES.START_QUOTE_AND_APPLY_EAPP]: SiteGoalDisplayInfo({
    text: "Start a Quote & Apply eApp",
    color: COLORS.PRIMARY,
    href: user => user?.approved_domain_url
  }),
  [TYPES.SUBMIT_A_CASE]: SiteGoalDisplayInfo({
    text: "Submit a Case",
    color: COLORS.GOLD,
    to: { name: "CaseSubmission" }
  })
};
