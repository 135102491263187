<template>
  <v-card flat color="section">
    <v-card-title>
      <v-row class="pa-3">
        Informals
        <v-spacer />
        <app-button
          v-if="isGroupTwoPlus"
          color="accent"
          class="text-none"
          data-testid="add-informal"
          @click="addInformalOffer"
        >
          <v-icon :icon="mdiPlus" /> Add Informal</app-button
        >
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels multiple>
        <v-expansion-panel
          v-for="(informal, index) in informals"
          :key="`${informal.id}`"
        >
          <v-expansion-panel-title
            :data-testid="`informal-offer-header-${index}`"
          >
            {{ informal.carrier.name }},
            {{ informal.offer || "Awaiting Underwriting Offer" }},
            <strong class="ml-1">{{ informal.name }}</strong>
          </v-expansion-panel-title>
          <v-expansion-panel-text
            :data-testid="`informal-offer-body-${index}`"
          >
            <v-row dense>
              <v-col cols="12">
                <v-sheet color="section" class="pa-3 rounded">
                  <h4 class="mb-2" data-testid="underwriter">
                    <template v-if="informal.underwriter.name">
                      Underwriting by {{ informal.underwriter.name }}
                    </template>
                    <template v-else> No Underwriter Provided </template>
                  </h4>
                  <div data-testid="underwriting-rationale">
                    {{
                      informal.underwritingRationale ||
                      "Underwriting Rationale Not Provided"
                    }}
                  </div>
                </v-sheet>
              </v-col>

              <v-col v-if="isGroupTwoPlus" align="end" class="pb-0">
                <app-button
                  color="success"
                  class="text-none mr-1"
                  variant="outlined"
                  data-testid="edit-informal"
                  :loading="informal.loading"
                  @click="editInformalOffer(informal)"
                >
                  <v-icon :icon="mdiPencil" /> Edit
                </app-button>
                <app-button
                  color="error"
                  class="text-none"
                  variant="outlined"
                  data-tetstid="delete-informal"
                  :loading="informal.loading"
                  @click="destroyInformalOffer(informal)"
                >
                  <v-icon :icon="mdiDelete" /> Delete
                </app-button>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AddInformalDialog from "@/components/cases/case-dialogs/AddInformalDialog.vue";
import { deleteInformalOffer } from "@/api/cases.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useCaseViewStore } from "@/stores/case-view";
import { markRaw } from "vue";
import { mdiPlus, mdiPencil, mdiDelete } from "@mdi/js";

const caseView = useCaseViewStore();
const { informals, id } = storeToRefs(caseView);

const user = useUserStore();
const { isGroupTwoPlus } = storeToRefs(user);

const dialog = useDialogStore();

function destroyInformalOffer({ id: informalId, carrier, name }) {
  if (!isGroupTwoPlus.value) return;
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Delete ${carrier.name}'s Informal Offer (${name})?`,
    subtitle: "This cannot be undone",
    func: async () => {
      await deleteInformalOffer(id.value, informalId);
      const index = informals.value.findIndex(i => i.id === informalId);
      if (index > -1) informals.value.splice(index, 1);
    }
  });
}

function addInformalOffer() {
  if (!isGroupTwoPlus.value) return;

  dialog
    .showDialog({
      component: markRaw(AddInformalDialog),
      caseId: id.value
    })
    .then(result => {
      if (!result?.informalOffer) return;
      informals.value.push(result.informalOffer);
    });
}
function editInformalOffer(offer) {
  if (!isGroupTwoPlus.value) {
    return;
  }

  dialog
    .showDialog({
      component: markRaw(AddInformalDialog),
      caseId: id.value,
      offer
    })
    .then(result => {
      if (!result?.informalOffer) return;

      const informalIndex = informals.value.findIndex(i => i.id === offer.id);
      informals.value[informalIndex] = result.informalOffer;
    });
}
</script>
