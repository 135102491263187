<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    style="background-color: transparent"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :mobile="null"
    mobile-breakpoint="sm"
  >
    <template #top>
      <v-row class="pa-3" align="center">
        <slot name="new-access" />
        <app-button
          class="text-none ml-2"
          data-testid="access-add-advisor"
          color="accent"
          :loading="addingAccess"
          @click="addAccess"
        >
          <v-icon :icon="mdiPlus" /> Add Access
        </app-button>
      </v-row>
    </template>
    <template #[`item.name`]="{ item }">
      <router-link
        v-if="item.additional.routerLink"
        :to="item.additional.routerLink"
        data-testid="access-name"
      >
        {{ item.name }}
      </router-link>
      <span v-else data-testid="access-name">
        {{ item.name }}
      </span>
    </template>
    <template
      v-for="checkbox in checkboxes"
      #[`item.${checkbox.value}`]="{ item }"
      :key="checkbox.value"
    >
      <div style="display: flex; align-items: center">
        <div style="width: 2rem">
          <checkbox-field
            v-if="!checkbox.if || checkbox.if(item.additional)"
            v-model="item.additional[checkbox.value]"
            hide-details
            :data-testid="`access-checkbox-${checkbox.text}`"
            :disabled="item.additional.disabled"
            @update:model-value="updateAttribute(item.additional, checkbox)"
          />
        </div>
        <active-save-indicator
          :controller="
            savingBuffer[item.additional.bufferKeys[checkbox.value]].controller
              .value
          "
        />
      </div>
    </template>

    <template #[`item.actions`]="{ item }">
      <app-button
        v-if="item.additional.isDeletable"
        :icon="mdiDelete"
        color="error"
        variant="text"
        density="comfortable"
        data-testid="access-delete"
        @click="deleteAccess(item.additional)"
      />
    </template>
  </v-data-table>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, watch, toRefs } from "vue";
import { mdiPlus, mdiDelete } from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { useActiveSave } from "@/composables/active-save.composable";

const props = defineProps({
  modelValue: { type: Array, required: true },
  updateFunc: { type: Function, required: true },
  deleteFunc: { type: Function, required: true },
  addFunc: { type: Function, required: true },
  checkboxes: { type: Array, required: false, default: () => [] },
  deletable: { type: Function, required: false, default: () => true },
  newAccess: { type: Object, required: false, default: null }
});

const emit = defineEmits(["update:model-value", "update:new-access"]);

const snackbar = useSnackbarStore();

const { modelValue: propValue } = toRefs(props);

const addingAccess = ref(false);
const accesses = ref([...propValue.value]);

const table = useTable({
  options: TableOptions({ sortBy: [{ key: "name", order: "asc" }] }),
  headers: [
    new TableHeader({
      text: "Advisor",
      value: "name",
      map: "name",
      ...TableHeader.IS_SORTABLE
    }),
    ...props.checkboxes.map(checkbox => {
      return new TableHeader({
        text: checkbox.text,
        value: checkbox.value,
        map: checkbox.value
      });
    }),
    new TableHeader({ text: "Actions", value: "actions", map: "actions" })
  ]
});

const savingBuffer = {};

function findOrCreateSavingBufferKey(item, checkbox) {
  const key = `${item.id} ${checkbox.value}`;
  if (!savingBuffer[key]) savingBuffer[key] = useActiveSave();
  return key;
}

async function addAccess() {
  const newAccess = props.newAccess;
  if (!newAccess) return;

  const accessAlreadyExists = accesses.value.some(
    a => `${newAccess.id}` === `${a.id}` && newAccess.type === a.type
  );

  if (accessAlreadyExists) return;
  addingAccess.value = true;
  try {
    const access = await props.addFunc(newAccess);
    snackbar.showSuccessSnackbar({
      message: `Successfully added ${newAccess.name}`
    });
    emit("update:new-access", null);
    accesses.value.push(access);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  } finally {
    addingAccess.value = false;
  }
}

async function deleteAccess(item) {
  if (item.disabled) return;
  try {
    await props.deleteFunc(item);
    const index = accesses.value.findIndex(i => i.id === item.id);
    if (index === -1) return;
    accesses.value.splice(index, 1);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  }
}

function updateAttribute(item, checkbox) {
  if (item.disabled) return;
  const accessIndex = accesses.value.findIndex(a => a.id === item.id);
  if (accessIndex === -1) return;

  const key = checkbox.value;
  accesses.value[accessIndex][key] = item[key];

  const bufferKey = findOrCreateSavingBufferKey(item, checkbox);
  savingBuffer[bufferKey].update(() => props.updateFunc(item, key));
}

watch(
  propValue,
  newValue => {
    const newStr = JSON.stringify(newValue);
    const oldStr = JSON.stringify(accesses.value);
    if (newStr.localeCompare(oldStr) === 0) return;
    accesses.value.splice(0, accesses.value.length);
    accesses.value.push(...newValue);
  },
  { deep: true }
);

watch(
  accesses,
  v => {
    emit("update:model-value", v);

    table.items.value.splice(0, table.items.value.length);
    accesses.value.forEach(item => {
      let isDeletable = !item.disabled;
      if (isDeletable && typeof props.deletable === "function") {
        isDeletable = props.deletable(item);
      }
      table.items.value.push({
        ...item,
        routerLink: item.disabled ? null : item.routerLink,
        isDeletable,
        bufferKeys: props.checkboxes.reduce(
          (accumulator, checkbox) => ({
            ...accumulator,
            [checkbox.value]: findOrCreateSavingBufferKey(item, checkbox)
          }),
          {}
        )
      });
    });
  },
  { immediate: true, deep: true }
);
</script>
