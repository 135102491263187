import { ElectronicApplication } from "@/factories/ElectronicApplication";
import { defineStore } from "pinia";
import {
  updateEapp,
  resendEnvelope,
  unlockEApp,
  getQuoteHistory,
  getElectronicApplication,
  addElectronicApplicationAccess,
  updateElectronicApplicationAccess,
  removeElectronicApplicationAccess,
  updateEAppCommissionSplit,
  createEAppCommissionSplit,
  deleteEAppCommissionSplit
} from "@/api/electronic-application.service";
import { deleteElectronicApplicationQuote } from "@/api/electronic-application-quotes.service";

export const useEappViewStore = defineStore("eapp-view", {
  state: () => ({ ...ElectronicApplication(), todoCount: 0, owner: null }),
  actions: {
    async initialize(id) {
      this.$reset();
      const eApp = await getElectronicApplication(id);
      this.$patch(eApp);

      const owner = eApp.electronicApplicationAccesses.find(a => a.owner);
      this.$patch({ owner });
    },
    // Consolidate to update EappAccess
    updateOwner() {
      const body = { owner: 1 };
      return updateElectronicApplicationAccess(this.id, this.owner.id, body);
    },
    deleteEappAccess(accessId) {
      return removeElectronicApplicationAccess(this.id, accessId);
    },
    updateEappAccess(accessId, attribute) {
      const access = this.electronicApplicationAccesses.find(
        a => a.id === accessId
      );
      const map = {
        advisorAccess: { advisor_access: access.advisorAccess },
        carrierAccess: { carrier_access: access.carrierAccess },
        carrierEmail: { carrier_email: access.carrierEmail },
        additionalCaseAccess: {
          additional_case_access: access.additionalCaseAccess
        },
        email: { email: access.email }
      };

      return updateElectronicApplicationAccess(
        this.id,
        accessId,
        map[attribute]
      );
    },
    updateOverrideCommissionSplits() {
      const body = {
        override_commission_splits: this.overrideCommissionSplits
      };

      return updateEapp(this.id, body);
    },
    createApprovedDomainCommissionSplit(agent) {
      return createEAppCommissionSplit(this.id, {
        agent_id: agent.id,
        split_percent: 0.01
      });
    },
    updateApprovedDomainCommissionSplit({ id, splitPercent }) {
      return updateEAppCommissionSplit(this.id, id, {
        split_percent: +(splitPercent / 100).toFixed(2)
      });
    },
    deleteApprovedDomainCommissionSplit({ id }) {
      return deleteEAppCommissionSplit(this.id, id);
    },
    createEappAccess(advisor) {
      const body = {
        electronic_application_id: this.id,
        ownable_id: advisor.id,
        ownable_type: advisor.type,
        owner: false
      };

      return addElectronicApplicationAccess(this.id, body);
    },
    updateEapp(attribute) {
      const map = {
        commissionsPaidTo: {
          commissions_paid_to_id: this.commissionsPaidTo?.id || null,
          commissions_paid_to_type: this.commissionsPaidTo?.type || null
        }
      };

      const body = map[attribute];
      return updateEapp(this.id, body);
    },
    resendEnvelope() {
      return resendEnvelope(this.id);
    },
    async getQuoteHistory() {
      const quoteHistory = await getQuoteHistory(this.id);
      // We can memoise these values + filters
      this.$patch({ quoteHistory });
      this.quoteHistory = quoteHistory;
      return { items: this.quoteHistory };
    },
    deleteQuote(quoteId) {
      const quote = this.quoteHistory.find(q => q.id === quoteId);
      if (!quote) return;
      return deleteElectronicApplicationQuote(quoteId);
    },
    async unlockEApp() {
      await unlockEApp(this.id);
      this.locked = false;
    }
  }
});
