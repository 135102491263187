<template>
  <v-card v-bind="cardProps">
    <v-row class="ma-0" :class="{ 'flex-nowrap': mdAndUp }">
      <v-tabs v-model="activeTab" v-bind="tabProps" color="primary">
        <v-tab
          v-for="(model, index) in tabs"
          :key="index"
          class="text-none justify-start"
        >
          {{ model.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-window v-model="activeTab" touchless class="flex-grow-1">
        <v-tabs-window-item
          v-for="(model, index) in tabs"
          :key="index + 'body'"
        >
          <component :is="model.component" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-row>
  </v-card>
</template>

<script setup>
import AdvisorProfileRecentAdvisorsTable from "@/components/advisor-profile/recents/AdvisorProfileRecentAdvisorsTable.vue";
import AdvisorProfileRecentAppointmentsTable from "@/components/advisor-profile/recents/AdvisorProfileRecentAppointmentsTable.vue";
import AdvisorProfileRecentCampaignsTable from "@/components/advisor-profile/recents/AdvisorProfileRecentCampaignsTable.vue";
import AdvisorProfileRecentCasesTable from "@/components/advisor-profile/recents/AdvisorProfileRecentCasesTable.vue";
import AdvisorProfileRecentEAppsTable from "@/components/advisor-profile/recents/AdvisorProfileRecentEAppsTable.vue";
import AdvisorProfileRecentPaymentsTable from "@/components/advisor-profile/recents/AdvisorProfileRecentPaymentsTable.vue";
import AdvisorProfileRecentPayPeriodsTable from "@/components/advisor-profile/recents/AdvisorProfileRecentPayPeriodsTable.vue";
import AdvisorProfileRecentQuotesTable from "@/components/advisor-profile/recents/AdvisorProfileRecentQuotesTable.vue";

import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";

import { computed, markRaw, ref, watch, toRefs } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps({
  resource: {
    type: String,
    required: false,
    default: "cases"
  },
  active: {
    type: Boolean,
    required: false,
    default: false
  }
});

const { active } = toRefs(props);

const router = useRouter();
const activeTab = ref(0);
const user = useUserStore();

const { mdAndUp } = useDisplay();

const cardProps = computed(() => {
  if (mdAndUp.value) {
    return { elevation: 8, class: "ma-3" };
  }
  return { color: "section" };
});

const tabProps = computed(() => {
  if (mdAndUp.value) {
    return { direction: "vertical", class: "rounded-r-0 divided-tabs" };
  }
  return { showArrows: true };
});

const tabs = computed(() => {
  const tabs = [
    {
      title: "Advisors",
      component: markRaw(AdvisorProfileRecentAdvisorsTable),
      page: "advisors"
    },
    {
      title: "Appointments",
      component: markRaw(AdvisorProfileRecentAppointmentsTable),
      page: "appointments"
    },
    {
      title: "Cases",
      component: markRaw(AdvisorProfileRecentCasesTable),
      page: "cases"
    },
    {
      title: "eApps",
      component: markRaw(AdvisorProfileRecentEAppsTable),
      page: "eapps"
    },
    {
      title: "Marketing",
      component: markRaw(AdvisorProfileRecentCampaignsTable),
      page: "marketing"
    },
    {
      title: "Quotes",
      component: markRaw(AdvisorProfileRecentQuotesTable),
      page: "quotes"
    }
  ];

  if (!user.loginable.hide_commission) {
    tabs.push(
      {
        title: "Payments",
        component: markRaw(AdvisorProfileRecentPaymentsTable),
        page: "payments"
      },
      {
        title: "Pay Periods",
        component: markRaw(AdvisorProfileRecentPayPeriodsTable),
        page: "pay-periods"
      }
    );
  }

  return tabs;
});

activeTab.value = tabs.value.findIndex(model => model.page === props.resource);

function syncQueryToTab() {
  if (!router) return;
  const resource = tabs.value[activeTab.value].page;
  if (props.resource === resource || !resource) return;
  router.replace({
    query: { page: router.currentRoute.value.query.page, resource }
  });
}

watch(activeTab, syncQueryToTab);
watch(active, v => {
  if (v) syncQueryToTab();
});
</script>

<style lang="scss">
.divided-tabs {
  .v-item-group:first-child {
    margin-right: 1px !important;
  }
}
.theme--light {
  .divided-tabs {
    background-color: rgba(0, 0, 0, 0.12);
  }
}
.theme--dark {
  .divided-tabs {
    background-color: rgba(255, 255, 255, 0.12);
  }
}
</style>
