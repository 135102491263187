import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";

const baseUrl = "api/boss/notes";

export async function createNote(note) {
  return getHttpClient().post(baseUrl, await serializeObject({ note }));
}

export function deleteNote(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export async function getFullEmail(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/full_email`);
  return data;
}

export async function authorizeChat({
  channelName,
  id,
  type,
  socketId,
  callback
} = {}) {
  try {
    const { data } = await getHttpClient().post(
      `${baseUrl}/authorize-chat-subscription`,
      {
        channel_name: channelName,
        notable_id: id,
        notable_type: type,
        socket_id: socketId
      }
    );
    callback(null, data);
  } catch (e) {
    callback(e, { auth: false });
  }
}
