<template>
  <v-app>
    <component :is="router.currentRoute.value.meta.layout" />
    <app-services />
  </v-app>
</template>

<script setup>
import AppServices from "@/app-services/AppServices.vue";

import { storeToRefs } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { heartbeat } from "@/api/auth.service";
import { useRouter } from "vue-router";
import { onBeforeUnmount, watch } from "vue";

import { useUserStore } from "@/stores/user";
import { useInstanceStore } from "@/stores/instance";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";

const router = useRouter();

const instance = useInstanceStore();
const user = useUserStore();
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const { hasSentRequest, fullscreen, breadcrumb } = storeToRefs(instance);
const { id: userId } = storeToRefs(user);

watch(userId, v => {
  if (v) initHeartbeat();
  else stopHeartbeat();
});

watch(
  [
    () => router.currentRoute.value.params,
    () => router.currentRoute.value.name
  ],
  (newV, oldV) => {
    if (JSON.stringify(newV) === JSON.stringify(oldV)) return;

    fullscreen.value = false;
    breadcrumb.value = "";
    dialog.closeAll();
  }
);

onBeforeUnmount(() => stopHeartbeat());

let interval;
const EVERY_FIVE_MINUTES = 5 * 60 * 1000;
function initHeartbeat() {
  if (interval) clearInterval(interval);

  interval = setInterval(() => heartbeatRequest(), EVERY_FIVE_MINUTES);
}

function stopHeartbeat() {
  if (interval) clearInterval(interval);
}

async function heartbeatRequest() {
  if (
    !userId.value ||
    !hasSentRequest.value ||
    process.env.NODE_ENV !== "production"
  )
    return;

  try {
    await heartbeat();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    hasSentRequest.value = false;
  }
}

</script>
