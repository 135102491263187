const formFilterOptions = [
  "Complete",
  "Incomplete",
  "Complete - Not Latest",
  "Complete - Ready for Review"
];

const statusFilterOptions = [
  { title: "Pending", value: "pending" },
  { title: "Active", value: "active" },
  { title: "Inactive", value: "inactive" }
];

const pendingStatusFilter = [
  "Entered",
  "Submitted",
  "Approved",
  "Issued",
  "Awaiting Reissued Policy",
  "Awaiting Reconsideration",
  "Awaiting Delivery Requirements",
  "Received Delivery Requirements",
  "Informal Entered",
  "All Offers In",
  "Gathering Medical Information",
  "Awaiting Carrier Offers"
];

const startPageItems = [
  { value: "apply", title: "Apply" },
  { value: "agent_sign_up", title: "Agent Sign Up" },
  { value: "life_insurance_calculator", title: "Life Insurance Calculator" },
  { value: "refer", title: "Refer" },
  { value: "select_agent", title: "Select Agent Dropdown" },
  { value: "track_demo", title: "Track Demo" }
];

const activeStatusFilter = [
  "Active - Pending Requirements",
  "Active",
  "Informal Formalized"
];

const inactiveStatusFilter = [
  "Withdrawn",
  "Not Taken",
  "Postponed",
  "Declined",
  "Closed - Not Submitted to Carrier",
  "Closed - Submitted by Another GA",
  "Grace Period",
  "Lapsed",
  "Deceased/Death Claim",
  "Informal Closed"
];

const allStatusFilterOptions = [
  { header: "Pending" },
  ...pendingStatusFilter,
  { header: "Active" },
  ...activeStatusFilter,
  { header: "Inactive" },
  ...inactiveStatusFilter
];

const statusFilterGroups = [
  { title: "Pending", value: pendingStatusFilter },
  { title: "Inactive", value: inactiveStatusFilter },
  { title: "Active", value: activeStatusFilter }
];

const lineFilterOptions = [
  { title: "Annuity", value: "annuity" },
  { title: "Disability", value: "disability" },
  { title: "Life", value: "life" },
  { title: "LTC", value: "ltc" },
  { title: "Linked Benefit", value: "linked_benefit" },
  { title: "Informal", value: "informal" },
  { title: "Variable Life", value: "variable_life" }
];

const taxFilterOptions = [
  "401k",
  "403b",
  "412i Plan",
  "457 Deferred Compensation",
  "Inherited IRA Plan",
  "Inherited Roth IRA Plan",
  "IRA",
  "Non-Qualified",
  "Pension Plan",
  "Profit Sharing Plan",
  "Roth 401k",
  "Roth 403b",
  "Roth IRA",
  "SEP-IRA",
  "Simple IRA"
];

const eappStatusFilterOptions = [
  "Quote",
  "Lead",
  "Sent",
  "Delivered",
  "Completed"
];

const paymentTypes = [
  "Affiliate",
  "BackNine Policy Fee",
  "Fee",
  "First Case Bonus",
  "Flat",
  "Flat - Estimated",
  "Net Retained",
  "Retained",
  "Street"
];

const commissionTypes = [
  "First Year",
  "Excess",
  "Renewal",
  "PUA",
  "Term Rider",
  "Bonus",
  "Minimum Fee"
];

const ratingOptions = [
  "Super Preferred",
  "Preferred",
  "Preferred NT",
  "Preferred Tobacco",
  "Select",
  "Standard Plus",
  "Standard NT",
  "Standard Tobacco",
  "Class 1",
  "Class 2",
  "Table 1 Tobacco",
  "Table 2 Tobacco",
  "Table 3 Tobacco",
  "Table 4 Tobacco",
  "Table 5 Tobacco",
  "Table 6 Tobacco",
  "Table 7 Tobacco",
  "Table 8 Tobacco",
  "Table 9 Tobacco",
  "Table 10 Tobacco",
  "Table 1",
  "Table 2",
  "Table 3",
  "Table 4",
  "Table 5",
  "Table 6",
  "Table 7",
  "Table 8",
  "Table 9",
  "Table 10",
  "Table 11",
  "Table 12",
  "Table 13",
  "Table 14",
  "Table 15",
  "Table 16",
  "Table 17",
  "Table 18",
  "Uninsurable 19",
  "Uninsurable 20",
  "Guaranteed Issue",
  "Postponed",
  "Declined",
  "Level",
  "Level Tobacco",
  "Graded"
];

const pendingAppointmentStatuses = [
  "Pending",
  "Just In Time",
  "Active - Pending Requirements"
];

const inactiveAppointmentStatuses = ["Closed", "Terminated"];

const activeAppointmentStatuses = [
  "Active",
  "Active - Pending Requirements",
  "Just In Time"
];

const smokingOptions = [
  "Cigarettes",
  "eCigarettes",
  "Pipe",
  "Chew",
  "Cigars",
  "Cannabis"
];

const productTypes = [
  {
    title: "Accidental Death",
    value: "accidental_death"
  },
  {
    title: "Accumulation IUL",
    value: "universal_life.accumulation_indexed"
  },
  {
    title: "Final Expense",
    value: "whole_life.final_expense"
  },
  {
    title: "Guaranteed Issue",
    value: "whole_life.final_expense.guaranteed_issue"
  },
  {
    title: "Guaranteed UL",
    value: "universal_life.no_lapse_guarantee"
  },
  {
    title: "Linked Benefit",
    value: "universal_life.linked_benefit"
  },
  {
    title: "LTC",
    value: "ltc"
  },
  {
    title: "Non Med",
    value: "term.none.non_med"
  },
  {
    title: "Protection IUL",
    value: "universal_life.protection_indexed"
  },
  {
    title: "Protection VUL",
    value: "universal_life.variable"
  },
  {
    title: "ROP Term",
    value: "term.return_of_premium"
  },
  {
    title: "Term",
    value: "term.none"
  },
  {
    title: "Universal Life",
    value: "universal_life.none"
  },
  {
    title: "Whole Life",
    value: "whole_life.none"
  }
];

export {
  productTypes,
  statusFilterOptions,
  lineFilterOptions,
  taxFilterOptions,
  eappStatusFilterOptions,
  paymentTypes,
  allStatusFilterOptions,
  pendingStatusFilter,
  activeStatusFilter,
  inactiveStatusFilter,
  statusFilterGroups,
  formFilterOptions,
  commissionTypes,
  ratingOptions,
  pendingAppointmentStatuses,
  inactiveAppointmentStatuses,
  activeAppointmentStatuses,
  smokingOptions,
  startPageItems
};
