<template>
  <v-card tile color="section">
    <v-card-title class="text-h4"> Website Colors </v-card-title>
    <v-card-subtitle>
      Customize the colors of your Quote & Apply Widget and Theme.
    </v-card-subtitle>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12" md="6">
          <h3 class="mb-3">Light Mode</h3>
          <approved-domain-theme-colors
            v-model:primary="colors.light.primary"
            v-model:accent="colors.light.accent"
            data-testid="theme-light"
            :default-primary="COLOR_DEFAULTS.LIGHT.PRIMARY"
            :default-accent="COLOR_DEFAULTS.LIGHT.ACCENT"
            @save="$event => handleSave('light', $event)"
          />
        </v-col>

        <v-col cols="12" md="6">
          <h3 class="mb-3">Dark Mode</h3>
          <approved-domain-theme-colors
            v-model:primary="colors.dark.primary"
            v-model:accent="colors.dark.accent"
            data-testid="theme-dark"
            :default-primary="COLOR_DEFAULTS.DARK.PRIMARY"
            :default-accent="COLOR_DEFAULTS.DARK.ACCENT"
            @save="$event => handleSave('dark', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title class="text-h4"> Website Theme </v-card-title>
    <v-card-subtitle>
      Customize the view of your direct link. Direct links are hosted by
      BackNine: e.g. app.back9ins.com/apply/
    </v-card-subtitle>
    <v-card-text>
      <v-row class="ma-0">
        <v-row>
          <v-col v-for="{ id, name, img } in themes" :key="id" cols="12">
            <v-card variant="flat" class="border-thin">
              <v-row class="ma-0">
                <v-col cols="12" md="4">
                  <v-card
                    elevation="8"
                    width="100%"
                    :data-testid="`theme-${name}`"
                    @click="setTheme(id)"
                  >
                    <div style="height: 300px">
                      <v-img
                        :src="img"
                        position="top center"
                        max-height="300px"
                        height="auto"
                        style="
                          border-top-right-radius: inherit;
                          border-top-left-radius: inherit;
                        "
                      />
                      <v-divider />
                    </div>
                    <v-card-title
                      class="text-center pb-0"
                      style="font-weight: 400; font-size: 24px"
                    >
                      {{ name }}
                    </v-card-title>
                    <v-card-actions class="justify-center flex-row pb-3">
                      <div>
                        <v-radio-group v-model="theme" color="accent">
                          <v-radio :value="id" class="ma-0" />
                        </v-radio-group>
                        <active-save-indicator
                          :controller="savingBuffer[id].controller.value"
                          style="position: absolute; right: 12px; bottom: 12px"
                        />
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col
                  v-if="
                    [gradientLifeTheme.id, lifeEssentialsTheme.id].includes(id)
                  "
                  cols="12"
                  md="8"
                >
                  <v-card-title> {{ name }} Settings</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <div>
                          <h3>Custom Hero Image</h3>
                          <img
                            v-if="heroImageUrl"
                            data-testid="hero-image"
                            :src="heroImageUrl"
                            style="
                              width: 15rem;
                              height: 15rem;
                              object-fit: cover;
                            "
                          />
                          <p v-else data-testid="no-hero-image" class="mb-3">
                            No custom hero image specified
                          </p>
                          <div>
                            <app-button
                              data-testid="upload-hero-image"
                              class="text-none"
                              color="accent"
                              @click="updateHeroImage"
                            >
                              <v-icon class="mr-1" :icon="mdiUpload" />Upload
                              Hero Image
                            </app-button>
                          </div>
                          <div v-if="heroImageUrl" class="mt-1">
                            <app-button
                              data-testid="remove-hero-image"
                              class="text-none"
                              color="error"
                              @click="removeHeroImage"
                            >
                              <v-icon class="mr-1" :icon="mdiDelete" />Remove
                              Hero Image
                            </app-button>
                          </div>
                        </div>
                      </v-col>

                      <v-col cols="12" md="6">
                        <div class="checkbox-width">
                          <checkbox-field
                            v-model="hideAdditionalServices"
                            hide-details
                            data-testid="hide-additional-services"
                            dense
                            @update:model-value="saveHideAdditionalServices"
                          >
                            <template #label>
                              Hide Additional Services
                              <active-save-indicator
                                class="ml-1"
                                :controller="
                                  savingBuffer.hideAdditionalServices.controller
                                    .value
                                "
                              />
                            </template>
                          </checkbox-field>
                        </div>
                        <div>
                          Hide Annuities, Disability Income, and Long Term Care
                          information bubbles.
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col v-else cols="12" md="8">
                  <v-card-title> {{ name }} Settings</v-card-title>
                  <v-card-subtitle>
                    No additional settings for the {{ name }} theme
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ApprovedDomainThemeColors from "@/components/approved-domain/ApprovedDomainThemeColors.vue";
import UploadDocumentDialog from "@/dialogs/UploadDocumentDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { COLOR_DEFAULTS } from "@/factories/ApprovedDomain";
import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { APPROVED_DOMAIN_THEMES } from "@/factories/ApprovedDomain";
import { useUserStore } from "@/stores/user";
import { markRaw } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useDialogStore } from "@/stores/dialog";
import { mdiUpload, mdiDelete } from "@mdi/js";

const dialog = useDialogStore();
const approvedDomain = useApprovedDomainView();
const { theme, colors, heroImageUrl, hideAdditionalServices } =
  storeToRefs(approvedDomain);

const gradientLifeTheme = APPROVED_DOMAIN_THEMES.GRADIENT_LIFE();
const lifeEssentialsTheme = APPROVED_DOMAIN_THEMES.LIFE_ESSENTIALS();
const themes = [
  APPROVED_DOMAIN_THEMES.BASIC(),
  gradientLifeTheme,
  lifeEssentialsTheme
];
// soon to add as a feature for all
if (useUserStore().isGroupFour) {
  themes.push(APPROVED_DOMAIN_THEMES.LTC());
}

const savingBuffer = {
  hideAdditionalServices: useActiveSave()
};

for (const t in themes) {
  savingBuffer[themes[t].id] = useActiveSave();
}

function setTheme(id) {
  theme.value = id;
  savingBuffer[id].update(() => approvedDomain.updateApprovedDomain("theme"));
}

function handleSave(theme, { color, savingBuffer }) {
  let key;
  if (theme === "light") {
    key = `color_${color}`;
  } else if (theme === "dark") {
    key = `color_dark_${color}`;
  }
  if (!key) throw "Invalid color update" + theme + color;

  savingBuffer.debounceUpdate(() => approvedDomain.updateApprovedDomain(key));
}

function saveHideAdditionalServices() {
  savingBuffer.hideAdditionalServices.update(() =>
    approvedDomain.updateApprovedDomain("hideAdditionalServices")
  );
}

function updateHeroImage() {
  dialog.showDialog({
    component: markRaw(UploadDocumentDialog),
    title: "Upload Hero Image",
    uploadFunction: approvedDomain.updateHeroImage,
    single: true
  });
}

function removeHeroImage() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Remove Hero Image",
    subtitle: "Are you sure you want to remove the hero image?",
    showErrorMessage: true,
    func: () => approvedDomain.removeHeroImage()
  });
}
</script>
