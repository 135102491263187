import { serializeObject } from "@/util/helpers";
import { getHttpClient } from "@/http-client";

import { setCaseTaskPreviewFromRequest } from "@/factories/Case";
const baseUrl = "api/boss/orders";
export async function createOrder(body) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject(body)
  );

  return setCaseTaskPreviewFromRequest(data[0]);
}
