<template>
  <v-data-table
    :loading="loading"
    :search="search"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    data-testid="totals-table"
    class="transparent-data-table"
    item-value="key"
    :mobile="null"
    mobile-breakpoint="sm"
  />
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { toRefs, watch } from "vue";
import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";
import { currencyFormat } from "@/util/helpers";

const { totals } = storeToRefs(useCaseViewStore());

const props = defineProps({
  search: {
    type: [Object, String],
    required: false,
    default: () => null
  },
  loading: Boolean
});

const { search, loading } = toRefs(props);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Advisor",
      value: "payableName",
      map: "payableName",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Amount",
      value: "amount",
      map: "amount",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Payment Types",
      value: "paymentTypesStr",
      map: "paymentTypesStr",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    })
  ]
});

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  totals.value.forEach(i => {
    table.items.value.push({
      ...i,
      amount: currencyFormat(i.amount),
      paymentTypesStr: i.paymentTypes.join(",")
    });
  });
}

watch(loading, () => {
  table.loading.value = loading.value;
});

watch(totals, setItems, { immediate: true, deep: true });
</script>
