import { defineStore } from "pinia";

export const useGoogleMaps = defineStore("google-maps", {
  state: () => ({ initializing: false, initialized: false, promise: null }),
  actions: {
    async initGoogleMaps() {
      const mapsEl = document.getElementById("quote-and-apply-google-maps");
      if (mapsEl && this.initialized) return;
      if (mapsEl && this.initializing) {
        return this.promise;
      }

      this.promise = new Promise((resolve, reject) => {
        this.initializing = true;
        const script = document.createElement("script");
        script.id = "quote-and-apply-google-maps";
        script.setAttribute("async", true);
        script.setAttribute("defer", true);
        const key = "AIzaSyBsNmNnvyhzDbrdn6SKrp2Iy04sbnqojKI";
        const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback`;
        script.setAttribute("src", mapsUrl);
        script.onload = () => {
          this.initializing = false;
          this.initialized = true;
          resolve();
        };
        script.onerror = () => {
          this.initializing = false;
          this.initialized = true;
          reject();
        };
        document.body.appendChild(script);
      });

      return this.promise;
    }
  }
});
