<template>
  <div class="card-item">
    <span class="text-grey lighten-2"> {{ props.title }} </span>
    <br />
    <router-link :to="props.to">
      {{ props.text }}
    </router-link>
  </div>
</template>

<script setup>

const props = defineProps({
  title: { type: String, required: true },
  text: { type: String, required: true },
  to: { type: Object, required: true }
});
</script>
