const INFLATION_ITEMS = [
  { title: "None", value: null },
  { title: "Deferred", value: "Deferred" },
  { title: "1% Compound", value: "1% Compound" },
  { title: "2% Compound", value: "2% Compound" },
  { title: "3% Simple", value: "3% Simple" },
  { title: "3% Compound", value: "3% Compound" },
  { title: "3% Compound for 20 Years", value: "3% Compound for 20 Years" },
  { title: "4% Compound", value: "4% Compound" },
  { title: "5% Compound", value: "5% Compound" },
  { title: "5% Compound for 20 Years", value: "5% Compound for 20 Years" },
  { title: "5% Compound Limit 2X", value: "5% Compound Limit 2X" },
  { title: "5% Simple", value: "5% Simple" },
  { title: "5% Step Rated", value: "5% Step Rated" },
  { title: "6% Compound", value: "6% Compound" },
  { title: "CPI", value: "CPI" },
  { title: "US Medical Care Inflation", value: "US Medical Care Inflation" }
];

const discountItems = [
  { title: "None", value: null },
  { title: "Association", value: "Association" },
  { title: "Employer", value: "Employer" },
  { title: "Producer", value: "Producer" }
];

const BENEFIT_PERIODS = [
  "1 Year",
  "2 Years",
  "3 Years",
  "4 Years",
  "5 Years",
  "6 Years",
  "7 Years",
  "8 Years",
  "9 Years",
  "10 Years",
  "Max"
];

const monthlyBenefitSolveItems = ["Max", "Specify Amount"];

const DISABILITY_BENEFIT_PERIODS = [
  { title: "Lump Sum", value: "Lump Sum" },
  { title: "1 Year", value: "1 Year" },
  { title: "18 Months", value: "18 Months" },
  { title: "2 Years", value: "2 Years" },
  { title: "5 Years", value: "5 Years" },
  { title: "10 Years", value: "10 Years" },
  { title: "To Age 65", value: "To Age 65" },
  { title: "To Age 67 (recommended)", value: "To Age 67" },
  { title: "To Age 70", value: "To Age 70" }
];

const disabilityTypeItems = [
  "Business Overhead",
  "Buy Sell",
  "Individual Disability",
  "Key Person"
];

const tobaccoUseItems = [
  { title: "Non-Tobacco", value: false },
  { title: "Tobacco Use", value: true }
];

export const maritalStatusItems = ["Married", "Single", "Domestic Parter"];

export {
  INFLATION_ITEMS,
  discountItems,
  BENEFIT_PERIODS,
  monthlyBenefitSolveItems,
  DISABILITY_BENEFIT_PERIODS,
  disabilityTypeItems,
  tobaccoUseItems
};
