<template>
  <v-card>
    <v-card-title class="px-9">Add Advisor</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" :md="displayAdvisorCode ? 6 : 12">
          <agent-search
            v-model="advisor"
            label="Search for an Agent"
            data-testid="agent-search"
            better-return
            :clearable="false"
            :success="advisorValidation.success"
            :error-messages="advisorValidation.errorMessages"
            @update:model-value="getAdvisorCode"
          />
        </v-col>
        <template v-if="displayAdvisorCode">
          <v-col cols="12" md="6">
            <select-field
              v-model="appointmentCase.appointmentCode"
              item-title="name"
              item-value="id"
              label="Advisor Code"
              :prepend-inner-icon="mdiBarcode"
              persistent-hint
              hint=" "
              data-testid="advisor-code"
              :items="codes"
              :loading="loadingCodes"
              :success="appointmentCodeValidation.success"
              :error-messages="appointmentCodeValidation.errorMessages"
            >
              <template #message="{ message }">
                <v-row class="pa-3">
                  {{ message }}
                  <v-spacer />
                  <a @click="createAppointment">
                    No Codes? Create an Appointment
                  </a>
                </v-row>
              </template>
            </select-field>
          </v-col>
          <v-col cols="12" md="6">
            <integer-input
              v-model="appointmentCase.splitPercent"
              data-lpignore="true"
              mask="###"
              label="Split Percent"
              :prepend-inner-icon="mdiPercent"
              data-testid="split-percent"
              :success="splitPercentValidation.success"
              :error-messages="splitPercentValidation.errorMessages"
            />
          </v-col>
          <v-col cols="12" md="6">
            <integer-input
              v-model="appointmentCase.splitRenewal"
              data-lpignore="true"
              mask="###"
              label="Renewal Split"
              :prepend-inner-icon="mdiPercent"
              data-testid="split-renewal"
              :success="splitRenewalValidation.success"
              :error-messages="splitRenewalValidation.errorMessages"
            />
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none mb-3"
        variant="outlined"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none mr-7 mb-3"
        color="accent"
        data-testid="add-advisor"
        :loading="loading"
        @click="addAdvisor"
      >
        Add
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import AppointmentCreate from "@/components/appointments/AppointmentCreate.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";

import { AppointmentCase } from "@/factories/Case";
import { parseErrorMessage, computedValidation } from "@/util/helpers";
import { getCode } from "@/api/appointment.service";
import { createAppointmentCase } from "@/api/appointment-cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, ref, nextTick, markRaw } from "vue";
import useVuelidate from "@vuelidate/core";

import { mdiBarcode, mdiPercent } from "@mdi/js";

const props = defineProps({
  caseId: { type: Number, default: null },
  insuredName: { type: String, default: null },
  productId: { type: Number, default: null },
  carrier: { type: Object, default: () => {} },
  states: { type: Array, default: () => [] }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const codes = ref([]);
const loadingCodes = ref(false);
const loading = ref(false);
const appointmentCase = ref(AppointmentCase({ caseId: props.caseId }));

const v$ = useVuelidate(
  {
    appointmentCase: {
      advisorId: {
        required: v => Boolean(v)
      },
      appointmentCode: {
        required: v => Boolean(v)
      },
      splitPercent: {
        required: v => Boolean(+v)
      },
      splitRenewal: {
        required: v => Boolean(+v)
      }
    }
  },
  { appointmentCase },
  { $autoDirty: true, $scope: false }
);

const displayAdvisorCode = computed(() => {
  return appointmentCase.value.advisorId && appointmentCase.value.advisorName;
});

const advisor = computed({
  get() {
    return {
      id: appointmentCase.value.advisorId,
      name: appointmentCase.value.advisorName,
      type: appointmentCase.value.advisorType
    };
  },
  set(v) {
    appointmentCase.value.advisorId = v?.id;
    appointmentCase.value.advisorType = v?.type;
    appointmentCase.value.advisorName = v?.name;
  }
});

const advisorValidation = computedValidation(
  v$.value.appointmentCase.advisorId,
  { required: "Required" }
);

const appointmentCodeValidation = computedValidation(
  v$.value.appointmentCase.appointmentCode,
  { required: "Required" }
);

const splitPercentValidation = computedValidation(
  v$.value.appointmentCase.splitPercent,
  { required: "Required" }
);

const splitRenewalValidation = computedValidation(
  v$.value.appointmentCase.splitRenewal,
  { required: "Required" }
);

async function createAppointment() {
  const code = await dialog.showDialog({
    component: markRaw(AppointmentCreate),
    carrier: props.carrier,
    productId: props.productId,
    advisor: advisor.value,
    insuredName: props.insuredName,
    states: props.states,
    scrollable: true
  });
  if (!code?.id) return;
  await getCodes();
  await nextTick();
  const foundCode = codes.value.find(i => i.id === code.id);
  if (foundCode) {
    appointmentCase.value.appointmentCode = foundCode.id;
  }
}

async function getCodes() {
  loadingCodes.value = true;
  try {
    const newCodes = await getCode(
      appointmentCase.value.advisorId,
      appointmentCase.value.advisorType,
      props.carrier.id
    );
    codes.value.splice(0, codes.value.length);
    codes.value.push(...newCodes);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingCodes.value = false;
  }
}
async function getAdvisorCode() {
  await nextTick();
  getCodes();
}
async function addAdvisor() {
  const isValid = await v$.value.$validate();
  if (!isValid || loading.value) return;
  loading.value = true;
  try {
    const ac = await createAppointmentCase(appointmentCase.value);
    dialog.closeDialog({ appointmentCase: ac });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
