import { serializeObject } from "@/util/helpers";

import { getHttpClient } from "@/http-client";
import { setErrorsOmissionsFromRaw } from "@/factories/EoFactory";

const baseUrl = "/api/boss/eos";

export async function createNewEo(body) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject({ eo: body })
  );

  return setErrorsOmissionsFromRaw(data.eo);
}

export function deleteEo(eoId) {
  return getHttpClient().delete(`${baseUrl}/${eoId}`);
}
