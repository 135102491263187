<template>
  <text-field
    v-model="model"
    v-bind="$attrs"
    v-maska:[options]
    inputmode="numeric"
  >
    <template v-if="$slots.message" #message="{ message }">
      <v-row v-if="message" class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
      <slot v-else name="message" />
    </template>
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </text-field>
</template>

<script setup>
import {   watch, ref, toRef } from "vue";
import { vMaska } from "maska";

const props = defineProps({
  modelValue: {
    type: [Number, String],
    required: false,
    default: null
  },
  allowExtension: Boolean
});

const modelValue = toRef(props, "modelValue");

const options = {
  mask: "(###) ###-####"
};
if (props.allowExtension) {
  options.mask = "(###) ###-####x######";
}

const model = ref(props.modelValue || null);

watch(modelValue, value => {
  if (value === model.value) return;
  model.value = value;
});

const emit = defineEmits(["update:model-value"]);
const update = v => emit("update:model-value", v);

watch(model, () => {
  if (model.value === props.modelValue) return;
  update(model.value);
});
</script>
