<template>
  <v-card flat color="section" tile>
    <v-card-title>Basic Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <text-field
            v-model="firstName"
            data-lpignore="true"
            data-testid="first-name"
            label="First Name"
            :prepend-inner-icon="mdiAccount"
            :error-messages="firstNameValidation.errors"
            :success="firstNameValidation.success"
            @update:model-value="saveAttribute('firstName')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.firstName.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="lastName"
            data-lpignore="true"
            data-testid="last-name"
            label="Last Name"
            :prepend-inner-icon="mdiAccountMultiple"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
            @update:model-value="saveAttribute('lastName')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.lastName.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="email"
            data-lpignore="true"
            data-testid="email"
            label="Email"
            :prepend-inner-icon="mdiEmail"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
            @update:model-value="saveAttribute('email')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.email.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <marketing-manager-search
            v-model="marketingManager"
            data-testid="marketing-manager"
            :success="marketingManagerValidation.success"
            :error-messages="marketingManagerValidation.errorMessages"
            @update:model-value="saveAttribute('marketingManager')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.marketingManager.controller.value"
              />
            </template>
          </marketing-manager-search>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Additional Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <phone-input
            v-model="phoneWork"
            data-lpignore="true"
            data-testid="work-phone"
            :prepend-inner-icon="mdiDeskphone"
            label="Work Phone"
            :success="Boolean(phoneWork)"
            @update:model-value="saveAttribute('phoneWork')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.phoneWork.controller.value"
              />
            </template>
          </phone-input>
        </v-col>
        <v-col cols="12" md="6">
          <phone-input
            v-model="phoneMobile"
            data-lpignore="true"
            data-testid="mobile-phone"
            :prepend-inner-icon="mdiCellphone"
            label="Mobile Phone"
            :success="Boolean(phoneMobile)"
            @update:model-value="saveAttribute('phoneMobile')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.phoneMobile.controller.value"
              />
            </template>
          </phone-input>
        </v-col>
        <v-col cols="12" md="6">
          <phone-input
            v-model="phoneFax"
            data-lpignore="true"
            label="Fax"
            data-testid="fax-phone"
            :prepend-inner-icon="mdiFax"
            :success="Boolean(phoneFax)"
            @update:model-value="saveAttribute('phoneFax')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.phoneFax.controller.value"
              />
            </template>
          </phone-input>
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="birthdate"
            label="Birthdate"
            data-testid="birthdate"
            :prepend-inner-icon="mdiCalendar"
            :success="Boolean(birthdate)"
            @update:model-value="saveAttribute('birthdate')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.birthdate.controller.value"
              />
            </template>
          </date-input>
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="gender"
            label="Gender"
            data-testid="gender"
            :prepend-inner-icon="mdiGenderMaleFemale"
            :success="Boolean(gender)"
            :items="GENDERS"
            @update:model-value="saveAttribute('gender')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.gender.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="category"
            label="Category"
            data-testid="category"
            :prepend-inner-icon="mdiCube"
            :items="LEAD_CATEGORIES"
            :success="Boolean(category)"
            @update:model-value="saveAttribute('category')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.category.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="followUpDate"
            label="Follow Up"
            data-testid="follow-up-date"
            :prepend-inner-icon="mdiCalendarAccount"
            :success="Boolean(followUpDate)"
            @update:model-value="saveAttribute('followUpDate')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.followUpDate.controller.value"
              />
            </template>
          </date-input>
        </v-col>

        <v-col cols="12" md="6">
          <div class="checkbox-width">
            <checkbox-field
              v-model="subscribed"
              class="mt-1"
              data-testid="subscribed"
              :success="Boolean(subscribed)"
              @update:model-value="saveAttribute('subscribed')"
            >
              <template #label>
                Subscribed
                <active-save-indicator
                  :controller="savingBuffer.subscribed.controller.value"
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>

        <v-col cols="12">
          <h2 class="mb-2 text-body-1">Home Address</h2>
          <basic-address-input
            v-model:street-address-model="homeAddress.street_address"
            v-model:city-model="homeAddress.city"
            v-model:state-model="homeAddress.state"
            v-model:zip-model="homeAddress.zip"
            autofill-label="Home Address"
            autofill-placeholder="Home Address"
            data-testid="home-address"
            :street-address-validation="homeStreetAddressValidation"
            :city-validation="homeCityValidation"
            :state-validation="homeStateValidation"
            :zip-validation="homeZipValidation"
            @update:street-address-model="saveAttribute('homeStreet')"
            @update:city-model="saveAttribute('homeCity')"
            @update:state-model="saveAttribute('homeState')"
            @update:zip-model="saveAttribute('homeZip')"
            @autofill="handleHomeAutofill"
          >
            <template #append-inner-street-address>
              <active-save-indicator
                :controller="savingBuffer.homeStreet.controller.value"
              />
            </template>
            <template #append-inner-city>
              <active-save-indicator
                :controller="savingBuffer.homeCity.controller.value"
              />
            </template>
            <template #append-inner-state>
              <active-save-indicator
                :controller="savingBuffer.homeState.controller.value"
              />
            </template>
            <template #append-inner-zip>
              <active-save-indicator
                :controller="savingBuffer.homeZip.controller.value"
              />
            </template>
          </basic-address-input>
        </v-col>

        <v-col cols="12">
          <h2 class="mb-2 text-body-1">Business Address</h2>
          <basic-address-input
            v-model:street-address-model="businessAddress.street_address"
            v-model:city-model="businessAddress.city"
            v-model:state-model="businessAddress.state"
            v-model:zip-model="businessAddress.zip"
            autofill-label="Business Address"
            autofill-placeholder="Business Address"
            data-testid="business-address"
            :street-address-validation="businessStreetAddressValidation"
            :city-validation="businessCityValidation"
            :state-validation="businessStateValidation"
            :zip-validation="businessZipValidation"
            @update:street-address-model="saveAttribute('businessStreet')"
            @update:city-model="saveAttribute('businessCity')"
            @update:state-model="saveAttribute('businessState')"
            @update:zip-model="saveAttribute('businessZip')"
            @autofill="handleBusinessAutofill"
          >
            <template #append-inner-street-address>
              <active-save-indicator
                :controller="savingBuffer.businessStreet.controller.value"
              />
            </template>
            <template #append-inner-city>
              <active-save-indicator
                :controller="savingBuffer.businessCity.controller.value"
              />
            </template>
            <template #append-inner-state>
              <active-save-indicator
                :controller="savingBuffer.businessState.controller.value"
              />
            </template>
            <template #append-inner-zip>
              <active-save-indicator
                :controller="savingBuffer.businessZip.controller.value"
              />
            </template>
          </basic-address-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import DateInput from "@/components/shared/DateInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";

import { storeToRefs } from "pinia";
import { useLeadView } from "@/stores/lead-view";

import { LEAD_CATEGORIES } from "@/factories/Lead";
import { genders as GENDERS } from "@/data/advisor-data";

import { computedValidation, someTextValidator } from "@/util/helpers";
import { useHead } from "@unhead/vue";
import useVuelidate from "@vuelidate/core";
import { useActiveSave } from "@/composables/active-save.composable";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiEmail,
  mdiDeskphone,
  mdiCellphone,
  mdiFax,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiCube,
  mdiCalendarAccount
} from "@mdi/js";

useHead({ title: "Create Lead" });

const leadView = useLeadView();
const {
  marketingManager,
  email,
  firstName,
  lastName,
  homeAddress,
  businessAddress,
  phoneWork,
  phoneMobile,
  phoneFax,
  birthdate,
  gender,
  category,
  followUpDate,
  subscribed
} = storeToRefs(leadView);

const savingBuffer = {
  homeAddress: useActiveSave(),
  homeStreet: useActiveSave(),
  homeCity: useActiveSave(),
  homeState: useActiveSave(),
  homeZip: useActiveSave(),
  businessAddress: useActiveSave(),
  businessStreet: useActiveSave(),
  businessCity: useActiveSave(),
  businessState: useActiveSave(),
  businessZip: useActiveSave(),
  firstName: useActiveSave(),
  lastName: useActiveSave(),
  email: useActiveSave(),
  marketingManager: useActiveSave(),
  phoneWork: useActiveSave(),
  phoneMobile: useActiveSave(),
  phoneFax: useActiveSave(),
  birthdate: useActiveSave(),
  gender: useActiveSave(),
  category: useActiveSave(),
  followUpDate: useActiveSave(),
  subscribed: useActiveSave()
};

const v$ = useVuelidate(
  {
    marketingManager: { required: v => Boolean(v?.id) },
    email: { required: Boolean },
    firstName: { required: v => someTextValidator(true, v, 2) },
    lastName: { required: v => someTextValidator(true, v, 2) },
    homeAddress: {
      street_address: { required: true },
      city: { required: true },
      state: { required: true },
      zip: { required: true }
    },
    businessAddress: {
      street_address: { required: true },
      city: { required: true },
      state: { required: true },
      zip: { required: true }
    }
  },
  {
    marketingManager,
    email,
    firstName,
    lastName,
    homeAddress,
    businessAddress
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const firstNameValidation = computedValidation(v$.value.firstName, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.lastName, {
  required: "Required"
});
const emailValidation = computedValidation(v$.value.email, {
  required: "Required"
});
const marketingManagerValidation = computedValidation(
  v$.value.marketingManager,
  {
    required: "Required"
  }
);
const homeStreetAddressValidation = computedValidation(
  v$.value.homeAddress.street_address,
  { required: "Required" }
);
const homeCityValidation = computedValidation(v$.value.homeAddress.city, {
  required: "Required"
});
const homeStateValidation = computedValidation(v$.value.homeAddress.state, {
  required: "Required"
});
const homeZipValidation = computedValidation(v$.value.homeAddress.zip, {
  required: "Required"
});
const businessStreetAddressValidation = computedValidation(
  v$.value.businessAddress.street_address,
  { required: "Required" }
);
const businessCityValidation = computedValidation(
  v$.value.businessAddress.city,
  { required: "Required" }
);
const businessStateValidation = computedValidation(
  v$.value.businessAddress.state,
  { required: "Required" }
);
const businessZipValidation = computedValidation(v$.value.businessAddress.zip, {
  required: "Required"
});

function handleAutofill(components, model, savingBufferKey) {
  model.value.street_address = components.street_address;
  model.value.city = components.city;
  model.value.state = components.state;
  model.value.zip = components.zip;

  saveAttribute(savingBufferKey);
}

const handleBusinessAutofill = components =>
  handleAutofill(components, businessAddress, "businessAddress");

const handleHomeAutofill = components =>
  handleAutofill(components, homeAddress, "homeAddress");

function saveAttribute(attribute) {
  savingBuffer[attribute].debounceUpdate(() =>
    leadView.updateAttribute(attribute)
  );
}
</script>
