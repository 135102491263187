<template>
  <v-card flat tile color="section">
    <v-card-text> <timeline-graph :items="appointmentTimeline" /> </v-card-text>
  </v-card>
</template>

<script setup>
import TimelineGraph from "@/components/shared/TimelineGraph.vue";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";

const { timeline: appointmentTimeline } = storeToRefs(useAppointmentView());
</script>
