<template>
  <v-card class="site-goals" :height="height">
    <v-card-title class="pb-2">
      <v-row class="ma-0 pl-3 pr-1" align="center">
        Your Profile is {{ percent }}% Complete <v-spacer />
        <v-progress-circular
          :model-value="percent"
          :color="percent === 100 ? 'success' : 'primary'"
          size="50"
          width="4"
        >
          <span class="text-subtitle-1 font-weight-bold"> {{ percent }}% </span>
        </v-progress-circular>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-2" style="flex: 1 1 auto">
      <v-list class="pa-0">
        <site-goal v-for="goal in visibleGoals" :key="goal.text" :goal="goal" />
      </v-list>
    </v-card-text>
    <div v-if="goalPageCount > 1" style="position: relative; flex: 0 0 auto">
      <v-pagination
        v-model="currentPage"
        rounded
        active-color="primary"
        :length="goalPageCount"
        circle
      />
    </div>
  </v-card>
</template>

<script>
import SiteGoal from "@/components/home/SiteGoal.vue";

const DISPLAYED_GOAL_LENGTH = 6;

export default {
  components: {
    SiteGoal
  },
  props: {
    goals: { type: Array, required: true },
    percent: { type: Number, required: true },
    height: { type: String, required: true }
  },
  data() {
    return {
      currentPage: 1
    };
  },
  computed: {
    visibleGoals() {
      const currentGoals = (this.currentPage - 1) * DISPLAYED_GOAL_LENGTH;
      const nextGoals = this.currentPage * DISPLAYED_GOAL_LENGTH;
      return this.goals.slice(currentGoals, nextGoals);
    },
    goalPageCount() {
      return Math.ceil(this.goals.length / DISPLAYED_GOAL_LENGTH);
    }
  }
};
</script>

<style lang="scss">
.site-goals {
  .hoverable-goal-item {
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
  }

  .hoverable-goal-item.light-goal-item {
    a > div {
      color: #191919 !important;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .hoverable-goal-item.dark-goal-item {
    a > div {
      color: white !important;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.04);
    }
  }
}
</style>
