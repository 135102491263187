import { getHttpClient } from "@/http-client";
const baseURL = "api/boss/agent-sources";

function AgentSource(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    category: model?.category
  };
}

function setAgentSourceFromRequest(raw = {}) {
  const source = AgentSource();
  source.id = raw.id;
  source.name = raw.name;
  source.category = raw.category;
  return source;
}

export async function getAgentSources(agentId) {
  const params = new URLSearchParams();
  params.append("agent_id", agentId);
  const { data } = await getHttpClient().get(baseURL, { params });

  return data.map(setAgentSourceFromRequest);
}
