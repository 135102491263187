<template>
  <v-fade-transition mode="out-in">
    <span v-if="formCategories.loading" key="loading" class="text-grey">
      Loading
    </span>
    <span v-else key="cleaned"> {{ cleanedValue }} </span>
  </v-fade-transition>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed } from "vue";
const props = defineProps({
  modelValue: { type: String, default: null }
});

const snackbar = useSnackbarStore();
const searchItemsCache = useSearchItemsCache();
const { formCategories } = storeToRefs(searchItemsCache);

const cleanedValue = computed(
  () =>
    formCategories.value.items.find(v => v.value === props.modelValue)?.title
);
async function fetchItems() {
  try {
    await searchItemsCache.getFormCategoriesList();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

fetchItems();
</script>
