<template>
  <v-card>
    <div style="width: 100%">
      <vimeo-video url="https://player.vimeo.com/video/835979488" />
    </div>
    <v-row class="ma-0" justify="center">
      <div style="width: 25rem">
        <v-card-title class="text-h4 justify-center">
          Welcome to BOSS
        </v-card-title>
        <v-card-text class="text-center">
          We invite you to learn about key features and enhancements to orient
          you with BackNine's BOSS (Back Office Support System).
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-col>
            <app-button
              color="primary"
              class="text-none"
              block
              @click="dialog.closeDialog()"
            >
              Start Welcome Tour
            </app-button>
            <app-button
              color="grey"
              variant="text"
              class="text-none mt-1"
              block
              @click="dialog.closeDialog({ stop: true })"
            >
              Skip
            </app-button>
          </v-col>
        </v-card-actions>
      </div>
    </v-row>
  </v-card>
</template>

<script setup>
import VimeoVideo from "@/components/shared/VimeoVideo.vue";
import { useDialogStore } from "@/stores/dialog";

const dialog = useDialogStore();
</script>

<style>
#vimeo-video iframe {
  border-radius: 4px;
  width: 100%;
  height: 28rem;
}
</style>
