<template>
  <autocomplete-field
    v-model="user"
    v-bind="$attrs"
    v-model:search="searchText"
    autocomplete="false"
    :prepend-inner-icon="mdiAccount"
    placeholder="Search Users by Email"
    label="User"
    item-title="email"
    return-object
    no-filter
    persistent-hint
    hint=" "
    :data-testid="dataTestid"
    :hide-no-data="!includeNew || !searchText || loading || typing"
    :disabled="disabled"
    :items="users"
    :loading="loading"
    @blur="$emit('blur')"
  >
    <template v-if="hasAppendOuterSlot" #append-inner>
      <slot name="append-inner" />
    </template>
    <template #no-data>
      <v-list-item
        data-testid="existing-search-create-new-user"
        @click="$emit('create-new', searchText)"
      >
        <v-list-item-title> {{ searchText }} </v-list-item-title>
        <v-list-item-subtitle>
          User not found. Create new user?
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot name="message-inner" />
      </v-row>
    </template>
  </autocomplete-field>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import { userSearch } from "@/api/search.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
export default {
  props: {
    dataTestid: { type: String, requried: false, default: null },
    modelValue: { type: Object, required: false, default: null },
    disabled: Boolean,
    includeNew: Boolean
  },
  emits: ["update:model-value", "blur", "create-new"],
  data() {
    let users = [];
    let user = null;
    if (
      this.modelValue &&
      Object.keys(this.modelValue).length &&
      this.modelValue
    ) {
      users = [this.modelValue];
      user = this.modelValue;
    }
    return {
      searchText: "",
      timer: null,
      loading: false,
      typing: false,
      users,
      user,
      mdiAccount
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append"];
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.user = null;
        this.users = [];
        return;
      }
      this.user = value;
      this.users = [value];
    },
    user(value) {
      this.$emit("update:model-value", value);
    },
    searchText(value) {
      if (!value || !Object.keys(value).length) {
        this.typing = false;
        return;
      }
      if (this.users.some(val => val?.email === value)) {
        this.typing = false;
        return;
      }
      this.typing = true;
      this.debounceAndSearch();
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    debounceAndSearch() {
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(this.search, 200);
    },
    async search() {
      this.loading = true;
      try {
        this.users = await userSearch(this.searchText);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
        this.typing = false;
      }
    }
  }
};
</script>
