<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="payments-table"
    item-value="additional.id"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0 px-3 py-0" align="center">
        <div class="text-subtitle-1 text-grey py-1">
          A Payment is any payment from a carrier or BackNine. Payments from
          BackNine are grouped into Statements.
        </div>
        <v-row class="ma-0" justify="end" align="center">
          <table-stats :stats="stats" />
          <v-tooltip location="bottom">
            <template #activator="{ props: templateProps }">
              <app-button
                v-bind="templateProps"
                variant="text"
                density="comfortable"
                class="table-btn"
                :icon="mdiRefresh"
                data-testid="payments-table-refresh"
                @click="getData"
              />
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-tooltip v-if="canEmail" location="bottom">
            <template #activator="{ props: templateProps }">
              <app-button
                v-bind="templateProps"
                variant="text"
                density="comfortable"
                class="table-btn"
                :icon="mdiFileExport"
                data-testid="payments-table-email"
                @click="emailData"
              />
            </template>
            <span>Email a CSV</span>
          </v-tooltip>
        </v-row>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.createdDate`]="{ item }">
      <timestamp-formatter :model-value="item.createdDate" parser="sole-day" />
    </template>
    <template #[`item.statementDate`]="{ item }">
      <timestamp-formatter
        :model-value="item.statementDate"
        parser="sole-day"
      />
    </template>
    <template #[`item.payPeriod`]="{ item }">
      <v-tooltip v-if="item.payPeriod" location="bottom">
        <template #activator="{ props: templateProps }">
          <v-chip
            color="primary"
            label
            v-bind="templateProps"
            :prepend-icon="mdiCalendar"
            :text="`#${item.payPeriod}`"
            :to="item.additional.payPeriod.routerLink"
          />
        </template>
        <span>View Statement</span>
      </v-tooltip>
    </template>
    <template #[`item.parties`]="{ item }">
      <template v-if="item.additional.commission?.payor?.name">
        Sender: <strong> {{ item.additional.commission.payor.name }} </strong>
        <br />
      </template>
      <template v-if="item.additional.commission?.assignedPayable?.name">
        Recipient:
        <strong> {{ item.additional.commission.assignedPayable.name }} </strong>
        <br />
      </template>
      <template
        v-if="item.additional.commission?.appointmentCase?.advisor?.name"
      >
        Agent:
        <strong>
          {{ item.additional.commission.appointmentCase.advisor.name }}
        </strong>
      </template>
    </template>
    <template #[`item.premium`]="{ item }">
      <currency-formatter :model-value="item.premium" />
    </template>
    <template #[`item.splitPercent`]="{ item }">
      <percentage-formatter :model-value="item.splitPercent" />
    </template>
    <template #[`item.commission`]="{ item }">
      <currency-formatter :model-value="item.commission" />
      <span class="text-grey">
        (<percentage-formatter :model-value="item.additional.percent" />)
      </span>
    </template>
    <template #[`item.policyNumber`]="{ item }">
      <v-tooltip location="bottom">
        <template #activator="{ props: templateProps }">
          <v-chip
            v-if="item.additional.commission?.appointmentCase?.caseLink"
            color="accent"
            label
            variant="flat"
            :prepend-icon="mdiBriefcase"
            :text="item.policyNumber"
            v-bind="templateProps"
            :to="item.additional.commission.appointmentCase.caseLink"
          />
        </template>
        <span>View Case</span>
      </v-tooltip>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableStats from "@/components/shared/data-table/TableStats.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import { paymentTypes, commissionTypes } from "@/data/filter-options";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";

import { emailPayments, getPayments } from "@/api/payments.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";

import {
  mdiCashMultiple,
  mdiCashRefund,
  mdiPound,
  mdiCalendar,
  mdiBriefcase,
  mdiRefresh,
  mdiFileExport
} from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { computed, markRaw } from "vue";
import { useDialogStore } from "@/stores/dialog";

import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const user = useUserStore();
const { paymentsTable } = storeToRefs(useTableStore());

const table = useTable({
  getData: getPayments,
  emailData: emailPayments,
  shouldIncludeCancelToken: true,
  headers: [
    new TableHeader({
      text: "Statement ID",
      value: "payPeriod",
      map: "payPeriod.id",
      sortFilterMap: "pay_periods.id",
      icon: mdiPound,
      ...TableHeader.TOTAL_ORDER(2),
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Parties",
      value: "parties",
      ...TableHeader.TOTAL_ORDER(3)
    }),
    new TableHeader({
      text: "Paid By",
      value: "paidBy",
      sortFilterMap: [{ key: "payor_id", value: "id" }],
      ...TableHeader.TOTAL_ORDER(4),
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_PAYOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Paid To",
      value: "paidTo",
      sortFilterMap: [
        { key: "assigned_payable_id", value: "id" },
        { key: "assigned_payable_type", value: "type" }
      ],
      ...TableHeader.TOTAL_ORDER(5),
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_ADVISOR_PAYMENT_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Agent",
      value: "agent",
      sortFilterMap: [
        { key: "agent_id", value: "id" },
        { key: "agent_type", value: "type" }
      ],
      ...TableHeader.TOTAL_ORDER(6),
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Paid on Behalf of",
      value: "paidOnBehalfOf",
      ...TableHeader.TOTAL_ORDER(7),
      sortFilterMap: [
        { key: "payable_id", value: "id" },
        { key: "payable_type", value: "type" }
      ],
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_ADVISOR_PAYMENT_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policyNumber",
      map: "commission.appointmentCase.policyNumber",
      sortFilterMap: [{ key: "case_id", value: "id" }],
      displayMap: "policy_number",
      ...TableHeader.TOTAL_ORDER(8),
      ...TableHeader.IS_POLICY_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "commission.appointmentCase.insured.name",
      sortFilterMap: [{ key: "contract_party_id", value: "id" }],
      ...TableHeader.TOTAL_ORDER(9),
      ...TableHeader.IS_CONTRACT_PARTY_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      sortFilterMap: "payments.premium",
      ...TableHeader.TOTAL_ORDER(10)
    }),
    new TableHeader({
      text: "Commission",
      value: "commission",
      map: "dollar",
      sortFilterMap: "payments.dollar",
      ...TableHeader.TOTAL_ORDER(11),
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Commission Type",
      value: "type",
      map: "commissionType",
      selectableOptions: commissionTypes,
      sortFilterMap: "payments.commission_type",
      icon: mdiCashMultiple,
      ...TableHeader.TOTAL_ORDER(12),
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Created",
      value: "createdDate",
      map: "createdDate",
      sortFilterMap: "payments.created_at",
      ...TableHeader.TOTAL_ORDER(13),
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      ...TableHeader.TOTAL_ORDER(14),
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Inforce Date",
      value: "inforce",
      sortFilterMap: "inforce_date",
      ...TableHeader.TOTAL_ORDER(18),
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL
    })
  ]
});

if (user.isGroupTwoPlus) {
  table.headers.value.push(
    new TableHeader({
      text: "Payment Type",
      value: "paymentType",
      ...TableHeader.TOTAL_ORDER(15),
      selectableOptions: paymentTypes,
      sortFilterMap: "payment_type",
      icon: mdiCashRefund,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Marketing Manager",
      value: "marketingManager",
      sortFilterMap: [
        { key: "marketing_manager_id", value: "marketing_manager_id" }
      ],
      ...TableHeader.TOTAL_ORDER(16),
      ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "White Glove eApp",
      value: "whiteGloveEApp",
      sortFilterMap: "white_glove_eapp",
      ...TableHeader.TOTAL_ORDER(17),
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    })
  );
}

if (user.isGroupFour) {
  table.headers.value.push(
    new TableHeader({
      text: "View",
      value: "view",
      sortFilterMap: "view",
      ...TableHeader.TOTAL_ORDER(1),
      selectableOptions: [
        { title: "All Payments", value: "all" },
        { title: "My Payments", value: "my" }
      ],
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_MANDATORY
    })
  );
}

table.ingestFromStore({
  filter: paymentsTable.value.filter,
  options: paymentsTable.value.options,
  defaultFilter: { view: "my" },
  defaultOptions: TableOptions({
    sortBy: [{ key: "createdDate", order: "desc" }]
  })
});

const canEmail = computed(() => table.filter.value.view !== "all");

const stats = computed(() => {
  const revenue = {
    text: "Revenue",
    type: "loading",
    dataTestid: "stat-revenue"
  };

  if ("revenue" in table.stats.value) {
    revenue.value = table.stats.value.revenue;
    revenue.type = "dollar";
  }

  return [revenue];
});

function emailData() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: async () => {
      const additionalFilter = {};
      if (!user.isGroupFour) additionalFilter.view = "my";
      await table.emailData(additionalFilter);
      snackbar.showSuccessSnackbar({
        message: `You will receive a copy of this data in your inbox shortly.`,
        timeout: 6000
      });
    }
  });
}

async function getData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupFour) additionalFilter.view = "my";
    await table.getData(additionalFilter);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  paymentsTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  paymentsTable.value.filter = filter;
  table.resetPage();
  getData();
}
</script>
