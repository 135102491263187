<template>
  <v-sheet :data-testid="dataTestid" :color="color" class="my-3 rounded">
    <v-row align="center" dense>
      <v-col cols="12">
        <v-row class="ma-0" align="center">
          <h3 style="font-weight: 400; font-size: 18px">{{ label }}</h3>
          <app-button
            v-if="hasValue"
            :icon="mdiUndo"
            variant="text"
            density="comfortable"
            class="ml-2"
            :disabled="loading"
            @click="reset"
          />
        </v-row>
      </v-col>
      <v-col
        :key="label"
        cols="12"
        :lg="filterType.requiresMin && filterType.requiresMax ? 4 : 6"
      >
        <select-field
          ref="type"
          v-model="number.type"
          attach
          hide-details
          label="Type"
          :data-testid="`${dataTestid}-type`"
          :items="numberFilters"
          :disabled="loading"
          :menu-props="{ bottom: true, offsetY: true }"
          @update:model-value="clearValue"
        />
      </v-col>
      <v-col
        v-if="filterType.requiresMin"
        :key="label + '-min-range'"
        cols="12"
        :lg="filterType.requiresMin && filterType.requiresMax ? 4 : 6"
      >
        <component
          :is="filterComponent"
          ref="minInput"
          v-model="number.value.min"
          clearable
          hide-details
          style="margin-right: 0.5rem"
          :data-testid="`${dataTestid}-min`"
          :label="isSoloType ? soloLabel : 'Min'"
          :prepend-inner-icon="isCurrency ? mdiCurrencyUsd : null"
          :decimal-length="0"
          :disabled="loading"
          @click:clear="number.value.min = undefined"
        />
      </v-col>
      <v-col
        v-if="filterType.requiresMax"
        :key="label + '-max-range'"
        cols="12"
        :lg="filterType.requiresMin && filterType.requiresMax ? 4 : 6"
      >
        <component
          :is="filterComponent"
          v-model="number.value.max"
          hide-details
          clearable
          :data-testid="`${dataTestid}-max`"
          :label="isSoloType ? soloLabel : 'Max'"
          :prepend-inner-icon="isCurrency ? mdiCurrencyUsd : null"
          :disabled="loading"
          :decimal-length="0"
          @click:clear="number.value.max = undefined"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup>
import { mdiUndo, mdiCurrencyUsd } from "@mdi/js";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import {
  numberFilters,
  NUMBER_FILTER
} from "@/constants/number-filter.constants";
import { computed, ref, watch, toRef, markRaw } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "white"
  },
  label: {
    type: String,
    required: true
  },
  isCurrency: Boolean,
  modelValue: { type: [Object, String], default: null },
  loading: Boolean,
  dataTestid: { type: String, default: "number" }
});

const emit = defineEmits(["update:model-value"]);

const modelValue = toRef(props, "modelValue");

const number = ref({
  type: NUMBER_FILTER.GTEQ,
  value: {
    min: "",
    max: ""
  }
});

const minInput = ref(); // template ref

const filterComponent = props.isCurrency
  ? markRaw(CurrencyInput)
  : markRaw(IntegerInput);

const hasValue = computed(() => {
  let minDefined = true;
  if (filterType.value.requiresMin)
    minDefined = !isNumberEmpty(number.value.value.min);

  let maxDefined = true;
  if (filterType.value.requiresMax)
    maxDefined = !isNumberEmpty(number.value.value.min);

  return minDefined && maxDefined;
});

const filterType = computed(() =>
  numberFilters.find(v => v.value === number.value.type)
);

const isSoloType = computed(
  () => +filterType.value.requiresMin + +filterType.value.requiresMax === 1
);

watch(
  modelValue,
  value => {
    if (isEqual(value)) return;
    setNumber(value);
  },
  { deep: true }
);

watch(
  number,
  val => {
    if (isEqual(modelValue.value)) return;
    let minDefined = true;
    if (filterType.value.requiresMin) {
      minDefined = isNumberValid(val.value.min) || isNumberEmpty(val.value.min);
    }

    let maxDefined = true;
    if (filterType.value.requiresMax) {
      maxDefined = isNumberValid(val.value.max) || isNumberEmpty(val.value.max);
    }

    if (minDefined && maxDefined)
      emit("update:model-value", JSON.parse(JSON.stringify(val)));
  },
  { deep: true }
);

function isEqual(value) {
  const oldV = valueCast(value);
  const newV = valueCast(number.value);

  return (
    oldV.type === newV.type &&
    oldV.value.min === newV.value.min &&
    oldV.value.max === newV.value.max
  );
}

function valueCast(value) {
  return {
    type: value?.type || NUMBER_FILTER.GTEQ,
    value: {
      min: numberCast(value?.value?.min),
      max: numberCast(value?.value?.max)
    }
  };
}

function numberCast(value) {
  return isNumberValid(value) ? value : null;
}

function isNumberEmpty(value) {
  return value === null;
}
function isNumberValid(value) {
  if (typeof value === "number") return true;
  if (typeof value === "string") return !isNaN(value) && value.trim() !== "";
  return value === null;
}
function setNumber(val) {
  const value = JSON.parse(JSON.stringify(val));
  const activeType = numberFilters.find(t => t.value === value?.type);
  if (!activeType) return;

  let minDefined = true;
  if (activeType.requiresMin) {
    minDefined =
      isNumberValid(value.value.min) || isNumberEmpty(value.value.min);
  }

  let maxDefined = true;
  if (activeType.requiresMax) {
    maxDefined =
      isNumberValid(value.value.max) || isNumberEmpty(value.value.max);
  }

  if (!minDefined || !maxDefined) return;

  number.value.type = activeType.type;
  number.value.value.min = value.value.min;
  number.value.value.max = value.value.max;
}
function focus() {
  minInput.value.$el.querySelector("input").focus();
}
function reset() {
  number.value.type = NUMBER_FILTER.GTEQ;
  clearValue();
}
function clearValue() {
  number.value.value.min = null;
  number.value.value.max = null;
}

setNumber(modelValue.value);

defineExpose({ focus });
</script>
