<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    default: "case"
  }
});

const formattedValue = computed(() => {
  let relationship = null;
  const anon = role => {
    if (role.relationship) relationship = role.relationship;
    if (
      !["Primary Beneficiary", "Contingent Beneficiary"].includes(role.role)
    ) {
      return role.role;
    }
    return `${role.role} - ${Math.trunc(role.beneficiaryAmount * 100)}%`;
  };

  const formattedRoles = props.modelValue.roles
    .map(role => anon(role))
    .join(", ");
  const isInsured =
    formattedRoles.includes("Annuitant") || formattedRoles.includes("Insured");

  if (isInsured || !relationship) return formattedRoles;
  return `${formattedRoles} (${relationship})`;
});
</script>
