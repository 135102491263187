<template>
  <v-form>
    <v-row dense>
      <v-col cols="12">
        <h3 class="text-h4 mb-3 font-weight-light">Referral Link</h3>
        <p class="text-body-1">
          Looking to invite many agents? Use your referral link!
        </p>

        <text-field
          ref="copyRef"
          data-lpignore="true"
          class="my-6 has-appended-button"
          variant="outlined"
          dense
          readonly
          data-testid="referral-link"
          label="Referral Link"
          :model-value="referralLink"
          :loading="fetchingReferralCode"
          hint=" "
          persistent-hint
          @click="copy"
        >
          <template #append-inner>
            <app-button
              variant="text"
              color="primary"
              class="text-none opacity-100"
              text="Copy"
              @click="copy"
            />
          </template>
          <template #message="{ message }">
            <v-row class="ma-0">
              {{ message }} <v-spacer />
              <a data-testid="show-embed-code" @click="copyDialog">
                Want to embed a signup page on your website?
              </a>
            </v-row>
          </template>
        </text-field>

        <v-fade-transition mode="out-in">
          <v-alert v-if="copied" type="success" variant="outlined" class="mb-6" style="font-size: 1rem">
            Copied your referral link!
          </v-alert>
        </v-fade-transition>

        <p class="text-body-1 mb-3">
          Our goal is to simplify the selling of insurance and help BackNine
          agents share their excitement and experiences with others. You can
          earn commissions when agents use your referral link to sign up and
          place business.
        </p>
        <p class="text-body-1">
          When agents sign up through your referral link, you will earn the
          difference between your commission level and the agent's commission
          level. As their upline, you can also view their business within BOSS.
        </p>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import CopyDialog from "@/dialogs/CopyDialog.vue";

import { parseErrorMessage } from "@/util/helpers";

import { getAgentSummary } from "@/api/agents.service";
import { getAgencySummary } from "@/api/agencies.service";

import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const copied = ref(false);
const referralCode = ref(null);
const fetchingReferralCode = ref(null);
const copyRef = ref(); // template ref

const referralLink = computed(() => {
  if (!referralCode.value) return null;
  const route = router.resolve({
    name: "ReferralSignUp",
    params: { referralCode: referralCode.value }
  });

  return new URL(route.href, window.location.origin).href;
});

async function fetchReferralLink() {
  fetchingReferralCode.value = true;
  try {
    let fetchFunc = getAgentSummary;
    if (user.loginable.type.toLowerCase() === "agency") {
      fetchFunc = getAgencySummary;
    }
    const { advisor } = await fetchFunc(user.loginable.id);

    referralCode.value = advisor.referralCode;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    fetchingReferralCode.value = false;
  }
}
function copyDialog() {
  const connections = [
    {
      parent_type: user.loginable.type,
      parent_id: user.loginable.id,
      hierarchy: 1
    }
  ];
  const htmlSnippet =
    `<div id="container-id"></div><script id="strife" src="https://cdn.quoteandapply.io/widget.js?prefill&mode=signup&connections=${JSON.stringify(
      connections
    )}" data-strife-container-id="container-id"></` + "script>";

  dialog.showDialog({
    component: markRaw(CopyDialog),
    text: htmlSnippet,
    title: "Quote & Apply Snippet",
    subtitle: "Just copy and paste this into your site!"
  });
}

let copyTimeout;
function copy() {
  copyRef.value.$el.querySelector("input").select();

  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  copied.value = true;
  if (copyTimeout) clearTimeout(copyTimeout);
  copyTimeout = setTimeout(() => {
    copied.value = false;
  }, 8000);
}

fetchReferralLink();
</script>
