<template>
  <v-card>
    <v-card-title>Add Temporary Insurance Profile</v-card-title>
    <v-card-text>
      <select-field
        v-model="productTemporaryInsuranceProfile.temporaryInsuranceProfile"
        label="Temporary Insurance Profile"
        data-testid="temporary-insurance-profile-select"
        item-value="additional"
        :loading="temporaryInsuranceProfiles.loading"
        :items="temporaryInsuranceProfiles.items"
        v-bind="idValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="confirm-button"
        :loading="loading"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { createTemporaryInsuranceProfile } from "@/api/products.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import useVuelidate from "@vuelidate/core";
import {
  ProductTemporaryInsuranceProfile,
  ProductTemporaryInsuranceProfileToCreateRequest
} from "@/factories/Product";
import { ref,  computed } from "vue";
import { useSearchItemsCache } from "@/stores/search-items-cache";

const props = defineProps({
  productId: { type: Number, required: true },
  carrierId: { type: Number, required: true }
});

const cache = useSearchItemsCache();
const profiles = ref(
  cache.generateTemporaryInsuranceProfileByCarrierId(props.carrierId)
);
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const productTemporaryInsuranceProfile = ref(
  ProductTemporaryInsuranceProfile()
);
const loading = ref(false);

const temporaryInsuranceProfiles = computed(() => profiles.value.state);
const v$ = useVuelidate(
  {
    productTemporaryInsuranceProfile: {
      temporaryInsuranceProfile: { required: v => Boolean(v?.id) }
    }
  },
  { productTemporaryInsuranceProfile },
  { $autoDirty: true, $scope: null }
);

const idValidation = computedValidation(
  v$.value.productTemporaryInsuranceProfile.temporaryInsuranceProfile,
  {
    required: "Required"
  }
);

async function getTemporaryInsuranceProfiles() {
  try {
    await profiles.value.fetch();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid || loading.value) return;

  try {
    const response = await createTemporaryInsuranceProfile(
      props.productId,
      ProductTemporaryInsuranceProfileToCreateRequest(
        productTemporaryInsuranceProfile.value
      )
    );
    dialog.closeDialog(response);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  }
}

getTemporaryInsuranceProfiles();
</script>
