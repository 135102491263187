<template>
  <div>
    <dialog-manager />
    <snackbar-manager />
  </div>
</template>
<script setup>
import DialogManager from "@/app-services/DialogManager.vue";
import SnackbarManager from "@/app-services/SnackbarManager.vue";
import { useOutboundCallObserver } from "@/app-services/OutboundCallObserver.mjs";
import { useThemeManager } from "@/app-services/ThemeManager.mjs";

useOutboundCallObserver();
useThemeManager();
</script>
