import { formatBytes } from "@/util/helpers";

export const DOCUMENT_CATEGORIES = {
  SIGNATURE_AUTHORIZATION: "Signature Authorization"
};

export function Document(model = {}) {
  return {
    canDelete: model?.canDelete || null,
    category: model?.category || null,
    createdAt: model?.createdAt || null,
    ext: model?.ext || null,
    id: model?.id || null,
    uid: model?.uid || null,
    mimeType: model?.mimeType || null,
    name: model?.name || null,
    ownable: model?.ownable || null,
    contentSize: model?.contentSize || 0,
    fileSize: model?.fileSize || 0
  };
}

export function setDocumentFromRequest(model = {}) {
  const document = Document();
  document.uid = model?.uid;
  document.canDelete = model?.can_delete;
  document.category = model?.category;
  document.createdAt = model?.created_at;
  document.ext = model?.ext;
  document.id = model?.id;
  document.mimeType = model?.mime_type;
  document.name = model?.name;
  document.ownable = model?.ownable;
  document.contentSize = model?.content_size;
  if (document.contentSize) {
    document.fileSize = formatBytes(document.contentSize);
  }
  return document;
}
