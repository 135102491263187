<template>
  <v-card v-if="onClick" class="card-item" variant="flat" @click="onClick">
    <v-card-text class="pa-0">
      <div class="text-grey lighten-2">{{ props.title }}</div>
      <div class="text-primary white-space-pre-wrap" v-text="props.text"></div>
    </v-card-text>
  </v-card>
  <div v-else class="card-item">
    <div class="text-grey lighten-2">{{ props.title }}</div>
    <div class="white-space-pre-wrap">{{ props.text }}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: { type: String, required: true },
  text: { type: String, required: true },
  onClick: { type: Function, required: false, default: null }
});
</script>
