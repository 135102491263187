<template>
  <v-card>
    <v-card-title> Apply Missing Product Alias </v-card-title>
    <v-card-text>
      <product-search
        v-model="product"
        data-testid="product-input"
        :success="Boolean(product?.id)"
      />
      <text-field
        ref="input"
        readonly
        data-testid="plan-name"
        :prepend-inner-icon="mdiTableQuestion"
        :model-value="props.planName"
        @click="copy"
      />
      <v-fade-transition mode="out-in">
        <product-view-alias-table
          v-if="product"
          :id="product.id"
          :key="product.id"
        />
      </v-fade-transition>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import ProductSearch from "@/components/shared/ProductSearch.vue";
import ProductViewAliasTable from "@/components/products/ProductViewAliasTable.vue";
import { useDialogStore } from "@/stores/dialog";
import { ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiTableQuestion } from "@mdi/js";

const props = defineProps({
  planName: {
    type: String,
    required: true
  }
});
const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const product = ref(null);
const input = ref(null); // templateref
function copy() {
  input.value.$el.querySelector("input").select();

  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  snackbar.showSuccessSnackbar({ message: "Copied to clipboard!" });
}
</script>
