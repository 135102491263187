<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    class="transparent-data-table"
    data-testid="pay-periods-table"
    must-sort
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0 px-3 py-0" align="center">
        <div class="text-subtitle-1 text-grey py-1">
          A Statement is the summation of Payments paid by BackNine. Statements
          from carriers are available on the carriers' websites.
        </div>
        <v-row class="ma-0" justify="end" align="center">
          <table-stats :stats="stats" />
          <v-tooltip location="bottom">
            <template #activator="{ props: templateProps }">
              <app-button
                v-bind="templateProps"
                variant="text"
                density="comfortable"
                class="table-btn"
                :icon="mdiRefresh"
                data-testid="pay-periods-table-refresh"
                @click="getData"
              />
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </v-row>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.id`]="{ item }">
      <router-link
        :to="{
          name: 'PayPeriodView',
          params: { id: item.id }
        }"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template #[`item.amount`]="{ item }">
      <currency-formatter :model-value="item.amount" :decimal-length="2" />
    </template>
    <template #[`item.date`]="{ item }">
      <timestamp-formatter format="date-time" :model-value="item.date" />
    </template>
    <template #[`item.actions`]="{ item }">
      <span style="white-space: nowrap">
        <v-tooltip v-if="item.additional.editable" location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              v-bind="templateProps"
              variant="text"
              density="comfortable"
              color="primary"
              data-testid="edit-pay-period"
              :icon="mdiPencil"
              @click="editPayPeriod(item.additional)"
            />
          </template>
          <span>Edit Statement</span>
        </v-tooltip>
        <v-tooltip v-if="item.additional.payable" location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              v-bind="templateProps"
              color="accent"
              variant="text"
              density="comfortable"
              data-testid="pay-pay-period"
              :icon="mdiCurrencyUsd"
              @click="pay(item.additional)"
            />
          </template>
          <span>{{ item.additional.paymentMethod }}</span>
        </v-tooltip>
      </span>
    </template>
  </v-data-table-server>
</template>

<script setup>
import EditPayPeriodDialog from "@/dialogs/EditPayPeriodDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableStats from "@/components/shared/data-table/TableStats.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { TableOptions } from "@/classes/data-table/TableOptions";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useTableStore } from "@/stores/table";
import { useSnackbarStore } from "@/stores/snackbar";

import { getPayPeriods, payPayPeriod } from "@/api/pay-periods.service";
import { mdiPencil, mdiCurrencyUsd, mdiRefresh } from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { computed, markRaw } from "vue";
import { parseErrorMessage } from "@/util/helpers";

const tableStore = useTableStore();
const user = useUserStore();
const dialog = useDialogStore();

const { payPeriodTable } = storeToRefs(tableStore);
const table = useTable({
  getData: getPayPeriods,
  shouldIncludeCancelToken: true,
  headers: [
    new TableHeader({
      text: "Statement ID",
      value: "id",
      map: "id",
      sortFilterMap: "pay_periods.id",
      ...TableHeader.TOTAL_ORDER(2),
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Paid To",
      value: "paidTo",
      map: "paidTo.name",
      sortFilterMap: [
        { key: "payable_id", value: "id" },
        { key: "payable_type", value: "type" }
      ],
      ...TableHeader.TOTAL_ORDER(3),
      ...TableHeader.IS_ADVISOR_PAYMENT_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Amount",
      value: "amount",
      map: "paidAmount",
      sortFilterMap: "paid_amount_at",
      ...TableHeader.TOTAL_ORDER(5),
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Payment Method",
      value: "paymentMethod",
      map: "payingTo",
      ...TableHeader.TOTAL_ORDER(7),
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Paid Date",
      value: "date",
      map: "paidDate",
      sortFilterMap: "pay_periods.paid_date",
      ...TableHeader.TOTAL_ORDER(8),
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ]
});

const PAID = "paid";

if (user.isGroupTwoPlus) {
  table.headers.value.push(
    new TableHeader({
      text: "Paid By",
      value: "paidBy",
      map: "paidBy.name",
      sortFilterMap: [
        { key: "billable_id", value: "id" },
        { key: "billable_type", value: "type" }
      ],
      ...TableHeader.TOTAL_ORDER(4),
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    })
  );
}

if (user.isGroupThreePlus) {
  table.headers.value.push(
    new TableHeader({
      text: "Paid or Unpaid",
      value: "type",
      map: "type",
      selectableOptions: [
        { title: "Paid", value: PAID },
        { title: "Unpaid", value: "unpaid" }
      ],
      sortFilterMap: "type",
      ...TableHeader.TOTAL_ORDER(1),
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_MANDATORY
    })
  );
}

if (user.isGroupFour) {
  table.headers.value.push(
    new TableHeader({
      text: "Transaction ID",
      value: "transactionId",
      map: "transactionId",
      sortFilterMap: "transaction_id",
      ...TableHeader.TOTAL_ORDER(6),
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      sortFilterMap: "pay_periods.status",
      ...TableHeader.TOTAL_ORDER(9),
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Status Note",
      value: "statusNote",
      map: "statusNote",
      ...TableHeader.TOTAL_ORDER(10)
    }),
    new TableHeader({
      text: "Errors",
      value: "errors",
      map: "errors",
      ...TableHeader.TOTAL_ORDER(11),
      showIf: ({ type }) => type !== PAID
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      ...TableHeader.TOTAL_ORDER(12),
      showIf: ({ type }) => type !== PAID
    })
  );
}

table.ingestFromStore({
  filter: payPeriodTable.value.filter,
  options: payPeriodTable.value.options,
  defaultFilter: {},
  defaultOptions: TableOptions({ sortBy: [{ key: "id", order: "desc" }] })
});

const stats = computed(() => {
  const score = {
    text: "Paid Total",
    type: "loading",
    dataTestid: "stat-paid-total"
  };
  if ("paid_amount_at_sum" in table.stats.value) {
    score.value = table.stats.value.paid_amount_at_sum;
    score.type = "dollar";
  }
  return [score];
});

async function editPayPeriod(payPeriod) {
  const response = await dialog.showDialog({
    component: markRaw(EditPayPeriodDialog),
    modelValue: payPeriod
  });
  if (response?.updated) getData();
}

function pay(payPeriod) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Confirm Payment",
    message: "Are you sure you want to send payment?",
    func: async () => {
      try {
        await payPayPeriod(payPeriod.id);
        await getData();
      } catch (e) {
        if (e.response.status == 422) {
          payPeriod.errors = e.response.data.message;
          dialog.closeDialog();
          editPayPeriod(payPeriod);
        }
      }
    }
  });
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  payPeriodTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  payPeriodTable.value.filter = filter;
  table.resetPage();
  getData();
}

defineExpose({ stats, getData });
</script>
