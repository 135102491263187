<template>
  <v-card>
    <v-card-title> {{ title }} </v-card-title>
    <v-card-subtitle v-if="subtitle"> {{ subtitle }} </v-card-subtitle>
    <v-card-text>
      <div
        v-if="jpeg"
        id="copy"
        contenteditable
        style="background-color: transparent"
        @click="copy"
      >
        <img ref="copy" :src="jpeg" height="70" width="244" />
      </div>
      <textarea-field
        v-else
        ref="copy"
        data-testid="copy-textarea"
        :label="title"
        :model-value="text"
        readonly
        rows="4"
        @click="copy"
      />
      <v-alert v-if="copied" type="success" class="mt-4" variant="outlined">
        Copied to clipboard!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        color="grey"
        data-testid="copy-dialog-close"
        @click="closeDialog"
      >
        Close
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="copy-button"
        @click="copy"
        >Copy</app-button
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "pinia";
import { selectImage } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
export default {
  props: {
    text: { type: String, default: null },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    jpeg: Boolean
  },
  data() {
    return {
      copied: false,
      copyTimer: null
    };
  },
  methods: {
    ...mapActions(useDialogStore, ["closeDialog"]),
    copy() {
      if (this.jpeg) {
        selectImage(document.getElementById("copy"));
      } else {
        this.$refs.copy.$el.querySelector("textarea").select();
      }

      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      this.copied = true;
      if (this.copyTimer) clearTimeout(this.copyTimer);
      this.copyTimer = setTimeout(() => {
        this.copied = false;
      }, 8000);
    }
  }
};
</script>
