<template>
  <v-snackbar
    id="snackbar"
    closable
    data-testid="snackbar"
    :bottom="mdAndUp"
    :model-value="show"
    :timeout="timeout"
    :color="color"
    @update:model-value="handleInput"
  >
    {{ message }}
    <template #actions>
      <app-button
        color="white"
        data-testid="snackbar-dismiss"
        variant="text"
        density="comfortable"
        :icon="mdiClose"
        @click="snackbar.hideSnackbar()"
      />
    </template>
  </v-snackbar>
</template>

<script setup>
import { mdiClose } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDisplay } from "vuetify/lib/framework.mjs";

const snackbar = useSnackbarStore();
const { message, timeout, show, color } = storeToRefs(snackbar);

const { mdAndUp } = useDisplay();

function handleInput(v) {
  if (v) return;
  snackbar.hideSnackbar();
}
</script>
