import { getHttpClient } from "@/http-client";
import { setExamFromRequest } from "@/factories/Task";

const baseUrl = "api/boss/exams";
export async function getExam(caseId, id) {
  const params = new URLSearchParams();
  params.append("case_id", caseId);
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`, { params });
  return setExamFromRequest(data.exam);
}

export async function updateExam(id, body) {
  const { data } = await getHttpClient().put(`${baseUrl}/${id}`, body);
  return setExamFromRequest(data);
}

export function deleteExam(caseId, taskId) {
  const params = new URLSearchParams();
  params.append("case_id", caseId);
  return getHttpClient().delete(`${baseUrl}/${taskId}`, { params });
}
