<template>
  <autocomplete-field
    v-bind="$attrs"
    ref="contractPartySearch"
    v-model="party"
    v-model:search="partySearch"
    autocomplete="false"
    placeholder="Search Contract Parties"
    return-object
    no-filter
    hide-no-data
    :data-testid="dataTestid"
    :label="label"
    :prepend-inner-icon="mdiAccount"
    :item-title="itemTextGenerator"
    :items="parties"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
  >
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
    </template>
  </autocomplete-field>
</template>

<script setup>
import { searchForContractParty } from "@/api/contract-party.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiAccount } from "@mdi/js";
import { ref, watch, toRef } from "vue";
const props = defineProps({
  modelValue: {
    type: Object,
    required: false,
    default: null
  },
  simple: Boolean,
  superSimple: Boolean,
  dataTestid: { type: String, required: false, default: null },
  label: {
    type: String,
    default: "Contract Party"
  }
});

const emit = defineEmits(["update:model-value", "blur"]);
const modelValue = toRef(props, "modelValue");

const snackbar = useSnackbarStore();

const parties = ref([]);
const party = ref(null);
const partySearch = ref("");
const loading = ref(false);
const contractPartySearch = ref(null); // templateref

watch(
  modelValue,
  () => {
    parties.value.splice(0, parties.value.length);
    if (!modelValue.value || !Object.keys(modelValue.value).length) {
      party.value = null;
      return;
    }
    party.value = modelValue.value;
    parties.value.push(modelValue.value);
  },
  { immediate: true }
);

function partyEquivalence(a, b) {
  return a?.type === b?.type && a?.id === b?.id;
}

watch(party, () => {
  if (partyEquivalence(party.value, modelValue.value)) return;
  emit("update:model-value", party.value);
});

let timer;
watch(partySearch, () => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(getParties, 400);
});

async function getParties() {
  if (!partySearch.value) return;

  if (
    parties.value.some(val => itemTextGenerator(val) === partySearch.value) &&
    parties.value.length === 1
  ) {
    return;
  }

  loading.value = true;
  try {
    const res = await searchForContractParty(partySearch.value);
    parties.value.splice(0, parties.value.length);
    parties.value.push(...res);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function focus() {
  if (!contractPartySearch.value?.$el) return;
  const input = contractPartySearch.value.$el.querySelector("input");
  if (!input?.focus) return;
  input.focus();
}

function itemTextGenerator(value) {
  if (props.superSimple && value.lastFour) {
    return `${value.name} (${value.lastFour})`;
  }

  if (value.firstName && value.lastName) {
    return `${value.firstName} ${value.lastName}`;
  }

  return value.name;
}

defineExpose({ focus });
</script>
