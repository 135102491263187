<template>
  <v-card>
    <v-card-title>Send Custom Chat Template</v-card-title>
    <v-card-subtitle>
      Configure a chat template and send it to a client
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="px-2 pt-3">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-card-title class="text-body-1 font-weight-medium pt-0">
            Chat Template Details
          </v-card-title>
          <v-card-subtitle>
            Specify a chat template and send it to a client
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <autocomplete-field
                  v-model="selectedTemplate"
                  label="Chat Template"
                  class="has-append-button"
                  hide-details="auto"
                  item-title="name"
                  data-testid="chat-template-select"
                  return-object
                  :prepend-inner-icon="mdiFile"
                  :loading="loadingTemplates"
                  :items="templates"
                  v-bind="selectedTemplateValidation"
                  @update:model-value="updateSelectedTemplateData"
                >
                  <template #append-inner>
                    <app-button
                      :icon="mdiRefresh"
                      :loading="loadingTemplates"
                      @click="getTemplates"
                    />
                  </template>
                </autocomplete-field>
              </v-col>
              <v-col cols="12">
                <text-field
                  v-model="email.subjectLine"
                  label="Subject"
                  data-testid="subject-line"
                  hide-details="auto"
                  :prepend-inner-icon="mdiFormatTitle"
                  v-bind="subjectValidation"
                />
              </v-col>
              <v-col cols="12">
                <textarea-field
                  v-model="email.mainText"
                  label="Main Text"
                  hide-details="auto"
                  data-testid="main-text"
                  :prepend-inner-icon="mdiText"
                  v-bind="mainTextValidation"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6">
          <v-card-title class="text-body-1 font-weight-medium pt-0">
            Preview
          </v-card-title>
          <v-card-subtitle>
            Preview of the email that will be sent to the client.
            <a
              data-testid="send-preview"
              :class="{
                'text-primary': !sendingPreviewEmail,
                'text-grey': sendingPreviewEmail
              }"
              @click="previewTemplate"
            >
              Send a Preview to yourself.
            </a>
            <v-progress-circular
              v-if="sendingPreviewEmail"
              indeterminate
              size="12"
              width="1"
              color="primary"
            />
          </v-card-subtitle>
          <v-card-text>
            <iframe
              style="height: 40vh; width: 100%; border: 1px solid #ccc"
              class="rounded"
              data-testid="preview-iframe"
              :srcdoc="previewHTML"
              sandbox
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>

      <app-button
        class="text-none"
        color="primary"
        data-testid="send-email"
        :loading="sendingEmail"
        @click="sendTemplate"
      >
        Send
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { mdiFile, mdiFormatTitle, mdiText, mdiRefresh } from "@mdi/js";
import {
  getHydratedCustomChatTemplate,
  getCustomChatTemplates,
  sendCustomChatTemplate,
  previewLiveCustomChatTemplate
} from "@/api/chat-templates.service";
import {
  CustomChatTemplateEmail,
  CustomChatTemplateEmailToRequest
} from "@/factories/CustomChatTemplate";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import useVuelidate from "@vuelidate/core";
import { computed, ref } from "vue";

const props = defineProps({
  emailSubject: {
    type: Object,
    required: true
  }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const email = ref(
  CustomChatTemplateEmail({
    emailSubject: props.emailSubject
  })
);
const selectedTemplate = ref(null);
const templates = ref([]);
const loadingTemplates = ref(false);
const sendingEmail = ref(false);
const sendingPreviewEmail = ref(false);

const v$ = useVuelidate(
  {
    selectedTemplate: {
      required: v => Boolean(v?.id)
    },
    email: {
      chatTemplate: {
        required: v => Boolean(v?.id)
      },
      mainText: {
        required: v => someTextValidator(true, v, 2),
        isValid: v => /^[A-Za-z0-9\s,;:'"-.!?]+$/.test(v)
      },
      subjectLine: {
        required: v => someTextValidator(true, v, 2),
        isValid: v => /^[A-Za-z0-9\s,;:'"-.!?]+$/.test(v)
      }
    }
  },
  {
    email,
    selectedTemplate
  },
  { $scope: null, $autoDirty: true }
);

const selectedTemplateValidation = computedValidation(
  v$.value.selectedTemplate,
  {
    required: "Required"
  }
);
const subjectValidation = computedValidation(v$.value.email.subjectLine, {
  required: "Required",
  isValid: "May only contain letters, numbers, and ,;:-.!?"
});
const mainTextValidation = computedValidation(v$.value.email.mainText, {
  required: "Required",
  isValid: "May only contain letters, numbers, and ,;:-.!?"
});

const previewHTML = computed(() => {
  if (!email.value.chatTemplate?.id) {
    return "Please select a chat template to get started.";
  }

  return email.value.chatTemplate.html.replaceAll(
    "[MainText]",
    email.value.mainText
  );
});

async function updateSelectedTemplateData() {
  email.value.chatTemplate = null;
  try {
    const body = {
      model_id: email.value.emailSubject.id
    };

    email.value.chatTemplate = await getHydratedCustomChatTemplate(
      selectedTemplate.value.id,
      body
    );
    email.value.mainText = email.value.chatTemplate.mainText;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function getTemplates() {
  try {
    loadingTemplates.value = true;
    const params = new URLSearchParams();
    params.append("published", "true");
    params.append("model_type", props.emailSubject.type);
    const t = await getCustomChatTemplates(params);
    templates.value.splice(0, templates.value.length);
    t.sort((a, b) => a.name.localeCompare(b.name));
    templates.value.push(...t);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingTemplates.value = false;
  }
}

async function previewTemplate() {
  if (sendingPreviewEmail.value) return;
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    sendingPreviewEmail.value = true;
    await previewLiveCustomChatTemplate(
      email.value.chatTemplate.id,
      CustomChatTemplateEmailToRequest(email.value)
    );
    snackbar.showSuccessSnackbar({
      message: "Chat template sent successfully. Check your inbox shortly."
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    sendingPreviewEmail.value = false;
  }
}

async function sendTemplate() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    sendingEmail.value = true;
    await sendCustomChatTemplate(
      email.value.chatTemplate.id,
      CustomChatTemplateEmailToRequest(email.value)
    );
    snackbar.showSuccessSnackbar({
      message: "Chat template sent successfully."
    });
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    sendingEmail.value = false;
  }
}

getTemplates();
</script>
