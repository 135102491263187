<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="missing-product-aliases-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5">Missing Product Aliases</h1>
        <v-spacer />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              class="table-btn"
              v-bind="templateProps"
              data-testid="missing-product-aliases-table-refresh"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
    </template>

    <template #[`item.actions`]="{ item }">
      <app-button
        color="accent"
        data-testid="edit"
        variant="text"
        density="comfortable"
        :icon="mdiPencil"
        @click="applyAlias(item.additional)"
      />
    </template>
  </v-data-table>
</template>

<script setup>
import ApplyMissingProductAliasDialog from "@/components/products/dialogs/ApplyMissingProductAliasDialog.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { fetchMissingAliases } from "@/api/engines.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { mdiRefresh, mdiPencil } from "@mdi/js";
import { markRaw } from "vue";
import { useDialogStore } from "@/stores/dialog";

const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Carrier Name",
      value: "carrierName",
      map: "carrierName",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Plan Name",
      value: "planName",
      map: "planName",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Type",
      value: "type",
      map: "type",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ],
  getData: fetchMissingAliases,
  options: TableOptions({ sortBy: [{ key: "carrierName", order: "asc" }] })
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function applyAlias(item) {
  dialog.showDialog({
    component: markRaw(ApplyMissingProductAliasDialog),
    planName: item.planName.trim()
  });
}

getData();
</script>
