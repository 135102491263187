<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        data-testid="medical-sources-table"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        :items-per-page="-1"
        :mobile="null"
        mobile-breakpoint="sm"
        :items="table.mappedItems.value"
        :headers="table.tableHeaders.value"
      >
        <template #[`item.created_at`]="{ item }">
          <timestamp-formatter :model-value="item.created_at" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { useEappViewStore } from "@/stores/eapp-view";
import TableHeader from "@/classes/data-table/TableHeader";
import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { watch } from "vue";

const eapp = useEappViewStore();
const { insured } = storeToRefs(eapp);

const table = useTable({
  headers: [
    new TableHeader({ text: "Name", value: "name", map: "name" }),
    new TableHeader({ text: "Status", value: "status", map: "status" }),
    new TableHeader({ text: "Created", value: "created_at", map: "created_at" })
  ]
});

watch(
  () => insured.value.medicalSources,
  v => {
    table.items.value.splice(0, table.items.value.length);
    table.items.value.push(...v);
  },
  { deep: true, immediate: true }
);
</script>
