<template>
  <v-card flat>
    <v-card-title>Commission Splits</v-card-title>
    <v-card-subtitle class="mb-3">
      Specify the agent(s) you would like to split the commission with for this
      eApp. The agent(s) you list will automatically be added on the agent's
      report for their respective percentage as an additional agent. The
      agent(s) must be licensed in the state of the application in order to be
      added to the application. You must maintain at least 1% commission split.
      Enabling Commission Splits on this eApp will override the Commission
      Splits in the agent's settings.
    </v-card-subtitle>
    <v-card-text class="pt-0">
      <div class="checkbox-width">
        <checkbox-field
          v-model="overrideCommissionSplits"
          hide-details
          data-testid="commission-splits-override"
          :disabled="agent.lockCommissions || disabled"
          @update:model-value="updateAttribute('overrideCommissionSplits')"
        >
          <template #label>
            <template v-if="agent.lockCommissions">
              {{ agent.name }}'s Commissions are Locked
            </template>
            <template v-else>
              Override {{ agent.name }}'s Commission Splits

              <active-save-indicator
                :controller="
                  savingBuffer.overrideCommissionSplits.controller.value
                "
                class="ml-2"
              />
            </template>
          </template>
        </checkbox-field>
      </div>

      <commission-splits
        v-if="overrideCommissionSplits"
        v-model="commSplits"
        style="margin-bottom: 50px"
        :advisor-name="agent.name"
        :disabled="agent.lockCommissions || disabled"
        :update-func="eappView.updateApprovedDomainCommissionSplit"
        :delete-func="eappView.deleteApprovedDomainCommissionSplit"
        :create-func="eappView.createApprovedDomainCommissionSplit"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import CommissionSplits from "@/components/shared/CommissionSplits.vue";

import { storeToRefs } from "pinia";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, nextTick, onMounted, ref } from "vue";
import { useEappViewStore } from "@/stores/eapp-view";

const eappView = useEappViewStore();

const {
  agent,
  commissionSplits: commSplits,
  overrideCommissionSplits,
  allowCommissionChange
} = storeToRefs(eappView);

const disabled = computed(() => !allowCommissionChange.value);

const isMounted = ref(false);
onMounted(() => nextTick(() => (isMounted.value = true)));

const savingBuffer = {
  overrideCommissionSplits: useActiveSave()
};

function updateAttribute(attribute) {
  if (!isMounted.value) return;
  if (agent.value.lockCommissions) return;

  savingBuffer[attribute].update(eappView.updateOverrideCommissionSplits);
}
</script>
