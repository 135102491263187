<template>
  <v-card :color="color" height="100%" width="100%" class="text-white">
    <div style="height: 100%; display: flex; align-items: center">
      <v-icon
        size="large"
        style="position: absolute; top: 2.5rem; right: 0.5rem"
        :icon="icon"
      />
      <v-progress-circular
        v-if="type === 'loading'"
        indeterminate
        class="ml-3"
      />
      <v-col v-else :align="left ? 'start' : 'center'">
        <h1 v-if="type === 'number'">
          <animated-number
            :key="text"
            :model-value="modelValue"
            :format-value="numberFormat"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else-if="type === 'dollar'">
          <animated-number
            :key="text"
            :model-value="modelValue"
            :format-value="v => currencyFormat(v, 0)"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else-if="type === 'percent'">
          <animated-number
            :key="text"
            :model-value="modelValue"
            :format-value="percentageFormat"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else-if="type === 'fractionalPercent'">
          <animated-number
            :key="text"
            :model-value="modelValue"
            :format-value="v => percentageFormat(v, true)"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h1 v-else>
          <animated-number
            :key="text"
            :model-value="modelValue"
            :duration="1000"
            :round="1"
          />
        </h1>
        <h5 style="font-weight: regular">{{ text }}</h5>
      </v-col>
    </div>
    <app-button
      v-if="hint"
      style="position: absolute; bottom: 0; right: 0"
      variant="text"
      density="comfortable"
      :icon="mdiInformation"
      @click.prevent="emit('hint')"
    />
  </v-card>
</template>

<script setup>
import AnimatedNumber from "@/components/shared/AnimatedNumber.vue";
import { currencyFormat, numberFormat, percentageFormat } from "@/util/helpers";
import { mdiInformation } from "@mdi/js";

defineProps({
  type: { type: String, required: true },
  text: { type: String, required: true },
  modelValue: { type: [String, Number], required: true },
  hint: Boolean,
  color: {
    required: false,
    default: "primary",
    type: String
  },
  left: {
    required: false,
    default: false,
    type: Boolean
  },
  icon: {
    required: false,
    default: "",
    type: String
  }
});

const emit = defineEmits(["hint"]);
</script>
