<template>
  <v-card flat>
    <v-row class="ma-0">
      <v-card-title> Build Charts </v-card-title>
      <v-spacer />
      <app-button
        class="text-none ma-3"
        color="primary"
        variant="text"
        density="comfortable"
        data-testid="add-product-build-chart"
        :icon="mdiPlus"
        @click="add"
      />
    </v-row>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="item in productBuildCharts"
        :key="item.id"
        data-testid="product-build-chart"
      >
        <v-list-item-title>{{ item.buildChart.name }}</v-list-item-title>

        <template #append>
          <app-button
            class="text-none"
            :icon="mdiDelete"
            color="error"
            variant="text"
            density="comfortable"
            data-testid="remove"
            @click="remove(item)"
          />
        </template>
      </v-list-item>
      <v-list-item v-if="!productBuildCharts.length">
        <v-list-item-title>No Build Charts</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import AddProductBuildChartDialog from "@/components/products/dialogs/AddProductBuildChartDialog.vue";

import { removeProductBuildChart } from "@/api/product-build-charts.service";
import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useProductView } from "@/stores/product-view";
import { mdiPlus, mdiDelete } from "@mdi/js";
import { markRaw } from "vue";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const productView = useProductView();
const { productBuildCharts, id, carrier } = storeToRefs(productView);

function remove(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Product Build Chart",
    subtitle: "Are you sure you want to delete this Product Build Chart?",
    func: async () => {
      await removeProductBuildChart(item.id);
      snackbar.showSuccessSnackbar({
        message: "Product Build Chart has been deleted",
        timeout: 6000
      });

      const index = productBuildCharts.value.findIndex(v => item.id === v.id);
      productBuildCharts.value.splice(index, 1);
    }
  });
}

async function add() {
  const res = await dialog.showDialog({
    component: markRaw(AddProductBuildChartDialog),
    productId: id.value,
    carrierId: carrier.value.id
  });
  if (!res?.productBuildChart) return;
  productBuildCharts.value.push(res.productBuildChart);
  snackbar.showSuccessSnackbar({
    message: "Build Chart has been added",
    timeout: 6000
  });
}
</script>
