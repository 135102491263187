<template>
  <v-tooltip v-if="!showSearchBar && !alwaysVisible" location="bottom">
    <template #activator="{ props: templateProps }">
      <app-button
        data-testid="show-search-toggle"
        variant="text"
        density="comfortable"
        class="opacity-60"
        v-bind="templateProps"
        :icon="mdiMagnify"
        @click="showSearch"
      />
    </template>
    <span> Search BOSS </span>
  </v-tooltip>
  <div v-else>
    <sitewide-search
      id="menu-search"
      ref="searchRef"
      :key="$route.path"
      as-router-link
      :show-nav-helpers="!alwaysVisible"
      autocomplete="off"
      style="min-width: 300px"
      placeholder="Type to search BOSS"
      return-object
      hide-no-data
      hide-selected
      :density="alwaysVisible ? 'default' : 'compact'"
      :prepend-inner-icon="$slots['prepend-inner'] ? null : mdiMagnify"
      tabindex="0"
      inputmode="search"
      data-testid="search-bar"
      :autofocus="autofocus || showSearchBar"
      :variant="!notDense ? 'outlined' : 'underlined'"
      :class="{
        'mt-0': notDense,
        'menu-w-100': notDense,
        'pt-3': !alwaysVisible
      }"
      :attach="notDense"
      :dense="!notDense"
      :clearable="!notDense"
      :label="label"
      @blur="hideSearch"
    >
      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>
    </sitewide-search>
  </div>
</template>

<script setup>
import { mdiMagnify } from "@mdi/js";
import SitewideSearch from "@/components/shared/SitewideSearch.vue";
import { ref, onMounted, nextTick } from "vue";
const props = defineProps({
  startExpanded: Boolean,
  alwaysVisible: Boolean,
  autofocus: Boolean,
  label: {
    default: "Search BOSS",
    type: String
  },
  notDense: Boolean
});
const showSearchBar = ref(false);

onMounted(() => {
  if (props.startExpanded) nextTick(showSearch);
});

function showSearch() {
  showSearchBar.value = true;
}
function hideSearch() {
  showSearchBar.value = false;
}
</script>
