<template>
  <autocomplete-field
    v-model="model"
    return-object
    :prepend-inner-icon="mdiShape"
    :hide-details="hideDetails"
    :clearable="clearable"
    :loading="formCategories.fetchingFormCategories"
    :label="label"
    :disabled="readonly"
    :items="formCategories.items"
    :success="success"
    :error-messages="errorMessages"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { mdiShape } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, toRefs, watch } from "vue";
const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  returnObject: Boolean,
  label: {
    type: String,
    default: ""
  },
  readonly: {
    type: Boolean
  },
  clearable: Boolean,
  hideDetails: Boolean,
  success: {
    type: Boolean
  },
  errorMessages: {
    type: Array,
    default: () => []
  }
});

const { modelValue } = toRefs(props);

const searchItemsCache = useSearchItemsCache();
const snackbar = useSnackbarStore();
const { formCategories } = storeToRefs(searchItemsCache);

const emit = defineEmits(["update:model-value"]);

const model = ref(null);

function setModelValue(value) {
  const v = value || null;
  let currentValue;
  if (props.returnObject) {
    currentValue = model.value;
  } else {
    currentValue = model.value?.value || null;
  }
  if (currentValue === v) return;
  model.value = v;
}

async function getFormCategories() {
  try {
    await searchItemsCache.getFormCategoriesList();
    if (props.returnObject) {
      setModelValue(modelValue.value);
    } else {
      const value = formCategories.value.items.find(
        i => i.value === modelValue.value
      );
      setModelValue(value);
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function clear() {
  model.value = null;
}

watch(model, v =>
  emit("update:model-value", props.returnObject ? v : v?.value || null)
);

watch(modelValue, setModelValue);

defineExpose({ clear });

getFormCategories();
</script>
