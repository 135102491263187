import { getHttpClient } from "@/http-client";
import { setToDoFromResponse } from "@/factories/ToDo";
import { serializeObject } from "@/util/helpers";
const baseUrl = "api/boss/todos";

export async function createTodo(body) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject({ todo: body })
  );
  return data.id;
}

export async function getTodos(params, cancelToken) {
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  return {
    meta: data.meta,
    items: data.todos.map(setToDoFromResponse)
  };
}

export async function getTodo(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setToDoFromResponse(data);
}

export function updateTodo(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, { todo: body });
}

export function deleteTodo(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export function deleteTodoSubject(id, subjectId) {
  return getHttpClient().delete(`${baseUrl}/${id}/subjects/${subjectId}`);
}

export function createTodoSubject(id, body) {
  return getHttpClient().post(`${baseUrl}/${id}/subjects`, {
    todo_subject: body
  });
}

export function deleteTodoAssignee(id, assigneeId) {
  return getHttpClient().delete(`${baseUrl}/${id}/assignees/${assigneeId}`);
}

export function createTodoAssignee(id, body) {
  return getHttpClient().post(`${baseUrl}/${id}/assignees`, {
    todo_assignee: body
  });
}

export async function createTodoDocument(id, body) {
  return getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    await serializeObject({ file: body })
  );
}
