<template>
  <v-row class="ma-0 pa-3">
    <v-spacer />
    <app-button
      variant="outlined"
      color="primary"
      class="text-none ma-1 rounded-pill"
      data-testid="view-cases-action"
      @click="viewCases"
    >
      <v-icon class="mr-1" :icon="mdiBriefcaseVariant" />
      View {{ displayName }}'s Cases
    </app-button>
    <app-button
      v-if="canDelete"
      variant="outlined"
      color="error"
      data-testid="delete-action"
      class="text-none ma-1 rounded-pill"
      @click="deleteContractParty"
    >
      <v-icon class="mr-1" :icon="mdiDelete" />
      Delete Client
    </app-button>
  </v-row>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useContractPartyView } from "@/stores/contract-party-view";
import { useRouter } from "vue-router";
import { mdiDelete, mdiBriefcaseVariant } from "@mdi/js";
import { useDialogStore } from "@/stores/dialog";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const router = useRouter();
const dialog = useDialogStore();
const contractPartyView = useContractPartyView();
const { displayName, canDelete } = storeToRefs(contractPartyView);
const { casesTable } = storeToRefs(useTableStore());

function viewCases() {
  casesTable.value.filter = { client: displayName.value };
  if (router) router.push({ name: "CasesTable" });
}

async function deleteContractParty() {
  dialog.showDialog({
    component: ConfirmationDialog,
    title: `Delete ${displayName.value}`,
    subtitle: "Please confirm your intent",
    func: async () => {
      await contractPartyView.deleteContractParty();
      if (router) router.push({ name: "ClientsTable" });
    }
  });
}
</script>
