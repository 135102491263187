import { timestampFormatter, getRouterLink } from "@/util/helpers";

function Notification(model = {}) {
  return {
    subtitle: model?.subtitle,
    title: model?.title,
    followUp: model?.followUp,
    text: model?.text,
    key: model?.key,
    routerLink: model?.routerLink
  };
}

export function CastTodoToNotification(i) {
  let title;
  if (i.subjects?.length) {
    title = i.subjects[0].name;
  }

  return Notification({
    subtitle: `Follow Up ${timestampFormatter(
      i.followUpAt,
      "none",
      "date-time"
    )}`,
    title,
    followUp: new Date(i.followUpAt).getTime(),
    text: i.title,
    key: `todo-${i.id}`,
    routerLink: getRouterLink(i.subjects[0].type, i.subjects[0].id, {
      query: { page: "to-do", "highlight-todo": i.id }
    })
  });
}

export function CastSupportTicketTableItemToNotification(i) {
  return {
    subtitle: `Created At ${timestampFormatter(
      i.createdAt,
      "none",
      "date-time"
    )}`,
    title: `Support Ticket: ${i.ownable.name}`,
    followUp: new Date(i.createdAt).getTime(),
    text: i.description,
    routerLink: getRouterLink("SupportTicket", i.id)
  };
}
