export const DISCOVERY_ITEMS = [
  "Applied/EZLynx",
  "BackNine Contact",
  "Hawksoft",
  "Insurance Soup/Career Agent Concepts",
  "LinkedIn",
  "NowCerts",
  "Other",
  "Premier Group Insurance",
  "Redtail Technology",
  "SIAA",
  "Smart Choice",
  "Vertafore",
  "Word of Mouth",
  "Wealthbox"
].sort();

export function NewUser(model = {}) {
  return {
    npn: model?.npn || null,
    email: model?.email || null,
    password: model?.password || null,
    discovery: model?.discovery || null,
    discovery_explanation: model?.discovery_explanation || null,
    marketingManager: {
      id: model?.marketingManager?.id || null,
      name: model?.marketingManager?.name || null
    },
    marketing: model?.marketing || null,
    membership: model?.membership || null,
    management: model?.management || null,
    otherManagement: model?.otherManagement || undefined,
    otherMembership: model?.otherMembership || undefined,
    referrer: model?.referrer || null,
    referral: model?.referral || null
  };
}
