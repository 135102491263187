<template>
  <v-card color="section" flat tile>
    <v-card-text class="pa-3">
      <v-row v-if="showSideBySide" class="ma-0">
        <v-col cols="12" :md="expanded ? 8 : 4" class="pr-1 pl-0 py-0">
          <slot name="left" />
        </v-col>
        <v-col cols="12" :md="expanded ? 4 : 8" class="pl-2 pr-0 py-0">
          <slot name="right" />
        </v-col>
      </v-row>
      <v-tabs-window v-else v-model="activeTab" touchless>
        <v-tabs-window-item>
          <slot name="left" />
        </v-tabs-window-item>
        <v-tabs-window-item>
          <slot name="right" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import {   ref, watch, toRef } from "vue";
const props = defineProps({
  showSideBySide: Boolean,
  modelValue: { type: Number, required: true },
  expanded: Boolean
});
const emit = defineEmits(["update:model-value"]);
const activeTab = ref(props.modelValue);

const modelValue = toRef(props, "modelValue");

watch(modelValue, newValue => {
  activeTab.value = newValue;
});

watch(activeTab, v => emit("update:model-value", v));
</script>
