export const MODEL_TYPES = [{ title: "eApp", value: "ElectronicApplication" }];

export function CustomChatTemplate(model = {}) {
  return {
    html: model?.html || "",
    mainText: model?.mainText || "",
    id: model?.id || null,
    name: model?.name || "",
    published: model?.published || false,
    modelType: model?.modelType || null
  };
}

export function setCustomChatTemplateFromRequest(raw = {}) {
  const model = CustomChatTemplate();
  model.html = raw.html;
  model.mainText = raw.default_main_body_text;
  model.id = raw.id;
  model.name = raw.name;
  model.published = raw.published;
  model.modelType = raw.model_type;
  return model;
}

export function CustomChatTemplateToRequest(model) {
  return {
    html: model.html,
    default_main_body_text: model.mainText,
    name: model.name,
    published: model.published,
    model_type: model.modelType
  };
}

export function CustomChatTemplateTableItem(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    published: model?.published,
    modelType: model?.modelType,
    formattedModelType: model?.formattedModelType
  };
}

export function setCustomChatTemplateTableItemFromRequest(raw = {}) {
  const model = CustomChatTemplateTableItem();
  model.id = raw.id;
  model.name = raw.name;
  model.published = raw.published;
  model.modelType = raw.model_type;
  model.formattedModelType = MODEL_TYPES.find(
    t => t.value === raw.model_type
  )?.title;
  return model;
}

export function CustomChatTemplateEmail(model = {}) {
  return {
    mainText: model?.mainText || "",
    chatTemplate: CustomChatTemplate(model?.chatTemplate),
    subjectLine: model?.subjectLine || "",
    emailSubject: CustomChatTemplateEmailSubject(model?.emailSubject)
  };
}

export function CustomChatTemplateEmailSubject(model = {}) {
  return {
    id: model?.id,
    type: model?.type
  };
}

export function CustomChatTemplateEmailToRequest(model = {}) {
  return {
    main_text: model.mainText,
    subject_line: model.subjectLine,
    model_id: model.emailSubject.id
  };
}
