import { getHttpClient } from "@/http-client";

const baseURL = "api/v1/sources";
export async function getAllSources() {
  const { data } = await getHttpClient().get(baseURL);
  let memberships = [],
    crms = [],
    raters = [];
  if (!data) return { memberships, crms, raters };

  const allSources = [...data];
  allSources.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  allSources.forEach(d => {
    switch (d.category) {
      case "Rater":
        raters.push(d);
        break;
      case "CRM":
        crms.push(d);
        break;
      default:
        memberships.push(d);
        break;
    }
  });

  return { memberships, crms, raters };
}
