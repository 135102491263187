<template>
  <v-card flat color="section">
    <v-card-title>BackNine Webhooks</v-card-title>
    <v-card-text>
      Subscribing to our webhooks allows you to integrate Case and Quote & Apply
      application data with your systems. We'll send a POST request to your
      desired endpoint everytime a Case or Quote & Apply application is saved.
      View example response data and more within our
      <a href="https://docs.back9ins.com/#webhooks" target="_blank">
        API docs.
      </a>
    </v-card-text>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <h3 class="font-weight-normal opacity-60">eApp (Quote & Apply)</h3>
          <v-card-text class="pl-0 pt-0">
            Enter your server's endpoint to receive updates on your Quote &
            Apply eApps.
          </v-card-text>
          <div class="clickable webhook-item" @click="editEappWebhook">
            <text-field
              class="block-pointer-events"
              data-lpignore="true"
              :prepend-inner-icon="mdiFileDocument"
              prefix="https://"
              label="Desired Endpoint"
              readonly
              hide-details
              data-testid="eapp-webhook-url"
              :loading="loadingWebhooks"
              :model-value="eappWebhook.url"
              :success="Boolean(eappWebhook.url)"
            />
          </div>
          <v-fade-transition mode="out-in">
            <developer-webhook-auth-key-toggle
              v-if="eappWebhook?.authenticationKey"
              data-testid="eapp-webhook-auth-key-toggle"
              :webhook="eappWebhook"
            />
          </v-fade-transition>
        </v-col>
        <v-col cols="12">
          <h3 class="font-weight-normal mt-6 opacity-60">Case</h3>
          <v-card-text class="pl-0 pt-0">
            Enter your server's endpoint to receive updates on your Cases. A
            Case is a fully signed eApp.
          </v-card-text>
          <div class="clickable webhook-item" @click="editCaseWebhook">
            <text-field
              class="block-pointer-events"
              data-lpignore="true"
              :prepend-inner-icon="mdiBriefcase"
              prefix="https://"
              label="Desired Endpoint"
              readonly
              hide-details
              data-testid="case-webhook-url"
              :loading="loadingWebhooks"
              :model-value="caseWebhook.url"
              :success="Boolean(caseWebhook.url)"
            />
          </div>
          <v-fade-transition mode="out-in">
            <developer-webhook-auth-key-toggle
              v-if="caseWebhook?.authenticationKey"
              data-testid="case-webhook-auth-key-toggle"
              :webhook="caseWebhook"
            />
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import DeveloperWebhookAuthKeyToggle from "@/components/settings/developer/DeveloperWebhookAuthKeyToggle.vue";

import { CaseWebhook, EAppWebhook } from "@/factories/Webhook";

import { getAgentWebhooks } from "@/api/agents.service";
import { getAgencyWebhooks } from "@/api/agencies.service";

import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import { useSettingsViewStore } from "@/stores/settings-view";

import { markRaw, ref } from "vue";
import { parseErrorMessage } from "@/util/helpers";

import DeveloperSettingsWebhookEditor from "@/components/settings/developer/DeveloperSettingsWebhookEditor.vue";
import { mdiFileDocument, mdiBriefcase } from "@mdi/js";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const props = defineProps({
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const eappWebhook = ref(EAppWebhook());
const caseWebhook = ref(CaseWebhook());

const loadingWebhooks = ref(false);

async function getWebhooks() {
  loadingWebhooks.value = true;
  let func = getAgentWebhooks;
  if (props.module === AGENCY_SETTINGS) func = getAgencyWebhooks;
  try {
    const {
      [eappWebhook.value.key]: reqEappWebhook,
      [caseWebhook.value.key]: reqCaseWebhook
    } = await func(store.id);
    eappWebhook.value = reqEappWebhook;
    caseWebhook.value = reqCaseWebhook;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingWebhooks.value = false;
  }
}

async function editEappWebhook() {
  const res = await dialog.showDialog({
    component: markRaw(DeveloperSettingsWebhookEditor),
    webhook: eappWebhook.value,
    module: props.module
  });

  if (res?.updated) getWebhooks();
}

async function editCaseWebhook() {
  const res = await dialog.showDialog({
    component: markRaw(DeveloperSettingsWebhookEditor),
    webhook: caseWebhook.value,
    module: props.module
  });

  if (res?.updated) getWebhooks();
}

getWebhooks();
</script>

<style lang="scss">
.webhook-item {
  max-width: 40rem;
  cursor: pointer !important;
  border-radius: 4px;
}

.block-pointer-events {
  pointer-events: none;
}
</style>
