const integerOrNull = v => {
  if (Number.isInteger(+v)) return +v;
  return null;
};

export function Connection(model = {}) {
  return {
    appointments: Boolean(model?.appointments),
    appointmentsEmails: Boolean(model?.appointmentsEmails),
    cases: Boolean(model?.cases),
    casesEmails: Boolean(model?.casesEmails),
    contractParties: Boolean(model?.contractParties),
    contractPartiesEmails: Boolean(model?.contractPartiesEmails),
    customDomains: Boolean(model?.customDomains),
    disabled: Boolean(model?.disabled),
    electronicApplications: Boolean(model?.electronicApplications),
    electronicApplicationsEmails: Boolean(model?.electronicApplicationsEmails),
    hidden: Boolean(model?.hidden),
    hierarchy: Boolean(model?.hierarchy),
    hierarchyOrder: integerOrNull(model?.hierarchyOrder),
    id: +model?.id || 0,
    limitEmailRecipients: Boolean(model?.limitEmailRecipients),
    connectedAdvisorName: model?.connectedAdvisorName,
    connectedAdvisorId: model?.connectedAdvisorId,
    connectedAdvisorType: model?.connectedAdvisorType,
    payments: Boolean(model?.payments),
    paymentsEmails: Boolean(model?.paymentsEmails),
    quickQuotes: Boolean(model?.quickQuotes),
    quickQuotesEmails: Boolean(model?.quickQuotesEmails),
    quotes: Boolean(model?.quotes),
    quotesEmails: Boolean(model?.quotesEmails),
    signer: Boolean(model?.signer),
    routerLink: model?.routerLink
  };
}

export function setConnectionFromRaw(raw = {}) {
  const connection = Connection();

  connection.appointments = Boolean(raw?.appointments);
  connection.appointmentsEmails = Boolean(raw?.appointments_emails);
  connection.cases = Boolean(raw?.cases);
  connection.casesEmails = Boolean(raw?.cases_emails);
  connection.contractParties = Boolean(raw?.contract_parties);
  connection.contractPartiesEmails = Boolean(raw?.contract_parties_emails);
  connection.customDomains = Boolean(raw?.custom_domains);
  connection.disabled = Boolean(raw?.disabled);
  connection.electronicApplications = Boolean(raw?.electronic_applications);
  connection.electronicApplicationsEmails = Boolean(
    raw?.electronic_applications_emails
  );
  connection.hidden = Boolean(raw?.hidden);
  connection.hierarchy = +raw?.hierarchy || 0;
  connection.hierarchyOrder = integerOrNull(raw?.hierarchy_order);
  connection.id = +raw?.id || 0;
  connection.connectedAdvisorName = raw?.parent?.name;
  connection.connectedAdvisorId = raw?.parent?.id;
  connection.connectedAdvisorType = raw?.parent?.type?.model;
  connection.payments = Boolean(raw?.payments);
  connection.paymentsEmails = Boolean(raw?.payments_emails);
  connection.quickQuotes = Boolean(raw?.quick_quotes);
  connection.quickQuotesEmails = Boolean(raw?.quick_quotes_emails);
  connection.quotes = Boolean(raw?.quotes);
  connection.quotesEmails = Boolean(raw?.quotes_emails);
  connection.signer = +raw?.signor || 0;
  connection.limitEmailRecipients = Boolean(raw?.limit_email_recipients);

  if (connection.disabled) return connection;

  if (connection.connectedAdvisorType === "Agent") {
    connection.routerLink = {
      name: "AgentView",
      params: {
        id: connection.connectedAdvisorId
      }
    };
  } else if (connection.connectedAdvisorType === "Agency") {
    connection.routerLink = {
      name: "AgencyView",
      params: {
        id: connection.connectedAdvisorId
      }
    };
  }

  return connection;
}

export function setConnectionFromRawCreate(raw = {}) {
  const connection = Connection();
  connection.id = +raw.id || 0;
  connection.hidden = Boolean(raw.hidden);
  connection.hierarchy = +raw.hierarchy || 0;
  connection.hierarchyOrder = integerOrNull(raw.hierarchy_order);
  connection.connectedAdvisorName = raw.parent.name;
  connection.connectedAdvisorId = raw.parent.id;
  connection.connectedAdvisorType = raw.parent.type.model;
  connection.disabled = Boolean(raw.disabled);

  const opts = raw.options;
  connection.appointments = Boolean(opts.appointments);
  connection.appointmentsEmails = Boolean(opts.appointments_emails);
  connection.cases = Boolean(opts.cases);
  connection.casesEmails = Boolean(opts.cases_emails);
  connection.contractParties = Boolean(opts.contract_parties);
  connection.contractPartiesEmails = Boolean(opts.contract_parties_emails);
  connection.customDomains = Boolean(opts.custom_domains);
  connection.electronicApplications = Boolean(opts.electronic_applications);
  connection.electronicApplicationsEmails = Boolean(
    opts.electronic_applications_emails
  );
  connection.payments = Boolean(opts.payments);
  connection.paymentsEmails = Boolean(opts.payments_emails);
  connection.quickQuotes = Boolean(opts.quick_quotes);
  connection.quickQuotesEmails = Boolean(opts.quick_quotes_emails);
  connection.quotes = Boolean(opts.quotes);
  connection.quotesEmails = Boolean(opts.quotes_emails);
  connection.signer = +opts.signer || 0;
  connection.limitEmailRecipients = Boolean(raw?.limit_email_recipients);

  if (connection.disabled) return connection;

  if (connection.connectedAdvisorType === "Agent") {
    connection.routerLink = {
      name: "AgentView",
      params: {
        id: connection.connectedAdvisorId
      }
    };
  } else if (connection.connectedAdvisorType === "Agency") {
    connection.routerLink = {
      name: "AgencyView",
      params: {
        id: connection.connectedAdvisorId
      }
    };
  }

  return connection;
}

export function setRawCreateFromConnection(connection) {
  return {
    appointments: Boolean(connection.appointments),
    appointments_emails: Boolean(connection.appointmentsEmails),
    cases: Boolean(connection.cases),
    cases_emails: Boolean(connection.casesEmails),
    contract_parties: Boolean(connection.contractParties),
    contract_parties_emails: Boolean(connection.contractPartiesEmails),
    custom_domains: Boolean(connection.customDomains),
    electronic_applications: Boolean(connection.electronicApplications),
    electronic_applications_emails: Boolean(
      connection.electronicApplicationsEmails
    ),
    hidden: Boolean(connection.hidden),
    parent_id: +connection.connectedAdvisorId,
    parent_type: connection.connectedAdvisorType,
    payments: Boolean(connection.payments),
    payments_emails: Boolean(connection.paymentsEmails),
    quick_quotes: Boolean(connection.quickQuotes),
    quick_quotes_emails: Boolean(connection.quickQuotesEmails),
    quotes: Boolean(connection.quotes),
    quotes_emails: Boolean(connection.quotesEmails),
    signor: +connection.signer || 0,
    limit_email_recipients: Boolean(connection?.limitEmailRecipients)
  };
}

export function setRawUpdateFromConnection(connection) {
  return {
    appointments: Boolean(connection.appointments),
    appointments_emails: Boolean(connection.appointmentsEmails),
    cases: Boolean(connection.cases),
    cases_emails: Boolean(connection.casesEmails),
    contract_parties: Boolean(connection.contractParties),
    contract_parties_emails: Boolean(connection.contractPartiesEmails),
    custom_domains: Boolean(connection.customDomains),
    electronic_applications: Boolean(connection.electronicApplications),
    electronic_applications_emails: Boolean(
      connection.electronicApplicationsEmails
    ),
    hidden: Boolean(connection.hidden),
    payments: Boolean(connection.payments),
    payments_emails: Boolean(connection.paymentsEmails),
    quick_quotes: Boolean(connection.quickQuotes),
    quick_quotes_emails: Boolean(connection.quickQuotesEmails),
    quotes: Boolean(connection.quotes),
    quotes_emails: Boolean(connection.quotesEmails),
    signor: +connection.signer || 0,
    limit_email_recipients: Boolean(connection?.limitEmailRecipients)
  };
}
