<template>
  <v-alert
    v-if="notice.message"
    color="warning"
    class="rounded-0"
    data-testid="system-alert"
  >
    {{ notice.message.trim() }}
    <template v-if="notice.link" #append>
      <app-button
        size="x-large"
        class="text-none"
        data-testid="system-alert-link"
        :to="notice.link"
      >
        {{ notice.linkText }}
      </app-button>
    </template>
  </v-alert>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { useRouter } from "vue-router";

function SystemAlert({ message, link, linkText } = {}) {
  return {
    message: message || null,
    link: link || null,
    linkText: linkText || null
  };
}

const router = useRouter();
const user = useUserStore();
const { system_message, loginable } = storeToRefs(user);

const routeHidesContractingBanner = computed(() => {
  return [
    "AdvisorEdit",
    "AgentEdit",
    "AgencyEdit",
    "LoggedInUserSettings"
  ].includes(router?.currentRoute?.name);
});

const userHidesContractingBanner = computed(() => {
  return (
    !loginable.value.show_backnine ||
    loginable.value.bypass_contracting ||
    !loginable.value.npn ||
    loginable.value.type === "Agency"
  );
});

const contractingNotice = computed(() => {
  const notice = SystemAlert();
  if (userHidesContractingBanner.value || routeHidesContractingBanner.value)
    return notice;

  if (!loginable.value.signature_authorized) {
    notice.message =
      "Please complete your contracting to remove account limitations.";
    notice.link = { name: "LoggedInUserSettings" };
    notice.linkText = "Go to Settings";
  } else if (!loginable.value.has_eo) {
    notice.message =
      "Your E&O is expired and needs to be updated to remove account limitations.";
    notice.link = { name: "LoggedInUserSettings" };
    notice.linkText = "Go to Settings";
  }

  return notice;
});

const systemMessageNotice = computed(() => {
  const notice = SystemAlert();
  if (!system_message.value) return notice;
  notice.message = system_message.value;
  return notice;
});

const notice = computed(() => {
  if (systemMessageNotice.value.message) return systemMessageNotice.value;
  if (contractingNotice.value.message) return contractingNotice.value;
  return SystemAlert();
});
</script>
