<template>
  <v-row class="ma-0 pa-1" justify="center">
    <v-card max-width="500" width="100%">
      <v-card-title>
        <v-icon class="mr-1" :icon="mdiCellphone" /> Incoming Call
      </v-card-title>
      <v-card-text>
        <integer-input
          v-model.lazy="phoneNumber"
          :prepend-inner-icon="mdiPhone"
          label="Phone Number"
          mask="(###) ###-####x######"
          hide-details
          data-testid="phone-number"
        />

        <v-col cols="12" class="px-0">
          <h3 style="font-weight: 400">Include the following:</h3>
          <v-chip-group
            v-model="include"
            column
            multiple
            selected-class="bg-primary"
          >
            <v-chip
              v-for="{ title, value } in CATEGORIES"
              :key="title"
              :data-testid="`include-${title}`"
              :value="value"
              :text="title"
            />
          </v-chip-group>
        </v-col>

        <v-fade-transition mode="out-in">
          <v-row v-if="loading" key="loader" class="ma-0">
            <h3 style="font-weight: 400">Loading Results</h3>
            <v-progress-circular
              indeterminate
              width="2"
              size="24"
              class="ml-2"
            />
          </v-row>
          <v-col v-else key="results" class="pa-0">
            <h3 style="font-weight: 400">Results:</h3>
            <v-list>
              <v-list-item
                v-for="result in results"
                :key="result.uniqueId"
                :data-testid="`result-${result.uniqueId}`"
                :to="result.routerLink"
                target="_blank"
                :title="result.name"
                @click="confirmTrackCall(result)"
              >
                <v-list-item-subtitle>
                  {{ result.displayType }}
                  ·
                  {{ result.phones }}
                </v-list-item-subtitle>
                <template #append>
                  <v-tooltip location="top">
                    <template #activator="{ props: templateProps }">
                      <app-button
                        v-bind="templateProps"
                        variant="text"
                        :icon="mdiOpenInNew"
                        :data-testid="`result-${result.uniqueId}-open`"
                      />
                    </template>
                    <span>View </span>
                  </v-tooltip>
                </template>
              </v-list-item>
              <v-list-item v-if="!results.length" title="No Results" />
            </v-list>
          </v-col>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import {
  CALL_DIRECTIONS,
  inboundCallSearch,
  trackCall
} from "@/api/calls.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, watch } from "vue";
import { mdiCellphone, mdiPhone, mdiOpenInNew } from "@mdi/js";
const props = defineProps({
  phone: { type: String, required: true }
});

const CATEGORIES = [
  { title: "Agents", value: "agents" },
  { title: "Contract Parties", value: "contract_parties" },
  {
    title: "eApps",
    value: "individuals"
  }
];
const phoneNumber = ref(props.phone);
const results = ref([]);
const loading = ref(false);

const include = ref(Object.values(CATEGORIES).map(({ value }) => value));
const tracked = ref([]);

async function getRelatedData() {
  try {
    loading.value = true;
    const relatedData = await inboundCallSearch(
      phoneNumber.value.replace(/\D+/g, ""),
      include.value
    );
    results.value.splice(0, results.value.length);
    results.value.push(...relatedData);
  } catch (e) {
    useSnackbarStore().showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

let timer;
function debounceAndGetRelatedData() {
  loading.value = true;
  if (timer) clearTimeout(timer);
  timer = setTimeout(getRelatedData, 300);
}

watch(phoneNumber, debounceAndGetRelatedData, { immediate: true });
watch(include, debounceAndGetRelatedData);

async function confirmTrackCall(result) {
  if (tracked.value.includes(result.uniqueId)) return;
  try {
    trackCall({
      type: result.callableType,
      id: result.callableId,
      callerNumber: phoneNumber.value,
      direction: CALL_DIRECTIONS.INBOUND
    });
    tracked.value.push(result.uniqueId);
  } catch (e) {
    //do nothing
  }
}
</script>
