<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { currencyFormat } from "@/util/helpers";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  decimalLength: {
    type: Number,
    required: false,
    default: 2
  }
});

const formattedValue = computed(() => {
  return currencyFormat(props.modelValue, props.decimalLength);
});
</script>
