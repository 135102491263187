<template>
  <v-col class="">
    <slot name="extra-actions" />
    <textarea-field
      v-cloak
      v-model="note.message"
      :prepend-inner-icon="mdiText"
      data-testid="task-input"
      auto-grow
      :disabled="loading"
      :success="inputValidation.success"
      :error-messages="inputValidation.errorMessages"
      :hide-details="!inputValidation.errorMessages.length"
      :placeholder="messagePlaceholder"
      @drop.prevent.stop="addDropFile"
      @dragover.prevent.stop
    >
      <template #append-inner>
        <v-tooltip location="top">
          <template #activator="{ props: tooltipProps }">
            <app-button
              v-bind="tooltipProps"
              :icon="mdiPaperclip"
              style="font-size: 1.2rem"
              variant="text"
              density="comfortable"
              @click="openFileInput"
            />
          </template>
          <span>Upload File</span>
        </v-tooltip>
        <v-file-input
          ref="fileInput"
          hide-input
          hide-details
          multiple
          data-testid="file-input"
          style="display: none"
          :accept="ALL_FILE_EXTENSIONS"
          :disabled="loading"
          @update:model-value="addDropFile"
        />
      </template>
    </textarea-field>
    <v-row class="ma-0" align="center">
      <v-chip-group column>
        <v-chip
          v-for="(file, index) in note.files"
          :key="index"
          pill
          closable
          :text="file.name"
          :close-icon="mdiClose"
          :disabled="loading"
          @click:close="removeFile(index)"
        />
      </v-chip-group>
    </v-row>
    <v-row class="ma-0">
      <v-spacer />
      <template v-if="canChangeStatus">
        <app-button
          v-if="isComplete"
          class="text-none"
          :disabled="loading && loadingStatus !== 'status-change'"
          :loading="loadingStatus === 'status-change'"
          @click="statusSubmit(false)"
        >
          Mark as Incomplete
        </app-button>
        <v-fade-transition v-else mode="out-in">
          <app-button
            v-if="note.message || note.files.length"
            key="comment-and-mark-as-complete"
            class="text-none"
            :disabled="loading && loadingStatus !== 'status-change'"
            :loading="loadingStatus === 'status-change'"
            @click="submitMessage(true)"
          >
            <v-icon class="mr-1" :icon="mdiCheckCircle" />
            Comment and Mark as Complete
          </app-button>
          <app-button
            v-else
            class="text-none"
            :disabled="loading && loadingStatus !== 'status-change'"
            :loading="loadingStatus === 'status-change'"
            @click="statusSubmit(true)"
          >
            <v-icon class="mr-1" :icon="mdiCheckCircle" />
            Mark as Complete
          </app-button>
        </v-fade-transition>
      </template>
      <app-button
        color="primary"
        class="text-none ml-1"
        data-testid="action-comment"
        :disabled="loading && loadingStatus !== 'submit'"
        :loading="loadingStatus === 'submit'"
        @click="submitMessage(null)"
      >
        Comment
      </app-button>
    </v-row>
  </v-col>
</template>

<script setup>
import {
  ALL_FILE_EXTENSIONS,
  EXTENSION_DICTIONARY
} from "@/data/allowed-file-extensions";
import { computedValidation, someTextValidator } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import useVuelidate from "@vuelidate/core";
import { computed, ref, toRefs } from "vue";
import { mdiText, mdiClose, mdiCheckCircle, mdiPaperclip } from "@mdi/js";

const emit = defineEmits(["submit"]);
const props = defineProps({
  canChangeStatus: Boolean,
  isComplete: Boolean,
  loadingStatus: {
    type: String,
    required: false,
    default: null
  }
});

const { loadingStatus, canChangeStatus } = toRefs(props);

const fileInput = ref(); //templateref
const note = ref({
  message: "",
  files: []
});

const user = useUserStore();
const v$ = useVuelidate(
  {
    note: {
      message: {
        required: (value, vm) => {
          if (vm.files.length > 0) return true;
          return someTextValidator(true, value, 1);
        }
      },
      files: {
        validSize: value => value.every(v => v.size > 0)
      }
    }
  },
  {
    note
  },
  {
    $autoDirty: true,
    $scope: null
  }
);

const loading = computed(() => Boolean(loadingStatus.value));

const messagePlaceholder = computed(() => {
  if (note.value.files.length) return filesOnlyPlaceholderMessage.value;
  return "Write a comment";
});

const filesOnlyPlaceholderMessage = computed(() => {
  const attachmentCount = note.value.files.length;
  const attachmentText = attachmentCount === 1 ? "attachment" : "attachments";
  return `${user.loginable.name} uploaded ${attachmentCount} ${attachmentText}.`;
});

const messageValidation = computedValidation(v$.value.note.message, {
  required: "Required"
});

const filesValidation = computedValidation(v$.value.note.files, {
  validSize:
    "Please confirm all files have data or try re-uploading your files."
});

const inputValidation = computed(() => {
  return {
    success:
      messageValidation.value.success &&
      filesValidation.value.success &&
      Boolean(note.value.message || note.value.files.length),
    errorMessages: [
      ...messageValidation.value.errorMessages,
      ...filesValidation.value.errorMessages
    ]
  };
});

function statusSubmit(complete) {
  emit("submit", { complete, files: [], message: null });
}

function removeFile(index) {
  note.value.files.splice(index, 1);
}

async function submitMessage(complete = null) {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  emit("submit", {
    complete,
    files: note.value.files,
    message: note.value.message || filesOnlyPlaceholderMessage.value,
    callback: resetDefaults
  });
}

function resetDefaults() {
  note.value.message = "";
  note.value.files.splice(0, note.value.files.length);
  v$.value.$reset();
}

function addDropFile(e) {
  const files = [];
  if (Array.isArray(e)) files.push(...e);
  else files.push(...Array.from(e.dataTransfer.files));

  const filteredFiles = files.filter(v => {
    const chunks = v.name.split(".");
    return EXTENSION_DICTIONARY[chunks[chunks.length - 1].toLowerCase()];
  });

  const currentFiles = [...note.value.files];
  note.value.files.splice(0, note.value.files.length);
  note.value.files.push(...currentFiles, ...filteredFiles);
}

function openFileInput() {
  fileInput.value.$el.querySelector("[role=button]").click();
}
</script>
