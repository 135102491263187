import { getHttpClient } from "@/http-client";

const baseURL = "/api/boss/invites";

export const inviteAssistant = async ({
  email,
  subscribe_to_marketing_emails,
  first_name,
  last_name,
  permissions: {
    appointments,
    appointments_emails,
    cases,
    cases_emails,
    contract_parties,
    contract_parties_emails,
    electronic_applications,
    electronic_applications_emails,
    payments,
    payments_emails,
    quick_quotes,
    quick_quotes_emails,
    quotes,
    quotes_emails
  },
  access_to,
  ownable_id = undefined,
  ownable_type = undefined
}) => {
  const body = {
    type: "assistant",
    email,
    subscribe_to_marketing_emails,
    first_name,
    last_name,
    permissions: {
      appointments,
      appointments_emails,
      cases,
      cases_emails,
      contract_parties,
      contract_parties_emails,
      electronic_applications,
      electronic_applications_emails,
      payments,
      payments_emails,
      quick_quotes,
      quick_quotes_emails,
      quotes,
      quotes_emails
    },
    access_to: access_to.map(({ id, type }) => ({ id, type }))
  };

  if (ownable_id && ownable_type) {
    body.ownable_id = ownable_id;
    body.ownable_type = ownable_type;
  }
  const { data } = await getHttpClient().post(baseURL, body);
  return data.agent.id;
};

export const inviteDownline = async ({
  email,
  subscribe_to_marketing_emails,
  lock_commissions,
  assign_commissions,
  npn,
  first_name,
  last_name,
  marketing_manager_id = undefined,
  ownable_id = undefined,
  ownable_type = undefined
}) => {
  const body = {
    type: "downline",
    email,
    subscribe_to_marketing_emails,
    lock_commissions,
    assign_commissions
  };

  if (ownable_id && ownable_type) {
    body.ownable_id = ownable_id;
    body.ownable_type = ownable_type;
  }
  if (first_name && last_name) {
    body.first_name = first_name;
    body.last_name = last_name;
  }
  if (npn) {
    body.npn = npn;
  }

  if (marketing_manager_id) {
    body.marketing_manager_id = marketing_manager_id;
  }

  const { data } = await getHttpClient().post(baseURL, body);
  return data.agent.id;
};

export const inviteAgency = async ({
  email,
  signer_id,
  user_id,
  subscribe_to_marketing_emails,
  marketing_manager_id,
  name = undefined,
  npn = undefined
}) => {
  const body = {
    type: "agency",
    email,
    subscribe_to_marketing_emails,
    signer: {
      id: signer_id
    },
    user: {
      id: user_id
    },
    marketing_manager_id,
    name: undefined,
    npn: undefined
  };

  if (name) body.name = name;
  else body.npn = npn;

  const { data } = await getHttpClient().post(baseURL, body);
  return data.agency.id;
};
