export function RecentAdvisor(model = {}) {
  return {
    id: model?.id || 0,
    lastSubmitted: model?.lastSubmitted || "",
    name: model?.name || "",
    pending: model?.pending || "",
    model: model?.model || "",
    link: model?.link || "",
    yearToDate: model?.yearToDate || 0
  };
}

export function setRecentAdvisorFromRequest(raw = {}) {
  const model = RecentAdvisor();
  model.id = raw?.id;
  model.lastSubmitted = raw?.last_submitted;
  model.name = raw?.name;
  model.pending = raw?.pending;
  model.model = raw?.type?.model;
  model.link = raw?.type?.link;
  model.yearToDate = raw?.year_to_date;
  return model;
}
