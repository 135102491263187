<template>
  <chat-view
    title="Case Chat"
    :checkboxes="checkboxes"
    :additional-buttons="additionalButtons"
    :messages="notes"
    :pusher-id="`private-${caseId}-Case`"
    :room="room"
    :create-func="sendChatMessage"
    @new-note="newNote"
    @remove-message="removeFromMessages"
    @select-template="selectTemplate"
  />
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";
import ChatTemplateDialog from "@/dialogs/ChatTemplateDialog.vue";

import {
  setNoteFromRequest,
  Note,
  NoteToCreateRequest
} from "@/factories/Note";
import { markRaw } from "vue";

import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useCaseViewStore } from "@/stores/case-view";
import { useSnackbarStore } from "@/stores/snackbar";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const user = useUserStore();
const {
  notes,
  caseManager,
  caseConcierge,
  id: caseId,
  room
} = storeToRefs(useCaseViewStore());

const checkboxes = [
  { label: "Send Email", dataTestid: "email", key: "sendEmail", default: true }
];

const additionalButtons = [
  {
    key: "useTemplate",
    method: selectTemplate,
    text: "Use Template",
    dataTestid: "use-template"
  }
];
function initNotes() {
  notes.value.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  if (caseManager.value) {
    const introText = `Hi ${user.loginable.name}, I'll be your case manager. Feel free to chat with me here`;
    const emailText = () =>
      `e-mail me at <a href="mailto:${caseManager.value.userEmail}">${caseManager.value.userEmail}</a>`;
    const phoneText = () =>
      `call your case concierge ${caseConcierge.value.name} at <a href="tel:${caseConcierge.value.phone}">${caseConcierge.value.phone}</a>`;
    const closingText = `You can also chat with me on each requirement here.`;
    let finalText = "";
    if (caseManager.value.email && !caseManager.value.phone_work) {
      finalText = `${introText} or ${emailText()}. ${closingText}`;
    } else if (!caseManager.value.email && caseConcierge.value?.phone) {
      finalText = `${introText} or ${phoneText()}. ${closingText}`;
    } else if (caseManager.value.email && caseConcierge.value?.phone) {
      finalText = `${introText}, ${emailText()}, or ${phoneText()}. ${closingText}`;
    } else {
      finalText = `${introText}. ${closingText}`;
    }

    const defaultMessage = {
      html: finalText,
      ownable: caseManager.value,
      key: Symbol("intro")
    };

    notes.value.unshift(defaultMessage);
  }
}
function removeFromMessages(message) {
  const index = notes.value.findIndex(v => v.id === message.id);
  if (index === -1) return;
  notes.value.splice(index, 1);
}

function selectTemplate() {
  dialog.showDialog({
    component: markRaw(ChatTemplateDialog),
    identifier: caseId.value,
    scrollable: true,
    type: "Case",
    createFunc: sendChatMessage
  });
}

async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: note.sendEmail,
    phoneCall: false,
    note: note.message,
    isTemplate: user.isGroupTwoPlus ? note.isTemplate : false,
    notableId: caseId.value,
    notableType: "Case"
  });
  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}
function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}

if (!notes.value.some(n => Symbol.keyFor(Symbol.for(n.key)) === "intro")) {
  initNotes();
}
</script>
