export const EXAM_VENDOR = "exam";
export const PHYSICIAN_VENDOR = "physician_statement";

export const VENDOR_DISPLAY = {
  [EXAM_VENDOR]: "Exam",
  [PHYSICIAN_VENDOR]: "Physician Statement"
};

export function Vendor(model = {}) {
  return {
    available: model?.available || false,
    avatarUrl: model?.avatarUrl || "",
    default: model?.default || false,
    email: model?.email || null,
    id: model?.id || null,
    integration: model?.integration || null,
    name: model?.name || "",
    requireDoctorInfo: model?.requireDoctorInfo || false,
    requireOrderNumber: model?.requireOrderNumber || false,
    type: model?.type || ""
  };
}

export function setVendorFromRequest(req = {}) {
  const vendor = Vendor();

  vendor.available = req?.available;
  vendor.avatarUrl = req?.avatar_url;
  vendor.default = req?.default;
  vendor.email = req?.email;
  vendor.id = req?.id;
  vendor.integration = req?.integration; // integration is the symbol for the vendor
  vendor.name = req?.name;
  vendor.requireDoctorInfo = req?.require_doctor_info;
  vendor.requireOrderNumber = req?.require_order_number;
  vendor.type = req?.type;

  return vendor;
}

export function setRequestFromVendor(vendor = {}) {
  return {
    name: vendor.name,
    email: vendor.email,
    default: vendor.default,
    available: vendor.available,
    require_doctor_info: vendor.requireDoctorInfo,
    require_order_number: vendor.requireOrderNumber
  };
}
