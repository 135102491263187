<template>
  <v-card class="ma-1">
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        item-value="key"
        mobile-breakpoint="1090"
        data-testid="queued-transactions-table"
        :mobile="null"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :footer-props="table.footerProps.value"
        :loading="table.loading.value"
        :items-per-page-options="table.itemsPerPageOptions"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0">
            <h4 class="text-h5">Pending Transactions</h4>
            <v-spacer />
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  variant="text"
                  density="comfortable"
                  class="table-btn"
                  data-testid="queued-transactions-table-refresh"
                  v-bind="templateProps"
                  @click="getData"
                />
              </template>
              <span>Refresh </span>
            </v-tooltip>
            <v-col cols="12" class="pa-0">
              <table-filter
                :model-value="table.filter.value"
                :headers="table.filterHeaders.value"
                :loading="table.loading.value"
                @update="updateFilter"
              />
            </v-col>
          </v-row>
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.statementId`]="{ item }">
          <v-tooltip v-if="item.additional.statement?.id" location="top">
            <template #activator="{ props: templateProps }">
              <div v-bind="templateProps" style="white-space: nowrap">
                <app-button
                  variant="outlined"
                  color="primary"
                  class="text-none button-group left-button"
                  data-testid="statement"
                  :prepend-icon="mdiCalendar"
                  :to="{
                    name: 'StatementView',
                    params: {
                      id: item.additional.statement.id
                    },
                    query: {
                      page: 'pending-transactions'
                    }
                  }"
                >
                  <span class="truncate-200">
                    #{{ item.additional.statement.id }} -
                    {{ item.additional.statement.payor.name }}
                  </span>
                </app-button>
                <app-button
                  variant="outlined"
                  color="primary"
                  :to="{
                    name: 'StatementView',
                    params: {
                      id: item.additional.statement.id
                    },
                    query: {
                      page: 'pending-transactions'
                    }
                  }"
                  :icon="mdiOpenInNew"
                  data-testid="statement-open-in-new"
                  target="_blank"
                  class="button-group right-button"
                  density="comfortable"
                />
              </div>
            </template>
            <span>
              View #{{ item.additional.statement.id }} -
              {{ item.additional.statement.payor.name }}
            </span>
          </v-tooltip>
        </template>
        <template #[`item.transactionDetails`]="{ item }">
          <v-col class="pa-1">
            <div>{{ item.additional.commissionType }}</div>
            <div>
              Premium:
              <currency-formatter :model-value="item.additional.premium" />
            </div>
            <div>
              Override:
              <currency-formatter :model-value="item.additional.amount" />
            </div>
          </v-col>
        </template>
        <template #[`item.policyNumber`]="{ item }">
          <v-tooltip v-if="item.additional.case?.id" location="top">
            <template #activator="{ props: templateProps }">
              <div v-bind="templateProps" style="white-space: nowrap">
                <app-button
                  color="accent"
                  variant="outlined"
                  class="button-group left-button"
                  data-testid="case"
                  :prepend-icon="mdiBriefcase"
                  :to="getRouterLink('Case', item.additional.case.id)"
                >
                  {{ item.policyNumber }}
                </app-button>
                <app-button
                  variant="outlined"
                  color="accent"
                  target="_blank"
                  density="comfortable"
                  class="button-group right-button"
                  :icon="mdiOpenInNew"
                  data-testid="case-open-in-new"
                  :to="getRouterLink('Case', item.additional.case.id)"
                />
              </div>
            </template>
            <span>View Case</span>
          </v-tooltip>
          <v-tooltip v-else location="top">
            <template #activator="{ props: templateProps }">
              <app-button
                label
                class="text-none"
                variant="outlined"
                v-bind="templateProps"
                :prepend-icon="mdiHelpCircleOutline"
                :color="item.additional.policyNumber ? 'warning' : 'error'"
                data-testid="edit-queued-transaction"
                @click="editQueuedTransaction(item.additional)"
              >
                {{ item.additional.policyNumber || "No Policy #" }}
              </app-button>
            </template>
            <span>{{
              item.additional.policyNumber
                ? "Unable to locate case, click to edit"
                : "No Policy #"
            }}</span>
          </v-tooltip>
        </template>
        <template
          #[`item.data-table-expand`]="{
            item,
            toggleExpand,
            internalItem,
            isExpanded
          }"
        >
          <v-row class="ma-0 flex-nowrap" dense>
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiPencil"
                  color="accent"
                  variant="text"
                  v-bind="templateProps"
                  density="comfortable"
                  class="mr-1"
                  data-testid="edit-queued-transaction"
                  @click="editQueuedTransaction(item.additional)"
                />
              </template>
              <span>Edit Transaction </span>
            </v-tooltip>
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="item.additional.isIgnored ? mdiEye : mdiEyeOff"
                  variant="text"
                  v-bind="templateProps"
                  density="comfortable"
                  class="mr-1"
                  data-testid="ignore-queued-transaction"
                  @click="toggleIgnore(item.additional)"
                />
              </template>
              <span v-if="item.additional.isIgnored">
                Don't Ignore Transaction
              </span>
              <span v-else>Ignore Transaction </span>
            </v-tooltip>
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="
                    isExpanded(internalItem) ? mdiChevronUp : mdiChevronDown
                  "
                  variant="text"
                  v-bind="templateProps"
                  density="comfortable"
                  color="primary"
                  data-testid="show-raw-data"
                  @click="toggleExpand(internalItem)"
                />
              </template>
              <span>Show Raw Data </span>
            </v-tooltip>
          </v-row>
        </template>
        <template #[`item.errorMessage`]="{ item }">
          {{ item.errorMessage }}
        </template>
        <template #expanded-row="{ item, columns }">
          <td :colspan="columns.length">
            <v-row>
              <v-col
                v-for="(value, key) in item.additional.rawData"
                :key="key"
                data-testid="raw-value"
                cols="4"
                md="3"
                lg="2"
              >
                <span class="text-grey lighten-2"> {{ key }} </span>
                <br />
                <span> {{ value }} </span>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import TransactionCreate from "@/components/transactions/TransactionCreate.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import {
  getQueuedTransactions,
  toggleIgnoreQueuedTransaction
} from "@/api/queued-transactions.service";


import { parseErrorMessage, getRouterLink } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useHead } from "@unhead/vue";
import { markRaw } from "vue";

import {
  mdiRefresh,
  mdiCalendar,
  mdiOpenInNew,
  mdiBriefcase,
  mdiHelpCircleOutline,
  mdiPencil,
  mdiEye,
  mdiEyeOff,
  mdiChevronUp,
  mdiChevronDown
} from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import {
  Transaction,
  TransactionCaseSearchItem
} from "@/factories/Transaction";
import { StatementSearchItem } from "@/factories/Statement";

const props = defineProps({
  statementId: {
    type: [String, Number],
    required: false,
    default: null
  }
});

const emit = defineEmits(["total"]);

useHead({
  title: "Pending Transactions"
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    props.statementId
      ? null
      : new TableHeader({
          text: "Statement #",
          value: "statementId",
          map: "statementId",
          sortFilterMap: "statement_id",
          ...TableHeader.IS_FILTERABLE,
          ...TableHeader.IS_SORTABLE,
          ...TableHeader.IS_QUERY_FILTER,
          width: "250px"
        }),
    new TableHeader({
      text: "Policy #",
      value: "policyNumber",
      map: "policyNumber",
      sortFilterMap: "policy_number",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_QUERY_FILTER,
      width: "250px"
    }),
    new TableHeader({
      text: "Transaction Details",
      value: "transactionDetails",
      width: "250px"
    }),
    new TableHeader({
      text: "Error Message",
      value: "errorMessage",
      map: "errorMessage"
    }),
    new TableHeader({
      text: "",
      value: "data-table-expand",
      width: "125px"
    }),
    new TableHeader({
      text: "Include Ignored Transactions",
      value: "ignored",
      sortFilterMap: "ignore",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.SEND_FALSE
    })
  ].filter(Boolean),
  filter: {
    ignored: false
  },
  getData: getQueuedTransactions
});

async function getData() {
  const additionalFilter = {
    pending: true,
    with_count: true
  };
  if (props.statementId) additionalFilter.statement_id = props.statementId;
  try {
    await table.getData(additionalFilter);
    emit("total", table.meta.value.total);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function editQueuedTransaction(item) {
  const value = Transaction({
    queuedTransactionId: item.id,
    premium: item.premium,
    amount: item.amount
  });

  if (item.statement) {
    value.statement = StatementSearchItem();
    value.statement.id = item.statement.id;
    value.statement.payor = item.statement.payor;
    value.statement.statementDate = item.statement.date;
  }

  if (item.case) {
    value.policy = TransactionCaseSearchItem();
    value.policy.id = item.case.id;
  }

  const res = await dialog.showDialog({
    component: markRaw(TransactionCreate),
    modelValue: value,
    width: "90%",
    scrollable: true,
    isDialog: true,
    persistent: true
  });
  if (res?.updated) getData();
}

function toggleIgnore({ id, isIgnored }) {
  let title = "Ignore this transaction?";
  let subtitle =
    "You can find this transaction by including ignored transactions.";

  if (isIgnored) {
    title = "Don't Ignore this Transaction?";
    subtitle =
      "You can find this transaction without including ignored transactions.";
  }

  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title,
    subtitle,
    func: async () => {
      await toggleIgnoreQueuedTransaction(id, !isIgnored);
      await getData();
    }
  });
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
