<template>
  <v-col>
    <v-data-table
      :headers="table.tableHeaders.value"
      :items="table.mappedItems.value"
      :search="search"
      data-testid="informal-case-advisors-table"
      class="transparent-data-table"
      :mobile="null"
      mobile-breakpoint="sm"
    >
      <template #[`item.name`]="{ item }">
        <router-link
          v-if="item.additional.routerLink"
          :to="item.additional.routerLink"
        >
          {{ item.name }}
        </router-link>
        <span v-else> {{ item.name }} </span>
      </template>
      <template #[`item.phone`]="{ item }">
        <a
          :href="`tel:${item.phone}`"
          :data-outbound-type="item.additional.type"
          :data-outbound-id="item.additional.id"
          :data-outbound-number="item.phone"
          >{{ item.phone }}</a
        >
      </template>
      <template #[`item.actions`]="{ item }">
        <app-button
          :icon="mdiDelete"
          color="error"
          density="comfortable"
          variant="text"
          data-testid="delete-advisor"
          @click="deleteAdvisor(item.additional)"
        />
      </template>
    </v-data-table>
  </v-col>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { storeToRefs } from "pinia";
import { useTable } from "@/composables/table.composable";
import { useCaseViewStore } from "@/stores/case-view";
import { computed, markRaw, toRef, watch } from "vue";
import { mdiDelete } from "@mdi/js";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
const props = defineProps({
  search: {
    type: String,
    required: false,
    default: ""
  }
});

const dialog = useDialogStore();
const caseView = useCaseViewStore();
const user = useUserStore();
const { room } = storeToRefs(caseView);

const search = toRef(props, "search");
const advisors = computed(() => room.value.filter(v => Boolean(v?.agent)));

function getHeaders() {
  const headers = [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name"
    }),
    new TableHeader({
      text: "Phone",
      value: "phone",
      map: "phone"
    })
  ];

  if (user.isGroupThreePlus) {
    headers.push(
      new TableHeader({
        text: "Actions",
        value: "actions",
        map: "actions"
      })
    );
  }

  return headers;
}

const table = useTable({
  headers: getHeaders()
});

function getAdvisorIndex(advisor) {
  return room.value.findIndex(i => i.caseAccessId === advisor.caseAccessId);
}

function deleteAdvisor(advisor) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Advisor",
    subtitle: "Please confirm your intent",
    checkboxText: "Remove advisor access to this case?",
    func: async ({ checkboxChecked }) => {
      let index = getAdvisorIndex(advisor);
      if (index === -1) return;

      try {
        room.value[index].agent = false;
        await caseView.updateAccess(room.value[index].caseAccessId, "agent");
      } catch (e) {
        index = getAdvisorIndex(advisor);
        room.value[index].agent = true;
        throw e;
      }

      if (checkboxChecked) {
        await caseView.deleteAccess(advisor.caseAccessId);
      }
    }
  });
}

watch(
  advisors,
  () => {
    table.items.value.splice(0, table.items.value.length);
    table.items.value.push(...advisors.value);
  },
  { deep: true, immediate: true }
);
</script>
