<template>
  <v-row style="height: 100%; min-height: 13rem" dense>
    <v-col
      v-for="(
        { type, to, text, value, statisticType, hint }, index
      ) in statistics"
      :key="index"
      lg="3"
      sm="6"
      cols="12"
    >
      <template v-if="to">
        <router-link :to="to">
          <statistic-card
            :text="text"
            :model-value="value"
            :type="statisticType"
            :hint="hint"
            @hint="handleHint(type)"
          />
        </router-link>
      </template>
      <template v-else>
        <a @click="handleClick(type)">
          <statistic-card
            :text="text"
            :model-value="value"
            :type="statisticType"
            :hint="hint"
            @hint="handleHint(type)"
          />
        </a>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import StatisticCard from "@/components/home/StatisticCard.vue";
import { STATISTIC_TYPES } from "@/factories/home/HomeStatisticFactory";
import { useDialogStore } from "@/stores/dialog";
import { mapWritableState, mapActions } from "pinia";
import { useTableStore } from "@/stores/table";
import { ARTICLES } from "@/data/articles";
export default {
  components: {
    StatisticCard
  },
  props: {
    statistics: { type: Array, required: true }
  },
  computed: {
    ...mapWritableState(useTableStore, ["casesTable"])
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    viewActiveCases() {
      const filter = {
        status: "active",
        view: "my"
      };

      this.casesTable.filter = filter;
      this.$router.push({ name: "CasesTable" });
    },
    handleClick(type) {
      if (type === STATISTIC_TYPES.ACTIVE_CASES) {
        this.viewActiveCases();
        return;
      }
    },
    handleHint(type) {
      if (type === STATISTIC_TYPES.PENDING_PRODUCTION_CREDIT) {
        this.showDialog({
          component: "HelpCenterReader",
          id: ARTICLES.PRODUCTION_CREDIT
        });
      }
    }
  }
};
</script>
