<template>
  <v-card>
    <v-card-title>Contact Details</v-card-title>
    <v-card-text class="pb-0">
      <v-row class="ma-0">
        <v-col cols="3">
          <v-img
            :src="contact.avatar"
            class="pa-3"
            style="border-radius: 4px; max-width: 12rem"
          />
        </v-col>
        <v-col cols="9">
          <v-col cols="12">
            <h2>{{ contact.name }}</h2>
            <h4 style="font-weight: 400">{{ contact.type }}</h4>
          </v-col>
          <v-col cols="12" class="pb-0">
            <div v-if="contact.phone">
              <h4>
                Office:
                <a
                  data-testid="contact-details-phone"
                  :href="`tel:${contact.phone}`"
                  style="font-weight: normal"
                  :data-outbound-type="contact.type"
                  :data-outbound-id="contact.id"
                  :data-outbound-number="contact.phone"
                >
                  {{ contact.phone }}
                </a>
              </h4>
            </div>
          </v-col>
          <v-col cols="12" class="pt-0">
            <div v-if="contact.userEmail">
              <h4>
                Email:
                <a
                  :href="`mailto:${contact.userEmail}`"
                  style="font-weight: normal"
                >
                  {{ contact.userEmail }}
                </a>
              </h4>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        color="grey"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { useDialogStore } from "@/stores/dialog";
defineProps({
  contact: { type: Object, default: () => {} }
});

const dialog = useDialogStore();
</script>
