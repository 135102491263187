<template>
  <v-card
    v-if="item.href"
    hover
    :variant="item.outlined ? 'outlined' : undefined"
    height="100%"
    style="padding-bottom: 2rem"
    :href="href"
    :target="target"
    :class="item.class + '-' + themeClass"
  >
    <v-row
      v-if="item.img || item.icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img
        v-if="item.img"
        :src="item.img"
        style="width: auto; max-height: 5rem; height: auto; max-width: 14rem"
      />
      <v-icon
        v-else-if="item.icon"
        :color="item.iconColor"
        :icon="item.icon"
        size="42"
      />
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="text-grey font-weight-light">{{ item.type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="item.text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
            :icon="mdiRocketLaunch"
          />
          {{ item.text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ item.description }} </v-card-subtitle>

    <v-tooltip location="bottom">
      <template #activator="{ props: templateProps }">
        <v-icon
          v-bind="templateProps"
          style="position: absolute; bottom: 0; right: 0"
          :loading="item.loading"
          color="accent"
          class="text-none"
          :icon="mdiPlus"
        />
      </template>
      <span>Start Quoting</span>
    </v-tooltip>
  </v-card>
  <v-card
    v-else-if="item.to"
    hover
    :variant="item.outlined ? 'outlined' : undefined"
    height="100%"
    style="padding-bottom: 2rem"
    :to="to"
    :class="item.class + '-' + themeClass"
  >
    <v-row
      v-if="item.img || item.icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img v-if="item.img" :src="item.img" style="width: auto; height: 3rem" />
      <v-icon
        v-else-if="item.icon"
        :color="item.iconColor"
        size="42"
        :icon="item.icon"
      />
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="text-grey font-weight-light">{{ item.type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="item.text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
            :icon="mdiRocketLaunch"
          />
          {{ item.text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ item.description }} </v-card-subtitle>

    <v-tooltip location="bottom">
      <template #activator="{ props: templateProps }">
        <v-icon
          v-bind="templateProps"
          style="position: absolute; bottom: 0; right: 0"
          :loading="item.loading"
          :color="buttonColor"
          class="text-none"
          :icon="mdiPlus"
        />
      </template>
      <span>Start Quoting</span>
    </v-tooltip>
  </v-card>
  <v-card
    v-else
    height="100%"
    hover
    :variant="item.outlined ? 'outlined' : undefined"
    style="padding-bottom: 2rem"
    :style="{ backgroundColor: item.outlined ? 'transparent' : null }"
    :class="item.class + '-' + themeClass"
    @click="$emit('click')"
  >
    <v-row
      v-if="item.img || item.icon"
      class="ma-0"
      justify="center"
      align="center"
      style="height: 5rem"
    >
      <img v-if="item.img" :src="item.img" style="width: auto; height: 3rem" />
      <v-icon
        v-else-if="item.icon"
        :color="item.iconColor"
        size="42"
        :icon="item.icon"
      />
    </v-row>
    <v-card-title>
      <v-col class="pa-0">
        <div class="text-grey font-weight-light">{{ item.type }}</div>
        <div style="line-height: 1.2" class="mb-1">
          <v-icon
            v-if="item.text === 'Quote & Apply'"
            :color="buttonColor"
            class="mr-1"
            :icon="mdiRocketLaunch"
          />
          {{ item.text }}
        </div>
      </v-col>
    </v-card-title>
    <v-card-subtitle> {{ item.description }} </v-card-subtitle>

    <div style="position: absolute; bottom: 5px; right: 5px">
      <v-progress-circular v-if="item.loading" indeterminate color="accent" />
      <v-tooltip v-else location="bottom">
        <template #activator="{ props: templateProps }">
          <v-icon
            v-bind="templateProps"
            :color="buttonColor"
            class="text-none"
            :icon="mdiPlus"
          />
        </template>
        <span>Start Quoting</span>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
import { mdiRocketLaunch, mdiPlus } from "@mdi/js";
import { useTheme } from "vuetify/lib/framework.mjs";
export default {
  name: "QuoteMethod",
  props: {
    href: { type: String, default: null },
    target: { type: String, default: null },
    to: { type: Object, default: () => {} },
    click: { type: Function, default: () => {} },
    item: { type: Object, default: () => {} }
  },
  emits: ["click"],
  setup() {
    const theme = useTheme();
    return { theme };
  },
  data: () => ({ mdiRocketLaunch, mdiPlus }),
  computed: {
    themeClass() {
      return this.theme.current.value.dark ? "dark" : "light";
    },
    buttonColor() {
      if (this.item.key === "requestAQuote") return "primary";
      if (this.item.key === "impairedRiskQuote") return "orange";
      return "accent";
    }
  }
};
</script>
