<template>
  <v-row class="ma-0">
    <v-col
      v-for="statistic in statisticTypes"
      :key="`${statistic.title}-body`"
      cols="4"
      md="2"
      sm="3"
    >
      <v-card>
        <v-card-text>
          <span
            class="text-grey lighten-2"
            :data-testid="`analytics-${statistic.title}`"
          >
            {{ statistic.title }}
          </span>
          <br />
          <strong style="font-size: 18px">
            <number-formatter
              :data-testid="`analytics-${statistic.title}-week`"
              :model-value="statistics.weekly[statistic.key]"
            />
          </strong>
          this Week
          <br />
          <strong style="font-size: 18px">
            <number-formatter
              :data-testid="`analytics-${statistic.title}-month`"
              :model-value="statistics.monthly[statistic.key]"
            />
          </strong>
          this Month
          <br />
          <strong style="font-size: 18px">
            <number-formatter
              :data-testid="`analytics-${statistic.title}-all`"
              :model-value="statistics.all[statistic.key]"
            />
          </strong>
          All Time
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
const statisticTypes = [
  { title: "Applications", key: "applicationCount" },
  { title: "Leads", key: "leadCount" },
  { title: "Views", key: "viewCount" }
];

const approvedDomainView = useApprovedDomainView();
const { statistics } = storeToRefs(approvedDomainView);
</script>
