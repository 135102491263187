import { defineStore } from "pinia";

import {
  getApprovedDomain,
  updateApprovedDomain,
  unarchiveApprovedDomain,
  deleteDomainAccess,
  updateDomainAccess,
  createDomainAccess,
  createApprovedDomainCommissionSplit,
  updateApprovedDomainCommissionSplit,
  deleteApprovedDomainCommissionSplit,
  addApprovedDomainCarrier,
  removeApprovedDomainCarrier,
  updateApprovedDomainHeroImage,
  deleteApprovedDomainHeroImage,
  createApprovedDomainIntegration,
  deleteApprovedDomainIntegration,
  updateApprovedDomainIntegration
} from "@/api/approved-domain.service";

import ApprovedDomain, { toHtmlSnippet } from "@/factories/ApprovedDomain";

export const useApprovedDomainView = defineStore("approved-domain-view", {
  state: () => ApprovedDomain(),
  getters: {
    verbosePricingPlan() {
      return this.pricingPlanOptions.find(p => p.id === this.pricingPlan) || {};
    },
    isWhiteglove() {
      return this.verbosePricingPlan?.name?.includes("White Glove");
    },
    htmlSnippet() {
      return toHtmlSnippet(this.domainKey);
    },
    isSignUpOrSelectAgent() {
      return ["agent_sign_up", "select_agent"].includes(this.startPage);
    },
    isReferral() {
      return ["refer"].includes(this.startPage);
    }
  },
  actions: {
    async initializeApprovedDomain(id) {
      this.$reset();
      const approvedDomain = await getApprovedDomain(id);
      this.$patch(approvedDomain);
    },
    updateApprovedDomain(attribute, newVal) {
      let captureEmail = "Off";
      if (this.captureEmail && this.capturePhone) {
        captureEmail = "Prompt and Require";
      } else if (this.captureEmail) {
        captureEmail = "Require Email";
      } else if (this.capturePhone) {
        captureEmail = "Require Phone";
      }

      const map = {
        embedLocation: {
          embed_location: this.embedLocation
        },
        limitExam: {
          limit_exam: this.limitExam
        },
        chat: {
          chat: this.chat
        },
        overridePhone: {
          override_phone: this.overridePhone
        },
        defaultMode: {
          default_mode: this.defaultMode
        },
        theme: {
          theme: this.theme
        },
        productTypes: {
          product_types: {
            accidental_death: this.productTypes.includes("accidental_death"),
            iul: this.productTypes.includes("iul"),
            fex: this.productTypes.includes("fex"),
            piul: this.productTypes.includes("piul"),
            pvul: this.productTypes.includes("pvul"),
            rop_term: this.productTypes.includes("rop_term"),
            term: this.productTypes.includes("term"),
            ul: this.productTypes.includes("ul"),
            whole_life: this.productTypes.includes("whole_life"),
            non_med: this.productTypes.includes("non_med"),
            ltc: this.productTypes.includes("ltc"),
            linked_benefit: this.productTypes.includes("linked_benefit"),
            gul: false, // not sure what to do here
            avul: false // not sure what to do here
          }
        },
        customSchedulingLink: {
          scheduling_link: this.customSchedulingLink
        },
        referToDomain: {
          refer_to_approved_domain_id: this.referToDomain?.id || null
        },
        greeting: {
          greeting: this.greeting
        },
        introEmail: {
          client_email_text: this.introEmail
        },
        disclosure: {
          disclosure: this.disclosure
        },
        marketingAs: {
          market_as_id: this.marketingAs.id,
          market_as_type: this.marketingAs.type
        },
        marketAsLock: {
          market_as_lock: this.marketAsLock
        },
        informationCapture: {
          information_capture: this.informationCapture
        },
        hideAdditionalServices: {
          hide_additional_services: this.hideAdditionalServices
        },
        phoneNumber: {
          phone: this.phoneNumber
        },
        marketAsAgency: {
          market_as_agency: this.marketAsAgency
        },
        emailAgent: {
          email: this.emailAgent || false
        },
        accidentalDeathDefault: {
          default_slider_values: {
            accidental_death: this.accidentalDeathDefault,
            fex: this.finalExpenseDefault,
            permanent: this.permanentDefault
          }
        },
        finalExpenseDefault: {
          default_slider_values: {
            accidental_death: this.accidentalDeathDefault,
            fex: this.finalExpenseDefault,
            permanent: this.permanentDefault
          }
        },
        permanentDefault: {
          default_slider_values: {
            accidental_death: this.accidentalDeathDefault,
            fex: this.finalExpenseDefault,
            permanent: this.permanentDefault
          }
        },
        googleAnalyticsId: {
          google_analytics_id: this.googleAnalyticsId
        },
        googleTagManagerId: {
          google_tag_manager_id: this.googleTagManagerId
        },
        startPage: {
          start_page: this.startPage
        },
        menuOptions: {
          menu_options: this.menuOptions
        },
        overrideState: {
          override_state: this.overrideState || null
        },
        domain: {
          domain: this.domain
        },
        hideTestModeBanner: {
          hide_test_mode_banner: this.hideTestModeBanner
        },
        test: {
          test: this.test
        },
        useOwnersSchedulingLink: {
          use_owners_scheduling_link: this.useOwnersSchedulingLink
        },
        activeFirstInput: {
          active_first_input: this.activeFirstInput
        },
        assignable: {
          assignable_id: this.assignable.id,
          assignable_type: this.assignable.type
        },
        assignableLock: {
          assignable_lock: this.assignableLock
        },
        appointmentAssignable: {
          appointment_assignment_ownable_id: this.appointmentAssignable.id,
          appointment_assignment_ownable_type: this.appointmentAssignable.type
        },
        appointmentAssignableLock: {
          appointment_assignment_lock: this.appointmentAssignableLock
        },
        pricingPlan: {
          pricing_plan_id: newVal || this.pricingPlan
        },
        billable: {
          billable_id: this.billable.id,
          billable_type: this.billable.type
        },
        hideSelectRoundRobinAgent: {
          hide_select_round_robin_agent: this.hideSelectRoundRobinAgent
        },
        hideFromSearchEngines: {
          disallow_crawlers: this.hideFromSearchEngines
        },
        captureEmail: {
          email_capture: captureEmail
        },
        capturePhone: {
          email_capture: captureEmail
        },
        useOwnersAvatar: {
          use_owners_avatar: Boolean(this.useOwnersAvatar)
        },
        overrideCommissionSplits: {
          override_commission_splits: Boolean(this.overrideCommissionSplits)
        },
        color_primary: {
          color_primary: this.colors.light.primary
        },
        color_accent: {
          color_accent: this.colors.light.accent
        },
        color_dark_primary: {
          color_dark_primary: this.colors.dark.primary
        },
        color_dark_accent: {
          color_dark_accent: this.colors.dark.accent
        },
        skipRoundRobinLicenseCheck: {
          skip_round_robin_license_check: this.skipRoundRobinLicenseCheck
        },
        referText: {
          refer_text: this.referText
        },
        sendReferText: {
          send_refer_text: this.sendReferText
        }
      };

      return updateApprovedDomain(this.id, map[attribute]);
    },
    updateApprovedDomainCarrier(carrierId, value) {
      if (value) return addApprovedDomainCarrier(this.id, carrierId);
      return removeApprovedDomainCarrier(this.id, carrierId);
    },
    async unarchiveApprovedDomain() {
      await unarchiveApprovedDomain(this.id);
      this.archived = false;
    },
    deleteApprovedDomainAccess(accessId) {
      return deleteDomainAccess(this.id, accessId);
    },
    updateApprovedDomainAccess(accessId, attribute) {
      const access = this.accesses.find(a => a.id === accessId);
      const map = {
        name: { name: access.name },
        email: { email: access.email },
        roundRobin: { round_robin: access.roundRobin },
        additionalCaseAccess: {
          additional_case_access: access.additionalCaseAccess
        }
      };
      const body = { approved_domain_access: map[attribute] };
      return updateDomainAccess(this.id, accessId, body);
    },
    createApprovedDomainAccess(advisor) {
      const body = {
        approved_domain_access: {
          ownable_id: advisor.id,
          ownable_type: advisor.type,
          email: true,
          round_robin: false,
          additional_case_access: true
        }
      };

      return createDomainAccess(this.id, body);
    },
    createApprovedDomainCommissionSplit(agent) {
      return createApprovedDomainCommissionSplit(this.id, {
        agent_id: agent.id,
        split_percent: 0.01
      });
    },
    updateApprovedDomainCommissionSplit({ id, splitPercent }) {
      return updateApprovedDomainCommissionSplit(this.id, id, {
        split_percent: +(splitPercent / 100).toFixed(2)
      });
    },
    deleteApprovedDomainCommissionSplit({ id }) {
      return deleteApprovedDomainCommissionSplit(this.id, id);
    },
    async updateHeroImage({ file }) {
      this.heroImageUrl = await updateApprovedDomainHeroImage(this.id, file);
    },
    async removeHeroImage() {
      await deleteApprovedDomainHeroImage(this.id);
      this.heroImageUrl = null;
    },
    async createIntegration(integration) {
      const result = await createApprovedDomainIntegration(this.id, {
        name: integration.name,
        client_id: integration.clientId,
        enabled: integration.enabled,
        phone: integration.phone
      });
      return { ...result, name: integration.name };
    },
    async updateIntegration(integration) {
      const result = await updateApprovedDomainIntegration(
        this.id,
        integration.id,
        {
          enabled: integration.enabled,
          client_id: integration.clientId,
          phone: integration.phone
        }
      );
      return { ...result, name: integration.name };
    },
    async deleteIntegration(integration) {
      const result = await deleteApprovedDomainIntegration(
        this.id,
        integration.id
      );

      return { ...result, name: integration.name };
    }
  }
});
