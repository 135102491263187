import { Case, CASE_TASK_STATUS } from "@/factories/Case";
import { defineStore } from "pinia";
import { timestampFormatter, deepMergeObjects } from "@/util/helpers";

import {
  getCaseData,
  updateCase,
  removeCaseAccess,
  updateCaseAccess,
  createCaseAccess,
  getCaseCommissions
} from "@/api/cases.service.js";
import { updateRole } from "@/api/roles.service";
import { INSURED, ANNUITANT } from "@/factories/RoleFactory";
import { useCaseViewActiveTaskStore } from "@/stores/case-view-active-task";
import { setCaseTaskPreviewFromCaseTask } from "@/factories/Case";

const getInsuredIndex = contractParties =>
  contractParties.findIndex(v => getInsuredRoleIndex(v) !== -1);

const getInsuredRoleIndex = insured =>
  insured.roles.findIndex(r => [INSURED, ANNUITANT].includes(r.role));

export const useCaseViewStore = defineStore("case-view", {
  state: () => ({
    ...Case(),
    commissions: [],
    revenue: [],
    totals: [],
    todoCount: 0
  }),
  getters: {
    insured() {
      const insuredIndex = getInsuredIndex(this.contractParties);
      if (insuredIndex > -1) return this.contractParties[insuredIndex];
    },
    insuredRole() {
      if (!this.insured) return null;
      const insuredRoleIndex = getInsuredRoleIndex(this.insured);
      return this.insured.roles[insuredRoleIndex];
    },
    LINE() {
      return {
        INFORMAL: this.line === "informal",
        ANNUITY: this.line === "annuity",
        DISABILITY: this.line === "disability",
        LIFE: ["life", "variable_life", "linked_benefit"].includes(this.line),
        LINKED_BENEFIT: this.line === "linked_benefit",
        LTC: this.line === "ltc"
      };
    },
    policyDate() {
      return this.timeline.find(v => v.key === "policy_date")?.date;
    },
    usesDeathBenefit() {
      return ["life", "variable_life", "linked_benefit"].includes(
        this.product?.line
      );
    },
    caseIsApproved() {
      return [
        "Not Taken",
        "Withdrawn",
        "Postponed",
        "Declined",
        "Surrendered",
        "Matured",
        "Deceased/Death Claim",

        "Pended",
        "Cancelled",
        "Free Look",
        "Deferred Death",
        "Lapsed",
        "Surrender to the client",

        "Surrender/Exchanged to a third party",
        "Surrender/Converted",
        "Termination",
        "Rescinded",

        "Active",
        "Active - Pending Requirements",
        "Annuitized",
        "Letter of Authorization Sent",
        "One Deceased",

        "Active Annuity Payout",
        "Paid Up",
        "Grace Period",
        "Approved",
        "Issued",
        "Awaiting Reissued Policy",
        "Awaiting Reconsideration",

        "Received Delivery Requirements",
        "Awaiting Delivery Requirements",

        "Closed - Not Submitted to Carrier",
        "Closed - Submitted by Another GA"
      ].includes(this.status);
    },
    hiddenTasks() {
      return this.requirements.filter(v => v.hidden);
    },
    completeTasks() {
      const completedTasks = this.requirements.filter(
        v => v.status === CASE_TASK_STATUS.COMPLETE && !v.hidden
      );

      completedTasks.sort(
        (a, b) => new Date(b.completedAt) - new Date(a.completedAt)
      );
      return completedTasks;
    },
    incompleteTasks() {
      return this.requirements.filter(
        v => v.status === CASE_TASK_STATUS.INCOMPLETE && !v.hidden
      );
    },
    incompleteTaskCount() {
      return this.incompleteTasks.length;
    },
    completeTaskCount() {
      return this.completeTasks.length;
    },
    agents() {
      return this.room.filter(r => r.agent === true);
    },
    marketingManager() {
      let mms;
      if (this.LINE.INFORMAL) {
        mms = this.agents;
      } else {
        const apptCases = [...this.appointmentCases];
        apptCases.sort((a, b) => a.split_percent > b.split_percent);
        mms = apptCases;
      }
      return {
        schedulingLink: mms?.[0]?.schedulingLink,
        name: mms?.[0]?.name
      };
    },
    underwriter() {
      return (
        this.room.find(r => r.personnelDepartment === "Underwriting") || {
          name: "Unassigned"
        }
      );
    },
    carrierCaseManager() {
      return this.room.find(
        r => r.personnelDepartment === "Carrier Case Manager"
      );
    },
    primaryContact() {
      return this.caseConcierge || this.caseManager;
    },
    statusDate() {
      const statusDate = {
        Active: "inforce_date",
        "Active - Pending Requirements": "app_sign_date",
        Approved: "approved_date",
        "Awaiting Delivery Requirements": "sent_to_agent",
        Cancelled: "closed_date",
        "Closed - Not Submitted to Carrier": "closed_date",
        "Closed - Submitted by Another GA": "closed_date",
        Declined: "closed_date",
        Entered: "created_at",
        "Informal Closed": "closed_date",
        Issued: "issue_date",
        Lapsed: "lapsed_date",
        "Gathering Medical Information": "created_at",
        "Not Taken": "closed_date",
        "Paid Up": "paid_up_date",
        Postponed: "closed_date",
        "Received Delivery Requirements": "received_delivery_requirements_date",
        Submitted: "submitted_date",
        "Surrender/Exchange (to a third party)": "closed_date",
        "Surrender/Individual (to the client)": "closed_date",
        Surrendered: "closed_date",
        Suspended: "closed_date",
        Withdrawn: "closed_date"
      }[this.status];

      if (!statusDate) return;
      const timelineItem = this.timeline.find(val => val.key === statusDate);
      if (!timelineItem) return;

      return timestampFormatter(timelineItem.date, "sole-day");
    }
  },
  actions: {
    async initializeCaseView(id) {
      this.$reset();
      const caseData = await getCaseData(id);
      this.$patch({
        ...caseData,
        commissions: [],
        revenue: [],
        totals: []
      });
    },
    saveAttributes(attributes = []) {
      if (!attributes.length) return;
      const mappedAttributes = attributes.reduce((accumulator, index) => {
        const cfg = this.mapAttribute(index);
        if (!accumulator[cfg.group]) accumulator[cfg.group] = {};
        accumulator[cfg.group] = { ...accumulator[cfg.group], ...cfg.body };
        return accumulator;
      }, {});

      const { insuredRole, caseData } = mappedAttributes;

      const promises = [];
      if (insuredRole) {
        promises.push(updateRole(this.insuredRole.id, insuredRole));
      }
      if (caseData) {
        promises.push(updateCase(this.id, caseData));
      }

      return Promise.all(promises);
    },
    mapAttribute(attribute) {
      return {
        ratingApplied: {
          group: "insuredRole",
          body: { rating_applied: this.insuredRole.ratingApplied }
        },
        ratingApproved: {
          group: "insuredRole",
          body: { approval_rating: this.insuredRole.ratingApproved }
        },
        underwritingRationale: {
          group: "insuredRole",
          body: {
            underwriting_rationale: this.insuredRole.underwritingRationale
          }
        },
        relationship: {
          group: "insuredRole",
          body: { relationship: this.relationship }
        },
        occupationClass: {
          group: "insuredRole",
          body: { occupation_class: this.insuredRole.occupationClass }
        },

        policyNumber: {
          group: "caseData",
          body: { policy_number: this.policyNumber }
        },
        status: {
          group: "caseData",
          body: { status: this.status }
        },
        state: {
          group: "caseData",
          body: { state: this.state }
        },
        product: {
          group: "caseData",
          body: { product_id: this.product?.id }
        },
        faceAmount: {
          group: "caseData",
          body: { face_amount: this.faceAmount }
        },
        chronicIllnessRider: {
          group: "caseData",
          body: { chronic_illness_rider: this.chronicIllnessRider }
        },
        ltcRider: {
          group: "caseData",
          body: { ltc_rider: this.ltcRider }
        },
        tia: {
          group: "caseData",
          body: { tia: this.tia }
        },
        appliedMode: {
          group: "caseData",
          body: { applied_mode: this.appliedMode }
        },
        appliedPremium: {
          group: "caseData",
          body: { applied_premium: this.appliedPremium }
        },
        messageClient: {
          group: "caseData",
          body: { message_client: this.messageClient }
        },
        mode: {
          group: "caseData",
          body: { mode: this.mode }
        },
        premium: {
          group: "caseData",
          body: { premium: this.premium }
        },
        targetPremium: {
          group: "caseData",
          body: { target_premium: this.targetPremium }
        },
        exchangeAmount: {
          group: "caseData",
          body: { exchange_amount: this.exchangeAmount }
        },
        lumpSum: {
          group: "caseData",
          body: { lump_sum: this.lumpSum }
        },
        cashReceived: {
          group: "caseData",
          body: { cash_received: this.cashReceived }
        },
        taxType: {
          group: "caseData",
          body: { tax_type: this.taxType }
        },
        inflation: {
          group: "caseData",
          body: { inflation: this.inflation }
        },
        carrierEDelivery: {
          group: "caseData",
          body: { carrier_e_delivery: this.carrierEDelivery }
        },
        address: {
          group: "caseData",
          body: { address_id: this.address }
        },
        trackingNumberType: {
          group: "caseData",
          body: { tracking_number_type: this.trackingNumberType }
        },
        trackingNumber: {
          group: "caseData",
          body: { tracking_number: this.trackingNumber }
        },
        eliminationPeriod: {
          group: "caseData",
          body: { elimination_period: this.eliminationPeriod }
        },
        saveAge: {
          group: "caseData",
          body: { save_age: this.saveAge }
        },
        policyDate: {
          group: "caseData",
          body: {
            policy_date: this.timeline.find(v => v.key === "policy_date")?.date
          }
        },
        conversionExpirationDate: {
          group: "caseData",
          body: {
            conversion_expiry: this.timeline.find(
              v => v.key === "conversion_expiry"
            )?.date
          }
        },
        submittedDate: {
          group: "caseData",
          body: {
            submitted_date: this.timeline.find(v => v.key === "submitted_date")
              ?.date
          }
        },
        approvedDate: {
          group: "caseData",
          body: {
            approved_date: this.timeline.find(v => v.key === "approved_date")
              ?.date
          }
        },
        issueDate: {
          group: "caseData",
          body: {
            issue_date: this.timeline.find(v => v.key === "issue_date")?.date
          }
        },
        inforceDate: {
          group: "caseData",
          body: {
            inforce_date: this.timeline.find(v => v.key === "inforce_date")
              ?.date
          }
        },
        appSignDate: {
          group: "caseData",
          body: {
            app_sign_date: this.timeline.find(v => v.key === "app_sign_date")
              ?.date
          }
        },
        deliveryRequirementsDueDate: {
          group: "caseData",
          body: {
            delivery_requirements_due_date: this.timeline.find(
              v => v.key === "delivery_requirements_due_date"
            )?.date
          }
        },
        closedDate: {
          group: "caseData",
          body: {
            closed_date: this.timeline.find(v => v.key === "closed_date")?.date
          }
        },
        sentToAgentDate: {
          group: "caseData",
          body: {
            sent_to_agent: this.timeline.find(v => v.key === "sent_to_agent")
              ?.date
          }
        },
        benefitPeriod: {
          group: "caseData",
          body: { benefit_period: this.benefitPeriod }
        },
        benefitAmount: {
          group: "caseData",
          body: { benefit_amount: this.benefitAmount }
        },
        benefitMode: {
          group: "caseData",
          body: { benefit_mode: this.benefitMode }
        }
      }[attribute];
    },
    setInsuredRole(data) {
      const insuredIndex = getInsuredIndex(this.contractParties);
      if (insuredIndex === -1) return;
      const insuredRole = getInsuredRoleIndex(
        this.contractParties[insuredIndex]
      );
      if (insuredRole === -1) return;

      const role = this.contractParties[insuredIndex].roles[insuredRole];
      this.contractParties[insuredIndex].roles[insuredRole] = deepMergeObjects(
        role,
        data
      );
    },

    async deleteAccess(caseAccessId) {
      let accessIndex = this.room.findIndex(
        a => a.caseAccessId === caseAccessId
      );
      if (accessIndex === -1) return;
      const access = this.room[accessIndex];
      await removeCaseAccess(this.id, access.caseAccessId);

      accessIndex = this.room.findIndex(a => a.caseAccessId === caseAccessId);
      if (accessIndex === -1) return;
      this.room.splice(accessIndex, 1);
    },
    updateAccess(caseAccessId, attribute) {
      const access = this.room.find(a => a.caseAccessId === caseAccessId);
      const map = {
        advisorAccess: { advisor_access: access.advisorAccess },
        carrierAccess: { carrier_access: access.carrierAccess },
        carrierEmail: { carrier_email: access.carrierEmail },
        agent: { agent: access.agent },
        email: { email: access.email }
      };

      return updateCaseAccess(this.id, access.caseAccessId, map[attribute]);
    },
    createAccess(advisor, { agent = false } = {}) {
      const body = {
        agent,
        case_id: `${this.id}`,
        case_manager: false,
        ownable_id: advisor.id,
        ownable_type: advisor.type
      };

      return createCaseAccess(this.id, body);
    },
    async getCaseCommissions() {
      const { commissions, revenue, totals, transactions } =
        await getCaseCommissions(this.id);

      this.commissions.push(...commissions);
      this.revenue.push(...revenue);
      this.totals.push(...totals);
      this.transactions.push(...transactions);
    },
    syncTask() {
      const activeTaskStore = useCaseViewActiveTaskStore();
      const key = activeTaskStore.activeTaskKey;
      const index = this.requirements.findIndex(v => v.key === key);
      if (index === -1) return;

      const updatedPreview = setCaseTaskPreviewFromCaseTask(
        activeTaskStore.$state,
        this.requirements[index]
      );
      this.requirements[index] = updatedPreview;
    },
    deleteTask(taskKey) {
      const index = this.requirements.findIndex(k => k.key === taskKey);
      if (index === -1) return;
      this.requirements.splice(index, 1);
    }
  }
});
