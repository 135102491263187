<template>
  <v-card variant="flat" class="my-1 border-thin" @click="emit('edit')">
    <v-row class="ma-0 pa-3" align="center">
      <strong class="mr-1" data-testid="occupation-description">
        {{ props.occupation.occupation }} - {{ props.occupation.employerName }}
      </strong>
      <v-spacer />
      <div data-testid="occupation-timeframe">
        <timestamp-formatter
          :model-value="props.occupation.startDate"
          parser="sole-day"
        />
        <template v-if="props.occupation.endDate">
          -
          <timestamp-formatter
            :model-value="props.occupation.endDate"
            parser="sole-day"
          />
        </template>
        <template v-else> - Present </template>
      </div>
      <div v-if="!props.readonly">
        <app-button
          :icon="mdiPencil"
          color="success"
          class="ml-1"
          variant="text"
          density="comfortable"
          data-testid="edit-occupation"
          @click.stop="emit('edit')"
        />
        <app-button
          :icon="mdiDelete"
          color="error"
          variant="text"
          density="comfortable"
          data-testid="delete-occupation"
          @click.stop="emit('delete')"
        />
      </div>
    </v-row>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { mdiPencil, mdiDelete } from "@mdi/js";

const props = defineProps({
  occupation: {
    type: Object,
    required: true
  },
  readonly: Boolean
});

const emit = defineEmits(["edit", "delete"]);
</script>
