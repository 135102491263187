<template>
  <v-sheet :color="color" class="my-3 rounded" :data-testid="dataTestid">
    <v-row align="center" dense>
      <v-col cols="12">
        <v-row class="ma-0">
          <h3 style="font-weight: 400; font-size: 18px">{{ headerText }}</h3>
          <app-button
            v-if="hasValue"
            :icon="mdiUndo"
            class="ml-2"
            density="comfortable"
            variant="text"
            :disabled="loading"
            @click="reset"
          />
        </v-row>
      </v-col>
      <v-col
        :key="headerText"
        cols="12"
        :lg="
          date.type === DATE_TYPES.RANGE
            ? 4
            : date.type === DATE_TYPES.SOLO
            ? 6
            : 12
        "
      >
        <select-field
          ref="type"
          v-model="date.type"
          attach
          label="Type"
          hide-details
          :prepend-inner-icon="mdiCalendarCursor"
          :data-testid="`${dataTestid}-type`"
          :items="dateFilterItems"
          :disabled="loading"
          :menu-props="{ bottom: true, offsetY: true }"
          @update:model-value="updateType"
        />
      </v-col>
      <template v-if="date.type === DATE_TYPES.RANGE">
        <v-col :key="headerText + '-start-range'" cols="12" md="4">
          <date-input
            ref="startInput"
            v-model="date.value.start"
            label="Start"
            style="margin-right: 0.5rem"
            hide-details
            :prepend-inner-icon="mdiCalendarStart"
            clearable
            :disabled="loading"
            :data-testid="`${dataTestid}-start`"
            @update:model-value="emitChange"
            @click:clear="date.value.start = undefined"
          />
        </v-col>
        <v-col :key="headerText + '-finish-range'" cols="12" md="4">
          <date-input
            v-model="date.value.finish"
            hide-details
            label="Finish"
            :prepend-inner-icon="mdiCalendarEnd"
            clearable
            :disabled="loading"
            :data-testid="`${dataTestid}-finish`"
            @update:model-value="emitChange"
            @click:clear="date.value.finish = undefined"
          />
        </v-col>
      </template>
      <v-col
        v-else-if="date.type === DATE_TYPES.SOLO"
        :key="headerText + '-solo'"
        cols="12"
        md="6"
      >
        <date-input
          ref="startInput"
          v-model="date.value.start"
          label="Date"
          hide-details
          :prepend-inner-icon="mdiCalendar"
          clearable
          :data-testid="`${dataTestid}-specific`"
          :disabled="loading"
          @update:model-value="specifyFn"
          @click:clear="clearValue"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup>
import {
  pastDateFilters,
  futureDateFilters
} from "@/constants/date-filter.constants";
import DateInput from "@/components/shared/DateInput.vue";
import {
  mdiUndo,
  mdiCalendarCursor,
  mdiCalendarStart,
  mdiCalendarEnd,
  mdiCalendar
} from "@mdi/js";
import { computed, ref, toRef, unref, watch } from "vue";
const DATE_TYPES = {
  RANGE: "range",
  SOLO: "solo"
};

const props = defineProps({
  color: {
    type: String,
    default: "white"
  },
  futureDateValues: Boolean,
  headerText: { default: null, type: String },
  modelValue: { default: null, type: Object },
  loading: Boolean,
  dataTestid: {
    type: String,
    default: "date"
  }
});
const emit = defineEmits(["update:model-value"]);

const modelValue = toRef(props, "modelValue");
const type = ref(); //templateRef
const startInput = ref(); //templateRef

const date = ref({
  type: "solo",
  value: {
    start: "",
    finish: ""
  }
});

const hasValue = computed(
  () => date.value.value.start && date.value.value.finish
);

const dateFilterItems = computed(() => {
  const items = [{ title: "Specify Date", value: "solo" }];
  if (props.futureDateValues) items.push(...futureDateFilters);
  else items.push(...pastDateFilters);
  items.push({ title: "Custom Range", value: "range" });
  return items;
});

function specifyFn() {
  date.value.value.finish = date.value.value.start;
  emitChange();
}
function focus() {
  if ([DATE_TYPES.RANGE, DATE_TYPES.SOLO].includes(date.value.type)) {
    startInput.value.$el.querySelector("input").focus();
  } else {
    type.value.$el.querySelector("input").focus();
  }
}
function emitChange() {
  const sameType = date.value.type === modelValue.value?.type;
  const sameFinish =
    date.value.value.finish === modelValue.value?.value?.finish;
  const sameStart = date.value.value.start === modelValue.value?.value?.start;

  if (sameType && sameStart && sameFinish) return;

  if ([DATE_TYPES.SOLO, DATE_TYPES.RANGE].includes(date.value.type)) {
    debounceAndEmit();
  } else {
    emit("update:model-value", unref(date));
  }
}

let debounceTimer;
function debounceAndEmit() {
  if (debounceTimer) clearTimeout(debounceTimer);
  setTimeout(() => {
    if (date.value.type === DATE_TYPES.SOLO) {
      emit("update:model-value", unref(date));
    } else if (date.value.type === DATE_TYPES.RANGE) {
      if (hasValue.value) emit("update:model-value", unref(date));
    }
  }, 200);
}

function reset() {
  date.value.type = "solo";
  clearValue();
}
function clearValue() {
  date.value.value.start = null;
  date.value.value.finish = null;
  emitChange();
}
function updateType() {
  let type = date.value.type || date.value.filterType;

  const filter = dateFilterItems.value.find(i => i.value === type);
  if (filter?.fn) {
    const value = filter.fn();
    date.value.value.start = value.start;
    date.value.value.finish = value.finish;
  } else {
    date.value.value = { start: null, finish: null };
  }

  emitChange();
}

watch(
  modelValue,
  val => {
    if (JSON.stringify(date.value) === JSON.stringify(val)) return;
    const unrefModelValue = unref(modelValue);
    if (dateFilterItems.value.some(i => i.value === unrefModelValue?.type)) {
      date.value.type = unrefModelValue.type;
    }
    date.value.value.start = unrefModelValue?.value?.start;
    date.value.value.finish = unrefModelValue?.value?.finish;
  },
  { deep: true, immediate: true }
);

defineExpose({ focus });
</script>
