export const PERMISSION = {
  FORM_MAPPING: "form_mapping",
  ACCOUNTING: "accounting",
  SCORECARD: "scorecard"
};

export const PERMISSION_TEXT = {
  [PERMISSION.FORM_MAPPING]: "Form Mapping",
  [PERMISSION.ACCOUNTING]: "Accounting",
  [PERMISSION.SCORECARD]: "Scorecard"
};

export const PERMISSION_FILTER_ITEMS = Object.values(PERMISSION).map(rp => ({
  title: PERMISSION_TEXT[rp],
  value: rp
}));
