<template>
  <autocomplete-field
    v-model="statement"
    v-bind="$attrs"
    v-model:search="search"
    autocomplete="false"
    return-object
    no-filter
    hide-no-data
    :data-testid="dataTestid"
    :item-title="convertStatementToText"
    :label="label"
    :prepend-inner-icon="mdiCheckbook"
    :placeholder="placeholder"
    :items="statements"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
  />
</template>

<script setup>
import { mdiCheckbook } from "@mdi/js";
import { statementSearch } from "@/api/statements.service";
import {
  currencyFormat,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";
import { ref,  watch, toRef } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";

const props = defineProps({
  dataTestid: { type: String, required: false, default: null },
  modelValue: { type: Object, required: false, default: null },
  carrierId: { type: [String, Number], required: false, default: null },
  label: { type: String, required: false, default: "Statement" },
  placeholder: { type: String, required: false, default: "Search Statements" }
});

const snackbar = useSnackbarStore();
const emit = defineEmits(["update:model-value"]);
const modelValue = toRef(props, "modelValue");

const statements = ref([]);
const statement = ref(null);
const search = ref("");
const loading = ref(false);

if (modelValue.value && Object.keys(modelValue.value).length) {
  statements.value.push(modelValue.value);
  statement.value = modelValue.value;
}

watch(modelValue, () => {
  statements.value.splice(0, statements.value.length);
  if (!modelValue.value || !Object.keys(modelValue.value).length) {
    statement.value = null;
    return;
  }
  statement.value = modelValue.value;
  statements.value.push(modelValue.value);
});

watch(statement, () => {
  if (statement.value?.id === modelValue.value?.id) return;
  emit("update:model-value", statement.value);
});

watch(search, debounceAndSearch);

let timer;
function debounceAndSearch() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(searchStatements, 200);
}

async function searchStatements() {
  if (!search.value) return;

  if (
    statements.value.length === 1 &&
    convertStatementToText(statements.value[0]) === search.value
  )
    return;

  try {
    loading.value = true;
    const params = new URLSearchParams();
    params.append("search", search.value);
    if (props.carrierId) params.append("carrier_id", props.carrierId);

    const response = await statementSearch(params);
    statements.value.splice(0, statements.value.length);
    statements.value.push(
      ...response
        .toSorted(
          (a, b) => new Date(a.statementDate) - new Date(b.statementDate)
        )
        .toReversed()
    );
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function convertStatementToText(row) {
  if (!row?.payor?.name) return "";
  return [
    row.payor.name,
    timestampFormatter(row.statementDate, "sole-day"),
    row.amount ? currencyFormat(row.amount) : null
  ]
    .filter(Boolean)
    .join(" - ");
}
</script>
