import { currencyFormat, numberFormat } from "@/util/helpers";

const displayFormatter = (v, isCurrency) =>
  isCurrency ? currencyFormat(v) : numberFormat(v);

export const NUMBER_FILTER = {
  GTEQ: ">=",
  LTEQ: "<=",
  EQ: "=",
  RANGE: "range"
};

export const numberFilters = [
  {
    title: "Greater than or equal to",
    value: NUMBER_FILTER.GTEQ,
    displayMap: (min, _, isCurrency) =>
      `Greater than or equal to ${displayFormatter(min, isCurrency)}`,
    requiresMin: true
  },
  {
    title: "Less than or equal to",
    value: NUMBER_FILTER.LTEQ,
    displayMap: (_, max, isCurrency) =>
      `Less than or equal to ${displayFormatter(max, isCurrency)}`,
    requiresMax: true
  },
  {
    title: "Exactly equal to",
    value: NUMBER_FILTER.EQ,
    displayMap: (min, max, isCurrency) =>
      `Exactly equal to ${displayFormatter(min, isCurrency)}`,
    requiresMin: true
  },
  {
    title: "Custom Range",
    value: NUMBER_FILTER.RANGE,
    displayMap: (min, max, isCurrency) =>
      `${displayFormatter(min, isCurrency)} - ${displayFormatter(
        max,
        isCurrency
      )}`,
    requiresMin: true,
    requiresMax: true
  }
];

export const isNumberFilterType = t => numberFilters.some(f => f.value === t);
