<template>
  <v-card flat tile>
    <v-card-text>
      <v-row class="pa-3">
        <v-spacer />
        <template v-if="!isWhiteglove">
          <app-button
            color="accent"
            class="text-none mr-1 rounded-pill"
            data-testid="action-toggle-custom-avatar"
            :variant="!useOwnersAvatar ? 'outlined' : 'flat'"
            :loading="changingAvatar"
            @click="toggleAvatar"
          >
            <template v-if="useOwnersAvatar">
              <v-icon class="mr-1" :icon="mdiImage" />
              Switch to Custom Avatar
            </template>
            <template v-else>
              <v-icon class="mr-1" :icon="mdiAccountCircle" />
              Switch to {{ assignable.name }}'s Avatar
            </template>
          </app-button>
        </template>
        <app-button
          variant="outlined"
          class="text-none rounded-pill"
          color="primary"
          data-testid="action-view-embed-code"
          @click="codeDialog"
        >
          <v-icon class="mr-1" :icon="mdiCodeTags" />Show Embed Code
        </app-button>
        <app-button
          v-if="user.isGroupTwoPlus && archived"
          variant="outlined"
          color="brown"
          class="text-none ml-1 rounded-pill"
          @click="unarchiveSite"
        >
          <v-icon :icon="mdiArchiveArrowDown" /> Unarchive
        </app-button>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CopyDialog from "@/dialogs/CopyDialog.vue";

import { parseErrorMessage } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { storeToRefs } from "pinia";
import {
  mdiImage,
  mdiAccountCircle,
  mdiCodeTags,
  mdiArchiveArrowDown
} from "@mdi/js";
import { markRaw, ref } from "vue";

const approvedDomainView = useApprovedDomainView();
const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { useOwnersAvatar, htmlSnippet, isWhiteglove, assignable, archived } =
  storeToRefs(approvedDomainView);
const changingAvatar = ref(false);

function toggleAvatar() {
  if (changingAvatar.value) return;
  useOwnersAvatar.value = !useOwnersAvatar.value;
  changingAvatar.value = true;
  try {
    approvedDomainView.updateApprovedDomain("useOwnersAvatar");
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    changingAvatar.value = false;
  }
}

function codeDialog() {
  dialog.showDialog({
    component: markRaw(CopyDialog),
    text: htmlSnippet.value,
    title: "Quote & Apply Snippet",
    subtitle: "Just copy and paste this into your site!"
  });
}

async function unarchiveSite() {
  try {
    await approvedDomainView.unarchiveApprovedDomain();
    snackbar.showSuccessSnackbar({
      message: "Successfully unarchived.",
      timeout: -1
    });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  }
}
</script>
