<template>
  <autocomplete-field
    v-model="model"
    multiple
    :prepend-inner-icon="mdiShape"
    chips
    small-chips
    deletable-chips
    :data-testid="dataTestid"
    :clearable="props.clearable"
    :loading="cache.productSolves.loading"
    :label="props.label"
    :disabled="props.readonly"
    :items="cache.productSolves.items"
    :success="props.success"
    :error-messages="props.errorMessages"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { basicArrayEquivalence, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, watch, toRef } from "vue";
import { mdiShape } from "@mdi/js";
const emit = defineEmits(["update:model-value"]);
const props = defineProps({
  readonly: Boolean,
  clearable: Boolean,
  success: Boolean,
  modelValue: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: ""
  },
  dataTestid: {
    type: String,
    default: "product-solve-search"
  },
  errorMessages: {
    type: Array,
    default: () => []
  }
});

const modelValue = toRef(props, "modelValue");
const model = ref(props.modelValue);

const cache = useSearchItemsCache();
const snackbar = useSnackbarStore();
watch(model, () => {
  if (basicArrayEquivalence(model.value, modelValue.value || [])) return;
  emit("update:model-value", model.value);
});

watch(modelValue, () => {
  if (basicArrayEquivalence(model.value, modelValue.value || [])) return;
  model.value = modelValue.value || [];
});

async function getItems() {
  try {
    await cache.getProductSolves();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getItems();
</script>
