<template>
  <v-card>
    <v-card-title>Change Password</v-card-title>
    <v-card-text>
      <text-field
        v-model="passwordForm.oldPassword"
        label="Old Password"
        type="password"
        data-testid="old-password"
        :prepend-inner-icon="mdiLockOutline"
        v-bind="oldPasswordValidation"
      />
      <text-field
        v-model="passwordForm.newPassword"
        label="New Password"
        type="password"
        data-testid="new-password"
        :prepend-inner-icon="mdiLock"
        v-bind="newPasswordValidation"
      />
      <text-field
        v-model="passwordForm.newPasswordConfirmation"
        label="Confirm New Password"
        type="password"
        data-testid="confirm-password"
        :prepend-inner-icon="mdiLockCheck"
        v-bind="newPasswordConfirmationValidation"
      />
    </v-card-text>
    <v-card-actions>
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="change-password-button"
        :loading="saving"
        @click="updatePassword"
      >
        Change Password
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { changePassword } from "@/api/security.service";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import {
  passwordValidator,
  passwordValidatorMessages
} from "@/util/vuelidateValidators";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiLockOutline, mdiLock, mdiLockCheck } from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { ref } from "vue";
const dialog = useDialogStore();

const snackbar = useSnackbarStore();
const saving = ref(false);
const passwordForm = ref({
  oldPassword: "",
  newPassword: "",
  newPasswordConfirmation: ""
});

const v$ = useVuelidate(
  {
    passwordForm: {
      oldPassword: { required: v => Boolean(v) },
      newPassword: passwordValidator,
      newPasswordConfirmation: {
        matches: (v, vm) => v && v === vm.newPassword
      }
    }
  },
  {
    passwordForm
  },
  {
    $scope: "password-change",
    $autoDirty: true
  }
);

const oldPasswordValidation = computedValidation(
  v$.value.passwordForm.oldPassword,
  { required: "Required" }
);

const newPasswordValidation = computedValidation(
  v$.value.passwordForm.newPassword,
  passwordValidatorMessages
);

const newPasswordConfirmationValidation = computedValidation(
  v$.value.passwordForm.newPasswordConfirmation,
  {
    matches: "Passwords must match"
  }
);

async function updatePassword() {
  try {
    const isValid = v$.value.$validate();
    if (!isValid) return;
    saving.value = true;
    await changePassword({
      oldPassword: passwordForm.value.oldPassword,
      newPassword: passwordForm.value.newPassword,
      newPasswordConfirmation: passwordForm.value.newPasswordConfirmation
    });
    snackbar.showSuccessSnackbar({ message: "Password successfully updated" });
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
