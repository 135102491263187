import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/user_identities";

export async function createRedtailIntegration(username, password) {
  const { data } = await getHttpClient().post(`${baseUrl}/redtail`, {
    username,
    password
  });

  return data.id;
}
