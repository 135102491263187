<template>
  <v-card flat class="rounded-t-0" color="section">
    <v-card-title>Basic Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="4">
          <text-field
            v-model="firstName"
            label="First Name"
            data-lpignore="true"
            data-testid="first-name"
            :prepend-inner-icon="mdiAccount"
            :success="firstNameValidation.success"
            :error-messages="firstNameValidation.errorMessages"
            @update:model-value="updateAttribute('firstName')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.firstName.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="4">
          <text-field
            v-model="middleName"
            label="Middle Name"
            data-lpignore="true"
            data-testid="middle-name"
            :prepend-inner-icon="mdiAccount"
            :success="middleNameValidation.success"
            :error-messages="middleNameValidation.errorMessages"
            @update:model-value="updateAttribute('middleName')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.middleName.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="4">
          <text-field
            v-model="lastName"
            label="Last Name"
            data-lpignore="true"
            data-testid="last-name"
            :prepend-inner-icon="mdiAccountMultiple"
            :success="lastNameValidation.success"
            :error-messages="lastNameValidation.errorMessages"
            @update:model-value="updateAttribute('lastName')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.lastName.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="gender"
            label="Gender"
            placeholder="Choose a Gender"
            data-testid="gender"
            :prepend-inner-icon="mdiGenderMaleFemale"
            :items="GENDERS"
            :success="genderValidation.success"
            :error-messages="genderValidation.errorMessages"
            @update:model-value="updateAttribute('gender')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.gender.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="maritalStatus"
            label="Marital Status"
            placeholder="Choose a Marital Status"
            clearable
            data-testid="marital-status"
            :prepend-inner-icon="mdiRing"
            :success="maritalStatusValidation.success"
            :error-messages="maritalStatusValidation.errorMessages"
            :items="INDIVIDUAL_MARITAL_STATUSES"
            @update:model-value="updateAttribute('maritalStatus')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.maritalStatus.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Contact Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <phone-input
            v-model="phoneMobile"
            label="Mobile Phone"
            data-lpignore="true"
            data-testid="phone-mobile"
            :prepend-inner-icon="mdiPhone"
            :success="phoneMobileValidation.success"
            :error-messages="phoneMobileValidation.errorMessages"
            @update:model-value="updateAttribute('phoneMobile')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.phoneMobile.controller.value"
              />
            </template>
          </phone-input>
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            label="Email"
            data-lpignore="true"
            data-testid="email"
            readonly
            :model-value="email"
            :prepend-inner-icon="mdiEmail"
            :success="emailValidation.success"
            :error-messages="emailValidation.errorMessages"
            @click="showChangeEmailDialog"
          />
        </v-col>
        <v-col cols="12">
          <basic-address-input
            key="contract-party-home-address"
            v-model:street-address-model="homeAddress.street_address"
            v-model:city-model="homeAddress.city"
            v-model:state-model="homeAddress.state"
            v-model:zip-model="homeAddress.zip"
            autofill-label="Home Address"
            autofill-placeholder="Home Address"
            data-testid="home"
            :street-address-validation="homeStreetValidation"
            :city-validation="homeCityValidation"
            :state-validation="homeStateValidation"
            :zip-validation="homeZipValidation"
            @update:street-address-model="updateAttribute('homeStreet')"
            @update:city-model="updateAttribute('homeCity')"
            @update:state-model="updateAttribute('homeState')"
            @update:zip-model="updateAttribute('homeZip')"
            @autofill="autofillHomeAddress"
          >
            <template #append-inner-street-address>
              <active-save-indicator
                :controller="savingBuffer.homeStreet.controller.value"
              />
            </template>
            <template #append-inner-city>
              <active-save-indicator
                :controller="savingBuffer.homeCity.controller.value"
              />
            </template>
            <template #append-inner-state>
              <active-save-indicator
                :controller="savingBuffer.homeState.controller.value"
              />
            </template>
            <template #append-inner-zip>
              <active-save-indicator
                :controller="savingBuffer.homeState.controller.value"
              />
            </template>
          </basic-address-input>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Employment Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" :md="!occupationId ? 12 : 4">
          <text-field
            v-model="occupation"
            label="Occupation"
            data-lpignore="true"
            data-testid="occupation"
            :prepend-inner-icon="mdiBriefcase"
            :success="occupationValidation.success"
            :error-messages="occupationValidation.errorMessages"
            @update:model-value="updateAttribute('occupation')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.occupation.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <template v-if="occupationId">
          <v-col cols="12" md="4">
            <text-field
              v-model="employerName"
              label="Employer"
              data-lpignore="true"
              data-testid="employer-name"
              :prepend-inner-icon="mdiAccountSupervisor"
              :success="employerNameValidation.success"
              :error-messages="employerNameValidation.errorMessages"
              @update:model-value="updateAttribute('employerName')"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.employerName.controller.value"
                />
              </template>
            </text-field>
          </v-col>
          <v-col cols="12" md="4">
            <phone-input
              v-model="phoneWork"
              label="Office Phone"
              data-lpignore="true"
              data-testid="phone-work"
              :prepend-inner-icon="mdiDeskphone"
              :success="phoneWorkValidation.success"
              :error-messages="phoneWorkValidation.errorMessages"
              @update:model-value="updateAttribute('phoneWork')"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.phoneWork.controller.value"
                />
              </template>
            </phone-input>
          </v-col>
          <v-col cols="12">
            <basic-address-input
              v-model:street-address-model="employerAddress.street_address"
              v-model:city-model="employerAddress.city"
              v-model:state-model="employerAddress.state"
              v-model:zip-model="employerAddress.zip"
              autofill-label="Employer Address"
              autofill-placeholder="Employer Address"
              data-testid="employer"
              :street-address-validation="employerStreetValidation"
              :city-validation="employerCityValidation"
              :state-validation="employerStateValidation"
              :zip-validation="employerZipValidation"
              @update:street-address-model="updateAttribute('employerStreet')"
              @update:city-model="updateAttribute('employerCity')"
              @update:state-model="updateAttribute('employerState')"
              @update:zip-model="updateAttribute('employerZip')"
              @autofill="autofillEmployerAddress"
            >
              <template #append-inner-street-address>
                <active-save-indicator
                  :controller="savingBuffer.employerStreet.controller.value"
                />
              </template>
              <template #append-inner-city>
                <active-save-indicator
                  :controller="savingBuffer.employerCity.controller.value"
                />
              </template>
              <template #append-inner-state>
                <active-save-indicator
                  :controller="savingBuffer.employerState.controller.value"
                />
              </template>
              <template #append-inner-zip>
                <active-save-indicator
                  :controller="savingBuffer.employerZip.controller.value"
                />
              </template>
            </basic-address-input>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-title>Private Information</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6">
          <input-ssn
            v-model="ssn"
            required
            data-testid="ssn"
            :success="ssnValidation.success"
            :error-messages="ssnValidation.errorMessages"
            @update:model-value="updateAttribute('ssn')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.ssn.controller.value"
              />
            </template>
          </input-ssn>
        </v-col>
        <v-col cols="12" md="6">
          <date-input
            v-model="birthdate"
            label="Date of Birth"
            data-testid="birthdate"
            :prepend-inner-icon="mdiCalendar"
            :success="birthdateValidation.success"
            :error-messages="birthdateValidation.errorMessages"
            @update:model-value="updateAttribute('birthdate')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.birthdate.controller.value"
              />
            </template>
          </date-input>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="4">
          <currency-input
            v-model="income"
            include-decimals
            label="Income"
            data-testid="income"
            :decimal-length="0"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="incomeValidation.success"
            :error-messages="incomeValidation.errorMessages"
            @update:model-value="updateAttribute('income')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.income.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12" md="4">
          <currency-input
            v-model="assets"
            label="Assets"
            include-decimals
            data-testid="assets"
            :decimal-length="0"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="assetsValidation.success"
            :error-messages="assetsValidation.errorMessages"
            @update:model-value="updateAttribute('assets')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.assets.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12" md="4">
          <currency-input
            v-model="liabilities"
            label="Liabilities"
            include-decimals
            data-testid="liabilities"
            :decimal-length="0"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="liabilitiesValidation.success"
            :error-messages="liabilitiesValidation.errorMessages"
            @update:model-value="updateAttribute('liabilities')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.liabilities.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <text-field
            v-model="driversLicenseNumber"
            label="Drivers License Number"
            data-lpignore="true"
            data-testid="drivers-license-number"
            :prepend-inner-icon="mdiCardAccountDetails"
            :success="driversLicenseNumberValidation.success"
            :error-messages="driversLicenseNumberValidation.errorMessages"
            @update:model-value="updateAttribute('driversLicenseNumber')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.driversLicenseNumber.controller.value"
              />
            </template>
          </text-field>
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="driversLicenseState"
            label="Drivers License State"
            placeholder="Choose DL State"
            clearable
            data-testid="drivers-license-state"
            :items="STATES"
            :success="driversLicenseStateValidation.success"
            :error-messages="driversLicenseStateValidation.errorMessages"
            :prepend-inner-icon="mdiCardAccountDetailsOutline"
            @update:model-value="updateAttribute('driversLicenseState')"
            @click:clear="driversLicenseState = null"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.driversLicenseState.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="height"
            label="Height"
            data-testid="height"
            :items="HEIGHTS"
            :prepend-inner-icon="mdiHumanMaleHeight"
            :success="heightValidation.success"
            :error-messages="heightValidation.errorMessages"
            @update:model-value="updateAttribute('height')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.height.controller.value"
              />
            </template>
          </autocomplete-field>
        </v-col>
        <v-col cols="12" md="6">
          <integer-input
            v-model="weight"
            label="Weight"
            data-lpignore="true"
            mask="###"
            data-testid="weight"
            :prepend-inner-icon="mdiScale"
            :success="weightValidation.success"
            :error-messages="weightValidation.errorMessages"
            @update:model-value="updateAttribute('weight')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.weight.controller.value"
              />
            </template>
          </integer-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import InputSsn from "@/components/shared/InputSsn.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import DateInput from "@/components/shared/DateInput.vue";
import PhoneInput from "@/components/shared/PhoneInput.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import ChangeEmailDialog from "@/dialogs/ChangeEmailDialog.vue";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiAccountSupervisor,
  mdiBriefcase,
  mdiCalendar,
  mdiCardAccountDetails,
  mdiCardAccountDetailsOutline,
  mdiCurrencyUsd,
  mdiDeskphone,
  mdiEmail,
  mdiGenderMaleFemale,
  mdiHumanMaleHeight,
  mdiPhone,
  mdiRing,
  mdiScale
} from "@mdi/js";

import { GENDERS } from "@/data/genders";
import { STATES } from "@/data/states";
import { HEIGHTS } from "@/data/heights";
import { useActiveSave } from "@/composables/active-save.composable";
import { computedValidation, someTextValidator } from "@/util/helpers";
import { INDIVIDUAL_MARITAL_STATUSES } from "@/factories/ContractParty";
import { useContractPartyView } from "@/stores/contract-party-view";
import { storeToRefs } from "pinia";
import useVuelidate from "@vuelidate/core";
import { computed, markRaw } from "vue";
import { maritalStatusItems } from "@/data/quote-request-items";
import {
  phone as phoneValidator,
  ssn as ssnValidator
} from "@/util/vuelidateValidators";
import { useDialogStore } from "@/stores/dialog";

const contractPartyView = useContractPartyView();
const dialog = useDialogStore();
const {
  assets,
  birthdate,
  driversLicenseNumber,
  driversLicenseState,
  email,
  employerAddress,
  employerName,
  firstName,
  gender,
  height,
  homeAddress,
  income,
  lastName,
  liabilities,
  maritalStatus,
  middleName,
  phoneMobile,
  occupation,
  occupationId,
  phoneWork,
  ssn,
  weight
} = storeToRefs(contractPartyView);

const v$ = useVuelidate(
  {
    assets: {
      required: v => !v || v >= 0
    },
    birthdate: {
      required: v => Boolean(v)
    },
    driversLicenseNumber: {
      required: v => !v || someTextValidator(true, v, 2)
    },
    driversLicenseState: {
      required: v => !v || STATES.some(s => s.value === v)
    },
    email: {
      required: v => Boolean(v)
    },
    employerStreet: { required: v => !v || someTextValidator(true, v, 2) },
    employerCity: { required: v => !v || someTextValidator(true, v, 2) },
    employerState: { required: v => !v || STATES.some(s => s.value === v) },
    employerZip: { required: v => !v || `${v}`.length === 5 },
    employerName: {
      required: v => someTextValidator(true, v, 2)
    },
    firstName: {
      required: v => someTextValidator(true, v, 2)
    },
    gender: {
      required: v => !v || GENDERS.includes(v)
    },
    height: {
      required: v => !v || HEIGHTS.some(h => h.value === v)
    },
    homeStreet: { required: v => !v || someTextValidator(true, v, 2) },
    homeCity: { required: v => !v || someTextValidator(true, v, 2) },
    homeState: { required: v => !v || STATES.some(s => s.value === v) },
    homeZip: { required: v => !v || `${v}`.length === 5 },
    income: {
      required: v => !v || v >= 0
    },
    lastName: {
      required: v => someTextValidator(true, v, 2)
    },
    liabilities: {
      required: v => !v || v >= 0
    },
    maritalStatus: {
      required: v => !v || maritalStatusItems.includes(v)
    },
    middleName: {
      required: v => !v || someTextValidator(true, v, 1)
    },
    phoneMobile: {
      required: v => !v || phoneValidator(v)
    },
    occupation: {
      required: v => !v || someTextValidator(true, v, 2)
    },
    phoneWork: {
      required: v => !v || phoneValidator(v)
    },
    ssn: {
      required: v => !v || ssnValidator(v)
    },
    weight: {
      required: v => !v || v >= 0
    }
  },
  {
    assets,
    birthdate,
    driversLicenseNumber,
    driversLicenseState,
    email,
    employerName,
    employerStreet: computed(() => employerAddress.value?.street_address),
    employerCity: computed(() => employerAddress.value?.city),
    employerState: computed(() => employerAddress.value?.state),
    employerZip: computed(() => employerAddress.value?.zip),
    homeStreet: computed(() => homeAddress.value?.street_address),
    homeCity: computed(() => homeAddress.value?.city),
    homeState: computed(() => homeAddress.value?.state),
    homeZip: computed(() => homeAddress.value?.zip),
    firstName,
    gender,
    height,
    income,
    lastName,
    liabilities,
    maritalStatus,
    middleName,
    phoneMobile,
    occupation,
    phoneWork,
    ssn,
    weight
  },
  { $autoDirty: true, $scope: null }
);

const savingBuffer = {
  assets: useActiveSave(),
  birthdate: useActiveSave(),
  driversLicenseNumber: useActiveSave(),
  driversLicenseState: useActiveSave(),
  employerCity: useActiveSave(),
  employerName: useActiveSave(),
  employerState: useActiveSave(),
  employerStreet: useActiveSave(),
  employerZip: useActiveSave(),
  firstName: useActiveSave(),
  gender: useActiveSave(),
  height: useActiveSave(),
  homeCity: useActiveSave(),
  homeState: useActiveSave(),
  homeStreet: useActiveSave(),
  homeZip: useActiveSave(),
  income: useActiveSave(),
  lastName: useActiveSave(),
  liabilities: useActiveSave(),
  maritalStatus: useActiveSave(),
  middleName: useActiveSave(),
  phoneMobile: useActiveSave(),
  occupation: useActiveSave(),
  phoneWork: useActiveSave(),
  ssn: useActiveSave(),
  weight: useActiveSave()
};

const assetsValidation = computedValidation(v$.value.assets, {
  required: "Required"
});
const birthdateValidation = computedValidation(v$.value.birthdate, {
  required: "Must be a valid birthdate"
});
const driversLicenseNumberValidation = computedValidation(
  v$.value.driversLicenseNumber,
  { required: "Required" }
);
const driversLicenseStateValidation = computedValidation(
  v$.value.driversLicenseState,
  { required: "Required" }
);
const emailValidation = computedValidation(v$.value.email, {
  required: "Must be a valid email"
});
const employerCityValidation = computedValidation(v$.value.employerCity, {
  required: "Required"
});
const employerNameValidation = computedValidation(v$.value.employerName, {
  required: "Required"
});
const employerStateValidation = computedValidation(v$.value.employerState, {
  required: "Required"
});
const employerStreetValidation = computedValidation(v$.value.employerStreet, {
  required: "Required"
});
const employerZipValidation = computedValidation(v$.value.employerZip, {
  required: "Required"
});
const firstNameValidation = computedValidation(v$.value.firstName, {
  required: "Required"
});
const genderValidation = computedValidation(v$.value.gender, {
  required: "Required"
});
const heightValidation = computedValidation(v$.value.height, {
  required: "Required"
});
const homeCityValidation = computedValidation(v$.value.homeCity, {
  required: "Required"
});
const homeStateValidation = computedValidation(v$.value.homeState, {
  required: "Required"
});
const homeStreetValidation = computedValidation(v$.value.homeStreet, {
  required: "Required"
});
const homeZipValidation = computedValidation(v$.value.homeZip, {
  required: "Required"
});
const incomeValidation = computedValidation(v$.value.income, {
  required: "Required"
});
const lastNameValidation = computedValidation(v$.value.lastName, {
  required: "Required"
});
const liabilitiesValidation = computedValidation(v$.value.liabilities, {
  required: "Required"
});
const maritalStatusValidation = computedValidation(v$.value.maritalStatus, {
  required: "Required"
});
const middleNameValidation = computedValidation(v$.value.middleName, {
  required: "Required"
});
const phoneMobileValidation = computedValidation(v$.value.phoneMobile, {
  required: "Must be a valid phone number"
});
const occupationValidation = computedValidation(v$.value.occupation, {
  required: "Required"
});
const phoneWorkValidation = computedValidation(v$.value.phoneWork, {
  required: "Must be a valid phone number"
});
const ssnValidation = computedValidation(v$.value.ssn, {
  required: "Must be a valid SSN"
});
const weightValidation = computedValidation(v$.value.weight, {
  required: "Required"
});

function autofillHomeAddress({ street_address, city, state, zip }) {
  homeAddress.value.street_address = street_address;
  homeAddress.value.city = city;
  homeAddress.value.state = state;
  homeAddress.value.zip = zip;
  savingBuffer.homeStreet.debounceUpdate(() =>
    contractPartyView.updateIndividualAttribute("homeAddress")
  );
}

function autofillEmployerAddress({ street_address, city, state, zip }) {
  employerAddress.value.street_address = street_address;
  employerAddress.value.city = city;
  employerAddress.value.state = state;
  employerAddress.value.zip = zip;
  savingBuffer.employerStreet.debounceUpdate(() =>
    contractPartyView.updateIndividualAttribute("employerAddress")
  );
}

function updateAttribute(attribute) {
  savingBuffer[attribute].debounceUpdate(async () => {
    const isValid = await v$.value[attribute].$validate();
    if (!isValid) return;
    return contractPartyView.updateIndividualAttribute(attribute);
  });
}

async function showChangeEmailDialog() {
  dialog.showDialog({
    component: markRaw(ChangeEmailDialog),
    saveFunc: () => contractPartyView.updateIndividualAttribute("email"),
    storeFunc: v => (email.value = v),
    modelValue: email.value
  });
}
</script>
