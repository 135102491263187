<template>
  <v-card :id="listId" width="100%" flat tile style="vertical-align: top">
    <v-card-title
      class="font-weight-light pa-2"
      :data-testid="`${props.leadType}-title`"
    >
      {{ props.leadType }}
      <v-fade-transition mode="out-in">
        <v-progress-circular
          v-if="loadingMeta"
          key="loading"
          indeterminate
          class="ml-1"
          size="16"
          width="2"
          color="grey"
        />
        <span
          v-else
          key="loaded"
          class="ml-1 text-grey"
          style="font-size: 16px"
        >
          (<number-formatter :model-value="table.meta.value.total" />)
        </span>
      </v-fade-transition>
    </v-card-title>
    <v-card-subtitle
      class="font-weight-light pa-2"
      :data-testid="`${props.leadType}-subtitle`"
    >
      {{ subtitle }}
    </v-card-subtitle>
    <v-divider />
    <div class="pa-2">
      <app-button
        class="text-none"
        color="primary"
        block
        :data-testid="`${props.leadType}-sort`"
        @click="invertSort"
      >
        {{ sortName }}
        <v-fade-transition mode="out-in">
          <v-icon
            v-if="sortDesc"
            key="desc"
            class="ml-3"
            data-testid="desc"
            :icon="mdiSortDescending"
          />
          <v-icon
            v-else
            key="asc"
            class="ml-3"
            data-testid="asc"
            :icon="mdiSortAscending"
          />
        </v-fade-transition>
      </app-button>
    </div>
    <v-divider />
    <v-fade-transition mode="out-in">
      <v-card-text v-if="loadingMeta" key="loader">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate />
        </v-row>
      </v-card-text>
      <v-card-text
        v-else
        key="draglist"
        class="pa-0"
        style="overflow-y: scroll; height: calc(100vh - 305px)"
      >
        <draggable
          v-if="isDraggable"
          style="min-height: calc(100% - 60px)"
          group="leads"
          :model-value="cards"
          :sort="false"
          item-key="eAppId"
          @change="updateList"
        >
          <template #item="{ element }">
            <div :data-testid="`${props.leadType}-item-draggable`">
              <electronic-application-pipeline-item
                class="ma-2"
                :data-testid="`${props.leadType}-item`"
                :show-stats="props.showStats"
                :eapp="element"
                :lead-type="props.leadType"
              />
            </div>
          </template>
        </draggable>
        <div v-else style="min-height: calc(100% - 60px)">
          <electronic-application-pipeline-item
            v-for="card in cards"
            :key="card.eAppId"
            class="ma-2"
            :data-testid="`${props.leadType}-item`"
            :show-stats="props.showStats"
            :eapp="card"
            :lead-type="props.leadType"
          />
        </div>
        <div v-if="canLoadMore" class="pa-2">
          <app-button
            variant="outlined"
            block
            color="primary"
            class="text-none"
            :data-testid="`${props.leadType}-load-more`"
            :loading="table.loading.value"
            @click="loadMore"
          >
            Load More
          </app-button>
        </div>
      </v-card-text>
    </v-fade-transition>
  </v-card>
</template>

<script setup>
import ElectronicApplicationPipelineItem from "@/components/electronic-applications/ElectronicApplicationPipelineItem.vue";
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import draggable from "vuedraggable";
import { getElectronicApplications } from "@/api/electronic-application.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, watch, toRefs } from "vue";
import { useTable } from "@/composables/table.composable";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { mdiSortAscending, mdiSortDescending } from "@mdi/js";

const props = defineProps({
  externalFilter: {
    type: Object,
    required: true
  },
  headers: {
    type: Array,
    required: true
  },
  leadType: {
    type: String,
    validator: v =>
      ["New", "Contacted", "Sold", "Inactive", "Awaiting Signatures"].includes(
        v.toString()
      ),
    required: true
  },
  showStats: Boolean,
  isDraggable: Boolean,
  cards: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(["new-items", "reset-items", "removed", "added"]);

const listId = `${props.leadType}-list`;
const canLoadMore = ref(false);

const { leadList } = storeToRefs(useTableStore());
const sortDesc = ref(leadList.value.options[props.leadType]);
const loadingMeta = ref(false);
const subtitle = {
  New: "New eApps appear here",
  Sold: "Leads that are sold",
  Contacted: "Leads you're actively working on",
  Inactive: "Leads that are cold",
  "Awaiting Signatures": "Leads that are awaiting signatures"
}[props.leadType];

const sortName = {
  New: "Created",
  Contacted: "Follow Up",
  Sold: "Completed",
  Inactive: "Updated",
  "Awaiting Signatures": "Updated"
}[props.leadType];

function loadMore() {
  table.options.value.page++;
  getData();
}

function typeToSortFilter(type, params, sort) {
  function newReq(params, sort) {
    params["lead_status"] = "New";
    params["filter[electronic_applications.status][]"] = ["Lead"];
    params["sorting[electronic_applications.created_at]"] = sort;
  }

  function contactedReq(params, sort) {
    params["lead_status"] = "Active";
    params["filter[electronic_applications.status][]"] = ["Lead"];
    params["sorting[follow_up_at]"] = sort;
  }

  function awaitingSignaturesReq(params, sort) {
    params["filter[electronic_applications.status][]"] = ["Sent", "Delivered"];
    params["sorting[updated_at]"] = sort;
  }

  function soldReq(params, sort) {
    params["is_completed"] = true;
    params["sorting[completed_at]"] = sort;
  }

  function inactiveReq(params, sort) {
    params["lead_status"] = "Inactive";
    params["filter[electronic_applications.status][]"] = [
      "Lead",
      "Sent",
      "Delivered"
    ];
    params["sorting[updated_at]"] = sort;
  }

  return {
    New: newReq,
    Contacted: contactedReq,
    "Awaiting Signatures": awaitingSignaturesReq,
    Sold: soldReq,
    Inactive: inactiveReq
  }[type](params, sort);
}

function additionalFilter() {
  const params = { view: "my", stats: false };
  const sort = sortDesc.value ? "desc" : "asc";

  typeToSortFilter(props.leadType, params, sort);

  return params;
}

const table = useTable({
  getData: (p, ct) => getElectronicApplications(p, ct),
  options: TableOptions({ itemsPerPage: 25 }),
  headers: props.headers,
  shouldIncludeCancelToken: true
});

const { externalFilter, cards } = toRefs(props);

watch(
  externalFilter,
  async () => {
    table.filter.value = externalFilter.value;
    loadingMeta.value = true;
    await init();
    loadingMeta.value = false;
  },
  { immediate: true, deep: true }
);

async function getData() {
  try {
    await table.getData(additionalFilter());
    emit("new-items", table.items.value);
    canLoadMore.value = cards.value.length < table.meta.value.total;
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
async function init() {
  emit("reset-items");
  table.options.value.page = 1;
  await getData();
}
function invertSort() {
  sortDesc.value = !sortDesc.value;
  leadList.value.options[props.leadType] = sortDesc.value;
  init();
}
function updateList(event) {
  if (event.added) handleAdded(event.added);
  if (event.removed) handleRemoved(event.removed);
}
function handleRemoved({ element, oldIndex }) {
  emit("removed", { element, index: oldIndex });
}
function handleAdded({ element, newIndex }) {
  emit("added", { element, index: newIndex });
}
</script>
