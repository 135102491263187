<template>
  <v-card>
    <v-card-title>Assign new Commissions </v-card-title>
    <v-card-text>
      <npn-input
        v-model="newNpn"
        autofocus
        data-testid="assign-npn"
        :success="Boolean(newNpn)"
        :disabled="loading"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="assign-search"
        :loading="loading"
        @click="npnSearch"
      >
        <v-icon class="mr-1" :icon="mdiBriefcaseSearch" /> Search
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import NpnInput from "@/components/shared/NpnInput.vue";

import { parseErrorMessage } from "@/util/helpers";
import { npnVerify } from "@/api/advisors.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import {  ref } from "vue";
import { mdiBriefcaseSearch } from "@mdi/js";
const props = defineProps({
  type: { type: String, default: null }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const agent = useAgentSettingsStore();

const newNpn = ref("");
const loading = ref(false);

async function npnSearch() {
  if (loading.value) return;

  const agency = Object.values(agent.agencies).find(
    val => +val.npn === +newNpn.value
  );
  if (agency) {
    return dialog.closeDialog({
      valid: true,
      loginable: agency,
      npn: newNpn.value
    });
  }

  loading.value = true;
  try {
    const { alreadyExists, loginable } = await npnVerify({
      npn: newNpn.value,
      type: props.type,
      loginable_type: "Agent",
      loginable_id: agent.id
    });
    dialog.closeDialog({ alreadyExists, loginable, npn: newNpn.value });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: 300 });
  } finally {
    loading.value = false;
  }
}
</script>
