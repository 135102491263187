<template>
  <v-data-table
    class="transparent-data-table"
    item-value="additional.id"
    data-testid="alias-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    :loading="table.loading.value"
  >
    <template #top>
      <v-row class="ma-0">
        <v-spacer />
        <app-button
          :icon="mdiRefresh"
          :disabled="table.loading.value"
          variant="text"
          density="comfortable"
          class="table-btn"
          data-testid="refresh"
          @click="getData"
        />
        <app-button
          :icon="mdiPlus"
          variant="text"
          density="comfortable"
          class="table-btn"
          data-testid="create"
          @click="createNewAlias"
        />
      </v-row>
    </template>
    <template #expanded-row="{ item, columns }">
      <td :colspan="columns.length">
        <v-row class="ma-0">
          <v-col cols="12" data-testid="included-states">
            Included States:
            {{ listToSentence(item.additional.includedStates) }}
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12" data-testid="excluded-states">
            Excluded States
            {{ listToSentence(item.additional.excludedStates) }}
          </v-col>
        </v-row>
      </td>
    </template>
    <template
      #[`item.data-table-expand`]="{
        item,
        isExpanded,
        toggleExpand,
        internalItem
      }"
    >
      <app-button
        :icon="mdiPencil"
        color="accent"
        variant="text"
        density="comfortable"
        data-testid="edit"
        @click="editAlias(item)"
      />
      <app-button
        :icon="mdiDelete"
        color="error"
        variant="text"
        density="comfortable"
        data-testid="delete"
        @click="destroyAlias(item)"
      />

      <app-button
        :icon="isExpanded(internalItem) ? mdiChevronUp : mdiChevronDown"
        data-testid="expand"
        size="small"
        density="comfortable"
        variant="text"
        @click="toggleExpand(internalItem)"
      />
    </template>
  </v-data-table>
</template>
<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import AliasCreateOrEditDialog from "@/components/products/dialogs/AliasCreateOrEditDialog.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import {
  mdiRefresh,
  mdiPlus,
  mdiPencil,
  mdiDelete,
  mdiChevronUp,
  mdiChevronDown
} from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { parseErrorMessage, listToSentence } from "@/util/helpers";

import { deleteAlias, getProductAliases } from "@/api/products.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";

const props = defineProps({ id: { type: Number, required: true } });

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  getData: () => getProductAliases(props.id),
  headers: [
    new TableHeader({
      text: "Engine",
      value: "engine",
      map: "engine",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Parameters",
      value: "parameters",
      map: "parameters",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "data-table-expand",
      width: "120px"
    })
  ]
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
async function createNewAlias() {
  const res = await dialog.showDialog({
    component: markRaw(AliasCreateOrEditDialog),
    productId: props.id
  });
  if (res?.alias) getData();
}

async function editAlias(item) {
  const res = await dialog.showDialog({
    component: markRaw(AliasCreateOrEditDialog),
    modelValue: item.additional,
    productId: props.id
  });
  if (!res?.alias) return;
  getData();
}
async function destroyAlias(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Delete Alias",
    subtitle: "Please confirm your intent ",
    func: async () => {
      await deleteAlias(props.id, item.additional.id);
      getData();
    }
  });
}

getData();
</script>
