export function InsuranceLicense(model = {}) {
  return {
    active: model?.active,
    effectiveDate: model?.effectiveDate || null,
    expirationDate: model?.expirationDate || null,
    number: model?.number || null,
    state: model?.state || null,
    createdAt: model?.createdAt || null,
    updatedAt: model?.updatedAt || null,
    types: model?.types || [],
    availableForQuoteAndApply: model?.availableForQuoteAndApply || false,
    typeText: model?.typeText || ""
  };
}

export function setInsuranceLicenseFromRequest(raw = {}) {
  const license = InsuranceLicense();
  license.active = Boolean(raw?.active);
  license.effectiveDate = raw?.effective_date;
  license.expirationDate = raw?.expiration_date;
  license.number = raw?.number;
  license.state = raw?.state;
  license.createdAt = raw?.created_at;
  license.updatedAt = raw?.updated_at;
  license.types = raw?.lines_of_authority?.summary;
  license.availableForQuoteAndApply = raw?.strife_usable;
  if (Array.isArray(license.types)) license.typeText = license.types.join(", ");
  return license;
}
