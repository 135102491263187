<template>
  <v-card>
    <v-card-title>
      <v-row class="ma-0" align="center">
        Add Party
        <v-spacer />
        <v-tabs
          v-model="activeTab"
          show-arrows
          right
          style="width: unset"
          color="primary"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.text"
            class="text-none"
            :data-testid="`add-${tab.text}`"
          >
            <v-icon class="mr-1" :icon="tab.icon" />
            {{ tab.text }}
          </v-tab>
        </v-tabs>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-tabs-window v-model="activeTab" touchless>
        <v-tabs-window-item v-for="tab in tabs" :key="tab.text + 'body'">
          <component
            :is="tab.component"
            :ref="v => tab.ref = v"
            :case-id="props.caseId"
            :insured-address="props.insuredAddress"
            :line="props.line"
          />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        data-testid="add-party-create"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="createRole"
      >
        Create Role
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import PreexistingParty from "@/components/cases/case-dialogs/PreexistingParty.vue";
import NewParty from "@/components/cases/case-dialogs/NewParty.vue";

import { useDialogStore } from "@/stores/dialog";
import { markRaw, ref } from "vue";
import { mdiAccountPlus, mdiAccountSearch } from "@mdi/js";

const props = defineProps({
  caseId: {
    type: Number,
    required: false,
    default: null
  },
  insuredAddress: {
    type: Object,
    required: false,
    default: () => null
  },
  line: {
    type: String,
    required: true
  }
});

const dialog = useDialogStore();

const loading = ref(false);
const activeTab = ref(0);

const tabs = ref([
  {
    text: "Pre-Existing Party",
    icon: mdiAccountSearch,
    ref: null,
    component: markRaw(PreexistingParty)
  },
  {
    text: "New Party",
    icon: mdiAccountPlus,
    ref: null,
    component: markRaw(NewParty)
  }
]);

// refs are arrays in vue 2 within a v-for
async function createRole() {
  const ref = tabs.value[activeTab.value]?.ref;
  if (!ref?.create) return;
  loading.value = true;
  const result = await ref.create();
  if (result) dialog.closeDialog({ party: result });
  loading.value = false;
}
</script>
