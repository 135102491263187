<template>
  <iframe
    sandbox="allow-same-origin"
    :style="{ height }"
    :srcdoc="props.modelValue"
    @load="height = $event.target.contentWindow.document.body.scrollHeight"
  />
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  modelValue: { type: String, required: true }
});
const height = ref(0);
</script>
