export function Article(model = {}) {
  return {
    author: model?.author || "",
    avatar: model?.avatar || "",
    content: model?.content || "",
    createdAt: model?.createdAt || new Date(),
    updatedAt: model?.updatedAt || new Date(),
    link: model?.link || "",
    name: model?.name || "",
    id: model?.id || 0,
    published: model?.published || false,
    tags: model?.tags || [],
    timeToRead: 0,
    minimumGroup: null
  };
}

const calculateTimeToRead = content => {
  if (!content) return 0;
  const wordCount = content.trim().split(/\s+/).length;
  const AVERAGE_WPM = 200;
  const calculatedMinutes = wordCount / AVERAGE_WPM;
  return Math.max(calculatedMinutes, 1).toFixed(0);
};

export function setArticleFromRequest(raw = {}) {
  const model = Article();
  model.id = raw?.id || 0;
  model.author = raw?.author || "";
  model.avatar = raw?.avatar_url || "";
  model.content = raw?.content || "";
  model.createdAt = new Date(raw?.created_at);
  model.updatedAt = new Date(raw?.updated_at);
  model.link = raw?.link || "";
  model.name = raw?.name || "";
  model.published = raw?.published;
  model.tags = raw.tags || [];
  model.timeToRead = calculateTimeToRead(raw.content);
  model.minimumGroup = raw?.minimum_group || null;
  return model;
}
