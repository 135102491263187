<template>
  <v-card flat class="pa-2 mb-5">
    <v-row class="px-6 py-3" align="center">
      <h3 style="text-align: left; height: 20px">
        <v-row class="pa-3" align="center"> Field Options </v-row>
      </h3>
      <v-spacer />
      <app-button
        v-if="!readonly"
        :icon="mdiPlus"
        variant="text"
        density="comfortable"
        data-testid="add-field-option"
        @click="addFieldOption"
      />
    </v-row>
    <transition-group name="flip-list" mode="out-in" tag="div" class="row">
      <v-col
        v-for="(fieldOption, index) in options"
        :key="fieldOption.uuid"
        cols="12"
        :order="fieldOption.order"
      >
        <mapped-form-view-aql-field-option
          v-model:text="fieldOption.text"
          v-model:value="fieldOption.value"
          v-model:virtual="fieldOption.virtual"
          :form-id="formId"
          :validation-scope="validationScope"
          :focused="currentCoordinateIndex === index"
          @click="currentCoordinateIndex = index"
          @delete="deleteOption(index)"
          @update="emit('update:model-value')"
          @update:virtual="handleVirtual(index, $event)"
        />
      </v-col>
    </transition-group>
  </v-card>
</template>

<script setup>
import { mdiPlus } from "@mdi/js";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import {
  AqlField,
  AqlFieldOption,
  RADIO_OPTION_DIAMETER,
  RADIO_OPTION_TYPE,
  CHECKBOX_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  CHECKBOX_SIDE_LENGTH
} from "@/factories/FormMapping";

import MappedFormViewAqlFieldOption from "@/components/form-mapping/MappedFormViewAqlFieldOption.vue";
import { computed } from "vue";

const props = defineProps({
  formId: { type: [String, Number], required: true },
  validationScope: { type: String, required: true }
});

const emit = defineEmits(["update:model-value"]);

const formStore = useMappedFormStore(props.formId);
const { currentCoordinateIndex, activeField, currentPage, viewport, readonly } =
  storeToRefs(formStore);

const useOptions = computed(
  () => activeField.value.pdfFieldType === TEXT_OPTION_TYPE
);

const options = computed({
  get() {
    if (useOptions.value) {
      return activeField.value.coordinates[0].options;
    }
    return activeField.value.coordinates;
  },
  set(value) {
    if (useOptions.value) {
      activeField.value.coordinates[0].options = value;
    } else {
      activeField.value.coordinates = value;
    }
  }
});

function validateRadio() {
  const coordinates = activeField.value.coordinates;
  if (coordinates.length !== 2) return false;

  const yesOption = coordinates.find(({ value }) => value === "1");
  const noOption = coordinates.find(({ value }) => value === "0");

  return Boolean(yesOption && noOption);
}

function initializeRadioOptions() {
  if (useOptions.value) {
    activeField.value.coordinates[0].options.splice(
      0,
      activeField.value.coordinates[0].options.length
    );
  } else {
    activeField.value.coordinates.splice(
      0,
      activeField.value.coordinates.length
    );
  }
  addFieldOption({ value: "1", text: "Yes" });
  addFieldOption({ value: "0", text: "No" });
}

function handleVirtual(index, value) {
  if (value) return;
  const fieldOption = activeField.value.coordinates[index];
  if (fieldOption.x) return;
  const copyFromIndex = index === 0 ? 1 : index - 1;
  const copyFrom = activeField.value.coordinates[copyFromIndex];
  activeField.value.coordinates[index].x =
    copyFrom.x + RADIO_OPTION_DIAMETER + 5;

  activeField.value.coordinates[index].y = copyFrom.y;
  activeField.value.coordinates[index].page = copyFrom.page;
}

function addFieldOption({ text = null, value = null }) {
  if (useOptions.value) {
    activeField.value.coordinates[0].options.push(
      new AqlFieldOption({ text, value })
    );

    return;
  }
  const optionCount = activeField.value.coordinates?.length;

  const aqlField = new AqlField({
    isFieldOption: true,
    virtual: ![CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(
      activeField.value.pdfFieldType
    ),
    text,
    value
  });
  if (
    [CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(
      activeField.value.pdfFieldType
    )
  ) {
    aqlField.x = 10;
    aqlField.y = viewport.value.height - 10;
    aqlField.page = currentPage.value;
    if (optionCount) {
      const { coordinates } = activeField.value;
      const lastPositionedCoordinate = [...coordinates]
        .reverse()
        .find(({ virtual }) => !virtual);

      let shapeWidth = RADIO_OPTION_DIAMETER;
      if (activeField.value.pdfFieldType === CHECKBOX_OPTION_TYPE) {
        shapeWidth = CHECKBOX_SIDE_LENGTH;
      }

      if (lastPositionedCoordinate) {
        aqlField.x = lastPositionedCoordinate.x + shapeWidth + 5;
        aqlField.y = lastPositionedCoordinate.y;
        aqlField.page = lastPositionedCoordinate.page;
      }
    }
  }

  activeField.value.coordinates.push(aqlField);
  currentCoordinateIndex.value = activeField.value.coordinates.length - 1;

  emit("update:model-value");
}

function deleteOption(index) {
  if (useOptions.value) {
    activeField.value.coordinates[0].options.splice(index, 1);
  } else {
    activeField.value.coordinates.splice(index, 1);
    currentCoordinateIndex.value = activeField.value.coordinates.length - 1;
  }
  emit("update:model-value");
}

if ([RADIO_OPTION_TYPE].includes(activeField.value.quoteAndApplyType)) {
  const isValid = validateRadio();
  if (!isValid) initializeRadioOptions();
}
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
</style>
