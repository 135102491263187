<template>
  <autocomplete-field
    v-bind="$attrs"
    ref="searchInput"
    v-model="policy"
    v-model:search="search"
    autocomplete="false"
    return-object
    no-filter
    hide-no-data
    item-title="policyNumber"
    :data-testid="dataTestid"
    :label="label"
    :placeholder="placeholder"
    :prepend-inner-icon="mdiPound"
    :items="policies"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
  />
</template>

<script setup>
import { ref, watch, toRef } from "vue";
import { mdiPound } from "@mdi/js";
import {
  policyNumberSearch,
  simplePolicyNumberSearch
} from "@/api/search.service";
import { searchCaseTransactions } from "@/api/transactions.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

const props = defineProps({
  modelValue: { type: Object, required: false, default: () => {} },
  transaction: Boolean,
  simple: Boolean,
  dataTestid: { type: String, required: false, default: null },
  label: { type: String, required: false, default: "Policy Number" },
  placeholder: {
    type: String,
    required: false,
    default: "Search Policy Numbers"
  }
});
const modelValue = toRef(props, "modelValue");

const snackbar = useSnackbarStore();
const emit = defineEmits(["update:model-value", "blur"]);

const policies = ref([]);
const policy = ref(null);
const search = ref("");
const loading = ref(false);

if (modelValue.value?.id) {
  policies.value.push(modelValue.value);
  policy.value = modelValue.value;
}

watch(modelValue, () => {
  policies.value.splice(0, policies.value.length);

  if (!modelValue.value?.id) {
    policy.value = null;
    return;
  }
  policy.value = modelValue.value;
  policies.value.push(modelValue.value);
});

watch(policy, () => {
  if (policy.value?.id === modelValue.value?.id) return;
  emit("update:model-value", policy.value);
});

watch(search, debounceAndSearch);

let policyTimer;
function debounceAndSearch() {
  if (policyTimer) clearTimeout(policyTimer);
  policyTimer = setTimeout(searchPolicies, 200);
}

async function searchPolicies() {
  if (!search.value) return;

  if (
    policies.value.length === 1 &&
    policies.value[0].policyNumber === search.value
  ) {
    return;
  }

  let method = policyNumberSearch;
  if (props.transaction) {
    method = v => searchCaseTransactions({ policy_number: v });
  } else if (props.simple) {
    method = simplePolicyNumberSearch;
  }

  try {
    loading.value = true;

    const response = await method(search.value);
    policies.value.splice(0, policies.value.length);
    policies.value.push(...response);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const searchInput = ref(null); //templateref

function focus() {
  if (!searchInput.value?.$el) return;
  const input = searchInput.value.$el.querySelector("input");
  if (!input?.focus) return;
  input.focus();
}

defineExpose({ focus });
</script>
