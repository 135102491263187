<template>
  <v-card class="ma-1">
    <v-card-text>
      <v-expansion-panels class="mb-3">
        <v-expansion-panel>
          <v-expansion-panel-title eager>
            Category States
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <form-mapping-category-states-table />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <form-mapping-forms-table />
    </v-card-text>
  </v-card>
</template>

<script setup>
import FormMappingCategoryStatesTable from "@/components/form-mapping/FormMappingCategoryStatesTable.vue";
import FormMappingFormsTable from "@/components/form-mapping/FormMappingFormsTable.vue";
import { useHead } from "@unhead/vue";

useHead({ title: "Forms" });
</script>
