<template>
  <v-row class="ma-0" align="center" justify="start">
    <v-alert v-if="errorMessage" type="info" variant="outlined">
      {{ errorMessage }}
    </v-alert>
    <div v-else :id="props.canvasParentId">
      <slot name="explanation-text" />
    </div>
  </v-row>
</template>

<script setup>
import {
  computed,

  nextTick,
  onMounted,
  ref,
  toRefs,
  watch
} from "vue";
import { createHighDPICanvas } from "@/util/helpers";
const props = defineProps({
  canvasParentId: {
    type: String,
    required: true
  },
  scale: {
    type: Number,
    required: true
  },
  buttonText: {
    type: String,
    required: true
  },
  primaryColor: {
    type: String,
    required: true
  },
  accentColor: {
    type: String,
    required: true
  },
  innerButtonTextColor: {
    type: String,
    required: true
  },
  outerButtonTextColor: {
    type: String,
    required: true
  },
  fontSize: {
    type: Number,
    required: true
  }
});

const {
  canvasParentId,
  scale,
  buttonText,
  primaryColor,
  accentColor,
  innerButtonTextColor,
  outerButtonTextColor,
  fontSize
} = toRefs(props);

const circleRadius = ref(45);
const textWidth = ref(0);
const textHeight = ref(0);
const CIRCLE_RATIO = 0.7;

const canvasId = `${canvasParentId.value}-canvas`;

const widthCalcStage = ref(true);

const text = computed(() => buttonText.value.toUpperCase());

const errorMessage = computed(() => {
  if (buttonText.value.split(" ")[0].length > 6)
    return "The first word may not exceed 6 characters for this style";
  if (buttonText.value.length > 16)
    return "The total phrase may not exceed 16 characters (including spaces) for this style";
  return null;
});

function generateButton() {
  const currentCanvas = document.getElementById(canvasId);
  if (currentCanvas) currentCanvas.remove();

  const div = document.getElementById(canvasParentId.value);
  if (!div) return;

  const radius = circleRadius.value;
  const height = radius * 2.1;
  const textOffsetY = Math.floor(height / 2 + textHeight.value / 2);

  const textOffsetX = radius / 4;
  const width = textWidth.value + textOffsetY;

  const canvas = createHighDPICanvas(
    Math.ceil(width * scale.value),
    Math.ceil(height * scale.value)
  );
  canvas.id = canvasId;
  const ctx = canvas.getContext("2d");
  ctx.scale(scale.value, scale.value);
  div.prepend(canvas);

  if (widthCalcStage.value) {
    const firstWord = text.value.split(" ")[0];
    const { width: firstWordWidth, height: wordHeight } = getTextSize(
      ctx,
      firstWord,
      fontSize.value
    );
    circleRadius.value = Math.max(
      Math.ceil(wordHeight * CIRCLE_RATIO),
      Math.ceil(firstWordWidth * CIRCLE_RATIO)
    );

    const { width: fullWidth, height: fullHeight } = getTextSize(
      ctx,
      text.value,
      fontSize.value
    );
    textWidth.value = Math.ceil(fullWidth);
    textHeight.value = Math.ceil(fullHeight);
    widthCalcStage.value = false;
    return generateButton();
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  drawAdditionalAccent(ctx, radius, width);
  drawCircleAccent(ctx, radius, width);

  const [firstWord, ...rest] = text.value.split(" ");

  drawText({
    ctx,
    text: firstWord,
    textOffsetX,
    textOffsetY,
    fontSize: fontSize.value,
    textColor: innerButtonTextColor.value
  });
  drawText({
    ctx,
    text: rest.join(" "),
    textOffsetX: radius * 2,
    textOffsetY,
    fontSize: fontSize.value,
    textColor: outerButtonTextColor.value
  });

  widthCalcStage.value = true;
}

function getTextSize(ctx, text, fontSize) {
  ctx.font = `${fontSize}px Roboto, Helvetica, Arial, sans-serif`;
  const dims = ctx.measureText(text);
  return {
    width: dims.width,
    height: Math.trunc(dims.actualBoundingBoxAscent)
  };
}

function drawCircleAccent(ctx, radius, width) {
  ctx.save();
  ctx.fillStyle = accentColor.value;
  ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
  ctx.fill();
  ctx.clip();

  ctx.fillStyle = primaryColor.value;
  ctx.fillRect(radius * 2 * 0.6, radius * 2 * 0.66, width, radius * 0.1);
  ctx.restore();
}

function drawAdditionalAccent(ctx, radius, width) {
  ctx.fillStyle = "#ececec";
  ctx.fillRect(
    radius * 2 * 0.66,
    radius * 2 * 0.66,
    width - radius * 2.25 * CIRCLE_RATIO,
    radius * 0.1
  );
}

function drawText({
  ctx,
  text,
  textOffsetX,
  textOffsetY,
  fontSize,
  textColor
}) {
  ctx.fillStyle = textColor;
  ctx.font = `bold ${fontSize}px Helvetica, Arial, sans-serif`;
  ctx.textAlign = "left";
  ctx.fillText(text, textOffsetX, textOffsetY);
}

watch(
  [
    scale,
    buttonText,
    primaryColor,
    accentColor,
    innerButtonTextColor,
    outerButtonTextColor,
    fontSize
  ],
  generateButton
);
watch(errorMessage, () => nextTick(generateButton));
onMounted(generateButton);
</script>
