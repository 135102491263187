<template>
  <v-card class="preliminary-card">
    <v-form @submit.prevent="signIn">
      <v-col>
        <v-row class="ma-0" align="center" justify="center">
          <app-logo large />
        </v-row>
      </v-col>
      <v-card-title class="justify-center font-weight-bold text-h5">
        2FA Confirmation
      </v-card-title>
      <v-card-text>
        <div>
          <text-field
            v-model="code"
            label="Verification Code"
            persistent-hint
            autofocus
            inputmode="numeric"
            data-testid="two-factor"
            hint="Please enter the verification code generated by your authenticator app"
            :prepend-inner-icon="mdiTwoFactorAuthentication"
            :success="Boolean(code)"
            :disabled="cancelling || loading"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row dense justify="center">
          <v-col cols="12">
            <app-button
              class="text-none"
              color="primary"
              type="submit"
              data-testid="submit-two-factor"
              :loading="loading"
              :disabled="cancelling"
            >
              Sign in
            </app-button>
          </v-col>
          <v-col cols="12">
            <app-button
              class="text-none"
              variant="text"
              data-testid="cancel-auth"
              :loading="cancelling"
              @click="cancelAuthentication"
            >
              Cancel
            </app-button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script setup>
import { mdiTwoFactorAuthentication } from "@mdi/js";

import {
  authenticateAndSetDetails,
  confirmTwoFactorData,
  handleRedirectAfterAuthentication,
  signOut
} from "@/api/auth.service";
import AppLogo from "@/components/AppLogo.vue";
import { useRouter } from "vue-router";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/user";
import { onMounted, ref } from "vue";
import { useInstanceStore } from "@/stores/instance";
import { useHead } from "@unhead/vue";

useHead({
  title: "Two Factor Confirmation"
});

const code = ref(null);
const loading = ref(false);
const cancelling = ref(false);

const user = useUserStore();
const instance = useInstanceStore();

const snackbar = useSnackbarStore();
const router = useRouter();
async function signIn() {
  try {
    loading.value = true;
    await confirmTwoFactorData(code.value);
    await authenticateAndSetDetails();
    await handleRedirectAfterAuthentication(router);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

async function cancelAuthentication(hideLoader = false) {
  try {
    if (!hideLoader) cancelling.value = true;
    await signOut();
    await instance.setCsrfToken();
    const user = useUserStore();
    user.logoutUser();
    if (router) await router.push({ name: "SignIn" });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    if (!hideLoader) cancelling.value = false;
  }
}

onMounted(() => {
  window.onbeforeunload = () => {
    if (user.id) return;
    cancelAuthentication(true);
  };
});
</script>
