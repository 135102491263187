<template>
  <v-fade-transition mode="out-in">
    <v-row v-if="loading === true" key="loading" class="ma-0">
      <v-col cols="12">
        <h3>Fetching Client Details</h3>
        <v-progress-linear indeterminate />
      </v-col>
    </v-row>
    <v-card v-else-if="loading === false">
      <v-card-title> More about {{ party.name }} </v-card-title>
      <v-card-text>
        <party-view-card-items :party="party" />
      </v-card-text>
    </v-card>
    <v-alert v-else key="error" type="error"> Unable to fetch party. </v-alert>
  </v-fade-transition>
</template>

<script setup>
import PartyViewCardItems from "@/components/parties/PartyViewCardItems.vue";
import { parseErrorMessage } from "@/util/helpers";
import { fetchIndividual } from "@/api/individual.service";
import { fetchEntity } from "@/api/entities.service";
import { useSnackbarStore } from "@/stores/snackbar";
import {  ref } from "vue";

const snackbar = useSnackbarStore();

const props = defineProps({
  type: {
    type: String,
    validates: v => ["Individual", "Entity"].includes(v),
    required: true
  },
  id: {
    type: [Number, String],
    required: true
  }
});

const party = ref(null);
const loading = ref(false);

async function fetchParty() {
  loading.value = true;
  try {
    if (props.type === "Individual") {
      party.value = await fetchIndividual(props.id);
    } else {
      party.value = await fetchEntity(props.id);
    }
    loading.value = false;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    loading.value = "error";
  }
}

fetchParty();
</script>
