<template>
  <div class="preliminary-card">
    <v-card light>
      <v-form id="login-form" @submit.prevent="submitSignIn">
        <v-col>
          <v-row class="ma-0" align="center" justify="center">
            <app-logo large />
          </v-row>
        </v-col>
        <v-card-title class="justify-center font-weight-bold text-h5 mb-6">
          Sign in to BOSS
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <text-field
                id="username"
                v-model="email"
                label="Email Address"
                name="username"
                autocomplete="username"
                autofocus
                data-testid="email"
                autocorrect="off"
                autocapitalize="off"
                type="text"
                v-bind="emailValidation"
                :prepend-inner-icon="mdiEmail"
              />
            </v-col>
            <v-scroll-y-transition mode="out-in">
              <v-col v-if="showPassword" cols="12">
                <text-field
                  v-model="authForm.password"
                  label="Password"
                  type="password"
                  autocomplete="off"
                  persistent-hint
                  hint=" "
                  data-testid="password"
                  :autofocus="showPassword"
                  :prepend-inner-icon="mdiLock"
                  :success="Boolean(authForm.password)"
                >
                  <template #message>
                    <router-link :to="{ name: 'ForgotPassword' }" tabindex="4">
                      Forgot Password?
                    </router-link>
                  </template>
                </text-field>
              </v-col>
            </v-scroll-y-transition>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <app-button
            class="text-none"
            color="primary"
            block
            variant="elevated"
            type="submit"
            data-testid="sign-in"
            :loading="loading"
          >
            <v-fade-transition mode="out-in">
              <span v-if="showPassword" key="sign-in"> Sign in </span>
              <span v-else key="check-federation"> Continue </span>
            </v-fade-transition>
          </app-button>
        </v-card-actions>
      </v-form>
      <v-card-text class="pt-3 text-darkgrey">
        Don't have an account?
        <router-link :to="{ name: 'SignUp' }">Sign up</router-link>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { mdiEmail, mdiLock } from "@mdi/js";
import AppLogo from "@/components/AppLogo.vue";

import { computed, ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { useSignInStore } from "@/stores/sign-in";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useRouter } from "vue-router";
import {
  authenticateAndSetDetails,
  handleRedirectAfterAuthentication,
  signIn
} from "@/api/auth.service";
import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";
import { checkUserEmail } from "@/api/users.service";
import {
  executeOAuthSso,
  executeSamlSso
} from "@/components/settings/security/sso.composable";
import useVuelidate from "@vuelidate/core";

import { email as emailValidator } from "@/util/vuelidateValidators";

useHead({
  title: "Sign In"
});

const signInStore = useSignInStore();
const { email: storedEmail } = storeToRefs(signInStore);

const showPassword = ref(false);
const loadingTimestamp = ref(false);
const snackbar = useSnackbarStore();
const router = useRouter();

const loading = computed(() => Boolean(loadingTimestamp.value));
const authForm = ref({
  email: storedEmail.value,
  password: ""
});

const email = computed({
  get: () => authForm.value.email,
  set: val => {
    authForm.value.email = val;
    storedEmail.value = val;
  }
});

const v$ = useVuelidate(
  {
    authForm: {
      email: { isValid: emailValidator }
    }
  },
  { authForm },
  { $scope: null, $autoDirty: true }
);

const emailValidation = computedValidation(v$.value.authForm.email, {
  isValid: "Please provide a valid email"
});

async function checkForSso() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    loadingTimestamp.value = new Date().getTime();
    const { provider, method } = await checkUserEmail(authForm.value.email);

    if (provider && method) {
      const handlingSso = await handleSso(provider, method);
      if (handlingSso) return;
    }
    showPassword.value = true;
    loadingTimestamp.value = null;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    showPassword.value = true;
    loadingTimestamp.value = null;
  }
}

async function submitSignIn() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  if (!showPassword.value) return checkForSso();

  try {
    loadingTimestamp.value = new Date().getTime();
    const { provider, method } = await signIn(authForm.value);
    if (provider && method) {
      const handlingSso = await handleSso(provider, method);
      if (handlingSso) return;
    }
    await authenticateAndSetDetails();
    signInStore.$reset();
    await handleRedirectAfterAuthentication(router);
  } catch (e) {
    if (e?.response?.status === 401) {
      snackbar.showErrorSnackbar({ message: "Invalid email or password" });
    } else {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    }
  } finally {
    loadingTimestamp.value = null;
  }
}

async function handleSso(provider, method) {
  loadingTimestamp.value = new Date().getTime();
  let currentTimestamp = loadingTimestamp.value;
  let isUsingSSO = false;
  if (method === "saml") {
    await executeSamlSso(provider);
    isUsingSSO = true;
  } else if (method === "oauth") {
    await executeOAuthSso(provider, email.value);
    isUsingSSO = true;
  }

  if (isUsingSSO) {
    setTimeout(() => {
      if (loadingTimestamp.value === currentTimestamp) {
        loadingTimestamp.value = null;
      }
    }, 3000);
  }

  return isUsingSSO;
}
</script>

<style lang="scss">
.nice-outline {
  border-color: lightgrey;
  border-width: 1px;
}
</style>
