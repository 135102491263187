<template>
  <v-card flat color="section">
    <v-card-text>
      <v-row align="center">
        <v-col cols="12" align="center">
          <div>
            <img
              :src="agency.avatar"
              style="object-fit: contain; max-width: 100px; max-height: 150px"
            />
          </div>
          <app-button
            variant="text"
            class="text-none"
            color="primary"
            data-testid="agency-profile-new-avatar"
            text="Upload New Avatar"
            :disabled="props.readonly"
            @click="uploadNewAvatar"
          />
        </v-col>
        <v-col cols="12">
          <text-field
            v-model="agency.name"
            data-lpignore="true"
            label="Display Name"
            data-testid="agency-display-name"
            :prepend-inner-icon="mdiAccount"
            :success="Boolean(agency.name)"
            :error-messages="!agency.name ? ['Required'] : []"
            @update:model-value="updateDisplayName('name')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.name.controller.value"
              />
            </template>
          </text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ChangeAvatarDialog from "@/dialogs/ChangeAvatarDialog.vue";
import { useDialogStore } from "@/stores/dialog";
import { useAgencySettingsStore } from "@/stores/agency-settings";
import { useActiveSave } from "@/composables/active-save.composable";
import { markRaw } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import { mdiAccount } from "@mdi/js";
const props = defineProps({
  readonly: Boolean
});

const settings = useSettingsViewStore();
const agency = useAgencySettingsStore(settings.currentAgencyId);
const savingBuffer = {
  name: useActiveSave()
};
const dialog = useDialogStore();

function updateDisplayName() {
  if (!agency.name) return;
  savingBuffer.name.debounceUpdate(agency.updateName);
}
async function uploadNewAvatar() {
  if (props.readonly) return;
  const result = await dialog.showDialog({
    component: markRaw(ChangeAvatarDialog),
    type: "Agency",
    id: agency.id,
    checkboxText: null
  });
  if (!result.updated) return;
  agency.avatar = result.newUrl;
}
</script>
