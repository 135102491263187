<template>
  <v-row>
    <v-col v-if="isAgent" cols="12" md="6">
      <date-input
        v-model="deceasedAt"
        label="Deceased At"
        data-testid="deceased-at"
        clearable
        hide-details
        @update:model-value="saveDeceasedAt"
      >
        <template #append-inner>
          <active-save-indicator
            :controller="savingBuffer.deceasedAt.controller.value"
          />
        </template>
      </date-input>
    </v-col>
    <v-col cols="12">
      <v-row align="center">
        <v-col cols="auto">
          <div style="max-width: 45rem; width: 100%">
            <div class="text-body-1">Delete {{ name }}</div>
            <div class="text-body-2">
              There are several restrictions around deletion. Not all may be
              deleted.
            </div>
          </div>
        </v-col>
        <v-col cols="auto">
          <app-button
            color="error"
            class="text-none"
            data-testid="delete-advisor"
            @click="confirmDeletion"
          >
            Delete
          </app-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import DateInput from "@/components/shared/DateInput.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useRouter } from "vue-router";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import { useDialogStore } from "@/stores/dialog";
import { useSettingsViewStore } from "@/stores/settings-view";
import { storeToRefs } from "pinia";


const props = defineProps({
  readonly: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

const dialog = useDialogStore();
const router = useRouter();

const isAgent = props.module === AGENT_SETTINGS;
let store;
if (isAgent) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { name, deceasedAt } = storeToRefs(store);

const savingBuffer = {
  deceasedAt: useActiveSave()
};

async function confirmDeletion() {
  const res = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Are you sure that you want to delete ${name.value}?`,
    subtitle: "This cannot be undone.",
    func: store.delete
  });

  if (res?.confirm && router) router.push({ name: "Home" });
}

function saveDeceasedAt() {
  savingBuffer.deceasedAt.debounceUpdate(store.updateDeceasedAt);
}
</script>
