<template>
  <v-row dense class="ma-0">
    <v-menu
      v-if="activeCoordinate"
      :close-on-content-click="false"
      offset-y
      bottom
    >
      <template #activator="{ props: templateProps }">
        <app-button
          :icon="showPositions ? mdiCogOff : mdiCog"
          v-bind="templateProps"
          variant="text"
          class="default-btn"
          density="comfortable"
          @click="showPositions = !showPositions"
        />
      </template>

      <v-card width="300px" elevation="8">
        <v-card-title> Field Data </v-card-title>
        <v-card-text class="pb-0">
          <template v-if="!activeCoordinate.isFieldOption">
            <text-field
              v-model="width"
              data-lpignore="true"
              type="text"
              inputmode="numeric"
              label="Width"
              :readonly="readonly"
            />
            <text-field
              v-model="height"
              data-lpignore="true"
              type="text"
              inputmode="numeric"
              label="Height"
              :readonly="readonly"
            />
          </template>
          <text-field
            v-model="x"
            data-lpignore="true"
            type="text"
            inputmode="numeric"
            label="X"
            :readonly="readonly"
          />
          <text-field
            v-model="y"
            data-lpignore="true"
            type="text"
            inputmode="numeric"
            label="Y"
            :readonly="readonly"
          />
        </v-card-text>
      </v-card>
    </v-menu>
    <v-tooltip location="bottom">
      <template #activator="{ props: templateProps }">
        <app-button
          v-bind="templateProps"
          variant="text"
          class="default-btn"
          density="comfortable"
          :icon="visible ? mdiEyeOff : mdiEye"
          @click="toggleVisibility"
        />
      </template>
      <span v-if="visible"> Hide Rectangle </span>
      <span v-else> Show Rectangle </span>
    </v-tooltip>
    <v-tooltip v-if="activeCoordinate?.isFieldOption" location="bottom">
      <template #activator="{ props: templateProps }">
        <app-button
          v-bind="templateProps"
          variant="text"
          class="default-btn"
          density="comfortable"
          :icon="
            allRadioValuesVisible ? mdiCircleOutline : mdiCircleMultipleOutline
          "
          @click="toggleRadioValueVisibility"
        />
      </template>
      <span v-if="allRadioValuesVisible"> Only Show Viewed Radio Value </span>
      <span v-else> Show All Radio Values </span>
    </v-tooltip>
    <v-tooltip location="bottom">
      <template #activator="{ props: templateProps }">
        <app-button
          v-bind="templateProps"
          variant="text"
          class="default-btn"
          density="comfortable"
          :icon="allVisible ? mdiTableEyeOff : mdiTableEye"
          @click="toggleOtherVisibility"
        />
      </template>
      <span v-if="allVisible"> Hide Other Rectangles </span>
      <span v-else> Show Other Rectangles </span>
    </v-tooltip>
  </v-row>
</template>

<script setup>
import { useMappedFormStore } from "@/stores/mapped-form";
import {
  mdiCogOff,
  mdiCog,
  mdiEyeOff,
  mdiEye,
  mdiCircleOutline,
  mdiCircleMultipleOutline,
  mdiTableEyeOff,
  mdiTableEye
} from "@mdi/js";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

const props = defineProps({
  formId: { type: [String, Number], required: true },
  pdfType: { type: String, required: true },
  rectangle: { type: Object, required: false, default: () => {} },
  visible: Boolean,
  allVisible: Boolean,
  allRadioValuesVisible: Boolean
});

const emit = defineEmits([
  "update:visible",
  "update:all-radio-values-visible",
  "update:all-visible"
]);

const mappedForm = useMappedFormStore(props.formId);

const {
  activeField,
  currentCoordinateIndex,
  readonly,
  currentFieldHasChanges
} = storeToRefs(mappedForm);

const showPositions = ref(false);

const activeCoordinate = computed(() => {
  return activeField.value.coordinates[currentCoordinateIndex.value];
});
const width = computed({
  get() {
    return activeField.value.coordinates[currentCoordinateIndex.value].width;
  },
  set(width) {
    activeField.value.coordinates[currentCoordinateIndex.value].width = width;
    currentFieldHasChanges.value = true;
  }
});
const height = computed({
  get() {
    return activeField.value.coordinates[currentCoordinateIndex.value].height;
  },
  set(height) {
    activeField.value.coordinates[currentCoordinateIndex.value].height = height;
    currentFieldHasChanges.value = true;
  }
});
const x = computed({
  get() {
    return activeField.value.coordinates[currentCoordinateIndex.value].x;
  },
  set(x) {
    activeField.value.coordinates[currentCoordinateIndex.value].x = x;
    currentFieldHasChanges.value = true;
  }
});
const y = computed({
  get() {
    return activeField.value.coordinates[currentCoordinateIndex.value].y;
  },
  set(y) {
    activeField.value.coordinates[currentCoordinateIndex.value].y = y;
    currentFieldHasChanges.value = true;
  }
});

function toggleVisibility() {
  emit("update:visible", !props.visible);
}

function toggleRadioValueVisibility() {
  emit("update:all-radio-values-visible", !props.allRadioValuesVisible);
}
function toggleOtherVisibility() {
  emit("update:all-visible", !props.allVisible);
}
</script>
