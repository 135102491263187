<template>
  <autocomplete-field
    ref="caseManagerInput"
    v-model="caseManager"
    v-model:search="caseManagerSearch"
    autocomplete="false"
    placeholder="Search Case Managers"
    item-title="name"
    return-object
    :prepend-inner-icon="mdiAccountTie"
    no-filter
    hide-no-data
    :label="label"
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="caseManagers"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
    @click:clear="$emit('update:model-value', {})"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script>
import { searchCaseManagers } from "@/api/search.service";
import { mdiAccountTie } from "@mdi/js";
export default {
  props: {
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    label: {
      type: String,
      default: "Case Manager"
    },
    errorMessages: { type: Array, default: () => [] },
    disabled: Boolean,
    modelValue: { type: [Object, String], default: null }
  },
  emits: ["update:model-value", "update:modelValue", "blur"],
  data() {
    let caseManagers = [];
    let caseManager = null;
    if (this.modelValue && Object.keys(this.modelValue).length) {
      caseManagers.push(this.modelValue);
      caseManager = this.modelValue;
    }
    return {
      caseManagerSearch: "",
      caseManagerTimer: null,
      loading: false,
      caseManagers,
      caseManager,
      mdiAccountTie
    };
  },
  watch: {
    modelValue(value) {
      if (!value || !Object.keys(value).length) {
        this.caseManager = null;
        this.caseManagers = [];
        return;
      }
      this.caseManager = value;
      this.caseManagers = [value];
    },
    caseManager(value) {
      this.$emit("update:model-value", value);
    },
    caseManagerSearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.caseManagers.some(val => val?.name === value) &&
        this.caseManagers.length === 1
      ) {
        return;
      }

      if (this.caseManagerTimer) {
        clearTimeout(this.caseManagerTimer);
      }

      this.caseManagerTimer = setTimeout(() => {
        this.loading = true;
        return searchCaseManagers(value)
          .then(response => {
            this.caseManagers = response.data.case_managers.toSorted((a, b) =>
              a.name.localeCompare(b.name)
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      if (!this.$refs.caseManagerInput?.$el) return;
      const input = this.$refs.caseManagerInput?.$el.querySelector("input");
      if (!input?.focus) return;
      input.focus();
    },
    clear() {
      this.caseManager = null;
      this.caseManagers = [];
    }
  }
};
</script>
