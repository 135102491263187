<template>
  <v-card flat tile color="section">
    <v-card-title data-testid="impairment-title">
      {{ impairments.length }}
      {{ impairments.length === 1 ? "Impairment" : "Impairments" }}
    </v-card-title>
    <v-card-text>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel
          v-for="(impairment, index) in impairments"
          :key="index"
        >
          <v-expansion-panel-title data-testid="impairment-header">
            {{ impairment.impairment }}
          </v-expansion-panel-title>
          <v-expansion-panel-text data-testid="impairment-content">
            <div class="mb-3">
              <h4>Diagnosis:</h4>
              <p>{{ impairment.diagnosis || "Not Provided" }}</p>
            </div>
            <div class="mb-3">
              <h4>Age at Onset:</h4>
              <p>{{ impairment.ageAtOnset || "Not Provided" }}</p>
            </div>
            <div class="mb-3">
              <h4>Treatment:</h4>
              <p>{{ impairment.treatment || "Not Provided" }}</p>
            </div>
            <div class="mb-3">
              <h4>Description:</h4>
              <p>{{ impairment.description || "Not Provided" }}</p>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
const { impairments } = storeToRefs(useImpairedRiskQuoteView());
</script>
