<template>
  <autocomplete-field
    :ref="e => (aqlSearch = e)"
    v-model="question"
    v-bind="$attrs"
    v-model:search="questionSearch"
    autocomplete="false"
    return-object
    no-filter
    hide-no-data
    item-title="name"
    :data-testid="dataTestid"
    :prepend-inner-icon="icon"
    :items="questions"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
  >
    <template v-if="slots['append']" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { getApplicationQuestionsByName } from "@/api/application-question.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { mdiCommentQuestion, mdiHeadQuestionOutline } from "@mdi/js";
import { computed, ref, useSlots, watch, toRef } from "vue";

const props = defineProps({
  smart: Boolean,
  modelValue: { type: [String, Object], required: false, default: null },
  modelType: { type: String, required: false, default: null },
  modelId: { type: [String, Number], required: false, default: null },
  dataTestid: { type: String, required: false, default: null }
});
const emit = defineEmits(["update:model-value", "blur"]);
const slots = useSlots();

const modelValue = toRef(props, "modelValue");

const snackbar = useSnackbarStore();

const questions = ref([]);
const question = ref(null);
const questionSearch = ref("");
const loading = ref(false);
const aqlSearch = ref(null);

if (props.modelValue?.id) {
  questions.value.push(props.modelValue);
  // eslint-disable-next-line vue/no-setup-props-destructure
  question.value = props.modelValue;
}

const icon = computed(() =>
  props.smart ? mdiHeadQuestionOutline : mdiCommentQuestion
);

async function search() {
  if (!questionSearch.value) return;

  if (
    questions.value.some(v => v?.name === questionSearch.value) &&
    questions.value.length === 1
  ) {
    return;
  }

  try {
    loading.value = true;

    const results = await getApplicationQuestionsByName({
      name: questionSearch.value,
      model: props.modelType,
      smart: props.smart,
      model_id: props.modelId
    });
    questions.value.splice(0, questions.value.length);
    questions.value.push(...results);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

watch(modelValue, () => {
  questions.value.splice(0, questions.value.length);
  if (!props.modelValue?.id) {
    question.value = null;
    return;
  }
  question.value = props.modelValue;
  questions.value.push(props.modelValue);
});

watch(question, v => emit("update:model-value", v));

let questionTimer;
watch(questionSearch, () => {
  if (questionTimer) clearTimeout(questionTimer);
  questionTimer = setTimeout(search, 200);
});

function getInput() {
  if (!aqlSearch.value?.$el?.querySelector) return;
  return aqlSearch.value.$el.querySelector("input");
}

function focus() {
  const el = getInput();
  if (el?.focus) el.focus();
}

function blur() {
  const el = getInput();
  if (el?.blur) el.blur();
}

defineExpose({ focus, blur });
</script>
