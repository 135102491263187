<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div data-testid="rendered-article" class="article" v-html="preview" />
  </div>
</template>

<script setup>
import MarkdownIt from "markdown-it";
import markdownItVideo from "markdown-it-video";
import { ref, toRefs, watch } from "vue";
const md = new MarkdownIt();
md.use(markdownItVideo, {
  youtube: { width: "100%", height: 390 },
  vimeo: { width: "100%", height: 390 }
});

const props = defineProps({
  article: { type: Object, required: true }
});

const { article } = toRefs(props);
const preview = ref("");

let timer;
watch(
  article,
  () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      preview.value = md.render(article.value.content);
    }, 500);
  },
  { immediate: true, deep: true }
);
</script>

<style scoped lang="scss">
.article {
  font-size: 1.2em;

  :deep(img) {
    max-width: 100%;
    height: auto;
    width: auto;
  }
  .subtitle {
    font-size: 0.8em;
  }

  h1,
  :deep(h1) {
    margin-bottom: 0.5em;
    font-weight: 400;
  }

  h4,
  :deep(h4) {
    font-weight: 400;
  }

  h5,
  :deep(h5) {
    font-weight: 500;
    font-size: 0.9em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  p,
  :deep(p) {
    line-height: 1.8em;
  }

  ul,
  :deep(ul),
  ol,
  :deep(ol) {
    margin-bottom: 1em;
    padding-left: 24px;
  }
}
</style>
