import { setDocumentFromRequest } from "@/factories/Document";
import { setNoteFromRequest } from "@/factories/Note";

export const SUPPORT_TICKET_CATEGORIES = [
  "General issue",
  "Something is not displaying right",
  "Contracting issue",
  "Licensing issue",
  "eApp Hold issue",
  "Something else"
];

export function NewSupportTicket(model = {}) {
  return {
    description: model?.description || "",
    documents: model?.documents || [],
    categories: model?.categories || []
  };
}

export function NewSupportTicketToCreateRequest(ticket) {
  return {
    description: ticket.description,
    documents: ticket.documents,
    categories: ticket.categories
  };
}

export function SupportTicket(model = {}) {
  return {
    assignable: Party(model?.assignable),
    ownable: Party(model?.ownable),
    createdAt: model?.createdAt,
    completedAt: model?.completedAt,
    categories: model?.categories,
    location: model?.location,
    description: model?.description,
    documents: model?.documents,
    id: model?.id,
    notes: model?.notes
  };
}

export function setSupportTicketFromRequest(raw = {}) {
  const model = SupportTicket();
  model.id = raw?.id;
  model.completedAt = raw?.completed_at;
  model.createdAt = raw?.created_at;
  model.description = raw?.description || "";
  model.location = raw?.location;

  model.assignable = setPartyFromRequest(raw?.assignable);
  model.ownable = setPartyFromRequest(raw?.ownable);

  model.categories = [];
  if (Array.isArray(raw?.categories)) {
    model.categories = raw.categories;
  }

  model.documents = [];
  if (Array.isArray(raw?.documents)) {
    model.documents = raw.documents.map(setDocumentFromRequest);
  }

  model.notes = [];
  if (Array.isArray(raw?.notes)) {
    model.notes = raw.notes.map(setNoteFromRequest);
  }

  return model;
}

function Party(model = {}) {
  return {
    avatarUrl: model?.avatarUrl,
    id: model?.id,
    name: model?.name,
    type: model?.type
  };
}

function setPartyFromRequest(raw = {}) {
  const model = Party();

  model.avatarUrl = raw?.avatar_url;
  model.id = raw?.id;
  model.name = raw?.name;
  model.type = raw?.type?.model;

  return model;
}

export function SupportTicketTableItem(model = {}) {
  return {
    ownable: Party(model?.ownable),
    assignable: Party(model?.assignable),
    description: model?.description,
    createdAt: model?.createdAt,
    id: model?.id
  };
}

export function setSupportTicketTableItemFromRequest(raw = {}) {
  const item = SupportTicketTableItem();

  item.description = raw.description;
  item.createdAt = raw.created_at;
  item.id = raw.id;
  item.completedAt = raw.completed_at;
  item.ownable = setPartyFromRequest(raw.ownable);
  item.assignable = setPartyFromRequest(raw.assignable);

  return item;
}
