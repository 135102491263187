<template>
  <v-card>
    <v-card-title>Add Smoker Chart</v-card-title>
    <v-card-text>
      <autocomplete-field
        v-model="productSmokerChart.smokerChart"
        data-testid="smoker-chart-select"
        label="Smoker Chart"
        item-value="additional"
        :loading="smokerCharts.loading"
        :prepend-inner-icon="mdiSmoking"
        :items="smokerCharts.items"
        v-bind="smokerChartValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        color="grey"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="confirm-button"
        :loading="loading"
        @click="save"
      >
        Create
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { mdiSmoking } from "@mdi/js";

import {
  ProductSmokerChart,
  ProductSmokerChartToCreateRequest
} from "@/factories/Product";
import { computedValidation, parseErrorMessage } from "@/util/helpers";

import { addProductSmokerChart } from "@/api/product-smoker-chart.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed,  ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useSearchItemsCache } from "@/stores/search-items-cache";

const props = defineProps({
  productId: { type: Number, required: true },
  carrierId: { type: Number, required: true }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const cache = useSearchItemsCache();
const productSmokerChart = ref(
  ProductSmokerChart({ productId: props.productId })
);
const smokerChartsObj = ref(
  cache.generateSmokerChartsByCarrierId(props.carrierId)
);
const loading = ref(false);

const smokerCharts = computed(() => smokerChartsObj.value.state);

const v$ = useVuelidate(
  {
    productSmokerChart: {
      smokerChart: { required: v => Boolean(v?.id) }
    }
  },
  { productSmokerChart },
  { $autoDirty: true, $scope: null }
);

const smokerChartValidation = computedValidation(
  v$.value.productSmokerChart.smokerChart,
  { required: "Required" }
);

async function getSmokerCharts() {
  try {
    await smokerChartsObj.value.fetch();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function save() {
  const isValid = await v$.value.$validate();
  if (loading.value || !isValid) return;
  loading.value = true;
  try {
    const response = await addProductSmokerChart(
      ProductSmokerChartToCreateRequest(productSmokerChart.value)
    );

    dialog.closeDialog({ productSmokerChart: response });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: `Unable to create smoker chart. ${parseErrorMessage(error)}`,
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

getSmokerCharts();
</script>
