export const SMOKING_USAGES = ["Yes", "No", "Unknown"];

export const SMOKING_CATEGORIES = [
  "Cannabis",
  "Chew",
  "Cigars",
  "Cigarettes",
  "eCigarettes",
  "Pipe"
];

export const SMOKING_RATINGS = [
  "Super Preferred",
  "Preferred",
  "Preferred NT",
  "Preferred Tobacco",
  "Select",
  "Standard Plus",
  "Standard NT",
  "Standard Tobacco",
  "Class 1",
  "Class 2",
  "Table 1",
  "Table 1 Tobacco",
  "Table 2",
  "Table 2 Tobacco",
  "Table 3",
  "Table 3 Tobacco",
  "Table 4",
  "Table 4 Tobacco",
  "Table 5",
  "Table 5 Tobacco",
  "Table 6",
  "Table 6 Tobacco",
  "Table 7",
  "Table 7 Tobacco",
  "Table 8",
  "Table 8 Tobacco",
  "Table 9",
  "Table 9 Tobacco",
  "Table 10",
  "Table 10 Tobacco",
  "Table 11",
  "Table 12",
  "Table 13",
  "Table 14",
  "Table 15",
  "Table 16",
  "Table 17",
  "Table 18",
  "Uninsurable 19",
  "Uninsurable 20",
  "Level",
  "Level Tobacco",
  "Graded",
  "Guaranteed Issue",
  "Postponed",
  "Declined",
  "Select",
  "Standard",
  "Class 1"
];

export const MAX_LAST_USE = 50000;
export const MAX_ANNUAL_FREQUENCY = 100000;
export const MIN_ANNUAL_FREQUENCY = 1;

export function SmokingType(model = {}) {
  return {
    smokerChart: model?.smokerChart || null,
    category: model?.category || null,
    rating: model?.rating || null,
    minimumAnnualFrequency: model?.minimumAnnualFrequency || null,
    maximumAnnualFrequency: model?.maximumAnnualFrequency || null,
    minimumLastUseDays: model?.minimumLastUseDays || null,
    maximumLastUseDays: model?.maximumLastUseDays || null,
    id: model?.id || null
  };
}

export function setSmokingTypeFromRequest(item = {}) {
  const type = SmokingType();

  type.category = item?.category;
  type.rating = item?.rating;
  type.minimumAnnualFrequency = item?.min_annual_frequency;
  type.maximumAnnualFrequency = item?.max_annual_frequency;
  type.minimumLastUseDays = item?.min_last_use_days;
  type.maximumLastUseDays = item?.max_last_use_days;
  type.id = item?.id;

  if (item?.smoker_chart) {
    type.smokerChart = {
      id: item.smoker_chart.id,
      name: item.smoker_chart.name,
      complete: item.smoker_chart.complete
    };
  }

  return type;
}

export function setRequestFromSmokingType(model = {}) {
  return {
    category: model.category,
    max_annual_frequency: +model.maximumAnnualFrequency,
    max_last_use_days: +model.maximumLastUseDays,
    min_annual_frequency: +model.minimumAnnualFrequency,
    min_last_use_days: +model.minimumLastUseDays,
    rating: model.rating,
    smoker_chart_id: +model.smokerChart.id
  };
}
