<template>
  <autocomplete-field
    v-model="state"
    :prepend-inner-icon="mdiHomeGroup"
    :data-testid="dataTestid"
    :disabled="disabled"
    :label="localLabel"
    :items="states"
    :multiple="multiple"
    :success="success"
    :error-messages="errorMessages"
    @blur="$emit('blur')"

  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script>
import { STATES } from "@/data/states";
import { mdiHomeGroup } from "@mdi/js";

export default {
  props: {
    dataTestid: {
      type: String,
      required: false,
      default: ""
    },
    abbreviated: Boolean,
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    multiple: Boolean,
    label: { type: String, default: null },
    modelValue: { type: [String, Array], default: null },
    disabled: Boolean
  },
  emits: ["update:model-value", "blur"],
  data() {
    let states = STATES;

    if (this.abbreviated) {
      states = STATES.map(s => ({ title: s.value, value: s.value }));
    }
    return {
      localLabel: this.label || "State",
      state: this.modelValue || null,
      states,
      mdiHomeGroup
    };
  },
  watch: {
    value(val) {
      this.state = val;
    },
    state(value) {
      this.$emit("update:model-value", value);
    }
  }
};
</script>
