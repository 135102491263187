<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        :items="table.mappedItems.value"
        :headers="table.tableHeaders.value"
        data-testid="signers-table"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        :mobile="null"
        mobile-breakpoint="sm"
        :items-per-page="-1"
      >
        <template #[`item.actions`]="{ item }">
          <v-tooltip v-if="!item.additional.signedAt" location="bottom">
            <template #activator="{ props: templateProps }">
              <app-button
                color="accent"
                v-bind="templateProps"
                variant="text"
                density="comfortable"
                :icon="mdiPencil"
                data-testid="signer-table-edit-signer"
                @click="editSigner(item)"
              />
            </template>
            <span>Edit Signer Email</span>
          </v-tooltip>
          <v-tooltip v-if="item.additional.canResendEnvelope" location="bottom">
            <template #activator="{ props: templateProps }">
              <app-button
                v-bind="templateProps"
                color="primary"
                variant="text"
                density="comfortable"
                :icon="mdiSend"
                data-testid="signer-table-resend-envelope"
                @click="resend"
              />
            </template>
            <span>Resend DocuSign Email to this Signer</span>
          </v-tooltip>
        </template>
        <template #[`item.signedAt`]="{ item }">
          <timestamp-formatter
            :model-value="item.signedAt"
            format="full-date-time"
          />
        </template>
        <template #[`item.ownerName`]="{ item }">
          <router-link
            v-if="item.additional.routerLink"
            class="text-none"
            :to="item.additional.routerLink"
          >
            {{ item.ownerName }}
          </router-link>
          <span v-else>
            {{ item.ownerName }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SignerEdit from "@/dialogs/SignerEdit.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import TableHeader from "@/classes/data-table/TableHeader";
import { useEappViewStore } from "@/stores/eapp-view";
import { storeToRefs } from "pinia";
import { markRaw } from "vue";
import { mdiPencil, mdiSend } from "@mdi/js";
import { watch } from "vue";

const eappView = useEappViewStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { signers } = storeToRefs(eappView);

const table = useTable({
  headers: [
    new TableHeader({ text: "Name", value: "ownerName", map: "ownerName" }),
    new TableHeader({
      text: "Email",
      value: "ownerEmail",
      map: "ownerEmail"
    }),
    new TableHeader({ text: "Role", value: "role", map: "role" }),
    new TableHeader({
      text: "Access Code",
      value: "accessCode",
      map: "accessCode"
    }),
    new TableHeader({ text: "Status", value: "status", map: "status" }),
    new TableHeader({ text: "Signed", value: "signedAt", map: "signedAt" }),
    new TableHeader({ text: "Actions", value: "actions", map: "actions" })
  ]
});
async function editSigner(signer) {
  if (signer.link === "agents") return;

  const res = await dialog.showDialog({
    component: markRaw(SignerEdit),
    modelValue: signer.additional.ownerEmail,
    signerId: signer.additional.signerId
  });

  if (!res?.email) return;
  const index = signers.value.findIndex(
    s => s.signerId === signer.additional.signerId
  );
  if (index < -1) return;
  signers.value[index].ownerEmail = res.email;
  snackbar.showSuccessSnackbar({
    message: `You have successfully changed the email to ${res.email}. DocuSign has sent a new eSignature email.`,
    timeout: 10000
  });
}

async function resend() {
  const func = async () => {
    await eappView.resendEnvelope();
    snackbar.showSuccessSnackbar({
      message: "DocuSign Successfully Resent",
      timeout: 6000
    });
  };

  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Resend Envelope?",
    subtitle: "This will dispatch an email",
    func
  });
}

watch(
  signers,
  v => {
    table.items.value.splice(0, table.items.value.length);
    table.items.value.push(...v);
  },
  { deep: true, immediate: true }
);
</script>
