<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { numberFormat } from "@/util/helpers";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true
  }
});

const formattedValue = computed(() => {
  return numberFormat(props.modelValue);
});
</script>
