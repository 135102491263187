import { serializeObject } from "@/util/helpers";

import { getHttpClient } from "@/http-client";
import { setTaskFromRequest } from "@/factories/Task";
import { setCaseTaskPreviewFromRequest } from "@/factories/Case";

const baseUrl = "api/boss/tasks";
export async function getTask(caseId, id) {
  const params = new URLSearchParams();
  params.append("case_id", caseId);
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`, { params });
  return setTaskFromRequest(data.task);
}

export async function updateTask(id, body) {
  const { data } = await getHttpClient().put(`${baseUrl}/${id}`, body);
  return setTaskFromRequest(data);
}

export async function createTask(task) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject(task)
  );

  return setCaseTaskPreviewFromRequest(data[0]);
}

export function deleteTask(caseId, taskId) {
  const params = new URLSearchParams();
  params.append("case_id", caseId);
  return getHttpClient().delete(`${baseUrl}/${taskId}`, { params });
}

export async function createHumanApiTask(task) {
  const { data } = await getHttpClient().post(`${baseUrl}/medical-records`, {
    task
  });
  return setCaseTaskPreviewFromRequest(data[0]);
}
