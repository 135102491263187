<template>
  <v-card tile flat>
    <v-row class="px-6 py-3" style="gap: 4px">
      <v-spacer />
      <app-button
        v-if="statementDocument"
        color="primary"
        variant="outlined"
        class="text-none rounded-pill"
        data-testid="statement-view-actions-download"
        v-bind="download()"
      >
        <v-icon :icon="mdiDownload" /> Download
      </app-button>
      <app-button
        v-if="nonImportableDocument"
        color="primary"
        variant="outlined"
        class="text-none rounded-pill"
        data-testid="statement-view-actions-download-non-importable"
        v-bind="downloadNonImportableDocument()"
      >
        <v-icon :icon="mdiDownload" /> Download PDF Statement
      </app-button>
      <app-button
        color="primary"
        variant="outlined"
        class="text-none rounded-pill"
        data-testid="statement-view-actions-upload-non-importable"
        @click="uploadNonImportableDocument"
      >
        <template v-if="nonImportableDocument">
          <v-icon :icon="mdiFileReplace" /> Replace PDF Statement
        </template>
        <template v-else>
          <v-icon :icon="mdiUpload" /> Upload PDF Statement
        </template>
      </app-button>
      <app-button
        v-if="errorsDocument"
        color="primary"
        variant="outlined"
        class="text-none rounded-pill"
        data-testid="statement-view-actions-download-errors"
        v-bind="downloadErrorDocument()"
      >
        <v-icon :icon="mdiDownload" /> Download Error Document
      </app-button>
      <app-button
        v-if="deletable"
        color="error"
        variant="outlined"
        class="text-none rounded-pill"
        data-testid="statement-view-actions-delete"
        @click="handleStatementDelete"
      >
        <v-icon :icon="mdiDelete" /> Delete
      </app-button>
    </v-row>
  </v-card>
</template>

<script setup>
import DocumentDialog from "@/dialogs/DocumentDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { downloadFileAsLink } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { getDocumentUrl } from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";
import { mdiDownload, mdiFileReplace, mdiUpload, mdiDelete } from "@mdi/js";
import { useStatementView } from "@/stores/statement-view";
import { useRouter } from "vue-router";
import { markRaw } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";

const router = useRouter();
const { mdAndDown } = useDisplay();
const dialog = useDialogStore();
const statementView = useStatementView();

const {
  document: statementDocument,
  nonImportableDocument,
  errorsDocument,
  deletable
} = storeToRefs(statementView);

function download() {
  return downloadFileAsLink(
    getDocumentUrl(statementDocument.value.uid),
    mdAndDown.value
  );
}

function downloadNonImportableDocument() {
  return downloadFileAsLink(
    getDocumentUrl(nonImportableDocument.value.uid),
    mdAndDown.value
  );
}

function downloadErrorDocument() {
  return downloadFileAsLink(
    getDocumentUrl(errorsDocument.value.uid),
    mdAndDown.value
  );
}

function uploadNonImportableDocument() {
  dialog.showDialog({
    component: markRaw(DocumentDialog),
    scrollable: true,
    title: `${nonImportableDocument.value ? "Replace" : "Add"} Statement PDF`,
    single: true,
    func: async ({ file }) => statementView.uploadNonImportableDocument(file)
  });
}

function handleStatementDelete() {
  return dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want to delete this statement?",
    subtitle: "This cannot be undone",
    func: async () => {
      await statementView.deleteStatement();
      router.replace({ name: "BackNineCommissionsStatements" });
    }
  });
}
</script>
