<template>
  <v-row dense>
    <v-col v-if="!disabled" cols="12">
      <v-row class="pa-3">
        <select-field
          v-model="newImpairmentType"
          :prepend-inner-icon="mdiStethoscope"
          label="New Impairment Type"
          data-testid="new-impairment-type"
          :items="availableUnderwritingRationale"
        />
        <app-button
          class="text-none ml-2"
          color="accent"
          data-testid="add-impairment"
          @click="createImpairment"
        >
          <v-icon :icon="mdiPlus" /> Add
        </app-button>
      </v-row>
    </v-col>

    <v-col
      v-for="(v, key, index) in underwritingRationale"
      :key="key"
      cols="12"
      :data-testid="`rationale-${key}`"
    >
      <textarea-field
        v-model="underwritingRationale[key]"
        class="has-append-button"
        data-testid="rationale-text"
        :label="`${index + 1}. ${key} Impairment`"
        :disabled="disabled"
        :success="Boolean(v)"
        @update:model-value="updateRationaleValue(key)"
      >
        <template v-if="!disabled" #append-inner>
          <active-save-indicator
            :controller="savingBuffer[key].controller.value"
            margin-top
          />
          <app-button
            :icon="mdiDelete"
            color="error"
            size="24"
            data-testid="remove-rationale"
            @click="removeRationale(key)"
          />
        </template>
      </textarea-field>
    </v-col>
    <p v-if="emptyUnderwritingRationale" class="px-1">
      No underwriting rationale have been entered.
    </p>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { IMPAIRMENT_CONCERNS } from "@/factories/ImpairedRiskQuote";
import { computed, ref } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { mdiStethoscope, mdiPlus, mdiDelete } from "@mdi/js";

const props = defineProps({
  modelValue: { type: Object, required: false, default: () => null },
  func: { type: Function, required: false, default: null },
  disabled: Boolean
});

const emit = defineEmits(["update:model-value"]);

const underwritingRationale = ref(props.modelValue || {});
const newImpairmentType = ref(undefined);

const savingBuffer = IMPAIRMENT_CONCERNS.reduce((acc, curr) => {
  acc[curr] = useActiveSave();
  return acc;
}, {});
savingBuffer.root = useActiveSave();

const emptyUnderwritingRationale = computed(
  () => !Object.keys(underwritingRationale.value).length
);

const availableUnderwritingRationale = computed(() =>
  IMPAIRMENT_CONCERNS.filter(val => !(val in underwritingRationale.value))
);

function createImpairment() {
  if (!newImpairmentType.value) return;
  underwritingRationale.value[newImpairmentType.value] = "";
  newImpairmentType.value = undefined;
}

function removeRationale(rationale) {
  delete underwritingRationale.value[rationale];
  updateRationaleValue("root");
}

function updateRationaleValue(attribute) {
  emit("update:model-value", underwritingRationale.value);
  if (!props.func || props.disabled) return;
  savingBuffer[attribute].controller.value.needsUpdate = true;
  updateAttributes();
}

let timer;
function updateAttributes() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    const attributesToUpdate = Object.keys(savingBuffer).filter(
      k => savingBuffer[k].controller.value.needsUpdate
    );

    const promise = props.func();

    attributesToUpdate.forEach(attr =>
      savingBuffer[attr].attachToPromise(promise)
    );
  }, 500);
}
</script>
