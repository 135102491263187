<template>
  <v-card>
    <v-card-title>
      {{ editing ? "Edit" : "Create a" }} Smoking Type
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <smoker-chart-search
            v-model="smokingType.smokerChart"
            label="Smoker Chart"
            data-testid="smoking-type-smoker-chart"
            :prepend-inner-icon="mdiLabel"
            :success="smokerChartValidation.success"
            :error-messages="smokerChartValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12">
          <v-alert
            v-if="smokingType.smokerChart?.complete && !editing"
            type="warning"
            data-testid="smoking-type-warning"
            border="left"
            variant="outlined"
          >
            The Smoker Chart named "{{ smokingType.smokerChart.name }}" is
            already complete.
          </v-alert>
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="smokingType.category"
            label="Category"
            data-testid="smoking-type-category"
            :prepend-inner-icon="mdiFormatListBulletedType"
            :items="SMOKING_CATEGORIES"
            :success="categoryValidation.success"
            :error-messages="categoryValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <autocomplete-field
            v-model="smokingType.rating"
            label="Rating"
            data-testid="smoking-type-rating"
            :prepend-inner-icon="mdiStar"
            :items="SMOKING_RATINGS"
            :success="ratingValidation.success"
            :error-messages="ratingValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="smokingType.minimumAnnualFrequency"
            label="Minimum Annual Frequency"
            data-testid="smoking-type-minimum-annual-frequency"
            :prepend-inner-icon="mdiChartBellCurve"
            persistent-hint
            :decimal-length="0"
            :hint="minimumAnnualFrequencyHint"
            :success="minimumAnnualFrequencyValidation.success"
            :error-messages="minimumAnnualFrequencyValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="smokingType.maximumAnnualFrequency"
            label="Maximum Annual Frequency"
            data-testid="smoking-type-maximum-annual-frequency"
            :prepend-inner-icon="mdiChartBellCurve"
            persistent-hint
            :decimal-length="0"
            :hint="maximumAnnualFrequencyHint"
            :success="maximumAnnualFrequencyValidation.success"
            :error-messages="maximumAnnualFrequencyValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="smokingType.minimumLastUseDays"
            label="Minimum Last Use Days"
            data-testid="smoking-type-minimum-last-use-days"
            :prepend-inner-icon="mdiCalendarStart"
            persistent-hint
            :decimal-length="0"
            :hint="minimumLastUseDaysHint"
            :success="minimumLastUseDaysValidation.success"
            :error-messages="minimumLastUseDaysValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="smokingType.maximumLastUseDays"
            label="Maximum Last Use Days"
            data-testid="smoking-type-maximum-last-use-days"
            :prepend-inner-icon="mdiCalendarEnd"
            persistent-hint
            :decimal-length="0"
            :hint="maximumLastUseDaysHint"
            :success="maximumLastUseDaysValidation.success"
            :error-messages="maximumLastUseDaysValidation.errorMessages"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        color="primary"
        class="text-none"
        data-testid="smoking-type-save"
        :loading="loading"
        @click="save"
      >
        {{ editing ? "Save" : "Create" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import SmokerChartSearch from "@/components/shared/SmokerChartSearch.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import {
  mdiLabel,
  mdiFormatListBulletedType,
  mdiStar,
  mdiChartBellCurve,
  mdiCalendarStart,
  mdiCalendarEnd
} from "@mdi/js";

import {
  numberFormat,
  parseErrorMessage,
  validationComputeV2
} from "@/util/helpers";
import {
  createSmokerType,
  updateSmokerType
} from "@/api/smoking-types.service";

import {
  MAX_ANNUAL_FREQUENCY,
  MAX_LAST_USE,
  MIN_ANNUAL_FREQUENCY,
  SMOKING_CATEGORIES,
  SMOKING_RATINGS,
  SmokingType
} from "@/factories/SmokingTypeFactory";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import { ref, computed } from "vue";

import { required, minValue, maxValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const minimumAnnualFrequencyHint = `Must be greater than or equal to ${numberFormat(
  MIN_ANNUAL_FREQUENCY
)}`;

const maximumAnnualFrequencyHint = `Must be less than or equal to ${numberFormat(
  MAX_ANNUAL_FREQUENCY
)}`;

const minimumLastUseDaysHint = `Must be divisible by 365 with a remainder of 1 or 0`;

const maximumLastUseDaysHint = `Must be ${numberFormat(
  MAX_LAST_USE
)} or divisible by 365 with a remainder of 1 or 0`;

const props = defineProps({
  modelValue: { type: Object, required: false, default: () => {} }
});
const smokingType = ref(SmokingType());

const rules = {
  smokingType: {
    smokerChart: { required },
    category: { required },
    rating: { required },
    minimumAnnualFrequency: {
      required,
      maxValue: maxValue(MAX_ANNUAL_FREQUENCY),
      minValue: minValue(MIN_ANNUAL_FREQUENCY),
      relativeMax: (v, vm) => maxValue(vm.maximumAnnualFrequency)
    },
    maximumAnnualFrequency: {
      required,
      maxValue: maxValue(MAX_ANNUAL_FREQUENCY),
      minValue: minValue(MIN_ANNUAL_FREQUENCY),
      relativeMin: (v, vm) => minValue(vm.minimumAnnualFrequency)
    },
    minimumLastUseDays: {
      required,
      yearlyValid: value => {
        return [0, 1].includes(value % 365);
      },
      relativeMax: (v, vm) => maxValue(vm.maximumLastUseDays)
    },
    maximumLastUseDays: {
      required,
      yearlyValid: value => {
        const remainder = +value % 365;
        if (remainder === 1 || remainder === 0) return true;
        return value <= MAX_LAST_USE;
      },
      relativeMin: (v, vm) => minValue(vm.minimumLastUseDays)
    }
  }
};

const state = { smokingType };

const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const loading = ref(false);
// eslint-disable-next-line vue/no-setup-props-destructure
if (props.modelValue?.id) smokingType.value = props.modelValue;
const editing = Boolean(props.modelValue?.id);

const smokerChartValidation = computed(() => {
  const model = v$.value.smokingType.smokerChart;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const categoryValidation = computed(() => {
  const model = v$.value.smokingType.category;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const ratingValidation = computed(() => {
  const model = v$.value.smokingType.rating;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

const minimumAnnualFrequencyValidation = computed(() => {
  const model = v$.value.smokingType.minimumAnnualFrequency;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "minValue", message: minimumAnnualFrequencyHint },
    { key: "maxValue", message: maximumAnnualFrequencyHint },
    {
      key: "relativeMax",
      message: "Must be less than Maximum Annual Frequency"
    }
  ]);
});

const maximumAnnualFrequencyValidation = computed(() => {
  const model = v$.value.smokingType.maximumAnnualFrequency;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "minValue", message: minimumAnnualFrequencyHint },
    { key: "maxValue", message: maximumAnnualFrequencyHint },
    {
      key: "relativeMin",
      message: "Must be greater than Minimum Annual Frequency"
    }
  ]);
});

const minimumLastUseDaysValidation = computed(() => {
  const model = v$.value.smokingType.minimumLastUseDays;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "yearlyValid", message: minimumLastUseDaysHint },
    {
      key: "relativeMax",
      message: "Must be less than Maximum Last Use Days"
    }
  ]);
});

const maximumLastUseDaysValidation = computed(() => {
  const model = v$.value.smokingType.maximumLastUseDays;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "yearlyValid", message: maximumLastUseDaysHint },
    {
      key: "relativeMin",
      message: "Must be greater than Minimum Last Use Days"
    }
  ]);
});

async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  if (editing) return saveSmokingType();
  return createSmokingType();
}
async function saveSmokingType() {
  loading.value = true;

  try {
    await updateSmokerType(smokingType.value);
    dialog.closeDialog({ updated: true });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Unable to update smoking type: ${parseErrorMessage(e)}`,
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
async function createSmokingType() {
  loading.value = true;
  try {
    await createSmokerType(smokingType.value);
    dialog.closeDialog({ created: true });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Unable to create smoking type: ${parseErrorMessage(e)}`,
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}
</script>
