<template>
  <v-card class="pa-0">
    <v-card-title>
      <v-row class="ma-0" align="center">
        Message from {{ message.ownable.name }} <v-spacer />
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <timestamp-formatter
        format="date-time"
        :model-value="message.createdAt"
      />
    </v-card-subtitle>
    <v-card-text style="height: 75vh" class="py-0">
      <iframe class="frame" sandbox :srcdoc="message.html || message.note" />
    </v-card-text>
    <v-card-actions class="px-6">
      <v-spacer />
      <app-button
        color="primary"
        class="text-none"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";

const props = defineProps({ message: { type: Object, default: () => {} } });
const { message } = storeToRefs(props);
const dialog = useDialogStore();
</script>
