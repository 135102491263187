<template>
  <text-field
    v-bind="$attrs"
    ref="inputref"
    v-model="model"
    v-maska:[options]
    :inputmode="props.decimalLength > 0 ? 'decimal' : 'numeric'"
    :data-testid="props.dataTestid"
  >
    <template v-if="$slots.message" #message="{ message }">
      <v-row v-if="message.trim()" class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
      <slot v-else name="message" />
    </template>

    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </text-field>
</template>

<script setup>
import { vMaska } from "maska";
import { watch, ref, toRef } from "vue";

const props = defineProps({
  decimalLength: {
    type: Number,
    required: false,
    default: 0
  },
  max: {
    type: Number,
    required: true,
    default: 0
  },
  min: {
    type: Number,
    required: false,
    default: 0
  },
  modelValue: {
    type: [Number, String],
    required: false,
    default: null
  },
  dataTestid: {
    type: String,
    required: false,
    default: ""
  }
});

let maskValue = "I".repeat(`${props.max}`.length);
// Yield something like IIII.DD
if (props.decimalLength) {
  const decimalValue = "D".repeat(props.decimalLength);
  maskValue = `${maskValue}.${decimalValue}`;
}

const options = {
  mask: maskValue,
  tokens: {
    I: { pattern: /[0-9]/, optional: true },
    D: { pattern: /[0-9]/, optional: true }
  },
  postProcess: val => {
    if (!val && val !== props.min) {
      return null;
    }
    if (+val < +props.min) {
      return props.min;
    }

    if (Math.min(+props.max, +val) === props.max) return props.max;

    return val;
  }
};

const model = ref(null);
const propValue = toRef(props, "modelValue");
if (propValue.value || propValue.value === 0) {
  model.value = propValue.value;
}

watch(propValue, () => {
  let v = null;
  if (propValue.value || propValue.value === 0) v = propValue.value;
  if (v === model.value) return;

  model.value = v;
});

const emit = defineEmits(["update:model-value"]);
const update = v => emit("update:model-value", v);

watch(model, () => {
  if (!props.max) {
    if (model.value === propValue.value) return;
    update(model.value);
    return;
  }

  let v = null;
  if (model.value || model.value === 0) v = +model.value;
  if (v === propValue.value) return;
  update(v);
});

const inputref = ref(null); // template ref
function focus() {
  if (inputref.value?.focus) inputref.value.focus();
}

defineExpose({ focus });
</script>

<style lang="scss">
.text-field {
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}
</style>
