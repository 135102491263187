<template>
  <autocomplete-field
    ref="autocomplete"
    v-model:search="search"
    name="address"
    class="search-location"
    type="text"
    :prepend-inner-icon="mdiSignRealEstate"
    item-title="description"
    :data-testid="dataTestid"
    :loading="loading"
    :placeholder="placeholder"
    :autofocus="autofocus"
    :disabled="disabled || fetchingLibrary"
    :class="{ 'success-field': success, 'error-field': errorMessages.length }"
    :base-color="success ? 'success' : 'basic'"
    :color="success ? 'success' : 'primary'"
    :error-messages="errorMessages"
    :hint="hint"
    :persistent-hint="persistentHint"
    :items="places"
    :label="label"
    return-object
    no-filter
    @update:model-value="handleSelect"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
    <template v-if="$slots.message" #message="{ message }">
      <v-row v-if="message" class="ma-0">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
      <slot v-else name="message" />
    </template>
    <template #no-data>
      <v-list-item>
        <v-list-item-title>
          Search for your <strong>Address</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-if="search" #append-item>
      <v-list-item link @click="$emit('switch-to-manual')">
        <v-list-item-title> Can't Find Your Address? </v-list-item-title>
      </v-list-item>
    </template>
  </autocomplete-field>
</template>

<script>
import { Address } from "@/factories/AddressFactory";
import { mdiSignRealEstate } from "@mdi/js";
import { mapActions } from "pinia";
import { useGoogleMaps } from "@/stores/google-maps";
export default {
  props: {
    dataTestid: { type: String, required: true },
    autofocus: Boolean,
    disabled: Boolean,
    success: Boolean,
    errorMessages: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ""
    },
    persistentHint: Boolean,
    label: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Start typing the first line of your address"
    }
  },
  emits: ["switch-to-manual", "error", "update:model-value"],
  data() {
    return {
      search: "",
      autocompleteService: null,
      placeService: null,
      places: [],
      timer: null,
      loading: false,
      mdiSignRealEstate,
      fetchingLibrary: false
    };
  },
  watch: {
    search(val) {
      if (!val) {
        this.places = [];
        return;
      }
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => this.getPlaces(val), 200);
    }
  },
  mounted() {
    this.getOrCreateLib();
  },
  methods: {
    ...mapActions(useGoogleMaps, ["initGoogleMaps"]),
    async getOrCreateLib() {
      this.fetchingLibrary = true;
      try {
        await this.initGoogleMaps();
        this.initMaps();
      } catch (e) {
        this.$emit("error");
      } finally {
        this.fetchingLibrary = false;
      }
    },
    getPlaces(val) {
      if (!val) return;
      try {
        this.loading = true;
        this.autocompleteService.getPlacePredictions(
          {
            input: val,
            types: ["geocode"],
            componentRestrictions: { country: "us" }
          },
          res => {
            this.loading = false;
            this.places = res?.filter(Boolean) || [];
          }
        );
      } catch (e) {
        this.$emit("error");
      }
    },
    initMaps() {
      try {
        this.loading = false;
        this.autocompleteService =
          new window.google.maps.places.AutocompleteService();
        this.placeService = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
      } catch (e) {
        this.$emit("error");
      }
    },
    handleSelect(item) {
      this.placeService.getDetails(
        {
          placeId: item.place_id,
          fields: ["address_components", "name"]
        },
        res => {
          const address = new Address();

          address.street_address = res?.name;
          res?.address_components?.forEach(component => {
            const type = component.types[0];
            if (type === "locality") {
              address.city = component.long_name;
            } else if (type === "administrative_area_level_1") {
              address.state = component.short_name;
            } else if (type === "postal_code") {
              address.zip = component.long_name;
            } else if (type === "country") {
              address.country = component.long_name;
            }
          });
          this.$emit("update:model-value", address);
        }
      );
    }
  }
};
</script>
