import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/requirements";
export async function getRequirements(params, cancelToken) {
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
  if (!response?.data) return;

  const { requirements, meta } = response.data;

  const stats = {
    score: meta?.score
  };

  const items = requirements.map(i => ({
    ...i,
    itemKey: `${params.get("type")}-${i.id}`
  }));

  return { items, meta, stats };
}
