<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="category-states-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row class="ma-0">
        <span class="text-h5"> Category States </span>
        <v-spacer />
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              variant="text"
              density="comfortable"
              v-bind="templateProps"
              class="table-btn"
              data-testid="category-states-table-refresh"
              :disabled="table.loading.value"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <table-filter
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.category`]="{ item }">
      <friendly-form-category :model-value="item.category" />
    </template>
    <template #[`item.formName`]="{ item }">
      <router-link
        v-if="item.additional.formId"
        :to="{
          name: 'FormMappingEditor',
          params: { id: item.additional.formId }
        }"
      >
        {{ item.formName }}
      </router-link>
    </template>
    <template #[`item.actions`]="{ item }">
      <app-button
        :icon="mdiPencil"
        variant="text"
        density="comfortable"
        color="accent"
        data-testid="edit"
        @click="editCategoryState(item)"
      />
    </template>
  </v-data-table-server>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";

import FriendlyFormCategory from "@/components/shared/FriendlyFormCategory.vue";
import CategoryStatesDialog from "@/dialogs/CategoryStatesDialog.vue";

import { STATES } from "@/data/states";
import { parseErrorMessage } from "@/util/helpers";
import { getCategoryStates } from "@/api/category-states.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { CATEGORY_STATES_STATUS } from "@/factories/FormMapping";
import { mdiRefresh, mdiPencil } from "@mdi/js";
import { markRaw } from "vue";

const table = useTable({
  headers: [
    new TableHeader({
      text: "Form",
      value: "formName",
      map: "formName",
      sortFilterMap: "form",
      sortKey: "forms.name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "stateName",
      sortFilterMap: "state",
      selectableOptions: STATES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      displayMap: "text",
      sortFilterMap: [{ key: "category", value: "value" }],
      sortKey: "forms.category",
      ...TableHeader.IS_FORM_CATEGORY_SEARCH,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrierName",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      sortKey: "carriers.name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Form Set",
      value: "formSetName",
      map: "formSetName",
      sortFilterMap: [{ key: "form_set_id", value: "id" }],
      displayMap: "name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FORM_SET_SEARCH
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      sortFilterMap: "status",
      selectableOptions: CATEGORY_STATES_STATUS,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Actions",
      value: "actions"
    })
  ],
  getData: getCategoryStates
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function editCategoryState({ additional }) {
  const res = await dialog.showDialog({
    component: markRaw(CategoryStatesDialog),
    modelValue: additional
  });

  if (res.saved) getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
