import { getHttpClient } from "@/http-client";
import { setRawUpdateFromConnection } from "@/factories/ConnectionFactory";
const baseUrl = "api/boss/connections";

export function updateConnection(id, connection) {
  return getHttpClient().put(`${baseUrl}/${id}`, {
    connection: setRawUpdateFromConnection(connection)
  });
}

export function deleteConnection(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
