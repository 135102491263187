export function Affiliation(model = {}) {
  return {
    id: model?.id,
    category: model?.category,
    name: model?.name,
    ownable: model?.ownable
  };
}

export function setAffiliationFromRequest(rawModel = {}) {
  const affiliation = Affiliation();
  affiliation.category = rawModel?.category;
  affiliation.name = rawModel?.name;
  affiliation.id = rawModel?.id;

  if (rawModel?.ownable) {
    affiliation.ownable = setAffiliationOwnableFromRequest(rawModel.ownable);
  }

  return affiliation;
}

export function setRequestFromAffiliation(affiliation = {}) {
  const req = {
    name: affiliation.name,
    category: affiliation.category,
    ownable_id: undefined,
    ownable_type: undefined
  };
  if (affiliation.ownable.id) {
    req.ownable_id = affiliation.ownable.id;
    req.ownable_type = affiliation.ownable.type;
  }

  return req;
}

export function AffiliationOwnable(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    type: model?.type
  };
}

function setAffiliationOwnableFromRequest(raw = {}) {
  const ownable = AffiliationOwnable();
  ownable.id = raw?.id;
  ownable.name = raw?.name;
  ownable.type = raw?.type;
  return ownable;
}

export const AFFILIATION_CATEGORIES = [
  "Agency Management System",
  "Aggregator",
  "Broker Dealer",
  "Brokerage",
  "Credit Union",
  "CRM",
  "CUSO",
  "Franchise Group",
  "P&C",
  "Rater",
  "Website Manager",
  "Unknown"
];
