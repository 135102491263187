<template>
  <v-list v-if="sidebar" id="user-menu" key="mobile-user-menu" nav>
    <v-list-group>
      <template #activator="{ props }">
        <v-list-item
          v-bind="props"
          :title="compactUserName"
          :subtitle="loginable.email"
          lines="1"
        >
          <template #prepend>
            <v-img :src="avatar" width="40" height="40" class="rounded-xl mr-3">
              <template #error>
                <v-img
                  width="40"
                  height="40"
                  class="rounded-xl"
                  :src="defaultAvatar"
                />
              </template>
            </v-img>
          </template>
        </v-list-item>
      </template>
      <user-menu-items :items="listItems" />
    </v-list-group>
    <v-divider />
  </v-list>
  <v-menu v-else key="lg-user-menu" offset-y eager>
    <template #activator="{ props }">
      <app-button
        v-bind="props"
        id="user-menu"
        variant="text"
        class="text-none"
      >
        {{ compactUserName }}
        <v-icon :icon="mdiChevronDown" />
        <v-img :src="avatar" width="40" height="40" class="rounded-xl ml-3">
          <template #error>
            <v-img
              width="40"
              height="40"
              class="rounded-xl"
              :src="defaultAvatar"
            />
          </template>
        </v-img>
      </app-button>
    </template>
    <user-menu-items :items="listItems" />
  </v-menu>
</template>

<script setup>
import UserMenuItems from "@/components/UserMenuItems.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { mdiChevronDown } from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";
import defaultAvatar from "@/assets/img/Default.png";
import { computed } from "vue";

defineProps({ sidebar: Boolean });

const { lgAndUp } = useDisplay();

const user = useUserStore();
const {
  logins,
  highest_group: highestGroup,
  loginable,
  compactUserName
} = storeToRefs(user);

const avatar = loginable.value.avatar_url || defaultAvatar;

const listItems = computed(() => {
  const items = ["Profile", "BackNine Contacts"];

  if (lgAndUp.value) {
    items.push("Divider");
  } else {
    items.push("Schedule Meeting");
  }

  if (highestGroup.value >= 2 || logins.value.length >= 2) {
    items.push("Change Access");
  }

  items.push("Advisor Edit", "LightDark Mode", "Logout");
  return items;
});
</script>
