<template>
  <v-card flat>
    <v-row class="ma-0 pr-3" align="center">
      <div>
        <v-card-title> Marketing Manager To-Do Report </v-card-title>
        <v-card-subtitle>
          View marketing manager To-Do completion performance
        </v-card-subtitle>
      </div>
      <v-spacer />
      <v-card
        v-for="stat in backnineStats.stats"
        :key="stat.title"
        variant="outlined"
        class="pa-1 ma-1"
        :data-testid="stat.dataTestid"
      >
        <span class="text-grey pr-1"> {{ stat.title }}:</span>
        <v-fade-transition mode="out-in">
          <v-progress-circular
            v-if="loading"
            key="loading"
            indeterminate
            size="18"
            width="2"
          />
          <span v-else key="display">
            {{ stat.value }} {{ stat.context ? `(${stat.context})` : "" }}
          </span>
        </v-fade-transition>
      </v-card>
    </v-row>
    <v-divider />
    <v-card-text class="section">
      <v-fade-transition mode="out-in">
        <v-row v-if="loading" key="loading" class="ma-0">
          <p class="font-weight-regular mb-0">Loading Marketing Managers</p>
          <v-progress-circular class="ml-3" indeterminate size="18" width="2" />
        </v-row>
        <v-row
          v-else-if="marketingManagers.length"
          key="has-managers"
          dense
          justify="center"
        >
          <v-col
            v-for="mm in marketingManagerItems"
            :key="mm.name"
            cols="12"
            xl="4"
            md="6"
          >
            <v-card flat>
              <v-card-title data-testid="manager-name" class="text-center">
                {{ mm.name }}
              </v-card-title>
              <v-row dense class="ma-0">
                <v-col
                  v-for="stat in mm.stats"
                  :key="stat.title"
                  cols="6"
                  md="4"
                >
                  <v-card
                    flat
                    color="section"
                    :class="stat.class"
                    :data-testid="`mm-${stat.dataTestid}`"
                  >
                    <v-card-title class="text-center">
                      <v-fade-transition mode="out-in">
                        <v-progress-circular
                          v-if="loading"
                          key="loading"
                          indeterminate
                          width="2"
                        />
                        <span v-else key="loaded">
                          {{ stat.value }}
                        </span>
                      </v-fade-transition>
                    </v-card-title>
                    <v-card-subtitle class="text-center pb-3">
                      {{ stat.title }}
                      {{ stat.context ? `(${stat.context})` : "" }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <p v-else key="no-managers" class="mb-0">No Marketing Managers Found</p>
      </v-fade-transition>
    </v-card-text>
  </v-card>
</template>

<script setup>
import {
  getMarketingManagersTodoReport,
  TodoScoreItem
} from "@/api/reports.service";
import { numberFormat, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { computed, ref } from "vue";

useHead({
  title: "Marketing Manager To-Do Report"
});

const snackbar = useSnackbarStore();

const marketingManagers = ref([]);
const backnine = ref(TodoScoreItem);
const loading = ref(false);

async function getReport() {
  loading.value = true;
  try {
    const result = await getMarketingManagersTodoReport();
    backnine.value = result.backnine;
    marketingManagers.value = result.marketingManagers;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const backnineStats = computed(() => generateFromScoreItem(backnine.value));

const marketingManagerItems = computed(() =>
  marketingManagers.value.map(generateFromScoreItem)
);

function generateFromScoreItem(item) {
  const scoreTitle = "Score";
  let scoreContext;
  if (!loading.value) {
    const totalCompleted = item.totalTodoCount - item.incompleteTodoCount;
    const totalCompletedFormatted = numberFormat(totalCompleted);
    const totalFormatted = numberFormat(item.totalTodoCount);
    scoreContext = `${totalCompletedFormatted}/${totalFormatted}`;
  }
  return {
    name: item.name,
    stats: [
      {
        title: scoreTitle,
        value: `${item.score}%`,
        class: "score",
        dataTestid: "score",
        context: scoreContext
      },
      {
        title: "Incomplete",
        value: item.incompleteTodoCount,
        class: "incomplete-count",
        dataTestid: "incomplete-count"
      },
      {
        title: "Avg. Duration",
        value: `${item.averageDuration} ${
          item.averageDuration === 1 ? "day" : "days"
        }`,
        class: "average-duration",
        dataTestid: "average-duration"
      }
    ]
  };
}

getReport();
</script>

<style lang="scss">
.v-application {
  .v-card.score,
  .v-card.incomplete-count,
  .v-card.average-duration {
    border-width: 1px !important;
    border-left-width: 5px !important;
    border-style: solid !important;
  }
  .v-card.score {
    border-color: green !important;
  }
  .v-card.incomplete-count {
    border-color: blue !important;
  }
  .v-card.average-duration {
    border-color: orange !important;
  }
}
</style>
