<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-card flat>
            <v-card-title> Appointment Information </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" lg="4">
                  <text-field
                    v-model="name"
                    label="Advisor Code"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiPound"
                    data-testid="advisor-code"
                    :disabled="!isGroupTwoPlus"
                    :success="nameValidation.success"
                    :error-messages="nameValidation.errorMessages"
                    @update:model-value="updateAttribute('name')"
                  >
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.name" />
                    </template>
                  </text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <select-field
                    v-model="linesOfAuthority"
                    label="Lines of Insurance"
                    placeholder="Select Lines"
                    :prepend-inner-icon="mdiCubeScan"
                    multiple
                    data-testid="lines-of-authority"
                    :disabled="!isGroupTwoPlus"
                    :items="LINES"
                    :success="Boolean(linesOfAuthority)"
                    @update:model-value="updateAttribute('linesOfAuthority')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.linesOfAuthority"
                      />
                    </template>
                  </select-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <select-field
                    v-model="status"
                    label="Status"
                    :prepend-inner-icon="mdiListStatus"
                    data-testid="status"
                    :disabled="!isGroupTwoPlus"
                    :items="APPOINTMENT_STATUSES"
                    :success="statusValidation.success"
                    :error-messages="statusValidation.errorMessages"
                    @update:model-value="updateStatus"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.status"
                      />
                    </template>
                  </select-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <text-field
                    v-model="insuredName"
                    data-lpignore="true"
                    :prepend-inner-icon="mdiAccount"
                    data-testid="applicant-name"
                    label="Applicant Name"
                    :success="Boolean(insuredName)"
                    :disabled="!isGroupTwoPlus"
                    @update:model-value="updateAttribute('insuredName')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.insuredName"
                      />
                    </template>
                  </text-field>
                </v-col>
                <template v-if="hasCarrier">
                  <v-col v-if="isGroupTwoPlus" cols="12" lg="4">
                    <select-field
                      v-model="compBuilder"
                      label="Comp Builder"
                      placeholder="Select Comp Builder"
                      item-value="id"
                      item-title="name"
                      :prepend-inner-icon="mdiHandSaw"
                      data-testid="comp-builder"
                      :success="compBuilderValidation.success"
                      :error-messages="compBuilderValidation.errorMessages"
                      :items="compBuilders"
                      @update:model-value="updateAttribute('compBuilder')"
                    >
                      <template #append-inner>
                        <active-save-indicator
                          :controller="savingBuffer.compBuilder"
                        />
                      </template>
                    </select-field>
                  </v-col>

                  <v-col cols="12" lg="4">
                    <select-field
                      v-model="productId"
                      :prepend-inner-icon="mdiCube"
                      label="Product"
                      placeholder="Select Product"
                      item-title="name"
                      item-value="id"
                      data-testid="product"
                      :disabled="!isGroupTwoPlus"
                      :items="carrier.products"
                      :success="productIdValidation.success"
                      :error-messages="productIdValidation.errorMessages"
                      @update:model-value="updateAttribute('productId')"
                    >
                      <template #append-inner>
                        <active-save-indicator
                          :controller="savingBuffer.productId"
                        />
                      </template>
                    </select-field>
                  </v-col>
                </template>
                <v-col cols="12" lg="4">
                  <state-select
                    v-model="states"
                    multiple
                    data-testid="state-select"
                    :disabled="!isGroupTwoPlus"
                    :success="statesValidation.success"
                    :error-messages="statesValidation.errorMessages"
                    @update:model-value="updateAttribute('states')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.states"
                      />
                    </template>
                  </state-select>
                </v-col>
                <v-col cols="12" lg="4">
                  <div class="checkbox-width">
                    <checkbox-field
                      :model-value="annualized"
                      class="mt-1"
                      dense
                      data-testid="annualized"
                      readonly
                      :disabled="!isGroupTwoPlus"
                      :success="Boolean(annualized)"
                      @click="toggleAnnualized"
                    >
                      <template #label>
                        Annualized
                        <active-save-indicator
                          :controller="savingBuffer.annualized"
                        />
                      </template>
                    </checkbox-field>
                  </div>
                </v-col>
                <v-col
                  v-if="linesOfAuthority.includes('Life')"
                  cols="12"
                  lg="4"
                >
                  <div class="checkbox-width">
                    <checkbox-field
                      v-model="ltcRider"
                      class="mt-1"
                      data-testid="ltc"
                      :disabled="!isGroupTwoPlus"
                      :success="Boolean(ltcRider)"
                      @update:model-value="updateAttribute('ltcRider')"
                    >
                      <template #label>
                        LTC Rider
                        <active-save-indicator
                          :controller="savingBuffer.ltcRider"
                        />
                      </template>
                    </checkbox-field>
                  </div>
                </v-col>
                <v-col cols="12" lg="4">
                  <assignment-input
                    :id="id"
                    v-model="assignment"
                    data-testid="assignment"
                    :success="Boolean(assignment?.id)"
                    :disabled="!isGroupTwoPlus"
                    @update:model-value="updateAttribute('assignment')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.assignment"
                      />
                    </template>
                  </assignment-input>
                </v-col>
                <v-col v-if="isGroupTwoPlus" cols="12" lg="4">
                  <div class="checkbox-width">
                    <checkbox-field
                      :model-value="commissionable"
                      class="mt-1"
                      dense
                      readonly
                      data-testid="backnine-appointment"
                      label="BackNine Appointment"
                      @click="updateCommissionable"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card flat>
            <v-card-title> Appointment Dates </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="3">
                  <date-input
                    v-model="applicationSignedDate"
                    :prepend-inner-icon="mdiCalendarEdit"
                    label="Application Sign Date"
                    data-testid="application-sign-date"
                    :success="Boolean(applicationSignedDate)"
                    :disabled="!isGroupTwoPlus"
                    clearable
                    @update:model-value="updateAttribute('applicationSignedDate')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.applicationSignedDate"
                      />
                    </template>
                  </date-input>
                </v-col>
                <v-col cols="12" lg="3">
                  <date-input
                    v-model="effectiveDate"
                    :prepend-inner-icon="mdiCalendarStart"
                    label="Effective"
                    clearable
                    data-testid="effective-date"
                    :success="Boolean(effectiveDate)"
                    :disabled="!isGroupTwoPlus"
                    @update:model-value="updateAttribute('effectiveDate')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.effectiveDate"
                      />
                    </template>
                  </date-input>
                </v-col>
                <v-col cols="12" lg="3">
                  <date-input
                    v-model="terminatedDate"
                    :prepend-inner-icon="mdiCalendarEnd"
                    label="Terminated"
                    clearable
                    data-testid="terminated"
                    :disabled="!isGroupTwoPlus"
                    :success="Boolean(terminatedDate)"
                    @update:model-value="updateAttribute('terminatedDate')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.terminatedDate"
                      />
                    </template>
                  </date-input>
                </v-col>
                <v-col cols="12" lg="3">
                  <date-input
                    v-model="followUpDate"
                    :prepend-inner-icon="mdiCalendarAccount"
                    label="Follow Up Date"
                    clearable
                    data-testid="follow-up"
                    :disabled="!isGroupTwoPlus"
                    :success="followUpDateValidation.success"
                    :error-messages="followUpDateValidation.errorMessages"
                    @update:model-value="updateAttribute('followUpDate')"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.followUpDate"
                      />
                    </template>
                  </date-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import StateSelect from "@/components/shared/StateSelect.vue";
import AssignmentInput from "@/components/shared/AssignmentInput.vue";
import DateInput from "@/components/shared/DateInput.vue";

import { LINES, APPOINTMENT_STATUSES } from "@/factories/Appointment";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useAppointmentView } from "@/stores/appointment-view";

import { useActiveSave } from "@/composables/active-save.composable";

import { computed } from "vue";
import { validationComputeV2 } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";

import {
  mdiPound,
  mdiCubeScan,
  mdiListStatus,
  mdiAccount,
  mdiHandSaw,
  mdiCube,
  mdiCalendarEdit,
  mdiCalendarStart,
  mdiCalendarEnd,
  mdiCalendarAccount
} from "@mdi/js";

const emit = defineEmits(["refresh"]);
const dialog = useDialogStore();

const { isGroupTwoPlus } = storeToRefs(useUserStore());

const appointment = useAppointmentView();

const {
  annualized,
  applicationSignedDate,
  assignment,
  commissionable,
  carrier,
  compBuilder,
  compBuilders,
  effectiveDate,
  followUpDate,
  id,
  insuredName,
  linesOfAuthority,
  ltcRider,
  name,
  ownable,
  productId,
  states,
  status,
  terminatedDate
} = storeToRefs(appointment);

const savingBuffer = {
  annualized: useActiveSave(),
  applicationSignedDate: useActiveSave(),
  assignment: useActiveSave(),
  commissionable: useActiveSave(),
  compBuilder: useActiveSave(),
  effectiveDate: useActiveSave(),
  followUpDate: useActiveSave(),
  insuredName: useActiveSave(),
  linesOfAuthority: useActiveSave(),
  ltcRider: useActiveSave(),
  name: useActiveSave(),
  productId: useActiveSave(),
  states: useActiveSave(),
  status: useActiveSave(),
  terminatedDate: useActiveSave()
};

const v$ = useVuelidate(
  {
    compBuilder: { required: v => Boolean(v) },
    followUpDate: { required: v => Boolean(v) },
    name: { required: v => Boolean(v) },
    productId: { required: v => Boolean(v) },
    states: { required: v => Boolean(v) },
    status: { required: v => Boolean(v) }
  },
  {
    compBuilder,
    followUpDate,
    name,
    productId,
    states,
    status
  }
);

function toggleAnnualized() {
  if (!isGroupTwoPlus.value) return;
  let title = "Enable Annualization";
  let annualizedAction = "is not";
  if (annualized.value === true) {
    title = "Disable Annualization";
    annualizedAction = "is";
  }
  const subtitle = `${ownable.value.name}'s ${carrier.value.name} appointment ${name.value} ${annualizedAction} annualized. Are you sure you want to make this change?`;

  dialog.showDialog({
    component: "ConfirmationDialog",
    title,
    subtitle,
    func: () => {
      annualized.value = !annualized.value;
      return updateAttribute("annualized");
    }
  });
}

function updateStatus(v) {
  const isCurrentlyActive = status.value.toLowerCase().indexOf("active") > -1;
  const incomingStatusIsActive = v.toLowerCase().indexOf("active") > -1;
  const shouldPrompt = !isCurrentlyActive && incomingStatusIsActive;
  if (!shouldPrompt) {
    status.value = v;
    updateAttribute("status");
    return;
  }

  let commissionsText = "is not assigning commissions.";
  if (assignment.value?.id && assignment.value?.id !== id.value) {
    commissionsText = `is assigning commissions to ${assignment.value?.ownable?.name}.`;
  }
  dialog
    .showDialog({
      component: "ConfirmationDialog",
      title: "Confirm Active Appointment Status",
      subtitle: `${ownable.value.name}'s ${carrier.value.name} appointment ${name.value} ${commissionsText}`
    })
    .then(result => {
      if (result?.confirm) {
        status.value = v;
        updateAttribute("status");
      }
    });
}

function updateCommissionable() {
  const v = !commissionable.value;
  let subtitleText = `${ownable.value.name}'s ${carrier.value.name} appointment`;
  if (v) subtitleText += " is through BackNine and is commissionable";
  else subtitleText += " is not through BackNine and is not commissionable";
  subtitleText += ". Are you sure you want to make this change?";

  dialog
    .showDialog({
      component: "ConfirmationDialog",
      title: "Confirm BackNine Appointment",
      subtitle: subtitleText
    })
    .then(result => {
      if (result?.confirm) {
        commissionable.value = v;
        updateAttribute("commissionable");
      }
    });
}

const hasCarrier = computed(() => carrier.value?.id);

const nameValidation = computed(() => {
  return validationComputeV2(v$.value.name, [
    { key: "required", message: "Required" }
  ]);
});

const statusValidation = computed(() => {
  return validationComputeV2(v$.value.status, [
    { key: "required", message: "Required" }
  ]);
});

const compBuilderValidation = computed(() => {
  return validationComputeV2(v$.value.compBuilder, [
    { key: "required", message: "Required" }
  ]);
});

const productIdValidation = computed(() => {
  return validationComputeV2(v$.value.productId, [
    { key: "required", message: "Required" }
  ]);
});

const statesValidation = computed(() => {
  return validationComputeV2(v$.value.states, [
    { key: "required", message: "Required" }
  ]);
});

const followUpDateValidation = computed(() => {
  return validationComputeV2(v$.value.followUpDate, [
    { key: "required", message: "Required" }
  ]);
});

function updateAttribute(attribute) {
  if (!isGroupTwoPlus.value) return;
  // check if valid?
  savingBuffer[attribute].debounceUpdate(async () => {
    await appointment.update(attribute);
    if (attribute === "assignment") {
      emit("refresh");
    }
  }, 250);
}
</script>
