<template>
  <v-card
    v-for="stat in stats"
    :key="stat.text"
    :data-testid="stat.dataTestid"
    variant="outlined"
    class="ma-1 inline-block"
  >
    <v-card-text v-if="stat.type === 'loading'" class="pa-2">
      <v-progress-circular indeterminate size="18" width="2" />
    </v-card-text>
    <template v-else>
      <v-row class="ma-0 pa-2" align="center" style="font-size: 1rem">
        <span class="text-grey pr-1"> {{ stat.text }}: </span>
        <span>
          <animated-number
            v-if="stat.type === 'number'"
            :model-value="stat.value"
            :format-value="numberFormat"
            :duration="1000"
            :round="1"
          />
          <animated-number
            v-else-if="stat.type === 'dollar'"
            :model-value="stat.value"
            :format-value="v => currencyFormat(v, 0)"
            :duration="1000"
            :round="1"
          />
          <animated-number
            v-else-if="stat.type === 'percent'"
            :model-value="stat.value"
            :format-value="percentageFormat"
            :duration="1000"
            :round="1"
          />
          <animated-number
            v-else-if="stat.type === 'fractionalPercent'"
            :model-value="stat.value"
            :format-value="percent"
            :duration="1000"
            :round="1"
          />
          <animated-number
            v-else
            :model-value="stat.value"
            :duration="1000"
            :round="1"
          />
        </span>
        <app-button
          v-if="stat.hint"
          density="compact"
          size="small"
          variant="text"
          icon
          class="ml-1 rounded-circle table-btn"
          @click="emit('hint', stat)"
        >
          <v-icon :icon="mdiInformation" size="20" />
        </app-button>
      </v-row>
    </template>
  </v-card>
</template>

<script setup>
import AnimatedNumber from "@/components/shared/AnimatedNumber.vue";
import { currencyFormat, numberFormat, percentageFormat } from "@/util/helpers";
import { mdiInformation } from "@mdi/js";
const emit = defineEmits(["hint"]);
defineProps({
  stats: {
    required: false,
    type: Array,
    default: value => (value && value.length ? value : [])
  }
});
</script>
