<template>
  <v-card v-if="positionallySortedFields.length" color="section" tile flat>
    <v-card-text>
      <v-list
        dense
        style="height: 500px; overflow-y: auto; background-color: transparent"
        class="py-0"
      >
        <mapped-form-view-aqls-by-visibility-item
          v-for="question in positionallySortedFields"
          :key="question.id"
          :pdf-field-type="question.pdfFieldType"
          :question-id="question.id"
          :current-field-id="currentFieldId"
          :readonly="readonly"
          @click="formStore.changeActiveField(question.id)"
          @delete="deleteField(question.id)"
        />
      </v-list>
    </v-card-text>
  </v-card>
  <v-card v-else flat tile color="section">
    <v-card-subtitle>
      <v-row class="pa-3" align="center"> No Questions Found </v-row>
    </v-card-subtitle>
  </v-card>
</template>

<script setup>
import MappedFormViewAqlsByVisibilityItem from "@/components/form-mapping/MappedFormViewAqlsByVisibilityItem.vue";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
const props = defineProps({
  formId: { type: [String, Number], required: true }
});

const dialog = useDialogStore();

const formStore = useMappedFormStore(props.formId);
const { positionallySortedFields, currentFieldId, readonly } =
  storeToRefs(formStore);

function deleteField(fieldId) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want to delete Application Question Link?",
    subtitle: "This cannot be undone.",
    func: () => formStore.deleteApplicationQuestionLink(fieldId)
  });
}
</script>
