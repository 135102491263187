<template>
  <autocomplete-field
    ref="productSearch"
    v-model="product"
    v-model:search="productSearch"
    autocomplete="false"
    :prepend-inner-icon="mdiCube"
    placeholder="Search Products"
    label="Product"
    item-title="name"
    return-object
    no-filter
    hide-no-data
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="products"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
  >
    <template v-if="hasAppendOuterSlot" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script>
import { productSimpleSearch } from "@/api/products.service";
import { mdiCube } from "@mdi/js";
export default {
  props: {
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    modelValue: { type: [Object, String], default: () => {} },
    lines: {
      required: false,
      type: Array,
      default: () => []
    },
    disabled: Boolean,
    hideDetails: Boolean
  },
  emits: ["update:model-value", "update:modelValue", "blur"],
  data() {
    let products = [];
    let product = null;
    if (
      this.modelValue &&
      Object.keys(this.modelValue).length &&
      this.modelValue.id
    ) {
      products = [this.modelValue];
      product = this.modelValue;
    }
    return {
      productSearch: "",
      productTimer: null,
      loading: false,
      products,
      product,
      mdiCube
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append"];
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.product = null;
        this.products = [];
        return;
      }
      this.product = value;
      this.products = [value];
    },
    product(value) {
      this.$emit("update:model-value", value);
    },
    productSearch(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (
        this.products.some(val => val?.name === value) &&
        this.products.length === 1
      ) {
        return;
      }

      if (this.productTimer) {
        clearTimeout(this.productTimer);
      }

      this.productTimer = setTimeout(() => {
        this.loading = true;
        productSimpleSearch(value, this.lines)
          .then(response => {
            this.products = response.data.products.toSorted((a, b) =>
              a.name.localeCompare(b.name)
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      if (!this.$refs.productSearch?.$el) return;
      const input = this.$refs.productSearch?.$el.querySelector("input");
      if (!input?.focus) return;
      input.focus();
    },
    clear() {
      this.product = null;
      this.products = [];
    }
  }
};
</script>
