import { defineStore } from "pinia";
const DEFAULT_SNACKBAR_TIMEOUT = 2000;
const SUCCESS = "accent";
const ERROR = "error";
const INFO = "info";

export const useSnackbarStore = defineStore("snackbar", {
  state: () => ({
    show: false,
    message: null,
    timeout: 4000,
    color: null,
    buttonText: null,
    promise: {
      resolve: null,
      reject: null
    }
  }),
  actions: {
    showSnackbar({ message, timeout, color, buttonText }) {
      this.message = message;
      this.timeout = timeout;
      this.color = color;
      this.buttonText = buttonText;
      this.show = true;
    },
    showInfoSnackbar({ message, timeout = DEFAULT_SNACKBAR_TIMEOUT }) {
      this.showSnackbar({ message, timeout, color: INFO });
    },
    async showSuccessSnackbar({
      message,
      buttonText,
      timeout = DEFAULT_SNACKBAR_TIMEOUT
    }) {
      const promise = new Promise((resolve, reject) => {
        this.promise.resolve = resolve;
        this.promise.reject = reject;
      });
      this.showSnackbar({ message, timeout, color: SUCCESS, buttonText });
      return promise;
    },
    showErrorSnackbar({ message, timeout = DEFAULT_SNACKBAR_TIMEOUT }) {
      this.showSnackbar({ message, timeout, color: ERROR });
    },
    hideSnackbar() {
      this.show = false;
      this.promise.resolve = null;
      this.promise.reject = null;
    }
  }
});
