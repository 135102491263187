export const ROUTES = {
  AGENCY_SETTINGS: "AgencyEdit",
  AGENCY: "AgencyView",
  AGENT_SETTINGS: "AgentEdit",
  AGENT: "AgentView",
  APPOINTMENT: "AppointmentView",
  APPROVED_DOMAIN: "ApprovedDomains",
  CARRIER: "CarrierView",
  CASE: "CaseView",
  CONTRACT_PARTY: "ContractPartyView",
  E_APP: "ElectronicApplicationView",
  IMPAIRED_RISK_QUOTE: "ImpairedRiskQuoteView",
  INDIVIDUAL: "IndividualView",
  PAY_PERIOD: "PayPeriodView",
  PERSONNEL: "PersonnelView",
  PRODUCT: "ProductView",
  QUOTE: "QuoteView",
  STATEMENT: "StatementView",
  SUPPORT_TICKET: "SupportTicketView"
};
