import { defineStore } from "pinia";
import { QUOTE_ROLES, QuoteRequest } from "@/factories/Quote.js";
import * as QUOTE_TYPE from "@/constants/quote-types.constants.js";
import { createQuote } from "@/api/quotes.service.js";
import { useUserStore } from "@/stores/user";
import { isValid, parse } from "date-fns";
import { productTypeToRequestValue } from "@/factories/Quote";

export const useQuoteRequestStore = defineStore("quote-request", {
  state: () => ({
    ...QuoteRequest(),
    hasJointInsured: false
  }),
  getters: {
    displayHasJointInsured() {
      return [QUOTE_TYPE.ANNUITY, QUOTE_TYPE.LTC, QUOTE_TYPE.LIFE].includes(
        this.line
      );
    },
    showConcept() {
      return this.line === QUOTE_TYPE.LIFE;
    },
    birthdate() {
      try {
        const birthdate = this.insured.ownable.birthdate;
        const date = parse(birthdate, "yyyy-MM-dd", new Date());
        if (!isValid(date)) return null;
        return date;
      } catch (e) {
        return null;
      }
    }
  },
  actions: {
    async submit() {
      const quote = this.getQuoteBody();
      const quoteId = await createQuote({ quote });
      return quoteId;
    },
    getQuoteBody() {
      const user = useUserStore();
      const isAnnuity = this.line === QUOTE_TYPE.ANNUITY;
      const isDisability = this.line === QUOTE_TYPE.DISABILITY;
      const isLife = this.line === QUOTE_TYPE.LIFE;
      const isLTC = this.line === QUOTE_TYPE.LTC;

      const body = {
        line: this.line,
        quote_roles_attributes: [],
        opportunities_attributes: []
      };

      const isRequestor =
        this.agent.id === user.loginable.id &&
        [this.agent.type?.model, this.agent.type].includes(user.loginable.type);

      body.opportunities_attributes.push({
        agent: true,
        ownable_id: this.agent.id,
        ownable_type: this.agent.type?.model || this.agent.type,
        requestor: isRequestor
      });

      if (!isRequestor) {
        body.opportunities_attributes.push({
          agent: false,
          ownable_id: user.loginable.id,
          ownable_type: user.loginable.type,
          requestor: true
        });
      }

      let insuredAttributes = {
        ownable_attributes: {
          ownable_type: "Individual",
          first_name: this.insured.ownable.firstName,
          last_name: this.insured.ownable.lastName,
          name: `${this.insured.ownable.firstName} ${this.insured.ownable.lastName}`,
          gender: this.insured.ownable.gender,
          birthdate: this.insured.ownable.birthdate
        },
        role: isAnnuity ? QUOTE_ROLES.ANNUITANT : QUOTE_ROLES.INSURED
      };

      if (isLife || isLTC) {
        insuredAttributes = {
          ...insuredAttributes,
          underwriting_class: this.insured.underwritingClass
        };
      }

      if (isLTC) {
        insuredAttributes.ownable_attributes = {
          ...insuredAttributes.ownable_attributes,
          marital_status: this.insured.ownable.maritalStatus
        };
      }

      if (isDisability) {
        insuredAttributes.ownable_attributes = {
          ...insuredAttributes.ownable_attributes,
          income: this.insured.ownable.income,
          occupations_attributes: [
            { occupation: this.insured.occupationAndDuties }
          ],
          tobacco: this.smoker
        };
      }

      if (this.concept) {
        body.concept = this.concept;
        insuredAttributes.qualified_plan_balance = this.qualifiedPlanBalance;
      }

      body.quote_roles_attributes.push(insuredAttributes);
      if (this.hasJointInsured) {
        const jointInsuredAttributes = {
          ownable_attributes: {
            ownable_type: "Individual",
            first_name: this.jointInsured.ownable.firstName,
            last_name: this.jointInsured.ownable.lastName,
            name: `${this.jointInsured.ownable.firstName} ${this.jointInsured.ownable.lastName}`,
            gender: this.jointInsured.ownable.gender,
            birthdate: this.jointInsured.ownable.birthdate
          },
          role: isAnnuity
            ? QUOTE_ROLES.JOINT_ANNUITANT
            : QUOTE_ROLES.JOINT_INSURED
        };
        if (isLife || isLTC) {
          jointInsuredAttributes.underwriting_class =
            this.insured.underwritingClass;
        }

        body.quote_roles_attributes.push(jointInsuredAttributes);
      }
      if (isDisability) {
        body.product_type = productTypeToRequestValue(this.productType);
        body.state = this.state;
        body.monthly_benefit_type = this.monthlyBenefitSolve;
        body.monthly_benefit = this.monthlyBenefit;
        body.riders = this.riders;
        body.description = this.description;
        if (this.files.length) body.documents_attributes = this.files;
        body.benefit_period = this.benefitPeriod;
        body.elimination_period = this.eliminationPeriod;
        body.existing_coverage = this.existingCoverage;
        body.mode = this.mode;
        body.business_owner = this.businessOwner;

        if (this.existingCoverage) {
          body.existing_benefit_maximum = this.existingBenefitMaximum;
          body.existing_benefit_period = this.existingBenefitPeriod;
          body.existing_elimination_period = this.existingEliminationPeriod;
          body.existing_employer_paid = Boolean(this.existingEmployerPaid);
          body.existing_percentage_maximum = this.existingPercentageMaximum;
        }
      } else if (isLife) {
        body.existing_coverage = false;
        body.product_type = productTypeToRequestValue(this.productType);
        if (this.payDurations.length) {
          body.pay_durations = JSON.stringify(this.payDurations);
        }
        body.state = this.state;
        body.mode = this.mode;
        body.face_amount = this.faceAmount || 0;
        body.premium = this.premium || 0;
        body.description = this.description;
        if (this.files.length) body.documents_attributes = this.files;
        if (this.incomeEnabled) {
          body.income_start_age = this.incomeStartAge;
          body.income_end_age = this.incomeEndAge;
        }
      } else if (isAnnuity) {
        body.type_of_funds = this.typeOfFunds;
        body.product_type = productTypeToRequestValue(this.productType);
        body.premium = this.premium;
        body.state = this.state;
        body.description = this.description;
        if (this.files.length) body.documents_attributes = this.files;
        body.income_start_date = this.incomeStartDate;
        body.income_start_age = this.incomeStartAge;
        // body.income_end_age = this.incomeEndAge;
        body.income_duration_type = this.incomeDurationType;
        body.annuity_guarantee_type = this.annuityGuaranteeType;
        body.certain_period = this.certainPeriod;
        body.surrender_periods = JSON.stringify(this.surrenderPeriods);
      } else if (isLTC) {
        body.benefit_period = this.benefitPeriod;
        body.state = this.state;
        body.mode = this.mode;
        body.monthly_benefit = this.monthlyBenefit;
        body.elimination_period = this.eliminationPeriod;
        body.inflation = this.inflation;
        body.discount = this.discount;
        body.description = this.description;
        if (this.files.length) body.documents_attributes = this.files;
      }

      return body;
    }
  }
});
