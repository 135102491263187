<template>
  <div>
    <h2 class="text-h6 mb-2">{{ props.title }}</h2>
    <p v-if="props.subtitle" class="text-body-2 mb-3">
      {{ props.subtitle }}
    </p>
    <integer-input
      v-model="model"
      :prepend-inner-icon="mdiPound"
      :disabled="props.disabled"
      :data-testid="props.label"
      :label="props.label"
      :min="props.min"
      :max="props.max"
      :success="validation.success && !!(model || model === 0)"
      :error-messages="validation.errorMessages"
      @update:model-value="debounceUpdate"
    >
      <template v-if="props.save" #append-inner>
        <active-save-indicator :controller="savingBuffer.controller.value" />
      </template>
    </integer-input>
  </div>
</template>
<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import {   watch, ref, toRefs } from "vue";
import { mdiPound } from "@mdi/js";
const props = defineProps({
  disabled: Boolean,
  title: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: false,
    default: ""
  },
  model: {
    type: Number,
    required: false,
    default: null
  },
  min: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  },
  validation: {
    type: Object,
    required: true
  },
  save: {
    type: Function,
    required: false,
    default: null
  }
});

const { model: modelWatcher } = toRefs(props);

const emit = defineEmits(["update:model"]);

const model = ref(props.model);

const savingBuffer = useActiveSave();

function debounceUpdate() {
  if (props.save && props.validation.success)
    savingBuffer.debounceUpdate(() => {
      if (props.validation.success) props.save();
    });
}

watch(modelWatcher, v => {
  if (model.value !== v) model.value = v;
});
watch(model, v => {
  if (modelWatcher.value !== v) emit("update:model", v);
});
</script>
