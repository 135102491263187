<template>
  <v-row class="ma-0 dual-link-button">
    <v-tooltip location="top">
      <template #activator="{ props: templateProps }">
        <app-button
          :to="props.leftLink"
          color="primary"
          size="small"
          class="text-none truncate-200 rounded"
          :class="{ 'left-button': props.showRightLink }"
          variant="flat"
          v-bind="templateProps"
        >
          <v-icon size="small" class="mr-1" :icon="props.leftIcon" />
          {{ props.leftText }}
        </app-button>
      </template>
      <span> {{ props.leftTooltip }} </span>
    </v-tooltip>
    <v-tooltip v-if="props.showRightLink" location="top">
      <template #activator="{ props: templateProps }">
        <app-button
          :to="props.rightLink"
          v-bind="templateProps"
          :icon="props.rightIcon"
          variant="flat"
          size="28"
          data-testid="right-link-button"
          color="primary"
          class="px-2 right-button rounded"
        />
      </template>
      <span> {{ props.rightTooltip }} </span>
    </v-tooltip>
  </v-row>
</template>

<script setup>
const props = defineProps({
  leftLink: { type: Object, required: false, default: () => {} },
  leftIcon: { type: String, required: false, default: "" },
  leftText: { type: String, required: false, default: "" },
  leftTooltip: { type: String, required: false, default: "" },
  showRightLink: Boolean,
  rightLink: { type: Object, required: false, default: () => {} },
  rightIcon: { type: String, required: false, default: "" },
  rightTooltip: { type: String, required: false, default: "" }
});
</script>

<style lang="scss">
.v-application {
  .dual-link-button {
    flex-wrap: nowrap;
    .left-button {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right-color: white !important;
      border-right-style: solid;
      border-right-width: 1px;
    }

    .right-button {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      min-width: unset !important;
    }
  }
}
</style>
