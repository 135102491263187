import { getHttpClient } from "@/http-client";

import {
  setElectronicApplicationFromRequest,
  setElectronicApplicationAccessFromRequest,
  setElectronicApplicationQuoteFromRequest,
  setElectronicApplicationTableItemFromRequest,
  setElectronicApplicationTableStatsFromRequest
} from "@/factories/ElectronicApplication";

import { setCommissionSplitFromRequest } from "@/factories/CommissionSplitFactory";

const baseURL = "api/boss/electronic_applications";

export const getElectronicApplication = async id => {
  const { data } = await getHttpClient().get(`${baseURL}/${id}`);
  return setElectronicApplicationFromRequest(data.electronic_application);
};

export async function addElectronicApplicationAccess(
  id,
  electronic_application_access
) {
  const { data } = await getHttpClient().post(`${baseURL}/${id}/accesses`, {
    electronic_application_access
  });
  return setElectronicApplicationAccessFromRequest(
    data?.electronic_application_access
  );
}

export const updateElectronicApplicationAccess = (
  id,
  accessId,
  electronic_application_access
) => {
  return getHttpClient().put(`${baseURL}/${id}/accesses/${accessId}`, {
    electronic_application_access
  });
};

export const removeElectronicApplicationAccess = (id, accessId) => {
  return getHttpClient().delete(`${baseURL}/${id}/accesses/${accessId}`);
};

export const inviteClient = id => {
  return getHttpClient().post(`${baseURL}/${id}/send_intro_message`);
};

export const getResumeEAppUrl = async id => {
  const { data } = await getHttpClient().post(`${baseURL}/${id}/resume`);
  return data?.url;
};

/**
 *
 * @param id
 * @returns {Promise<String>}
 */
export async function requestCarrierEappUrl(id) {
  const { data } = await getHttpClient().post(
    `${baseURL}/${id}/resume_carrier_eapp`
  );
  return data.url;
}

export async function getElectronicApplications(urlParams, cancelToken) {
  let params = urlParams;
  if (!params) params = new URLSearchParams();
  params.append("model", "electronic_applications");

  const res = await getHttpClient().get(baseURL, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  const { electronic_applications, meta } = data;

  const items = electronic_applications.map(
    setElectronicApplicationTableItemFromRequest
  );

  let stats = {};
  if (meta?.stats) {
    stats = setElectronicApplicationTableStatsFromRequest(meta.stats);
  }

  return { items, stats, meta };
}

export function emailElectronicApplications(params) {
  params.model = "electronic_applications";
  return getHttpClient().post(`${baseURL}/email`, params);
}

export async function copyElectronicApplication(id) {
  const { data } = await getHttpClient().post(`${baseURL}/${id}/copy`);
  return data.electronic_application.id;
}

export function destroyElectronicApplication(id) {
  return getHttpClient().delete(`${baseURL}/${id}`);
}

export function voidDocusign(id) {
  return getHttpClient().post(`${baseURL}/${id}/void_envelope`, {
    id: id
  });
}

export function reopenElectronicApplication(id) {
  return getHttpClient().post(`${baseURL}/${id}/reopen`, { id: id });
}

export function redateElectronicApplication(id) {
  return getHttpClient().post(`${baseURL}/${id}/redate`, { id: id });
}

export function resendEnvelope(id) {
  return getHttpClient().post(`${baseURL}/${id}/resend_envelope`, {
    id: id
  });
}

export function unlockEApp(id) {
  return getHttpClient().post(`${baseURL}/${id}/unlock`);
}

export function updateEapp(id, electronic_application) {
  return getHttpClient().put(`${baseURL}/${id}`, {
    electronic_application
  });
}

export async function skipPreappointment(id) {
  const { data } = await getHttpClient().post(
    `${baseURL}/${id}/skip-preappointment`
  );
  return data.message;
}

export async function bypassAppointmentHold(id) {
  const { data } = await getHttpClient().post(
    `${baseURL}/${id}/skip-hold-for-appointment`
  );

  return data.message;
}

export async function getQuoteHistory(id) {
  const { data } = await getHttpClient().get(
    `${baseURL}/${id}/electronic_application_quotes`
  );

  return data.map(rq => setElectronicApplicationQuoteFromRequest(rq));
}

export async function createEAppCommissionSplit(eappId, commission_split) {
  const { data } = await getHttpClient().post(
    `${baseURL}/${eappId}/commission_splits`,
    { commission_split }
  );

  return setCommissionSplitFromRequest(data.commission_split);
}

export async function updateEAppCommissionSplit(
  eappId,
  commissionSplitId,
  commission_split
) {
  const { data } = await getHttpClient().put(
    `${baseURL}/${eappId}/commission_splits/${commissionSplitId}`,
    { commission_split }
  );

  return setCommissionSplitFromRequest(data.commission_split);
}

export function deleteEAppCommissionSplit(eappId, commissionSplitId) {
  return getHttpClient().delete(
    `${baseURL}/${eappId}/commission_splits/${commissionSplitId}`
  );
}
