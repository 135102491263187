<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { formatPercentage } from "@/util/helpers";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true
  },
  decimalLength: {
    type: Number,
    required: false,
    default: 2
  },
  isDecimal: Boolean,
  floor: Boolean
});

const formattedValue = computed(() =>
  formatPercentage(props.modelValue, {
    isDecimal: props.isDecimal,
    floor: props.floor,
    decimalLength: props.decimalLength
  })
);
</script>
