<template>
  <v-card>
    <v-row class="ma-0 pr-3" align="center">
      <v-card-title> Completed eApps by Product Category </v-card-title>
      <v-spacer />
      <carrier-search
        v-model="carrier"
        hide-details
        clearable
        class="mx-3"
        data-testid="carrier-search"
        @update:model-value="getData"
      />
    </v-row>
    <v-divider />
    <v-card-text>
      <v-col cols="12" align="center" class="highcharts-pie">
        <highcharts-graph
          v-if="loaded"
          data-testid="highcharts-graph"
          :options="options"
          style="border-radius: 5px"
        />
        <v-progress-circular v-else indeterminate />
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script setup>
const HighchartsGraph = defineAsyncComponent(() =>
  import("@/components/shared/HighchartsGraph.vue")
);
import {
  getAllCompletedEAppsByProductCategoryReport,
  getMyCompletedEAppsByProductCategoryReport
} from "@/api/reports.service";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref,  defineAsyncComponent } from "vue";
const props = defineProps({
  all: Boolean
});

const snackbar = useSnackbarStore();

const carrier = ref(null);
const loaded = ref(false);
const loading = ref(false);
const options = ref({
  chart: {
    type: "pie",
    styledMode: true,
    style: {
      fontFamily: "Roboto"
    }
  },
  title: {
    text: `${props.all ? "All" : "My"} Completed eApps By Product Category`
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer"
    }
  },
  series: []
});

async function getData() {
  loading.value = true;
  let func = getMyCompletedEAppsByProductCategoryReport;
  if (props.all) {
    func = getAllCompletedEAppsByProductCategoryReport;
  }

  try {
    const params = new URLSearchParams();
    if (carrier.value?.id) {
      params.append("carrier_id", carrier.value.id);
    }
    const r = await func(params);
    options.value.series = r;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
    loaded.value = true;
  }
}

getData();
</script>
