export const STATES = [
  {
    value: "AL",
    title: "Alabama"
  },
  {
    value: "AK",
    title: "Alaska"
  },
  {
    value: "AZ",
    title: "Arizona"
  },
  {
    value: "AR",
    title: "Arkansas"
  },
  {
    value: "CA",
    title: "California"
  },
  {
    value: "CO",
    title: "Colorado"
  },
  {
    value: "CT",
    title: "Connecticut"
  },
  {
    value: "DE",
    title: "Delaware"
  },
  {
    value: "DC",
    title: "Washington, D.C."
  },
  {
    value: "FL",
    title: "Florida"
  },
  {
    value: "GA",
    title: "Georgia"
  },
  {
    value: "GU",
    title: "Guam"
  },
  {
    value: "HI",
    title: "Hawaii"
  },
  {
    value: "ID",
    title: "Idaho"
  },
  {
    value: "IL",
    title: "Illinois"
  },
  {
    value: "IN",
    title: "Indiana"
  },
  {
    value: "IA",
    title: "Iowa"
  },
  {
    value: "KS",
    title: "Kansas"
  },
  {
    value: "KY",
    title: "Kentucky"
  },
  {
    value: "LA",
    title: "Louisiana"
  },
  {
    value: "ME",
    title: "Maine"
  },
  {
    value: "MD",
    title: "Maryland"
  },
  {
    value: "MA",
    title: "Massachusetts"
  },
  {
    value: "MI",
    title: "Michigan"
  },
  {
    value: "MN",
    title: "Minnesota"
  },
  {
    value: "MS",
    title: "Mississippi"
  },
  {
    value: "MO",
    title: "Missouri"
  },
  {
    value: "MT",
    title: "Montana"
  },
  {
    value: "NE",
    title: "Nebraska"
  },
  {
    value: "NV",
    title: "Nevada"
  },
  {
    value: "NH",
    title: "New Hampshire"
  },
  {
    value: "NJ",
    title: "New Jersey"
  },
  {
    value: "NM",
    title: "New Mexico"
  },
  {
    value: "NY",
    title: "New York"
  },
  {
    value: "NC",
    title: "North Carolina"
  },
  {
    value: "ND",
    title: "North Dakota"
  },
  {
    value: "OH",
    title: "Ohio"
  },
  {
    value: "OK",
    title: "Oklahoma"
  },
  {
    value: "OR",
    title: "Oregon"
  },
  {
    value: "PA",
    title: "Pennsylvania"
  },
  {
    value: "PR",
    title: "Puerto Rico"
  },
  {
    value: "RI",
    title: "Rhode Island"
  },
  {
    value: "SC",
    title: "South Carolina"
  },
  {
    value: "SD",
    title: "South Dakota"
  },
  {
    value: "TN",
    title: "Tennessee"
  },
  {
    value: "TX",
    title: "Texas"
  },
  {
    value: "UT",
    title: "Utah"
  },
  {
    value: "VT",
    title: "Vermont"
  },
  {
    value: "VA",
    title: "Virginia"
  },
  {
    value: "WA",
    title: "Washington"
  },
  {
    value: "WV",
    title: "West Virginia"
  },
  {
    value: "WI",
    title: "Wisconsin"
  },
  {
    value: "WY",
    title: "Wyoming"
  }
];

const fullStateDict = {};
STATES.forEach(({ title, value }) => (fullStateDict[value] = title));
export { fullStateDict };

export const stateIds = [
  { value: 2, title: "Alabama" },
  { value: 3, title: "Alaska" },
  { value: 4, title: "Arizona" },
  { value: 5, title: "Arkansas" },
  { value: 1, title: "California" },
  { value: 6, title: "Colorado" },
  { value: 7, title: "Connecticut" },
  { value: 8, title: "Delaware" },
  { value: 9, title: "Washington D.C." },
  { value: 10, title: "Florida" },
  { value: 11, title: "Georgia" },
  { value: 53, title: "Guam" },
  { value: 12, title: "Hawaii" },
  { value: 13, title: "Idaho" },
  { value: 14, title: "Illinois" },
  { value: 15, title: "Indiana" },
  { value: 16, title: "Iowa" },
  { value: 17, title: "Kansas" },
  { value: 18, title: "Kentucky" },
  { value: 19, title: "Louisiana" },
  { value: 20, title: "Maine" },
  { value: 21, title: "Maryland" },
  { value: 22, title: "Massachusetts" },
  { value: 23, title: "Michigan" },
  { value: 24, title: "Minnesota" },
  { value: 25, title: "Mississippi" },
  { value: 26, title: "Missouri" },
  { value: 27, title: "Montana" },
  { value: 28, title: "Nebraska" },
  { value: 29, title: "Nevada" },
  { value: 30, title: "New Hampshire" },
  { value: 31, title: "New Jersey" },
  { value: 32, title: "New Mexico" },
  { value: 33, title: "New York" },
  { value: 34, title: "North Carolina" },
  { value: 35, title: "North Dakota" },
  { value: 36, title: "Ohio" },
  { value: 37, title: "Oklahoma" },
  { value: 38, title: "Oregon" },
  { value: 39, title: "Pennsylvania" },
  { value: 41, title: "Rhode Island" },
  { value: 42, title: "South Carolina" },
  { value: 43, title: "South Dakota" },
  { value: 44, title: "Tennessee" },
  { value: 45, title: "Texas" },
  { value: 46, title: "Utah" },
  { value: 47, title: "Vermont" },
  { value: 48, title: "Virginia" },
  { value: 49, title: "Washington" },
  { value: 50, title: "West Virginia" },
  { value: 51, title: "Wisconsin" },
  { value: 52, title: "Wyoming" }
];
