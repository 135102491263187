import { PERMISSION } from "@/constants/permissions.constants";

export function User(model = {}) {
  return {
    id: model?.id,
    email: model?.email,
    lastSignInAt: model?.lastSignInAt,
    createdAt: model?.createdAt,
    group: model?.group,
    highestGroup: model?.highestGroup,
    permissions: {
      [PERMISSION.FORM_MAPPING]: model?.permissions?.[PERMISSION.FORM_MAPPING],
      [PERMISSION.ACCOUNTING]: model?.permissions?.[PERMISSION.ACCOUNTING],
      [PERMISSION.SCORECARD]: model?.permissions?.[PERMISSION.SCORECARD]
    },
    otpEnrolled: model?.otpEnrolled,
    otpRequiredOn: model?.otpRequiredOn,
    otpRequiredForLogin: model?.otpRequiredForLogin,
    sso: {
      authMethod: model?.sso?.auth_method,
      oauthProvider: model?.sso?.oauth_provider,
      emailDomain: model?.sso?.email_domain
    },
    identities: model?.identities || [],
    logins: model?.logins || [],
    loggedInAs: model?.loggedInAs,
    isLocked: model?.isLocked
  };
}

export function setUserFromRaw(raw = {}) {
  const user = User();
  user.id = raw?.id;
  user.email = raw?.email;
  user.group = raw?.group;
  user.createdAt = raw?.created_at;
  user.highestGroup = raw?.highest_group;
  user.lastSignInAt = raw?.last_sign_in_at;
  user.otpEnrolled = raw?.otp_enrolled;
  user.otpRequiredOn = raw?.otp_required_on;
  user.otpRequiredForLogin = raw?.otp_required_for_login;
  user.isLocked = raw?.is_locked;

  if (raw?.sso) {
    user.sso = {
      authMethod: raw.sso.auth_method,
      oauthProvider: raw.sso.oauth_provider,
      emailDomain: raw.sso.email_domain
    };
  }

  user.permissions = {
    [PERMISSION.FORM_MAPPING]: raw?.permissions?.[PERMISSION.FORM_MAPPING],
    [PERMISSION.ACCOUNTING]: raw?.permissions?.[PERMISSION.ACCOUNTING],
    [PERMISSION.SCORECARD]: raw?.permissions?.[PERMISSION.SCORECARD]
  };

  user.identities = raw.identities || [];

  if (raw?.logins) {
    user.logins = raw.logins.map(login => {
      return {
        name: login.name,
        id: login.id,
        type: login.type,
        lastLogin: login.last_login
      };
    });
  }

  if (raw?.active_login) {
    user.loggedInAs = {
      id: raw.active_login.id,
      type: raw.active_login.type,
      name: raw.active_login.name
    };
  }
  return user;
}

export function UserTableItem(model = {}) {
  return {
    email: model?.email,
    group: model?.group,
    loggedInAs: model?.loggedInAs,
    highestGroup: model?.highestGroup,
    id: model?.id,
    lastSignInAt: model?.lastSignInAt,
    otpEnrolled: model?.otpEnrolled,
    otpRequiredForLogin: model?.otpRequiredForLogin,
    permissions: model?.permissions,
    sso: model?.sso
  };
}

export function setUserTableItemFromRaw(raw = {}) {
  const item = UserTableItem();

  item.email = raw?.email;
  item.group = raw?.group;
  item.highestGroup = raw?.highest_group;
  item.id = raw?.id;
  item.otpEnrolled = raw?.otp_enrolled;
  item.otpRequiredForLogin = raw?.otp_required_for_login;
  item.lastSignInAt = raw?.last_sign_in_at;

  if (raw?.sso) {
    item.sso = {
      authMethod: raw.sso.auth_method,
      oauthProvider: raw.sso.oauth_provider,
      emailDomain: raw.sso.email_domain
    };
  }

  if (raw?.active_login) {
    item.loggedInAs = {
      id: raw.active_login.id,
      type: raw.active_login.type,
      name: raw.active_login.name
    };
  }

  item.permissions = {
    [PERMISSION.FORM_MAPPING]: raw?.permissions?.[PERMISSION.FORM_MAPPING],
    [PERMISSION.ACCOUNTING]: raw?.permissions?.[PERMISSION.ACCOUNTING],
    [PERMISSION.SCORECARD]: raw?.permissions?.[PERMISSION.SCORECARD]
  };

  return item;
}
