<template>
  <autocomplete-field
    ref="searchInput"
    v-model="model"
    v-model:search="search"
    return-object
    no-filter
    hide-no-data
    item-title="domain"
    :data-testid="dataTestid"
    :disabled="disabled"
    :prepend-inner-icon="prependInnerIcon"
    :loading="loading"
    :placeholder="placeholder"
    :label="label"
    :success="success"
    :error-messages="errorMessages"
    :items="items"
    :clearable="clearable"
    :hide-details="hideDetails"
    :persistent-hint="persistentHint"
    :hint="hint"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
    @click:clear="emit('update:model-value', {})"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }} <v-spacer /> <slot name="message" />
      </v-row>
    </template>
  </autocomplete-field>
</template>

<script setup>
import { getDefaultableApprovedDomains } from "@/api/agents.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { ref, watch, onMounted, nextTick, toRef } from "vue";
import { mdiAccount } from "@mdi/js";
const props = defineProps({
  success: Boolean,
  errorMessages: { type: Array, default: () => [] },
  modelValue: { type: Object, default: () => {} },
  disabled: Boolean,
  persistentHint: Boolean,
  hideDetails: Boolean,
  hint: { type: String, default: null },
  clearable: Boolean,
  agentId: { type: [Number, String], required: true },
  dataTestid: {
    type: String,
    required: false,
    default: "approved-domain-search"
  },
  label: {
    required: false,
    default: "Approved Domain",
    type: String
  },
  placeholder: {
    required: false,
    default: "Search Approved Domains",
    type: String
  },
  prependInnerIcon: {
    type: String,
    required: false,
    default: mdiAccount
  }
});

const emit = defineEmits(["update:model-value", "blur"]);
const modelValue = toRef(props, "modelValue");

const items = ref([]);
const model = ref(null);
const search = ref("");
const isMounted = ref(false);
let timer = null;
const loading = ref(false);

function isSettableValue(v) {
  return v && Object.keys(v).length && Object.values(v).filter(Boolean).length;
}

const preExistingValue = isSettableValue(props.modelValue);
if (preExistingValue) {
  items.value.push(props.modelValue);
  // eslint-disable-next-line vue/no-setup-props-destructure
  model.value = props.modelValue;
}

watch(modelValue, value => {
  if (isSettableValue(value)) {
    model.value = value;
    items.value.splice(0, items.value.length);
    items.value.push(value);
  } else {
    model.value = null;
    items.value.splice(0, items.value.length);
  }
});

watch(model, () => {
  if (!model.value?.id && !props.clearable) return;
  if (model.value?.id === props.modelValue?.id) return;
  emit("update:model-value", model.value);
});

watch(search, () => {
  if (!isMounted.value) return;
  if (items.value.some(v => v.domain === search.value)) return;
  if (search.value) debounceAndSearch();
});

const snackbar = useSnackbarStore();

function debounceAndSearch() {
  if (timer) clearTimeout(timer);

  timer = setTimeout(() => searchAdvisors(), 200);
}
async function searchAdvisors() {
  loading.value = true;

  const params = new URLSearchParams();
  params.append("domain", search.value);

  try {
    const domains = await getDefaultableApprovedDomains(props.agentId, params);
    items.value.splice(0, items.value.length);

    items.value.push(...domains);
    items.value.sort((a, b) => a.domain.localeCompare(b.domain));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const searchInput = ref(null); //template ref

function focus() {
  if (!searchInput.value?.$el) return;
  const input = searchInput.value.$el.querySelector("input");
  if (!input?.focus) return;
  input.focus();
}

defineExpose({ focus });

onMounted(() => nextTick(() => (isMounted.value = true)));
</script>
