import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/form_sets";

export const addFormSetToForm = (id, form_id) => {
  return getHttpClient().post(`${baseUrl}/${id}/forms`, { form_id });
};

export const removeFormSetFromForm = (id, form_id) => {
  return getHttpClient().delete(`${baseUrl}/${id}/forms/${form_id}`);
};

export const getAllFormSets = async (carrierId = null) => {
  const params = new URLSearchParams();
  if (carrierId) params.append("carrier_id", carrierId);
  const { data } = await getHttpClient().get(baseUrl, { params });
  return data;
};
