<template>
  <div v-if="loaded" class="ma-1 bg-surface mb-12">
    <case-view-card>
      <template #actions>
        <case-view-actions />
      </template>
    </case-view-card>

    <view-tabs v-model="activeTab" :tabs="tabs" />
    <need-help @click="showChat" />
  </div>
  <v-row v-else>
    <v-col>
      <v-skeleton-loader type="card" />
    </v-col>
  </v-row>
</template>

<script setup>
import ViewTabs from "@/components/shared/ViewTabs.vue";

import CaseViewCard from "@/components/cases/case-view/CaseViewCard.vue";
import CaseViewActions from "@/components/cases/case-view/CaseViewActions.vue";

import CaseViewAccess from "@/components/cases/case-view/CaseViewAccess.vue";
import CaseViewPartiesTabs from "@/components/cases/case-view/CaseViewPartiesTabs.vue";
import CaseViewCommissionTabs from "@/components/cases/case-view/CaseViewCommissionTabs.vue";
import CaseViewChat from "@/components/cases/case-view/CaseViewChat.vue";
import CaseViewDetails from "@/components/cases/case-view/CaseViewDetails.vue";
import CaseViewDocuments from "@/components/cases/case-view/CaseViewDocuments.vue";
import CaseViewTasks from "@/components/cases/case-view/CaseViewTasks.vue";
import CaseViewQuoteTable from "@/components/cases/case-view/CaseViewQuoteTable.vue";
import CaseViewInformalOffers from "@/components/cases/case-view/CaseViewInformalOffers.vue";
import CaseViewToDoList from "@/components/cases/case-view/CaseViewToDoList.vue";

import TimelineGraph from "@/components/shared/TimelineGraph.vue";
import NeedHelp from "@/components/shared/NeedHelp.vue";

import { useHead } from "@unhead/vue";
import { parseErrorMessage } from "@/util/helpers";
import {
  computed,
  onBeforeUnmount,
  ref,
  watch,
  markRaw,
  nextTick,
  toRef
} from "vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useCaseViewStore } from "@/stores/case-view";
import { useRouter } from "vue-router";
import { useCaseViewActiveTaskStore } from "@/stores/case-view-active-task";
import {
  mdiAccountMultiple,
  mdiCalendar,
  mdiCheck,
  mdiCurrencyUsd,
  mdiFile,
  mdiFileDocumentEdit,
  mdiFileTree,
  mdiMessage,
  mdiOffer,
  mdiShieldAccount
} from "@mdi/js";
import { useFullEmailViewer } from "@/composables/full-email.composable";

const head = useHead({ title: "Case Viewer" });

const props = defineProps({
  task: {
    type: String,
    default: null
  },
  id: {
    type: [String, Number],
    required: true
  },
  page: {
    type: String,
    default: "tasks"
  },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  },
  highlightNote: {
    type: Number,
    required: false,
    default: null
  }
});
const highlightTodo = toRef(props, "highlightTodo");

const snackbar = useSnackbarStore();
const { breadcrumb } = storeToRefs(useInstanceStore());
const { loginable } = storeToRefs(useUserStore());
const router = useRouter();

const loaded = ref(false);
const activeTab = ref(0);

const caseView = useCaseViewStore();
const {
  policyNumber,
  LINE,
  incompleteTaskCount,
  quotes,
  daysPending,
  timeline,
  todoCount
} = storeToRefs(caseView);
const canSeeCommissions =
  !loginable.value.hide_commission || !loginable.value.show_backnine;

onBeforeUnmount(() => (breadcrumb.value = ""));
watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

const tabs = computed(() => {
  const tabs = [
    {
      title: "Tasks",
      icon: mdiFileTree,
      page: "tasks",
      component: markRaw(CaseViewTasks),
      badgeProps: incompleteTaskCount.value
        ? {
            floating: true,
            "offset-y": 3,
            color: "error",
            modelValue: Boolean(incompleteTaskCount.value),
            content: incompleteTaskCount.value
          }
        : null
    }
  ];
  if (LINE.value.INFORMAL) {
    tabs.push({
      title: "Informals",
      icon: mdiOffer,
      page: "informals",
      component: markRaw(CaseViewInformalOffers)
    });
  }
  tabs.push(
    {
      title: "Details",
      icon: mdiFileDocumentEdit,
      page: "details",
      component: markRaw(CaseViewDetails)
    },
    {
      title: "Chat",
      icon: mdiMessage,
      page: "chat",
      component: markRaw(CaseViewChat),
      eager: true
    },
    {
      title: "Parties",
      icon: mdiAccountMultiple,
      page: "parties",
      component: markRaw(CaseViewPartiesTabs)
    }
  );

  if (canSeeCommissions && !LINE.value.INFORMAL) {
    tabs.push({
      title: "Commissions",
      icon: mdiCurrencyUsd,
      page: "commissions",
      component: markRaw(CaseViewCommissionTabs)
    });
  }

  tabs.push({
    title: "Documents",
    icon: mdiFile,
    page: "documents",
    component: markRaw(CaseViewDocuments)
  });

  if (quotes.value.length) {
    tabs.push({
      title: "Quotes",
      icon: mdiOffer,
      page: "quotes",
      component: markRaw(CaseViewQuoteTable)
    });
  }

  tabs.push(
    {
      title: "To-Do",
      page: "to-do",
      icon: mdiCheck,
      component: markRaw(CaseViewToDoList),
      componentProps: {
        highlightTodo: highlightTodo.value
      },
      badgeProps: todoCount.value
        ? {
            floating: true,
            "offset-y": 3,
            color: "error",
            content: todoCount.value,
            "data-testid": "todo-badge"
          }
        : null,
      eager: true
    },
    {
      title: "Access",
      icon: mdiShieldAccount,
      page: "access",
      component: markRaw(CaseViewAccess)
    }
  );

  tabs.push({
    title: `${daysPending.value} Days Pending`,
    icon: mdiCalendar,
    page: "timeline",
    component: markRaw(TimelineGraph),
    componentProps: {
      items: timeline.value
    }
  });
  return tabs;
});

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

function clearTaskQuery() {
  if (!router) return;
  if (router.currentRoute.value.query.task) {
    router.replace({ query: { page: "tasks" } });
  }
}

async function showChat() {
  activeTab.value = tabs.value.findIndex(v => v.page === "chat");
  await nextTick();
  document.querySelector('[data-testid="chat-message-input"] textarea')?.focus();
  snackbar.showInfoSnackbar({
    message: "Please use the chat to ask any case questions",
    timeout: 5000,
    color: "info"
  });
}

async function getCase() {
  try {
    useCaseViewActiveTaskStore().$reset();
    useCaseViewStore().$reset();
    let taskPromise;
    const caseViewPromise = caseView.initializeCaseView(props.id);

    if (props.task) {
      const [type, id] = props.task.split("-");
      taskPromise = useCaseViewActiveTaskStore().initializeActiveTask(
        props.id,
        props.task,
        id,
        type
      );
    }
    try {
      await taskPromise;
    } catch (e) {
      useCaseViewActiveTaskStore().$reset();
      snackbar.showErrorSnackbar({
        message: parseErrorMessage(e),
        timeout: 5000
      });
      clearTaskQuery();
    }
    await caseViewPromise;

    loaded.value = true;
    await nextTick();
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;

    if (props.highlightNote) {
      router.replace({ query: { "highlight-note": undefined } });
      const fullEmailViewer = useFullEmailViewer(props.highlightNote);
      fullEmailViewer.retrieveFullEmail();
    }
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 5000
    });
    router.push({ name: "Home" });
  }
}

watch(policyNumber, () => {
  breadcrumb.value = policyNumber.value;
  head.patch({ title: policyNumber.value });
});

getCase();
</script>
