<template>
  <div>
    <div v-for="(value, key, index) in props.changes" :key="index">
      <pre>{{ key }}:</pre>
      <span class="text-error">
        {{ value[0] == null ? "null" : value[0] }}
      </span>
      =>
      <span class="text-success">
        {{ value[1] == null ? "null" : value[1] }}
      </span>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  changes: {
    type: Object,
    required: true
  }
});
</script>
