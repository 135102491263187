<template>
  <v-card>
    <v-card-title>Add Build</v-card-title>
    <v-row dense class="px-5 ma-0">
      <v-col cols="12" md="6">
        <autocomplete-field
          v-model="newBuild.buildChart"
          label="Build Chart"
          data-testid="build-chart-search"
          :loading="buildCharts.loading"
          :items="allBuildCharts"
          :success="Boolean(newBuild.buildChart)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <autocomplete-field
          v-model="newBuild.rating"
          label="Rating"
          data-testid="rating-search"
          :items="ratings"
          :success="Boolean(newBuild.rating)"
        />
      </v-col>
    </v-row>
    <v-card-text id="build-box" class="pt-1">
      <build-form
        v-for="key in newBuild.builds.keys"
        :key="key"
        :ref="el => (newBuild.builds.refs_by_key[key] = el)"
        v-model="newBuild.builds.by_key[key]"
        :build-chart-id="newBuild.buildChart"
        :rating="newBuild.rating"
        :dense="newBuild.builds.keys.length > 1"
        :validation-scope="validationScope"
        @deleted="deleteBuild(key)"
      />
      <div id="build-box-bottom"></div>
    </v-card-text>
    <v-divider />
    <v-row dense align="center" class="ma-0 py-3 px-6">
      <v-col cols="3">
        <integer-input
          v-model="min"
          label="Min Inches"
          hide-details
          data-testid="min-generator-input"
        />
      </v-col>
      <v-col cols="3">
        <integer-input
          v-model="max"
          data-lpignore="true"
          label="Max Inches"
          hide-details
          data-testid="max-generator-input"
        />
      </v-col>
      <v-col cols="3">
        <app-button
          block
          class="text-none"
          color="accent"
          data-testid="generate"
          @click="generateRows"
        >
          Generate
        </app-button>
      </v-col>
      <v-col cols="3">
        <app-button
          block
          class="text-none"
          data-testid="add-build"
          @click="addBuild"
        >
          +1 Build
        </app-button>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        {{ newBuild.builds.keys.length === 1 ? "Cancel" : "Close" }}
      </app-button>
      <app-button
        v-if="newBuild.builds.keys.length === 1"
        class="text-none"
        color="primary"
        data-testid="save-build"
        :loading="savingBuild"
        @click="saveFirstBuild()"
      >
        {{ value?.id ? "Update" : "Create" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import BuildForm from "@/components/builds/BuildForm.vue";

import { parseErrorMessage, uuidv4 } from "@/util/helpers";
import { ratingsForLines } from "@/data/case-data";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { ref, nextTick, computed } from "vue";
import { Build } from "@/factories/Build";
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { storeToRefs } from "pinia";

const props = defineProps({
  modelValue: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const searchItemsCache = useSearchItemsCache();

const { buildCharts } = storeToRefs(searchItemsCache);

const allBuildCharts = computed(() => {
  const available = [];
  const disabled = [];
  buildCharts.value.items.forEach(item => {
    if (item.additional.isCompleted) {
      disabled.push({
        ...item,
        title: `${item.text} (Completed)`,
        disabled: true
      });
    } else {
      available.push(item);
    }
  });

  return [...available, ...disabled];
});

const min = ref(null);
const max = ref(null);
const savingBuild = ref(false);
const ratings = ref(ratingsForLines(["life", "ltc"]));

const newBuild = ref({
  buildChart: props.modelValue?.buildChart?.id || null,
  rating: props.modelValue?.rating || null,
  builds: {
    by_key: {},
    refs_by_key: {},
    keys: []
  }
});

if (props.modelValue?.id) addBuild(props.modelValue);
else addBuild();

const validationScope = "build-dialog";

function generateRows() {
  if (!min.value || !max.value) return;

  if (newBuild.value.builds.keys.length === 1) {
    const firstKey = newBuild.value.builds.keys[0];
    const firstBuild = newBuild.value.builds.by_key[firstKey];
    if (!firstBuild.inches && !firstBuild.minWeight && !firstBuild.maxWeight) {
      deleteBuild(firstKey);
    }
  }

  for (let i = min.value; i <= max.value; i++) {
    addBuild({ inches: i }, false);
  }

  min.value = null;
  max.value = null;
}

function deleteBuild(key) {
  delete newBuild.value.builds.by_key[key];
  delete newBuild.value.builds.refs_by_key[key];
  const index = newBuild.value.builds.keys.findIndex(k => k === key);
  if (index > -1) newBuild.value.builds.keys.splice(index, 1);
}

function addBuild(defaults = { inches: null, ...Build() }, scrollTo = true) {
  const key = uuidv4();
  newBuild.value.builds.keys.push(key);
  newBuild.value.builds.by_key[key] = Build(defaults);
  newBuild.value.builds.refs_by_key[key] = {};

  if (!scrollTo) return;
  goToBottom();
}

function goToBottom() {
  nextTick(() => {
    const container = document.getElementById("build-box");
    container
      .querySelector("#build-box-bottom")
      .scrollIntoView({ behavior: "smooth" });
  });
}

async function fetchBuildCharts() {
  try {
    await searchItemsCache.getBuildCharts();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  }
}

async function saveFirstBuild() {
  const key = newBuild.value.builds.keys[0];
  savingBuild.value = true;
  const result = await newBuild.value.builds.refs_by_key[key].saveBuild();
  savingBuild.value = false;
  if (result) dialog.closeDialog({ created: true });
}

fetchBuildCharts();
</script>

<style lang="scss">
.many-builds {
  overflow-y: scroll;
  -moz-box-shadow: inset 0 0 3px #000000;
  -webkit-box-shadow: inset 0 0 3px #000000;
  box-shadow: inset 0 0 3px #000000;
  border-radius: 4px;
}
</style>
