<template>
  <v-card>
    <v-card-title>
      <v-row class="pa-3 pr-0">
        Top Cases <v-spacer />
        <app-button
          id="v-step-4"
          variant="text"
          color="primary"
          class="text-none"
          :prepend-icon="mdiOpenInNew"
          text="View Cases"
          :to="{ name: 'CasesTable' }"
        />
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-list>
        <template v-if="props.cases.length">
          <v-list-item
            v-for="(item, index) in props.cases"
            :key="index"
            link
            lines="two"
            :to="getRouterLink('Case', item.id)"
            class="green-list-item rounded"
          >
            <v-list-item-title>
              {{ item.insuredName }} - {{ item.status }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-row class="py-3">
                <v-col class="pb-0 pt-2">
                  {{ item.carrierName }}
                </v-col>
                <v-col align="end" class="pb-0 pt-2">
                  Credit:
                  <strong>
                    <currency-formatter
                      :model-value="item.productionCreditTotal"
                      :decimal-length="0"
                    />
                  </strong>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item lines="two" class="ma-1 secondary rounded">
            <v-list-item-title>
              Your top cases will appear here
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script setup>
import { mdiOpenInNew } from "@mdi/js";
import { getRouterLink } from "@/util/helpers";

import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
const props = defineProps({
  cases: {
    type: Array,
    required: true
  }
});
</script>
