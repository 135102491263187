import { getHttpClient } from "@/http-client";

const baseURL = "api/boss/campaigns";

export async function getCampaigns(params, cancelToken) {
  const res = await getHttpClient().get(baseURL, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;

  return { items: res.data.campaigns, meta: res.data.meta };
}
