<template>
  <text-field
    v-model="npn"
    data-lpignore="true"
    :prepend-inner-icon="mdiPound"
    label="NPN"
    type="text"
    inputmode="numeric"
    class="no-tick has-append-button pr-0"
    :loading="loading"
    :disabled="disabled"
    :error-messages="errorMessages"
    :success="success"
    persistent-hint
    hint=" "
    @update:model-value="handleTyping"
  >
    <template v-if="!disabled" #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot v-if="$slots.message" name="message" />
        <a href="https://www.nipr.com/PacNpnSearch.htm" target="_blank">
          Don't know your NPN?
        </a>
      </v-row>
    </template>
    <template v-if="hasAppendedSlot" #append-inner>
      <slot name="append-inner" />
    </template>
  </text-field>
</template>

<script>
import { mdiPound } from "@mdi/js";
import { validateNpn } from "@/api/nipr.service";
import { parseErrorMessage } from "@/util/helpers";
export default {
  props: {
    validate: Boolean,
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    modelValue: { type: [String, Number], default: null },
    disabled: Boolean
  },
  emits: ["update:model-value", "valid", "error-message"],
  data() {
    return {
      npn: this.modelValue || null,
      niprTimer: null,
      isTyping: false,
      typingTimer: null,
      loading: false,
      mdiPound
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append"];
    },
    hasAppendedSlot() {
      return !!this.$slots["append"];
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue === this.npn) return;
      this.npn = this.modelValue;
    }
  },
  methods: {
    handleTyping() {
      this.$emit("update:model-value", this.npn);

      if (!this.validate) return;

      this.isTyping = true;
      this.$emit("valid", null);
      if (this.typingTimer) clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.isTyping = false;
      }, 500);

      this.debounceAndValidate();
    },
    debounceAndValidate() {
      if (this.niprTimer) clearTimeout(this.niprTimer);
      this.niprTimer = setTimeout(this.validateNpn, 1000);
    },
    async validateNpn() {
      this.$emit("error-message", "");
      if (!this.npn) {
        this.$emit("valid", false);
        return;
      }
      const params = {
        npn: this.npn
      };

      try {
        this.loading = true;
        const { data } = await validateNpn(params);
        this.loading = false;
        if (data?.valid === true) {
          this.$emit("valid", true);
          return;
        }

        this.$emit("valid", false);
        this.$emit("error-message", data?.message);
      } catch (e) {
        this.$emit("valid", false);
        this.$emit("error-message", parseErrorMessage(e));
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
