<template>
  <v-card class="ma-1">
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        must-sort
        data-testid="backnine-statements-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :loading="table.loading.value"
        :footer-props="table.footerProps.value"
        :items-per-page-options="table.itemsPerPageOptions"
        :mobile="null"
        mobile-breakpoint="sm"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0 mb-3">
            <h3 class="text-h4">Statements</h3>
            <v-spacer />
            <app-button
              variant="text"
              class="table-btn"
              density="comfortable"
              data-testid="statement-create"
              :icon="mdiPlus"
              :to="{ name: 'StatementCreate' }"
            />
            <app-button
              variant="text"
              class="table-btn"
              density="comfortable"
              :icon="mdiRefresh"
              :loading="table.loading.value"
              data-testid="backnine-statements-table-refresh"
              @click="getData"
            />
          </v-row>
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.id`]="{ item }">
          <router-link :to="{ name: 'StatementView', params: { id: item.id } }">
            {{ item.id }}
          </router-link>
        </template>
        <template #[`item.statementDate`]="{ item }">
          <timestamp-formatter
            :model-value="item.statementDate"
            parser="sole-day"
          />
        </template>
        <template #[`item.premium`]="{ item }">
          <currency-formatter :model-value="item.premium" />
        </template>
        <template #[`item.premiumSum`]="{ item }">
          <currency-formatter :model-value="item.premium_sum" />
        </template>
        <template #[`item.amount`]="{ item }">
          <span :class="{ 'text-error': item.amount < 0 }">
            <currency-formatter :model-value="item.amount" />
          </span>
        </template>
        <template #[`item.commissionSum`]="{ item }">
          <currency-formatter :model-value="item.commissionSum" />
        </template>
        <template #[`item.createdAt`]="{ item }">
          <timestamp-formatter :model-value="item.createdAt" />
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import { parseErrorMessage } from "@/util/helpers";

import { TableOptions } from "@/classes/data-table/TableOptions";
import { getBackNineStatementData } from "@/api/statements.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { mdiPlus, mdiRefresh } from "@mdi/js";

useHead({ title: "Statements" });

const snackbar = useSnackbarStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Statement",
      value: "id",
      map: "id",
      sortFilterMap: "statements.id",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Payor",
      value: "payorName",
      map: "payorName",
      sortFilterMap: "payors.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Statement Type",
      value: "statementType",
      map: "statementType",
      sortFilterMap: "statements.statement_type",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      sortFilterMap: "statements.premium",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Premium Sum",
      value: "premiumSum",
      map: "premiumSum",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Commission",
      value: "amount",
      map: "amount",
      sortFilterMap: "statements.commission",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Commission Sum",
      value: "commissionSum",
      map: "commissionSum",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Statement Date",
      value: "statementDate",
      map: "statementDate",
      sortFilterMap: "statements.statement_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "statements.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: getBackNineStatementData,
  options: TableOptions({ sortBy: [{ key: "id", order: "desc" }] })
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
