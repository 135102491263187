<template>
  <app-button
    v-bind="confirmOptions"
    class="text-none"
    color="primary"
    :data-testid="props.dataTestid"
    v-on="confirmActions"
  >
    <slot />
  </app-button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  confirmHref: { type: String, default: null, required: true },
  dataTestid: { type: String, required: true },
  loading: Boolean
});

const emit = defineEmits(["click"]);
const confirmActions = computed(() => {
  if (props.confirmHref) return {};
  return {
    click: () => emit("click")
  };
});
const confirmOptions = computed(() => {
  let target, href;
  if (props.confirmHref) {
    target = "_blank";
    href = props.confirmHref;
  }
  return {
    loading: props.loading,
    target,
    href
  };
});
</script>
