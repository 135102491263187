import { Payor, setPayorFromRequest } from "@/factories/Payor";
import { setDocumentFromRequest } from "./Document";

export const STATEMENT_TYPES = ["Carrier Statement", "IMO Statement"];

export const ACTIONS_DICT = {
  UPLOAD_ONLY: "upload_only",
  PAYMENTS_ONLY: "payments",
  ENQUEUE: "transactions_and_payments"
};

export const ACTIONS = [
  {
    title: "Create Transaction and Payments",
    value: ACTIONS_DICT.ENQUEUE
  },
  { title: "Create Payments", value: ACTIONS_DICT.PAYMENTS_ONLY },
  { title: "No Action", value: ACTIONS_DICT.UPLOAD_ONLY }
];

export function Statement(model = {}) {
  return {
    amount: model?.amount || null,
    deletable: model?.deletable || null,
    document: model?.document || null,
    errorsDocument: model?.errorsDocument || null,
    id: model?.id || null,
    payor: Payor(model?.payor),
    premium: model?.premium || null,
    premiumSum: model?.premiumSum || null,
    statementDate: model?.statementDate || null,
    statementType: model?.statementType || null,
    status: model?.status || null,
    commissionSum: model?.commissionSum || null,
    nonImportableDocument: model?.nonImportableDocument || null
  };
}

export function setStatementFromRequest(raw = {}) {
  const model = Statement();

  model.amount = raw?.amount;
  model.deletable = raw?.deletable;
  model.commissionSum = raw?.commission_sum;
  model.id = raw?.id;
  model.premium = raw?.premium;
  model.premiumSum = raw?.premium_sum;
  model.statementDate = raw?.statement_date;
  model.statementType = raw?.statement_type;
  model.status = raw?.status;

  if (raw?.errors_document) {
    model.errorsDocument = setDocumentFromRequest(raw.errors_document);
  }
  if (raw?.document) {
    model.document = setDocumentFromRequest(raw.document);
  }
  if (raw?.non_importable_document) {
    model.nonImportableDocument = setDocumentFromRequest(
      raw.non_importable_document
    );
  }

  model.payor = setPayorFromRequest(raw?.payor);

  return model;
}

export function StatementCreate(model = {}) {
  return {
    statementDate: model?.statementDate || null,
    payor: model?.payor || null,
    premium: model?.premium || null,
    amount: model?.amount || null,
    statementType: model?.statementType || null,
    file: model?.file || null,
    optionalPdf: model?.pdfFile || null,
    action: model?.action || null
  };
}

export function StatementCreateToRequest(statementCreate) {
  const documents = [{ file: statementCreate.file, category: "Transactions" }];
  if (statementCreate.optionalPdf) {
    documents.push({
      file: statementCreate.optionalPdf,
      category: "Non Importable Transactions"
    });
  }
  return {
    amount: statementCreate.amount,
    payor_id: statementCreate.payor.id,
    premium: statementCreate.premium,
    statement_date: statementCreate.statementDate,
    statement_type: statementCreate.statementType,
    import_action: statementCreate.action || ACTIONS_DICT.UPLOAD_ONLY,
    documents_attributes: documents
  };
}

export function StatementSearchItem(model = {}) {
  return {
    amount: model?.amount || null,
    id: model?.id || null,
    statementDate: model?.statementDate || null,
    payor: Payor(model?.payor)
  };
}

export function setStatementSearchItemFromRequest(raw = {}) {
  const model = StatementSearchItem();
  model.amount = raw?.amount;
  model.id = raw?.id;
  model.statementDate = raw?.statement_date;
  model.payor = setPayorFromRequest(raw?.payor);

  return model;
}

function StatementPayor(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    payableType: model?.payableType || null
  };
}

export function setStatementPayorFromRequest(raw = {}) {
  const model = StatementPayor();
  model.id = raw?.id;
  model.name = raw?.name;
  model.payableType = raw?.payable_type;

  return model;
}
