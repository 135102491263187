<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-0" flat>
            <v-card-title>Change Agent and Assign To</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <select-field
                    data-testid="change-agent"
                    :prepend-inner-icon="mdiAccountTie"
                    return-object
                    item-title="name"
                    label="Agent"
                    hint="Click the dropdown to change the agent on this Quote. Add the agent as an access if they do not appear in the list."
                    persistent-hint
                    :model-value="agent"
                    :success="Boolean(agent?.id)"
                    :disabled="props.readonly"
                    :items="agentOptions"
                    @update:model-value="handleAgentChange"
                  >
                    <template #append-inner>
                      <active-save-indicator :controller="savingBuffer.agent" />
                    </template>
                  </select-field>
                </v-col>
                <v-col cols="12" md="6">
                  <select-field
                    data-testid="change-assignable"
                    :prepend-inner-icon="mdiAccountTie"
                    return-object
                    item-title="name"
                    label="Assign To"
                    hint="Click the dropdown to change who this Quote is assigned to. Add the desired assignable as access if they do not appear in the list."
                    persistent-hint
                    :model-value="assignable"
                    :success="Boolean(assignable?.id)"
                    :disabled="props.readonly"
                    :items="agentOptions"
                    @update:model-value="handleAssignableChange"
                  >
                    <template #append-inner>
                      <active-save-indicator
                        :controller="savingBuffer.assignable"
                      />
                    </template>
                  </select-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat>
            <v-card-title>Access Table </v-card-title>
            <v-card-text>
              <access-table
                v-model="opportunities"
                v-model:new-access="newAccess"
                data-testid="access-table"
                :checkboxes="checkboxes"
                :update-func="updateAccess"
                :delete-func="removeAccess"
                :add-func="addAccess"
              >
                <template #new-access>
                  <advisor-search
                    v-model="newAccess"
                    hide-details
                    data-testid="access-advisor-search"
                  />
                </template>
              </access-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import AccessTable from "@/components/shared/AccessTable.vue";

import {
  addQuoteAccess,
  updateQuoteAccess,
  removeQuoteAccess,
  updateQuote
} from "@/api/quotes.service";
import { useQuoteViewStore } from "@/stores/quote-view";

import { mdiAccountTie } from "@mdi/js";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed,  ref } from "vue";
import { storeToRefs } from "pinia";

const props = defineProps({ readonly: Boolean });

const checkboxes = [
  {
    text: "Email",
    value: "email"
  }
];

const newAccess = ref(null);

const quoteStore = useQuoteViewStore();
const { agent, id, assignable, opportunities } = storeToRefs(quoteStore);

const savingBuffer = {
  agent: useActiveSave(),
  assignable: useActiveSave()
};

const agentOptions = computed(() => {
  return opportunities.value.filter(access =>
    access.ownerType?.toLowerCase()?.includes("agent")
  );
});

function handleAgentChange({ id: newAgentId }) {
  const prevAgent = agent.value?.id;
  setActiveAgent(newAgentId);

  savingBuffer.agent.update(() =>
    updateQuoteAccess(id.value, newAgentId, { agent: true }).catch(e => {
      if (prevAgent) setActiveAgent(prevAgent);
      throw e;
    })
  );
}
function handleAssignableChange({ ownerType, name, ownerId }) {
  assignable.value.id = ownerId;
  assignable.value.name = name;
  assignable.value.type = ownerType;

  savingBuffer.assignable.update(() =>
    updateQuote(id.value, {
      quote: {
        assignable_id: ownerId,
        assignable_type: ownerType
      }
    })
  );
}

function setActiveAgent(currId) {
  if (agent.value?.id) {
    const prevIndex = opportunities.value.findIndex(
      ({ id: i }) => agent.value.id === i
    );
    opportunities.value[prevIndex].agent = false;
  }

  const currIndex = opportunities.value.findIndex(({ id: i }) => currId === i);
  opportunities.value[currIndex].agent = true;

  quoteStore.updateAgent(opportunities.value[currIndex]);
}
async function addAccess(access) {
  if (!access) return;
  return addQuoteAccess(id.value, access);
}
async function updateAccess(access, attribute) {
  return await updateQuoteAccess(id.value, access.id, {
    [attribute]: access[attribute]
  });
}
async function removeAccess(access) {
  return await removeQuoteAccess(id.value, access.id);
}
</script>
