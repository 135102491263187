export function RecentCase(model = {}) {
  return {
    carrierName: model?.carrierName || "",
    id: model?.id || 0,
    insuredName: model?.insuredName || "",
    productionCreditTotal: model?.productionCreditTotal || 0,
    status: model?.status || ""
  };
}

export function setRecentCaseFromRequest(raw = {}) {
  const model = RecentCase();
  model.carrierName = raw?.carrier?.name;
  model.id = raw?.id;
  model.insuredName = raw?.insured?.name;
  model.productionCreditTotal = raw?.production_credit_total;
  model.status = raw?.status;
  return model;
}
