<template>
  <autocomplete-field
    ref="searchText"
    v-model="vendor"
    v-bind="$attrs"
    v-model:search="searchText"
    autocomplete="false"
    :prepend-inner-icon="mdiBookOpenPageVariant"
    placeholder="Search Vendors"
    label="Vendor"
    item-title="name"
    return-object
    hide-no-data
    :data-testid="dataTestid"
    :clearable="clearable"
    :disabled="disabled"
    :items="vendors"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
  >
    <template v-if="hasAppendOuterSlot" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script>
import { getVendors } from "@/api/vendor.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiBookOpenPageVariant } from "@mdi/js";
export default {
  props: {
    dataTestid: { type: String, default: null },
    clearable: Boolean,
    modelValue: { type: [Object, String], default: () => {} },
    lines: {
      required: false,
      type: Array,
      default: () => []
    },
    disabled: Boolean,
    hideDetails: Boolean
  },
  emits: ["update:model-value", "blur"],
  data() {
    let vendors = [];
    let vendor = null;
    if (
      this.modelValue &&
      Object.keys(this.modelValue).length &&
      this.modelValue.id
    ) {
      vendors = [this.modelValue];
      vendor = this.modelValue;
    }
    return {
      searchText: "",
      timer: null,
      loading: false,
      vendors,
      vendor,
      mdiBookOpenPageVariant
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append"];
    }
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.vendor = null;
        this.vendors = [];
        return;
      }
      this.vendor = value;
      this.vendors = [value];
    },
    vendor(value) {
      this.$emit("update:model-value", value);
    }
  },
  created() {
    this.getVendors();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getVendors() {
      try {
        this.loading = true;
        const vendors = await getVendors();
        this.vendors.splice(0, this.vendors.length);
        this.vendors.push(
          ...vendors.toSorted((a, b) => a.name.localeCompare(b.name))
        );
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    focus() {
      if (!this.$refs.searchText?.$el) return;
      const input = this.$refs.searchText?.$el.querySelector("input");
      if (!input?.focus) return;
      input.focus();
    },
    clear() {
      this.vendor = null;
      this.vendors = [];
    }
  }
};
</script>
