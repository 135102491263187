<template>
  <v-card>
    <v-row class="ma-0 pa-3" justify="center" align="center">
      <v-img src="@/assets/img/Redtail.svg" max-height="100" contain />
    </v-row>
    <v-card-title>Connect to Redtail </v-card-title>
    <v-card-text>
      <text-field
        v-model="credentials.username"
        label="Redtail Username"
        data-testid="redtail-username"
        :disabled="loading"
        v-bind="usernameValidation"
      />
      <text-field
        v-model="credentials.password"
        label="Redtail Password"
        type="password"
        data-testid="redtail-password"
        :disabled="loading"
        v-bind="passwordValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        color="primary"
        class="text-none"
        data-testid="redtail-connect"
        :loading="loading"
        @click="connectToRedtail"
      >
        Connect
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref } from "vue";
import { createRedtailIntegration } from "@/api/user-identities.service.js";

import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";

import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { useUserStore } from "@/stores/user";
import useVuelidate from "@vuelidate/core";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const user = useUserStore();

const credentials = ref({
  username: "",
  password: ""
});

const v$ = useVuelidate(
  {
    credentials: {
      username: { required: v => someTextValidator(true, v, 2) },
      password: { required: v => someTextValidator(true, v, 2) }
    }
  },
  { credentials },
  { $autoDirty: true, $scope: null }
);

const usernameValidation = computedValidation(v$.value.credentials.username, {
  required: "Required"
});
const passwordValidation = computedValidation(v$.value.credentials.password, {
  required: "Required"
});

const loading = ref(false);

async function connectToRedtail() {
  try {
    const isValid = await v$.value.$validate();
    if (!isValid) return;
    loading.value = true;
    const id = await createRedtailIntegration(
      credentials.value.username,
      credentials.value.password
    );
    snackbar.showSuccessSnackbar({ message: "Connected to Redtail" });
    user.integrations.redtail.id = id;
    user.integrations.redtail.enabled = true;
    user.integrations.redtail.createdAt = new Date();
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
