import { defineStore } from "pinia";

export const useDialogStore = defineStore("dialog", {
  state: () => ({
    show: false,
    dialogStack: []
  }),
  actions: {
    showDialog(props) {
      this.show = true;
      return new Promise(resolve => {
        this.dialogStack.push({ resolve, props });
      });
    },
    closeAll(res = {}) {
      const count = this.dialogStack.length;
      for (let i = 0; i < count; i++) {
        this.closeDialog(res);
      }
    },
    closeDialog(props) {
      if (this.dialogStack.length <= 1) {
        this.show = false;
      }
      if (!this.dialogStack.length) return;

      const last = this.dialogStack.pop();

      last.resolve(props || {});
    }
  }
});
