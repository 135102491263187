import { setRoleFromRequest } from "@/factories/RoleFactory";
import { setAddressFromRaw } from "@/factories/AddressFactory";
export const INDIVIDUAL_INDICATE = "0";
export const ENTITY_INDICATE = "1";
export function ContractParty(model = {}) {
  return {
    contractParty: model?.contractParty || null,
    avatar: model?.avatar || null,
    birthdate: model?.birthdate || null,
    name: model?.name || null,
    partyId: model?.partyId || null,
    roles: model?.roles || [],
    type: model?.type || null,
    addresses: model?.addresses || {},
    gender: model?.gender || ""
  };
}

export function setContractPartyFromRequest(raw = {}) {
  const model = ContractParty();
  model.avatar = raw?.avatar_url;
  model.birthdate = raw?.birthdate;
  model.id = raw?.id;
  model.name = raw?.name;
  model.partyId = raw?.party_id;
  model.email = raw?.email;
  model.gender = raw?.gender;
  model.primaryPhone = raw?.primary_phone;

  model.type = {
    individual: "Individual",
    entity: "Entity"
  }[raw?.type];

  model.roles = [];
  raw?.roles?.forEach(rawRole => {
    model.roles.push(setRoleFromRequest(rawRole));
  });

  if (raw?.address) {
    model.addresses = [setAddressFromRaw(raw?.address)];
  }
  return model;
}
