export function MarketingManagerTableItem(model = {}) {
  return {
    id: model?.id || null,

    caseConciergeId: model?.caseConciergeId || null,
    caseConciergeName: model?.caseConciergeName || null,
    caseManagerId: model?.caseManagerId || null,
    caseManagerName: model?.caseManagerName || null,

    annuityWholesalerName: model?.annuityWholesalerName || null,
    annuityWholesalerId: model?.annuityWholesalerId || null,
    disabilityWholesalerName: model?.disabilityWholesalerName || null,
    disabilityWholesalerId: model?.disabilityWholesalerId || null,
    ltcWholesalerName: model?.ltcWholesalerName || null,
    ltcWholesalerId: model?.ltcWholesalerId || null,
    name: model?.name || null
  };
}

export function setMarketingManagerTableItemFromRequest(rawModel = {}) {
  const item = MarketingManagerTableItem();
  item.id = rawModel?.id;
  item.name = rawModel?.name;

  item.caseConciergeId = rawModel?.case_concierge?.id;
  item.caseConciergeName = rawModel?.case_concierge?.name;
  item.caseManagerId = rawModel?.case_manager?.id;
  item.caseManagerName = rawModel?.case_manager?.name;

  item.annuityWholesalerName = rawModel?.annuity_wholesaler?.name;
  item.annuityWholesalerId = rawModel?.annuity_wholesaler?.id;
  item.disabilityWholesalerName = rawModel?.disability_wholesaler?.name;
  item.disabilityWholesalerId = rawModel?.disability_wholesaler?.id;
  item.ltcWholesalerName = rawModel?.ltc_wholesaler?.name;
  item.ltcWholesalerId = rawModel?.ltc_wholesaler?.id;

  return item;
}
