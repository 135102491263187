<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-row justify="center" class="ma-0 mt-3">
        <div class="w-100">
          <to-do-list-view
            :subject="{
              id: leadView.id,
              type: 'Lead',
              name: 'This Lead'
            }"
            :highlight-todo="highlightTodo"
            @due-count="todoCount = $event"
          />
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ToDoListView from "@/components/todo/ToDoListView.vue";
import { useLeadView } from "@/stores/lead-view";
import { storeToRefs } from "pinia";

import {  toRef } from "vue";
const props = defineProps({
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  }
});

const highlightTodo = toRef(props, "highlightTodo");

const leadView = useLeadView();
const { todoCount } = storeToRefs(leadView);
</script>
