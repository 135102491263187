<template>
  <v-data-table
    data-testid="questionnaires-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :search="search"
  >
    <template #top>
      <v-row class="ma-0 px-3" align="center">
        <h1 class="text-h5">Impaired Risk Questionnaires</h1>
        <v-spacer />
        <text-field
          v-model="search"
          data-testid="questionnaires-table-search"
          data-lpignore="true"
          hide-details
          label="Search"
          :prepend-inner-icon="mdiMagnify"
        />
        <app-button
          :icon="mdiRefresh"
          variant="text"
          class="table-btn"
          density="comfortable"
          data-testid="questionnaires-table-refresh"
          @click="getData"
        />
      </v-row>
    </template>
    <template #[`item.actions`]="{ item }">
      <app-button
        :icon="mdiDownload"
        variant="text"
        density="comfortable"
        data-testid="download"
        color="primary"
        v-bind="downloadDocument(item.additional)"
      />
    </template>
  </v-data-table>
</template>

<script setup>
import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { getDocumentUrl } from "@/api/documents.service";
import { getQuestionnaires } from "@/api/questionnaire.service";
import { useTable } from "@/composables/table.composable";
import { ref } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiMagnify, mdiRefresh, mdiDownload } from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";
const search = ref("");
const snackbar = useSnackbarStore();

const { mdAndDown } = useDisplay();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Impairment",
      value: "questionnaire",
      map: "name",
      sortFilterMap: "questionnaires.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: ""
    })
  ],
  getData: getQuestionnaires,
  options: TableOptions({ sortBy: [{ key: "questionnaire", order: "asc" }] })
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function downloadDocument({ uid }) {
  return downloadFileAsLink(getDocumentUrl(uid), mdAndDown.value);
}

getData();
</script>
