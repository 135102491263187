<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    dense
    disable-pagination
    hide-default-footer
    must-sort
    data-testid="pay-period-payments-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-per-page="-1"
  >
    <template #[`item.statementDate`]="{ item }">
      <timestamp-formatter
        :model-value="item.statementDate"
        parser="sole-day"
      />
    </template>

    <template #[`item.policy`]="{ item }">
      <router-link
        v-if="item.additional.carrier"
        :to="getRouterLink('Case', item.additional.case.id)"
      >
        {{ item.additional.carrier.name }} ·
        {{ item.additional.insured.name }} ·
        {{ item.additional.case.policy_number }}
      </router-link>
      <template v-else>
        {{ item.additional.description }}
      </template>
    </template>

    <template #[`item.splitPercent`]="{ item }">
      <percentage-formatter :model-value="item.splitPercent" />
    </template>

    <template #[`item.premium`]="{ item }">
      <currency-formatter :model-value="item.premium" />
    </template>

    <template #[`item.netRetained`]="{ item }">
      <currency-formatter :model-value="item.netRetained" />
    </template>

    <template #[`item.dollar`]="{ item }">
      <span
        :class="{
          'text-success': item.dollar > 0,
          'text-error': item.dollar < 0
        }"
      >
        <currency-formatter :model-value="item.dollar" />
        (<percentage-formatter :model-value="item.additional.percent" />)
      </span>
    </template>
  </v-data-table>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { useUserStore } from "@/stores/user";
import { usePayPeriodViewStore } from "@/stores/pay-period-view";
import { storeToRefs } from "pinia";
import { useTable } from "@/composables/table.composable";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { getRouterLink } from "@/util/helpers";

const payPeriodView = usePayPeriodViewStore();
const user = useUserStore();

const { payments } = storeToRefs(payPeriodView);

function getTableHeaders() {
  const headers = [
    new TableHeader({
      text: "Date",
      value: "statementDate",
      map: "statement_date"
    }),
    new TableHeader({
      text: "Policy",
      value: "policy",
      map: ""
    }),
    new TableHeader({
      text: "Advisor",
      value: "advisor",
      map: "advisor.name"
    }),
    new TableHeader({
      text: "Advisor %",
      value: "splitPercent",
      map: "split_percent"
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium"
    })
  ];
  if (user.isGroupTwoPlus) {
    headers.push(
      new TableHeader({
        text: "Net Retained",
        value: "netRetained",
        map: "net_retained"
      })
    );
  }
  headers.push(
    new TableHeader({
      text: "Type",
      value: "type",
      map: "commission_type"
    }),
    new TableHeader({
      text: "Commission",
      value: "dollar",
      map: "dollar",
      ...TableHeader.IS_SORTABLE
    })
  );

  return headers;
}

const table = useTable({
  headers: getTableHeaders(),
  items: payments.value,
  options: TableOptions({ sortBy: [{ key: "dollar", order: "desc" }] })
});
</script>
