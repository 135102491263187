import { getHttpClient } from "@/http-client";
import { setArticleFromRequest } from "@/factories/ArticleFactory";
import { serializeObject } from "@/util/helpers";

const baseUrl = "/api/boss/help-desk";

export async function getArticles({
  limit,
  searchText,
  createdAt = null,
  updatedAt = null,
  published = null,
  sort = {}
}) {
  const params = new URLSearchParams();
  if (limit) params.append("limit", limit);
  if (searchText) params.append("q", searchText);
  if (createdAt) params.append("date[created_at][start]", createdAt);
  if (updatedAt) params.append("date[updated_at][start]", updatedAt);
  if ([true, false].includes(published)) params.append("published", published);
  const sortKeys = Object.keys(sort);
  if (sortKeys.length) {
    sortKeys.forEach(key => {
      params.append(`sorting[${key}]`, sort[key]);
    });
  }

  const response = await getHttpClient().get(`${baseUrl}/authenticated_index`, {
    params
  });

  return response.data.map(setArticleFromRequest);
}

export async function createArticle(name) {
  const { data } = await getHttpClient().post(`${baseUrl}/articles`, {
    article: {
      name,
      content: null,
      published: false
    }
  });
  return setArticleFromRequest(data.help_desk_article);
}

export async function getArticleById(id) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/authenticated_show/${id}`
  );
  return setArticleFromRequest(data.help_desk_article);
}

export function deleteArticle(id) {
  return getHttpClient().delete(`${baseUrl}/articles/${id}`);
}

export function updateArticle(id, body) {
  return getHttpClient().put(`${baseUrl}/articles/${id}`, {
    article: body
  });
}

export async function uploadArticleImage(articleId, file) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/articles/${articleId}/files`,
    await serializeObject({ file })
  );
  return data?.document?.cdn_url;
}
