<template>
  <text-field
    v-model="privDate"
    v-maska:[options]
    data-lpignore="true"
    inputmode="numeric"
    class="date-input"
    v-bind="componentProps"
    :data-testid="props.dataTestid"
    :prepend-inner-icon="mdiCalendar"
    :hide-details="hideDetails"
    :label="label"
    :autofocus="autofocus"
    :success="success"
    :error-messages="errorMessages"
    :disabled="disabled"
    :clearable="clearable"
    @click:clear="clearValue"
    @paste.prevent="handlePaste"
  >
    <template #append-inner>
      <slot name="append-inner" />
      <v-menu :close-on-content-click="false" v-model="showMenu">
        <template #activator="{ props: templateProps }">
          <app-button
            v-bind="templateProps"
            variant="text"
            density="comfortable"
            :icon="mdiCalendar"
            data-testid="date-input-calendar-button"
          />
        </template>
        <v-date-picker
          v-model="dateModel"
          data-testid="date-input-calendar"
          color="primary"
          :disabled="disabled"
        />
      </v-menu>
    </template>
  </text-field>
</template>

<script setup>
import { mdiCalendar } from "@mdi/js";
import { vMaska } from "maska";

import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import format from "date-fns/format";
import { ref, watch, toRef, computed } from "vue";
import { useSnackbarStore } from "@/stores/snackbar";

const snackbar = useSnackbarStore();
const props = defineProps({
  hideDetails: { type: [Boolean, String], required: false, default: false },
  clearable: Boolean,
  label: { type: String, default: null },
  modelValue: { type: String, default: null },
  errorMessages: { type: Array, default: () => [] },
  success: Boolean,
  disabled: Boolean,
  autofocus: Boolean,
  dataTestid: {
    type: String,
    required: false,
    default: ""
  }
});

const showMenu = ref(false);

const emit = defineEmits(["update:model-value"]);

const componentProps = {
  type: "text",
  placeholder: "mm/dd/yyyy"
};
const inputFormat = "MM/dd/yyyy";
const propFormat = "yyyy-MM-dd";
const options = {
  mask: "##/##/####"
};

const propValue = toRef(props, "modelValue");

const dateModel = computed({
  get() {
    if (!privDate.value) return undefined;
    try {
      return parse(privDate.value, inputFormat, new Date());
    } catch (e) {
      return undefined;
    }
  },
  set(v) {
    if (!v) privDate.value = null;
    else {
      try {
        privDate.value = format(v, inputFormat);
      } catch (e) {
        privDate.value = null;
      }
    }
  }
});

const dateValue = computed({
  get() {
    if (!privDate.value) return undefined;
    try {
      return format(parse(privDate.value, inputFormat, new Date()), propFormat);
    } catch (e) {
      return undefined;
    }
  },
  set(v) {
    if (!v) privDate.value = null;
    else {
      try {
        const date = parse(v, propFormat, new Date());
        if (isValid(date) && v.length === 10) {
          privDate.value = format(date, inputFormat);
        }
      } catch (e) {
        privDate.value = null;
      }
    }
  }
});

const privDate = ref(null);
if (propValue.value) dateValue.value = propValue.value;

watch(propValue, () => {
  if (dateValue.value === propValue.value) return;
  dateValue.value = propValue.value;
});

watch(privDate, () => {
  showMenu.value = false;
  if (dateValue.value === propValue.value) return;
  if (!privDate.value?.length && props.clearable) {
    emit("update:model-value", dateValue.value);
  }

  if (privDate.value?.length === 10) {
    emit("update:model-value", dateValue.value);
  }
});

function clearValue() {
  privDate.value = null;
  emit("update:model-value", null);
}

function handlePaste(e) {
  let clipboardData, pastedData;

  e.stopPropagation();
  e.preventDefault();

  clipboardData = e.clipboardData || window.clipboardData;
  pastedData = clipboardData.getData("Text");

  try {
    const parsedDate = parse(pastedData, inputFormat, new Date());
    if (!isValid(parsedDate)) throw "invalid date";
    privDate.value = format(parsedDate, inputFormat);
  } catch (e) {
    snackbar.showInfoSnackbar({
      message: "Pasted dates must be valid and formatted as mm/dd/yyyy"
    });
    return;
  }
}
</script>

<style lang="scss">
.date-input {
  .v-input__append-inner:last-child {
    margin-top: 2px !important;
  }
}
</style>
