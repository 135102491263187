import { getHttpClient } from "@/http-client";
import { setBuildFromRequest } from "@/factories/Build";
const baseUrl = "api/boss/builds";

export async function getBuilds(params, cancelToken = null) {
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  return {
    items: data.builds.map(setBuildFromRequest),
    meta: data.meta
  };
}

export function deleteBuild(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export async function createBuild({
  build_chart_id,
  inches,
  rating,
  min_weight,
  max_weight
}) {
  const { data } = await getHttpClient().post(baseUrl, {
    build: {
      build_chart_id,
      inches,
      rating,
      min_weight,
      max_weight
    }
  });

  return setBuildFromRequest(data.build);
}

export async function updateBuild(
  build_id,
  { build_chart_id, inches, rating, min_weight, max_weight }
) {
  const { data } = await getHttpClient().put(`${baseUrl}/${build_id}`, {
    build: {
      build_chart_id,
      inches,
      rating,
      min_weight,
      max_weight
    }
  });

  return setBuildFromRequest(data.build);
}
