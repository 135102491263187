<template>
  <div class="pa-3">
    <v-row v-if="user.isGroupOne" justify="center">
      <v-col cols="12" md="10">
        <v-card elevation="8">
          <v-card-title> Add an Agent </v-card-title>
          <v-card-text>
            <agent-form />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card v-else color="section" class="pa-3 py-2" variant="flat">
      <v-row class="ma-0" dense justify="center">
        <v-col cols="12">
          <h1 class="text-h4 mb-4">
            Add an {{ user.isGroupOne ? "Agent" : "Agent or Agency" }}
          </h1>
        </v-col>
        <template v-if="user.isGroupTwoPlus">
          <v-col cols="6">
            <app-button
              block
              class="text-none mb-3"
              :color="type === 'Agent' ? 'accent' : null"
              @click="type = 'Agent'"
            >
              Create Agent
            </app-button>
          </v-col>
          <v-col cols="6">
            <app-button
              block
              class="text-none mb-3"
              :color="type === 'Agency' ? 'accent' : null"
              @click="type = 'Agency'"
            >
              Create Agency
            </app-button>
          </v-col>
        </template>
        <v-col v-if="type === 'Agent'" cols="12" lg="10">
          <v-card elevation="8">
            <v-card-title> Add an Agent </v-card-title>
            <v-card-text>
              <agent-form />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="type === 'Agency'" cols="12">
          <agency-form />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script setup>
import AgencyForm from "@/components/advisors/AgencyForm.vue";
import AgentForm from "@/components/advisors/AgentForm.vue";
import { useUserStore } from "@/stores/user";
import { useHead } from "@unhead/vue";
import { ref } from "vue";

useHead({ title: "Create Advisor" });
const type = ref("Agent");

const user = useUserStore();
</script>
