import { getHttpClient } from "@/http-client";
import { setPersonnelFromRequest } from "@/factories/Personnel";
import { serializeObject } from "@/util/helpers";
const baseUrl = "api/boss/personnels";

export async function uploadPersonnelAvatar(id, file) {
  const body = await serializeObject({ file });
  return getHttpClient().put(`${baseUrl}/${id}/avatar`, body);
}

export const getAllPersonnel = async () => {
  const params = new URLSearchParams();

  const { data } = await getHttpClient().get(baseUrl, { params });
  return data.personnels;
};

export async function createPersonnel(personnel) {
  const { data } = await getHttpClient().post(baseUrl, { personnel });
  return setPersonnelFromRequest(data.personnel);
}

export async function getPersonnel(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  const personnel = setPersonnelFromRequest(data.personnel);
  personnel.id = +id;
  return personnel;
}

export function deletePersonnel(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export function updatePersonnel(id, personnel) {
  return getHttpClient().put(`${baseUrl}/${id}`, { personnel });
}

export async function fetchCarrierPersonnel(carrierId) {
  const params = new URLSearchParams();
  params.append("carrier", carrierId);
  const { data } = await getHttpClient().get(`${baseUrl}`, {
    params
  });

  return data.personnel;
}
