<template>
  <autocomplete-field
    v-model="model"
    :prepend-inner-icon="mdiShape"
    :clearable="props.clearable"
    :loading="cache.submissionMethods.loading"
    :label="props.label"
    :disabled="props.readonly || props.disabled"
    :items="cache.submissionMethods.items"
    :success="props.success"
    :error-messages="props.errorMessages"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import {   ref, watch, toRef } from "vue";
import { mdiShape } from "@mdi/js";
const emit = defineEmits(["update:model-value", "update:full"]);
const props = defineProps({
  full: {
    type: [Object, String],
    default: () => {}
  },
  modelValue: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  readonly: Boolean,
  disabled: Boolean,
  clearable: Boolean,
  success: {
    type: Boolean
  },
  errorMessages: {
    type: Array,
    default: () => []
  }
});

const modelValue = toRef(props, "modelValue");
const model = ref(props.modelValue);
// eslint-disable-next-line vue/no-setup-props-destructure
if (props.full?.value) model.value = props.full.value;

const cache = useSearchItemsCache();
const snackbar = useSnackbarStore();
watch(
  () => model.value,
  () => {
    emit("update:model-value", model.value);
    let full = model.value;
    if (full)
      full = cache.submissionMethods.items.find(({ value }) => value === full);
    emit("update:full", full);
  }
);

watch(
  () => props.full,
  () => {
    if (model.value === props.full?.value) return;
    model.value = props.full.value;
  }
);

watch(modelValue, value => {
  if (model.value === value) return;
  model.value = value;
});

async function getItems() {
  try {
    await cache.getFormSubmissionMethods();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getItems();
</script>
