<template>
  <div class="card-item">
    <span class="text-grey lighten-2"> {{ props.title }} </span>
    <br />

    <template
      v-for="{ key, to, text, punctuation } in linksWithPunctuation"
      :key="key"
    >
      <router-link :to="to" v-text="text" />{{ punctuation }}
    </template>
  </div>
</template>

<script setup>
import { computed, toRef } from "vue";
const props = defineProps({
  title: { type: String, required: true },
  links: { type: Array, required: true }
});

const links = toRef(props, "links");
const linksWithPunctuation = computed(() => {
  return links.value.map((link, index) => {
    let punctuation = "";
    const addComma = links.value.length > 2 && links.value.length - 1 !== index;
    const addAnd = links.value.length > 1 && links.value.length - 2 === index;
    if (addComma && addAnd) punctuation = ", and ";
    else if (addComma) punctuation = ", ";
    else if (addAnd) punctuation = " and ";
    return {
      ...link,
      punctuation
    };
  });
});
</script>
