<template>
  <v-fade-transition mode="out-in">
    <quote-and-apply-websites-list v-if="condensedView" key="list">
      <template #header>
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiDatabase"
              variant="text"
              density="comfortable"
              class="default-icon"
              v-bind="templateProps"
              data-testid="toggle-condensed-view"
              @click="condensedView = !condensedView"
            />
          </template>
          <span>Show Table View</span>
        </v-tooltip>
      </template>
    </quote-and-apply-websites-list>
    <quote-and-apply-websites-table v-else key="table">
      <template #header>
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiDatabaseOff"
              variant="text"
              density="comfortable"
              v-bind="templateProps"
              class="default-icon"
              data-testid="toggle-condensed-view"
              @click="condensedView = !condensedView"
            />
          </template>
          <span>Show Condensed View</span>
        </v-tooltip>
      </template>
    </quote-and-apply-websites-table>
  </v-fade-transition>
</template>
<script setup>
import QuoteAndApplyWebsitesList from "@/components/quote-and-apply/QuoteAndApplyWebsitesList.vue";
import QuoteAndApplyWebsitesTable from "@/components/quote-and-apply/QuoteAndApplyWebsitesTable.vue";
import { ref } from "vue";

import { mdiDatabaseOff, mdiDatabase } from "@mdi/js";
import { useUserStore } from "@/stores/user";
const user = useUserStore();
const condensedView = ref(!user.isGroupTwoPlus);
</script>
