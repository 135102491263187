<template>
  <v-data-table
    v-model:expanded="expanded"
    v-model:search="search"
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    item-value="additional.id"
    data-testid="employee-table"
    must-sort
    :mobile="null"
    mobile-breakpoint="sm"
    :loading="table.loading.value"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5">Employees</h1>
        <v-spacer />
        <text-field
          v-model="search"
          hide-details
          label="Search"
          data-lpignore="true"
          data-testid="search-employees"
          placeholder="Search Employees"
          :prepend-inner-icon="mdiMagnify"
        />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              v-bind="templateProps"
              data-testid="employees-table-refresh"
              variant="text"
              class="table-btn"
              density="comfortable"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-tooltip v-if="user.isGroupFour" location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiPlusCircle"
              v-bind="templateProps"
              variant="text"
              density="comfortable"
              class="table-btn"
              data-testid="employee-create"
              @click="createEmployee"
            />
          </template>
          <span>Create Employee</span>
        </v-tooltip>
      </v-row>
    </template>
    <template #[`item.name`]="{ item }">
      <router-link
        v-if="item.additional.accessible"
        :to="{ name: 'EmployeeView', params: { id: item.additional.id } }"
      >
        {{ item.name }}
      </router-link>
      <span v-else>{{ item.name }}</span>
    </template>
    <template #[`item.phones`]="{ item }">
      <v-col class="pl-0">
        <div v-if="item.additional.mobile" data-testid="mobile">
          Mobile:
          <a
            :href="`tel:${item.additional.mobile}`"
            data-outbound-type="Agent"
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.additional.mobile"
            v-text="item.additional.mobile"
          />
        </div>
        <div v-if="item.additional.phone" data-testid="work">
          Work:
          <a
            :href="`tel:${item.additional.phone}`"
            data-outbound-type="Agent"
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.additional.phone"
            v-text="item.additional.phone"
          />
        </div>
        <div v-if="item.additional.extension" data-testid="extension">
          Extension: {{ item.additional.extension }}
        </div>
      </v-col>
    </template>
    <template #[`item.contact`]="{ item }">
      <v-col class="pl-0">
        <div v-if="item.additional.email" data-testid="email">
          Email:
          <a
            :href="`mailto:${item.additional.email}`"
            v-text="item.additional.email"
          />
        </div>
        <div
          v-if="item.additional.schedulingLink"
          data-testid="scheduling-link"
        >
          Scheduling Link:
          <a
            :href="item.additional.schedulingLink"
            v-text="item.additional.schedulingLink"
          />
        </div>
      </v-col>
    </template>
    <template #[`item.lastReviewDate`]="{ item }">
      <timestamp-formatter
        :model-value="item.lastReviewDate"
        parser="sole-day"
        format="basic"
      />
    </template>
    <template #[`item.dates`]="{ item }">
      <v-col class="pl-0">
        <div v-if="item.additional.birthdate" data-testid="birthdate">
          Birthdate: <strong>{{ item.additional.birthdate }}</strong>
        </div>
        <div v-if="item.additional.anniversary" data-testid="anniversary">
          B9 Anniversary:
          <strong>
            <timestamp-formatter
              :model-value="item.additional.anniversary"
              parser="sole-day"
              format="basic"
            />
          </strong>
        </div>
      </v-col>
    </template>
    <template #expanded-row="{ columns, item }">
      <td :colspan="columns.length" data-testid="job-description">
        <strong>Job Description: </strong>{{ item.additional.jobDescription }}
      </td>
    </template>
  </v-data-table>
</template>

<script setup>
import EmployeeCreateDialog from "@/components/employees/EmployeeCreateDialog.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { mdiMagnify, mdiRefresh, mdiPlusCircle } from "@mdi/js";
import { getEmployees } from "@/api/employees.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { ref, markRaw } from "vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({ text: "Phones", value: "phones", map: "phones" }),
    new TableHeader({ text: "Contact", value: "contact", map: "contact" }),
    new TableHeader({
      text: "Department",
      value: "department",
      map: "department",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Manager",
      value: "managerName",
      map: "managerName"
    }),
    new TableHeader({
      text: "Last Review",
      value: "lastReviewDate",
      map: "lastReviewDate",
      showIf: ({ tableItems }) =>
        tableItems.filter(v => v.accessible).length > 1
    }),
    new TableHeader({ text: "Dates", value: "dates", map: "dates" }),
    new TableHeader({
      text: "",
      value: "data-table-expand"
    })
  ],
  options: TableOptions({ sortBy: [{ key: "name", order: "asc" }] })
});

const expanded = ref([]);
const search = ref("");

async function getData() {
  table.loading.value = true;
  try {
    const employees = await getEmployees();
    table.items.value.splice(0, table.items.value.length);
    table.items.value.push(...employees);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    table.loading.value = false;
  }
}

async function createEmployee() {
  const response = await dialog.showDialog({
    component: markRaw(EmployeeCreateDialog)
  });

  if (response?.refresh) getData();
}

getData();
</script>
