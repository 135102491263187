import { getHttpClient } from "@/http-client";

import { isValid as dlValidation } from "usdl-regex";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";

export const phone = v => /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/.test(v);

// covers ITIN + SSN format. try to keep it in sync with fairway
export const ssn = ssn => {
  if (!ssn || ssn.length === 0) {
    return false;
  }
  const noDashSSN = ssn.replace(/-/g, "");
  const match = ssn.match(/[0-9]{3}-[0-9]{2}-[0-9]{4}/);
  return match && noDashSSN.length === 9;
};

export const vDriversLicense = (state, value) => {
  if (!state || !value) return false;

  return dlValidation(state, value);
};

export const dateIsBeforeNow = value => {
  const date = parse(value, "yyyy-MM-dd", new Date());
  if (!isValid(date)) return false;
  return isSameDay(new Date(), date) || isBefore(date, new Date());
};

export const email = value => {
  const regex = /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i;
  const isEmpty = [null, undefined, ""].includes(value);
  const validFromRegex = regex.test(value);
  return !isEmpty && validFromRegex;
};

export const tin = tin => {
  if (!tin || tin.length === 0) return false;
  const noDashTIN = tin.replace(/-/g, "");
  return noDashTIN.length === 9;
};
const uniquePartyId = async (value, type) => {
  if (type === "ssn" && !ssn(value)) return "Invalid SSN";
  if (type === "tin" && !tin(value)) return "Invalid TIN";

  const body = {
    party_id: value
  };

  const result = await getHttpClient().post(
    "api/boss/contract_parties/exists",
    body
  );

  if (!result || !result.data) return false;
  return !result.data.exists;
};

export const uniqueSsn = async value => {
  return await uniquePartyId(value, "ssn");
};

export const uniqueTin = async value => {
  return await uniquePartyId(value, "tin");
};

export const passwordValidator = {
  required: v => Boolean(v),
  minLength: v => `${v}`.length >= 10,
  includesLowercase: v => /(?=.*[a-z])/.test(v),
  includesUppercase: v => /(?=.*[A-Z])/.test(v),
  includesDigit: v => /(?=.*\d)/.test(v),
  includesSymbol: v => /(?=.*?[#?!@$ %^&*\-_.])/.test(v)
};

export const passwordValidatorMessages = {
  required: "Required",
  minLength: "Must be 10 characters long",
  includesLowercase: "Must include at least one lowercase letter",
  includesUppercase: "Must include at least one uppercase letter",
  includesDigit: "Must include at least one number",
  includesSymbol: "Must include at least one symbol (#?!@$ %^&*-_.)"
};
