<template>
  <v-card
    class="noselect"
    justify="center"
    height="100%"
    :data-testid="props.dataTestid"
    :variant="!props.active ? 'outlined' : undefined"
    :elevation="props.active ? 8 : null"
    :color="props.active ? 'accent' : null"
    @click="emit('click')"
  >
    <v-row class="ma-0">
      <v-col cols="12" class="pb-0" align="center">
        <v-icon
          size="38"
          :color="props.active ? 'white' : null"
          :icon="props.icon"
        />
      </v-col>
    </v-row>
    <v-card-title
      class="justify-center pt-2 text-center"
      :class="{ 'text-white': props.active }"
    >
      {{ props.title }}
    </v-card-title>
    <v-card-subtitle
      class="text-center pb-3"
      :class="{ 'text-white': props.active }"
    >
      {{ props.subtitle }}
    </v-card-subtitle>
  </v-card>
</template>

<script setup>
const props = defineProps({
  active: Boolean,
  icon: { type: String, required: true },
  title: { type: String, required: true },
  subtitle: { type: String, required: true },
  dataTestid: { type: String, required: true }
});
const emit = defineEmits(["click"]);
</script>
