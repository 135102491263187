<template>
  <v-card flat color="section">
    <v-card-title>Applications</v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <v-col
          v-for="partner in partners"
          :key="`${partner.text}`"
          cols="12"
          lg="4"
          md="6"
        >
          <security-action-card v-bind="partner" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SecurityActionCard from "@/components/settings/security/SecurityActionCard.vue";
import {
  useWealthboxIntegration,
  useRedtailIntegration
} from "@/components/settings/security/integration-partners.composable";
import { computed } from "vue";

const wealthbox = useWealthboxIntegration();
const redtail = useRedtailIntegration();

const partners = computed(() => {
  const partners = [];
  if (!import.meta.env.VITE_HIDE_REDTAIL) partners.push(redtail.value);
  partners.push(wealthbox.value);
  return partners;
});
</script>
