<template>
  <div>
    Authentication Key:
    <v-fade-transition mode="out-in">
      <code :key="authKey" data-testid="auth-key">
        {{ authKey }}
      </code>
    </v-fade-transition>
    <div class="mb-2 inline-block">
      <v-fade-transition mode="out-in">
        <app-button
          v-if="authKey === APIKEY_DISPLAY"
          :icon="mdiEye"
          color="accent"
          variant="text"
          data-testid="reveal-auth-key"
          @click="updateAuthKeyValue"
        />
        <app-button
          v-else
          data-testid="copy-auth-key"
          :icon="mdiContentCopy"
          variant="text"
          color="accent"
          @click="copyAuthKey"
        />
      </v-fade-transition>
    </div>
  </div>
</template>

<script setup>
import { useSnackbarStore } from "@/stores/snackbar";
import { ref } from "vue";
import { mdiEye, mdiContentCopy } from "@mdi/js";
const props = defineProps({
  webhook: {
    type: Object,
    required: true
  }
});
const snackbar = useSnackbarStore();
const APIKEY_DISPLAY = "* * * * * * * * * * * * * * * * * * ";
const authKey = ref(APIKEY_DISPLAY);

function updateAuthKeyValue() {
  authKey.value = props.webhook.authenticationKey;
}

function copyAuthKey() {
  navigator.clipboard.writeText(props.webhook.authenticationKey);
  snackbar.showSuccessSnackbar({
    message: "Authentication Key copied to clipboard"
  });
}
</script>
