import {
  getFormCategories,
  getFormSubmissionMethods
} from "@/api/forms.service";
import { getProductSolves } from "@/api/reference.service";
import { fetchCategories, fetchEngines } from "@/api/engines.service";
import { getAllFormSets } from "@/api/form-sets.service";
import { getAllTemporaryInsuranceProfiles } from "@/api/temporary-insurance-profile.service";
import { getBuildCharts } from "@/api/build-charts.service";
import { getAllCitizenshipProfiles } from "@/api/citizenship-profile.service";
import { getSmokerCharts } from "@/api/smoker-charts.service";
import { useCacheableSearch } from "@/composables/cacheable-search";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

//Use this when retrieving a full list for an autocomplete
export const useSearchItemsCache = defineStore("search-items-cache", () => {
  const formCategories = useCacheableSearch(async () => {
    const items = await getFormCategories();
    return items.map(i => ({ title: i.label, value: i.id }));
  });
  const submissionMethods = useCacheableSearch(async () => {
    const items = await getFormSubmissionMethods();
    return items.map(i => ({ title: i.label, value: i.value }));
  });
  const productSolves = useCacheableSearch(async () => {
    const items = await getProductSolves();
    return items.map(i => ({ title: i, value: i }));
  });

  const engines = useCacheableSearch(async () => {
    const items = await fetchEngines();
    return items.map(i => ({ title: i.name, value: i.id }));
  });

  const formSets = useCacheableSearch(async () => {
    const items = await getAllFormSets();
    return items
      .map(i => ({ title: i.name, value: i.id }))
      .toSorted((a, b) => a.title.localeCompare(b.title));
  });

  const citizenshipProfiles = useCacheableSearch(async () => {
    const items = await getAllCitizenshipProfiles();
    return items.map(i => ({ title: i.name, value: i.id, additional: i }));
  });

  const buildCharts = useCacheableSearch(async () => {
    const items = await getBuildCharts();
    return items.map(i => ({ title: i.name, value: i.id, additional: i }));
  });

  const productMeta = useCacheableSearch(async () => {
    const { types, traits } = await fetchCategories();
    return [types, traits];
  });

  const productTypes = computed(() => ({
    ...productMeta.state.value,
    items: productMeta.state.value.items?.[0]
  }));

  const productTraits = computed(() => ({
    ...productMeta.state.value,
    items: productMeta.state.value.items?.[1]
  }));

  const temporaryInsuranceProfilesByCarrierId = ref({});
  function generateTemporaryInsuranceProfileByCarrierId(carrierId) {
    if (!temporaryInsuranceProfilesByCarrierId.value[carrierId]) {
      temporaryInsuranceProfilesByCarrierId.value[carrierId] =
        useCacheableSearch(async () => {
          const items = await getAllTemporaryInsuranceProfiles(carrierId);
          return items.map(i => ({
            title: i.name,
            value: i.id,
            additional: i
          }));
        });
    }
    return temporaryInsuranceProfilesByCarrierId.value[carrierId];
  }

  const buildChartsByCarrierId = ref({});
  function generateBuildChartsByCarrierId(carrierId) {
    if (!buildChartsByCarrierId.value[carrierId]) {
      const cache = useCacheableSearch(async () => {
        const params = new URLSearchParams();
        params.append("carrier_id", carrierId);
        const items = await getBuildCharts(params);
        return items.map(i => ({
          title: i.name,
          value: i.id,
          additional: i
        }));
      });
      buildChartsByCarrierId.value[carrierId] = cache;
    }
    return buildChartsByCarrierId.value[carrierId];
  }

  const smokerChartsByCarrierId = ref({});
  function generateSmokerChartsByCarrierId(carrierId) {
    if (!smokerChartsByCarrierId.value[carrierId]) {
      const cache = useCacheableSearch(async () => {
        const params = new URLSearchParams();
        params.append("carrier_id", carrierId);
        const items = await getSmokerCharts(params);
        return items.map(i => ({
          title: i.name,
          value: i.id,
          additional: i
        }));
      });
      smokerChartsByCarrierId.value[carrierId] = cache;
    }
    return smokerChartsByCarrierId.value[carrierId];
  }

  return {
    buildCharts: buildCharts.state,
    getBuildCharts: buildCharts.fetch,
    formCategories: formCategories.state,
    getFormCategoriesList: formCategories.fetch,
    submissionMethods: submissionMethods.state,
    getFormSubmissionMethods: submissionMethods.fetch,
    productSolves: productSolves.state,
    getProductSolves: productSolves.fetch,
    getProductMeta: productMeta.fetch,
    productTypes,
    productTraits,
    engines: engines.state,
    getEngines: engines.fetch,
    formSets: formSets.state,
    getFormSets: formSets.fetch,
    citizenshipProfiles: citizenshipProfiles.state,
    getCitizenshipProfiles: citizenshipProfiles.fetch,

    // dynamic generators
    generateTemporaryInsuranceProfileByCarrierId,
    temporaryInsuranceProfilesByCarrierId,
    generateBuildChartsByCarrierId,
    buildChartsByCarrierId,
    generateSmokerChartsByCarrierId,
    smokerChartsByCarrierId
  };
});
