import { useInstanceStore } from "@/stores/instance";
import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/documents";

export function getDocumentUrl(documentUid) {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${documentUid}/download?download`;
}

export function deleteDocument(documentId) {
  return getHttpClient().delete(`${baseUrl}/${documentId}`);
}

export function updateDocument(documentId, { name, category }) {
  return getHttpClient().put(`${baseUrl}/${documentId}`, { name, category });
}

export function downloadDocumentFromUid(uid, openInSelf = false) {
  const link = document.createElement("a");
  link.download = true;
  link.href = getDocumentUrl(uid);
  link.target = openInSelf ? "_self" : "_blank";
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
