<template>
  <router-link
    :to="{
      name: 'CarrierView',
      params: {
        id: props.id
      }
    }"
  >
    <v-img
      class="pointer"
      :src="props.image"
      style="border-radius: 4px"
      :height="props.height"
      :width="props.width"
      contain
    />
  </router-link>
</template>

<script setup>

const props = defineProps({
  image: { default: null, type: String },
  id: { default: null, type: [Number, String] },
  height: {
    default: "150px",
    type: String
  },
  width: {
    default: "300px",
    type: String
  }
});
</script>
