export function StatisticItem(model = {}) {
  const typeInfo = SITE_STATISTICS[model.type];
  return {
    type: model?.type || null,
    value: model?.value || null,
    typeInfo,
    text: typeInfo?.text,
    to: typeInfo?.to,
    statisticType: typeInfo?.statisticType,
    hint: typeInfo?.hint
  };
}

function StatisticDisplay(model = {}) {
  return {
    text: model?.text || "",
    statisticType: model?.statisticType || "",
    to: model?.to || undefined,
    hint: model?.hint || false
  };
}

export const STATISTIC_TYPES = {
  PENDING_CASES: "pending_cases",
  PENDING_PRODUCTION_CREDIT: "pending_production_credit",
  PLACEMENT_RATIO: "placement_ratio",
  ACTIVE_CASES: "downline_lifetime_active_cases"
};

const UNIT = {
  NUMBER: "number",
  PERCENT: "percent",
  DOLLAR: "dollar"
};

const SITE_STATISTICS = {
  [STATISTIC_TYPES.PENDING_CASES]: StatisticDisplay({
    text: "Pending Cases",
    statisticType: UNIT.NUMBER,
    to: { name: "CasesTable" }
  }),
  [STATISTIC_TYPES.PENDING_PRODUCTION_CREDIT]: StatisticDisplay({
    text: "Pending Production Credit",
    statisticType: UNIT.DOLLAR,
    to: { name: "CasesTable" },
    hint: true
  }),
  [STATISTIC_TYPES.PLACEMENT_RATIO]: StatisticDisplay({
    text: "Placement Ratio",
    statisticType: UNIT.PERCENT,
    to: { name: "PersonalReportView" }
  }),
  [STATISTIC_TYPES.ACTIVE_CASES]: StatisticDisplay({
    text: "Lifetime Active Cases",
    statisticType: UNIT.NUMBER
  })
};
