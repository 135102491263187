import { setDocumentFromRequest } from "@/factories/Document";

export function ToDo(model = {}) {
  return {
    id: model?.id || null,
    title: model?.title || "",
    complete: Boolean(model?.complete),
    subjects: model?.subjects || [],
    assignables: model?.assignables || [],
    documents: model?.documents || [],
    description: model?.description || "",
    followUpAt: model?.followUpAt || null,
    completedAt: model?.completedAt || null,
    createdAt: model?.createdAt || null,
    ownableName: model?.ownableName || ""
  };
}

export function setToDoFromResponse(response) {
  const todo = ToDo();
  todo.id = response.id;
  todo.title = response.title;
  todo.description = response.description;
  todo.complete = response.complete;

  todo.createdAt = response.created_at;
  todo.followUpAt = response.follow_up_at;
  todo.completedAt = response.completed_at;

  todo.subjects = response.todo_subjects.map(setToDoSubjectFromResponse);
  if (response.todo_assignees) {
    todo.assignables = response.todo_assignees.map(setToDoAssigneeFromResponse);
  }
  if (response.documents) {
    todo.documents = response.documents.map(d => setDocumentFromRequest(d));
  }
  if (response.ownable) {
    todo.ownableName = response.ownable.name;
  }
  return todo;
}

function ToDoSubject(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || "",
    type: model?.type || "",
    subjectId: model?.subjectId || null
  };
}

function setToDoSubjectFromResponse(response) {
  const subject = ToDoSubject();
  if (response.subject) {
    subject.id = response.subject.id;
    subject.name = response.subject.name;
    subject.type = response.subject.type;
  }
  // NOTE(tim): subject_title is an enhanced name included with the "include_subject_title" query param.
  if (response.subject_title) subject.name = response.subject_title;
  else if (!subject.name) subject.name = subject.type || "";

  subject.subjectId = response.id;
  return subject;
}

function ToDoAssignee(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || "",
    type: model?.type || "",
    assigneeId: model?.assigneeId || null
  };
}

function setToDoAssigneeFromResponse(response) {
  const assignee = ToDoAssignee();
  if (response.assignable) {
    assignee.id = response.assignable.id;
    assignee.name = response.assignable.name;
    assignee.type = response.assignable.type;
  }
  assignee.assigneeId = response.id;
  return assignee;
}

export function NewToDo(model = {}) {
  let subjects = [];
  if (model.subjects?.length) subjects = [...model.subjects];
  let assignables = [];
  if (model.assignables?.length) assignables = [...model.assignables];
  let documents = [];
  if (model.documents?.length) documents = [...model.documents];
  return {
    id: model?.id || null,
    complete: Boolean(model?.complete),
    subjects,
    assignables,
    documents,
    description: model?.description || "",
    title: model?.title || "",
    followUpAt: model?.followUpAt || null
  };
}

export function NewToDoToRequest(newToDo) {
  let subjects = undefined;
  if (newToDo.subjects?.length) {
    subjects = newToDo.subjects.map(a => ({ id: a.id, type: a.type }));
  }
  let assignees = undefined;
  if (newToDo.assignables?.length) {
    assignees = newToDo.assignables.map(a => ({ id: a.id, type: a.type }));
  }
  let documents = undefined;
  if (newToDo.documents?.length) {
    documents = newToDo.documents.map(value => value);
  }
  return {
    title: newToDo.title,
    description: newToDo.description || null,
    follow_up_at: newToDo.followUpAt,
    subjects,
    assignees,
    documents_attributes: documents
  };
}

export function NewToDoToRequestUpdate(newToDo) {
  return {
    title: newToDo.title,
    description: newToDo.description,
    follow_up_at: newToDo.followUpAt
  };
}

export function SubjectToRequest(value) {
  return {
    subject_id: value.id,
    subject_type: value.type
  };
}

export function AssigneeToRequest(value) {
  return {
    assignable_id: value.id,
    assignable_type: value.type
  };
}

export function CastToDoToNewToDo(todo) {
  const newTodo = NewToDo();
  newTodo.id = todo.id;
  newTodo.complete = todo.complete;
  newTodo.description = todo.description;
  newTodo.title = todo.title;
  newTodo.followUpAt = todo.followUpAt;
  newTodo.subjects = [...todo.subjects];
  newTodo.assignables = [...todo.assignables];
  newTodo.documents = [...todo.documents];
  return newTodo;
}
