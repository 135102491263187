<template>
  <v-checkbox
    v-bind="$attrs"
    :class="classes"
    density="compact"
    :base-color="success ? 'success' : 'basic'"
    :color="success ? 'success' : 'primary'"
    :error-messages="errorMessages"
  >
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="$slots.message" #message="{ message }">
      <slot name="message" :message="message" />
    </template>
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
  </v-checkbox>
</template>

<script setup>
import { computed, toRefs } from "vue";

const props = defineProps({
  success: Boolean,
  dense: Boolean,
  errorMessages: { type: Array, default: () => [] }
});

const { success, errorMessages } = toRefs(props);

const classes = computed(() => {
  const classes = ["checkbox-opacity full-opacity"];
  if(props.dense) classes.push("dense-checkbox")
  if (success.value) classes.push("success-field");
  if (errorMessages.length) classes.push("error-field");
  return classes.join(" ");
});
</script>
