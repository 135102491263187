import { getHttpClient } from "@/http-client";

const baseUrl = "/security";

export async function generateTwoFactorData() {
  const { data } = await getHttpClient().post(
    `${baseUrl}/two-factor/enroll/app`
  );
  return { secret: data.secret, qr_code: data.qr_code };
}

export function deleteTwoFactorEnrollment(otp_attempt) {
  return getHttpClient().delete(
    `${baseUrl}/two-factor`,
    { params: { otp_attempt } },
    {
      headers: {
        Accept: "application/json"
      }
    }
  );
}

export async function createTwoFactorData(otp_attempt) {
  const { data } = await getHttpClient().post(`${baseUrl}/two-factor`, {
    otp_attempt
  });
  return data;
}

export async function setTwoFactorDataLater() {
  const { data } = await getHttpClient().post(`${baseUrl}/two-factor`, {
    commit: "Setup Later"
  });
  return data;
}

export function changePassword({
  oldPassword,
  newPassword,
  newPasswordConfirmation
}) {
  return getHttpClient().put(
    `${baseUrl}/password`,
    {
      user: {
        current_password: oldPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation
      }
    },
    {
      headers: {
        Accept: "application/json"
      }
    }
  );
}

export function deleteIdentity(id) {
  return getHttpClient().delete(`${baseUrl}/sso/${id}`);
}
