<template>
  <v-row class="ma-0" dense>
    <v-col cols="12">
      <v-row class="ma-0">
        <div class="rounded bg-surface pa-3">
          <v-expansion-panels class="mb-3">
            <v-expansion-panel eager>
              <v-expansion-panel-title>
                <h2 class="font-weight-light">
                  View Support Tickets (<v-progress-circular
                    v-if="loading"
                    color="primary"
                    indeterminate
                    size="20"
                    width="2"
                  />
                  <span v-else> {{ count }}</span
                  >)
                </h2>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <support-tickets-table
                  @total="v => (count = v)"
                  @loading="v => (loading = v)"
                />
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <help-center-videos class="mb-6" />
          <help-center-articles />
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import SupportTicketsTable from "@/components/support-tickets/SupportTicketsTable.vue";

import HelpCenterVideos from "@/components/help-center/HelpCenterVideos.vue";
import HelpCenterArticles from "@/components/help-center/HelpCenterArticles.vue";
import { useHead } from "@unhead/vue";
import { ref } from "vue";

useHead({ title: "Help Center" });

const count = ref(0);
const loading = ref(false);
</script>
