import RedtailIntegrationDialog from "@/components/settings/security/RedtailIntegrationDialog.vue";
import { deleteIdentity } from "@/api/security.service";
import { useUserStore } from "@/stores/user";
import format from "date-fns/format";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import {
  executeWealthboxSso,
  executeMicrosoftSso
} from "@/components/settings/security/sso.composable";
import { storeToRefs } from "pinia";


import microsoft from "@/assets/img/microsoft.svg";
import wealthboxDark from "@/assets/img/wealthbox-dark.png";
import wealthbox from "@/assets/img/wealthbox.png";
import redtail from "@/assets/img/Redtail.svg";

export const useMicrosoftIntegration = () => {
  const user = useUserStore();
  const dialog = useDialogStore();

  const { integrations } = storeToRefs(user);

  return computed(() => {
    let actionText = "Get Started";

    let listeners = {
      click: executeMicrosoftSso
    };

    let value;
    if (integrations.value.microsoft?.enabled) {
      actionText = "Delete Connection";
      listeners.click = () =>
        dialog.showDialog({
          component: markRaw(ConfirmationDialog),
          title: "Delete Connection",
          subtitle:
            "Please confirm your intent to delete the connection to Microsoft.",
          func: async () => {
            await deleteIdentity(integrations.value.microsoft.id);
            integrations.value.microsoft.enabled = false;
            integrations.value.microsoft.id = null;
          }
        });
      const connected = format(
        new Date(integrations.value.microsoft.createdAt),
        "MM/dd/yyyy"
      );
      value = `You connected to Microsoft on ${connected}. `;
    }

    return {
      text: "Microsoft",
      darkImage: microsoft,
      image: microsoft,
      subtitle:
        "Sign-in with one click using your Microsoft account. No need to remember another password.",
      modelValue: value,
      actionText,
      listeners,
      solid: Boolean(integrations.value.microsoft?.enabled)
    };
  });
};

export const useWealthboxIntegration = () => {
  const user = useUserStore();
  const dialog = useDialogStore();

  const { integrations } = storeToRefs(user);

  return computed(() => {
    let actionText = "Get Started";

    let listeners = {
      click: executeWealthboxSso
    };

    let value;
    if (integrations.value.wealthbox?.enabled) {
      actionText = "Delete Connection";
      listeners.click = () =>
        dialog.showDialog({
          component: markRaw(ConfirmationDialog),
          title: "Delete Connection",
          subtitle:
            "Please confirm your intent to delete the connection to Wealthbox.",
          func: async () => {
            await deleteIdentity(integrations.value.wealthbox.id);
            user.integrations.wealthbox.enabled = false;
            user.integrations.wealthbox.id = null;
          }
        });
      const connected = format(
        new Date(integrations.value.wealthbox.createdAt),
        "MM/dd/yyyy"
      );
      value = `You connected to Wealthbox on ${connected}. `;
    }

    return {
      text: "Wealthbox",
      darkImage: wealthboxDark,
      image: wealthbox,
      subtitle:
        "Improve your efficiency with our Wealthbox integration. Launch Quote & Apply directly from Wealthbox CRM, using your clients' existing information to prefill electronic applications. Automatically sync your contacts from Boss into Wealthbox, reducing manual data entry and saving you time—so you can focus on growing your business.",
      modelValue: value,
      actionText,
      listeners,
      solid: Boolean(integrations.value.wealthbox?.enabled)
    };
  });
};

export const useRedtailIntegration = () => {
  const user = useUserStore();
  const dialog = useDialogStore();

  const { integrations } = storeToRefs(user);
  return computed(() => {
    let actionText = "Get Started";

    let listeners = {
      click: () =>
        dialog.showDialog({
          component: markRaw(RedtailIntegrationDialog)
        })
    };

    let value;
    if (integrations.value.redtail?.enabled) {
      actionText = "Delete Connection";
      listeners.click = () =>
        dialog.showDialog({
          component: markRaw(ConfirmationDialog),
          title: "Delete Connection",
          subtitle:
            "Please confirm your intent to delete the connection to Redtail.",
          func: async () => {
            await deleteIdentity(integrations.value.redtail.id);
            user.integrations.redtail.enabled = false;
            user.integrations.redtail.id = null;
          }
        });
      const connected = format(
        new Date(integrations.value.redtail.createdAt),
        "MM/dd/yyyy"
      );
      value = `You connected to Redtail on ${connected}. `;
    }
    return {
      text: "Redtail",
      darkImage: redtail,
      image: redtail,
      subtitle:
        "Effortlessly manage your clients and insurance cases with our seamless Redtail integration. Keep your contacts and cases in sync, so you can easily access client information and related insurance policies within Redtail. Seperately, add your NPN within Redtail so you can launch Quote & Apply and automatically prefill the eApp with your client's information.",
      modelValue: value,
      actionText,
      listeners,
      solid: Boolean(integrations.value.redtail?.enabled)
    };
  });
};
