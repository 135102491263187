<template>
  <v-row dense>
    <v-col cols="12" md="4">
      <agent-search
        v-model="model.advisor"
        label="Search for an Agent"
        data-testid="advisor"
        :clearable="false"
        :success="advisorValidation.success"
        :error-messages="advisorValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="3">
      <select-field
        v-model="model.code"
        :prepend-inner-icon="mdiPound"
        label="Code"
        item-title="name"
        return-object
        persistent-hint
        hint=" "
        data-testid="code"
        :success="codeValidation.success"
        :error-messages="codeValidation.errorMessages"
        :disabled="!carrierId || !model.advisor?.id"
        :loading="loadingCodes"
        :items="codes"
      >
        <template #message="{ message }">
          <v-row class="pa-3">
            {{ message }}
            <v-spacer />
            <a data-testid="add-appointment" @click="createAppointment">
              No Codes? Create an Appointment
            </a>
          </v-row>
        </template>
      </select-field>
    </v-col>
    <v-col cols="12" md="2">
      <integer-input
        v-model="model.splitPercentage"
        data-lpignore="true"
        :prepend-inner-icon="mdiPercent"
        data-testid="split-percentage"
        label="Split %"
        mask="###"
        type="text"
        inputmode="numeric"
        :success="splitPercentageValidation.success"
        :error-messages="splitPercentageValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-row class="ma-0">
        <integer-input
          v-model="model.renewalSplitPercentage"
          data-lpignore="true"
          :prepend-inner-icon="mdiPercent"
          data-testid="renewal-split-percentage"
          label="Renewal %"
          mask="###"
          type="text"
          inputmode="numeric"
          :success="renewalSplitPercentageValidation.success"
          :error-messages="renewalSplitPercentageValidation.errorMessages"
        />
        <app-button
          :icon="mdiDelete"
          variant="text"
          density="comfortable"
          color="error"
          class="text-none"
          data-testid="delete-advisor"
          @click="emit('delete-advisor')"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import AppointmentCreate from "@/components/appointments/AppointmentCreate.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import IntegerInput from "@/components/shared/IntegerInput.vue";
import { getCode } from "@/api/appointment.service";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import useVuelidate from "@vuelidate/core";
import { ref, computed, watch, toRefs, markRaw } from "vue";
import { mdiPound, mdiPercent, mdiDelete } from "@mdi/js";

const props = defineProps({
  modelValue: { type: Object, default: () => {} },
  carrier: { type: Object, default: () => {} },
  product: { type: Object, default: () => {} }
});
const emit = defineEmits(["delete-advisor"]);
const { carrier, product } = toRefs(props);

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const codes = ref([]);
const model = ref(props.modelValue);
const loadingCodes = ref(false);

const carrierId = computed(() => carrier.value?.id);

async function createAppointment() {
  const code = await dialog.showDialog({
    component: markRaw(AppointmentCreate),
    carrier: carrier.value,
    productId: product.value?.id,
    advisor: model.value.advisor
  });
  if (!code?.id) return;
  await findCodes();
  model.value.code = codes.value.find(({ id }) => id === code.id);
}

async function findCodes() {
  if (!model.value.advisor?.id || !carrier.value.id) return;

  loadingCodes.value = true;
  try {
    codes.value = (
      await getCode(
        model.value.advisor.id,
        model.value.advisor.type.model,
        carrier.value.id
      )
    ).map(c => ({ ...c, name: `${c.name} (${c.id})` }));

    const currentCodeId = model.value.code?.id;

    if (currentCodeId && !codes.value.some(c => c.id === currentCodeId)) {
      model.value.code = undefined;
      v$.value.model.code.$reset();
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingCodes.value = false;
  }
}

const currentAdvisor = computed(() => model.value.advisor);
watch([carrier, currentAdvisor], findCodes, { deep: true });

const v$ = useVuelidate(
  {
    model: {
      advisor: { required: v => Boolean(v?.id) },
      code: { required: v => Boolean(v?.id) },
      splitPercentage: { required: Boolean },
      renewalSplitPercentage: { required: Boolean }
    }
  },
  { model },
  { $scope: "new-case", $autoDirty: true }
);

const advisorValidation = computedValidation(v$.value.model.advisor, [
  { key: "required", message: "Required" }
]);
const codeValidation = computedValidation(v$.value.model.code, [
  { key: "required", message: "Required" }
]);
const splitPercentageValidation = computedValidation(
  v$.value.model.splitPercentage,
  [{ key: "required", message: "Required" }]
);
const renewalSplitPercentageValidation = computedValidation(
  v$.value.model.renewalSplitPercentage,
  [{ key: "required", message: "Required" }]
);
</script>
