<template>
  <div v-if="loading" class="rounded bg-surface ma-1">
    <v-skeleton-loader type="card" />
  </div>
  <div v-else class="rounded bg-surface ma-1">
    <support-ticket-view-card />
    <support-ticket-view-actions />
    <view-tabs v-model="activeTab" :tabs="tabs" />
  </div>
</template>

<script setup>
import SupportTicketViewCard from "@/components/support-tickets/SupportTicketViewCard.vue";
import SupportTicketViewActions from "@/components/support-tickets/SupportTicketViewActions.vue";
import SupportTicketViewChat from "@/components/support-tickets/SupportTicketViewChat.vue";
import SupportTicketViewDetails from "@/components/support-tickets/SupportTicketViewDetails.vue";
import ViewTabs from "@/components/shared/ViewTabs.vue";

import { parseErrorMessage } from "@/util/helpers";
import { computed, markRaw, ref, watch } from "vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useSupportTicketView } from "@/stores/support-ticket-view";
import { useInstanceStore } from "@/stores/instance";
import { useUserStore } from "@/stores/user";

import { storeToRefs } from "pinia";
import { useHead } from "@unhead/vue";
import { mdiFileDocumentEdit, mdiMessage } from "@mdi/js";
import { useRouter } from "vue-router";

const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  }
});

const { breadcrumb } = storeToRefs(useInstanceStore());
const supportTicketView = useSupportTicketView();
const snackbar = useSnackbarStore();
const user = useUserStore();
const router = useRouter();
const head = useHead({ title: "Support Ticket" });

const loading = ref(false);
const activeTab = ref(0);

async function fetch() {
  loading.value = true;

  try {
    await supportTicketView.initialize(props.id);
    breadcrumb.value = props.id;
    head.patch({ title: `Support Ticket #${props.id}` });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const tabs = computed(() => {
  let tabs = [
    {
      title: "Chat",
      component: markRaw(SupportTicketViewChat),
      icon: mdiMessage,
      query: "chat",
      eager: true
    }
  ];

  if (user.loginable.is_employee) {
    tabs.push({
      title: "Details",
      component: markRaw(SupportTicketViewDetails),
      icon: mdiFileDocumentEdit,
      query: "details"
    });
  }
  return tabs;
});

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});
function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

fetch();
</script>
