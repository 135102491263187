import { serializeObject } from "@/util/helpers";

import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/save_signature";
export async function updateSignature(signatureFile) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject({ file: signatureFile })
  );
  return data.message;
}
