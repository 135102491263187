<template>
  <v-form @submit.prevent="signUpNewUser">
    <v-card elevation="8">
      <v-col>
        <v-row class="ma-0" align="center" justify="center">
          <template v-if="avatarUrl">
            <v-img
              :src="avatarUrl"
              max-width="75px"
              max-height="75px"
              contain
            />
            <v-icon class="mx-1" :icon="mdiPlus" />
          </template>
          <app-logo />
        </v-row>
      </v-col>
      <v-card-title data-testid="title" class="justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <basic-email-input
              v-model="newUser.email"
              label="Email"
              data-testid="email"
              autofocus
              :success="emailValidation.success"
              :error-messages="emailValidation.errorMessages"
              @valid="emailIsValid = $event"
              @update:model-value="showForm = false"
            />

            <v-scroll-y-transition mode="out-in">
              <div v-if="showForm">
                <text-field
                  v-model="newUser.npn"
                  data-lpignore="true"
                  :prepend-inner-icon="mdiPound"
                  label="National Producer Number"
                  type="text"
                  inputmode="numeric"
                  persistent-hint
                  autofocus
                  hint=" "
                  data-testid="npn"
                  :success="npnValidation.success"
                  :error-messages="npnValidation.errorMessages"
                >
                  <template #message="{ message: formMessage }">
                    <v-row class="pa-3">
                      {{ formMessage }}
                      <v-spacer />
                      <a
                        href="https://www.nipr.com/PacNpnSearch.htm"
                        target="_blank"
                      >
                        Don't know your NPN?
                      </a>
                    </v-row>
                  </template>
                </text-field>
                <text-field
                  v-model="newUser.password"
                  data-lpignore="true"
                  data-testid="signup-password"
                  :prepend-inner-icon="mdiAsterisk"
                  label="Password"
                  type="password"
                  hint="Must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol (#?!@$ %^&*-_.)"
                  persistent-hint
                  :success="passwordValidation.success"
                  :error-messages="passwordValidation.errorMessages"
                />
                <select-field
                  v-if="showDiscovery"
                  v-model="newUser.discovery"
                  :prepend-inner-icon="mdiSend"
                  label="How Did You Find Us?"
                  data-testid="discovery"
                  :items="DISCOVERY_ITEMS"
                  :success="discoveryValidation.success"
                  :error-messages="discoveryValidation.errorMessages"
                />
                <text-field
                  v-if="showDiscoveryExplanation"
                  v-model="newUser.discovery_explanation"
                  data-testid="discovery-explanation"
                  data-lpignore="true"
                  maxlength="128"
                  :label="isOther ? 'Please Specify' : 'Who Referred You?'"
                  :success="discoveryExplanationValidation.success"
                  :error-messages="discoveryExplanationValidation.errorMessages"
                  :prepend-inner-icon="isOther ? mdiText : mdiAccount"
                />
                <select-field
                  v-if="showMarketingManager"
                  v-model="newUser.marketingManager"
                  :prepend-inner-icon="mdiAccountTie"
                  data-testid="marketing-manager"
                  return-object
                  item-title="name"
                  :label="backnineContactLabel"
                  :items="contacts"
                  :loading="loadingExternal"
                  :success="marketingManagerValidation.success"
                  :error-messages="marketingManagerValidation.errorMessages"
                />
                <v-row
                  v-if="requiredMembershipData.yesNo"
                  class="mb-3"
                  justify="start"
                  dense
                >
                  <v-col cols="12">
                    <p class="text-left mb-0" style="font-size: 1rem">
                      Are you a member of a broker-dealer, cluster, or
                      aggregator?
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="ma-0">
                      <app-button
                        data-testid="member-yes"
                        class="text-none mr-1"
                        :variant="buttonVariant(useMembership === true)"
                        :color="membershipColors.true"
                        @click="useMembership = true"
                      >
                        Yes
                      </app-button>
                      <app-button
                        class="text-none"
                        data-testid="member-no"
                        :variant="buttonVariant(useMembership === false)"
                        :color="membershipColors.false"
                        @click="useMembership = false"
                      >
                        No
                      </app-button>
                    </v-row>
                  </v-col>
                </v-row>
                <v-slide-y-transition mode="out-in">
                  <autocomplete-field
                    v-if="requiredMembershipData.affiliation"
                    ref="membershipRef"
                    v-model="newUser.membership"
                    v-model:search="membershipSearch"
                    data-testid="member-name"
                    :prepend-inner-icon="mdiDomain"
                    item-title="name"
                    item-value="id"
                    :menu-props="{ auto: true, overflowY: true }"
                    label="What broker-dealer, cluster, or aggregator are you a member of?"
                    clearable
                    :success="membershipValidation.success"
                    :error-messages="membershipValidation.errorMessages"
                    :disabled="loadingSources"
                    :items="availableMemberships"
                    :loading="loadingSources"
                  >
                    <template #no-data>
                      <v-list-item @click="newUser.membership = 'Other'">
                        <v-list-item-title>
                          Can't find your membership?
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </autocomplete-field>
                </v-slide-y-transition>
                <v-slide-y-transition mode="out-in">
                  <text-field
                    v-if="requiredMembershipData.other"
                    v-model="newUser.otherMembership"
                    data-lpignore="true"
                    autofocus
                    data-testid="other-member"
                    :prepend-inner-icon="mdiDomainPlus"
                    label="Who do you use?"
                    :success="otherMembershipValidation.success"
                    :error-messages="otherMembershipValidation.errorMessages"
                  />
                </v-slide-y-transition>
                <v-slide-y-transition mode="out-in">
                  <v-row
                    v-if="requiredManagementData.yesNo"
                    class="mb-3"
                    justify="start"
                    dense
                  >
                    <v-col cols="12">
                      <p class="text-left mb-1" style="font-size: 1rem">
                        Do you use any agency management system / CRM?
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-row class="ma-0">
                        <app-button
                          class="text-none mr-1"
                          data-testid="management-yes"
                          :variant="buttonVariant(useManagement === true)"
                          :color="managementColors.true"
                          @click="useManagement = true"
                        >
                          Yes
                        </app-button>
                        <app-button
                          class="text-none"
                          data-testid="management-no"
                          :variant="buttonVariant(useManagement === false)"
                          :color="managementColors.false"
                          @click="useManagement = false"
                        >
                          No
                        </app-button>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-slide-y-transition>
                <v-slide-y-transition mode="out-in">
                  <autocomplete-field
                    v-if="requiredManagementData.affiliation"
                    ref="managementRef"
                    v-model="newUser.management"
                    v-model:search="managementSearch"
                    data-testid="management-name"
                    :prepend-inner-icon="mdiTable"
                    item-title="name"
                    item-value="id"
                    :menu-props="{ auto: true, overflowY: true }"
                    clearable
                    dense
                    :label="managementLabel"
                    :success="managementValidation.success"
                    :error-messages="managementValidation.errorMessages"
                    :disabled="loadingSources"
                    :items="availableRaters"
                    :loading="loadingSources"
                  >
                    <template #no-data>
                      <v-list-item @click="newUser.management = 'Other'">
                        <v-list-item-title>
                          Can't find your system?
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </autocomplete-field>
                </v-slide-y-transition>
                <v-slide-y-transition mode="out-in">
                  <text-field
                    v-if="requiredManagementData.other"
                    v-model="newUser.otherManagement"
                    data-lpignore="true"
                    autofocus
                    data-testid="other-management"
                    :prepend-inner-icon="mdiTablePlus"
                    label="Who do you use?"
                    :success="otherManagementValidation.success"
                    :error-messages="otherManagementValidation.errorMessages"
                  />
                </v-slide-y-transition>

                <p class="text-left mb-1" style="font-size: 1rem">
                  Subscribe to marketing emails
                </p>
                <v-row class="ma-0 mb-5" justify="start">
                  <app-button
                    class="text-none mr-1"
                    data-testid="marketing-yes"
                    :variant="buttonVariant(newUser.marketing === true)"
                    :disabled="loading"
                    :color="marketingColors.true"
                    @click="newUser.marketing = true"
                  >
                    Yes
                  </app-button>
                  <app-button
                    class="text-none"
                    data-testid="marketing-no"
                    :disabled="loading"
                    :variant="buttonVariant(newUser.marketing === false)"
                    :color="marketingColors.false"
                    @click="newUser.marketing = false"
                  >
                    No
                  </app-button>
                </v-row>

                <div class="checkbox-width">
                  <checkbox-field
                    v-model="termsAccepted"
                    class="mt-0"
                    data-testid="actions"
                    :success="termsAcceptedValidation.success"
                    :error-messages="termsAcceptedValidation.errorMessages"
                    :disabled="loading"
                  >
                    <template #label>
                      I agree to the
                      <a
                        href="https://github.com/back9ins/terms"
                        target="_blank"
                        class="pl-1"
                        @click.stop="event => event.stopPropagation()"
                      >
                        terms and conditions.
                      </a>
                    </template>
                  </checkbox-field>
                </div>
              </div>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-button
          color="primary"
          class="text-none"
          style="width: 100%"
          data-testid="sign-up"
          variant="elevated"
          type="submit"
          :loading="loading"
        >
          <v-fade-transition mode="out-in">
            <span v-if="showForm"> Sign up </span>
            <span v-else> Continue </span>
          </v-fade-transition>
        </app-button>
      </v-card-actions>
      <v-card-text>
        Already have an account?
        <router-link :to="{ name: 'SignIn' }">Sign in </router-link>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script setup>
import AppLogo from "@/components/AppLogo.vue";
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";

import { signUp } from "@/api/auth.service";
import { getExternalMarketingManagers } from "@/api/marketing-managers.service";
import { getAllSources } from "@/api/sources.service";

import { parseErrorMessage, someTextValidator } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { computed, nextTick, ref, watch } from "vue";
import { computedValidation } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";
import { useSignupStore } from "@/stores/sign-up";
import { NewUser, DISCOVERY_ITEMS } from "@/factories/NewUser";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import {
  passwordValidator,
  passwordValidatorMessages
} from "@/util/vuelidateValidators";
import { checkUserEmail } from "@/api/users.service";
import {
  executeOAuthSso,
  executeSamlSso
} from "../settings/security/sso.composable";

import {
  mdiPlus,
  mdiPound,
  mdiAsterisk,
  mdiSend,
  mdiText,
  mdiAccount,
  mdiAccountTie,
  mdiDomain,
  mdiDomainPlus,
  mdiTable,
  mdiTablePlus
} from "@mdi/js";
import { useTheme } from "vuetify/lib/framework.mjs";

useHead({ titleTemplate: "Sign Up | BOSS" });

const {
  message,
  hideAffiliations,
  name,
  referralCode,
  discovery,
  referrer,
  avatarUrl
} = storeToRefs(useSignupStore());

const snackbar = useSnackbarStore();
const theme = useTheme();

const newUser = ref(
  NewUser({
    referrer: referrer.value,
    referral: referralCode.value
  })
);

const initializedWithQuery = DISCOVERY_ITEMS.includes(discovery.value);
if (initializedWithQuery) newUser.value.discovery = discovery.value;

const emailIsValid = ref(null);
const useMembership = ref(null);
const useManagement = ref(null);
const contacts = ref([]);
const loadingExternal = ref(false);
const loading = ref(false);
const termsAccepted = ref(false);
const memberships = ref([]);
const raters = ref([]);
const crms = ref([]);
const loadingSources = ref(false);
const managementSearch = ref("");
const membershipSearch = ref("");

const title = computed(() => {
  let title = "Sign Up for BackNine";
  if (message.value) title = message.value;
  else if (name.value) {
    title = `${name.value} has invited you to sign up for BackNine`;
  }
  return title;
});

const availableMemberships = computed(() => [
  ...memberships.value,
  { name: "Other", id: "Other" }
]);
const backnineContactLabel = computed(() =>
  showBackNineContact.value
    ? "Select Your BackNine Contact"
    : "Do you have a BackNine Contact? (Optional)"
);
const isPnc = computed(() => {
  const membership = memberships.value.find(
    val => val.id === newUser.value.membership
  );
  if (!membership) return false;
  return membership.category === "P&C";
});
const isOther = computed(() => newUser.value.discovery === "Other");
const showDiscovery = computed(
  () => !initializedWithQuery && !referralCode.value
);
const showDiscoveryExplanation = computed(() =>
  ["Word of Mouth", "Other"].includes(newUser.value.discovery)
);
const showBackNineContact = computed(() =>
  ["BackNine Contact"].includes(newUser.value.discovery)
);
const showOptionalBackNineContact = computed(() =>
  ["Other", "Word of Mouth"].includes(newUser.value.discovery)
);

const showMarketingManager = computed(
  () => showBackNineContact.value || showOptionalBackNineContact.value
);

const managementLabel = computed(() =>
  isPnc.value
    ? "What membership do you use?"
    : "What agency management systems or raters do you use?"
);

const requiredMembershipData = computed(() => {
  let yesNo = false;
  let affiliation = false;
  let other = false;

  if (!hideAffiliations.value) {
    yesNo = true;
    if (useMembership.value) {
      affiliation = true;
      other = newUser.value.membership === "Other";
    }
  }

  return { yesNo, affiliation, other };
});

const requiredManagementData = computed(() => {
  let yesNo = false;
  let affiliation = false;
  let other = false;

  if (
    !hideAffiliations.value &&
    (newUser.value.membership || useMembership.value === false)
  ) {
    yesNo = !isPnc.value;
    affiliation = useManagement.value || isPnc.value;
    other = newUser.value.management === "Other" && affiliation;
  }

  return { yesNo, affiliation, other };
});

const availableRaters = computed(() => {
  let availableRaters = [...raters.value];

  if (!isPnc.value) {
    const names = {};
    availableRaters.push(...crms.value);

    availableRaters = availableRaters.filter(val => {
      if (names[val.name]) return false;
      names[val.name] = val;
      return true;
    });
  }

  availableRaters.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  availableRaters.push({ name: "Other", id: "Other" });

  return availableRaters;
});

const v$ = useVuelidate(
  {
    termsAccepted: {
      required: val => Boolean(val)
    },
    useMembership: {
      required: val => !requiredMembershipData.value.yesNo || val !== null
    },
    useManagement: {
      required: val => !requiredManagementData.value.yesNo || val !== null
    },
    newUser: {
      npn: { required: v => Boolean(v), minLength: v => `${v}`.length >= 4 },
      email: {
        required: v => Boolean(v),
        isValid: () => emailIsValid.value === true
      },
      password: passwordValidator,
      discovery: {
        required: value => someTextValidator(showDiscovery.value, value)
      },
      discovery_explanation: {
        required: value =>
          someTextValidator(showDiscoveryExplanation.value, value)
      },
      marketingManager: {
        id: {
          required: value => !showBackNineContact.value || Boolean(value)
        }
      },
      membership: {
        required: value =>
          !requiredMembershipData.value.affiliation || Boolean(value)
      },
      otherMembership: {
        required: value => !requiredMembershipData.value.other || Boolean(value)
      },
      management: {
        required: value =>
          !requiredManagementData.value.affiliation || Boolean(value)
      },
      otherManagement: {
        required: value => !requiredManagementData.value.other || Boolean(value)
      },
      marketing: {
        required: v => [true, false].includes(v)
      }
    }
  },
  {
    termsAccepted,
    useMembership,
    useManagement,
    newUser,
    requiredManagementData,
    requiredMembershipData
  },
  {
    $scope: false,
    $autoDirty: true
  }
);

const emailValidation = computedValidation(v$.value.newUser.email, {
  required: "Required",
  isValid: "Invalid Email"
});
const termsAcceptedValidation = computedValidation(v$.value.termsAccepted, {
  required: "Required"
});
const passwordValidation = computedValidation(
  v$.value.newUser.password,
  passwordValidatorMessages
);

const npnValidation = computedValidation(v$.value.newUser.npn, {
  required: "Required",
  minLength: "Must be 4 characters long"
});
const discoveryValidation = computedValidation(v$.value.newUser.discovery, {
  required: "Required"
});
const discoveryExplanationValidation = computedValidation(
  v$.value.newUser.discovery_explanation,
  { required: "Required" }
);
const marketingManagerValidation = computedValidation(
  v$.value.newUser.marketingManager.id,
  { required: "Required" }
);
const marketingValidation = computedValidation(v$.value.newUser.marketing, {
  required: "Required"
});
const otherMembershipValidation = computedValidation(
  v$.value.newUser.otherMembership,
  { required: "Required" }
);
const otherManagementValidation = computedValidation(
  v$.value.newUser.otherManagement,
  { required: "Required" }
);
const useMembershipValidation = computedValidation(v$.value.useMembership, {
  required: "Required"
});
const useManagementValidation = computedValidation(v$.value.useManagement, {
  required: "Required"
});
const membershipValidation = computedValidation(v$.value.newUser.membership, {
  required: "Required"
});
const managementValidation = computedValidation(v$.value.newUser.management, {
  required: "Required"
});

const membershipColors = computed(() => {
  const getColors = v => {
    if (useMembershipValidation.value.errorMessages.length) return "error";
    if (useMembership.value === v) return "accent";
    if (!theme.current.value.dark) return "grey darken-1";
    return null;
  };
  return {
    true: getColors(true),
    false: getColors(false)
  };
});
const managementColors = computed(() => {
  const getColors = v => {
    if (useManagementValidation.value.errorMessages.length) return "error";
    if (useManagement.value === v) return "accent";
    if (!theme.current.value.dark) return "grey darken-1";
    return null;
  };

  return {
    true: getColors(true),
    false: getColors(false)
  };
});
const marketingColors = computed(() => {
  const getColors = value => {
    if (marketingValidation.value.errorMessages.length) return "error";
    if (newUser.value.marketing === value) return "accent";
    if (!theme.current.value.dark) return "grey darken-1";
    return null;
  };

  return {
    true: getColors(true),
    false: getColors(false)
  };
});

watch(showMarketingManager, () => {
  newUser.value.marketingManager = null;
});

watch(showDiscovery, () => {
  newUser.value.discovery = null;
  newUser.value.discovery_explanation = null;
});

watch(useMembership, () => {
  useManagement.value = null;
  newUser.value.management = null;
  newUser.value.otherManagement = null;
});

watch(useManagement, () => {
  newUser.value.management = null;
  newUser.value.otherManagement = null;
});

const managementRef = ref(null); //templateref
watch(
  () => requiredManagementData.value.other,
  v => {
    if (v) {
      nextTick(() => {
        if (managementRef.value?.$el) {
          managementRef.value.$el.querySelector("input")?.blur();
        }
        if (managementSearch.value.toLowerCase() === "other") return;
        newUser.value.otherManagement = managementSearch.value;
      });
    } else {
      newUser.value.otherMembership = null;
    }
  }
);

const membershipRef = ref(null); //templateref
watch(
  () => requiredMembershipData.value.other,
  v => {
    if (v) {
      nextTick(() => {
        if (managementRef.value?.$el) {
          managementRef.value.$el.querySelector("input")?.blur();
        }
        if (membershipSearch.value.toLowerCase() === "other") return;
        newUser.value.otherMembership = membershipSearch.value;
      });
    } else {
      newUser.value.otherMembership = null;
    }
  }
);

async function fetchSources() {
  loadingSources.value = true;
  try {
    const res = await getAllSources();
    crms.value = res.crms;
    raters.value = res.raters;
    memberships.value = res.memberships;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingSources.value = false;
  }
}
async function fetchMarketingManagers() {
  loadingExternal.value = true;
  contacts.value = await getExternalMarketingManagers();
  loadingExternal.value = false;
}

const router = useRouter();
const showForm = ref(false);
async function signUpNewUser() {
  if (!showForm.value) return await checkForSso();
  const valid = await v$.value.$validate();
  if (!valid) return;
  loading.value = true;
  const body = newUser.value;
  try {
    await signUp(body);
    if (router) router.push({ name: "Home" });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

async function checkForSso() {
  try {
    loading.value = true;
    const { provider, method } = await checkUserEmail(newUser.value.email);

    if (provider && method) {
      const handlingSso = handleSso(provider, method);
      if (handlingSso) return;
    }
    loading.value = false;
    showForm.value = true;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    loading.value = false;
    showForm.value = true;
  }
}

async function handleSso(provider, method) {
  if (method === "saml") return executeSamlSso(provider);
  else if (method === "oauth") return executeOAuthSso(provider);
}

function buttonVariant(value) {
  return value ? "elevated" : "outlined";
}

fetchMarketingManagers();
fetchSources();
</script>
