<template>
  <v-card
    flat
    style="position: relative; overflow: hidden"
    :variant="focused ? 'outlined' : undefined"
    :ripple="false"
    @click="emit('click')"
  >
    <v-row class="ma-3" align="center" dense>
      <v-col cols="12" :md="showText ? 4 : 9">
        <text-field
          v-model="valueModel"
          data-lpignore="true"
          label="Value"
          hide-details
          data-testid="field-option-value"
          :prepend-inner-icon="mdiForum"
          :readonly="readonly || isRadio"
          :disabled="readonly || isRadio"
          v-bind="valueValidation"
        />
      </v-col>
      <v-col v-if="showText" cols="12" md="5">
        <text-field
          v-model="textModel"
          data-lpignore="true"
          label="Text"
          hide-details
          data-testid="field-option-text"
          :prepend-inner-icon="mdiForum"
          :readonly="readonly || isRadio"
          :disabled="readonly || isRadio"
          v-bind="textValidation"
        />
      </v-col>
      <v-col v-if="canBeVirtual || !readonly" cols="12" md="3">
        <app-button
          v-if="canBeVirtual"
          variant="text"
          density="comfortable"
          data-testid="field-option-virtual"
          :class="{'is-not-virtual': virtual, 'is-virtual': !virtual}"
          :icon="virtual ? mdiEyeOff : mdiEye"
          :color="virtual ? 'orange' : ''"
          :disabled="readonly"
          @click="toggleVirtual"
        />
        <app-button
          v-if="!readonly"
          :icon="mdiDelete"
          variant="text"
          density="comfortable"
          color="error"
          data-testid="delete-field-option"
          @click="emit('delete')"
        />
      </v-col>
    </v-row>
    <div v-if="focused" class="focused-option-container">
      <div class="focused-option-inner-container"></div>
      <v-icon size="small" :icon="mdiBullseyeArrow" />
    </div>
  </v-card>
</template>

<script setup>
import { computed } from "vue";
import {
  mdiForum,
  mdiEyeOff,
  mdiEye,
  mdiDelete,
  mdiBullseyeArrow
} from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { useMappedFormStore } from "@/stores/mapped-form";
import {
  NOT_ON_PDF_OPTION_TYPE,
  RADIO_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  VERBATIM_QUESTION
} from "@/factories/FormMapping";
import { computedValidation } from "@/util/helpers";
const props = defineProps({
  focused: Boolean,
  formId: { type: [String, Number], required: true },
  text: {
    type: String,
    required: true
  },
  value: {
    type: String,
    required: true
  },
  virtual: {
    type: Boolean,
    required: false
  },
  validationScope: {
    type: String,
    required: true
  }
});

const { activeField, readonly: readonlyForm } = storeToRefs(
  useMappedFormStore(props.formId)
);

const emit = defineEmits([
  "click",
  "delete",
  "update",
  "update:virtual",
  "update:value",
  "update:text"
]);

function update(updateEvent, value) {
  emit(updateEvent, value);
  emit("update");
}

const isTextPdfFieldType = computed(
  () => activeField.value.pdfFieldType === TEXT_OPTION_TYPE
);

const canBeVirtual = computed(
  () =>
    !isTextPdfFieldType.value &&
    activeField.value.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE
);

// if it is radio, it should be readonly

const readonly = computed(() =>
  Boolean(readonlyForm.value || activeField.value.referenceField)
);

const isRadio = computed(
  () => activeField.value.quoteAndApplyType === RADIO_OPTION_TYPE
);

const showText = computed(
  () =>
    activeField.value?.applicationQuestion?.name === VERBATIM_QUESTION &&
    activeField.value.quoteAndApplyType !== RADIO_OPTION_TYPE &&
    !activeField.value.isDocusignField
);

const textModel = computed({
  get: () => props.text,
  set: v => update("update:text", v)
});

const valueModel = computed({
  get: () => props.value,
  set: v => update("update:value", v)
});

const v$ = useVuelidate(
  {
    valueModel: { required: v => Boolean(v) || v === 0 },
    textModel: { required: v => !showText.value || Boolean(v) || v === 0 }
  },
  {
    valueModel,
    textModel
  },
  {
    $scope: props.validationScope
  }
);

const textValidation = computedValidation(v$.value.textModel, {
  required: "Required"
});
const valueValidation = computedValidation(v$.value.valueModel, {
  required: "Required"
});

function toggleVirtual() {
  if (readonly.value) return;
  update("update:virtual", !props.virtual);
}
</script>

<style lang="scss">
.focused-option-container {
  position: absolute;
  bottom: 0;
  right: 5px;
}
.focused-option-container > .focused-option-inner-container {
  background-color: rgba(0, 0, 0, 0.12);

  width: 100px;
  height: 100px;
  position: absolute;
  bottom: -65px;
  right: -65px;
  transform: rotate(-45deg);
}

.theme--dark > .focused-option-container > .focused-option-inner-container {
  background-color: rgba(255, 255, 255, 0.12);
}
</style>
