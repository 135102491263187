<template>
  <v-card
    data-testid="change-password"
    variant="outlined"
    class="pt-6 pa-3 bg-surface"
    @click="openChangePasswordDialog"
  >
    <div class="w-100 flex-row justify-center">
      <v-icon size="64" class="opacity-50" :icon="mdiLockReset" />
    </div>
    <v-card-title class="justify-start text-h5">Password</v-card-title>
    <v-card-text> &nbsp; </v-card-text>
    <v-card-text class="text-left">
      <div style="max-width: 25em">
        Enhance the security of your password, or consider changing it if it's
        known by someone else.
      </div>
    </v-card-text>
    <v-card-actions>
      <app-button
        class="text-none"
        variant="text"
        color="primary"
        text="Change Password"
      />
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { useDialogStore } from "@/stores/dialog";
import { mdiLockReset } from "@mdi/js";
import { markRaw } from "vue";

import SecurityChangePasswordDialog from "@/components/settings/security/SecurityChangePasswordDialog.vue";

const dialog = useDialogStore();
function openChangePasswordDialog() {
  dialog.showDialog({
    component: markRaw(SecurityChangePasswordDialog)
  });
}
</script>
