<template>
  <v-card>
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        must-sort
        data-testid="tasks-table"
        item-value="additional.itemKey"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :loading="table.loading.value"
        :footer-props="table.footerProps.value"
        :items-per-page-options="table.itemsPerPageOptions"
        :mobile="null"
        mobile-breakpoint="sm"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5" style="width: 130px">{{ viewText }}</h1>
            <v-spacer />
            <v-row class="ma-0" justify="end" align="center">
              <past-due-tasks-indicator
                v-for="type in TASK_TYPES"
                :key="type.value"
                :singular="type.title"
                :plural="type.plural"
                :type="type.value"
                :global="globalSearch"
                :refreshed-at="refreshedAt"
                :data-testid="`tasks-table-${type.value}-indicator`"
                class="ma-1"
                @click="changeViewTo(type.value)"
              />
              <table-stats v-if="user.isGroupTwoPlus" :stats="stats" />
              <v-tooltip location="top">
                <template #activator="{ props: templateProps }">
                  <app-button
                    data-testid="tasks-table-refresh"
                    variant="text"
                    class="table-btn"
                    density="comfortable"
                    :icon="mdiRefresh"
                    v-bind="templateProps"
                    @click="refresh"
                  />
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-row>
          </v-row>
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.insured`]="{ item }">
          <router-link :to="caseLink(item.additional)" class="truncate">
            {{ item.insured }}
          </router-link>
        </template>
        <template #[`item.description`]="{ item }">
          <v-tooltip location="top">
            <template #activator="{ props: templateProps }">
              <div
                v-bind="templateProps"
                class="truncate text-decoration-underline cursor-help"
              >
                {{ item.description }}
              </div>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template #[`item.last`]="{ item }">
          <v-tooltip location="top">
            <template #activator="{ props: templateProps }">
              <div
                v-bind="templateProps"
                class="truncate text-decoration-underline cursor-help"
              >
                {{ item.last }}
              </div>
            </template>
            <span>{{ item.last }}</span>
          </v-tooltip>
        </template>
        <template #[`item.doctor`]="{ item }">
          <div class="truncate">{{ item.doctor }}</div>
        </template>
        <template #[`item.requirements`]="{ item }">
          <v-tooltip location="top">
            <template #activator="{ props: templateProps }">
              <div v-bind="templateProps" class="truncate">
                {{ item.requirements }}
              </div>
            </template>
            <span>{{ item.requirements }}</span>
          </v-tooltip>
        </template>
        <template #[`item.carrier`]="{ item }">
          <div class="truncate">{{ item.carrier }}</div>
        </template>
        <template #[`item.case`]="{ item }">
          <div class="truncate">{{ item.case }}</div>
        </template>
        <template #[`item.follow`]="{ item }">
          <div>
            <until-time-formatter :model-value="item.follow" format="hours" />
          </div>
        </template>
        <template #[`item.next`]="{ item }">
          <div>
            <until-time-formatter :model-value="item.next" format="hours" />
          </div>
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PastDueTasksIndicator from "@/components/tasks/PastDueTasksIndicator.vue";
import UntilTimeFormatter from "@/components/shared/formatters/UntilTimeFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableStats from "@/components/shared/data-table/TableStats.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { getRequirements } from "@/api/requirements.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { computed, ref } from "vue";
import { mdiPound, mdiRefresh } from "@mdi/js";

useHead({ title: "Tasks" });
const user = useUserStore();
const snackbar = useSnackbarStore();

const TASK_TYPES = [
  { title: "Task", value: "task", plural: "Tasks" },
  { title: "Exam", value: "exam", plural: "Exams" },
  { title: "APS", value: "aps", plural: "APS" }
];

const table = getTable();

const refreshedAt = ref(null);

const stats = computed(() => {
  const score = {
    text: "Score",
    icon: mdiPound,
    type: "loading",
    dataTestid: "stat-score"
  };

  if ("score" in table.stats.value) {
    score.type = "percent";
    score.value = table.stats.value.score;
  }
  return [score];
});

const viewText = computed(
  () =>
    TASK_TYPES.find(({ value }) => value === table.filter.value.type)?.plural
);

const globalSearch = computed(() => {
  if (!user.isGroupTwoPlus) return false;
  return table.filter.value.view === "all";
});

function updateFilter(filter) {
  const previousSort = JSON.stringify(table.options.value.sortBy);
  if (filter.type !== table.filter.value.type) {
    table.options.value.sortBy = [{ key: "follow", order: "asc" }];
  }

  const currentSort = JSON.stringify(table.options.value.sortBy);

  table.filter.value = filter;

  if (previousSort === currentSort && table.options.value.page === 1) getData();
  table.resetPage();
}

function changeViewTo(type) {
  updateFilter({ ...table.filter.value, type });
}

async function getData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      color: "error"
    });
  }
}

function getTable() {
  const headers = [
    new TableHeader({
      text: "Type",
      value: "type",
      map: "type",
      filterOrder: 2,
      sortFilterMap: "type",
      selectableOptions: TASK_TYPES,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_MANDATORY,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Case Manager",
      value: "case",
      filterOrder: 3,
      map: "case_manager_name",
      sortFilterMap: [{ key: "case_manager_id", value: "id" }],
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CASE_MANAGER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Policy #",
      value: "policy",
      map: "policy_number",
      sortFilterMap: "policy_number",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured_name",
      sortFilterMap: "contract_party_name",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier_name",
      sortKey: "carrier",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Description",
      value: "description",
      map: "description",
      sortFilterMap: "description",
      showIf: ({ type }) => ["task"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Last Note",
      value: "last",
      map: "last_note",
      sortFilterMap: "note",
      showIf: ({ type }) => ["exam", "aps", "task"].includes(type)
    }),
    new TableHeader({
      text: "Follow Up",
      value: "follow",
      map: "follow_up_date",
      sortFilterMap: "follow_up",
      showIf: ({ type }) => ["task", "exam", "aps"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Order #",
      value: "order",
      map: "order_number",
      sortFilterMap: "order_number",
      showIf: ({ type }) => ["exam"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Requirements",
      value: "requirements",
      map: "requirements",
      sortFilterMap: "requirements",
      showIf: ({ type }) => ["exam"].includes(type),
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Assignable",
      value: "assignable",
      map: "",
      sortFilterMap: [
        { value: "id", key: "assignable_id" },
        { value: "type", key: "assignable_type" }
      ],
      filterOrder: 4,
      showIf: ({ type }) => ["task", "exam", "aps"].includes(type),
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Doctor",
      value: "doctor",
      map: "doctor",
      sortFilterMap: "doctor",
      showIf: ({ type }) => ["aps"].includes(type)
    }),
    new TableHeader({
      text: "Vendor",
      value: "vendor",
      map: "vendor",
      sortFilterMap: [{ value: "id", key: "vendor_id" }],
      showIf: ({ type }) => ["exam", "aps"].includes(type),
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_VENDOR_SEARCH_TYPE,
      ...TableHeader.IS_FILTERABLE
    })
  ];

  const filter = { type: "task" };
  if (!user.loginable.is_case_manager) {
    filter.assignable = {
      id: user.loginable.id,
      type: user.loginable.type,
      name: user.loginable.name
    };
  }

  if (user.isGroupTwoPlus) {
    headers.unshift(
      new TableHeader({
        text: "View",
        value: "view",
        map: "view",
        selectableOptions: [
          { title: "All Tasks", value: "all" },
          { title: "My Tasks", value: "my" }
        ],
        sortFilterMap: "view",
        filterOrder: 1,
        ...TableHeader.IS_SELECT_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_MANDATORY
      })
    );
    filter.view = "my";
  }

  return useTable({
    getData: getRequirements,
    headers,
    shouldIncludeCancelToken: true,
    filter,
    options: TableOptions({ sortBy: [{ key: "follow", order: "asc" }] })
  });
}

function refresh() {
  refreshedAt.value = new Date().getTime();
  getData();
}

function caseLink(item) {
  let type = TASK_TYPES.find(({ value }) => value === table.filter.value.type);
  let taskType = type.title;
  if (type.value === "aps") taskType = "PhysicianStatement";

  let query = { task: `${taskType}-${item.id}` };
  return {
    name: "CaseView",
    params: { id: item.case_id },
    query
  };
}
</script>
