export function Login(model = {}) {
  return {
    id: model?.id,
    createdAt: model?.createdAt,
    lastLogin: model?.lastLogin,
    usersLastLogin: model?.usersLastLogin,
    userId: model?.userId,
    userEmail: model?.userEmail,
    hasApiKey: Boolean(model?.hasApiKey)
  };
}

export function setLoginFromRaw(raw = {}) {
  const login = Login();
  login.id = raw.id;
  login.createdAt = raw.created_at;
  login.lastLogin = raw.last_login;
  login.usersLastLogin = raw.user.current_sign_in_at;
  login.userId = raw.user.id;
  login.userEmail = raw.user.email;
  login.hasApiKey = Boolean(raw.has_api_key);
  return login;
}
