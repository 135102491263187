<template>
  <autocomplete-field
    ref="formSetSearchText"
    v-model="formSet"
    hide-no-data
    return-object
    autocomplete="false"
    :prepend-inner-icon="mdiFolderMultiple"
    placeholder="Search Form Sets"
    label="Form Set"
    :clearable="clearable"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="formSets"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    :item-title="val => `${val.id} · ${val.name}`"
    v-on="$attrs"
  >
    <template v-if="hasAppendOuterSlot" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script>
import { getAllFormSets } from "@/api/form-sets.service";
import { parseErrorMessage } from "@/util/helpers";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiFolderMultiple } from "@mdi/js";
export default {
  props: {
    clearable: Boolean,
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    modelValue: { type: [Object, String], default: null },
    disabled: Boolean,
    hideDetails: Boolean
  },
  emits: ["update:model-value"],
  data() {
    let formSets = [];
    let formSet = this.modelValue || null;
    return {
      loading: false,
      formSets,
      formSet,
      mdiFolderMultiple
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append"];
    }
  },
  watch: {
    value(value) {
      if (this.formSet?.id === value?.id) return;
      this.formSet = value;
    },
    formSet(value) {
      this.$emit("update:model-value", value);
    }
  },
  created() {
    this.getFormSets();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    focus() {
      if (!this.$refs.formSetSearchText?.$el) return;
      const input = this.$refs.formSetSearchText?.$el.querySelector("input");
      if (!input?.focus) return;
      input.focus();
    },
    clear() {
      this.formSet = null;
    },
    async getFormSets() {
      this.loading = true;
      try {
        const formSets = await getAllFormSets();
        this.formSets = formSets.toSorted((a, b) => a.id - b.id);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
