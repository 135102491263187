<template>
  <v-card v-cloak @drop.prevent.stop="addDropFile" @dragover.prevent.stop>
    <v-card-title class="mb-1">{{ title }} </v-card-title>
    <v-card-subtitle v-if="subtitle" class="pb-1">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text>
      <v-col cols="12" class="pa-0">
        <v-list-subheader style="font-size: 1rem" class="pl-0 pb-1">
          Who is this website for?
        </v-list-subheader>

        <agent-search
          v-model="widget.agent"
          label="Agent"
          include-assignable
          better-return
          data-testid="agent-search"
          :clearable="false"
          v-bind="agentValidation"
        />
        <v-list-subheader style="font-size: 1rem" class="pl-0 pb-1">
          What would you like to name your website?
        </v-list-subheader>
        <text-field
          v-if="mdAndUp"
          key="uri-input"
          v-model="widget.uri"
          data-lpignore="true"
          placeholder="your-desired-name"
          class="prepend-slot"
          data-testid="uri-input"
          :loading="loadingSettings"
          v-bind="uriValidation"
        >
          <template v-if="customDomains.length" #prepend-inner>
            <select-field
              v-if="customDomains.length > 1"
              v-model="widget.domain"
              item-title="domain"
              return-object
              hide-details
              solo
              flat
              style="max-width: 20rem"
              class="pl-2"
              :items="customDomains"
            />
            <span v-else class="prepend-text">
              {{ domainPrefix }}
            </span>
          </template>
        </text-field>
        <select-field
          v-else-if="customDomains.length > 1"
          key="sm-domain-select"
          v-model="widget.domain"
          item-title="domain"
          return-object
          hide-details
          solo
          flat
          style="max-width: 20rem"
          class="pl-2"
          data-testid="domain-select"
          :items="customDomains"
        />
        <text-field
          v-else
          key="sm-input"
          v-model="widget.uri"
          placeholder="your-desired-name"
          data-lpignore="true"
          data-testid="uri-input"
          :label="domainPrefix"
          :loading="loadingSettings"
          v-bind="uriValidation"
        />
        <v-list-subheader style="font-size: 1rem" class="pl-0 pb-1">
          How would you like to greet your customers?
        </v-list-subheader>
        <textarea-field
          v-model="widget.greeting"
          label="Greeting"
          rows="2"
          auto-grow
          data-testid="greeting"
          :prepend-inner-icon="mdiHumanGreeting"
          v-bind="greetingValidation"
        />
        <v-list-subheader style="font-size: 1rem" class="pl-0 pb-1">
          Would you like to use a custom image?
        </v-list-subheader>
        <v-row class="ma-0" style="gap: 12px">
          <div v-for="avatarType in avatarTypes" :key="avatarType.value">
            <v-card
              height="165px"
              width="165px"
              hover
              :data-testid="avatarType.dataTestid"
              @click="avatarType.onClick"
            >
              <v-row
                class="ma-0 h-100 position-relative"
                align="center"
                justify="center"
              >
                <v-fade-transition mode="out-in">
                  <div
                    v-if="widget.avatarType === avatarType.value"
                    class="active-avatar-container"
                  >
                    <div class="active-avatar">
                      <v-icon
                        size="x-large"
                        class="default-icon"
                        color="accent"
                        :icon="mdiCheckboxMarkedCircleOutline"
                      />
                    </div>
                  </div>
                </v-fade-transition>
                <v-img
                  v-if="avatarType.src"
                  :src="avatarType.src"
                  height="100%"
                  width="100%"
                  contain
                />
                <v-icon v-else size="42" class="default-icon" :icon="avatarType.icon" />
              </v-row>
            </v-card>
            <v-card-subtitle class="pa-1 pb-0">
              {{ avatarType.text }}
              <template v-if="avatarType.value === AVATAR_TYPE.CUSTOM">
                <a v-if="customAvatarSrc" @click="openUploadFile"> (Change) </a>
                <v-file-input
                  id="avatar"
                  v-model="widget.customAvatar"
                  style="display: none"
                  :accept="ACCEPTABLE_IMAGE_EXTENSIONS"
                />
              </template>
            </v-card-subtitle>
          </div>
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        class="text-none"
        :disabled="creating"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        color="primary"
        class="text-none"
        data-testid="create"
        :loading="creating"
        @click="createQuoteAndApplySite"
      >
        Create
      </app-button>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import {
  computedValidation,
  parseErrorMessage,
  someTextValidator
} from "@/util/helpers";
import { createApprovedDomain } from "@/api/approved-domain.service";

import AgentSearch from "@/components/shared/AgentSearch.vue";
import { getAgentElectronicApplicationSettings } from "@/api/agents.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiHumanGreeting,
  mdiImagePlus,
  mdiCheckboxMarkedCircleOutline,
  mdiImageOff
} from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

const ACCEPTABLE_IMAGE_EXTENSIONS = ".png,.svg,.jpeg,.jpg,.webp";
const AVATAR_TYPE = {
  DEFAULT: "default",
  CUSTOM: "custom",
  NONE: "none"
};

defineProps({
  title: {
    type: String,
    default: "New Quote & Apply Website"
  },
  subtitle: {
    type: String,
    default:
      "Add Quote & Apply to your website for customers to purchase life insurance 24/7."
  }
});

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();
const { mdAndUp } = useDisplay();

const uriServerError = ref("");
const customAvatarSrc = ref(null);
const creating = ref(false);
const customDomains = ref([]);
const loadingSettings = ref(false);
const widget = ref({
  uri: "",
  greeting: "",
  agent: null,
  domain: "",
  customAvatar: null,
  avatarType: AVATAR_TYPE.DEFAULT
});

const v$ = useVuelidate(
  {
    widget: {
      uri: {
        required: v => someTextValidator(true, v, 2),
        validUri: v => new RegExp(/^[A-Za-z0-9_-]*$/).test(v),
        serverError: () => !uriServerError.value
      },
      agent: {
        required: v => Boolean(v?.id),
        customDomains: () => customDomains.value.length > 0
      },
      greeting: { required: v => someTextValidator(true, v, 2) }
    }
  },
  { widget },
  { $scope: null, $autoDirty: true }
);

const avatarTypes = computed(() => [
  {
    value: AVATAR_TYPE.DEFAULT,
    text: "Current Avatar",
    src: widget.value.agent?.assignable?.avatar_url,
    icon: null,
    dataTestid: "default-avatar",
    onClick: () => (widget.value.avatarType = AVATAR_TYPE.DEFAULT)
  },
  {
    value: "custom",
    text: "Custom Avatar",
    src: customAvatarSrc.value,
    icon: mdiImagePlus,
    dataTestid: "custom-avatar",
    onClick: () => handleCustomClick()
  },
  {
    value: "none",
    text: "No Avatar",
    src: null,
    icon: mdiImageOff,
    dataTestid: "no-avatar",
    onClick: () => (widget.value.avatarType = AVATAR_TYPE.NONE)
  }
]);

const potentialURL = computed(
  () => `https://${widget.value.domain.domain}/${widget.value.uri}`
);

const agentValidation = computedValidation(v$.value.widget.agent, {
  required: "Required",
  customDomains: "Agent has no Domains. Please Change this Agent"
});

const uriValidation = computedValidation(v$.value.widget.uri, {
  required: "Required",
  validUri: "Can only contain letters, numbers, underscores, and dashes",
  serverError: uriServerError
});

const greetingValidation = computedValidation(v$.value.widget.greeting, {
  required: "Required"
});

const domainPrefix = computed(() => {
  if (!widget.value.domain?.domain) return null;
  return `https://${widget.value.domain.domain}/`;
});

function addDropFile(e) {
  const options = ACCEPTABLE_IMAGE_EXTENSIONS.split(",").map(v => v.trim());
  const validFiles = Array.from(e.dataTransfer.files).filter(file => {
    return options.some(extension =>
      file.name.toLowerCase().endsWith(extension)
    );
  });
  if (!validFiles.length) return;

  widget.value.customAvatar = validFiles[0];
}
function handleCustomClick() {
  if (widget.value.customAvatar) widget.value.avatarType = AVATAR_TYPE.CUSTOM;
  else openUploadFile();
}
function openUploadFile() {
  document.getElementById("avatar").click();
}

function initializeGreeting() {
  if (widget.value.agent?.assignable?.type === "Agency") {
    widget.value.greeting = `Welcome to ${widget.value.agent.assignable.name}! We'll get your life insurance quotes in seconds. Ready to go?`;
  } else {
    widget.value.greeting = `Hi, I'm ${widget.value.agent?.name}! I'll get your life insurance quotes in seconds. Ready to go?`;
  }
}
async function getElectronicApplicationSettings() {
  if (!widget.value.agent?.id) return [];
  loadingSettings.value = true;
  try {
    const res = await getAgentElectronicApplicationSettings(
      widget.value.agent?.id
    );
    customDomains.value.splice(0, customDomains.value.length);
    customDomains.value.push(...res.customDomains);
    if (customDomains.value.length > 0) {
      widget.value.domain = customDomains.value[0];
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingSettings.value = false;
  }
}
async function createQuoteAndApplySite() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  creating.value = true;
  try {
    let avatar = undefined;
    if (widget.value.avatarType === AVATAR_TYPE.CUSTOM) {
      avatar = widget.value.customAvatar;
    } else if (widget.value.avatarType === AVATAR_TYPE.NONE) {
      avatar = null;
    }
    const body = {
      domain: potentialURL.value,
      custom_domain_id: widget.value.domain.id,
      greeting: widget.value.greeting,
      avatar
    };

    const { id } = await createApprovedDomain(body);

    router.push({
      name: "ApprovedDomains",
      params: { id }
    });
    dialog.closeDialog();
  } catch (e) {
    const message = parseErrorMessage(e);
    if (
      message?.toLowerCase()?.includes("domain path has already been taken")
    ) {
      uriServerError.value = `${potentialURL.value} has already been taken`;
      snackbar.showErrorSnackbar({
        message: uriServerError.value,
        timeout: 10000
      });
    } else {
      snackbar.showErrorSnackbar({
        message: parseErrorMessage(e),
        timeout: 10000
      });
    }
  } finally {
    creating.value = false;
  }
}

if (user.loginable.type === "Agency") {
  widget.value.agent = {
    id: user.loginable.signer.id,
    name: user.loginable.signer.name,
    email: user.loginable.signer.email,
    assignable: {
      type: "Agency",
      name: user.loginable.name,
      avatar_url: user.loginable.avatar_url
    }
  };
} else {
  widget.value.agent = {
    id: user.loginable.id,
    name: user.loginable.name,
    email: user.loginable.email,
    assignable: {
      type: "Agent",
      name: user.loginable.name,
      avatar_url: user.loginable.avatar_url
    }
  };
}

watch(
  () => widget.value.uri,
  () => (uriServerError.value = "")
);

watch(
  () => widget.value.customAvatar,
  () => {
    widget.value.avatarType = AVATAR_TYPE.CUSTOM;
    customAvatarSrc.value = URL.createObjectURL(widget.value.customAvatar);
  }
);

watch(
  () => widget.value.agent,
  () => {
    getElectronicApplicationSettings();
    initializeGreeting();
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss">
.active-avatar-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.active-avatar {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 50%;
  padding: 6px;
}
</style>
