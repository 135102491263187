export function RecentRequirement(model = {}) {
  return {
    caseId: model?.caseId || 0,
    description: model?.description || "",
    id: model?.id || 0,
    insuredName: model?.insuredName || "",
    type: model?.type || "",
    updatedAt: model?.updatedAt || ""
  };
}

export function setRecentRequirementFromRequest(raw = {}) {
  const model = RecentRequirement();
  model.caseId = raw?.case_id;
  model.description = raw?.description;
  model.id = raw?.id;
  model.insuredName = raw?.insured_name;
  model.type = raw?.type;
  model.updatedAt = raw?.updated_at;
  return model;
}
