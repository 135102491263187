<template>
  <v-row v-if="friendlyFilters.length" class="ma-0" align="center">
    <h4 class="mr-3" style="font-size: 1rem">Applied Filters:</h4>
    <v-chip
      v-for="(filter, index) in friendlyFilters"
      :key="`${index}-${filter.value}`"
      :data-testid="`${filter.name}-active`"
      :closable="filter.clearable && !loading"
      class="mr-1 mb-1"
      @click:close="$emit('clear-filter', filter.filterKey)"
      @click="$emit('clicked', filter.filterKey)"
    >
      <template
        v-if="typeof filter.value === 'boolean' || filter.hideDisplayValue"
      >
        {{ filter.name }}
      </template>
      <template v-else>
        {{ filter.name }}: <strong class="ml-2"> {{ filter.value }} </strong>
      </template>
    </v-chip>
  </v-row>
</template>

<script>
import { getDescendantProp, timestampFormatter } from "@/util/helpers";
import { numberFilters } from "@/constants/number-filter.constants";
import TableHeader from "@/classes/data-table/TableHeader";
const SELECT_TYPES = [TableHeader.SELECT_TYPE, TableHeader.AUTOCOMPLETE_TYPE];
export default {
  props: {
    activeFilter: { type: Object, default: () => {} },
    sortFilterMap: { type: Object, default: () => {} },
    headers: { type: Array, default: () => [] },
    loading: Boolean
  },
  emits: ["clicked", "clear-filter"],
  computed: {
    friendlyFilters() {
      const friendlyFilters = [];
      if (!Object.keys(this.activeFilter).length) return [];

      Object.keys(this.activeFilter).forEach(key => {
        if (!this.activeFilter[key]) return;
        const h = this.headers.find(h => h.value === key);

        if (!h) return;

        const isObject = typeof this.activeFilter[key] === "object";

        if (isObject && !Object.keys(this.activeFilter[key]).length === 0)
          return;

        let value = this.activeFilter[key];

        if (Array.isArray(this.activeFilter[key])) {
          value = this.getArrayFilter(h, value);
        } else if (h.filterType === TableHeader.DATE_TYPE) {
          value = this.getDateFilter(h, value);
        } else if (h.filterType === TableHeader.NUMBER_RANGE) {
          value = this.getNumberFilter(h, value);
        } else if (SELECT_TYPES.includes(h.filterType)) {
          value = this.getSelectFilter(h, value);
        } else if (isObject) {
          value = this.getObjectFilter(h, value);
        }

        if (!value) return;

        friendlyFilters.push({
          name: h.text || h?.title,
          filterKey: h.value,
          clearable: !h.isMandatory,
          hideDisplayValue: h.hideDisplayValue,
          value
        });
      });

      return friendlyFilters;
    }
  },
  methods: {
    getDateFilter(h, value) {
      let display = [];
      if (value.value.start) {
        display.push(timestampFormatter(value.value.start, "sole-day"));
      }

      if (value.value.finish && value.value.start !== value.value.finish) {
        display.push(timestampFormatter(value.value.finish, "sole-day"));
      }
      if (!display.length) return;

      return display.join(" - ");
    },
    getNumberFilter(h, value) {
      const type = value.type;
      const filterType = numberFilters.find(f => f.value === type);
      if (!filterType) return;

      const nullOrUndefined = v => [null, undefined].includes(v);
      if (nullOrUndefined(value.value.min) && nullOrUndefined(value.value.max))
        return;

      const display = filterType.displayMap(
        value.value.min,
        value.value.max,
        h.isCurrency
      );

      return display;
    },
    getArrayFilter(h, value) {
      let items = h.selectableOptions;
      if (h.selectableOptionsFn) {
        items = h.selectableOptionsFn(this.activeFilter);
      }
      return value.map(v => this.getFilterItem(items, v)).join(", ");
    },
    getSelectFilter(h, value) {
      if (h.displayMap) return getDescendantProp(value, h.displayMap);

      return this.getFilterItem(h.selectableOptions, value);
    },
    getObjectFilter(h, value) {
      return h.displayMap ? getDescendantProp(value, h.displayMap) : value.name;
    },
    getFilterItem(options, value) {
      const res = options.find(o => o === value || o.value === value);
      return res?.text || res?.title || res;
    }
  }
};
</script>
