<template>
  <v-card>
    <v-card-title>New Agency Email</v-card-title>
    <v-card-subtitle>
      Provide an email for agency communications.
    </v-card-subtitle>
    <v-card-text>
      <basic-email-input
        v-model="email"
        data-testid="new-agency-email"
        dense
        required
        autofocus
        :disabled="loading"
        label="Email"
        :success="emailValidation.success"
        :error-messages="emailValidation.errorMessages"
        @valid="emailIsValid = $event"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="new-agency-continue"
        :loading="loading"
        @click="continueSigningUp"
      >
        Continue
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import BasicEmailInput from "@/components/shared/BasicEmailInput.vue";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { initSocketForAgencySignup } from "@/util/socket-helper-service";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useDialogStore } from "@/stores/dialog";
import { useSettingsViewStore } from "@/stores/settings-view";
import { useSnackbarStore } from "@/stores/snackbar";
import useVuelidate from "@vuelidate/core";
import { computed, onBeforeUnmount, ref } from "vue";

const props = defineProps({ npn: { type: String, required: true } });

const loading = ref(false);
const email = ref("");
const emailIsValid = ref(null);

const snackbar = useSnackbarStore();
const agent = useAgentSettingsStore();

const v$ = useVuelidate(
  {
    email: {
      required: v => Boolean(v),
      isValid: (v, parent) => parent.emailIsValid.value === true
    },
    emailIsValid: {
      required: v => v === true
    }
  },
  {
    email,
    emailIsValid
  },
  { $autoDirty: true, $scope: false }
);

const emailValidation = computed(() => {
  const model = v$.value.email;
  return validationComputeV2(model, [
    { key: "required", message: "Required" },
    { key: "isValid", message: "Email must be valid" }
  ]);
});

onBeforeUnmount(() => {
  if (pusher.disconnect) pusher.disconnect();
});

let pusher;
const dialog = useDialogStore();
async function continueSigningUp() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  try {
    loading.value = true;

    const agencyId = await agent.createNewAgency({
      npn: props.npn,
      email: email.value
    });
    pusher = initSocketForAgencySignup(agencyId, {
      niprUpdateHandler,
      niprFailHandler
    });
  } catch (e) {
    loading.value = false;
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    if (pusher?.disconnect) pusher.disconnect();
  }
}

const settings = useSettingsViewStore();
async function niprUpdateHandler(val) {
  if (pusher?.disconnect) pusher.disconnect();

  try {
    // Fairway automatically updates the assignment, no update required.
    agent.assignAgentToAgency({
      id: val.agency.id,
      name: val.agency.name,
      type: "Agency"
    });
    settings.currentAgencyId = val.agency.id;
    await settings.loadCurrentAgency();
    dialog.closeDialog({ assigned: true });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
function niprFailHandler(val) {
  if (pusher?.disconnect) pusher.disconnect();
  snackbar.showErrorSnackbar({ message: val, timeout: -1 });
  loading.value = false;
}
</script>
