<template>
  <v-card color="section" flat tile>
    <chat-view
      type="advisor"
      :title="shouldRenderCarrierChat ? '' : 'Appointment Chat'"
      :additional-buttons="additionalButtons"
      :checkboxes="checkboxes"
      :create-func="sendChatMessage"
      :pusher-id="`private-${id}-Appointment`"
      :messages="activeNotes"
      :room="activeRoom"
      @new-note="newNote"
      @remove-message="removeFromMessages"
    >
      <template v-if="shouldRenderCarrierChat" #title>
        <v-tabs
          style="width: unset"
          background-color="section"
          color="primary"
          @update:model-value="changeTab"
        >
          <v-tab class="text-none" data-testid="advisor-chat">
            <v-icon class="mr-1" :icon="mdiAccountTie" /> Advisor Chat
          </v-tab>
          <v-tab class="text-none" data-testid="carrier-chat">
            <v-icon class="mr-1" :icon="mdiDomain" /> Carrier Chat
          </v-tab>
        </v-tabs>
      </template>
    </chat-view>
  </v-card>
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";
import ChatTemplateDialog from "@/dialogs/ChatTemplateDialog.vue";

import { parseErrorMessage } from "@/util/helpers";
import { createNote } from "@/api/notes.service";
import { computed, ref, markRaw } from "vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useAppointmentView } from "@/stores/appointment-view";
import {
  Note,
  setNoteFromRequest,
  NoteToCreateRequest
} from "@/factories/Note";
import { mdiAccountTie, mdiDomain } from "@mdi/js";
const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const activeTab = ref(0);

const { isGroupTwoPlus, loginable } = storeToRefs(useUserStore());
const { assignment, room, ownable, createdAt, notes, id } = storeToRefs(
  useAppointmentView()
);

const additionalButtons = [];
if (isGroupTwoPlus.value) {
  additionalButtons.push({
    text: "Use Template",
    dataTestid: "use-template",
    method: selectTemplate
  });
}

const copyToText = computed(() => {
  const isSigner = Boolean(assignment.value?.signer);
  return `Copy to ${isSigner ? "Assignment's" : "Signer's"} Appointment`;
});
const checkboxes = computed(() => {
  const checkboxes = [
    {
      key: "sendEmail",
      label: "Send Email",
      default: true,
      dataTestid: "email"
    }
  ];

  const appointmentHasSigner =
    assignment.value?.signer || ownable.value.signerId;

  if (isGroupTwoPlus.value && appointmentHasSigner) {
    checkboxes.push({
      key: "copyToRelated",
      label: copyToText.value,
      default: false,
      dataTestid: "copy-to-related"
    });
  }
  return checkboxes;
});

const shouldRenderCarrierChat = computed(() => {
  if (!isGroupTwoPlus.value) return false;
  return room.value.some(value => value.type.toLowerCase() === "personnel");
});
const advisorNotes = computed(() => {
  const defaultMessage = {
    note: `Hi ${loginable.value.name}. We're here to manage your appointment. Feel free to chat and send documents to us here.`,
    ownable: { name: "Contracting Team" },
    createdAt: createdAt.value
  };
  const availableNotes = notes.value.filter(value => value.advisorAccess);
  return availableNotes.length ? availableNotes : [defaultMessage];
});
const carrierNotes = computed(() => {
  const defaultMessage = {
    note: `Hi ${loginable.value.name}. We're here to manage your appointment. Feel free to chat and send documents to us here.`,
    ownable: { name: "Contracting Team" },
    createdAt: createdAt.value
  };

  const availableNotes = notes.value.filter(value => value.carrierAccess);
  return availableNotes.length ? availableNotes : [defaultMessage];
});

const activeNotes = computed(() => {
  let notes;
  if (activeTab.value === 0) notes = [...advisorNotes.value];
  else if (activeTab.value === 1) notes = [...carrierNotes.value];

  return notes;
});

const advisorRoom = computed(() => room.value.filter(v => v.advisorAccess));
const carrierRoom = computed(() => room.value.filter(v => v.carrierAccess));

const activeRoom = computed(() => {
  if (activeTab.value === 0) return advisorRoom.value;
  return carrierRoom.value;
});

function removeFromMessages(message) {
  const index = notes.value.findIndex(v => v.id === message.id);
  if (index > -1) notes.value.splice(index, 1);
}

function changeTab(value) {
  activeTab.value = value;
}

function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}

async function sendChatMessage({
  sendEmail,
  files: documents,
  phoneCall,
  message: note,
  isTemplate,
  copyToRelated
}) {
  let email = false,
    carrierEmail = false,
    advisorAccess = false,
    carrierAccess = false;

  if (activeTab.value === 0) {
    email = Boolean(sendEmail);
    advisorAccess = true;
  } else if (activeTab.value === 1) {
    carrierEmail = Boolean(sendEmail);
    carrierAccess = true;
  }

  const newNote = new Note({
    documents,
    email,
    carrierEmail,
    phoneCall,
    note,
    advisorAccess,
    carrierAccess,
    isTemplate: isGroupTwoPlus.value ? isTemplate : false,
    copyToRelated: isGroupTwoPlus.value ? copyToRelated : false,
    notableId: id.value,
    notableType: "Appointment"
  });
  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}

function selectTemplate() {
  return dialog.showDialog({
    component: markRaw(ChatTemplateDialog),
    identifier: id.value,
    scrollable: true,
    type: "Appointment",
    createFunc: sendChatMessage,
    checkboxes: checkboxes.value
  });
}
</script>
