<template>
  <v-card v-if="showActions" tile flat>
    <v-row class="ma-0 pa-3" justify="end">
      <app-button
        v-if="showCaseLink"
        variant="outlined"
        data-testid="action-view-case"
        class="text-none rounded-pill"
        color="orange"
        :to="getRouterLink('Case', quoteCase)"
      >
        <v-icon class="mr-1" :icon="mdiBriefcase" /> View Case
      </app-button>
      <app-button
        v-if="showEappLink"
        variant="outlined"
        data-testid="action-view-eapp"
        class="text-none rounded-pill"
        color="accent"
        :to="{ name: 'ElectronicApplicationView', params: { id: quoteEapp } }"
      >
        <v-icon class="mr-1" :icon="mdiRocketLaunch" /> View eApp
      </app-button>
    </v-row>
  </v-card>
</template>

<script setup>
import { getRouterLink } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";
import { mdiBriefcase, mdiRocketLaunch } from "@mdi/js";
import { computed, watch } from "vue";

const emit = defineEmits(["update:show"]);

const { eapp: quoteEapp, case: quoteCase } = storeToRefs(useQuoteViewStore());

const showEappLink = computed(() => Boolean(quoteEapp.value));
const showCaseLink = computed(() => Boolean(quoteCase.value));
const showActions = computed(() => showCaseLink.value || showEappLink.value);

watch(showActions, () => emit("update:show", showActions.value), {
  immediate: true
});
</script>
