<template>
  <div
    :class="{ 'display-on-hover': !sortConfig }"
    class="flex-row flex-nowrap"
    @click.stop="handleClick"
  >
    {{ column.text }}
    <v-icon :icon="icon" />
  </div>
</template>

<script setup>
import { mdiArrowDown, mdiArrowUp } from "@mdi/js";
import { computed, toRef } from "vue";
const props = defineProps({
  sortBy: { type: Array, required: false, default: () => [] },
  column: { type: Object, required: true }
});
const emit = defineEmits(["update:sort-by"]);

const sortBy = toRef(props, "sortBy");
const column = toRef(props, "column");

const sortConfig = computed(() =>
  sortBy.value.find(v => v.key === column.value.value)
);

const icon = computed(() => {
  if (!sortConfig.value) return mdiArrowDown;

  return sortConfig.value.order === "desc" ? mdiArrowDown : mdiArrowUp;
});

function handleClick() {
  if (sortConfig.value) {
    emit("update:sort-by", [
      {
        key: column.value.value,
        order: sortConfig.value.order === "desc" ? "asc" : "desc"
      }
    ]);
  } else {
    emit("update:sort-by", [{ key: column.value.value, order: "desc" }]);
  }
}
</script>

<style lang="scss">
.display-on-hover {
  i {
    opacity: 0;
  }

  &:hover {
    i {
      opacity: 1;
    }
  }
}
</style>
