<template>
  <v-card class="ma-1">
    <v-card-title class="pl-3 pt-3 pb-0">
      <v-tabs v-model="activeTab" style="width: unset" color="primary">
        <v-tab
          v-for="tab in tabs"
          :key="tab.page + 'title'"
          class="text-none text-h5"
          :data-testid="`tab-${tab.text}`"
        >
          <v-icon class="mr-1" :icon="tab.icon" /> {{ tab.text }}
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-divider />
    <v-tabs-window v-model="activeTab" touchless>
      <v-tabs-window-item v-for="tab in tabs" :key="tab.page + 'body'">
        <component :is="tab.component" />
      </v-tabs-window-item>
    </v-tabs-window>
  </v-card>
</template>

<script setup>
import PaymentsTable from "@/components/payments/PaymentsTable.vue";
import PayPeriodsTable from "@/components/pay-period/PayPeriodsTable.vue";

import { mdiCheckbook, mdiCalendarWeek } from "@mdi/js";
import { markRaw, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useHead } from "@unhead/vue";

const props = defineProps({
  page: {
    type: String,
    default: "payments"
  }
});

const router = useRouter();

const head = useHead({
  title: "Commissions"
});

const activeTab = ref(0);

const tabs = ref([
  {
    page: "payments",
    text: "Payments",
    icon: mdiCheckbook,
    defaultTab: true,
    component: markRaw(PaymentsTable)
  },
  {
    page: "pay-periods",
    text: "BackNine Statements",
    icon: mdiCalendarWeek,
    component: markRaw(PayPeriodsTable)
  }
]);

function syncMetaTitle() {
  head.patch({
    title: tabs.value[activeTab.value].text
  });
}

function initializeActiveTab() {
  const tempPage = tabs.value.findIndex(v => v.page === props.page);
  if (tempPage > -1) activeTab.value = tempPage;
  else activeTab.value = tabs.value.findIndex(tab => tab.defaultTab);
}

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

watch(
  activeTab,
  (newV, oldV) => {
    if (newV === oldV) return;
    syncQueryToTab();
    syncMetaTitle();
  },
  { immediate: true }
);

syncMetaTitle();
initializeActiveTab();
</script>
