<template>
  <autocomplete-field
    v-model="form"
    v-bind="$attrs"
    v-model:search="formSearchText"
    return-object
    item-title="name"
    autocomplete="false"
    placeholder="Search Forms"
    no-filter
    hide-no-data
    :data-testid="dataTestid"
    :prepend-inner-icon="mdiFileDocument"
    :items="forms"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    v-on="$attrs"
  >
    <template v-if="Boolean(slots['append'])" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { getFormsByName } from "@/api/forms.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { mdiFileDocument } from "@mdi/js";
import { ref, useSlots, watch,   toRef } from "vue";
const props = defineProps({
  success: Boolean,
  modelValue: { type: [String, Object], required: false, default: null },
  currentFormId: { type: [Number, String], required: false, default: null },
  dataTestid: {
    type: String,
    default: "form-search",
    required: false
  }
});

const emit = defineEmits(["update:model-value"]);

const snackbar = useSnackbarStore();
const slots = useSlots();

const forms = ref([]);
const form = ref(null);
const formSearchText = ref("");
const loading = ref(false);

const propValue = toRef(props, "modelValue");
if (
  propValue.value &&
  Object.keys(propValue.value).length &&
  propValue.value.id
) {
  forms.value = [propValue.value];
  // eslint-disable-next-line vue/no-setup-props-destructure
  form.value = propValue.value;
}

watch(propValue, value => {
  if (value === form.value || value?.id === form.value?.id) return;
  forms.value.splice(0, forms.value.length);
  if (!value || !Object.keys(value).length) {
    form.value = null;
    return;
  }
  form.value = value;
  forms.value.push(value);
});

watch(form, () =>
  emit("update:model-value", { id: form.value?.id, name: form.value?.name })
);

let formTimer;
watch(formSearchText, value => {
  if (!value || !Object.keys(value).length) {
    return;
  }

  if (forms.value.some(val => val?.name === value)) {
    return;
  }

  if (formTimer) clearTimeout(formTimer);

  formTimer = setTimeout(searchForms, 200);
});

async function searchForms() {
  loading.value = true;
  try {
    const params = new URLSearchParams();
    params.append("name", formSearchText.value);

    const fetchedForms = await getFormsByName(params);
    forms.value.splice(0, forms.value.length);
    forms.value.push(
      ...fetchedForms.toSorted((a, b) => a.name.localeCompare(b.name))
    );
    if (props.currentFormId) {
      const formIndex = forms.value.findIndex(
        val => val.id === props.currentFormId
      );
      if (formIndex > 0) forms.value.splice(formIndex, 1);
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}
</script>
