<template>
  <autocomplete-field
    v-bind="$attrs"
    v-model="model"
    :data-testid="props.dataTestid"
    :loading="cache[props.cacheItemsKey].loading"
    :items="cache[props.cacheItemsKey].items"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script setup>
import { useSearchItemsCache } from "@/stores/search-items-cache";
import { basicArrayEquivalence, parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import {   ref, watch, toRef } from "vue";
const emit = defineEmits(["update:model-value"]);
const props = defineProps({
  cacheFetchKey: { type: String, required: true },
  cacheItemsKey: { type: String, required: true },
  dataTestid: { type: String, required: false, default: null },
  modelValue: {
    type: [Array, String, Number],
    default: () => []
  }
});

const modelValue = toRef(props, "modelValue");

const model = ref(props.modelValue);

const cache = useSearchItemsCache();
const snackbar = useSnackbarStore();
watch(
  () => model.value,
  () => {
    if (!isEqual(model.value, props.modelValue))
      emit("update:model-value", model.value);
  }
);

watch(modelValue, () => {
  if (!isEqual(model.value, props.modelValue))
    emit("update:model-value", model.value);
  model.value = props.modelValue;
});

function isEqual(newValue, oldValue) {
  if (Array.isArray(newValue) || Array.isArray(oldValue)) {
    if (basicArrayEquivalence(newValue || [], oldValue || [])) return true;
  } else if (newValue === oldValue) return true;
  return false;
}

async function getItems() {
  try {
    await cache[props.cacheFetchKey]();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

getItems();
</script>
