<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="advisor-profile-quotes-table"
    :mobile="null"
    mobile-breakpoint="sm"
    hide-default-footer
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #[`item.insured`]="{ item }">
      <router-link
        :to="{
          name: 'QuoteView',
          params: { id: item.additional.id }
        }"
        class="a"
      >
        {{ item.insured }}
      </router-link>
    </template>
    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter :model-value="item.createdAt" />
    </template>
    <template #bottom>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <app-button
          data-testid="advisor-profile-quotes-table-view-all"
          color="primary"
          class="text-none"
          variant="text"
          :prepend-icon="mdiOpenInApp"
          :text="`View ${name}'s Quotes`"
          @click="viewAll"
        />
      </v-row>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";
import { getQuoteTable } from "@/api/quotes.service";
import { useTable } from "@/composables/table.composable";
import { mdiOpenInApp } from "@mdi/js";

import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "vue-router";

const router = useRouter();
const user = useUserStore();
const tableStore = useTableStore();
const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);

const table = useTable({
  options: TableOptions({ sortBy: [{ key: "createdAt", order: "desc" }] }),
  headers: [
    new TableHeader({
      text: "Name",
      value: "insured",
      map: "insured_name"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status"
    }),
    new TableHeader({
      text: "Line",
      value: "line",
      map: "line"
    }),
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "created_at",
      sortFilterMap: "quotes.created_at",
      ...TableHeader.IS_SORTABLE
    })
  ],
  getData: getQuoteTable
});

async function getData() {
  try {
    const additionalFilters = {
      shared_with_id: id.value,
      shared_with_type: type.value
    };

    if (user.isGroupOne) additionalFilters.view = "my";
    else if(user.isGroupTwoPlus) additionalFilters.view = "all";

    await table.getData(additionalFilters);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function viewAll() {
  tableStore.quotesTable.filter = {
    sharedWith: tableFilterData.value
  };
  router.push({ name: "Quotes" });
}
</script>
