<template>
  <v-row class="mx-0 my-0 bg-section">
    <v-col cols="12">
      <v-card flat>
        <v-card-title> Case Concierge and Case Manager </v-card-title>
        <v-card-text>
          <v-row class="ma-0" dense>
            <v-col cols="12" md="6">
              <text-field
                success
                readonly
                label="Case Concierge"
                :prepend-inner-icon="mdiRoomService"
                data-testid="case-concierge"
                :model-value="caseConcierge.name"
                :disabled="!user.isGroupThreePlus"
                @click="showCaseManagementDialog"
              />
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                success
                readonly
                label="Case Manager"
                :prepend-inner-icon="mdiAccountSupervisor"
                data-testid="case-manager"
                :model-value="caseManager.name"
                :disabled="!user.isGroupThreePlus"
                @click="showCaseManagementDialog"
              />
            </v-col>
            <v-col
              v-if="user.isGroupThreePlus && !sameManagerForParty"
              cols="12"
              data-testid="case-management-anomaly-alert"
            >
              <v-alert type="info" variant="outlined" style="font-size: 1rem">
                This Case is permitted to have a different Case Manager and Case
                Concierge from the rest of {{ insured.name }}'s Cases. This Case
                is excluded from the daily report of Cases with deviating Case
                Managers/Concierges.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title> Add or Remove Accesses </v-card-title>
        <v-card-text>
          <access-table
            v-model="room"
            v-model:new-access="newAccess"
            data-testid="case-access-table"
            :checkboxes="checkboxes"
            :deletable="accessIsDeletable"
            :update-func="updateCaseAccess"
            :delete-func="deleteCaseAccess"
            :add-func="addCaseAccess"
          >
            <template #new-access>
              <audience-search
                v-model="newAccess"
                hide-details
                :carrier-id="carrier.id"
                data-testid="access-audience-search"
              />
            </template>
          </access-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import ChangeCaseManagementDialog from "@/components/cases/case-dialogs/ChangeCaseManagementDialog.vue";
import AudienceSearch from "@/components/shared/AudienceSearch.vue";
import AccessTable from "@/components/shared/AccessTable.vue";

import { storeToRefs } from "pinia";

import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useCaseViewStore } from "@/stores/case-view";
import { markRaw, ref } from "vue";

import { mdiRoomService, mdiAccountSupervisor } from "@mdi/js";

const dialog = useDialogStore();
const user = useUserStore();
const caseView = useCaseViewStore();
const {
  room,
  caseConcierge,
  caseManager,
  sameManagerForParty,
  id: caseId,
  insured,
  carrier
} = storeToRefs(caseView);

const newAccess = ref(null);

const checkboxes = [
  {
    text: "Advisor Email",
    value: "email",
    if: a => a.advisorAccess
  },
  {
    text: "Carrier Email",
    value: "carrierEmail",
    if: a => a.carrierAccess
  }
];

async function showCaseManagementDialog() {
  if (!user.isGroupThreePlus) return;

  const eligibleManagement = [];
  room.value.forEach(({ canBeCaseManager, id, name, caseAccessId }) => {
    if (canBeCaseManager) eligibleManagement.push({ id, name, caseAccessId });
  });

  const result = await dialog.showDialog({
    component: markRaw(ChangeCaseManagementDialog),
    insuredName: insured.value.name,
    caseId: caseId.value,
    caseManagerValue: caseManager.value,
    caseConciergeValue: caseConcierge.value,
    sameManagerForPartyValue: sameManagerForParty.value,
    eligibleManagement
  });

  if (!result?.caseManager) return;

  caseManager.value = result.caseManager;
  caseConcierge.value = result.caseConcierge;
  sameManagerForParty.value = result.sameManagerForParty;
}

function accessIsDeletable(access) {
  return !access.caseManager && !access.caseConcierge && !access.agent;
}

function updateCaseAccess(access, attribute) {
  return caseView.updateAccess(access.caseAccessId, attribute);
}
function deleteCaseAccess(access) {
  return dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Are you sure that you want remove this case access?",
    subtitle: "Please confirm your intent",
    func: () => caseView.deleteAccess(access.caseAccessId)
  });
}
function addCaseAccess(advisor) {
  return caseView.createAccess(advisor);
}
</script>
