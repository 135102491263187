import { setCustomDomainFromRequest } from "@/factories/ApprovedDomain";

export function AdvisorElectronicApplicationSettings(model = {}) {
  return {
    id: model?.id || 0,
    customDomains: model?.customDomains || []
  };
}

export function setAdvisorElectronicApplicationSettingsFromRaw(raw = {}) {
  const model = AdvisorElectronicApplicationSettings();
  model.id = raw?.id;
  model.customDomains = [];

  raw?.custom_domains?.forEach(rawDomain => {
    model.customDomains.push(setCustomDomainFromRequest(rawDomain));
  });

  return model;
}
