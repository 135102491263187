<template>
  <v-card>
    <v-card-title> Create a Form </v-card-title>
    <v-card-text>
      <v-row class="ma-0" dense>
        <v-col cols="12">
          <form-categories-search
            v-model="form.category"
            data-testid="form-category"
            label="Category"
            :success="categoryValidation.success"
            :error-messages="categoryValidation.errorMessages"
          />
        </v-col>
        <v-col v-if="requiresCarrier" md="6" cols="12">
          <carrier-search
            v-model="form.carrier"
            data-testid="form-carrier"
            :disabled="Boolean(form.advisor?.id)"
            :success="carrierValidation.success"
            :error-messages="carrierValidation.errorMessages"
          />
        </v-col>
        <v-col v-if="requiresAdvisor" md="6" cols="12">
          <advisor-search
            v-model="form.advisor"
            data-testid="form-advisor"
            clearable
            :disabled="Boolean(form.carrier?.id)"
            :success="advisorValidation.success"
            :error-messages="advisorValidation.errorMessages"
            @update:model-value="updateFormAdvisor"
          />
        </v-col>

        <v-col cols="12">
          <file-drag-and-drop
            v-model="form.form"
            data-testid="form-document"
            :success="fileValidation.success"
            :error-messages="fileValidation.errorMessages"
            accept=".pdf"
            class="has-append-button-no-top"
          />
        </v-col>

        <v-col v-if="requiresSubmissionMethod" cols="12">
          <form-submission-methods-search
            v-model="form.submissionMethod"
            data-testid="form-submission-method"
            label="Submission Method"
            :success="submissionMethodValidation.success"
            :error-messages="submissionMethodValidation.errorMessages"
            dense
          />
        </v-col>
        <v-col v-if="requiresFormSets" cols="12">
          <v-row class="ma-0" align="center">
            <autocomplete-field
              :key="formSetAutocompleteKey"
              label="Form Sets"
              data-testid="form-sets"
              return-object
              :items="allFormSets"
              :item-title="val => `${val.id} · ${val.name}`"
              item-value="id"
              :error-messages="formSetValidation.errorMessages"
              @update:model-value="addFormSet"
            />
          </v-row>
          <template v-if="form.formSets.length > 0">
            <h3 class="my-3">Active Form Sets</h3>
            <v-list :key="form.formSets.length">
              <v-list-item
                v-for="formSet in form.formSets"
                :key="formSet.id"
                class="px-0"
                data-testid="form-set"
              >
                <v-list-item-title>
                  <app-button
                    :icon="mdiDelete"
                    :loading="formSet.deleting"
                    variant="text"
                    density="comfortable"
                    color="error"
                    class="mr-3"
                    @click="deleteFormSet(formSet)"
                  />
                  {{ formSet.id }} · {{ formSet.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        :disabled="loading"
        @click="dialog.closeDialog"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="form-create"
        :loading="loading"
        @click="create"
      >
        Create
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { createForm } from "@/api/forms.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { parseErrorMessage, computedValidation } from "@/util/helpers";
import { mdiDelete } from "@mdi/js";

import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import FormCategoriesSearch from "@/components/shared/FormCategoriesSearch.vue";
import FormSubmissionMethodsSearch from "@/components/shared/FormSubmissionMethodsSearch.vue";

import { VIRTUAL_FORM_CATEGORY_VALUE } from "@/api/forms.service";
import { getAllFormSets } from "@/api/form-sets.service";
import { ref, watch, nextTick, computed } from "vue";
import useVuelidate from "@vuelidate/core";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const form = ref({
  carrier: null,
  advisor: null,
  category: null,
  form: null,
  formSets: [],
  submissionMethod: null
});

const loading = ref(false);
const allFormSets = ref([]);

const requiresSubmissionMethod = computed(() =>
  Boolean(
    form.value.carrier?.id ||
      form.value.category === VIRTUAL_FORM_CATEGORY_VALUE
  )
);
const requiresAdvisor = computed(() => !form.value.carrier?.id);
const requiresCarrier = computed(() => !form.value.advisor?.id);
const requiresFormSets = computed(() => form.value.carrier?.id);

const v$ = useVuelidate(
  {
    form: {
      form: {
        required: Boolean,
        validSize: val => val?.size > 0
      },
      category: {
        required: Boolean
      },
      carrier: {
        required: v => !requiresCarrier.value || Boolean(v?.id)
      },
      advisor: {
        required: v => !requiresAdvisor.value || Boolean(v?.id)
      },
      formSets: {
        required: v => !requiresFormSets.value || v.length > 0
      },
      submissionMethod: {
        required: v => !requiresSubmissionMethod.value || Boolean(v)
      }
    }
  },
  {
    form
  },
  { $autoDirty: true, $scope: null }
);

const categoryValidation = computedValidation(v$.value.form.category, {
  required: "Required"
});

const carrierValidation = computedValidation(v$.value.form.carrier, {
  required: "Required"
});

const advisorValidation = computedValidation(v$.value.form.advisor, {
  required: "Required"
});

const submissionMethodValidation = computedValidation(
  v$.value.form.submissionMethod,
  {
    required: "Required"
  }
);

const formSetValidation = computedValidation(v$.value.form.formSets, {
  required: "Required"
});

const fileValidation = computedValidation(v$.value.form.form, {
  required: "Required",
  validSize: "Please reupload this file"
});

async function fetchFormSets() {
  if (!form.value.carrier?.id) return;
  form.value.formSets = [];
  try {
    const res = await getAllFormSets(form.value.carrier.id);
    allFormSets.value.splice(0, allFormSets.value.length);
    allFormSets.value.push(...res);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    allFormSets.value.splice(0, allFormSets.value.length);
  }
}

const formSetAutocompleteKey = ref(new Date().getTime());
async function addFormSet(formSet) {
  form.value.formSets.push(formSet);
  nextTick(() => {
    formSetAutocompleteKey.value = new Date().getTime();
  });
}

async function deleteFormSet(formSet) {
  const index = form.value.formSets.findIndex(val => val.id === formSet.id);
  if (index === -1) return;
  form.value.formSets.splice(index, 1);
}

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  loading.value = true;
  try {
    const body = {
      file: form.value.form,
      form: {
        category: form.value.category,
        status: "Incomplete"
      }
    };

    if (form.value.carrier?.id) {
      body.form.carrier_id = form.value.carrier.id;
      body.form.form_set_links_attributes = form.value.formSets?.map(val => ({
        form_set_id: val.id
      }));
    } else if (form.value.advisor?.type === "Agency") {
      body.form.agency_id = form.value.advisor.id;
    } else if (form.value.advisor?.type === "Agent") {
      body.form.agent_id = form.value.advisor.id;
    }

    if (form.value.submissionMethod) {
      body.form.submission_method = form.value.submissionMethod;
    }
    const id = await createForm(body);
    dialog.closeDialog({ id });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Unable to create form: ${parseErrorMessage(e)}`,
      timeout: 5000
    });
  } finally {
    loading.value = false;
  }
}

watch(requiresSubmissionMethod, v => {
  if (!v) form.value.submissionMethod = null;
  else form.value.submissionMethod = "Paper";
});
watch(() => form.value.carrier, fetchFormSets, { immediate: true });
</script>
