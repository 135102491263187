<template>
  <v-row>
    <v-col cols="12" md="6">
      <agent-search
        v-model="caseManager"
        label="Default Case Manager Override"
        clearable
        data-testid="default-case-manager-override"
        :disabled="!canEditSettings"
        :success="Boolean(caseManager?.id)"
        @update:model-value="saveCaseManager"
      >
        <template #append-inner>
          <active-save-indicator
            :controller="savingBuffer.caseManager.controller.value"
          />
        </template>
      </agent-search>
    </v-col>
    <v-col cols="12" md="6">
      <agent-search
        v-model="caseConcierge"
        label="Default Case Concierge Override"
        clearable
        data-testid="default-case-concierge-override"
        :disabled="!canEditSettings"
        :success="Boolean(caseConcierge?.id)"
        @update:model-value="saveCaseConcierge"
      >
        <template #append-inner>
          <active-save-indicator
            :controller="savingBuffer.caseConcierge.controller.value"
          />
        </template>
      </agent-search>
    </v-col>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";

import { useActiveSave } from "@/composables/active-save.composable";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

const user = useUserStore();
const agent = useAgentSettingsStore();
const { caseConcierge, caseManager } = storeToRefs(agent);

const canEditSettings = user.isGroupThreePlus;

const savingBuffer = {
  caseManager: useActiveSave(),
  caseConcierge: useActiveSave()
};

const saveCaseManager = () => {
  if (!canEditSettings) return;
  return savingBuffer.caseManager.debounceUpdate(agent.updateCaseManager);
};
const saveCaseConcierge = () => {
  if (!canEditSettings) return;
  return savingBuffer.caseConcierge.debounceUpdate(agent.updateCaseConcierge);
};
</script>
