<template>
  <v-card>
    <v-card-title>Impairment Counts by Type</v-card-title>
    <v-divider />
    <v-card-text>
      <v-data-table
        v-model:sort-by="table.options.value.sortBy"
        data-testid="impairment-report-table"
        must-sort
        :mobile="null"
        mobile-breakpoint="sm"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :loading="loading"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { getImpairmentsReport } from "@/api/reports.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { ref } from "vue";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { useTable } from "@/composables/table.composable";
import TableHeader from "@/classes/data-table/TableHeader";
import { numberFormat } from "@/util/helpers";

const props = defineProps({
  all: Boolean
});

const snackbar = useSnackbarStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Impairment",
      value: "text",
      map: "text",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Count",
      value: "value",
      map: "value",
      ...TableHeader.IS_SORTABLE
    })
  ],
  options: TableOptions({ sortBy: [{ key: "value", order: "desc" }] })
});

const loading = ref(false);

async function getData() {
  loading.value = true;
  const params = new URLSearchParams();
  if (props.all) params.append("view", "all");
  try {
    const items = await getImpairmentsReport(params);
    table.items.value = items.map(i => ({
      ...i,
      value: numberFormat(i.value)
    }));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

getData();
</script>
