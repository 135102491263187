<template>
  <autocomplete-field
    ref="carrierSearchText"
    v-model="carrier"
    v-model:search="carrierSearchText"
    autocomplete="false"
    :prepend-inner-icon="mdiDomain"
    placeholder="Search Carriers"
    label="Carrier"
    item-title="name"
    return-object
    no-filter
    hide-no-data
    :clearable="clearable"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="carriers"
    :loading="loading"
    :hide-details="hideDetails"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"
  >
    <template v-if="hasAppendOuterSlot" #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script>
import { searchCarriers } from "@/api/carrier.service";
import { mdiDomain } from "@mdi/js";
export default {
  props: {
    clearable: Boolean,
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    modelValue: { type: [Object, String], default: null },
    lines: {
      required: false,
      type: Array,
      default: () => []
    },
    disabled: Boolean,
    hideDetails: Boolean
  },
  emits: ["update:model-value", "blur"],
  data() {
    let carriers = [];
    let carrier = null;
    if (
      this.modelValue &&
      Object.keys(this.modelValue).length &&
      this.modelValue?.id
    ) {
      carriers = [this.modelValue];
      carrier = this.modelValue;
    }
    return {
      carrierSearchText: "",
      carrierTimer: null,
      loading: false,
      carriers,
      carrier,
      mdiDomain
    };
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots["append"];
    }
  },
  watch: {
    modelValue(value) {
      if (!value || !Object.keys(value).length) {
        this.carrier = null;
        this.carriers = [];
        return;
      }
      this.carrier = value;
      this.carriers = [value];
    },
    carrier(value) {
      this.$emit("update:model-value", value);
    },
    carrierSearchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (this.carriers.some(val => val?.name === value)) {
        return;
      }

      if (this.carrierTimer) {
        clearTimeout(this.carrierTimer);
      }

      this.carrierTimer = setTimeout(() => {
        this.loading = true;
        searchCarriers(value, this.lines)
          .then(response => {
            this.carriers = response.data.carriers.toSorted((a, b) =>
              a.name.localeCompare(b.name)
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    }
  },
  methods: {
    focus() {
      if (!this.$refs.carrierSearchText?.$el) return;
      const input = this.$refs.carrierSearchText?.$el.querySelector("input");
      if (!input?.focus) return;
      input.focus();
    },
    clear() {
      this.carrier = null;
      this.carriers = [];
    }
  }
};
</script>
