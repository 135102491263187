import { useUserStore } from "@/stores/user";
import { PERMISSION } from "@/constants/permissions.constants.js";

export const formMappingGuard = (to, from, next) => {
  const userStore = useUserStore();
  if (userStore.permissions[PERMISSION.FORM_MAPPING]) next();
  else next({ name: "Home" });
};

export const accountingGuard = (to, from, next) => {
  const userStore = useUserStore();
  if (userStore.permissions[PERMISSION.ACCOUNTING]) next();
  else next({ name: "Home" });
};

export const scorecardGuard = (to, from, next) => {
  const user = useUserStore();
  if (user.permissions[PERMISSION.SCORECARD] || user.isGroupTwoPlus) next();
  else next({ name: "Home" });
};
