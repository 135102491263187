<template>
  <v-list-item
    link
    :class="{ 'primary text-white': isActive }"
    @click="emit('click')"
  >
    <template #prepend>
      <v-icon :icon="icon" :class="{ 'text-white': isActive }" />
    </template>

    <v-list-item-title :class="{ 'text-white': isActive }">
      {{ questionId }}
    </v-list-item-title>

    <template v-if="!readonly" #append>
      <app-button
        :icon="mdiDelete"
        variant="text"
        density="comfortable"
        :color="isActive ? 'error lighten-1' : 'error'"
        @click="emit('delete')"
        @click.stop
      />
    </template>
  </v-list-item>
</template>

<script setup>
import {
  mdiCalendar,
  mdiDelete,
  mdiDraw,
  mdiExport,
  mdiEyeOff,
  mdiProgressQuestion,
  mdiRadioboxMarked,
  mdiSignatureFreehand,
  mdiText
} from "@mdi/js";
import {   computed } from "vue";
const props = defineProps({
  questionId: { type: String, required: true },
  currentFieldId: { type: String, required: true },
  pdfFieldType: { type: String, required: true },
  readonly: Boolean
});
const emit = defineEmits(["click", "delete"]);

const isActive = computed(() => props.questionId === props.currentFieldId);
const icon = computed(() => {
  const icons = {
    text: mdiText,
    text_output_verbatim_question: mdiExport,
    radio: mdiRadioboxMarked,
    signature: mdiDraw,
    date_signed: mdiCalendar,
    initials: mdiSignatureFreehand,
    not_on_pdf: mdiEyeOff
  };

  const icon = icons[props.pdfFieldType];
  return icon || mdiProgressQuestion;
});
</script>
