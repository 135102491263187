<template>
  <v-row dense class="ma-0">
    <v-col
      v-for="(item, index) in resources"
      :key="index"
      cols="12"
      md="3"
      sm="6"
    >
      <quote-method
        v-if="item.href && !item.action"
        :href="item.href"
        target="_blank"
        :item="item"
      />
      <quote-method
        v-else-if="item.to"
        :to="item.to"
        :item="item"
      />
      <quote-method
        v-else
        :item="item"
        :data-testid="item.dataTestid"
        @click="item.click"
      />
    </v-col>
    <v-col cols="12">
      <questionnaires-table class="pa-3" />
    </v-col>
  </v-row>
</template>


<script setup>
import { ref } from 'vue';
import QuoteMethod from "@/components/quotes/QuoteMethod.vue";
import QuestionnairesTable from "@/components/underwriting/QuestionnairesTable.vue";
import { mdiFilePdfBox } from "@mdi/js";

import ipipeline from "@/assets/img/ipipeline.png";
import firelight from "@/assets/img/firelight.svg"

import { firelightSamlResponse } from "@/api/idp.service";
import { useDialogStore } from '@/stores/dialog';
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { markRaw } from 'vue';

const dialog = useDialogStore();

const handleFireLightRedirect = () => {
  dialog.showDialog({
    title: "Continue to Firelight?",
    subtitle: "Bridge to Hexure's Firelight to complete annuity eApplications",
    func: handleFirelightClick,
    component: markRaw(ConfirmationDialog),
  });
};

const handleFirelightClick = async () => {
    const { samlUrl, samlResponse } = await firelightSamlResponse();

    const form = document.createElement('form');
    form.id = 'firelight-saml-form';
    form.method = 'POST';
    form.action = samlUrl;
    form.target = '_blank';

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'SamlResponse';
    input.value = samlResponse;

    form.appendChild(input);
    document.body.appendChild(form);
 
 if (!import.meta.env.VITE_COMPONENT_TESTING) form.submit()
};

const resources = [
  {
    text: "PDF Applications",
    type: "Annuity & Life",
    description:
      "Download PDFs for new business and policy holder services. Requires a BackNine iPipeline account.",
    href: "https://formspipe.ipipeline.com/?GAID=2479",
    img: ipipeline
  },
  {
    dataTestid: "firelight",
    text: "Firelight",
    type: "Annuity",
    description:
      "Bridge to Hexure's Firelight to complete annuity eApplications",
    img: firelight,
    click: handleFireLightRedirect,
  },
  {
    text: "Application Request",
    description: "Retrieve new application forms.",
    type: "All Lines",
    to: { name: "ApplicationRequest" },
    img: "https://portal-pictures.back9ins.com/BOSS-Logo.png"
  },
  {
    text: "Informal Inquiry",
    type: "Life",
    description:
      "Your client's medical records are sent to carriers for underwriting offers. Available for premiums $10,000+.",
    href: "https://cdn.back9ins.com/forms/Informal+Inquiry.pdf",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  {
    text: "Policy Review",
    type: "Annuity",
    description: "Used to obtain your client’s annuity policy information.",
    href: "https://cdn.back9ins.com/forms/Policy+Review+Authorization+-+Annuity.pdf",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  {
    text: "Policy Review",
    type: "Life",
    description: "Used to obtain your client’s life policy information.",
    href: "https://cdn.back9ins.com/forms/Policy+Review+Authorization+-+Life.pdf",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  {
    text: "iGo eApp",
    type: "Life",
    description:
      "Log-in to iPipeline's eApp. Requires a BackNine iPipeline Account.",
    href: "https://pipepasstoigo.ipipeline.com/default.aspx?gaid=2479",
    img: ipipeline
  },
  {
    text: "Application",
    type: "Financed & Leverage Insurance",
    description: "Start a life settlement application.",
    href: "https://cdn.back9ins.com/forms/BackNine.Life.Settlement.Application.pdf",
    icon: mdiFilePdfBox,
    iconColor: "error"
  },
  {
    text: "BackNine Preferred Agent Agreement",
    type: "All Lines",
    description: "Gain access to the ILIA platform by completing the Preferred Agent Agreement. This is needed for KaiZen and Premium Financed cases.",
    href: "https://cdn.back9ins.com/forms/KaiZen%20Preferred%20Agent%20Agreement%20&%20Rider.pdf",
    icon: mdiFilePdfBox,
    iconColor: "error"
  }
];
</script>
