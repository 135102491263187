<template>
  <v-card flat>
    <v-card-title> Commissions </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="!isWhiteglove && !isSignUpOrSelectAgent" cols="12" lg="6">
          <advisor-search
            id="commissions-assigned-to"
            v-model="appointmentAssignable"
            class="has-appended-button button-group-input"
            :prepend-inner-icon="mdiCalendar"
            label="Commissions Assigned To"
            hint="Determines if the agent's individual code or assignment code will be used. If locked, the Commissions Assigned To won't change even when the agent changes who their commissions are paid to."
            persistent-hint
            data-testid="settings-appointment-assignable"
            :clearable="false"
            :success="Boolean(appointmentAssignable)"
            :disabled="ownable.lockCommissions"
            @update:model-value="saveAttribute('appointmentAssignable')"
          >
            <template #append-inner>
              <app-button
                key="AppointmentAssignableLock"
                style="z-index: 3"
                data-testid="settings-appointment-assignable-lock"
                :disabled="ownable.lockCommissions"
                variant="text"
                density="comfortable"
                :icon="
                  appointmentAssignableLock
                    ? mdiLock
                    : mdiLockOpenVariantOutline
                "
                @mousedown.stop.prevent="toggleAppointmentAssignableLock"
                @touchstart.stop.prevent="toggleAppointmentAssignableLock"
              />
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.appointmentAssignable"
              />
              <active-save-indicator
                class="ml-2"
                :controller="savingBuffer.appointmentAssignableLock"
              />
            </template>
          </advisor-search>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useActiveSave } from "@/composables/active-save.composable";

import { mdiCalendar, mdiLock, mdiLockOpenVariantOutline } from "@mdi/js";

const approvedDomain = useApprovedDomainView();
const {
  ownable,
  isWhiteglove,
  isSignUpOrSelectAgent,
  appointmentAssignable,
  appointmentAssignableLock
} = storeToRefs(approvedDomain);

const savingBuffer = {
  appointmentAssignable: useActiveSave(),
  appointmentAssignableLock: useActiveSave()
};

function toggleAppointmentAssignableLock() {
  appointmentAssignableLock.value = !appointmentAssignableLock.value;
  saveAttribute("appointmentAssignableLock");
}

function saveAttribute(savingBufferAttribute, attribute = null) {
  savingBuffer[savingBufferAttribute].update(() =>
    approvedDomain.updateApprovedDomain(attribute || savingBufferAttribute)
  );
}
</script>
