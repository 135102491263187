import { SupportTicket } from "@/factories/SupportTicket";
import { defineStore } from "pinia";
import {
  getSupportTicket,
  updateSupportTicket
} from "@/api/support-tickets.service";
import { createNote, deleteNote } from "@/api/notes.service";
import {
  Note,
  NoteToCreateRequest,
  setNoteFromRequest
} from "@/factories/Note";

export const useSupportTicketView = defineStore("support-ticket", {
  state: () => SupportTicket(),
  getters: {
    pusherId() {
      return `private-${this.id}-SupportTicket`;
    }
  },
  actions: {
    async initialize(id) {
      this.$reset();
      const supportTicket = await getSupportTicket(id);
      this.$patch(supportTicket);
    },
    async deleteNote(id) {
      await deleteNote(id);
      const noteIndex = this.notes.findIndex(n => n.id === id);
      if (noteIndex === -1) return;
      this.notes.splice(noteIndex, 1);
    },
    addNote({ note: rawNote }) {
      this.notes.push(setNoteFromRequest(rawNote));
    },
    async createNote({ files, message }) {
      if (files?.length || message) {
        const noteToCreate = Note({
          documents: files,
          note: message,
          notableId: this.id,
          notableType: "SupportTicket"
        });

        await createNote(NoteToCreateRequest(noteToCreate, true));
      }
    },
    updateDescription() {
      return updateSupportTicket(this.id, { description: this.description });
    },
    updateAssignable() {
      return updateSupportTicket(this.id, {
        assignable_id: this.assignable.id,
        assignable_type: this.assignable.type
      });
    },
    updateCategories() {
      return updateSupportTicket(this.id, { categories: this.categories });
    },
    async updateStatus(value) {
      await updateSupportTicket(this.id, { completed: value });
      if (value) this.completedAt = new Date();
      else this.completedAt = null;
    }
  }
});
