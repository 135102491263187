<template>
  <v-row dense>
    <v-col
      v-for="(item, index) in resources"
      :key="index"
      cols="12"
      lg="3"
      md="4"
      sm="6"
    >
      <quote-method
        v-if="item.href"
        :href="item.href"
        target="_blank"
        :item="item"
      />
      <quote-method v-else-if="item.to" :to="item.to" :item="item" />
      <quote-method v-else :item="item" @click="item.click" />
    </v-col>
  </v-row>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import CreateQuoteAndApplySite from "@/dialogs/CreateQuoteAndApplySite.vue";
import QuoteMethod from "@/components/quotes/QuoteMethod.vue";

import { getRangeOfExposureLink } from "@/api/boss.service";
import {
  navigateToExternalLink,
  openWindowWithPost,
  parseErrorMessage
} from "@/util/helpers";
import { getResource } from "@/api/boss.service";

import { getAgentSummary } from "@/api/agents.service";
import { getAgencySummary } from "@/api/agencies.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiApplicationImport, mdiPageNext } from "@mdi/js";

import annuityRateWatch from "@/assets/img/annuity-rate-watch.png";
import quoteAndApplyLogo from "@/assets/img/quote-and-apply-logo.png";
import ipipeline from "@/assets/img/ipipeline.png";
import stratecision from "@/assets/img/stratecision.jpg";
import oneamerica from "@/assets/img/oneamerica.jpeg";
import zinnia from "@/assets/img/zinnia.png";
import ilia from "@/assets/img/ilia.svg";
import { computed, markRaw, ref } from "vue";
import { useRouter } from "vue-router";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const router = useRouter();

const fixed = {
  text: "Fixed",
  provider: "annuity_rate_watch",
  loading: false,
  ssoAction: "fixed_annuities",
  description: "Growth based on an annually declared rate.",
  type: "Annuity",
  img: annuityRateWatch
};
fixed.click = () => getLink(fixed);

const indexed = {
  text: "Indexed",
  provider: "annuity_rate_watch",
  loading: false,
  ssoAction: "fixed_indexed",
  description: "Growth based on the performance of an index.",
  type: "Annuity",
  img: annuityRateWatch,
  click: () => getLink(indexed)
};
const incomeRider = {
  text: "Income Rider",
  provider: "annuity_rate_watch",
  ssoAction: "income_rider",
  loading: false,
  description: "Indexed growth with guaranteed lifetime income.",
  type: "Annuity",
  img: annuityRateWatch,
  click: () => getLink(incomeRider)
};

const myga = {
  text: "MYGA",
  provider: "annuity_rate_watch",
  loading: false,
  ssoAction: "multi_year",
  description:
    "MYGA (Multi-Year Guaranteed Annuity) is a growth based fixed annuity with a guaranteed rate.",
  type: "Annuity",
  img: annuityRateWatch,
  click: () => getLink(myga)
};

const spia = {
  text: "SPIA",
  provider: "annuity_rate_watch",
  loading: false,
  ssoAction: "spia",
  description: "Guaranteed income stream using a single premium.",
  type: "Annuity",
  img: annuityRateWatch,
  click: () => getLink(spia)
};
spia.click = () => getLink(spia);

const ltciRangeOfExposure = {
  text: "LTC Range of Exposure",
  description: "Determine how costly long-term care can be.",
  type: "LTC",
  loading: false,
  icon: mdiApplicationImport,
  iconColor: "primary",
  click: () => openRangeOfExposure(ltciRangeOfExposure)
};

const ltciCostBenefit = {
  text: "LTC Cost & Benefit",
  description:
    "Cost & Benefit analyzer helps client make informed final decisions in 15-20 minutes",
  type: "LTC",
  loading: false,
  icon: mdiApplicationImport,
  iconColor: "primary",
  click: () => openCostBenefit(ltciCostBenefit)
};

let action = {};

let npn = user.loginable.npn;
if (user.loginable?.signer?.id) npn = user.loginable.signer?.npn;

if (user.loginable.approved_domain_url) {
  action = { href: user.loginable.approved_domain_url };
} else if (npn) {
  action = {
    click: () =>
      dialog.showDialog({
        component: markRaw(CreateQuoteAndApplySite),
        scrollable: true
      })
  };
} else {
  action = {
    click: () =>
      dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Missing NPN",
        subtitle:
          "You'll need your National Producer Number (NPN) added to use Quote & Apply. To do this, set a NPN in your settings.",
        func: () => {
          if (user.loginable?.signer?.id) {
            router.push({
              name: "AdvisorSettings",
              params: {
                id: user.loginable.signer.id,
                type: user.loginable.signer.type
              },
              query: { page: "basics" }
            });
          } else {
            router.push({
              name: "LoggedInUserSettings",
              query: { page: "basics" }
            });
          }
        }
      })
  };
}

const rawResources = [
  {
    text: "Quote & Apply",
    class: "quote-and-apply-card",
    description:
      "Agents and consumers can quote accidental death, IUL, final expense, linked benefit, long term care, term, universal life, and whole life.",
    type: "Life, LTC, and Linked Benefit",
    img: quoteAndApplyLogo,
    ...action
  },
  {
    text: "Request a Quote",
    description:
      "Still need help? Create a quote request for BackNine to quote your case.",
    type: "All Lines",
    key: "requestAQuote",
    class: "request-a-quote-card",
    to: { name: "CreateQuote" },
    img: "https://portal-pictures.back9ins.com/BOSS-Logo.png"
  },
  {
    text: "Impaired Risk",
    description:
      "Send client's information to multiple carriers and compare underwriting offers.",
    type: "Life",
    key: "impairedRiskQuote",
    class: "impaired-risk-card",
    to: { name: "ImpairedRiskQuoteCreate" },
    img: "https://portal-pictures.back9ins.com/BOSS-Logo.png"
  },
  {
    text: "More Quoting Options",
    description:
      "Choose other life, long term care, and annuity quoting engines.",
    type: "All Lines",
    click: () => (showAllOptions.value = true),
    icon: mdiPageNext,
    iconColor: "primary"
  },
  {
    text: "iPipeline",
    href: "//lifepipe.ipipeline.com/LTSearch.aspx?GAID=2479",
    description:
      "Agents can quote guaranteed universal life, return of premium term, and term. Requires a BackNine iPipeline Account.",
    type: "Life",
    img: ipipeline
  },
  fixed,
  indexed,
  incomeRider,
  myga,
  spia,
  {
    text: "Traditional LTCi",
    click: () => {
      const form = document.createElement("form");
      form.style.display = "none";
      form.method = "post";
      form.action = "https://www.ltcselect.com";
      form.target = "_blank";

      const inputAction = document.createElement("input");
      inputAction.type = "hidden";
      inputAction.name = "Action";
      inputAction.value = "SetLogin";
      form.appendChild(inputAction);

      const inputAccountName = document.createElement("input");
      inputAccountName.type = "hidden";
      inputAccountName.name = "AccountName";
      inputAccountName.value = "b9user";
      form.appendChild(inputAccountName);

      const inputPassword = document.createElement("input");
      inputPassword.type = "hidden";
      inputPassword.name = "Password";
      inputPassword.value = "b9pw";
      form.appendChild(inputPassword);

      document.body.appendChild(form);
      form.submit();
    },
    description: "Quote one or more insurers side-by-side",
    type: "LTC",
    img: stratecision
  },
  ltciRangeOfExposure,
  ltciCostBenefit,
  {
    text: "State Life Care Solutions Calculator",
    href: "https://www.oneamerica.com/caresolutionscalculator/index",
    description:
      "Quickly and efficiently quote key figures for Asset-Care. Print the quotes in a simple format for clients to digest and see a visual on how the product works",
    type: "Linked Benefit",
    img: oneamerica
  },
  {
    text: "WinFlex Web",
    href: "https://www.winflexweb.com/",
    description:
      "A standard entry to generate illustrations for multiple life insurance carriers",
    type: "Life",
    img: zinnia
  },
  {
    text: "ilia",
    href: "https://www.myilia.com",
    description:
      "NIW Companies specializes in innovative estate, business, and retirement planning solutions for high net-worth or high-income earning professionals and often used for Kai-Zen®.",
    type: "Life",
    img: ilia
  }
].filter(Boolean);

const showAllOptions = ref(false);

const resources = computed(() => {
  if (showAllOptions.value)
    return rawResources.filter(v => v.text !== "More Quoting Options");
  return rawResources.slice(0, 4);
});

async function getLink(item) {
  item.loading = true;
  try {
    const response = await getResource(item.provider, item.ssoAction);
    if (!response.data.link) return;

    navigateToExternalLink(response.data.link);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    item.loading = false;
  }
}

async function openRangeOfExposure(item) {
  item.loading = true;

  try {
    const url = await getRangeOfExposureLink();
    navigateToExternalLink(url);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    item.loading = false;
  }
}
async function openCostBenefit(item) {
  item.loading = true;

  try {
    const func =
      user.loginable.type === "Agent" ? getAgentSummary : getAgencySummary;
    const { advisor } = await func(user.loginable.id);
    const {
      phoneWork: phone,
      businessAddress,
      firstName,
      lastName,
      name,
      email
    } = advisor;

    if (!phone) {
      item.loading = false;
      return snackbar.showErrorSnackbar({
        message:
          "Please add a work phone number to use this tool (available under Settings > Basics)"
      });
    } else if (!businessAddress.state) {
      item.loading = false;
      return snackbar.showErrorSnackbar({
        message:
          "Please add a business address to use this tool (available under Settings > Basics)"
      });
    }

    openWindowWithPost("https://www.ltcicalc.com/sso.cfm?agency=BackNine", {
      UserName: email,
      FirstName: firstName || name,
      LastName: lastName || name,
      Email: email,
      Phone: phone,
      State: businessAddress.state,
      LogoutReturnUrl: "https://app.back9ins.com"
    });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    item.loading = false;
  }
}
</script>
