<template>
  <v-card flat color="section">
    <v-card-title> Identifiers </v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <text-field
          v-model="externalId"
          persistent-hint
          label="External ID"
          data-testid="external-id"
          :success="Boolean(externalId?.length)"
          hint="This is a special code used to identify people from other companies that we work with. It can be the ID the agent in an external application to BOSS, such as the agent's financial representative code."
          @update:model-value="saveExternalId"
        >
          <template #append-inner>
            <active-save-indicator
              :controller="savingBuffer.externalId.controller.value"
            />
          </template>
        </text-field>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { storeToRefs } from "pinia";

const agent = useAgentSettingsStore();
const { externalId } = storeToRefs(agent);

const savingBuffer = {
  externalId: useActiveSave()
};
function saveExternalId() {
  savingBuffer.externalId.debounceUpdate(agent.updateExternalId);
}
</script>
