export function ApplicationRequest(model = {}) {
  return {
    insuranceType: model?.insuranceType || null,
    carrier: model?.carrier || null,
    product: model?.product || null,
    dateOfBirth: model?.dateOfBirth || null,
    state: model?.state || null,
    faceAmount: model?.faceAmount || null,
    notes: model?.notes || null
  };
}

export function ApplicationRequestToCreateRequest(model) {
  return {
    birthdate: model.dateOfBirth,
    state: model.state,
    carrier_id: model.carrier.id,
    description: model.notes,
    product_id: model.product,
    face_amount: model.faceAmount
  };
}

export const INSURANCE_TYPES = [
  { title: "Annuity", value: "annuity" },
  { title: "Disability", value: "disability" },
  { title: "Life", value: "life" },
  { title: "LTC", value: "ltc" }
];
