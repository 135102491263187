<template>
  <v-row>
    <v-col cols="12">
      <v-row class="ma-0">
        <v-col cols="12" align="center">
          <v-img
            width="150"
            height="60"
            contain
            :src="theme.current.value.dark ? darkAvatar : lightAvatar"
          />
        </v-col>
        <v-col data-testid="billable" cols="12" md="6">
          <h2>{{ billable.name }}</h2>

          <card-item-a
            :text="billable.phone_work"
            :data-outbound-type="billable.type"
            :data-outbound-id="billable.id"
            :data-outbound-number="billable.phone_work"
            :href="`tel:${billable.phone_work}`"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="align-items-start"
          data-testid="billable-address"
          :class="{
            'text-right': mdAndUp,
            'text-left': !smAndDown
          }"
        >
          <div class="white-space-pre-wrap">
            {{ addressToText(billable.primary_address) }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <v-row class="ma-0">
        <v-col cols="12" md="9" class="pa-0">
          <card-items :items="items" />
        </v-col>
        <v-col data-testid="amount-due" cols="12" md="3" align="end">
          <h4 class="text-h6 mb-3">Amount Due</h4>
          <h1
            :class="{
              'text-error': paid_amount < 0,
              'text-success': paid_amount > 0
            }"
          >
            <currency-formatter :model-value="paid_amount" />
          </h1>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";

import CardItemA from "@/components/shared/card-items/CardItemA.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import { storeToRefs } from "pinia";

import { usePayPeriodViewStore } from "@/stores/pay-period-view";
import { computed } from "vue";
import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";

const theme = useTheme();
const { mdAndUp, smAndDown } = useDisplay();
const payPeriodView = usePayPeriodViewStore();

const { paid_date_value, id, eft, ownable, billable, paid_amount } =
  storeToRefs(payPeriodView);

import darkAvatar from "@/assets/img/Logo-Dark.png";
import lightAvatar from "@/assets/img/Logo-Light.png";
import { textItem } from "@/components/shared/card-items/card-items";

function addressToText(address) {
  return `${address.street_address}\n${address.city}, ${address.state} ${address.zip}`;
}

const items = computed(() => {
  return [
    textItem({
      title: "Advisor",
      text: ownable.value.name
    }),
    textItem({
      title: "Payment Type",
      text: eft.value.paying_to
    }),
    textItem({
      title: "Statement Number",
      text: `#${id.value}`
    }),
    textItem({
      title: `${ownable.value.name}'s Address`,
      text: addressToText(ownable.value.primary_address)
    }),
    textItem({
      title: "Payment Date",
      text: paid_date_value.value
    })
  ];
});
</script>
