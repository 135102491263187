<template>
  <sidebar-navigation />
  <v-main :style="mainStyle">
    <v-col class="pa-0" style="margin-bottom: 100px">
      <system-alert />
      <v-card class="hidden-md-and-up" flat width="100%">
        <v-row class="ma-0 pa-3" align="center" justify="center">
          <app-logo large />
        </v-row>
      </v-card>
      <app-header />
      <v-col class="pa-0">
        <app-breadcrumbs
          v-if="!$route.meta?.useBreadcrumbSlot"
          :key="$route.path"
          data-testid="breadcrumbs"
        />

        <router-view v-slot="{ Component }">
          <v-fade-transition mode="out-in">
            <component :is="Component" :key="$route.path" />
          </v-fade-transition>
        </router-view>

        <need-help v-if="showNeedHelpButton" @click="showNeedHelpDialog" />
      </v-col>
    </v-col>
  </v-main>
</template>

<script setup>
import NeedHelp from "@/components/shared/NeedHelp.vue";
import SystemAlert from "@/components/SystemAlert.vue";
import SidebarNavigation from "@/components/SidebarNavigation.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppLogo from "@/components/AppLogo.vue";
import { useHead } from "@unhead/vue";
import { useDialogStore } from "@/stores/dialog";
import CreateSupportTicketDialog from "@/components/support-tickets/CreateSupportTicketDialog.vue";
import { computed, markRaw } from "vue";
import { useUserStore } from "@/stores/user";
import { useRoute } from "vue-router";
import { useInstanceStore } from "@/stores/instance";
import { storeToRefs } from "pinia";

useHead({ titleTemplate: "%s | BOSS" });

const dialog = useDialogStore();
const user = useUserStore();
const route = useRoute();

const showNeedHelpButton = computed(() => {
  if (user.loginable.is_employee) return false;
  return !route.meta?.hideNeedHelpSupportTicket;
});

function showNeedHelpDialog() {
  dialog.showDialog({
    component: markRaw(CreateSupportTicketDialog)
  });
}

useHead({ titleTemplate: "%s | BOSS" });

const { fullscreen } = storeToRefs(useInstanceStore());

const mainStyle = computed(() =>
  fullscreen.value ? "--v-layout-top: 0px" : undefined
);
</script>
