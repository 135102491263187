import { computed } from "vue";

export function addEnabled(config, valueRef, { save }) {
  config.props.enabled = valueRef.value;
  config.props.enabledProps = { save };
  config.listeners["update:enabled"] = v => (valueRef.value = v);
}

export function addIncomeDurationType(config, valueRef, { items, save }) {
  config.props.incomeDurationType = valueRef.value.incomeDurationType;
  config.props.incomeDurationTypeProps = {
    items,
    save
  };
  config.listeners["update:incomeDurationType"] = v => {
    valueRef.value.incomeDurationType = v;
  };
}

export function addAnnuityGuaranteeType(config, valueRef, props) {
  config.props.annuityGuaranteeType = valueRef.value.annuityGuaranteeType;
  config.props.annuityGuaranteeTypeProps = props;
  config.listeners["update:annuityGuaranteeType"] = v => {
    valueRef.value.annuityGuaranteeType = v;
  };
}

export function addCertainPeriod(config, valueRef, { save }) {
  config.props.certainPeriod = valueRef.value.certainPeriod;
  config.props.certainPeriodProps = { save, min: 0, max: 99 };
  config.listeners["update:certainPeriod"] = v => {
    valueRef.value.certainPeriod = v;
  };
}

export function addIncomeStartDate(config, valueRef, props) {
  config.props.incomeStartDate = valueRef.value.incomeStartDate;
  config.props.incomeStartDateProps = props;
  config.listeners["update:incomeStartDate"] = v => {
    valueRef.value.incomeStartDate = v;
  };
}

export function addSurrenderPeriods(config, valueRef, { items, save }) {
  config.props.surrenderPeriods = valueRef.value.surrenderPeriods;
  config.props.surrenderPeriodsProps = {
    items,
    save
  };
  config.listeners["update:surrenderPeriods"] = v => {
    valueRef.value.surrenderPeriods = v;
  };
}

export function addIncomeStartAge(config, valueRef, { save }) {
  config.props.soleIncomeStartAgeProps = { save, min: 0, max: 999 };
  config.props.soleIncomeStartAge = valueRef.value.incomeStartAge;
  config.listeners["update:soleIncomeStartAge"] = v => {
    valueRef.value.incomeStartAge = v;
  };
}

export function addPayDurations(config, valueRef, { items, save }) {
  config.props.payDurationsProps = {
    items,
    save
  };
  config.props.payDurations = valueRef.value.payDurations;
  config.listeners["update:payDurations"] = v => {
    valueRef.value.payDurations = v;
  };
}

export function addIncome(
  config,
  valueRef,
  { min, max, startAgeSave, endAgeSave }
) {
  config.props.incomeProps = {
    min,
    max,
    startAgeSave,
    endAgeSave
  };
  config.props.incomeEnabled = valueRef.value.incomeEnabled;
  config.props.incomeStartAge = valueRef.value.incomeStartAge;
  config.props.incomeEndAge = valueRef.value.incomeEndAge;
  config.listeners["update:incomeEnabled"] = v => {
    valueRef.value.incomeEnabled = v;
  };
  config.listeners["update:incomeStartAge"] = v => {
    valueRef.value.incomeStartAge = v;
  };
  config.listeners["update:incomeEndAge"] = v => {
    valueRef.value.incomeEndAge = v;
  };
}

export function addProductTypes(config, valueRef, { items, save }) {
  config.props.productTypes = valueRef.value;
  config.props.productTypesProps = {
    items,
    save
  };
  config.listeners["update:productTypes"] = v => {
    valueRef.value = v;
  };
}

export function baseTypeGenerator(type, disabled) {
  return {
    props: {
      type,
      disabled
    },
    listeners: {}
  };
}

export const basicTypeEnabled = (ref, type, cb) =>
  computed({
    get: () => ref.value.productType.some(v => v === type),
    set: v => {
      const typeIndex = ref.value.productType.findIndex(v => v === type);
      if (v && typeIndex === -1) {
        ref.value.productType.push(type);
      } else if (!v && typeIndex !== -1) {
        ref.value.productType.splice(typeIndex, 1);
      }

      if (cb) cb({ type, value: v });
    }
  });

export const compositeProductType = (ref, items) =>
  computed({
    get: () => {
      return ref.value.productType.filter(v => items.some(i => i === v));
    },
    set: v => {
      items.forEach(i => {
        const valueIncludesItem = v.some(a => a === i);
        const refIndex = ref.value.productType.findIndex(r => r === i);
        const refHasItem = refIndex !== -1;

        if (valueIncludesItem && !refHasItem) ref.value.productType.push(i);
        else if (!valueIncludesItem && refHasItem) {
          ref.value.productType.splice(refIndex, 1);
        }
      });
    }
  });
