import xss from "xss";

export const sanitize = value => {
  return xss(value, {
    css: false,
    stripIgnoreTag: true,
    whiteList: {
      a: ["href"],
      img: ["src"]
    }
  });
};

export const customChatTemplateSanitizer = value => {
  return xss(value, {
    whiteList: {
      a: ["style", "href"],
      b: ["style"],
      blockquote: ["style"],
      body: ["style"],
      br: ["style"],
      code: ["style"],
      div: ["style"],
      em: ["style"],
      center: ["style"],
      title: ["style"],
      h1: ["style"],
      h2: ["style"],
      h3: ["style"],
      h4: ["style"],
      h5: ["style"],
      h6: ["style"],
      head: ["style"],
      html: ["style"],
      i: ["style"],
      img: ["style", "src"],
      li: ["style"],
      ol: ["style"],
      p: ["style"],
      pre: ["style"],
      small: ["style"],
      span: ["style"],
      strong: ["style"],
      sub: ["style"],
      sup: ["style"],
      table: ["style"],
      tbody: ["style"],
      td: ["style"],
      tfoot: ["style"],
      th: ["style"],
      thead: ["style"],
      tr: ["style"],
      u: ["style"],
      ul: ["style"]
    },
    disallowedTagsMode: "discard",
    allowedSchemes: ["https", "mailto", "tel"],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  });
};
