<template>
  <v-row dense class="ma-0 pa-3 bg-section">
    <v-col cols="12">
      <date-input
        v-model="followUp"
        label="Follow Up"
        data-testid="follow-up"
        :disabled="isGroupOne"
        :success="followUpValidation.success"
        :error-messages="followUpValidation.errorMessages"
        @update:model-value="validateAndSave('followUp')"
      >
        <template #append-inner>
          <active-save-indicator :controller="savingBuffer.followUp" />
        </template>
      </date-input>
    </v-col>
    <v-col cols="12">
      <component
        :is="lineComponent"
        v-model:type-of-funds="typeOfFunds"
        v-model:product-type="productType"
        v-model:premium="premium"
        v-model:riders="riders"
        v-model:existing-coverage="existingCoverage"
        v-model:description="description"
        v-model:existing-benefit-maximum="existingBenefitMaximum"
        v-model:existing-benefit-period="existingBenefitPeriod"
        v-model:existing-elimination-period="existingEliminationPeriod"
        v-model:existing-employer-paid="existingEmployerPaid"
        v-model:existing-percentage-maximum="existingPercentageMaximum"
        v-model:monthly-benefit="monthlyBenefit"
        v-model:monthly-benefit-solve="monthlyBenefitSolve"
        v-model:mode="mode"
        v-model:benefit-period="benefitPeriod"
        v-model:elimination-period="eliminationPeriod"
        v-model:smoker="smoker"
        v-model:status="status"
        v-model:state="state"
        v-model:face-amount="faceAmount"
        v-model:inflation="inflation"
        v-model:discount="discount"
        v-model:surrender-periods="surrenderPeriods"
        v-model:income-duration-type="incomeDurationType"
        v-model:income-enabled="incomeEnabled"
        v-model:annuity-guarantee-type="annuityGuaranteeType"
        v-model:certain-period="certainPeriod"
        v-model:income-start-date="incomeStartDate"
        v-model:income-start-age="incomeStartAge"
        v-model:income-end-age="incomeEndAge"
        v-model:pay-durations="payDurations"
        v-model:business-owner="businessOwner"
        :disabled="isGroupOne"
        :quote-id="id"
        :type="line"
        :birthdate="birthdate"
        :quote-save="quoteUpdate"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import DateInput from "@/components/shared/DateInput.vue";
import QuoteRequestAnnuityInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestAnnuityInfo.vue";
import QuoteRequestDisabilityInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestDisabilityInfo.vue";
import QuoteRequestLifeInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestLifeInfo.vue";
import QuoteRequestLtcInfo from "@/components/quotes/quote-request/illustration-info/QuoteRequestLtcInfo.vue";

import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { updateQuote } from "@/api/quotes.service";
import { updateIndividual } from "@/api/individual.service";
import { updateContractParty } from "@/api/contract-party.service";

import { storeToRefs } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";
import { computedValidation } from "@/util/helpers";
import { isValid } from "date-fns";
import parse from "date-fns/parse";
import { computed, markRaw, nextTick } from "vue";
import * as QUOTE_TYPE from "@/constants/quote-types.constants";
import { useActiveSave } from "@/composables/active-save.composable";
import useVuelidate from "@vuelidate/core";
import { QuoteAttributeToRequest } from "@/factories/Quote";
import { useUserStore } from "@/stores/user";

const user = useUserStore();
const { isGroupOne } = storeToRefs(user);

const savingBuffer = {
  followUp: useActiveSave()
};

const quote = useQuoteViewStore();
const {
  id,
  line,
  typeOfFunds,
  productType,
  premium,
  riders,
  existingCoverage,
  description,
  existingBenefitMaximum,
  existingBenefitPeriod,
  existingEliminationPeriod,
  existingEmployerPaid,
  existingPercentageMaximum,
  followUp,
  monthlyBenefit,
  monthlyBenefitSolve,
  mode,
  benefitPeriod,
  eliminationPeriod,
  smoker,
  status,
  birthdate,
  state,
  faceAmount,
  inflation,
  discount,
  insured,

  // New fields
  surrenderPeriods,
  incomeDurationType,
  incomeEnabled,
  annuityGuaranteeType,
  certainPeriod,
  incomeStartDate,
  incomeStartAge,
  incomeEndAge,
  payDurations,
  businessOwner
} = storeToRefs(quote);

const lineComponent = computed(() => {
  switch (line.value) {
    case QUOTE_TYPE.ANNUITY:
      return markRaw(QuoteRequestAnnuityInfo);
    case QUOTE_TYPE.LIFE:
      return markRaw(QuoteRequestLifeInfo);
    case QUOTE_TYPE.LTC:
      return markRaw(QuoteRequestLtcInfo);
    default:
      return markRaw(QuoteRequestDisabilityInfo);
  }
});

const v$ = useVuelidate(
  {
    followUp: {
      valid: v => {
        try {
          if (!v) return false;
          const date = parse(v, "yyyy-MM-dd", new Date());
          return isValid(date);
        } catch (e) {
          return false;
        }
      },
      required: v => !!v
    }
  },
  { followUp },
  { $scope: "quote-request" }
);

const followUpValidation = computedValidation(v$.value.followUp, [
  { key: "required", message: "Required" },
  { key: "valid", message: "Must be a valid date" }
]);

function validateAndSave(attribute) {
  if (isGroupOne.value) return;
  if (v$.value[attribute].$invalid) return;
  savingBuffer[attribute].debounceUpdate(() => quoteUpdate(attribute));
}

async function quoteUpdate(attribute) {
  if (isGroupOne.value) return;
  await nextTick();
  await nextTick();
  if (attribute === "smoker") return insuredUpdate(attribute);
  return updateQuote(id.value, {
    quote: QuoteAttributeToRequest(quote, attribute)
  });
}

async function insuredUpdate(attribute) {
  const requestMap = {
    smoker: { smoker: smoker.value }
  };

  const body = requestMap[attribute];
  // Contract Party will occur when created from a case
  if (insured.value.ownable.type === "contract_party") {
    return updateContractParty(insured.value.ownable.id, body);
  }

  return updateIndividual(insured.value.ownable.id, {
    individual: body
  });
}
</script>
