<template>
  <v-row justify="end" class="d-print-none ma-0 px-3">
    <app-button
      v-if="canEditEft"
      color="accent"
      variant="outlined"
      target="_blank"
      class="mr-1 text-none rounded-pill"
      data-testid="edit-payment-type"
      @click="changeEft"
    >
      <v-icon class="mr-1" :icon="mdiPencil" /> Edit Payment Type
    </app-button>
    <app-button
      color="primary"
      variant="outlined"
      class="mr-1 text-none rounded-pill"
      data-testid="download-pdf"
      download
      :href="payPeriodPdfUrl"
    >
      <v-icon class="mr-1" :icon="mdiFilePdfBox" /> Download PDF
    </app-button>
    <app-button
      variant="outlined"
      color="primary"
      class="text-none rounded-pill"
      data-testid="download-csv"
      download
      :href="payPeriodCsvUrl"
    >
      <v-icon class="mr-1" :icon="mdiFileDelimited" /> Download CSV
    </app-button>
  </v-row>
</template>
<script setup>
import { usePayPeriodViewStore } from "@/stores/pay-period-view";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw } from "vue";
import ChangePayPeriodEft from "@/dialogs/ChangePayPeriodEft.vue";
import { PERMISSION } from "@/constants/permissions.constants";
import { mdiPencil, mdiFilePdfBox, mdiFileDelimited } from "@mdi/js";

const user = useUserStore();
const dialog = useDialogStore();

const { payPeriodPdfUrl, payPeriodCsvUrl, paid_date_value, id, eft, ownable } =
  storeToRefs(usePayPeriodViewStore());

const canEditEft = computed(
  () =>
    user.permissions[PERMISSION.ACCOUNTING] &&
    paid_date_value.value === "Not Paid"
);

async function changeEft() {
  if (!canEditEft.value) return;
  const { eft: newEft } = await dialog.showDialog({
    component: markRaw(ChangePayPeriodEft),
    payPeriodId: id.value,
    modelValue: eft.value.id,
    advisorId: ownable.value.id,
    advisorType: ownable.value.type
  });
  if (!newEft) return;
  eft.value.id = newEft.value;
  eft.value.paying_to = newEft.title;
}
</script>
