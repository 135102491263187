<template>
  <v-data-table
    :search="props.search"
    :items="table.mappedItems.value"
    :headers="table.tableHeaders.value"
    data-testid="transactions-table"
    class="transparent-data-table"
    item-value="key"
    :mobile="null"
    mobile-breakpoint="sm"
  >
    <template #[`item.statementDate`]="{ item }">
      <router-link
        :to="getRouterLink('Statement', item.additional.statementId)"
      >
        {{ item.statementDate }}
      </router-link>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { useTable } from "@/composables/table.composable";
import { watch } from "vue";
import {
  timestampFormatter,
  currencyFormat,
  getRouterLink,
  formatPercentage
} from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";
const props = defineProps({
  search: {
    type: [Object, String],
    required: false,
    default: () => null
  },
  loading: Boolean
});

const { transactions } = storeToRefs(useCaseViewStore());

const table = useTable({
  headers: [
    new TableHeader({
      text: "Transaction ID",
      value: "id",
      map: "id",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Statement Date",
      value: "statementDate",
      map: "statementDate",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Premium",
      value: "amount",
      map: "amount",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Direct Commission",
      value: "directCommission",
      map: "directCommission",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Override",
      value: "override",
      map: "override",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Total Points",
      value: "totalPoints",
      map: "totalPoints",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Memo",
      value: "memo",
      map: "memo",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Created By",
      value: "createdBy",
      map: "createdBy",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Created At",
      value: "createdAt",
      map: "createdAt",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    })
  ]
});

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  transactions.value.forEach(t => {
    table.items.value.push({
      ...t,
      amount: currencyFormat(t.amount),
      directCommission: currencyFormat(t.directCommission),
      override: currencyFormat(t.override),
      statementDate: timestampFormatter(t.statementDate, "sole-day"),
      totalPoints: formatPercentage(t.totalPoints, { isDecimal: true }),
      createdAt: timestampFormatter(t.createdAt, null, "date-time")
    });
  });
}

watch(transactions, setItems, { immediate: true, deep: true });
</script>
