<template>
  <v-card
    data-testid="otp-enrollment"
    variant="outlined"
    class="pt-6 pa-3 bg-surface"
    @click="openOtpEnrollmentDialog"
  >
    <div class="w-100 flex-row justify-center">
      <v-icon size="64" class="opacity-50" :icon="mdiTwoFactorAuthentication" />
    </div>
    <v-card-title class="justify-start text-h5">
      <div>Two Factor Authentication</div>
    </v-card-title>
    <v-card-text class="text-body-2">
      <strong v-if="enrolled" class="text-success">Enrolled</strong>
      <strong v-else class="text-error">Not Enrolled</strong>
    </v-card-text>
    <v-card-text class="text-left">
      <div style="max-width: 25em">
        Boost your account security by activating two-factor authentication.
      </div>
    </v-card-text>
    <v-card-actions>
      <app-button
        class="text-none"
        variant="text"
        color="primary"
        text="Manage Enrollment"
      />
    </v-card-actions>
  </v-card>
</template>
<script setup>
import SecurityTwoFactorEnrollmentDialog from "@/components/settings/security/SecurityTwoFactorEnrollmentDialog.vue";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
import { mdiTwoFactorAuthentication } from "@mdi/js";
import { storeToRefs } from "pinia";
import { markRaw } from "vue";

const user = useUserStore();
const { otp_enrolled: enrolled } = storeToRefs(user);

const dialog = useDialogStore();
function openOtpEnrollmentDialog() {
  dialog.showDialog({
    component: markRaw(SecurityTwoFactorEnrollmentDialog),
    width: "30rem"
  });
}
</script>
