export const templates = [
  { title: "Business Life Insurance", value: "business_life_insurance" },
  {
    title: "Final Expense Life Insurance",
    value: "final_expense_life_insurance"
  },
  {
    title: "Get a Head Start on your Future Retirement",
    value: "get_a_head_start_on_your_future_retirement"
  },
  {
    title: "Life Insurance Policy Review",
    value: "life_insurance_policy_review"
  },
  {
    title: "Life with Long Term Care Insurance",
    value: "life_with_long_term_care_insurance"
  },
  {
    title: "New Mortgage, New Life Insurance",
    value: "new_mortgage_new_life_insurance"
  },
  { title: "No Exam Life Insurance", value: "no_exam_life_insurance" }
];
