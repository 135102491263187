<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="advisor-profile-appointments-table"
    :mobile="null"
    mobile-breakpoint="sm"
    hide-default-footer
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :sort-by="table.options.value.sortBy"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #[`item.name`]="{ item }">
      <div class="truncate">
        <router-link
          class="text-none"
          :to="{
            name: 'AppointmentView',
            params: { id: item.additional.id }
          }"
        >
          {{ item.name }}
        </router-link>
      </div>
    </template>

    <template #[`item.linesOfAuthority`]="{ item }">
      {{ item.linesOfAuthority?.join(", ") }}
    </template>

    <template #[`item.created`]="{ item }">
      <timestamp-formatter :model-value="item.created" />
    </template>

    <template #[`item.followUp`]="{ item }">
      <timestamp-formatter :model-value="item.followUp" parser="sole-day" />
    </template>

    <template #[`item.lastNote`]="{ item }">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <div class="truncate" v-bind="props">
            {{ item.lastNote }}
          </div>
        </template>
        <span> {{ item.lastNote }} </span>
      </v-tooltip>
    </template>

    <template #bottom>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <app-button
          data-testid="advisor-profile-appointments-table-view-all"
          color="primary"
          class="text-none"
          variant="text"
          :prepend-icon="mdiOpenInApp"
          :text="`View ${name}'s Appointments`"
          @click="viewAll"
        />
      </v-row>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { parseErrorMessage } from "@/util/helpers";
import { getAppointments } from "@/api/appointment.service";
import { useTable } from "@/composables/table.composable";

import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useRouter } from "vue-router";
import { mdiOpenInApp } from "@mdi/js";
import { TableOptions } from "@/classes/data-table/TableOptions";

const snackbar = useSnackbarStore();
const router = useRouter();

const tableStore = useTableStore();
const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Code",
      value: "name",
      map: "name",
      sortFilterMap: "appointments.name"
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrierName",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      sortKey: "carrier"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      sortFilterMap: "general_status"
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUp",
      map: "followUpDate",
      sortFilterMap: "follow_up_date",
      ...TableHeader.IS_SORTABLE
    })
  ],
  getData: getAppointments,
  shouldIncludeCancelToken: true,
  options: TableOptions({
    sortBy: [{ key: "followUp", order: "desc" }]
  })
});

async function getData() {
  const additionalFilter = {
    advisor_id: id.value,
    advisor_type: type.value,
    view: "all"
  };

  try {
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function viewAll() {
  tableStore.appointmentsTable.filter = {
    advisor: tableFilterData.value
  };

  router.push({ name: "Tables", query: { page: "appointments" } });
}
</script>
