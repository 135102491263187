import CardItemText from "@/components/shared/card-items/CardItemText.vue";
import CardItemRouterLink from "@/components/shared/card-items/CardItemRouterLink.vue";
import CardItemA from "@/components/shared/card-items/CardItemA.vue";
import CardItemSensitiveInfo from "@/components/shared/card-items/CardItemSensitiveInfo.vue";
import CardItemRouterLinkList from "@/components/shared/card-items/CardItemRouterLinkList.vue";
import { markRaw } from "vue";

export const routerLinkItem = ({ title, text, to }) => ({
  component: markRaw(CardItemRouterLink),
  key: title,
  props: {
    title,
    text,
    to
  }
});

export const routerLinkItemList = ({ title, links }) => ({
  component: markRaw(CardItemRouterLinkList),
  key: title,
  props: {
    title,
    links
  }
});

export const textItem = ({ title, text, onClick = null }) => ({
  component: markRaw(CardItemText),
  key: title,
  props: {
    title,
    text,
    onClick
  }
});

export const linkItem = ({
  title,
  href,
  text,
  dataOutboundType,
  dataOutboundNumber,
  dataOutboundId,
  linkProps,
  key
}) => ({
  component: markRaw(CardItemA),
  key: key || title,
  props: {
    title,
    href,
    text,
    dataOutboundType,
    dataOutboundNumber,
    dataOutboundId,
    linkProps
  }
});

export const sensitiveInfoItem = ({ title, type, fetchFunc }) => ({
  component: markRaw(CardItemSensitiveInfo),
  key: title,
  props: {
    title,
    type,
    fetchFunc
  }
});
