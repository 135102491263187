import { setConnectionFromRaw } from "@/factories/ConnectionFactory";
import { setEftFromRaw } from "@/factories/EftFactory";
import { setErrorsOmissionsFromRaw } from "@/factories/EoFactory";
import { Address, setAddressFromRaw } from "@/factories/AddressFactory";
import { setCommunicationPreferencesFromRaw } from "@/factories/CommunicationPreferencesFactory";
import { setLoginFromRaw } from "@/factories/Login";
import { setCommissionSplitFromRequest } from "@/factories/CommissionSplitFactory";
import {
  CommissionOptions,
  CommissionSchedules,
  setCommissionOptionsFromRaw,
  setCommissionSchedulesFromRaw
} from "@/factories/CommissionSchedules";
import {
  DOCUMENT_CATEGORIES,
  Document,
  setDocumentFromRequest
} from "@/factories/Document";
import { setApprovedDomainSearchItemFromRequest } from "@/factories/ApprovedDomain";

import { soleDateSort } from "@/util/helpers";

export function AgentSettings() {
  return {
    pageValidation: {
      trainings: null,
      "account-details": null,
      commissions: null,
      eo: null,
      employment: null,
      signature: null,
      "background-questions": null
    },
    aml: Training(),
    category: null,
    defaultApprovedDomain: null,
    deceasedAt: null,
    reg187: Training(),
    trustedSenders: [],
    anyAppointments: false,
    approvedDomainUrl: null,
    isSaas: false,
    id: null,
    agencies: {},
    legalFirstName: null,
    legalLastName: null,
    firstName: null,
    lastName: null,
    npn: null,
    marketingManager: null,
    primaryLogin: null,
    phoneWork: null,
    phoneWorkExtension: null,
    phoneMobile: null,
    email: null,
    messageClient: null,
    caseConcierge: null,
    caseManager: null,
    ssn: null,
    birthdate: null,
    gender: null,
    driversLicense: {
      id: null,
      state: null,
      number: null
    },
    securitiesLicensed: null,
    licensed: null,
    crdNumber: null,
    assignment: {
      id: null,
      type: "",
      name: ""
    },
    signingOfficer: null,
    annualization: null,
    hideCommission: null,
    signatureAuthorized: null,
    contractingQuestions: {},
    occupations: {},
    occupationOrder: [],
    efts: {},
    eo: null,
    externalId: null,
    title: null,
    businessAddress: {},
    homeAddresses: {},
    homeAddressOrder: [],
    avatar: null,
    communicationPreferences: null,
    description: "",
    discovery: null,
    link: "agent",
    showUseOwnersAvatar: null,
    goals: [],
    schedulingLink: null,
    commissionSplits: {},
    isMarketingManager: false,
    lockCommissions: false,
    commissions: {
      current: CommissionSchedules(),
      options: CommissionOptions()
    },
    credits: {},
    connections: {},
    logins: {},
    relatedDocuments: {}
  };
}

export function setAgentSettingsfromRaw(model = {}) {
  const agent = AgentSettings();

  agent.annualization = model?.annualization;
  agent.anyAppointments = model?.any_appointments;
  agent.avatar = model?.avatar_url;
  agent.birthdate = model?.birthdate;
  agent.category = model?.category;
  agent.crdNumber = model?.crd_number;
  agent.deceasedAt = model?.deceased_at;
  agent.email = model?.email;
  agent.externalId = model?.external_id;
  agent.firstName = model?.first_name;
  agent.gender = model?.gender;
  agent.goals = model?.goals;
  agent.hideCommission = model?.hide_commission;
  agent.id = model?.id;
  agent.isMarketingManager = model?.is_marketing_manager;
  agent.isSaas = !model?.show_backnine;
  agent.lastName = model?.last_name;
  agent.legalFirstName = model.legal_first_name;
  agent.legalLastName = model.legal_last_name;
  agent.lockCommissions = model?.commission_lock;
  agent.marketingManager = model?.marketing_manager;
  agent.messageClient = model?.message_client;
  agent.npn = model?.npn;
  agent.phoneMobile = model?.phones?.mobile;
  agent.schedulingLink = model?.scheduling_link;
  agent.securitiesLicensed = Boolean(model?.crd_number);
  agent.signingOfficer = Boolean(model?.assigns?.connection?.signor);
  agent.ssn = model?.ssn || model?.identifier;

  agent.driversLicense.number = model?.drivers_license?.number;
  agent.driversLicense.state = model?.drivers_license?.state;
  agent.driversLicense.id = model?.drivers_license?.id;

  agent.assignment.id = model?.assigns?.id;
  agent.assignment.name = model?.assigns?.name;
  agent.assignment.type = model?.assigns?.type;
  agent.title = model?.title;

  if (model?.case_concierge) {
    agent.caseConcierge = {
      id: model.case_concierge.id,
      name: model.case_concierge.name,
      type: model.case_concierge.type.model
    };
  }

  if (model?.case_manager) {
    agent.caseManager = {
      id: model.case_manager.id,
      name: model.case_manager.name,
      type: model.case_manager.type.model
    };
  }

  agent.description = model?.description;
  agent.discovery = model?.discovery;
  agent.showUseOwnersAvatar = model?.show_use_owners_avatar;
  if (model?.default_approved_domain) {
    agent.defaultApprovedDomain = setApprovedDomainSearchItemFromRequest(
      model?.default_approved_domain
    );
  }

  const workPhone = model?.phones?.work;
  if (workPhone) {
    const phoneStr = workPhone.split("x");
    agent.phoneWork = phoneStr[0];
    if (phoneStr.length > 1) {
      agent.phoneWorkExtension = phoneStr[1];
    }
  }

  agent.aml = setTrainingFromRaw(model?.aml);
  agent.reg187 = setTrainingFromRaw(model?.reg_187);
  agent.eo = setErrorsOmissionsFromRaw(model?.eo || model?.eos?.current);

  if (model?.primary_login) {
    agent.primaryLogin = setLoginFromRaw(model?.primary_login);
  }

  model?.connections?.forEach(rawConnection => {
    const connection = setConnectionFromRaw(rawConnection);
    agent.connections[connection.id] = connection;
  });

  agent.contractingQuestions = {};
  flattenContractingQuestions(
    model.parent_contracting_answers,
    agent.contractingQuestions
  );

  model?.occupations?.forEach(rawOccupation => {
    const occupation = setOccupationFromRaw(rawOccupation);
    agent.occupations[occupation.id] = occupation;
  });

  agent.occupationOrder = Object.values(agent.occupations)
    .sort(soleDateSort)
    .map(val => val.id)
    .reverse();

  model?.efts?.forEach(rawEft => {
    const eft = setEftFromRaw(rawEft);
    agent.efts[eft.id] = eft;
  });

  model?.addresses?.forEach(rawAddress => {
    const address = setAddressFromRaw(rawAddress);
    if (address.addressType === "business") {
      agent.businessAddress = address;
    } else {
      agent.homeAddresses[address.id] = address;
    }
  });

  agent.homeAddressOrder = Object.values(agent.homeAddresses)
    .sort(soleDateSort)
    .map(val => val.id)
    .reverse();

  agent.communicationPreferences = setCommunicationPreferencesFromRaw(
    model?.communication_preference
  );

  model?.related_documents?.forEach(rdoc => {
    const document = setDocumentFromRequest(rdoc);
    agent.relatedDocuments[document.uid] = document;
  });

  Object.keys(agent.relatedDocuments).forEach(key => {
    const document = Document(agent.relatedDocuments[key]);
    if (document.category === DOCUMENT_CATEGORIES.SIGNATURE_AUTHORIZATION) {
      agent.signatureAuthorized = document.createdAt;
    }
  });

  model?.logins?.forEach(rlogin => {
    const login = setLoginFromRaw(rlogin);
    agent.logins[login.id] = login;
  });

  if (model?.commissions?.current) {
    agent.commissions.current = setCommissionSchedulesFromRaw(
      model.commissions.current
    );
  }

  if (model?.commissions?.options) {
    agent.commissions.options = setCommissionOptionsFromRaw(
      model.commissions.options
    );
  }

  model?.commission_splits?.forEach(rawSplit => {
    const split = setCommissionSplitFromRequest(rawSplit);
    agent.commissionSplits[split.id] = split;
  });

  model?.credits?.forEach(rawCredit => {
    const credit = setCreditFromRaw(rawCredit.marketing_manager);
    agent.credits[credit.id] = credit;
  });

  model?.agencies?.forEach(rawAgency => {
    if (!rawAgency) return;
    agent.agencies[rawAgency.id] = {
      id: rawAgency.id,
      name: rawAgency.name,
      type: "Agency"
    };
  });

  return agent;
}

function flattenContractingQuestions(
  questions,
  accumulator,
  level = 0,
  parentId = null
) {
  questions.forEach(q => {
    accumulator[`q_${q.id}`] = setContractingQuestionFromRequest(
      q,
      level,
      parentId
    );
    if (q.child_contracting_answers) {
      flattenContractingQuestions(
        q.child_contracting_answers,
        accumulator,
        level + 1,
        `q_${q.id}`
      );
    }
  });
}

function LegalQuestion(model = {}) {
  return {
    id: model?.id || null,
    questionId: model?.questionId,
    answer: model?.answer || null,
    level: model?.level || 0,
    documents: model?.documents || {},
    questionText: model?.questionText || "",
    explanations: model?.explanations || {},
    contractingQuestion: ContractingQuestion(model?.contractingQuestion),
    parentId: model?.parentId || null,
    childIds: model?.childIds || []
  };
}

function setContractingQuestionFromRequest(model, level, parentId) {
  const question = LegalQuestion();
  question.level = level;
  question.answer = model?.answer;
  question.questionText = model?.contracting_question?.question;
  question.id = `q_${model?.id}`;
  question.questionId = model?.id;
  question.parentId = parentId;

  if (model?.child_contracting_answers?.length) {
    model.child_contracting_answers.forEach(q =>
      question.childIds.push(`q_${q.id}`)
    );
  }

  question.contractingQuestion = setContractingQuestionFromRaw(
    model?.contracting_question
  );

  model?.documents?.forEach(rdoc => {
    const document = setDocumentFromRequest(rdoc);
    question.documents[document.id] = document;
  });

  model?.explanations?.forEach(rawExplanation => {
    const explanation =
      setContractingQuestionExplanationFromRaw(rawExplanation);
    question.explanations[explanation.id] = explanation;
  });

  return question;
}

export function setRawFromContractingQuestion(question) {
  return {
    answer: question.answer
  };
}

export function ContractingQuestionExplanation(model = {}) {
  return {
    actionDate: model?.actionDate || null,
    action: model?.action || null,
    reason: model?.reason || null,
    explanation: model?.explanation || null,
    id: model?.id || null,
    deleting: model?.deleting || false
  };
}

export function setContractingQuestionExplanationFromRaw(model) {
  const q = ContractingQuestionExplanation();
  q.actionDate = model?.action_date;
  q.action = model?.action;
  q.reason = model?.reason;
  q.explanation = model?.explanation;
  q.id = model?.id;
  return q;
}

export function setRawFromContractingQuestionExplanation(explanation) {
  return {
    action_date: explanation.actionDate,
    action: explanation.action,
    reason: explanation.reason,
    explanation: explanation.explanation
  };
}

function ContractingQuestion(model = {}) {
  return {
    id: model?.id || null,
    order: model?.order || null,
    parentId: model?.parentId || null,
    question: model?.question || null,
    questionNumber: model?.questionNumber || null,
    documents: model?.documents || []
  };
}

function setContractingQuestionFromRaw(model = {}) {
  const q = ContractingQuestion();
  q.id = model?.id;
  q.order = model?.order;
  q.parentId = model?.parent_id;
  q.question = model?.question;
  q.questionNumber = model?.question_number;

  q.documents = [];
  if (model?.documents?.length) {
    model.documents.forEach(rdoc =>
      q.documents.push(setDocumentFromRequest(rdoc))
    );
  }
  return q;
}

function Credit({ id = 0, name = "", schedulingLinks = [] } = {}) {
  return {
    id,
    name,
    schedulingLinks
  };
}

function setCreditFromRaw(model = {}) {
  const credit = Credit();
  credit.id = model.id;
  credit.name = model.name;
  credit.schedulingLinks = model.scheduling_links || [];
  return credit;
}

export const TRAININGS = {
  AML: "AML",
  REG_187: "Reg 187"
};

export function Training(model = {}) {
  return {
    id: model?.id || 0,
    providerName: model?.providerName || "",
    courseName: model?.courseName || "",
    completedAt: model?.completedAt || "",
    file: model?.file || null,
    documentUid: model?.documentUid || null,
    ownerId: model?.ownerId || null,
    ownerType: model?.ownerType || null,
    category: model?.category || null
  };
}

export function setTrainingFromRaw(raw = {}) {
  const training = Training();
  training.id = raw?.id;
  training.providerName = raw?.provider_name;
  training.completedAt = raw?.completed_at;
  training.courseName = raw?.course_name;
  training.documentUid = raw?.document?.uid;
  training.category = raw?.category;
  return training;
}

export function setRawFromTraining(training) {
  return {
    category: training.category,
    provider_name: training.providerName,
    completed_at: training.completedAt,
    course_name: training.courseName,
    file: training.file,
    ownable_type: training.ownerType,
    ownable_id: training.ownerId
  };
}

export function Occupation(model = {}) {
  return {
    id: model?.id,
    endDate: model?.endDate,
    occupation: model?.occupation,
    startDate: model?.startDate,
    employerId: model?.employerId,
    employerName: model?.employerName,
    employerAddress: new Address({
      ...model?.employerAddress,
      address_type: "business_address"
    }),
    employeeId: model?.employeeId, //used during creation
    employeeType: model?.employeeType //used during creation
  };
}

export function setOccupationFromRaw(model) {
  const occupation = Occupation();
  occupation.id = model?.id;
  occupation.endDate = model?.end_date;
  occupation.occupation = model?.occupation;
  occupation.startDate = model?.start_date;
  occupation.employerName = model?.employer?.name;
  occupation.employerId = model?.employer?.id;

  if (model?.employer?.addresses?.length) {
    const rawAddress = model.employer.addresses.find(
      a => a.address_type === "business_address"
    );
    if (rawAddress) {
      occupation.employerAddress = setAddressFromRaw(rawAddress);
    }
  }
  return occupation;
}

export function setRawCreateFromOccupation(occupation) {
  return {
    employer_attributes: {
      name: occupation.employerName,
      addresses_attributes: [
        {
          street_address: occupation.employerAddress.street_address,
          city: occupation.employerAddress.city,
          state: occupation.employerAddress.state,
          zip: occupation.employerAddress.zip,
          address_type: "business_address"
        }
      ],
      employer_type: "Entity"
    },
    end_date: occupation.endDate || null,
    start_date: occupation.startDate,
    occupation: occupation.occupation,
    employee_id: occupation.employeeId,
    employee_type: occupation.employeeType
  };
}

export function setRawUpdateFromOccupation(occupation) {
  return {
    employer_attributes: {
      id: occupation.employerId,
      name: occupation.employerName,
      addresses_attributes: [
        {
          id: occupation.employerAddress.id,
          street_address: occupation.employerAddress.street_address,
          city: occupation.employerAddress.city,
          state: occupation.employerAddress.state,
          zip: occupation.employerAddress.zip,
          address_type: "business_address"
        }
      ]
    },
    end_date: occupation.endDate || null,
    start_date: occupation.startDate,
    occupation: occupation.occupation,
    employee_id: occupation.employeeId,
    employee_type: occupation.employeeType
  };
}
