<template>
  <v-card>
    <div style="position: relative">
      <app-button
        v-if="policy?.id"
        style="position: absolute; top: 15px; right: 15px; z-index: 2"
        :icon="mdiRefresh"
        variant="text"
        data-testid="case-data-refresh"
        :loading="fetchingPolicy"
        @click="emit('refresh')"
      />
    </div>
    <v-card-text v-if="policy?.id" class="px-0">
      <div class="py-3">
        <v-img :src="avatar" contain height="100" />
      </div>
      <!-- <v-divider /> -->
      <template v-for="(group, index) in detailGroups" :key="group.key">
        <card-items :items="group.items" xl="6" lg="6" md="6" />
        <v-divider
          v-if="index < detailGroups.length - 1"
          :key="group.key + '-divider'"
        />
      </template>
    </v-card-text>
    <v-card-subtitle v-else class="pa-3">
      Add a Policy Number to see Policy data here
    </v-card-subtitle>
  </v-card>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";

import {
  currencyFormat,
  getRouterLink,
  modeFormat,
  timestampFormatter
} from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useTransactionCreate } from "@/stores/transaction-create";
import { computed } from "vue";
import {
  routerLinkItem,
  routerLinkItemList,
  textItem
} from "@/components/shared/card-items/card-items";
import { mdiRefresh } from "@mdi/js";
const emit = defineEmits(["refresh"]);
const transactionCreateStore = useTransactionCreate();

const { totalForecast, transaction, finalOverride, fetchingPolicy } =
  storeToRefs(transactionCreateStore);
const commissionType = computed(() => transaction.value.commissionType);
const statement = computed(() => transaction.value.statement);
const policy = computed(() => transaction.value.policy);

const avatar = computed(() => {
  if (!policy.value?.id) return null;
  return policy.value.product.carrier.avatarUrl;
});

const detailGroups = computed(() => {
  const groups = [];
  if (overrideItems.value.length) {
    groups.push({ key: "override", items: overrideItems.value });
  }
  if (caseItems.value.length) {
    groups.push({ key: "case-items", items: caseItems.value });
  }
  if (currencyItems.value.length) {
    groups.push({ key: "currency-items", items: currencyItems.value });
  }
  return groups;
});

const caseItems = computed(() => {
  const items = [];

  if (statement.value?.payor) {
    items.push(
      routerLinkItem({
        title: "Statement",
        text: `${statement.value.payor.name} ${timestampFormatter(
          statement.value.statementDate,
          "sole-day"
        )}`,
        to: {
          name: "StatementView",
          params: { id: statement.value.id }
        }
      })
    );
  }

  if (policy.value.policyNumber) {
    items.push(
      routerLinkItem({
        title: "Case",
        text: policy.value.policyNumber,
        to: getRouterLink("Case", policy.value.id)
      })
    );
  }

  if (policy.value.product?.name) {
    items.push(
      routerLinkItem({
        title: "Product",
        text: policy.value.product.name,
        to: {
          name: "ProductView",
          params: { id: policy.value.product.id }
        }
      })
    );
  }

  if (policy.value?.appointmentCases) {
    const links = [];
    policy.value.appointmentCases.forEach(appointmentCase => {
      if (appointmentCase.advisor.type !== "Agent") return;
      links.push({
        key: appointmentCase.advisor.id,
        to: getRouterLink("Agent", appointmentCase.advisor.id),
        text: appointmentCase.advisor.name
      });
    });

    if (links.length) {
      items.push(
        routerLinkItemList({
          title: links.length === 1 ? "Agent" : "Agents",
          links
        })
      );
    }
  }

  if (commissionType.value.includes("First Year") && policy.value?.product) {
    items.push(
      textItem({
        title: "First Year Total Commission",
        text: `${(policy.value.product.totalFirst * 100).toFixed(2)}%`
      })
    );
  }

  if (commissionType.value.includes("Renewal") && policy.value?.product) {
    items.push(
      textItem({
        title: "Renewal Total Commission",
        text: `${(policy.value.product.totalRenewal * 100).toFixed(2)}%`
      })
    );
  }

  if (commissionType.value.includes("Excess") && policy.value?.product) {
    items.push(
      textItem({
        title: "Excess Total Commission",
        text: `${(policy.value.product.totalExcess * 100).toFixed(2)}%`
      })
    );
  }

  if (commissionType.value.includes("PUA") && policy.value?.product) {
    items.push(
      textItem({
        title: "PUA Total Commission",
        text: `${(policy.value.product.totalPua * 100).toFixed(2)}%`
      })
    );
  }

  if (commissionType.value.includes("Term Rider") && policy.value?.product) {
    items.push(
      textItem({
        title: "Term Rider Total Commission",
        text: `${(policy.value.product.totalTermRider * 100).toFixed(2)}%`
      })
    );
  }

  if (commissionType.value.includes("Bonus") && policy.value?.product) {
    items.push(
      textItem({
        title: "Bonus Total Commission",
        text: `${(policy.value.product.totalBonus * 100).toFixed(2)}%`
      })
    );
  }

  if (policy.value.targetPremium) {
    items.push(
      textItem({
        title: "Target Premium",
        text: currencyFormat(policy.value.targetPremium, 2),
        type: "currency)"
      })
    );
  }

  if (policy.value.policyDate) {
    items.push(
      textItem({
        title: "Policy Date",
        text: timestampFormatter(policy.value.policyDate, "sole-day")
      })
    );
  } else if (policy.value) {
    items.push(
      textItem({
        title: "Policy Date",
        text: "Must Add Policy Date"
      })
    );
  }

  return items;
});

const currencyItems = computed(() => {
  const items = [];

  if (policy.value.premium && policy.value.mode) {
    items.push(
      textItem({
        title: "Premium",
        text: `${currencyFormat(policy.value.premium, 2)} (${modeFormat(
          policy.value.mode
        )})`
      })
    );
  }

  if (policy.value.firstYear) {
    items.push(
      textItem({
        title: "Total First Year Premium",
        text: currencyFormat(policy.value.firstYear, 2)
      })
    );
  }

  return items;
});

const overrideItems = computed(() => {
  const showTotalForecast = totalForecast.value || totalForecast.value === 0;
  const showFinalOverride = finalOverride.value || finalOverride.value === 0;
  const items = [];

  if (showTotalForecast)
    items.push(
      textItem({
        title: "Total Expected Override",
        text: currencyFormat(totalForecast.value, 2)
      })
    );
  if (showFinalOverride) {
    items.push({
      ...textItem({
        title: "Total Assumed Override",
        text: currencyFormat(finalOverride.value, 2)
      }),
      componentProps: { class: "section rounded" }
    });
  }

  return items;
});
</script>
