<template>
  <v-card color="section" flat tile>
    <v-row align="center" class="ma-0 pr-3">
      <v-card-title> Commissions </v-card-title>
      <v-spacer />
      <app-button
        v-if="user.isGroupFour"
        data-testid="add-payout"
        class="text-none"
        color="primary"
        @click="addPayout"
      >
        <v-icon class="mr-1" :icon="mdiPlus" /> Payout
      </app-button>
    </v-row>
    <v-data-table
      v-model:sort-by="table.options.value.sortBy"
      v-model:items-per-page="table.options.value.itemsPerPage"
      v-model:page="table.options.value.page"
      data-testid="product-commissions-table"
      class="transparent-data-table"
      :mobile="null"
      mobile-breakpoint="sm"
      :items="table.mappedItems.value"
      :headers="table.tableHeaders.value"
    >
      <template #[`item.percent`]="{ item }">
        <percentage-formatter :model-value="item.percent" />
      </template>

      <template #[`item.actions`]="{ item }">
        <app-button
          data-testid="edit-payout"
          :icon="mdiPencil"
          color="primary"
          variant="text"
          density="comfortable"
          @click="editPayout(item.additional)"
        />
        <app-button
          data-testid="remove-payout"
          :icon="mdiDelete"
          color="error"
          variant="text"
          density="comfortable"
          @click="removePayout(item.additional)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup>
import AddPayoutDialog from "@/components/products/dialogs/AddPayoutDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import { markRaw, watch } from "vue";

import { useUserStore } from "@/stores/user";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { deletePayout } from "@/api/payouts.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { useProductView } from "@/stores/product-view";
import { storeToRefs } from "pinia";
import { mdiPlus, mdiPencil, mdiDelete } from "@mdi/js";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const productView = useProductView();
const { payouts, name, payorOptions, id, carrier } = storeToRefs(productView);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Comp Builder",
      value: "compBuilder",
      map: "compBuilder.name",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Payor",
      value: "payor",
      map: "payor.name"
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType"
    }),
    new TableHeader({
      text: "Percent",
      value: "percent",
      map: "percent"
    }),
    new TableHeader({
      text: "Start Year",
      value: "startYear",
      map: "startYear"
    }),
    new TableHeader({
      text: "End Year",
      value: "endYear",
      map: "endYear"
    })
  ],
  options: TableOptions({ sortBy: [{ key: "compBuilder", order: "asc" }] })
});

if (user.isGroupFour) {
  table.headers.value.push(
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  );
}

function removePayout(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Remove payout from ${name.value}?`,
    subtitle: "This cannot be undone",
    func: async () => {
      await deletePayout(item.id);
      const index = payouts.value.findIndex(i => i.id === item.id);
      if (index === -1) return;
      payouts.value.splice(index, 1);
      snackbar.showSuccessSnackbar({
        message: "Payout has been deleted",
        timeout: 6000
      });
    }
  });
}

async function editPayout(item) {
  const res = await dialog.showDialog({
    component: markRaw(AddPayoutDialog),
    payors: payorOptions.value,
    productId: id.value,
    carrierId: carrier.value.id,
    modelValue: item
  });
  if (!res.payout) return;
  const index = payouts.value.findIndex(i => i.id === item.id);
  if (index === -1) return;
  payouts.value[index] = res.payout;
}
async function addPayout() {
  const res = await dialog.showDialog({
    component: markRaw(AddPayoutDialog),
    payors: payorOptions.value,
    productId: id.value,
    carrierId: carrier.value.id
  });

  if (!res.payout) return;
  payouts.value.push(res.payout);

  snackbar.showSuccessSnackbar({
    message: "Payout has been added.",
    timeout: 6000
  });
}

function setItems() {
  table.items.value.splice(0, table.items.value.length);
  table.items.value.push(...payouts.value);
}

watch(payouts, setItems, { immediate: true, deep: true });
</script>
