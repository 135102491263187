<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="application-versions-table"
    class="transparent-data-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5 pa-3">
          {{ props.title }}
        </h1>
        <v-spacer />
        <v-tooltip location="top">
          <template #activator="{ props: tooltipProps }">
            <app-button
              :icon="mdiRefresh"
              class="table-btn"
              variant="text"
              density="comfortable"
              v-bind="tooltipProps"
              data-testid="application-versions-table-refresh"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <v-divider />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.changedBy`]="{ item }">
      <span v-if="item.changedBy">
        {{ item.changedBy.email }}
      </span>
      <span v-else> System </span>
    </template>

    <template #[`item.changedObject`]="{ item }">
      <template v-if="item.changedObject">
        <router-link
          v-if="item.changedObject.routerLink"
          :to="item.changedObject.routerLink"
        >
          {{ item.changedObject.type }} #{{ item.changedObject.id }}
        </router-link>
        <span v-else>
          {{ item.changedObject.type }} #{{ item.changedObject.id }}
        </span>
      </template>
    </template>

    <template #[`item.changes`]="{ item }">
      <application-version-item :changes="item.changes" />
    </template>

    <template #[`item.createdAt`]="{ item }">
      {{ timestampFormatter(item.createdAt, null, "date-time") }}
    </template>
  </v-data-table-server>
</template>

<script setup>
import ApplicationVersionItem from "@/components/application-versions/ApplicationVersionItem.vue";

import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { TableOptions } from "@/classes/data-table/TableOptions";

import { mdiRefresh } from "@mdi/js";

import { parseErrorMessage, timestampFormatter } from "@/util/helpers";
import { getApplicationVersions } from "@/api/application-versions.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  changesById: {
    type: Number,
    required: false,
    default: null
  },
  changesByType: {
    type: String,
    required: false,
    default: null
  },
  changesToId: {
    type: Number,
    required: false,
    default: null
  },
  changesToType: {
    type: String,
    required: false,
    default: null
  }
});

if (
  !(props.changesById && props.changesByType) &&
  !(props.changesToId && props.changesToType)
) {
  throw new Error(
    "Either changesById and changesByType or changesToId and changesToType must be provided"
  );
}

const changesBy = props.changesById && props.changesByType;

const snackbar = useSnackbarStore();

const table = useTable({
  shouldIncludeCancelToken: true,
  getData: getApplicationVersions,
  options: TableOptions({ sortBy: [{ key: "createdAt", order: "desc" }] }),
  headers: [
    new TableHeader({
      text: "Created At",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "created_at",
      width: "200px",
      ...TableHeader.IS_SORTABLE
    }),
    changesBy
      ? null
      : new TableHeader({
          text: "Changed By",
          value: "changedBy",
          map: "changedBy",
          sortFilterMap: "changedBy",
          width: "200px"
        }),
    changesBy
      ? new TableHeader({
          text: "Changed Object",
          value: "changedObject",
          map: "changedObject",
          width: "200px"
        })
      : null,

    new TableHeader({
      text: "Changes",
      value: "changes",
      map: "changes",
      sortFilterMap: "changes"
    })
  ].filter(Boolean)
});

async function getData() {
  try {
    const additionalFilter = {};
    if (changesBy) {
      additionalFilter.changes_by_id = props.changesById;
      additionalFilter.changes_by_type = props.changesByType;
    } else {
      additionalFilter.changes_to_id = props.changesToId;
      additionalFilter.changes_to_type = props.changesToType;
    }
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}
</script>
