import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/category_states";

export const searchForCategoryStates = async ({
  carrier_id,
  category,
  form_id
}) => {
  const params = new URLSearchParams();
  if (carrier_id) params.append("carrier_id", carrier_id);
  if (category) params.append("category", category);
  if (form_id) params.append("form_id", form_id);

  const { data } = await getHttpClient().get(`${baseUrl}/search`, {
    params
  });
  return data;
};

function CategoryStateTableItem(model = {}) {
  return {
    id: model?.id,
    formName: model?.formName,
    formId: model?.formId,
    stateName: model?.stateName,
    category: model?.category,
    carrierName: model?.carrierName,
    formSetName: model?.formSetName,
    status: model?.status
  };
}

function setCategoryStateTableItemFromRequest(req = {}) {
  const cs = CategoryStateTableItem();

  cs.id = req.id;
  cs.status = req.status;
  cs.category = req.category;

  if (req.form_set?.id) {
    cs.formSetName = req.form_set.name;
  }

  if (req.carrier?.id) {
    cs.carrierName = req.carrier.name;
  }

  if (req.state?.name) {
    cs.stateName = req.state.name;
  }

  if (req.form?.id) {
    cs.formName = req.form.name;
    cs.formId = req.form.id;
  }

  return cs;
}

export async function getCategoryStates(params) {
  const { data } = await getHttpClient().get(baseUrl, { params });
  return {
    items: data.category_states.map(setCategoryStateTableItemFromRequest),
    meta: data.meta
  };
}

export async function updateCategoryState(categoryState) {
  return getHttpClient().put(`${baseUrl}/${categoryState.id}`, {
    category_state: {
      status: categoryState.status
    }
  });
}

export const updateIndividualCategoryState = (stateId, category_state) => {
  return getHttpClient().put(`${baseUrl}/${stateId}`, {
    category_state
  });
};
