<template>
  <v-row dense class="bg-section">
    <v-col cols="12">
      <v-card flat tile color="section">
        <v-card-title class="text-h6">
          {{ typeLabel }} General Information
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12">
              <text-field
                v-model="website"
                data-lpignore="true"
                data-testid="website"
                :prepend-inner-icon="mdiCalendarMonth"
                :disabled="isGroupOne"
                :label="`${typeLabel} Website`"
                :success="Boolean(website)"
                @update:model-value="updateWebsite"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.website.controller.value"
                  />
                </template>
              </text-field>
            </v-col>
            <v-col cols="12">
              <textarea-field
                v-model="details"
                label="Notes"
                auto-grow
                data-testid="notes"
                :prepend-inner-icon="mdiText"
                :disabled="isGroupOne"
                :success="Boolean(details)"
                @update:model-value="updateDetails"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.details.controller.value"
                  />
                </template>
              </textarea-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile color="section">
        <v-card-title class="text-h6">
          {{ typeLabel }} New Business
        </v-card-title>
        <v-card-subtitle v-if="newAppPersonnel?.id || newBusinessPersonnel?.id">
          <v-row dense>
            <v-col
              v-if="newAppPersonnel?.id"
              data-testid="new-application-email"
              cols="12"
              md="4"
            >
              <span class="text-grey lighten-2">New Application Email</span>
              <br />
              <a :href="`mailto:${newAppPersonnel.email}`">
                {{ newAppPersonnel.email }}
              </a>
            </v-col>
            <v-col
              v-if="newBusinessPersonnel?.id"
              data-testid="new-business-email"
              cols="12"
              md="4"
            >
              <span class="text-grey lighten-2">New Business Email</span>
              <br />
              <a :href="`mailto:${newBusinessPersonnel.email}`">
                {{ newBusinessPersonnel.email }}
              </a>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <personnel-search
                v-model="newAppPersonnel"
                clearable
                data-testid="new-application-personnel"
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} New Application Personnel`"
                :placeholder="`Search ${typeLabel} New Application Personnel`"
                :carrier-id="carrierId"
                :success="Boolean(newAppPersonnel?.id)"
                @update:model-value="updateNewApplicationPersonnel"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.newAppPersonnel.controller.value"
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="6">
              <personnel-search
                v-model="newBusinessPersonnel"
                clearable
                data-testid="new-business-personnel"
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} New Business Personnel`"
                :placeholder="`Search ${typeLabel} New Business Personnel`"
                :carrier-id="carrierId"
                :success="Boolean(newBusinessPersonnel?.id)"
                @update:model-value="updateNewBusinessPersonnel"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="
                      savingBuffer.newBusinessPersonnel.controller.value
                    "
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                v-model="newBusinessPhone"
                data-lpignore="true"
                data-testid="new-business-phone"
                :label="`${typeLabel} New Business Phone`"
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiDeskphone"
                :success="Boolean(newBusinessPhone)"
                @update:model-value="updatePhoneNumber('newBusinessPhone')"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.newBusinessPhone.controller.value"
                  />
                </template>
              </text-field>
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                v-model="newBusinessFax"
                data-lpignore="true"
                data-testid="new-business-fax"
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiDeskphone"
                :label="`${typeLabel} New Business Fax`"
                :success="Boolean(newBusinessFax)"
                @update:model-value="updatePhoneNumber('newBusinessFax')"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.newBusinessFax.controller.value"
                  />
                </template>
              </text-field>
            </v-col>
            <v-col cols="12">
              <basic-address-input
                v-model:street-address-model="newBusinessAddress.street_address"
                v-model:city-model="newBusinessAddress.city"
                v-model:state-model="newBusinessAddress.state"
                v-model:zip-model="newBusinessAddress.zip"
                autofill-placeholder="New Business Address"
                autofill-label="New Business Address"
                data-testid="new-business-address"
                :disabled="isGroupOne"
                :street-address-validation="streetAddressValidation"
                :city-validation="cityValidation"
                :state-validation="stateValidation"
                :zip-validation="zipValidation"
                @update:street-address-model="updateNewBusinessStreet"
                @update:city-model="updateNewBusinessCity"
                @update:state-model="updateNewBusinessState"
                @update:zip-model="updateNewBusinessZip"
                @autofill="handleAutofill"
              >
                <template #append-inner-street-address>
                  <active-save-indicator
                    :controller="
                      savingBuffer.newBusinessStreet.controller.value
                    "
                  />
                </template>
                <template #append-inner-city>
                  <active-save-indicator
                    :controller="savingBuffer.newBusinessCity.controller.value"
                  />
                </template>
                <template #append-inner-state>
                  <active-save-indicator
                    :controller="savingBuffer.newBusinessState.controller.value"
                  />
                </template>
                <template #append-inner-zip>
                  <active-save-indicator
                    :controller="savingBuffer.newBusinessZip.controller.value"
                  />
                </template>
              </basic-address-input>
            </v-col>
            <v-col cols="12">
              <textarea-field
                v-model="eSignature"
                data-testid="esignature"
                :prepend-inner-icon="mdiDraw"
                :disabled="isGroupOne"
                :success="Boolean(eSignature)"
                @update:model-value="updateESignature"
              >
                <template #label>
                  {{ typeLabel }} eSignature
                  <active-save-indicator
                    class="ml-2"
                    :controller="savingBuffer.eSignature.controller.value"
                  />
                </template>
              </textarea-field>
            </v-col>
            <v-col cols="12" md="4">
              <div class="checkbox-width" data-testid="auto-submit">
                <checkbox-field
                  :key="autoSubmitKey"
                  v-model="autoSubmit"
                  class="mt-1"
                  :disabled="!isGroupThreePlus"
                  :success="Boolean(autoSubmit)"
                >
                  <template #label> {{ typeLabel }} Auto Submit </template>
                </checkbox-field>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div
                class="checkbox-width"
                data-testid="requires-original-application"
              >
                <checkbox-field
                  v-model="requiresOriginalApplication"
                  class="mt-1"
                  true-value="Yes"
                  false-value="No"
                  success
                  :disabled="!isGroupThreePlus"
                  @update:model-value="updateRequiresOriginalApplication"
                >
                  <template #label>
                    {{ typeLabel }} Requires Original Application
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.requiresOriginalApplication.controller
                          .value
                      "
                    />
                  </template>
                </checkbox-field>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="checkbox-width" data-testid="edelivery">
                <checkbox-field
                  v-model="eDelivery"
                  class="mt-1"
                  success
                  :disabled="!isGroupThreePlus"
                  @update:model-value="updateEDelivery"
                >
                  <template #label>
                    {{ typeLabel }} eDelivery
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.eDelivery.controller.value"
                    />
                  </template>
                </checkbox-field>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="checkbox-width" data-testid="ga-can-order-aps">
                <checkbox-field
                  v-model="gaCanOrderAps"
                  class="mt-1"
                  success
                  :disabled="!isGroupThreePlus"
                  @update:model-value="updateGACanOrderAps"
                >
                  <template #label>
                    BackNine can order APS for {{ typeLabel }}
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.gaCanOrderAps.controller.value"
                    />
                  </template>
                </checkbox-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile color="section">
        <v-card-title class="text-h6">
          {{ typeLabel }} Customer Service
        </v-card-title>
        <v-card-subtitle v-if="customerServicePersonnel?.id">
          <v-row dense>
            <v-col cols="12" md="4" data-testid="customer-service-email">
              <span class="text-grey lighten-2">Customer Service Email</span>
              <br />
              <a :href="`mailto:${customerServicePersonnel.email}`">
                {{ customerServicePersonnel.email }}
              </a>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4">
              <personnel-search
                v-model="customerServicePersonnel"
                clearable
                data-testid="customer-service-personnel"
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} Customer Service Personnel`"
                :placeholder="`Search ${typeLabel} Customer Service Personnel`"
                :carrier-id="carrierId"
                :success="Boolean(customerServicePersonnel?.id)"
                @update:model-value="updateCustomerServicePersonnel"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="
                      savingBuffer.customerServicePersonnel.controller.value
                    "
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="4">
              <text-field
                v-model="customerServicePhone"
                data-lpignore="true"
                data-testid="customer-service-phone"
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiMonitorCellphone"
                :label="`${typeLabel} Customer Service Desk`"
                :success="Boolean(customerServicePhone)"
                @update:model-value="updatePhoneNumber('customerServicePhone')"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="
                      savingBuffer.customerServicePhone.controller.value
                    "
                  />
                </template>
              </text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile color="section">
        <v-card-title class="text-h6">
          {{ typeLabel }} Commissions and Licensing
        </v-card-title>
        <v-card-subtitle v-if="licensingPersonnel?.id">
          <v-row dense>
            <v-col cols="12" md="4" data-testid="licensing-email">
              <span class="text-grey lighten-2">Licensing Email</span>
              <br />
              <a :href="`mailto:${licensingPersonnel.email}`">
                {{ licensingPersonnel.email }}
              </a>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <personnel-search
                v-model="licensingPersonnel"
                clearable
                data-testid="licensing-personnel"
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} Licensing Personnel`"
                :placeholder="`Search ${typeLabel} Licensing Personnel`"
                :carrier-id="carrierId"
                :success="Boolean(licensingPersonnel?.id)"
                @update:model-value="updateLicensingPersonnel"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="
                      savingBuffer.licensingPersonnel.controller.value
                    "
                  />
                </template>
              </personnel-search>
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                v-model="licensingPhone"
                data-lpignore="true"
                data-testid="licensing-phone"
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiCardAccountPhone"
                :label="`${typeLabel} Licensing Phone`"
                :success="Boolean(licensingPhone)"
                @update:model-value="updatePhoneNumber('licensingPhone')"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.licensingPhone.controller.value"
                  />
                </template>
              </text-field>
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                v-model="multipleAppointments"
                data-lpignore="true"
                data-testid="multiple-appointments"
                :prepend-inner-icon="mdiCalendarMonth"
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} Multiple Appointments`"
                :success="Boolean(multipleAppointments)"
                @update:model-value="updateMultipleAppointments"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="
                      savingBuffer.multipleAppointments.controller.value
                    "
                  />
                </template>
              </text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="checkbox-width" data-testid="annualization">
                <checkbox-field
                  v-model="annualization"
                  class="mt-1"
                  hide-details
                  success
                  true-value="true"
                  false-value="false"
                  :disabled="!isGroupThreePlus"
                  @update:model-value="updateAnnualization"
                >
                  <template #label>
                    {{ typeLabel }} Annualization
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.annualization.controller.value"
                    />
                  </template>
                </checkbox-field>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <text-field
                v-model="annualizationDetails"
                data-lpignore="true"
                data-testid="annualization-details"
                :disabled="!isGroupThreePlus"
                :prepend-inner-icon="mdiAccountDetails"
                :label="`${typeLabel} Annualization Details`"
                :success="Boolean(annualizationDetails)"
                @update:model-value="updateAnnualizationDetails"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="
                      savingBuffer.annualizationDetails.controller.value
                    "
                  />
                </template>
              </text-field>
            </v-col>

            <v-col cols="12" md="6">
              <div class="checkbox-width" data-testid="pays-full-override">
                <checkbox-field
                  v-model="paysFullOverride"
                  class="mt-1"
                  success
                  :disabled="!isGroupThreePlus"
                  @update:model-value="updatePaysFullOverride"
                >
                  <template #label>
                    {{ typeLabel }} Pays Full Override Direct
                    <active-save-indicator
                      class="ml-2"
                      :controller="
                        savingBuffer.paysFullOverride.controller.value
                      "
                    />
                  </template>
                </checkbox-field>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <select-field
                v-model="paymentSchedule"
                item-value="text"
                data-testid="payment-schedule"
                :prepend-inner-icon="mdiClock"
                :success="Boolean(paymentSchedule)"
                :disabled="!isGroupThreePlus"
                :label="`${typeLabel} BackNine Payment Schedule`"
                :items="PAYMENT_SCHEDULES"
                @update:model-value="updatePaymentSchedule"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.paymentSchedule.controller.value"
                  />
                </template>
              </select-field>
            </v-col>

            <v-col v-if="showTraining" cols="12">
              <textarea-field
                v-model="training"
                data-testid="training"
                :prepend-inner-icon="mdiSchool"
                :disabled="isGroupOne"
                :label="`${typeLabel} Product Training `"
                :success="Boolean(training)"
                @update:model-value="updateTraining"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.training.controller.value"
                  />
                </template>
              </textarea-field>
            </v-col>

            <v-col v-if="showReg187Training" cols="12">
              <textarea-field
                v-model="reg187Training"
                data-testid="reg-187-training"
                :label="`${typeLabel} Reg 187 Training`"
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiSchool"
                :success="Boolean(reg187Training)"
                @update:model-value="updateReg187Training"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.reg187Training.controller.value"
                  />
                </template>
              </textarea-field>
            </v-col>

            <v-col cols="12">
              <textarea-field
                v-model="sendContracting"
                data-lpignore="true"
                data-testid="send-contracting"
                :disabled="isGroupOne"
                :label="`${typeLabel} Licensing Instructions`"
                :prepend-inner-icon="mdiFileDocumentOutline"
                :success="Boolean(sendContracting)"
                @update:model-value="updateSendContracting"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.sendContracting.controller.value"
                  />
                </template>
              </textarea-field>
            </v-col>

            <v-col v-if="isGroupThreePlus" cols="12">
              <h3>
                Pre-Appointment States
                <app-button
                  :icon="editablePreappointmentStates ? mdiLock : mdiPencil"
                  color="accent"
                  variant="text"
                  density="comfortable"
                  data-testid="preappointment-states-edit-toggle"
                  @click="
                    editablePreappointmentStates = !editablePreappointmentStates
                  "
                />
                <active-save-indicator
                  :controller="
                    savingBuffer.preappointmentStates.controller.value
                  "
                />
              </h3>

              <v-chip-group
                v-model="preappointmentStates"
                column
                multiple
                selected-class="bg-primary"
                @update:model-value="updatePreappointmentStates"
              >
                <v-chip
                  v-for="state in STATES"
                  :key="state.value"
                  :disabled="!editablePreappointmentStates"
                  :data-testid="`preappointment-state-${state.value}`"
                  :class="{
                    primary: inPreAppointmentStates(state.value)
                  }"
                  :value="state.value"
                  :text="state.title"
                />
              </v-chip-group>
            </v-col>
            <v-col v-else cols="12">
              <h3>Pre-Appointment States</h3>
              <p data-testid="preappointment-state-text">
                {{ preappointmentStatesText }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>

    <v-col cols="12">
      <v-card flat tile color="section">
        <v-card-title class="text-h6">
          Additional {{ typeLabel }} Information
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <text-field
                v-model="salesDeskPhone"
                data-lpignore="true"
                data-testid="sales-desk-phone"
                :disabled="isGroupOne"
                :prepend-inner-icon="mdiCellphoneBasic"
                :label="`${typeLabel} Sales Desk`"
                :success="Boolean(salesDeskPhone)"
                @update:model-value="updatePhoneNumber('salesDeskPhone')"
              >
                <template #append-inner>
                  <active-save-indicator
                    :controller="savingBuffer.salesDeskPhone.controller.value"
                  />
                </template>
              </text-field>
            </v-col>

            <v-col cols="12" md="6" @click="showChangeQuickQuoteEmailDialog">
              <text-field
                v-model="quickQuoteEmail"
                readonly
                data-testid="quick-quote-email"
                :prepend-inner-icon="mdiMail"
                :label="`${typeLabel} Quick Quote Email`"
                :disabled="isGroupOne"
                :success="Boolean(quickQuoteEmail)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import ChangeEmailDialog from "@/dialogs/ChangeEmailDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import BasicAddressInput from "@/components/shared/BasicAddressInput.vue";
import PersonnelSearch from "@/components/shared/PersonnelSearch.vue";

import { LINE, LINE_TEXT } from "@/factories/Carrier";
import { fullStateDict, STATES } from "@/data/states";
import { listToSentence } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useCarrierView } from "@/stores/carrier-view";

import { computed, markRaw, ref } from "vue";
import { useActiveSave } from "@/composables/active-save.composable";
import {
  mdiCalendarMonth,
  mdiText,
  mdiDeskphone,
  mdiDraw,
  mdiMonitorCellphone,
  mdiCardAccountPhone,
  mdiAccountDetails,
  mdiClock,
  mdiSchool,
  mdiFileDocumentOutline,
  mdiCellphoneBasic,
  mdiMail,
  mdiLock,
  mdiPencil
} from "@mdi/js";

const PAYMENT_SCHEDULES = ["Standard", "Express"];

const props = defineProps({ line: { type: String, required: true } });

const user = useUserStore();
const carrierView = useCarrierView();
const dialog = useDialogStore();

const { isGroupOne, isGroupThreePlus } = storeToRefs(user);

const {
  name: carrierName,
  id: carrierId,
  detailsByLine,
  sendContractingByLine,
  multipleAppointmentsByLine,
  autoSubmitByLine,
  gaCanOrderApsByLine,
  licensingPersonnelByLine,
  newAppPersonnelByLine,
  newBusinessPersonnelByLine,
  customerServicePersonnelByLine,
  emailsByLine,
  requiresOriginalApplicationByLine,
  annualizationByLine,
  annualizationDetailsByLine,
  eDeliveryByLine,
  eSignatureByLine,
  paysFullOverrideByLine,
  paymentScheduleByLine,
  preappointmentStatesByLine,
  websitesByLine,
  reg187TrainingByLine,
  trainingByLine,
  newBusinessAddressByLine,
  customerServicePhoneByLine,
  salesDeskPhoneByLine,
  licensingPhoneByLine,
  newBusinessFaxByLine,
  newBusinessPhoneByLine
} = storeToRefs(carrierView);

const typeLabel = LINE_TEXT[props.line];

const autoSubmitKey = ref(new Date().getTime());
const editablePreappointmentStates = ref(false);

const preappointmentStatesText = computed(() => {
  if (preappointmentStates.value.length === 0) {
    return `${carrierName.value} has no ${typeLabel} pre-appointment states.`;
  }

  const text = listToSentence(
    preappointmentStates.value.map(v => fullStateDict[v])
  );
  if (preappointmentStates.value.length === 1) {
    return `${carrierName.value}'s only ${typeLabel} pre-appointment state is ${text}.`;
  }

  return `${carrierName.value}'s ${typeLabel} pre-appointment states are ${text}.`;
});
const details = computed({
  get: () => detailsByLine.value[props.line],
  set: v => (detailsByLine.value[props.line] = v)
});
const licensingPhone = computed({
  get: () => licensingPhoneByLine.value[props.line],
  set: v => (licensingPhoneByLine.value[props.line] = v)
});
const newBusinessPhone = computed({
  get: () => newBusinessPhoneByLine.value[props.line],
  set: v => (newBusinessPhoneByLine.value[props.line] = v)
});
const newBusinessFax = computed({
  get: () => newBusinessFaxByLine.value[props.line],
  set: v => (newBusinessFaxByLine.value[props.line] = v)
});
const salesDeskPhone = computed({
  get: () => salesDeskPhoneByLine.value[props.line],
  set: v => (salesDeskPhoneByLine.value[props.line] = v)
});
const customerServicePhone = computed({
  get: () => customerServicePhoneByLine.value[props.line],
  set: v => (customerServicePhoneByLine.value[props.line] = v)
});
const sendContracting = computed({
  get: () => sendContractingByLine.value[props.line],
  set: v => (sendContractingByLine.value[props.line] = v)
});
const multipleAppointments = computed({
  get: () => multipleAppointmentsByLine.value[props.line],
  set: v => (multipleAppointmentsByLine.value[props.line] = v)
});

const autoSubmit = computed({
  get: () => autoSubmitByLine.value[props.line],
  set: v => {
    const pretext = v ? "Enable" : "Disable";
    dialog
      .showDialog({
        component: markRaw(ConfirmationDialog),
        title: `${pretext} ${carrierName.value} Auto Submission`,
        subtitle: "Please confirm your intent to change auto submission.",
        func: async () => {
          autoSubmitByLine.value[props.line] = v;
          await carrierView.updateAutoSubmit();
        }
      })
      .then(() => (autoSubmitKey.value = new Date().getTime()));
  }
});

const gaCanOrderAps = computed({
  get: () => gaCanOrderApsByLine.value[props.line],
  set: v => (gaCanOrderApsByLine.value[props.line] = v)
});

const licensingPersonnel = computed({
  get: () => licensingPersonnelByLine.value[props.line],
  set: v => (licensingPersonnelByLine.value[props.line] = v)
});

const newAppPersonnel = computed({
  get: () => newAppPersonnelByLine.value[props.line],
  set: v => (newAppPersonnelByLine.value[props.line] = v)
});

const newBusinessPersonnel = computed({
  get: () => newBusinessPersonnelByLine.value[props.line],
  set: v => (newBusinessPersonnelByLine.value[props.line] = v)
});

const customerServicePersonnel = computed({
  get: () => customerServicePersonnelByLine.value[props.line],
  set: v => (customerServicePersonnelByLine.value[props.line] = v)
});

const quickQuoteEmail = computed({
  get: () => emailsByLine.value[props.line].quickQuote,
  set: v => (emailsByLine.value[props.line].quickQuote = v)
});

const requiresOriginalApplication = computed({
  get: () => requiresOriginalApplicationByLine.value[props.line],
  set: v => (requiresOriginalApplicationByLine.value[props.line] = v)
});

const annualization = computed({
  get: () => annualizationByLine.value[props.line],
  set: v => (annualizationByLine.value[props.line] = v)
});

const annualizationDetails = computed({
  get: () => annualizationDetailsByLine.value[props.line],
  set: v => (annualizationDetailsByLine.value[props.line] = v)
});

const eDelivery = computed({
  get: () => eDeliveryByLine.value[props.line],
  set: v => (eDeliveryByLine.value[props.line] = v)
});

const paysFullOverride = computed({
  get: () => paysFullOverrideByLine.value[props.line],
  set: v => (paysFullOverrideByLine.value[props.line] = v)
});

const paymentSchedule = computed({
  get: () => paymentScheduleByLine.value[props.line],
  set: v => (paymentScheduleByLine.value[props.line] = v)
});

const eSignature = computed({
  get: () => eSignatureByLine.value[props.line],
  set: v => (eSignatureByLine.value[props.line] = v)
});

const preappointmentStates = computed({
  get: () => preappointmentStatesByLine.value[props.line] || [],
  set: v => (preappointmentStatesByLine.value[props.line] = v)
});

const website = computed({
  get: () => websitesByLine.value[props.line],
  set: v => (websitesByLine.value[props.line] = v)
});

const showReg187Training =
  props.line === LINE.LIFE || props.line === LINE.ANNUITY;
const reg187Training = computed({
  get: () => reg187TrainingByLine.value[props.line],
  set: v => (reg187TrainingByLine.value[props.line] = v)
});

const showTraining = props.line === LINE.ANNUITY;
const training = computed({
  get: () => trainingByLine.value[props.line],
  set: v => (trainingByLine.value[props.line] = v)
});

const newBusinessAddress = computed({
  get: () => newBusinessAddressByLine.value[props.line],
  set: v => (newBusinessAddressByLine.value[props.line] = v)
});

const savingBuffer = {
  details: useActiveSave(),
  licensingPhone: useActiveSave(),
  newBusinessPhone: useActiveSave(),
  newBusinessFax: useActiveSave(),
  salesDeskPhone: useActiveSave(),
  customerServicePhone: useActiveSave(),
  sendContracting: useActiveSave(),
  multipleAppointments: useActiveSave(),
  gaCanOrderAps: useActiveSave(),
  licensingPersonnel: useActiveSave(),
  newAppPersonnel: useActiveSave(),
  newBusinessPersonnel: useActiveSave(),
  customerServicePersonnel: useActiveSave(),
  quickQuoteEmail: useActiveSave(),
  requiresOriginal: useActiveSave(),
  annualization: useActiveSave(),
  annualizationDetails: useActiveSave(),
  eDelivery: useActiveSave(),
  paysFullOverride: useActiveSave(),
  paymentSchedule: useActiveSave(),
  eSignature: useActiveSave(),
  preappointmentStates: useActiveSave(),
  requiresOriginalApplication: useActiveSave(),
  website: useActiveSave(),
  reg187Training: useActiveSave(),
  training: useActiveSave(),
  newBusinessStreet: useActiveSave(),
  newBusinessCity: useActiveSave(),
  newBusinessState: useActiveSave(),
  newBusinessZip: useActiveSave()
};

const streetAddressValidation = computed(() => {
  let success = null;
  if (newBusinessAddress.value?.street_address) success = true;

  return { success, errorMessages: [] };
});
const cityValidation = computed(() => {
  let success = null;
  if (newBusinessAddress.value.city) success = true;

  return { success, errorMessages: [] };
});
const stateValidation = computed(() => {
  let success = null;
  if (newBusinessAddress.value.state) success = true;

  return { success, errorMessages: [] };
});
const zipValidation = computed(() => {
  let success = null;
  if (newBusinessAddress.value.zip) success = true;

  return { success, errorMessages: [] };
});

function showChangeQuickQuoteEmailDialog() {
  if (isGroupOne.value) return;
  dialog.showDialog({
    component: markRaw(ChangeEmailDialog),
    clearable: true,
    value: quickQuoteEmail.value || "",
    storeFunc: v => (quickQuoteEmail.value = v),
    saveFunc: carrierView.updateEmails
  });
}

function handleAutofill({ city, state, street_address, zip }) {
  newBusinessAddress.value.city = city;
  newBusinessAddress.value.state = state;
  newBusinessAddress.value.street_address = street_address;
  newBusinessAddress.value.zip = zip;
  return carrierView.updateNewBusinessAddressByLine(props.line);
}

function updateNewBusinessStreet() {
  savingBuffer.newBusinessStreet.debounceUpdate(() =>
    carrierView.updateNewBusinessAddressByLine(props.line)
  );
}
function updateNewBusinessCity() {
  savingBuffer.newBusinessCity.debounceUpdate(() =>
    carrierView.updateNewBusinessAddressByLine(props.line)
  );
}
function updateNewBusinessState() {
  savingBuffer.newBusinessState.debounceUpdate(() =>
    carrierView.updateNewBusinessAddressByLine(props.line)
  );
}
function updateNewBusinessZip() {
  savingBuffer.newBusinessZip.debounceUpdate(() =>
    carrierView.updateNewBusinessAddressByLine(props.line)
  );
}

function inPreAppointmentStates(state) {
  if (!preappointmentStates.value) return false;
  return preappointmentStates.value.includes(state);
}

function updateWebsite() {
  savingBuffer.website.debounceUpdate(carrierView.updateWebsites);
}

function updateDetails() {
  savingBuffer.details.debounceUpdate(() =>
    carrierView.updateDetailsByLine(props.line)
  );
}

function updateNewApplicationPersonnel() {
  savingBuffer.newAppPersonnel.debounceUpdate(() =>
    carrierView.updateNewApplicationPersonnelByLine(props.line)
  );
}

function updateNewBusinessPersonnel() {
  savingBuffer.newBusinessPersonnel.debounceUpdate(() =>
    carrierView.updateNewBusinessPersonnelByLine(props.line)
  );
}

function updateCustomerServicePersonnel() {
  savingBuffer.customerServicePersonnel.debounceUpdate(() =>
    carrierView.updateCustomerServicePersonnelByLine(props.line)
  );
}

function updateLicensingPersonnel() {
  savingBuffer.licensingPersonnel.debounceUpdate(() =>
    carrierView.updateLicensingPersonnelByLine(props.line)
  );
}

function updateESignature() {
  savingBuffer.eSignature.debounceUpdate(carrierView.updateESignature);
}

function updateRequiresOriginalApplication() {
  savingBuffer.requiresOriginalApplication.debounceUpdate(
    carrierView.updateRequiresOriginalApplication
  );
}

function updateEDelivery() {
  savingBuffer.eDelivery.debounceUpdate(carrierView.updateEDelivery);
}

function updateGACanOrderAps() {
  savingBuffer.gaCanOrderAps.debounceUpdate(carrierView.updateGACanOrderAps);
}

function updateMultipleAppointments() {
  savingBuffer.multipleAppointments.debounceUpdate(
    carrierView.updateMultipleAppointments
  );
}

function updateAnnualization() {
  savingBuffer.annualization.debounceUpdate(carrierView.updateAnnualization);
}

function updateAnnualizationDetails() {
  savingBuffer.annualizationDetails.debounceUpdate(
    carrierView.updateAnnualizationDetails
  );
}

function updatePaysFullOverride() {
  savingBuffer.paysFullOverride.debounceUpdate(
    carrierView.updatePaysFullOverride
  );
}

function updatePaymentSchedule() {
  savingBuffer.paymentSchedule.debounceUpdate(
    carrierView.updatePaymentSchedule
  );
}

function updateTraining() {
  savingBuffer.training.debounceUpdate(() =>
    carrierView.updateTrainingByLine(props.line)
  );
}

function updateReg187Training() {
  savingBuffer.reg187Training.debounceUpdate(() =>
    carrierView.updateReg187TrainingByLine(props.line)
  );
}

function updateSendContracting() {
  savingBuffer.sendContracting.debounceUpdate(
    carrierView.updateSendContracting
  );
}

function updatePreappointmentStates() {
  savingBuffer.preappointmentStates.debounceUpdate(
    carrierView.updatePreappointmentStates
  );
}

function updatePhoneNumber(attribute) {
  savingBuffer[attribute].debounceUpdate(() =>
    carrierView.updatePhoneNumberByLine(props.line)
  );
}
</script>
