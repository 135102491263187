<template>
  <v-sheet
    id="auth-background"
    align="center"
    justify="center"
    class="h-100 preliminary"
  >
    <v-col align="center" justify="center">
      <v-col align="center" justify="center" cols="12">
        <router-view v-slot="{ Component }">
          <v-fade-transition mode="out-in">
            <component :is="Component" :key="$route.path" />
          </v-fade-transition>
        </router-view>
      </v-col>
    </v-col>
  </v-sheet>
</template>

<style lang="scss">
#auth-background {
  background-image: url("https://portal-pictures.back9ins.com/golf-course.jpg");
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
  z-index: 0;
}

.preliminary {
  background: #fafafa;

  .preliminary-card {
    width: 100%;
    max-width: 30rem;

    .v-card__actions {
      padding: 16px;
      padding-top: 0;
    }

    .password {
      padding-left: 12px;
      padding-right: 0;
    }
  }
}
</style>
