<template>
  <v-fade-transition mode="out-in">
    <v-col
      v-if="isVisible"
      cols="12"
      :style="{ paddingLeft: `${question.level * 12}px` }"
    >
      <v-card flat color="section" class="pa-2">
        <v-row align="center" class="ma-0" densef>
          <v-col cols="12" md="8">
            <h3
              style="
                font-weight: 500;
                border-left: 5px solid #4caf50;
                padding-left: 12px;
              "
              :style="{
                borderLeftColor: !isValid ? 'red' : '#4caf50'
              }"
              :data-testid="`${question.questionId}-title`"
            >
              {{ question.questionText }}
            </h3>
          </v-col>
          <v-col cols="12" md="4">
            <select-field
              v-model="answer"
              label="Selected Answer"
              :data-testid="`${question.questionId}-answer`"
              :success="answerValidation.success"
              :error-messages="answerValidation.errorMessages"
              :items="BOOLEAN_SELECT"
              :hide-details="
                answerValidation.success || ![true, false].includes(answer)
              "
              :disabled="props.readonly"
              @click.stop
            >
              <template #append-inner>
                <v-icon v-if="isValid" color="success" :icon="mdiCheck" />
                <v-icon v-else color="error" :icon="mdiAlertCircle" />
                <active-save-indicator
                  :controller="savingBuffer.answer.controller.value"
                />
              </template>
            </select-field>
          </v-col>

          <template v-if="answer && !question.childIds.length">
            <contracting-question-explanations
              :data-testid="`${question.questionId}-explanations`"
              :question-id="props.questionId"
              :readonly="props.readonly"
            />
            <contracting-question-documents
              :data-testid="`${question.questionId}-documents`"
              :question-id="props.questionId"
              :readonly="props.readonly"
            />
          </template>
        </v-row>
      </v-card>
    </v-col>
  </v-fade-transition>
</template>

<script setup>
const BOOLEAN_SELECT = [
  { value: false, title: "No" },
  { value: true, title: "Yes" }
];
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ContractingQuestionExplanations from "@/components/settings/background-questions/ContractingQuestionExplanations.vue";
import ContractingQuestionDocuments from "@/components/settings/background-questions/ContractingQuestionDocuments.vue";
import { computed,  ref, watch } from "vue";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useActiveSave } from "@/composables/active-save.composable";

import { useVuelidate } from "@vuelidate/core";
import { validationComputeV2 } from "@/util/helpers";
import { mdiCheck, mdiAlertCircle } from "@mdi/js";
const props = defineProps({
  questionId: { type: [Number, String], default: null },
  readonly: Boolean
});
const agent = useAgentSettingsStore();
const question = ref(agent.contractingQuestions[props.questionId]);

const savingBuffer = {
  answer: useActiveSave()
};

const isVisible = ref(false);
if (!question.value.parentId) isVisible.value = true;
else {
  const parent = ref(agent.contractingQuestions[question.value.parentId]);
  isVisible.value = Boolean(parent.value.answer);
  watch(
    () => parent.value.answer,
    () => {
      isVisible.value = Boolean(parent.value.answer);
    }
  );
}

const isValid = computed(() => {
  const validSubComponents = Boolean(
    Object.values(question.value.explanations).length &&
      Object.values(question.value.documents).length
  );

  if (!isVisible.value) return true;

  if (![false, true].includes(question.value.answer)) return false;

  const hasChildren = Boolean(question.value.childIds.length);
  if (hasChildren) return [true, false].includes(question.value.answer);

  return question.value.answer === false || validSubComponents;
});

const answer = computed({
  get() {
    return question.value.answer;
  },
  set(value) {
    if (!value) clearChildValues();
    agent.storeQuestionAnswer({ questionId: props.questionId, value });
    savingBuffer.answer.update(() =>
      agent.updateContractingAnswer(props.questionId)
    );
  }
});

const rules = {
  answer: { required: val => [true, false].includes(val) }
};
const state = { answer };
const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });

const answerValidation = computed(() => {
  const model = v$.value.answer;
  return validationComputeV2(model, [{ key: "required", message: "Required" }]);
});

function clearChildValues() {
  agent.clearContractingQuestion(props.questionId);
}
</script>
