<template>
  <div class="card-item">
    <span class="text-grey lighten-2"> {{ props.title }} </span>
    <br />
    <sensitive-info-toggle
      :type="props.type"
      :to="props.to"
      :fetch-func="props.fetchFunc"
    />
  </div>
</template>

<script setup>
import SensitiveInfoToggle from "@/components/shared/SensitiveInfoToggle.vue";


const props = defineProps({
  title: { type: String, required: true },
  type: {
    type: String,
    required: true,
    validates: v => ["tin", "ssn"].includes(v)
  },
  fetchFunc: {
    type: Function,
    required: true
  }
});
</script>
