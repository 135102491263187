import { getHttpClient } from "@/http-client";
import { setLoginFromRaw } from "@/factories/Login";

const baseUrl = "api/boss/logins";
export function switchLogin(params) {
  return getHttpClient().put(`${baseUrl}/${params.id}/change`, params);
}

export async function createLogin(params) {
  const { data } = await getHttpClient().post(baseUrl, params);
  return setLoginFromRaw(data.login);
}

export function destroyLogin(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}

export async function getApiKey(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/api-key`);
  return data.secret;
}

export function cycleApiKey(id) {
  return getHttpClient().put(`${baseUrl}/${id}/refresh_api_key`);
}

export function deleteApiKey(id) {
  return getHttpClient().delete(`${baseUrl}/${id}/api-key`);
}
