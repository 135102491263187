<template>
  <v-card class="ma-3">
    <v-card-text class="pa-0">
      <v-row dense>
        <v-col cols="12" lg="9">
          <transaction-create-form @created="onCreate" />
          <v-divider />
          <v-card-title class="justify-space-between">
            <div>View {{ activeTabText }}</div>
            <div v-if="simulatedTransaction">
              <app-button
                variant="outlined"
                color="error"
                class="text-none mr-1"
                data-testid="reset-commissions"
                @click="resetCommissions"
              >
                <v-icon :icon="mdiRotateLeft" /> Reset Commissions
              </app-button>
              <app-button
                class="text-none"
                color="accent"
                variant="outlined"
                data-testid="add-commission"
                @click="addCommission"
              >
                <v-icon :icon="mdiPlus" /> Add Commission
              </app-button>
            </div>
          </v-card-title>
          <v-card-text>
            <v-tabs v-model="activeTab" color="primary">
              <v-tab
                v-for="tab in tabs"
                :key="tab.text"
                class="text-none"
                :class="tab.classes"
                :data-testid="`tab-${tab.text}`"
              >
                <v-icon
                  v-if="tab.icon"
                  v-bind="tab.iconProps"
                  :icon="tab.icon"
                  class="mr-1"
                />
                {{ tab.text }}
              </v-tab>
            </v-tabs>
            <v-tabs-window v-model="activeTab" touchless>
              <v-tabs-window-item v-for="tab in tabs" :key="tab.text">
                <v-card-subtitle v-if="tab.loading && !tab.loaded">
                  {{ tab.loadingText }}
                  <v-progress-circular class="ml-2" indeterminate />
                </v-card-subtitle>
                <v-card-subtitle v-else-if="!tab.loaded" class="pa-3">
                  {{ tab.notLoadedText }}
                </v-card-subtitle>
                <component :is="tab.component" v-else v-on="tab.listeners" />
              </v-tabs-window-item>
            </v-tabs-window>
          </v-card-text>
        </v-col>
        <v-col cols="12" lg="3">
          <transaction-case-data @refresh="refreshPolicyData" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="isDialog" class="px-3 pt-0">
      <app-button
        block
        class="text-none"
        color="primary"
        @click="dialog.closeDialog()"
      >
        Close
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import TransactionCreateForm from "@/components/transactions/TransactionCreateForm.vue";
import TransactionCaseData from "@/components/transactions/TransactionCaseData.vue";
import TransactionCreateTransactionsTable from "@/components/transactions/TransactionCreateTransactionsTable.vue";
import TransactionCreatePartiesTable from "@/components/transactions/TransactionCreatePartiesTable.vue";

import TransactionCreateSimulatedCommissionsTable from "@/components/transactions/TransactionCreateSimulatedCommissionsTable.vue";
import TransactionCreateSimulatedPaymentsTable from "@/components/transactions/TransactionCreateSimulatedPaymentsTable.vue";

import CommissionDialog from "@/dialogs/CommissionDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { mdiRotateLeft, mdiPlus, mdiInformation } from "@mdi/js";
import { markRaw, ref, watch, computed } from "vue";
import { resetCaseCommissions } from "@/api/cases.service";
import { useTransactionCreate } from "@/stores/transaction-create";

import { useHead } from "@unhead/vue";

import {
  simulateTransaction,
  getCaseTransactionsAndParties,
  searchCaseTransactions
} from "@/api/transactions.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { TransactionToCreateRequest } from "@/factories/Transaction";

const props = defineProps({
  isDialog: Boolean,
  modelValue: { type: Object, required: false, default: () => {} }
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

if (!props.isDialog) {
  useHead({ title: "Create Transaction" });
}

const transactionCreateStore = useTransactionCreate();
transactionCreateStore.$reset();
const {
  fetchingPolicy,
  transaction,
  simulatedTransaction,
  simulatingTransaction,
  forecast,
  payments,
  finalOverride,
  loadingCaseTransactions,
  loadedCaseTransactions,
  parties,
  simulationErrorMessage,
  commissions,
  transactions
} = storeToRefs(transactionCreateStore);

const activeTab = ref(0);

const canSimulateTransaction = computed(() => {
  const items = [
    transaction.value.premium,
    transaction.value.policy?.id,
    transaction.value.commissionType,
    transaction.value.statement?.id,
    transaction.value.amount
  ];
  if (!items.every(v => v)) return false;
  return items.join("-");
});

const commissionsHasErrors = computed(() =>
  commissions.value.some(v => Boolean(v.errors))
);
const activeTabText = computed(() => tabs.value[activeTab.value].text);

const tabs = computed(() => {
  return [
    {
      text: "Transactions",
      component: markRaw(TransactionCreateTransactionsTable),
      loadingText: "Loading Transaction Data",
      notLoadedText:
        "Add a Policy Number and Commission Type to see Transaction Data",
      loading: loadingCaseTransactions.value,
      loaded: loadedCaseTransactions.value,
      listeners: {
        refresh
      }
    },
    {
      text: "Parties",
      component: markRaw(TransactionCreatePartiesTable),
      loading: loadingCaseTransactions.value,
      loaded: loadedCaseTransactions.value,
      loadingText: "Loading Party Data",
      notLoadedText: "Add a Policy Number and Commission Type to see Party Data"
    },
    {
      text: "Simulated Commissions",
      component: markRaw(TransactionCreateSimulatedCommissionsTable),
      classes: commissionsHasErrors.value ? "text-error" : "",
      icon: commissionsHasErrors.value ? mdiInformation : null,
      color: "error",
      loading: simulatingTransaction.value,
      loaded: simulatedTransaction.value,
      loadingText: "Simulating Commission Data",
      notLoadedText:
        "Add a Policy Number, Commission Type, Statement, and Premium to see Payment Data",
      listeners: {
        refresh
      }
    },
    {
      text: "Simulated Payments",
      component: markRaw(TransactionCreateSimulatedPaymentsTable),
      loading: simulatingTransaction.value,
      loaded: simulatedTransaction.value,
      loadingText: "Simulating Payment Data",
      notLoadedText:
        "Add a Policy Number, Commission Type, Statement, and Premium to see Payment Data"
    }
  ];
});

function refresh() {
  if (canSimulateTransaction.value) transactionSimulate(true);
  if (canFetchCaseTransactions.value) fetchCaseTransactions(true);
}

const canFetchCaseTransactions = computed(
  () => transaction.value.policy?.id && transaction.value.commissionType
);

let previousSimulation;
async function transactionSimulate(force = false) {
  if (!canSimulateTransaction.value) return;
  const body = TransactionToCreateRequest(transaction.value);
  if (previousSimulation === JSON.stringify(body) && !force) return;
  simulatingTransaction.value = true;
  try {
    const res = await simulateTransaction(body);
    previousSimulation = JSON.stringify(body);

    finalOverride.value = res.finalOverride;

    forecast.value = res.forecast;
    commissions.value = res.commissions;
    payments.value = res.payments;

    const errorMessage = [];
    if (res.isErrored) {
      if (res.payments.some(v => v.errors)) {
        errorMessage.push("Some payments have errors.");
      }
      if (res.commissions.some(v => v.errors)) {
        errorMessage.push("Some commissions have errors.");
      }
      if (res.errors) {
        errorMessage.push(res.errors);
      }
    }

    simulationErrorMessage.value = errorMessage.join(" ");
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    simulatingTransaction.value = false;
    simulatedTransaction.value = true;
  }
}

let lastRequest;
async function fetchCaseTransactions(force = false) {
  if (!canFetchCaseTransactions.value) return;
  const params = {
    case_id: transaction.value.policy.id,
    commission_type: transaction.value.commissionType
  };

  if (JSON.stringify(params) === lastRequest && !force) return;

  try {
    loadingCaseTransactions.value = true;

    const res = await getCaseTransactionsAndParties(params);
    lastRequest = JSON.stringify(params);
    parties.value = res.parties;
    transactions.value = res.transactions;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingCaseTransactions.value = false;
    loadedCaseTransactions.value = true;
  }
}

function onCreate() {
  if (props.isDialog) dialog.closeDialog();
}

function resetCommissions() {
  if (!transaction.value.policy?.id) return;
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Reset Commissions",
    subtitle:
      "This will set all commission percentages to zero and re-build the commissions",
    func: async () => {
      await resetCaseCommissions(transaction.value.policy.id);
      refresh();
    }
  });
}

async function addCommission() {
  const res = await dialog.showDialog({
    component: markRaw(CommissionDialog),
    modelValue: {
      policy: transaction.value.policy
    }
  });

  if (res?.commission) refresh();
}

function refreshPolicyData() {
  fetchPolicyData(transaction.value.policy?.id);
  refresh();
}

async function fetchPolicyData(id = null) {
  if (!id) return;
  try {
    fetchingPolicy.value = true;
    const policies = await searchCaseTransactions({
      case_id: id
    });
    if (policies.length > 0) transaction.value.policy = policies[0];
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    fetchingPolicy.value = false;
  }
}

async function initialize() {
  transaction.value.amount = props.modelValue.amount;
  transaction.value.premium = props.modelValue.premium;
  transaction.value.commissionType = props.modelValue.commissionType;
  if (props.modelValue.policy?.id) {
    await fetchPolicyData(props.modelValue.policy?.id);
  }
  transaction.value.statement = props.modelValue.statement;
  transaction.value.queuedTransactionId = props.modelValue.queuedTransactionId;
}

let caseTransactionTimer;
function debounceAndFetchCaseTransitions() {
  if (caseTransactionTimer) clearTimeout(caseTransactionTimer);
  caseTransactionTimer = setTimeout(fetchCaseTransactions, 1000);
}

let simulationTimer;
function debounceAndSimulateTransaction() {
  if (simulationTimer) clearTimeout(simulationTimer);
  simulationTimer = setTimeout(transactionSimulate, 1000);
}

if (props.modelValue?.queuedTransactionId) initialize();

watch(
  transaction,
  () => {
    if (canFetchCaseTransactions.value) debounceAndFetchCaseTransitions();
    if (canSimulateTransaction.value) debounceAndSimulateTransaction();
  },
  { deep: true, immediate: true }
);
</script>
