<template>
  <autocomplete-field
    v-model="model"
    v-model:search="personnelSearchText"
    autocomplete="false"
    :placeholder="placeholder"
    :label="label"
    item-title="name"
    return-object
    :prepend-inner-icon="mdiBadgeAccountOutline"
    no-filter
    hide-no-data
    :hint="hint"
    :persistent-hint="persistentHint"
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="modelOptions"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"

    @click:clear="$emit('update:model-value', {})"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
    <template v-if="$slots['message']" #message>
      <slot name="message" />
    </template>
  </autocomplete-field>
</template>

<script>
import { personnelSearch } from "@/api/search.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiBadgeAccountOutline } from "@mdi/js";
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    carrierId: {
      type: [String, Number],
      required: true
    },
    hint: {
      type: String,
      required: false,
      default: null
    },
    persistentHint: Boolean,
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    disabled: Boolean,
    errorMessages: { type: Array, default: () => [] },
    modelValue: { type: [Object, String], required: false, default: null }
  },
  emits: ["update:model-value", "blur"],
  data() {
    let modelOptions = [];
    let model = null;
    if (
      this.modelValue &&
      Object.values(this.modelValue).filter(
        v => Boolean(v) && !(typeof v === "function")
      ).length
    ) {
      modelOptions.push(this.modelValue);
      model = this.modelValue;
    }
    return {
      personnelSearchText: "",
      modelOptions,
      timer: null,
      model,
      loading: false,
      mdiBadgeAccountOutline
    };
  },
  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.model = null;
        this.modelOptions = [];
        return;
      }
      this.model = value;
      this.modelOptions = [value];
    },
    model(value) {
      this.$emit("update:model-value", value);
    },
    personnelSearchText(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }
      if (this.modelOptions.some(val => val?.name === value)) {
        return;
      }

      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(this.search, 200);
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async search() {
      this.loading = true;
      try {
        const options = await personnelSearch({
          name: this.personnelSearchText,
          carrierId: this.carrierId
        });
        this.modelOptions = options.toSorted((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
