import { getHttpClient } from "@/http-client";
import {
  setSupportTicketFromRequest,
  setSupportTicketTableItemFromRequest
} from "@/factories/SupportTicket";
import { serializeObject } from "@/util/helpers";
const baseUrl = "api/boss/support-tickets";

export async function createSupportTicket(support_ticket) {
  const body = await serializeObject({ support_ticket });
  const response = await getHttpClient().post(baseUrl, body);

  return setSupportTicketFromRequest(response.data);
}

export async function getSupportTickets(urlParams, cancelToken) {
  let params = urlParams;
  if (!params) params = new URLSearchParams();
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  const items = data.support_tickets.map(setSupportTicketTableItemFromRequest);

  return { items, meta: data.meta };
}

export async function getSupportTicket(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);

  return setSupportTicketFromRequest(data);
}

export function updateSupportTicket(id, support_ticket) {
  return getHttpClient().put(`${baseUrl}/${id}`, { support_ticket });
}
