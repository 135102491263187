<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    :row-props="v => ({ class: v.item.additional.rowClass })"
    item-value="additional.key"
    multi-sort
    data-testid="transactions-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="loadingCaseTransactions"
  >
    <template #[`item.statementDate`]="{ item }">
      <router-link
        :class="{ 'text-white text-underline': item.additional.hasFatalFlaw }"
        :to="{
          name: 'StatementView',
          params: { id: item.additional.statementId }
        }"
      >
        {{ item.statementDate }}
      </router-link>
    </template>
    <template
      #[`item.data-table-expand`]="{ item, internalItem, toggleExpand }"
    >
      <v-menu location="bottom" offset-y>
        <template #activator="{ props: templateProps }">
          <app-button
            data-testid="transaction-actions"
            variant="text"
            density="comfortable"
            v-bind="templateProps"
            :icon="mdiDotsHorizontal"
            :color="item.additional.hasFatalFlaw ? 'white' : ''"
          />
        </template>
        <v-list>
          <v-list-item
            v-for="action in item.additional.actions"
            :key="action.title"
            :data-testid="action.title"
            @click="action.onClick()"
          >
            <template #prepend>
              <v-icon :icon="action.icon" />
            </template>

            <v-list-item-title> {{ action.title }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip location="top">
        <template #activator="{ props: templateProps }">
          <app-button
            v-if="item.additional.auditMessages.length"
            v-bind="templateProps"
            :icon="mdiMagnifyScan"
            variant="text"
            density="comfortable"
            color="white"
            data-testid="audit-messages"
            @click.stop="toggleExpand(internalItem)"
          />
        </template>
        <span> View Audit Messages </span>
      </v-tooltip>
    </template>
    <template #expanded-row="{ columns, item }">
      <td :colspan="columns.length" data-testid="audit-messages">
        <v-list>
          <template
            v-for="(message, index) in item.additional.auditMessages"
            :key="index"
          >
            <v-list-item>
              <template #prepend>
                <v-icon :color="message.color">
                  {{ message.icon }}
                </v-icon>
              </template>
              {{ message.message }}
            </v-list-item>
            <v-divider
              v-if="index < item.additional.auditMessages.length - 1"
            />
          </template>
        </v-list>
      </td>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import {
  revertTransaction,
  reflowTransaction,
  auditTransaction,
  deleteTransaction
} from "@/api/transactions.service";
import { useDialogStore } from "@/stores/dialog";
import { markRaw, watch } from "vue";

import {
  mdiDotsHorizontal,
  mdiDelete,
  mdiMagnifyScan,
  mdiBackupRestore,
  mdiUndoVariant,
  mdiAlertCircle,
  mdiBug,
  mdiInformation
} from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { storeToRefs } from "pinia";
import { useTransactionCreate } from "@/stores/transaction-create";
import { currencyFormat, timestampFormatter } from "@/util/helpers";

const emit = defineEmits(["refresh"]);

const { loadingCaseTransactions, transactions } = storeToRefs(
  useTransactionCreate()
);

const dialog = useDialogStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Transaction ID",
      value: "transactionId",
      map: "id",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Statement Date",
      value: "statementDate",
      map: "statementDate",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType"
    }),
    new TableHeader({
      text: "Premium",
      value: "amount",
      map: "amount"
    }),
    new TableHeader({
      text: "Direct Override",
      value: "directOverride",
      map: "directOverride"
    }),
    new TableHeader({
      text: "Total Override",
      value: "totalOverride",
      map: "totalOverride"
    }),
    new TableHeader({
      text: "Actions",
      value: "data-table-expand",
      map: "data-table-expand"
    })
  ],
  options: TableOptions({
    sortBy: [
      { key: "transactionId", order: "desc" },
      { key: "statementDate", order: "desc" }
    ]
  })
});

watch(
  transactions,
  () => {
    table.items.value.splice(0, table.items.value.length);
    transactions.value.forEach(t => {
      const actions = [];
      if (!t.anyPaid) {
        actions.push({
          icon: mdiDelete,
          title: "Delete Transaction",
          onClick: () => destroyTransaction(t)
        });
      }
      actions.push({
        icon: mdiMagnifyScan,
        title: "Audit Transaction",
        onClick: () => transactionAudit(t.id)
      });

      if (!t.revertedBy && t.anyPaid) {
        actions.push(
          {
            icon: mdiBackupRestore,
            title: "Reflow Transaction",
            onClick: () => transactionReflow(t.id)
          },
          {
            icon: mdiUndoVariant,
            title: "Revert Transaction",
            onClick: () => transactionRevert(t.id)
          }
        );
      }

      const auditMessages = [];
      let rowClass = null;
      let hasFatalFlaw = false;
      if (t.auditMessages.length) {
        t.auditMessages.forEach(auditMessage => {
          let icon = mdiInformation;
          let color = "info";
          if (auditMessage.level === "fatal") {
            icon = mdiAlertCircle;
            color = "error";
            rowClass = "bg-error text-white";
            hasFatalFlaw = true;
          } else if (auditMessage.level === "debug") {
            icon = mdiBug;
            color = "accent";
          }

          auditMessages.push({
            message: auditMessage.message,
            icon,
            color
          });
        });
      }

      table.items.value.push({
        ...t,
        statementDate: timestampFormatter(t.statementDate, "sole-day"),
        totalOverride: currencyFormat(t.override, 2),
        directOverride: currencyFormat(t.directCommission, 2),
        amount: currencyFormat(t.amount, 2),
        key: JSON.stringify(t),
        hasFatalFlaw,
        rowClass,
        actions,
        auditMessages
      });
    });
  },
  { immediate: true, deep: true }
);

function transactionRevert(transactionId) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Revert this transaction",
    subtitle: "This will undo the transaction",
    func: async () => {
      await revertTransaction(transactionId);
      emit("refresh");
    }
  });
}

function transactionReflow(transactionId) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Reflow this transaction",
    subtitle: "This will reflow the transaction",
    func: async () => {
      await reflowTransaction(transactionId);
      emit("refresh");
    }
  });
}

function transactionAudit(transactionId) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Audit this transaction",
    subtitle: "This will audit the transaction",
    func: async () => {
      await auditTransaction(transactionId);
      emit("refresh");
    }
  });
}
function destroyTransaction(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Delete Transaction?`,
    subtitle: "This cannot be undone",
    func: async () => {
      await deleteTransaction(item.id);
      emit("refresh");
    }
  });
}
</script>
