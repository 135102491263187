<template>
  <v-row v-if="showSensitiveData" align="center" class="ma-0">
    <p class="mb-0 mr-1">{{ content || "No Content" }}</p>
    <v-progress-circular
      size="12"
      width="2"
      :model-value="progress"
      style="transform: scaleY(-1) rotate(90deg)"
    />
  </v-row>
  <v-row v-else class="ma-0" align="center">
    <component
      :is="loadingContent ? 'p' : 'a'"
      class="mb-0 mr-1"
      @click="fetchSensitiveInfo"
    >
      <template v-if="type === 'ssn'"> ***-**-****</template>
      <template v-else-if="type === 'tin'"> **-*******</template>
    </component>
    <v-progress-circular
      indeterminate
      :style="{ opacity: +loadingContent }"
      size="12"
      width="2"
    />
  </v-row>
</template>

<script>
const MAX_VIEWABLE_SECONDS = 30;
export default {
  name: "SensitiveInfoToggle",
  props: {
    type: { type: String, required: true },
    fetchFunc: { type: Function, required: true }
  },
  data() {
    return {
      showSensitiveData: false,
      content: "",
      loadingContent: false,
      interval: null,
      viewableTime: 0
    };
  },
  computed: {
    progress() {
      return (this.viewableTime / MAX_VIEWABLE_SECONDS) * 100;
    }
  },
  methods: {
    fetchSensitiveInfo() {
      this.loadingContent = true;
      this.fetchFunc()
        .then(info => {
          this.showSensitiveData = true;
          this.content = info;
          this.viewableTime = MAX_VIEWABLE_SECONDS;
          this.interval = setInterval(() => {
            this.viewableTime -= 1;
            if (this.viewableTime > 0) return;
            clearInterval(this.interval);
            this.content = "";
            this.showSensitiveData = false;
          }, 1000);
        })
        .finally(() => {
          this.loadingContent = false;
        });
    }
  }
};
</script>

<style scoped></style>
