import TextField from "@/components/shared/TextField.vue";
import TextareaField from "@/components/shared/TextareaField.vue";
import SelectField from "@/components/shared/SelectField.vue";
import AutocompleteField from "@/components/shared/AutocompleteField.vue";
import CheckboxField from "@/components/shared/CheckboxField.vue";
import AppButton from "@/components/shared/AppButton.vue";
import ComboboxField from "@/components/shared/ComboboxField.vue";
import ChipGroupField from "@/components/shared/ChipGroupField.vue";

export const registerComponents = app => {
  app.component("TextField", TextField);
  app.component("SelectField", SelectField);
  app.component("AutocompleteField", AutocompleteField);
  app.component("CheckboxField", CheckboxField);
  app.component("AppButton", AppButton);
  app.component("TextareaField", TextareaField);
  app.component("ComboboxField", ComboboxField);
  app.component("ChipGroupField", ChipGroupField)
};

export const testingComponents = {
  TextField,
  SelectField,
  AutocompleteField,
  CheckboxField,
  AppButton,
  TextareaField,
  ComboboxField
};
