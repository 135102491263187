import { updateUser } from "@/api/users.service";
import { parse } from "date-fns";
import { PERMISSION } from "@/constants/permissions.constants";
import { defineStore } from "pinia";

export const AUTH_STEPS = {
  COMPLETED: "Home",
  ONBOARDING_SIGNUP: "OnboardingSignup",
  TWO_FACTOR_ENROLLMENT_REQUIRED: "TwoFactorEnrollmentRequired",
  TWO_FACTOR_CONFIRMATION: "TwoFactorConfirmation"
};

export function UserTutorials() {
  return {
    home: false,
    series_walkthrough: false
  };
}

function UserIdentity() {
  return {
    id: null,
    enabled: false,
    createdAt: null
  };
}

export const useUserStore = defineStore("user", {
  state: () => ({
    darkMode: false,
    deleted_at: null,
    email: "",
    group: 0,
    highest_group: 0,
    id: 0,
    legacy_salt: null,
    login: {
      active: false,
      id: null,
      type: "",
      has_api_key: false
    },
    loginable: {
      approved_domain_url: "",
      assignable: null,
      avatar_url: "",
      bypass_contracting: false,
      email: "",
      has_eo: false,
      has_eapps: null,
      hide_commission: false,
      id: null,
      is_employee: false,
      is_case_manager: false,
      marketing_manager: null,
      name: "",
      npn: "",
      signer: {
        id: null,
        name: null,
        type: null,
        email: null,
        npn: null
      },
      show_backnine: false,
      signature_authorized: false,
      type: "Agent"
    },

    logins: [],
    name: null,
    permissions: Object.values(PERMISSION).reduce(
      (acc, p) => ({ ...acc, [p]: false }),
      {}
    ),
    integrations: {
      wealthbox: UserIdentity(),
      redtail: UserIdentity(),
      microsoft: UserIdentity()
    },
    provider: "",
    source: null,
    system_message: "",
    tutorials: UserTutorials(),
    uid: "",
    users: null,
    otp_enrolled: null,
    otp_required_on: null,
    otp_required_for_login: null,
    otp_verified: null
  }),
  getters: {
    isEmployee() {
      return Boolean(this.loginable.is_employee);
    },
    isAccountingGroup() {
      return Boolean(this.permissions[PERMISSION.ACCOUNTING]);
    },
    isGroupOne() {
      return this.group === 1;
    },
    isGroupTwoPlus() {
      return this.group >= 2;
    },
    isGroupThreePlus() {
      return this.group >= 3;
    },
    isGroupFour() {
      return this.group === 4;
    },
    otpRequiredNow() {
      return (
        parse(this.otp_required_on, "yyyy-MM-dd", new Date()) <= new Date()
      );
    },
    compactUserName() {
      const MAX_LEN = 30;
      if (this.loginable.name.length < MAX_LEN) return this.loginable.name;
      return this.loginable.name.split(" ")[0];
    }
  },
  actions: {
    logoutUser() {
      this.$reset();
    },
    storeVerboseDetails({ login, logins, user, system_message } = {}) {
      this.otp_enrolled = user.otp_enrolled;
      this.otp_required_on = user.otp_required_on;
      this.otp_required_for_login = user.otp_required_for_login;
      this.otp_verified = user.otp_verified;
      this.system_message = system_message;
      this.group = user.group;
      this.highest_group = user.highest_group;
      this.id = user.id;
      this.name = user.name;
      this.darkMode = user.dark_mode;
      this.email = user.email;

      if (login) {
        this.login.active = login.active;
        this.login.type = login.type;
        this.login.id = login.id;
        this.login.has_api_key = login.has_api_key;

        const loginable = login.loginable;
        this.loginable.approved_domain_url = loginable.approved_domain_url;
        this.loginable.assignable = loginable.assignable;
        this.loginable.avatar_url = loginable.avatar_url;
        this.loginable.bypass_contracting = loginable.bypass_contracting;
        this.loginable.email = loginable.email;
        this.loginable.has_eo = Boolean(loginable.eo?.id);
        this.loginable.has_eapps = loginable.has_eapps;
        this.loginable.hide_commission = loginable.hide_commission;
        this.loginable.id = loginable.id;
        this.loginable.is_employee = loginable.is_employee;
        this.loginable.is_case_manager = loginable.is_case_manager;
        this.loginable.marketing_manager = loginable.marketing_manager;
        this.loginable.name = loginable.name;
        this.loginable.npn = loginable.npn;
        this.loginable.show_backnine = loginable.show_backnine;
        this.loginable.signature_authorized = loginable.signature_authorized;
        this.loginable.type = loginable.type.model;

        this.loginable.signer.email = loginable?.signer?.email;
        this.loginable.signer.npn = loginable?.signer?.npn;
        this.loginable.signer.id = loginable?.signer?.id;
        this.loginable.signer.type = loginable?.signer?.type?.model;
        this.loginable.signer.name = loginable?.signer?.name;
      }

      if (user.tutorials) {
        this.tutorials.home = user.tutorials.home || false;
        this.tutorials.series_walkthrough =
          user.tutorials.series_walkthrough || false;
      }

      if (user.identities?.length) {
        const identities = user.identities.reduce((acc, a) => {
          acc[a.name] = a;
          return acc;
        }, {});

        Object.keys(this.integrations).forEach(i => {
          if (identities[i]) {
            this.integrations[i].enabled = Boolean(identities[i]?.created_at);
            this.integrations[i].createdAt = identities[i]?.created_at;
            this.integrations[i].id = identities[i]?.id;
          }
        });
      }

      if (user.permissions) {
        Object.values(PERMISSION).forEach(p => {
          this.permissions[p] = Boolean(user.permissions[p]);
        });
      }

      this.logins = [];
      if (logins?.length) {
        logins.forEach(login => {
          this.logins.push({
            id: login.id,
            name: login.loginable?.name
          });
        });
      }
    },
    addUserLogin({ id, name }) {
      this.logins.push({ id, name });
    },
    removeUserLogin(loginId) {
      if (!this.logins?.length) return;
      const result = this?.logins?.findIndex(({ id }) => id === loginId);
      if (!result) return;
      this.logins.splice(result, 1);
    },
    completeTutorial(newTutorial) {
      const availableTutorials = UserTutorials();
      if (!(newTutorial in availableTutorials)) {
        throw "Missing Tutorial" + newTutorial;
      }
      this.tutorials[newTutorial] = true;
    },
    saveTutorials() {
      return updateUser(this.id, { tutorials: this.tutorials });
    },
    clearAllTutorials() {
      this.$patch({ tutorials: UserTutorials() });
      return this.saveTutorials();
    },
    nextAuthStep() {
      let otpEnrollmentIsRequired;
      if (!this.otp_required_for_login || this.otp_enrolled) {
        otpEnrollmentIsRequired = false;
      } else if (this.otpRequiredNow || !this.otp_verified) {
        otpEnrollmentIsRequired = true;
      }

      if (otpEnrollmentIsRequired) {
        return AUTH_STEPS.TWO_FACTOR_ENROLLMENT_REQUIRED;
      } else if (this.otp_enrolled && !this.otp_verified) {
        return AUTH_STEPS.TWO_FACTOR_CONFIRMATION;
      } else if (this.logins.length === 0) {
        return AUTH_STEPS.ONBOARDING_SIGNUP;
      } else {
        return AUTH_STEPS.COMPLETED;
      }
    }
  }
});
