<template>
  <div>
    <v-tabs v-model="activeTab" color="primary">
      <v-tab v-for="item in items" :key="item.text + 'title'" class="text-none">
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-window v-model="activeTab" touchless>
      <v-tabs-window-item v-for="item in items" :key="item.text + 'body'">
        <component :is="item.component" />
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<script setup>
import AdvisorsDashboard from "@/components/advisors/AdvisorsTable.vue";
import AppointmentsTable from "@/components/appointments/AppointmentsTable.vue";
import UnderwritingTables from "@/views/UnderwritingTables.vue";
import CasesTable from "@/components/cases/CasesTable.vue";
import TasksTable from "@/components/tasks/TasksTable.vue";
import { markRaw, ref } from "vue";
const activeTab = ref(0);
const items = [
  {
    text: "Advisors",
    component: markRaw(AdvisorsDashboard)
  },
  {
    text: "Appointments",
    component: markRaw(AppointmentsTable)
  },
  {
    text: "Cases",
    component: markRaw(CasesTable)
  },
  {
    text: "Tasks",
    component: markRaw(TasksTable)
  },
  {
    text: "Underwriting",
    component: markRaw(UnderwritingTables)
  }
];
</script>
