import { getRouterLink } from "@/util/helpers";

export function PaymentCreate(model = {}) {
  return {
    policy: model?.policy || undefined,
    statement: model?.statement || undefined,
    premium: model?.premium || undefined,
    override: model?.override || undefined,
    commissionType: model?.commission_type || "First Year",
    percent: model?.percent || null,
    agent: model?.agent || undefined
  };
}

export function Payment(model = {}) {
  return {
    case: model?.case,
    commissionType: model?.commissionType,
    premium: model?.premium,
    percent: model?.percent,
    paid: model?.paid,
    dollar: model?.dollar,
    statement: model?.statement,
    statementDate: model?.statementDate,
    statementId: model?.statementId,
    appointmentCaseId: model?.appointmentCaseId,
    payorId: model?.payorId
  };
}

export function PaymentToCreateRequest(model) {
  return {
    case: { id: model.policy.id, line: model.policy.line },
    case_id: model.policy.id,
    commission_type: model.commissionType,
    premium: model.premium,
    percent: model.percent / 100,
    paid: 1,
    dollar: model.override,
    statement_date: model.statement.statementDate,
    statement_id: model.statement.id,
    appointment_case_id: model.agent.id,
    payor_id: model.statement.payor.id
  };
}

export function setPaymentFromRequest(raw) {
  const model = Payment();

  model.case = raw.policy;
  model.commissionType = raw.commissionType;
  model.premium = raw.premium;
  model.percent = raw.percent / 100;
  model.paid = 1;
  model.dollar = raw.override;
  model.statement = raw.statement;
  model.statementDate = raw.statement.statement_date;
  model.statementId = raw.statement.id;
  model.appointmentCaseId = raw.agent.id;
  model.payorId = raw.statement.payor.id;

  return model;
}

export function PaymentStatementSearchItem(model = {}) {
  return {
    id: model?.id,
    amount: model?.amount,
    payor: StatementSearchItemPayor(model?.payor),
    statementDate: model?.statementDate
  };
}

export function setPaymentStatementSearchItemFromRequest(raw = {}) {
  const model = PaymentStatementSearchItem();
  model.id = raw.id;
  model.amount = raw.amount;
  model.statementDate = raw.statement_date;
  model.payor = setStatementSearchItemPayorFromRequest(raw.payor);
  return model;
}

function StatementSearchItemPayor(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    payableType: model?.payableType
  };
}

function setStatementSearchItemPayorFromRequest(raw = {}) {
  const model = StatementSearchItemPayor();
  model.id = raw.id;
  model.name = raw.name;
  model.payableType = raw.payable_type;
  return model;
}

function PaymentTableItem(model = {}) {
  return {
    id: model?.id,
    createdDate: model?.createdDate,
    premium: model?.premium,
    commissionType: model?.commissionType,
    percent: model?.percent,
    dollar: model?.dollar,
    commission: model?.commission
      ? PaymentTableItemCommission(model.commission)
      : undefined,
    payPeriod: model.payPeriod
      ? PaymentTableItemPayPeriod(model.payPeriod)
      : undefined,
    statement: model?.statement
      ? PaymentTableItemStatement(model?.statement)
      : undefined
  };
}

export function setPaymentTableItemFromRequest(raw = {}) {
  const model = PaymentTableItem();
  model.id = raw.id;
  model.createdDate = raw.created_date;
  model.premium = raw.premium;
  model.commissionType = raw.commission_type;
  model.percent = raw.percent;
  model.dollar = raw.dollar;
  model.commission = setPaymentTableItemCommissionFromRequest(raw.commission);

  if (raw?.pay_period) {
    model.payPeriod = setPaymentTableItemPayPeriodFromRequest(raw.pay_period);
  }

  if (raw?.statement) {
    model.statement = setPaymentTableItemStatementFromRequest(raw.statement);
  }
  return model;
}

function PaymentTableItemPayPeriod(model = {}) {
  return {
    id: model?.id,
    paidDate: model?.paidDate,
    routerLink: model?.routerLink
  };
}

function setPaymentTableItemPayPeriodFromRequest(raw = {}) {
  const model = PaymentTableItemPayPeriod();
  model.id = raw.id;
  model.paidDate = raw.paid_date;
  model.routerLink = getRouterLink("PayPeriod", model.id);
  return model;
}

function PaymentTableItemStatement(model = {}) {
  return {
    id: model?.id,
    statementDate: model?.statementDate
  };
}

function setPaymentTableItemStatementFromRequest(raw = {}) {
  const model = PaymentTableItemStatement();
  model.id = raw.id;
  model.statementDate = raw.statement_date;
  return model;
}

function PaymentTableItemCommission(model = {}) {
  return {
    id: model?.id,
    paymentType: model?.paymentType,
    commissionType: model?.commissionType,
    percent: model?.percent,
    payor: model?.payor ? RelatedResource(model?.payor) : undefined,
    assignedPayable: model?.assignedPayable
      ? RelatedResource(model?.assignedPayable)
      : undefined,
    appointmentCase: model?.appointmentCase
      ? PaymentTableItemCommissionAppointmentCase(model?.appointmentCase)
      : undefined
  };
}

function setPaymentTableItemCommissionFromRequest(raw = {}) {
  const model = PaymentTableItemCommission();

  model.id = raw.id;
  model.paymentType = raw.payment_type;
  model.commissionType = raw.commission_type;
  model.percent = raw.percent;
  model.payor = setRelatedResourceFromRequest(raw.payor);
  model.assignedPayable = setRelatedResourceFromRequest(raw.assigned_payable);
  if (raw?.appointment_case) {
    model.appointmentCase =
      setPaymentTableItemCommissionAppointmentCaseFromRequest(
        raw?.appointment_case
      );
  }
  return model;
}

function PaymentTableItemCommissionAppointmentCase(model = {}) {
  return {
    id: model?.id,
    splitPercent: model?.splitPercent,
    advisor: RelatedResource(model?.advisor),
    policyNumber: model?.policyNumber,
    caseId: model?.caseId,
    caseLink: model?.caseLink,
    insured: RelatedResource(model?.insured),
    carrier: RelatedResource(model?.carrier)
  };
}

function setPaymentTableItemCommissionAppointmentCaseFromRequest(raw = {}) {
  const model = PaymentTableItemCommissionAppointmentCase();
  model.id = raw.id;
  model.splitPercent = raw.split_percent;
  model.advisor = setRelatedResourceFromRequest(raw.advisor);
  model.policyNumber = raw.policy_number;
  model.caseId = raw.case_id;
  model.insured = setRelatedResourceFromRequest(raw.insured);
  model.carrier = setRelatedResourceFromRequest(raw.carrier);
  if (model.caseId) {
    model.caseLink = getRouterLink("Case", model.caseId);
  }
  return model;
}

const typeLinkDict = {
  agents: "Agent",
  agencies: "Agency",
  carriers: "Carrier"
};

function RelatedResource(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    type: model?.type
  };
}

function setRelatedResourceFromRequest(raw = {}) {
  const model = RelatedResource();
  model.id = raw.id;
  model.name = raw.name;
  if (raw?.type?.model) model.type = raw.type.model;
  if (raw?.link) model.type = typeLinkDict[raw.link];
  return model;
}
