<template>
  <v-data-table
    data-testid="marketing-managers-table"
    item-value="additional.id"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :search="search"
  >
    <template #top>
      <v-row class="ma-0 pa-3" align="center">
        <h1 class="text-h5">Marketing Managers</h1>
        <v-spacer />
        <text-field
          v-model="search"
          label="Search"
          :prepend-inner-icon="mdiMagnify"
          data-lpignore="true"
          data-testid="marketing-managers-table-search"
          variant="outlined"
          dense
          hide-details
        />
      </v-row>
    </template>
    <template #[`item.actions`]="{ item }">
      <app-button
        :icon="mdiPencil"
        variant="text"
        density="comfortable"
        color="success"
        data-testid="edit-marketing-manager"
        @click="editMarketingManager(item.additional)"
      />
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { mdiMagnify, mdiPencil } from "@mdi/js";
import { getMarketingManagers } from "@/api/boss.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { ref } from "vue";
import { parseErrorMessage } from "@/util/helpers";

useHead({ title: "Marketing Managers" });

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { isGroupThreePlus } = storeToRefs(useUserStore());

const search = ref(null);
const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Case Manager",
      value: "caseManagerName",
      map: "caseManagerName",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Case Concierge",
      value: "caseConciergeName",
      map: "caseConciergeName",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Annuity Wholesaler",
      value: "annuityWholesalerName",
      map: "annuityWholesalerName",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Disability Wholesaler",
      value: "disabilityWholesalerName",
      map: "disabilityWholesalerName",
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "LTC Wholesaler",
      value: "ltcWholesalerName",
      map: "ltcWholesalerName",
      ...TableHeader.IS_FILTERABLE
    }),
    isGroupThreePlus.value
      ? new TableHeader({
          text: "Actions",
          value: "actions"
        })
      : null
  ].filter(Boolean),
  getData: getMarketingManagers
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function editMarketingManager(marketingManager) {
  if (!isGroupThreePlus.value) return;
  const res = await dialog.showDialog({
    component: "MarketingManagerEditDialog",
    ...marketingManager,
    scrollable: true
  });
  if (res?.saved) getData();
}

getData();
</script>
