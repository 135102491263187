<template>
  <v-card tile flat>
    <v-card-title> Background Questions </v-card-title>
    <v-card-text class="pb-0"> Answer a few legal questions for us </v-card-text>
    <v-card-text>
      <v-row dense>
        <contracting-question
          v-for="(_, questionId) in agent.contractingQuestions"
          :key="questionId"
          :question-id="questionId"
          :readonly="props.readonly"
        />
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import ContractingQuestion from "@/components/settings/background-questions/ContractingQuestion.vue";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { computed,  onMounted, watch } from "vue";
const props = defineProps({ readonly: Boolean });
const agent = useAgentSettingsStore();

const isValid = computed(() =>
  Object.keys(agent.contractingQuestions).every(id => {
    const question = agent.contractingQuestions[id];
    const validSubComponents = Boolean(
      Object.values(question.explanations).length &&
        Object.values(question.documents).length
    );

    let isVisible = true;
    if (question.parentId) {
      isVisible = agent.contractingQuestions[question.parentId].answer === true;
    }

    if (!isVisible) return true;

    if (![false, true].includes(question.answer)) return false;

    const hasChildren = Boolean(question.childIds.length);
    if (hasChildren) return [true, false].includes(question.answer);

    return question.answer === false || validSubComponents;
  })
);

watch(isValid, () => setValidPage());

function setValidPage() {
  agent.setValidPage({
    page: "background-questions",
    valid: Boolean(isValid.value)
  });
}

setValidPage();

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
