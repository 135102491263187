<template>
  <div class="pa-0 ma-0" data-testid="file-drag-and-drop">
    <textarea-field
      v-cloak
      readonly
      no-resize
      rows="2"
      :prepend-inner-icon="prependInnerIcon"
      :success="success"
      :error-messages="errorMessages"
      :label="label ? label : ''"
      :placeholder="placeholder"
      :disabled="disabled || uploading || loading"
      :loading="uploading || loading"
      :persistent-hint="Boolean($slots.message)"
      :hint="$slots.message ? ' ' : null"
      :hide-details="hideDetails"
      @click="openFileInput"
      @drop.prevent.stop="addDropFile"
      @dragover.prevent.stop
      @blur="emit('blur')"
    >
      <template #append-inner>
        <v-progress-circular v-if="uploading" indeterminate />
        <div v-else class="mx-n9 mt-3" style="pointer-events: none">
          <v-file-input
            ref="fileInput"
            v-model="readonlyFiles"
            hide-input
            hide-details
            :multiple="multiple"
            :disabled="disabled || uploading || loading"
            :accept="accept"
          />
        </div>
        <slot name="append-inner" />
      </template>
      <template #message="{ message }">
        <v-row class="ma-0">
          {{ message }}
          <v-spacer />
          <slot name="message" />
        </v-row>
      </template>
      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
    </textarea-field>
    <v-row v-if="files.length > 0" class="pa-3 pt-0" align="center">
      <v-chip
        v-for="(file, index) in files"
        :key="index"
        :closable="true"
        :text="file.name"
        color="accent"
        variant="flat"
        :disabled="disabled || uploading || loading"
        @click:close="removeFile(index)"
      />
    </v-row>
  </div>
</template>

<script setup>
import { ref, watch, computed, toRaw } from "vue";
import { ALL_FILE_EXTENSIONS } from "@/data/allowed-file-extensions";
const props = defineProps({
  prependInnerIcon: { type: String, default: null },
  multiple: Boolean,
  success: Boolean,
  errorMessages: { type: Array, default: () => [] },
  label: { type: String, default: null },
  modelValue: { type: [Array, File], default: null },
  disabled: Boolean,
  accept: {
    type: String,
    default: ALL_FILE_EXTENSIONS
  },
  upload: Boolean,
  uploadFunc: { type: Function, required: false, default: () => {} },
  loading: Boolean,
  hideDetails: Boolean
});

const emit = defineEmits(["update:model-value", "blur"]);
const files = ref([]);
const fileInput = ref(); //templateref

const uploading = ref(false);
const fileOrFiles = props.multiple ? "Files" : "File";

const readonlyFiles = computed({
  get() {
    return files.value;
  },
  set(val) {
    let newFile = val;
    if (!Array.isArray(val)) newFile = [val].filter(Boolean);
    if (!newFile.length) return;
    if (props.multiple) {
      files.value.push(...newFile);
    } else {
      files.value.splice(0, files.value.length, newFile[0]);
    }
  }
});

const placeholder = computed(() => {
  if (files.value.length === 0)
    return `Drag and drop your ${fileOrFiles.toLowerCase()} here or click to upload`;

  let fileNames = "";

  files.value.forEach(file => (fileNames += `${file.name}, `));

  return `${fileOrFiles} to be uploaded: ${fileNames.trim().slice(0, -1)}.`;
});

function openFileInput() {
  fileInput.value.$el.querySelector("[role=button]").click();
}

function addDropFile(e) {
  if (props.disabled) return;

  let validatedFiles = Array.from(e.dataTransfer.files);
  const acceptableExtensions = props.accept.split(",").filter(Boolean);
  validatedFiles = validatedFiles.filter(val => {
    const split = val.name.toLowerCase().split(".").filter(Boolean);
    if (split.length === 1) return false;
    const extension = `.${split[split.length - 1].trim().toLowerCase()}`;
    const isValid = acceptableExtensions.some(
      v => v.trim().localeCompare(extension) === 0
    );

    return isValid;
  });
  if (!validatedFiles.length) return;
  if (props.multiple) {
    files.value.push(...validatedFiles);
  } else {
    files.value.splice(0, files.value.length, validatedFiles[0]);
  }
}

function removeFile(index) {
  if (props.disabled) return;
  files.value.splice(index, 1);
}

watch(
  () => props.modelValue,
  v => {
    if (!v) {
      files.value.splice(0, files.value.length);
      return;
    }

    if (Array.isArray(v)) files.value.splice(0, files.value.length, ...v);
    else files.value.splice(0, files.value.length, v);
  },
  { immediate: true }
);

watch(
  files,
  () => {
    const f = props.multiple ? toRaw(files.value) : files.value[0];
    emit("update:model-value", f);
    if (!props.upload) return;
    if (!f || (Array.isArray(f) && !f.length)) return;
    uploading.value = true;
    props.uploadFunc(f).finally(() => (uploading.value = false));
  },
  { deep: true }
);
</script>
