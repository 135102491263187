<template>
  <div
    :style="{ 'font-size': textSize }"
    style="font-family: Roboto, Helvetica, Arial, sans-serif"
  >
    {{ linkParts.start }}
    <a v-if="linkParts.link" :href="props.buttonLink"> {{ linkParts.link }}</a>
    <span v-if="linkParts.end"> {{ linkParts.end }} </span>
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  fontSize: {
    type: Number,
    required: true
  },
  scale: {
    type: Number,
    required: true
  },
  buttonExplanationText: {
    type: String,
    required: true
  },
  buttonLink: {
    type: String,
    required: true
  }
});

const textSize = computed(
  () => `${Math.trunc((props.fontSize * props.scale * 1.25) / 100)}px`
);

const linkParts = computed(() => {
  const linkStart = props.buttonExplanationText.indexOf("[");
  const linkEnd = props.buttonExplanationText.indexOf("]");

  let start = props.buttonExplanationText,
    link = null,
    end = null;
  if (linkStart === -1 || linkEnd === -1 || !props.buttonLink) {
    return { start, link, end };
  }

  start = props.buttonExplanationText.slice(0, linkStart);
  link = props.buttonExplanationText.slice(linkStart + 1, linkEnd);
  end = props.buttonExplanationText.slice(linkEnd + 1);
  return { start, link, end };
});
</script>
