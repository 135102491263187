<template>
  <v-card flat tile>
    <v-row align="center" class="ma-0">
      <v-col data-testid="carrier-name">
        <strong style="font-size: 1.2rem"> {{ name }} </strong>
        <br />
        Carrier
      </v-col>
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit
            :id="carrierId"
            type="Carrier"
            data-testid="carrier-avatar"
            :avatar="avatar"
            :editable="isGroupTwoPlus"
            @avatar-update="updateAvatar"
          />
        </v-row>
      </v-col>
      <v-col cols="12">
        <card-items :items="details" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import CardItems from "@/components/shared/card-items/CardItems.vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useCarrierView } from "@/stores/carrier-view";
import { computed, ref } from "vue";
import { textItem } from "@/components/shared/card-items/card-items";

const {
  id: carrierId,
  name,
  naic,
  amBestRating,
  strifeApproved,
  avatar: carrierAvatar
} = storeToRefs(useCarrierView());

const { isGroupTwoPlus } = storeToRefs(useUserStore());

const avatar = ref(generateAvatarUrl(carrierAvatar.value));

function generateAvatarUrl(uri) {
  return `${uri}?time=${new Date().getTime()}`;
}

function updateAvatar(newUri) {
  avatar.value = generateAvatarUrl(newUri);
}

const details = computed(() => {
  return [
    {
      ...textItem({
        title: "Available in Quote & Apply",
        text: strifeApproved.value ? "Yes" : "No"
      }),
      classStyle: strifeApproved.value ? "font-weight-bold text-success" : null
    },
    textItem({
      title: "NAIC Code",
      text: naic.value ? `${naic.value}` : ""
    }),
    textItem({
      title: "AM Best Rating",
      text: amBestRating.value || ""
    })
  ];
});
</script>
