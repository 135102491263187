import { defineStore } from "pinia";
export const useTableStore = defineStore("table", {
  state: () => ({
    electronicApplicationsTable: {
      filter: {},
      options: {}
    },
    quotesTable: {
      filter: {},
      options: {}
    },
    casesTable: {
      filter: {},
      options: {}
    },
    paymentsTable: {
      filter: {},
      options: {}
    },
    advisorsTable: {
      filter: {},
      options: {}
    },
    appointmentsTable: {
      filter: {},
      options: {}
    },
    todoTable: {
      filter: {},
      options: {}
    },
    contractPartiesTable: {
      filter: {},
      options: {}
    },
    payPeriodTable: {
      filter: {},
      options: {}
    },
    leadsTable: {
      filter: {},
      options: {}
    },
    affiliationsTable: {
      search: ""
    },
    leadList: {
      filter: {},
      // different asc/desc
      options: {
        New: true,
        Sold: true,
        Contacted: false,
        Inactive: true,
        "Awaiting Signatures": true
      }
    }
  }),
  syncWithLocalStorage: true
});
