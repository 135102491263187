export function CommunicationPreferences(model = {}) {
  return {
    appointments: Boolean(model?.appointments),
    caseSummaries: Boolean(model?.caseSummaries),
    cases: Boolean(model?.cases),
    crossSell: Boolean(model?.crossSell),
    electronicApplications: Boolean(model?.electronicApplications),
    highPremiumCases: Boolean(model?.highPremiumCases),
    iul: Boolean(model?.iul),
    marketing: Boolean(model?.marketing),
    payPeriods: Boolean(model?.payPeriods),
    quickQuotes: Boolean(model?.quickQuotes),
    quoteSummaries: Boolean(model?.quoteSummaries),
    quotes: Boolean(model?.quotes)
  };
}

export function setCommunicationPreferencesFromRaw(raw = {}) {
  const preferences = CommunicationPreferences();
  preferences.appointments = Boolean(raw?.appointments);
  preferences.caseSummaries = Boolean(raw?.case_summaries);
  preferences.cases = Boolean(raw?.cases);
  preferences.crossSell = Boolean(raw?.cross_sell);
  preferences.electronicApplications = Boolean(raw?.electronic_applications);
  preferences.highPremiumCases = Boolean(raw?.high_premium_cases);
  preferences.iul = Boolean(raw?.iul);
  preferences.marketing = Boolean(raw?.marketing);
  preferences.payPeriods = Boolean(raw?.pay_periods);
  preferences.quickQuotes = Boolean(raw?.quick_quotes);
  preferences.quoteSummaries = Boolean(raw?.quote_summaries);
  preferences.quotes = Boolean(raw?.quotes);
  return preferences;
}

export function getCommunicationPreferenceRequest(preferences, attribute) {
  return {
    appointments: { appointments: preferences.appointments },
    caseSummaries: { case_summaries: preferences.caseSummaries },
    cases: { cases: preferences.cases },
    crossSell: { cross_sell: preferences.crossSell },
    electronicApplications: {
      electronic_applications: preferences.electronicApplications
    },
    highPremiumCases: { high_premium_cases: preferences.highPremiumCases },
    iul: { iul: preferences.iul },
    marketing: { marketing: preferences.marketing },
    payPeriods: { pay_periods: preferences.payPeriods },
    quickQuotes: { quick_quotes: preferences.quickQuotes },
    quoteSummaries: { quote_summaries: preferences.quoteSummaries },
    quotes: { quotes: preferences.quotes }
  }[attribute];
}
