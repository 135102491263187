<template>
  <v-card
    flat
    color="section"
    :style="{ paddingBottom: table.items.value.length < 5 ? '10rem' : null }"
  >
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        class="transparent-data-table"
        data-testid="backnine-statement-transactions-table"
        must-sort
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :footer-props="table.footerProps.value"
        :loading="table.loading.value"
        :items-per-page-options="table.itemsPerPageOptions"
        :mobile="null"
        mobile-breakpoint="sm"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Transactions</h1>
            <v-spacer />
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  variant="text"
                  density="comfortable"
                  class="table-btn"
                  :icon="mdiRefresh"
                  v-bind="templateProps"
                  data-testid="backnine-statement-transactions-table-refresh"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.policyNumber`]="{ item }">
          <router-link :to="getRouterLink('Case', item.additional.case.id)">
            {{ item.policyNumber }}
          </router-link>
        </template>
        <template #[`item.amount`]="{ item }">
          <currency-formatter :model-value="item.amount" />
        </template>
        <template #[`item.override`]="{ item }">
          <currency-formatter :model-value="item.override" />
        </template>
        <template #[`item.directCommission`]="{ item }">
          <currency-formatter :model-value="item.directCommission" />
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { commissionTypes } from "@/data/filter-options";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";

import { getTransactionsRelatedToStatement } from "@/api/statements.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { mdiRefresh } from "@mdi/js";

const props = defineProps({ id: { type: [Number, String], required: true } });
const emit = defineEmits(["total"]);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Transaction ID",
      value: "id",
      map: "id",
      sortFilterMap: "transaction_id",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policyNumber",
      map: "case.policyNumber",
      sortFilterMap: "cases.stripped_policy_number",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType",
      selectableOptions: commissionTypes,
      sortFilterMap: "transactions.commission_type",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Amount",
      value: "amount",
      map: "amount",
      sortFilterMap: "transactions.amount",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Override",
      value: "override",
      map: "override",
      sortFilterMap: "transactions.override",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Direct Commission",
      value: "directCommission",
      map: "directCommission",
      sortFilterMap: "transactions.direct_commission",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: v => getTransactionsRelatedToStatement(props.id, v),
  options: TableOptions({ sortBy: [{ key: "policyNumber", order: "desc" }] })
});

async function getData() {
  try {
    await table.getData();
    emit("total", table.meta.value.total);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
