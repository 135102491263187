<template>
  <div ref="parentElement" class="parent">
    <chat-view-message
      v-for="(message, index) in messages"
      :key="`${index}-${message.id}`"
      class="message"
      data-testid="chat-message"
      :message="message"
      @remove-message="emit('remove-message', $event)"
    />
    <div ref="scrollToElement"></div>
  </div>
</template>
<script setup>
import ChatViewMessage from "@/components/shared/chat/ChatViewMessage.vue";
import { nextTick, ref, toRefs, watch } from "vue";

const props = defineProps({ messages: { type: Array, default: () => [] } });
const emit = defineEmits(["remove-message"]);
const { messages } = toRefs(props);

const scrollToElement = ref(null); // templatref
const parentElement = ref(null);
watch(
  [messages, scrollToElement, parentElement],
  () => {
    nextTick(() => {
      if (!scrollToElement.value || !scrollToElement.value) return;
      parentElement.value.scrollTo(0, scrollToElement.value.offsetTop);
    });
  },
  { immediate: true }
);
</script>
