<template>
  <div v-if="showActions" class="view-actions">
    <v-spacer />
    <app-button
      v-if="isEditable"
      class="text-none my-1 rounded-pill"
      :color="toggleAction.color"
      variant="outlined"
      data-testid="toggle-status"
      :prepend-icon="toggleAction.icon"
      :text="toggleAction.text"
      :block="smAndDown"
      @click="toggleStatus"
    />
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { useSupportTicketView } from "@/stores/support-ticket-view";
import { useTheme } from "vuetify/lib/framework.mjs";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { storeToRefs } from "pinia";
import {
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMarkedCircleOutline
} from "@mdi/js";

const { smAndDown } = useTheme();
const user = useUserStore();
const dialog = useDialogStore();
const supportTicketView = useSupportTicketView();
const isEditable = user.loginable.is_employee;

const { completedAt } = storeToRefs(supportTicketView);
const showActions = isEditable;

const toggleAction = computed(() => {
  if (completedAt.value) {
    return {
      icon: mdiCheckboxBlankCircleOutline,
      text: "Mark as Incomplete",
      value: false,
      color: "warning"
    };
  }
  return {
    icon: mdiCheckboxMarkedCircleOutline,
    text: "Mark as Completed",
    value: true,
    color: "success"
  };
});

function toggleStatus() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: toggleAction.value.text,
    subtitle: "Please confirm your intent.",
    func: () => supportTicketView.updateStatus(toggleAction.value.value)
  });
}
</script>
