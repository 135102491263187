<template>
  <v-card tile flat>
    <v-card-title>Developer Settings</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <developer-settings-api-keys
            v-if="primaryLogin"
            :module="props.module"
          />
          <v-card
            v-else
            flat
            color="section"
            data-testid="developer-settings-no-primary-login"
          >
            <v-card-title> API Key </v-card-title>
            <v-card-text>
              <v-alert type="info">
                No Primary Login exists for {{ name }}.
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <developer-settings-webhooks :module="props.module" />
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>

import DeveloperSettingsApiKeys from "@/components/settings/developer/DeveloperSettingsApiKeys.vue";
import DeveloperSettingsWebhooks from "@/components/settings/developer/DeveloperSettingsWebhooks.vue";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useSettingsViewStore } from "@/stores/settings-view";

import { onMounted } from "vue";
import { storeToRefs } from "pinia";
const emit = defineEmits(["mounted"]);

const props = defineProps({
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { primaryLogin, name } = storeToRefs(store);

onMounted(() => emit("mounted"));
</script>
