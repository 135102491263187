import { getHttpClient } from "@/http-client";

const baseUrl = "/api/v1/referrals";

export const getReferralInfo = async referralCode => {
  const { data } = await getHttpClient().get(`${baseUrl}/${referralCode}`);
  const {
    name,
    avatar_url: avatarUrl,
    message,
    hide_affiliations: hideAffiliations
  } = data;

  return { name, avatarUrl, message, hideAffiliations };
};
