<template>
  <v-card flat>
    <v-card-title>Access</v-card-title>
    <v-card-text>
      <access-table
        v-model="accesses"
        v-model:new-access="newAccess"
        data-testid="access-table"
        :checkboxes="checkboxes"
        :update-func="updateAccess"
        :delete-func="deleteAccess"
        :add-func="addAccess"
      >
        <template #new-access>
          <advisor-search
            v-model="newAccess"
            hide-details
            data-testid="access-advisor-search"
          />
        </template>
      </access-table>
    </v-card-text>
    <v-col class="pt-0">
      <p class="mb-0 text-caption text-grey">
        1. Email will email the advisor when notes are made on the Electronic
        Application.
      </p>
      <p class="mb-0 text-caption text-grey">
        2. Add to Case will add the advisor to the case once the Electronic
        Application is complete.
      </p>
      <p class="mb-0 text-caption text-grey">
        3. Round Robin will randomly add one advisor to the Electronic
        Application and make them the agent.
        <a @click="showRoundRobinArticle"> Read about Round Robin. </a>
      </p>
    </v-col>
  </v-card>
</template>

<script setup>
import AccessTable from "@/components/shared/AccessTable.vue";
import HelpCenterReader from "@/components/help-center/HelpCenterReader.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";

import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { ARTICLES } from "@/data/articles";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { markRaw, ref } from "vue";

const dialog = useDialogStore();
const approvedDomain = useApprovedDomainView();

const { accesses } = storeToRefs(approvedDomain);

const newAccess = ref(null);
const checkboxes = [
  { text: "Email¹", value: "email" },
  { text: "Additional Case Access²", value: "additionalCaseAccess" },
  {
    text: "Round Robin³",
    value: "roundRobin",
    if: val => val?.type === "Agent"
  }
];
async function deleteAccess(access) {
  return approvedDomain.deleteApprovedDomainAccess(access.id);
}

async function addAccess(advisor) {
  return approvedDomain.createApprovedDomainAccess(advisor);
}

function updateAccess(access, attribute) {
  return approvedDomain.updateApprovedDomainAccess(access.id, attribute);
}

function showRoundRobinArticle() {
  dialog.showDialog({
    component: markRaw(HelpCenterReader),
    id: ARTICLES.ROUND_ROBIN
  });
}
</script>
