<template>
  <v-card
    flat
    color="section"
    :style="{
      paddingBottom: table.mappedItems.value.length < 5 ? '10rem' : null
    }"
  >
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        data-testid="backnine-statement-payments-table"
        class="transparent-data-table"
        must-sort
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :loading="table.loading.value"
        :footer-props="table.footerProps.value"
        :items-per-page-options="table.itemsPerPageOptions"
        :mobile="null"
        mobile-breakpoint="sm"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Payments</h1>
            <v-spacer />
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  v-bind="templateProps"
                  variant="text"
                  class="table-btn"
                  density="comfortable"
                  data-testid="payments-table-refresh"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <table-filter
            key="Payments"
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.policyNumber`]="{ item }">
          <router-link :to="getRouterLink('Case', item.additional.caseId)">
            {{ item.policyNumber }}
          </router-link>
        </template>
        <template #[`item.premium`]="{ item }">
          <currency-formatter :model-value="item.premium" />
        </template>
        <template #[`item.percent`]="{ item }">
          <percentage-formatter
            :model-value="item.percent"
            is-decimal
            :decimal-length="3"
          />
        </template>
        <template #[`item.dollar`]="{ item }">
          <currency-formatter :model-value="item.dollar" />
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";

import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { paymentTypes, commissionTypes } from "@/data/filter-options";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";

import { TableOptions } from "@/classes/data-table/TableOptions";

import { getPaymentsRelatedToStatement } from "@/api/statements.service";
import { useSnackbarStore } from "@/stores/snackbar";

import { useTable } from "@/composables/table.composable";
import { mdiRefresh } from "@mdi/js";

const props = defineProps({ id: { type: [Number, String], default: null } });

const table = useTable({
  headers: [
    new TableHeader({
      text: "Policy Number",
      value: "policyNumber",
      map: "policyNumber",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "cases.stripped_policy_number"
    }),
    new TableHeader({
      text: "Assigned Payable",
      value: "assignedPayable",
      map: "commissionAssignedPayableName",
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      sortFilterMap: [
        { key: "assigned_payable_id", value: "id" },
        { key: "assigned_payable_type", value: "type" }
      ]
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      selectableOptions: commissionTypes,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "payments.commission_type"
    }),
    new TableHeader({
      text: "Payment Type",
      value: "paymentType",
      map: "paymentType",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      selectableOptions: paymentTypes,
      ...TableHeader.IS_FILTERABLE,
      sortFilterMap: "commissions.payment_type"
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      sortFilterMap: "payments.premium"
    }),
    new TableHeader({
      text: "Percent",
      value: "percent",
      map: "percent",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      sortFilterMap: "payments.percent"
    }),
    new TableHeader({
      text: "Dollar",
      value: "dollar",
      map: "dollar",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      sortFilterMap: "payments.dollar"
    })
  ],
  getData: v => getPaymentsRelatedToStatement(props.id, v),
  options: TableOptions({ sortBy: [{ key: "policyNumber", order: "desc" }] })
});

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
</script>
