export function RecentElectronicApplication(model = {}) {
  return {
    status: model?.status || "",
    benefitAmount: model?.benefitAmount || 0,
    id: model?.id || 0,
    name: model?.name || "",
    productName: model?.productName || "",
    carrierName: model?.carrierName || ""
  };
}

export function setRecentElectronicApplicationFromRequest(raw = {}) {
  const model = RecentElectronicApplication();
  model.status = raw?.status;
  model.benefitAmount = raw?.benefit_amount;
  model.id = raw?.id;
  model.name = raw?.name;
  model.productName = raw?.product?.name;
  model.carrierName = raw?.product?.carrier?.name;
  return model;
}
