<template>
  <v-card flat tile color="section">
    <v-card-text class="pa-0">
      <v-timeline>
        <v-timeline-item
          v-for="(item, index) in timeline"
          :key="`step-${index}`"
          data-testid="timeline-item"
          dot-color="primary"
          :class="{ 'text-right': index % 2 === 1 }"
          :icon="mdiCheck"
        >
          <v-col>
            <h3 class="opacity-60">{{ item.description }}</h3>
            <h5 class="opacity-100">
              <timestamp-formatter :model-value="item.date" parser="sole-day" />
            </h5>
          </v-col>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { mdiCheck } from "@mdi/js";
const props = defineProps({
  items: { type: Array, required: true }
});

const timeline = props.items
  .toSorted((a, b) => new Date(a.date) - new Date(b.date))
  .filter(v => v.date);
</script>
