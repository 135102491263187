<template>
  <v-card>
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        must-sort
        data-testid="leads-table"
        :mobile="null"
        mobile-breakpoint="sm"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :loading="table.loading.value"
        :footer-props="table.footerProps.value"
        :items-per-page-options="table.itemsPerPageOptions"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5">Leads</h1>
            <v-spacer />
            <v-tooltip location="top">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  variant="text"
                  density="comfortable"
                  class="table-btn"
                  v-bind="templateProps"
                  data-testid="leads-table-refresh"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.email`]="{ item }">
          <a :href="`mailto:${item.email}`"> {{ item.email }} </a>
        </template>
        <template #[`item.phone`]="{ item }">
          <a
            :href="`tel:${item.phone}`"
            data-outbound-type="Lead"
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.phone"
          >
            {{ item.phone }}
          </a>
        </template>
        <template #[`item.name`]="{ item }">
          <app-button
            color="primary"
            class="text-none"
            variant="flat"
            size="small"
            :to="{ name: 'LeadView', params: { id: item.additional.id } }"
          >
            <v-icon class="mr-1" size="small" :icon="mdiHumanGreeting" />
            {{ item.name || "Unnamed Lead" }}
          </app-button>
        </template>
        <template #[`item.followUp`]="{ item }">
          <until-time-formatter :model-value="item.followUp" />
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import UntilTimeFormatter from "@/components/shared/formatters/UntilTimeFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { getLeads } from "@/api/leads.service";
import { LEAD_CATEGORIES } from "@/factories/Lead";
import { STATES } from "@/data/states";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { mdiRefresh, mdiHumanGreeting } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";

useHead({ title: "Leads" });

const { leadsTable } = storeToRefs(useTableStore());

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      sortFilterMap: "leads.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "E-mail",
      value: "email",
      map: "email",
      sortFilterMap: "leads.email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Phone",
      value: "phone",
      map: "phone",
      sortFilterMap: "leads.phone_work",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "state",
      selectableOptions: STATES,
      sortFilterMap: "addresses.state",
      ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Manager",
      value: "manager",
      map: "manager",
      sortKey: "marketing_manager_id",
      sortFilterMap: [
        { key: "marketing_manager_id", value: "marketing_manager_id" }
      ],
      ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUp",
      map: "followUp",
      sortFilterMap: "leads.follow_up_date",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      selectableOptions: LEAD_CATEGORIES,
      sortFilterMap: "leads.category",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    })
  ],
  getData: getLeads
});

table.ingestFromStore({
  filter: leadsTable.value.filter,
  options: leadsTable.value.options,
  defaultFilter: {},
  defaultOptions: TableOptions({ sortBy: [{ key: "followUp", order: "desc" }] })
});

function updateOptions(newOptions) {
  leadsTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  leadsTable.value.filter = filter;
  table.resetPage();
  getData();
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
</script>
