<template>
  <v-card>
    <v-row class="ma-0 pt-3" align="center">
      <v-card-title>Production by Carrier</v-card-title>
      <v-spacer />
      <text-field
        v-model="search"
        data-lpignore="true"
        label="Search"
        data-testid="search-production-by-carrier-table"
        :prepend-inner-icon="mdiMagnify"
        hide-details
        class="mx-3"
      />
      <v-tooltip location="bottom">
        <template #activator="{ props: templateProps }">
          <app-button
            :icon="mdiRefresh"
            variant="text"
            density="comfortable"
            v-bind="templateProps"
            :disabled="loading"
            @click="getData"
          />
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </v-row>
    <v-data-table
      v-model:search="search"
      v-model:sort-by="table.options.value.sortBy"
      must-sort
      data-testid="production-by-carrier-table"
      :mobile="null"
      mobile-breakpoint="sm"
      :headers="table.tableHeaders.value"
      :items="table.mappedItems.value"
      :loading="table.loading.value"
    />
  </v-card>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import {
  currencyFormat,
  numberFormat,
  parseErrorMessage
} from "@/util/helpers";
import { getProductionByCarrierReport } from "@/api/reports.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";
import { ref } from "vue";
import { mdiMagnify, mdiRefresh } from "@mdi/js";
import { TableOptions } from "@/classes/data-table/TableOptions";

const props = defineProps({
  all: Boolean
});
const snackbar = useSnackbarStore();
const loading = ref(false);
const search = ref("");

const currentYear = new Date().getFullYear();
const previousYear = currentYear - 1;
const headers = [
  new TableHeader({
    text: "Carrier",
    value: "carrier",
    map: "carrier",
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${currentYear} Active Cases`,
    value: "currentYearCaseCount",
    map: "currNumberOfCasesFormatted",
    sortRaw: (a, b) =>
      a.additional.currNumberOfCases - b.additional.currNumberOfCases,
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${currentYear} Production Credit`,
    value: "currentYearProductionCredit",
    map: "currProductionCreditFormatted",
    sortRaw: (a, b) =>
      a.additional.currProductionCredit - b.additional.currProductionCredit,
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${previousYear} Active Cases`,
    value: "previousYearCaseCount",
    map: "prevNumberOfCasesFormatted",
    sortRaw: (a, b) =>
      a.additional.prevNumberOfCases - b.additional.prevNumberOfCases,
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  }),
  new TableHeader({
    text: `${previousYear} Production Credit`,
    value: "previousYearProductionCredit",
    map: "prevProductionCreditFormatted",
    sortRaw: (a, b) =>
      a.additional.prevProductionCredit - b.additional.prevProductionCredit,
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_FILTERABLE
  })
];

const table = useTable({
  headers,
  options: TableOptions({ sortBy: [{ key: "currentYearProductionCredit", order: "desc" }] })
});

async function getData() {
  table.loading.value = true;
  try {
    const params = new URLSearchParams();
    if (props.all) params.append("view", "all");
    const items = await getProductionByCarrierReport(params);

    table.items.value = items.map(i => ({
      ...i,
      currNumberOfCasesFormatted: numberFormat(i.currNumberOfCases),
      currProductionCreditFormatted: currencyFormat(i.currProductionCredit, 0),
      prevNumberOfCasesFormatted: numberFormat(i.prevNumberOfCases),
      prevProductionCreditFormatted: currencyFormat(i.prevProductionCredit, 0)
    }));
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    table.loading.value = false;
  }
}

getData();
</script>
