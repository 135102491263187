import { createApp, markRaw } from "vue";
import App from "@/App.vue";
import "@/stylesheet/main.scss";
import router from "@/router";
import vuetify from "@/plugins/vuetify";

import browserUpdate from "browser-update";
import { createPinia } from "pinia";
import VueGtag from "vue-gtag";
import { createHead } from "@unhead/vue";
import { useInstanceStore } from "@/stores/instance";
import { syncWithLocalStoragePlugin } from "@/stores/sync-with-local-storage-plugin";
import { registerComponents } from "@/plugins/global-components";

import * as Sentry from "@sentry/vue";

const app = createApp(App, { name: "BOSS" });

const mountEl = document.querySelector("#app");

const head = createHead();
app.use(head);
const pinia = createPinia();
app.use(pinia);

Sentry.init({
  app,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: "https://51e55b8039fe1d15dc93bb3f3057da7b@o4506661584568320.ingest.us.sentry.io/4508321207681025",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Tracing
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", "https://app.back9ins.com", "https://demo.back9ins.com", "https://staging.back9ins.com"],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.use(vuetify(mountEl?.dataset?.nonce));
registerComponents(app);

browserUpdate({
  required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
  insecure: false,
  api: 2020.11
});

pinia.use(({ store }) => (store.router = markRaw(router)));
pinia.use(syncWithLocalStoragePlugin);

if (process.env.NODE_ENV === "production") {
  app.use(
    VueGtag,
    {
      config: { id: import.meta.env.VITE_GTAG }
    },
    router
  );
}

const instance = useInstanceStore(pinia);
instance.apiUrl = import.meta.env.VITE_API_URL;
instance.helpCenterUrl = import.meta.env.VITE_HELP_CENTER;
instance.nonce = mountEl?.dataset?.nonce;

instance.setCsrfToken().then(() => app.mount("#app"));
