<template>
  <v-card>
    <v-row class="ma-0" align="center">
      <v-card-title>Applications </v-card-title>
      <v-spacer />
      <carrier-search
        v-model="carrier"
        data-testid="carrier-search"
        hide-details
        clearable
        class="mx-3"
        @update:model-value="getReportData"
      />
    </v-row>
    <v-divider />
    <v-card-text>
      <v-col cols="12" align="center">
        <div v-if="chartOptions.series.length" class="highcharts-areaspline">
          <highcharts-graph
            :options="chartOptions"
            data-testid="applications-graph"
            style="border-radius: 5px"
          />
        </div>
        <div v-else-if="loaded">
          <v-alert color="error">Unable to load Premium Reports</v-alert>
        </div>
        <v-progress-circular v-else indeterminate />
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { getApplicationsReport } from "@/api/reports.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    CarrierSearch,
    HighchartsGraph: defineAsyncComponent(() =>
      import("@/components/shared/HighchartsGraph.vue")
    )
  },
  props: {
    all: Boolean
  },
  data() {
    return {
      carrier: null,
      loaded: false,
      chartOptions: {
        chart: {
          styledMode: true,
          type: "areaspline",
          style: {
            fontFamily: "Roboto"
          }
        },
        title: {
          text: "Submitted and Inforce Cases by Month",
          style: {
            textTransform: "none"
          }
        },
        tooltip: {
          xDateFormat: "%B"
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%b %Y"
          }
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Number of cases"
          }
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false
            }
          }
        },
        series: [],
        defs: {
          gradient0: {
            tagName: "linearGradient",
            id: "gradient-0",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          },
          gradient1: {
            tagName: "linearGradient",
            id: "gradient-1",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          }
        }
      }
    };
  },
  created() {
    this.getReportData();
  },

  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getReportData() {
      const params = new URLSearchParams();
      if (this.all) params.append("view", "all");
      if (this.carrier?.id) params.append("carrier_id", this.carrier.id);
      try {
        const res = await getApplicationsReport(params);
        this.chartOptions.series = res;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loaded = true;
      }
    }
  }
};
</script>
