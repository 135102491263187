<template>
  <v-menu
    v-model="showMenu"
    left
    offset-y
    width="600"
    :close-on-content-click="false"
  >
    <template #activator="{ props }">
      <app-button
        data-testid="notifications-indicator-button"
        v-bind="props"
        variant="text"
        :loading="loading"
        icon
      >
        <v-badge
          data-testid="notifications-indicator-count"
          color="error"
          :model-value="Boolean(total)"
          floating
        >
          <template #badge>
            {{ total }}
          </template>
          <v-icon class="opacity-60" :icon="mdiBell" />
        </v-badge>
      </app-button>
    </template>
    <v-card>
      <view-tabs v-model="activeTab" grow :tabs="tabs" />
    </v-card>
  </v-menu>
</template>

<script setup>
import ViewTabs from "@/components/shared/ViewTabs.vue";
import NotificationTypeList from "@/components/notifications/NotificationTypeList.vue";

import { mdiBell, mdiCheck, mdiFaceAgent } from "@mdi/js";
import { storeToRefs } from "pinia";
import { useNotificationsStore } from "@/stores/notifications.js";
import { computed, markRaw, ref, watch } from "vue";
import { useRoute } from "vue-router";

const notifications = useNotificationsStore();
const showMenu = ref(false);
const route = useRoute();

const activeTab = ref(0);

const { total, todos, todoCount, supportTickets, supportTicketCount, loading } =
  storeToRefs(notifications);
notifications.getDueTodos();

notifications.getIncompleteSupportTickets();

const tabs = computed(() => {
  const tabs = [
    {
      title: "To-Dos",
      icon: mdiCheck,
      total: todoCount.value,
      component: markRaw(NotificationTypeList),
      componentProps: {
        items: todos.value,
        viewAllLink: { name: "Tables", query: { page: "todos" } },
        title: "To-Dos"
      },
      badgeProps: {
        floating: true,
        "offset-y": 3,
        color: "error",
        modelValue: Boolean(todoCount.value),
        content: todoCount.value,
        "data-testid": "todos-badge"
      }
    }
  ];

  tabs.push({
    title: "Support Tickets",
    icon: mdiFaceAgent,
    total: supportTicketCount.value,
    component: markRaw(NotificationTypeList),
    componentProps: {
      items: supportTickets.value,
      viewAllLink: { name: "HelpCenter" },
      title: "Support Tickets"
    },
    badgeProps: {
      floating: true,
      "offset-y": 3,
      color: "error",
      modelValue: Boolean(supportTicketCount.value),
      content: supportTicketCount.value,
      "data-testid": "support-tickets-badge"
    }
  });

  return tabs;
});

watch(
  () => route.path,
  () => (showMenu.value = false)
);

watch(loading, v => {
  if (v) return;

  if (!todoCount.value && supportTicketCount.value) activeTab.value = 1;
});
</script>
