<template>
  <v-card flat color="section">
    <v-card-title>Commission Splits</v-card-title>
    <v-card-text v-if="showAssignmentBlurb">
      Your commissions are locked which disables your ability to modify your
      Commission Splits.
      <template v-if="!allCommissionSplits.length">
        You do not have any commission splits.
      </template>
      <a target="_blank" @click="showCommissionSplitsArticle">
        Learn about Commission Splits.
      </a>
    </v-card-text>
    <v-card-text v-else>
      Specify the agent(s) you would like to split
      <strong>all Quote & Apply commissions </strong> with. The agent(s) you
      list will automatically be added on the agent's report for their
      respective percentage as an additional agent. The agent(s) must be
      licensed in the state of the application in order to be added to the
      application. The minimum commission split is 1%.
      <a target="_blank" @click="showCommissionSplitsArticle">
        Learn about Commission Splits.
      </a>
    </v-card-text>
    <v-card-text v-if="!showAssignmentBlurb || allCommissionSplits.length">
      <commission-splits
        v-model="allCommissionSplits"
        :disabled="agent.lockCommissions"
        :advisor-name="agent.name"
        :update-func="agent.updateCommissionSplit"
        :delete-func="agent.deleteCommissionSplit"
        :create-func="agent.createCommissionSplit"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import CommissionSplits from "@/components/shared/CommissionSplits.vue";
import { ARTICLES } from "@/data/articles";
import { useAgentSettingsStore } from "@/stores/agent-settings";
import { useDialogStore } from "@/stores/dialog";
import { computed } from "vue";
const agent = useAgentSettingsStore();

const allCommissionSplits = [];
Object.values(agent.commissionSplits).forEach(c => allCommissionSplits.push(c));

const showAssignmentBlurb = computed(() => {
  if (agent.lockCommissions) return true;
  const isSigner =
    agent.assignment.type === "Agency" && agent.agencies[agent.assignment.id];

  if (isSigner) return false;
  return agent.assignment.id && agent.lockCommissions;
});

const dialog = useDialogStore();
function showCommissionSplitsArticle() {
  dialog.showDialog({
    component: "HelpCenterReader",
    id: ARTICLES.COMMISSION_SPLITS
  });
}
</script>
