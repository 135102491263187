<template>
  <v-card>
    <v-card-title> {{ props.title }}</v-card-title>
    <v-card-subtitle> {{ props.subtitle }}</v-card-subtitle>
    <v-card-text>
      <text-field
        v-model="model"
        data-testid="edit-text-input"
        :label="props.label"
        :success="modelValidation.success"
        :error-messages="modelValidation.errorMessages"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        class="text-none"
        variant="outlined"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="edit-text-save"
        :loading="saving"
        @click="save"
      >
        Save
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed,  ref } from "vue";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const props = defineProps({
  title: { type: String, required: true },
  subtitle: { type: String, required: true },
  label: { type: String, required: true },
  modelValue: { type: String, required: true },
  func: { type: Function, required: true },
  rules: { type: Object, required: true },
  rulesMessages: { type: Array, required: true }
});

const saving = ref(false);
const model = ref(props.modelValue);
const v$ = useVuelidate(
  { model: props.rules },
  { model },
  { $autoDirty: true, $scope: false }
);

const modelValidation = computed(() => {
  return validationComputeV2(v$.value.model, props.rulesMessages);
});

async function save() {
  const valid = await v$.value.$validate();

  if (!valid) return;
  try {
    saving.value = true;
    await props.func(model.value);
    dialog.closeDialog();
  } catch (e) {
    snackbar.showErrorSnackbar(parseErrorMessage(e));
  } finally {
    saving.value = false;
  }
}
</script>
