<template>
  <div>
    <v-row class="ma-0" align="center">
      <app-button
        variant="text"
        class="text-none mr-1 px-1 default-btn"
        data-testid="follow-up-button"
        :loading="verifyingActiveStatus"
        @click="verifyActiveStatus"
      >
        <v-icon class="mr-1" :icon="mdiRefresh" /> Follow Up At
        <timestamp-formatter :model-value="followUpAt" class="ml-1" />
      </app-button>
      <app-button
        :icon="mdiDownload"
        variant="text"
        density="comfortable"
        class="mr-1"
        color="primary"
        data-testid="download-form"
        v-bind="downloadForm()"
      />
      <app-button
        :icon="mdiPrinterEye"
        class="mr-1"
        variant="text"
        density="comfortable"
        color="info"
        data-testid="preview"
        :href="previewLink"
        target="_blank"
      />
      <div class="mr-1">
        <mapped-form-view-status-selector
          :loading="verifyingForm"
          :status="status"
          @status-update="toggleFormStatus"
        />
      </div>
      <app-button
        :icon="fullscreen ? mdiFullscreenExit : mdiFullscreen"
        variant="text"
        density="comfortable"
        class="default-btn"
        @click="fullscreen = !fullscreen"
      />
    </v-row>
  </div>
</template>
<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import MappedFormViewStatusSelector from "@/components/form-mapping/MappedFormViewStatusSelector.vue";

import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";
import { getPreviewUrl } from "@/api/forms.service";
import { computed, ref } from "vue";
import {
  mdiRefresh,
  mdiDownload,
  mdiPrinterEye,
  mdiFullscreenExit,
  mdiFullscreen
} from "@mdi/js";
import { storeToRefs } from "pinia";
import { useMappedFormStore } from "@/stores/mapped-form";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps({
  id: { type: [String, Number], required: true }
});

const { mdAndDown } = useDisplay();

const snackbar = useSnackbarStore();
const { fullscreen } = storeToRefs(useInstanceStore());

const formMapper = useMappedFormStore(props.id);
const { followUpAt, status, pdfUrl, verifyingForm } = storeToRefs(formMapper);

const verifyingActiveStatus = ref(false);

const previewLink = computed(() => getPreviewUrl(props.id));

function downloadForm() {
  return downloadFileAsLink(pdfUrl.value, mdAndDown.value);
}

async function verifyActiveStatus() {
  verifyingActiveStatus.value = true;
  try {
    await formMapper.verifyStatus();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    verifyingActiveStatus.value = false;
  }
}

async function toggleFormStatus(newStatus) {
  verifyingForm.value = true;
  try {
    await formMapper.updateFormStatus(newStatus);
    status.value = newStatus;
    snackbar.showSuccessSnackbar({
      message: `Successfully updated status to ${newStatus}`
    });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Error Verifying Form: ${parseErrorMessage(e)}`,
      timeout: -1
    });
  } finally {
    verifyingForm.value = false;
  }
}
</script>
