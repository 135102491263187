<template>
  <autocomplete-field
    v-model="pricingPlan"
    placeholder="Select a Pricing Plan"
    :label="label"
    item-title="name"
    return-object
    :prepend-inner-icon="mdiFloorPlan"
    hide-no-data
    :hide-details="hideDetails"
    :clearable="clearable"
    :disabled="disabled"
    :success="success"
    :error-messages="errorMessages"
    :items="pricingPlans"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="$emit('blur')"

    @click:clear="$emit('update:model-value', {})"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
  </autocomplete-field>
</template>

<script>
import { getAllPricingPlans } from "@/api/boss.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiFloorPlan } from "@mdi/js";

export default {
  props: {
    hideDetails: Boolean,
    clearable: Boolean,
    success: Boolean,
    errorMessages: { type: Array, default: () => [] },
    disabled: Boolean,
    modelValue: { type: [Object, String], default: null },
    label: {
      type: String,
      default: "Pricing Plan",
      required: false
    }
  },
  emits: ["update:model-value", "blur"],
  data() {
    let pricingPlans = [];
    let pricingPlan = null;
    if (this.modelValue) {
      pricingPlan = this.modelValue;
    }
    return {
      pricingPlans,
      pricingPlan,
      loading: false,
      mdiFloorPlan
    };
  },
  watch: {
    value(value) {
      if (value === this.pricingPlan) return;
      this.pricingPlan = value;
    },
    pricingPlan(value) {
      this.$emit("update:model-value", value);
    }
  },
  created() {
    this.getAllPricingPlans();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    getAllPricingPlans() {
      this.loading = true;
      getAllPricingPlans()
        .then(res => {
          this.pricingPlans = res.toSorted((a, b) =>
            a.name.localeCompare(b.name)
          );
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        });
    },
    clear() {
      this.pricingPlan = null;
      this.pricingPlans = [];
    }
  }
};
</script>
