<template>
  <v-card flat class="ma-1">
    <v-data-table-server
      v-model:sort-by="table.options.value.sortBy"
      v-model:items-per-page="table.options.value.itemsPerPage"
      v-model:page="table.options.value.page"
      must-sort
      data-testid="advisor-profile-cases-table"
      class="transparent-data-table"
      :mobile="null"
      hide-default-footer
      mobile-breakpoint="sm"
      :headers="table.tableHeaders.value"
      :items="table.mappedItems.value"
      :items-length="table.itemsLength.value"
      :loading="table.loading.value"
      :footer-props="table.footerProps.value"
      :items-per-page-options="table.itemsPerPageOptions"
      @update:options="getData"
    >
      <template #[`header.fy_premium`]="{ sortBy, column }">
        <inverted-sort
          :column="column"
          :sort-by="sortBy"
          @update:sort-by="updateSortBy"
        />
      </template>

      <template #[`item.insured`]="{ item }">
        <v-tooltip left>
          <template #activator="{ props: templateProps }">
            <router-link
              :to="getRouterLink('Case', item.additional.id)"
              class="text-none"
              v-bind="templateProps"
            >
              {{ item.insured }}
            </router-link>
          </template>
          <span>View Case</span>
        </v-tooltip>
      </template>
      <template #[`item.fy_premium`]="{ item }">
        <currency-formatter
          :model-value="item.fy_premium"
          :decimal-length="0"
        />
      </template>
      <template #[`item.updated`]="{ item }">
        <timestamp-formatter :model-value="item.updated" />
      </template>
      <template #bottom>
        <v-row class="ma-0 pa-3">
          <v-spacer />
          <app-button
            data-testid="advisor-profile-cases-table-view-all"
            color="primary"
            class="text-none"
            variant="text"
            :prepend-icon="mdiOpenInApp"
            :text="`View ${name}'s Cases`"
            @click="viewAll"
          />
        </v-row>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import InvertedSort from "@/components/shared/InvertedSort.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { parseErrorMessage, getRouterLink } from "@/util/helpers";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { emailCases, getCases } from "@/api/cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTable } from "@/composables/table.composable";

import { useTableStore } from "@/stores/table";
import { storeToRefs } from "pinia";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { mdiOpenInApp } from "@mdi/js";
import { useRouter } from "vue-router";

const snackbar = useSnackbarStore();
const router = useRouter();
const tableStore = useTableStore();
const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "insured.name",
      sortFilterMap: "contract_parties.name"
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrier_name",
      sortFilterMap: [{ key: "carrier_id", value: "id" }]
    }),
    new TableHeader({
      text: "Policy Number",
      value: "policy",
      map: "policy_number",
      sortFilterMap: "cases.policy_number"
    }),

    new TableHeader({
      text: "FY Premium",
      value: "fy_premium",
      map: "first_year",
      sortFilterMap: "cases.first_year",
      ...TableHeader.IS_INVERTED_SORT,
      ...TableHeader.IS_SORTABLE
    }),

    new TableHeader({
      text: "Status",
      value: "status",
      map: "status"
    }),

    new TableHeader({
      text: "Updated",
      value: "updated",
      map: "updated_at",
      sortFilterMap: "cases.updated_at",
      ...TableHeader.IS_SORTABLE
    })
  ],
  options: TableOptions({
    sortBy: [{ key: "updated", order: "desc" }]
  }),
  getData: getCases,
  emailData: emailCases
});

async function getData() {
  try {
    const additionalFilter = {
      shared_with_id: id.value,
      shared_with_type: type.value,
      view: "all"
    };

    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      color: "error"
    });
  }
}

function updateSortBy(newOptions) {
  table.options.value.sortBy = newOptions;
}

function viewAll() {
  tableStore.casesTable.filter = {
    additionalAdvisor: tableFilterData.value
  };
  router.push({ name: "CasesTable" });
}
</script>
