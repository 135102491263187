<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        :items="table.mappedItems.value"
        :headers="table.tableHeaders.value"
        data-testid="quote-table"
        class="transparent-data-table"
        :mobile="null"
        mobile-breakpoint="sm"
      >
        <template #top>
          <v-row class="pa-3">
            <v-spacer />
            <app-button
              class="text-none"
              data-testid="create-quote-request"
              color="accent"
              @click="createQuote"
            >
              <v-icon :icon="mdiPlus" />Create Quote Request
            </app-button>
          </v-row>
        </template>
        <template #[`item.id`]="{ index, item }">
          <router-link
            :data-test-id="`quote-table:column-id:${index}`"
            :to="{
              name: 'QuoteView',
              params: { id: item.id }
            }"
          >
            {{ item.id }}
          </router-link>
        </template>
        <template #[`item.status`]="{ index, item }">
          <div :data-test-id="`quote-table:column-id:${index}`">
            {{ item.status }}
          </div>
        </template>
        <template #[`item.createdAt`]="{ index, item }">
          <div :data-test-id="`quote-table:column-id:${index}`">
            <timestamp-formatter
              format="date-time"
              :model-value="item.createdAt"
            />
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SynthesizeQuoteRequestDialog from "@/dialogs/SynthesizeQuoteRequestDialog.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { useCaseViewStore } from "@/stores/case-view";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";
import { mdiPlus } from "@mdi/js";

const dialog = useDialogStore();

const { quotes, id } = storeToRefs(useCaseViewStore());
const headers = [
  new TableHeader({
    text: "ID",
    value: "id",
    map: "id"
  }),
  new TableHeader({
    text: "Status",
    value: "status",
    map: "status"
  }),
  new TableHeader({
    text: "Created",
    value: "createdAt",
    map: "created_at"
  })
];

const table = useTable({
  headers,
  items: quotes.value
});

function createQuote() {
  dialog.showDialog({
    component: markRaw(SynthesizeQuoteRequestDialog),
    id: id.value,
    idBodyKey: "case_id",
    scrollable: true
  });
}
</script>
