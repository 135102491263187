<template>
  <div>
    <v-divider />

    <div class="flex-row align-center justify-end pa-3" data-testid="data-table-footer">
      <div>Items per page:</div>
      <div style="min-width: 84px" class="mx-3">
        <select-field
          :model-value="itemsPerPage"
          :items="itemsPerPageOptions"
          hide-details
          data-testid="data-table-items-per-page"
          density="compact"
          @update:model-value="updateItemsPerPage"
        />
      </div>

      <div>{{ counterText }}</div>

      <app-button
        variant="text"
        density="comfortable"
        :class="{ 'table-btn': canGoPrevious }"
        :disabled="!canGoPrevious"
        :icon="mdiPageFirst"
        @click="goToBeginning"
      />
      <app-button
        variant="text"
        density="comfortable"
        data-testid="data-table-previous"
        :class="{ 'table-btn': canGoPrevious }"
        :disabled="!canGoPrevious"
        :icon="mdiChevronLeft"
        @click="goPrevious"
      />
      <app-button
        variant="text"
        density="comfortable"
        data-testid="data-table-next"
        :class="{ 'table-btn': canGoNext }"
        :disabled="!canGoNext"
        :icon="mdiChevronRight"
        @click="goNext"
      />
      <app-button
        variant="text"
        density="comfortable"
        :class="{ 'table-btn': canGoNext }"
        :disabled="!canGoNext"
        :icon="mdiPageLast"
        @click="goToEnd"
      />
    </div>
  </div>
</template>

<script setup>
import {
  mdiPageFirst,
  mdiChevronLeft,
  mdiChevronRight,
  mdiPageLast
} from "@mdi/js";
import { numberFormat } from "@/util/helpers";
import { computed, toRefs } from "vue";
const props = defineProps({
  itemsPerPageOptions: {
    type: Array,
    required: true
  },
  itemsLength: {
    type: Number,
    required: true
  },
  page: { type: Number, required: true },
  itemsPerPage: { type: Number, required: true }
});

const emit = defineEmits(["update:page", "update:items-per-page"]);

const { page, itemsPerPage, itemsLength } = toRefs(props);

const lowestCurrentPageValue = computed(() => {
  if (!itemsLength.value) return 0;
  return numberFormat(Math.max(1, (page.value - 1) * itemsPerPage.value + 1));
});

const highestCurrentPageValue = computed(() => {
  if (!itemsLength.value) return 0;
  return Math.min(itemsLength.value, page.value * itemsPerPage.value);
});

const counterText = computed(() => {
  const lowestText = numberFormat(lowestCurrentPageValue.value);
  const highestText = numberFormat(highestCurrentPageValue.value);
  const totalText = numberFormat(itemsLength.value);

  return `${lowestText} - ${highestText} of ${totalText}`;
});

const totalPageCount = computed(() =>
  Math.ceil(itemsLength.value / itemsPerPage.value)
);

const canGoPrevious = computed(() => page.value > 1);
const canGoNext = computed(() => page.value < totalPageCount.value);

function goToBeginning() {
  emit("update:page", 1);
}
function goPrevious() {
  emit("update:page", page.value - 1);
}
function goNext() {
  emit("update:page", page.value + 1);
}
function goToEnd() {
  emit("update:page", totalPageCount.value);
}

function updateItemsPerPage(e) {
  emit("update:items-per-page", e);
}
</script>
