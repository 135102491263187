<template>
  <v-row dense class="ma-0">
    <v-col cols="12">
      <marketing-manager-todo-report />
    </v-col>
    <template v-if="user.isGroupFour">
      <v-col cols="12">
        <marketing-manager-report />
      </v-col>
      <v-col cols="12">
        <marketing-manager-production-credit-report />
      </v-col>
    </template>
  </v-row>
</template>

<script setup>
import MarketingManagerTodoReport from "@/components/reports/MarketingManagerTodoReport.vue";
import MarketingManagerReport from "@/components/reports/MarketingManagerProfitReport.vue";
import MarketingManagerProductionCreditReport from "@/components/reports/MarketingManagerProductionCreditReport.vue";

import { useHead } from "@unhead/vue";
import { useUserStore } from "@/stores/user";

useHead({ title: "Marketing Manager Report" });

const user = useUserStore();
</script>
