<template>
  <text-field
    v-model="model"
    type="email"
    :prepend-inner-icon="mdiEmailOutline"
    data-lpignore="true"
    inputmode="email"
    :hide-details="$attrs.hideDetails"
    :clearable="clearable"
    :persistent-hint="persistentHint"
    :hint="hint"
    :disabled="disabled"
    :data-testid="dataTestid"
    :loading="validating"
    :autofocus="autofocus"
    :label="label"
    :success="success"
    :error-messages="validating || isTyping ? [] : errorMessages"
    @update:model-value="handleTyping"
  >
    <template v-if="$slots['append-inner']" #append-inner>
      <slot name="append-inner" />
    </template>
    <template v-if="$slots['message']" #message>
      <slot name="message" />
    </template>
  </text-field>
</template>

<script>
import { validateEmail } from "@/api/boss.service";
import { email as emailValidator } from "@/util/vuelidateValidators";
import { mdiEmailOutline } from "@mdi/js";
export default {
  name: "BasicEmailInput",
  props: {
    clearable: Boolean,
    disabled: Boolean,
    autofocus: Boolean,
    success: Boolean,
    persistentHint: Boolean,
    hint: { type: String, default: null },
    errorMessages: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: ""
    },
    dataTestid: {
      type: String,
      default: ""
    },
    validateOnCreated: Boolean
  },
  emits: ["update:model-value", "valid"],
  data() {
    return {
      model: this.modelValue,
      emailTimer: null,
      emailLoading: false,
      validating: false,
      typingTimer: null,
      isTyping: false,
      mdiEmailOutline
    };
  },
  watch: {
    modelValue() {
      if (this.modelValue === this.model) return;
      this.model = this.modelValue;
    }
  },
  created() {
    if (this.validateOnCreated && this.model) this.validate();
  },
  methods: {
    handleTyping() {
      this.isTyping = true;
      this.$emit("valid", null);
      if (this.typingTimer) clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.isTyping = false;
      }, 500);

      this.$emit("update:model-value", this.model);
      this.debounceAndValidate();
    },
    debounceAndValidate() {
      if (this.emailTimer) clearTimeout(this.emailTimer);
      this.emailTimer = setTimeout(this.validate, 300);
    },
    async validate() {
      if (!this.model && this.clearable) {
        this.$emit("valid", true);
        return;
      }
      if (!emailValidator(this.model)) {
        this.$emit("valid", false);
        return;
      }

      this.validating = true;
      let valid;
      valid = await validateEmail({ email: this.model });
      this.$emit("valid", valid);
      this.validating = false;
    }
  }
};
</script>
