<template>
  <v-card v-if="user.isGroupTwoPlus" flat tile>
    <v-card-text>
      <v-row class="ma-0">
        <v-spacer />
        <app-button
          variant="outlined"
          color="error"
          class="text-none rounded-pill"
          data-testid="delete-personnel"
          @click="deleteConfirmation"
        >
          <v-icon class="ml-1" :icon="mdiDelete" /> Delete Personnel
        </app-button>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { usePersonnelView } from "@/stores/personnel-view";
import { useUserStore } from "@/stores/user";
import { mdiDelete } from "@mdi/js";
import { markRaw } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const user = useUserStore();

const personnelView = usePersonnelView();
const router = useRouter();

const { firstName, lastName } = storeToRefs(personnelView);

function deleteConfirmation() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Are you sure you want to permanently delete this personnel, ${firstName.value} ${lastName.value}?`,
    subtitle: "This cannot be undone",
    func: async () => {
      await personnelView.deletePersonnel();
      snackbar.showSuccessSnackbar({
        message: `${firstName.value} ${lastName.value} has been deleted`,
        timeout: 6000
      });

      router.replace({ name: "Tables", query: { page: "carrier-personnel" } });
    }
  });
}
</script>
