export function Address(model = {}) {
  return {
    street_address: model?.street_address || null,
    city: model?.city || null,
    zip: model?.zip || null,
    state: model?.state || null,
    id: model?.id || null,
    startDate: model?.startDate || null,
    endDate: model?.endDate || null,
    addressType: model?.addressType || null,
    primary: Boolean(model?.primary) || false
  };
}

export function setAddressFromRaw(raw) {
  const address = Address();

  address.street_address = raw?.street_address;
  address.city = raw?.city;
  address.zip = raw?.zip;
  address.state = raw?.state;
  address.id = raw?.id;
  address.startDate = raw?.start_date;
  address.endDate = raw?.end_date;
  address.addressType = raw?.address_type;
  address.primary = Boolean(raw?.primary);

  return address;
}

export function setRawFromAddress(address) {
  return {
    street_address: address.street_address,
    city: address.city,
    zip: address.zip,
    state: address.state,
    id: address.id,
    start_date: address.startDate,
    end_date: address.endDate,
    address_type: address.addressType,
    primary: Boolean(address.primary)
  };
}

export function formatAddress(address) {
  return [address?.street_address, address?.city, address?.state, address?.zip]
    .filter(Boolean)
    .join(", ");
}
