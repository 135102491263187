<template>
  <span>{{ formattedValue }}</span>
</template>

<script setup>
import { modeFormat } from "@/util/helpers";
import { computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true
  },
  format: {
    type: String,
    default: "adverb",
    validates: v => ["adverb", "abbreviated"].includes(v)
  },
  lowercase: Boolean
});

const formattedValue = computed(() => {
  let value = modeFormat(props.modelValue, props.format);
  if (props.lowercase) value = value.toLowerCase();
  return value;
});
</script>
