<template>
  <v-card flat tile>
    <v-row v-if="!fullscreen" class="ma-0 pa-3" align="end">
      <div>
        <h1 class="text-h5">Leads</h1>
        <p class="mb-0">Drag and organize leads</p>
      </div>
    </v-row>
    <v-divider />
    <table-filter
      :header-props="{
        class: 'mt-0 pa-3',
        rounded: false
      }"
      :model-value="filter"
      :headers="headers"
      @update="filter = $event"
    />
    <v-divider />
    <div class="pipeline-row-container">
      <div class="ma-0 pipeline-row" dense>
        <electronic-application-pipeline-column
          v-for="{ text, isDraggable, showStats } in statuses"
          :key="text"
          :external-filter="filter"
          :lead-type="text"
          :cards="cards[text]"
          :headers="headers"
          :is-draggable="isDraggable"
          :show-stats="showStats"
          @new-items="newItems(text, $event)"
          @reset-items="resetItems(text)"
          @removed="handleMove(text, $event, false)"
          @added="handleMove(text, $event, true)"
        />
      </div>
    </div>
  </v-card>
</template>

<script setup>
import LeadContactedDialog from "@/dialogs/LeadContactedDialog.vue";
import ElectronicApplicationPipelineColumn from "@/components/electronic-applications/ElectronicApplicationPipelineColumn.vue";
import {
  BEST_TIME_TO_CALL_OPTIONS,
  ElectronicApplicationTableItem
} from "@/factories/ElectronicApplication";
import { updateEapp } from "@/api/electronic-application.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useUserStore } from "@/stores/user";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import { nextTick, ref, watch, markRaw } from "vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { productTypes } from "@/data/filter-options";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useTableStore } from "@/stores/table";
const statuses = [
  { text: "New", isDraggable: true, showStats: true },
  { text: "Contacted", isDraggable: true, showStats: true },
  { text: "Awaiting Signatures", showStats: true },
  { text: "Sold" },
  { text: "Inactive", isDraggable: true }
];

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const { fullscreen } = storeToRefs(useInstanceStore());

const { leadList } = storeToRefs(useTableStore());

const YES_NO_OPTIONS = [
  { title: "Yes", value: "true" },
  { title: "No", value: "false" }
];

const headers = (function () {
  const filters = [
    new TableHeader({
      text: "Insured",
      value: "insuredName",
      map: "insuredName",
      sortFilterMap: "electronic_applications.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Agent",
      value: "agentName",
      map: "agentName",
      sortFilterMap: [{ key: "agent_id", value: "id" }],
      sortKey: "owner_name",
      ...TableHeader.IS_AGENT_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Best Time to Call",
      value: "bestTimeToCall",
      map: "bestTimeToCall",
      sortFilterMap: "best_time_to_call",
      selectableOptions: BEST_TIME_TO_CALL_OPTIONS,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Appointment Set",
      value: "isAppointment",
      map: "isAppointment",
      sortFilterMap: "is_appointment",
      selectableOptions: YES_NO_OPTIONS,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Website",
      value: "website",
      map: "website",
      sortFilterMap: "approved_domains.domain",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Product",
      value: "productName",
      map: "productName",
      sortFilterMap: [{ key: "product_id", value: "id" }],
      ...TableHeader.IS_PRODUCT_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "created",
      sortFilterMap: "electronic_applications.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      sortKey: "carrier",
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    })
  ];

  if (user.isGroupTwoPlus) {
    filters.push(
      new TableHeader({
        text: "Manager",
        value: "marketingManager",
        map: "marketingManager",
        sortFilterMap: [
          { key: "marketing_manager_id", value: "marketing_manager_id" }
        ],
        ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER
      }),
      new TableHeader({
        text: "Pricing Plan",
        value: "pricingPlan",
        sortFilterMap: [{ key: "pricing_plan_id", value: "id" }],
        ...TableHeader.IS_PRICING_PLAN_SEARCH_TYPE,
        ...TableHeader.IS_FILTERABLE,
        ...TableHeader.IS_QUERY_FILTER
      })
    );
  }

  filters.push(
    new TableHeader({
      text: "Shared With",
      value: "additionalAdvisor",
      map: "additional_advisor",
      sortFilterMap: [
        { key: "shared_with_id", value: "id" },
        { key: "shared_with_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Product Category",
      value: "productCategory",
      map: "productCategory",
      sortFilterMap: "categories",
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE,
      selectableOptions: productTypes
    }),
    new TableHeader({
      text: "Was Contacted",
      value: "contacted",
      map: "contacted",
      sortFilterMap: "contacted",
      selectableOptions: YES_NO_OPTIONS,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE
    }),

    new TableHeader({
      text: "Death Benefit",
      value: "death_benefit",
      map: "death_benefit",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CURRENCY
    }),
    new TableHeader({
      text: "Is Referred",
      value: "refer",
      map: "refer",
      sortFilterMap: "refer",
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FILTERABLE
    })
  );

  return filters;
})();

const filter = ref(leadList.value.filter);

watch(
  filter,
  () => {
    leadList.value.filter = filter.value;
  },
  { deep: true }
);

const cards = ref({
  New: [],
  Contacted: [],
  Sold: [],
  Inactive: [],
  "Awaiting Signatures": []
});

const currentChange = ref({
  from: "",
  fromIndex: null,
  element: {},
  to: "",
  toIndex: null
});

function handleMove(leadStatus, { element, index }, added = false) {
  currentChange.value.element = element;
  if (added) {
    currentChange.value.to = leadStatus;
    currentChange.value.toIndex = index;
  } else {
    currentChange.value.from = leadStatus;
    currentChange.value.fromIndex = index;
  }

  if (currentChange.value.from && currentChange.value.to) handleStatusChange();
}
function newItems(leadStatus, newItems) {
  cards.value[leadStatus].push(...newItems);
}
function resetItems(leadStatus) {
  cards.value[leadStatus].splice(0, cards.value[leadStatus].length);
}
async function handleStatusChange() {
  const { to, toIndex, from, fromIndex, element } = currentChange.value;
  currentChange.value.to = null;
  currentChange.value.from = null;
  currentChange.value.element = null;
  const item = ElectronicApplicationTableItem(element);

  cards.value[from].splice(fromIndex, 1);
  cards.value[to].splice(toIndex, 0, item);

  let success;
  if (to === "Contacted") {
    success = await handleContacted(item);
  } else if (to === "New") {
    success = await handleNew(item);
  } else if (to === "Inactive") {
    success = await handleInactive(item);
  }

  if (success) {
    cards.value[to].splice(toIndex, 1);
    await nextTick();
    cards.value[to].splice(toIndex, 0, item);
  } else {
    cards.value[from].splice(fromIndex, 0, item);
    cards.value[to].splice(toIndex, 1);
  }
}
async function handleContacted(item) {
  const res = await dialog.showDialog({
    component: markRaw(LeadContactedDialog),
    eAppId: item.eAppId
  });
  item.followUpAt = res?.followUp;
  item.followUpIsScheduled = Boolean(res?.followUpIsScheduled);
  return Boolean(res?.followUp);
}
async function handleNew(item) {
  try {
    await updateEapp(item.eAppId, {
      lead_status: null,
      follow_up_at: null,
      follow_up_at_is_appointment: false
    });
    item.followUpAt = null;
    item.followUpIsScheduled = false;
    return true;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    return false;
  }
}
async function handleInactive(item) {
  try {
    await updateEapp(item.eAppId, {
      lead_status: "Inactive",
      follow_up_at: null,
      follow_up_at_is_appointment: false
    });
    item.updatedAt = new Date().toISOString();
    item.followUpAt = null;
    item.followUpIsScheduled = false;
    return true;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
</script>

<style lang="scss">
.pipeline-row-container {
  overflow-x: auto;
  padding-bottom: 1px;
  background: rgba(0, 0, 0, 0.12);
}
.pipeline-row {
  white-space: nowrap;
  min-width: 1400px;
  display: grid;
  grid-template-columns: repeat(5, 400px);
  gap: 0 1px;
}
</style>
