<template>
  <v-data-table
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    data-testid="smoker-charts-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :search="search"
  >
    <template #top>
      <v-row class="ma-0">
        <span class="text-h5"> Smoker Charts </span>
        <v-spacer />
        <text-field
          v-model="search"
          data-lpignore="true"
          label="Search"
          data-testid="smoker-charts-table-search"
          :prepend-inner-icon="mdiMagnify"
          hide-details
        />
        <v-tooltip location="bottom">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              variant="text"
              density="comfortable"
              class="table-btn"
              v-bind="templateProps"
              data-testid="smoker-charts-table-refresh"
              :disabled="table.loading.value"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
    </template>

    <template #[`item.actions`]="{ item }">
      <template v-if="isGroupFour">
        <app-button
          data-testid="edit-chart"
          :icon="mdiPencil"
          variant="text"
          density="comfortable"
          color="accent"
          @click="editSmokerChart(item.additional)"
        />
        <app-button
          :icon="
            item.additional.complete ? mdiCheckDecagram : mdiDecagramOutline
          "
          :data-testid="
            item.additional.complete ? 'is-complete' : 'is-incomplete'
          "
          variant="text"
          density="comfortable"
          :color="item.additional.complete ? 'orange' : 'grey'"
          @click="toggleCompleteness(item.additional)"
        />
      </template>
      <app-button
        v-if="item.additional.documentUrl"
        data-testid="download-document"
        color="primary"
        variant="text"
        density="comfortable"
        :href="item.additional.documentUrl"
        :icon="mdiDownload"
      />
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import UploadDocumentDialog from "@/dialogs/UploadDocumentDialog.vue";
import { parseErrorMessage } from "@/util/helpers";
import {
  getSmokerCharts,
  updateCompleteness,
  uploadSmokerChartDocument
} from "@/api/smoker-charts.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { markRaw, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { getDocumentUrl } from "@/api/documents.service";
import { TableOptions } from "@/classes/data-table/TableOptions";
import {
  mdiMagnify,
  mdiRefresh,
  mdiPencil,
  mdiCheckDecagram,
  mdiDecagramOutline,
  mdiDownload
} from "@mdi/js";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const search = ref("");

const user = useUserStore();
const { isGroupFour } = storeToRefs(user);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrierName",
      map: "carrierName",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ],
  options: TableOptions({ sortBy: [{ key: "name", order: "asc" }] }),
  getData: async () => {
    const items = await getSmokerCharts();
    const mapped = items.map(v => ({
      ...v,
      documentUrl: v.documentUid ? getDocumentUrl(v.documentUid) : null
    }));
    return { items: mapped };
  }
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function toggleCompleteness({ id, name, complete }) {
  const toggledValue = !complete;

  const res = await dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Mark ${name} as ${toggledValue ? "Complete" : "Incomplete"}`,
    subtitle: "Please confirm that you are intentionally making this change",
    showErrorMessage: true,
    func: () => updateCompleteness(id, toggledValue)
  });
  if (res?.confirm) getData();
}

async function editSmokerChart({ id }) {
  if (!this.isGroupFour) return;
  dialog
    .showDialog({
      component: markRaw(UploadDocumentDialog),
      uploadFunction: ({ file }) => uploadSmokerChartDocument(id, file),
      single: true
    })
    .then(document => {
      if (!document) return;
      this.getData();
    });
}

getData();
</script>
