import { getReferralInfo } from "@/api/referral.service";
import { defineStore } from "pinia";

export const useSignupStore = defineStore("signup", {
  state: () => ({
    referralCode: null,
    referrer: null,
    discovery: null,
    invalidCode: null,
    avatarUrl: null,
    name: null,
    message: null,
    hideAffiliations: null
  }),
  actions: {
    setData({ params, query }) {
      this.referralCode = params?.referralCode || null;
      this.discovery = query?.discovery || null;
      this.referrer = query?.referrer || null;
    },
    async fetchCode() {
      try {
        const data = await getReferralInfo(this.referralCode);
        this.avatarUrl = data.avatarUrl;
        this.name = data.name;
        this.message = data.message;
        this.hideAffiliations = Boolean(data.hideAffiliations);
      } catch (e) {
        this.invalidCode = true;
      }
    }
  }
});
