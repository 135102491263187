<template>
  <v-card class="quote-and-apply-header" max-height="60rem">
    <v-row align="center" class="ma-0">
      <v-col cols="12" md="7">
        <v-card-title class="text-h4 pb-0 font-weight-bold text-white">
          Quote & Apply
        </v-card-title>
        <v-card-subtitle class="text-white mb-5">
          Add Quote & Apply to your website for customers to purchase life
          insurance 24/7. Use one platform to sell products to anyone,
          anywhere—in person or online through your website, social media, and
          online marketplaces.
        </v-card-subtitle>
        <v-card-subtitle class="text-white mb-5">
          <strong style="font-size: 1.3rem">Sell Everywhere</strong> <br />
          Add Quote & Apply to your website or get a personalized Quote & Apply
          website to elevate your life insurance sales process.
        </v-card-subtitle>
        <v-card-subtitle class="text-white mb-5">
          <strong style="font-size: 1.3rem">Real-Time Quoting</strong>
          <br />
          Accidental Death, Accumulation Indexed Universal Life, Final Expense,
          Guaranteed Universal Life, Long Term Care, Protection Indexed
          Universal Life, Return of Premium, Term, Universal Life, and Whole
          Life.
        </v-card-subtitle>
        <div class="mx-3">
          <app-button
            v-if="hasQuoteAndApplyLink"
            :href="quoteAndApplyLink"
            target="_blank"
            class="text-none ma-1"
            color="accent"
            size="large"
          >
            <v-icon class="mr-2" :icon="mdiRocketLaunch" /> Start a New eApp
          </app-button>
          <app-button
            class="text-none ma-1"
            color="accent"
            size="large"
            @click="createSite"
          >
            <v-icon class="mr-2" :icon="mdiPlus" /> Create a Website
          </app-button>
          <app-button
            class="text-none ma-1"
            color="accent"
            size="large"
            exact
            :to="{ name: 'QuoteAndApply', query: { view: 'websites' } }"
          >
            <v-icon class="mr-2" :icon="mdiWeb" /> View Websites
          </app-button>
        </div>
      </v-col>
      <v-col v-if="mdAndUp" cols="5" align="center">
        <v-card color="transparent" width="unset" max-width="500px">
          <v-img :src="quoteAndApply" max-height="30rem" contain />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { mdiRocketLaunch, mdiPlus, mdiWeb } from "@mdi/js";
import { markRaw } from "vue";
import CreateQuoteAndApplySite from "@/dialogs/CreateQuoteAndApplySite.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import quoteAndApply from "@/assets/img/quote-and-apply.png";
export default {
  setup() {
    const { mdAndUp } = useDisplay();
    return { mdAndUp };
  },
  data: () => ({ mdiRocketLaunch, mdiPlus, mdiWeb, quoteAndApply }),
  computed: {
    ...mapState(useUserStore, ["loginable"]),
    hasQuoteAndApplyLink() {
      return Boolean(this.loginable.approved_domain_url);
    },
    quoteAndApplyLink() {
      return this.loginable.approved_domain_url;
    }
  },
  methods: {
    ...mapActions(useDialogStore, ["showDialog"]),
    createSite() {
      this.showDialog({
        component: markRaw(CreateQuoteAndApplySite),
        scrollable: true
      });
    }
  }
};
</script>

<style lang="scss">
.quote-and-apply-header {
  background: linear-gradient(
    to right top,
    #72cc50,
    #68cb58,
    #4fc96b,
    #28c482,
    #00bd97,
    #00b6a5,
    #00b0ab,
    #00aead
  );

  > .v-card__subtitle,
  .v-card__title {
    color: white !important;
  }
}
</style>
