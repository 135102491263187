import { getRouterLink } from "@/util/helpers";

export function ApplicationVersionTableItem(model = {}) {
  return {
    createdAt: model?.createdAt,
    changedBy: model?.changedBy,
    changedObject: model?.changedObject,
    changes: model?.changes
  };
}

export function setApplicationVersionTableItemFromRequest(raw = {}) {
  const item = ApplicationVersionTableItem();

  item.createdAt = raw?.created_at;

  if (raw.changed_by) {
    item.changedBy = {
      email: raw?.changed_by?.email,
      id: raw?.changed_by?.id
    };
  }

  if (raw.changed_object) {
    item.changedObject = {
      id: raw?.changed_object?.id,
      type: raw?.changed_object?.type,
      routerLink: getRouterLink(raw.changed_object.type, raw.changed_object.id)
    };
  }
  item.changes = raw?.changes;

  return item;
}
