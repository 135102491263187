<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        data-testid="parties-table"
        class="transparent-data-table"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        :mobile="null"
        mobile-breakpoint="sm"
        :items="parties"
        :headers="table.tableHeaders.value"
        :items-per-page="-1"
      >
        <template #[`item.birthdate`]="{ item }">
          <timestamp-formatter
            :model-value="item.birthdate"
            parser="sole-day"
          />
        </template>
        <template #[`item.role`]="{ item }">
          <role-formatter :model-value="item" />
        </template>
        <template #[`item.phone`]="{ item }">
          <a
            :href="`tel:${item.phone}`"
            :data-outbound-type="item.type"
            :data-outbound-id="item.id"
            :data-outbound-number="item.phone"
            >{{ item.phone }}</a
          >
        </template>
        <template #[`item.email`]="{ item }">
          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </template>
        <template #expanded-row="{ columns, item }">
          <td :colspan="columns.length" class="py-4">
            <electronic-application-view-party
              :id="item.id"
              :key="`${item.type}-${item.id}`"
              :type="item.type"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import RoleFormatter from "@/components/shared/formatters/RoleFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import ElectronicApplicationViewParty from "@/components/electronic-applications/ElectronicApplicationViewParty.vue";
import { storeToRefs } from "pinia";
import { useEappViewStore } from "@/stores/eapp-view";
import { useTable } from "@/composables/table.composable";

const eappView = useEappViewStore();
const { parties } = storeToRefs(eappView);

const headers = [
  new TableHeader({
    text: "Name",
    value: "name",
    map: "name"
  }),
  new TableHeader({
    text: "Role",
    value: "role",
    map: "role"
  }),
  new TableHeader({
    text: "Birthdate",
    value: "birthdate",
    map: "birthdate"
  }),
  new TableHeader({
    text: "Email",
    value: "email",
    map: "email"
  }),
  new TableHeader({
    text: "Phone",
    value: "phone",
    map: "phone"
  }),
  new TableHeader({
    text: "Actions",
    value: "data-table-expand",
    width: "125px"
  })
];
const table = useTable({
  headers
});
</script>
