export const ALL_FILE_EXTENSIONS =
  ".ai, .apng, .avif, .csv, .doc, .docx, .gif, .heic, .heif, .jfif, .jpg, .jpeg, .mp3, .odt, .pdf, .pdfa, .pjpeg, .pjp, .png, .pot, .pps, .ppt, .pptx, .rtf, .svg, .text, .tif, .tiff, .txt, .webp, .wrd, .xla, .xlc, .xlm, .xls, .xlt, .xlw, .xlsx, .xps";

const EXTENSION_DICTIONARY = {};
ALL_FILE_EXTENSIONS.replaceAll(",", "")
  .replaceAll(".", "")
  .split(" ")
  .map(v => v.trim())
  .filter(Boolean)
  .forEach(fe => (EXTENSION_DICTIONARY[fe] = true));

export { EXTENSION_DICTIONARY };
