export function ChatTemplate(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    templateContents: model?.templateContents || null,
    deliveryRequirement: model?.deliveryRequirement || false,
    email: model?.email || null,
    pdf: model?.pdf || false,
    errors: model?.errors || "",
    isTextOnly: model?.isTextOnly || false
  };
}

export function setChatTemplateFromRequest(raw = {}) {
  const model = ChatTemplate();
  model.id = raw?.id;
  model.name = raw?.name;
  model.templateContents = raw?.template_contents;
  model.deliveryRequirement = Boolean(raw?.delivery_requirement);
  model.email = raw?.email;
  model.pdf = Boolean(raw?.pdf);
  model.errors = raw?.errors;
  model.isTextOnly = Boolean(raw?.text_only);
  return model;
}
