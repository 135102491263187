import { defineStore } from "pinia";
import { useAgencySettingsStore } from "@/stores/agency-settings";

export const useSettingsViewStore = defineStore("settings-view", {
  state: () => ({
    // Used for the agency switcher and which agency's settings are visible.
    // this will not affect the current agent's assignable's E&O, however.
    currentAgencyId: null
  }),
  actions: {
    async loadCurrentAgency() {
      const agency = useAgencySettingsStore(this.currentAgencyId);
      return agency.getAgencyContractingData(this.currentAgencyId);
    }
  }
});
