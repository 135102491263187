<template>
  <v-card>
    <v-row class="ma-0" align="center">
      <v-card-title> Placement Ratio </v-card-title>
      <v-spacer />
      <carrier-search
        v-model="carrier"
        class="mx-3"
        hide-details
        clearable
        @update:model-value="getReportData"
      />
    </v-row>
    <v-divider />
    <v-card-text>
      <v-col cols="12" align="center">
        <v-progress-circular v-if="loading" indeterminate />
        <div
          v-else-if="chartOptions.series.length"
          class="highcharts-areaspline"
        >
          <highcharts-graph
            :options="chartOptions"
            style="border-radius: 5px"
          />
        </div>
        <div v-else-if="!chartOptions.series.length">
          <v-alert color="info">No Placement Ratio Data to Report</v-alert>
        </div>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import { getPlacementReport } from "@/api/reports.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    HighchartsGraph: defineAsyncComponent(() =>
      import("@/components/shared/HighchartsGraph.vue")
    ),
    CarrierSearch
  },
  props: {
    all: Boolean
  },
  data() {
    return {
      loading: false,
      carrier: null,
      chartOptions: {
        chart: {
          styledMode: true,
          type: "areaspline",
          style: {
            fontFamily: "Roboto"
          }
        },
        tooltip: {
          xDateFormat: "%B"
        },
        title: {
          text: "Case Placement Ratio",
          style: {
            textTransform: "none"
          }
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%b %Y"
          }
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Placement Ratio"
          },
          max: 100
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false
            }
          }
        },
        series: [],
        defs: {
          gradient0: {
            tagName: "linearGradient",
            id: "gradient-0",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          },
          gradient1: {
            tagName: "linearGradient",
            id: "gradient-1",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          },
          gradient2: {
            tagName: "linearGradient",
            id: "gradient-2",
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: "stop",
                offset: 0
              },
              {
                tagName: "stop",
                offset: 1
              }
            ]
          }
        }
      }
    };
  },
  created() {
    this.getReportData();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    async getReportData() {
      this.loading = true;
      const params = new URLSearchParams();
      if (this.all) params.append("view", "all");
      if (this.carrier?.id) params.append("carrier_id", this.carrier.id);
      try {
        const { all, eapp, paper } = await getPlacementReport(params);
        this.chartOptions.series = [
          {
            data: all.data,
            name: all.name
          },
          {
            data: eapp.data,
            name: eapp.name,
            visible: false
          },
          {
            data: paper.data,
            name: paper.name,
            visible: false
          }
        ];
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
