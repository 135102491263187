import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";

import { Appointment } from "@/factories/Appointment";

import {
  getAppointment,
  appointmentDelete,
  appointmentUpdate,
  createAppointmentAccess,
  updateAppointmentAccess,
  removeAppointmentAccess,
  deleteAppointmentHierarchy,
  updateAppointmentHierarchy,
  addAppointmentHierarchy
} from "@/api/appointment.service";
import { productSearch } from "@/api/products.service";
import { compBuilderSearch } from "@/api/search.service";

export const useAppointmentView = defineStore("appointment-view", {
  state: () => ({
    ...Appointment(),
    compBuilders: [],
    products: [],
    todoCount: 0
  }),
  getters: {
    appointmentManager() {
      return this.room.find(v => v.appointmentManager);
    }
  },
  actions: {
    async fetchAppointment(id) {
      const appointment = await getAppointment(id);
      this.$patch(appointment);
    },
    async sendContractingStartedEmail() {
      // TODO: Don't send the loginable ~ fairway already has the loginable.
      const user = useUserStore();
      return appointmentUpdate(this.id, {
        appointment: {
          submitted: 1,
          submitter_id: user.loginable.id,
          submitter_type: user.loginable.type
        }
      });
    },
    async destroyAppointment() {
      return appointmentDelete(this.id);
    },
    async updateAppointmentManager(newAppointmentManager) {
      await updateAppointmentAccess(
        this.id,
        newAppointmentManager.appointmentAccessId,
        {
          appointment_manager: true
        }
      );
      this.room.forEach((r, index) => {
        if (!r.canBeAppointmentManager) return;
        this.room[index].appointment_manager =
          newAppointmentManager.id === this.room[index].id;
      });
    },
    createRoomAccess(newAccess) {
      return createAppointmentAccess(this.id, {
        appointment_id: this.id,
        appointment_access_id: newAccess.appointmentAccessId,
        ownable_id: newAccess.id,
        ownable_type: newAccess.type,
        owner: 0
      });
    },
    removeRoomAccess(access) {
      return removeAppointmentAccess(this.id, access.appointmentAccessId);
    },
    updateRoomAccess(access, attribute) {
      const roomAccess = this.room.find(
        v => v.appointmentAccessId === access.appointmentAccessId
      );
      const map = {
        email: { email: roomAccess.email },
        advisorAccess: { advisor_access: roomAccess.advisorAccess },
        carrierAccess: { carrier_access: roomAccess.carrierAccess },
        carrierEmail: { carrier_email: roomAccess.carrierEmail }
      };
      return updateAppointmentAccess(
        this.id,
        access.appointmentAccessId,
        map[attribute]
      );
    },
    async fetchProducts() {
      const products = await productSearch(this.carrier.id);
      this.products.splice(0, products.length);
      this.products.push(...products);
    },
    async fetchCompBuilders() {
      const compBuilders = await compBuilderSearch(this.carrier.id);
      this.compBuilders.splice(0, this.compBuilders.length);
      this.compBuilders.push(...compBuilders);
      if (this.compBuilder) return;
      const defaultCompBuilder = this.compBuilders.find(v => v.street === true);
      if (!defaultCompBuilder?.id) return;
      this.compBuilder = defaultCompBuilder.id;
    },
    update(attribute) {
      const body = {
        commissionable: { commissionable: Boolean(this.commissionable) },
        linesOfAuthority: { lines_of_authority: this.linesOfAuthority },
        status: { status: this.status },
        insuredName: { insured_name: this.insuredName },
        compBuilder: { comp_builder_id: this.compBuilder },
        productId: { product_id: this.productId },
        states: { states: this.states },
        annualized: { annualized: this.annualized },
        ltcRider: { ltc_rider: this.ltcRider },
        assignment: { assignment_id: this.assignment?.id || this.id },
        applicationSignedDate: { app_sign_date: this.applicationSignedDate },
        effectiveDate: { effective_date: this.effectiveDate },
        terminatedDate: { terminated_date: this.terminatedDate },
        name: { name: this.name },
        followUpDate: { follow_up_date: this.followUpDate }
      }[attribute];

      return appointmentUpdate(this.id, { appointment: body });
    },
    async deleteHierarchy(id) {
      await deleteAppointmentHierarchy(this.id, id);
      const index = this.hierarchy.findIndex(v => v.id === id);
      if (index > -1) this.hierarchy.splice(index, 1);
    },
    async updateHierarchy(oldIndex, newIndex) {
      const oldPosition = this.hierarchy[oldIndex];
      const newPosition = this.hierarchy[newIndex];
      const oldOrder = oldPosition.order;
      oldPosition.order = newPosition.order;
      newPosition.order = oldOrder;

      this.hierarchy[oldIndex] = newPosition;
      this.hierarchy[newIndex] = oldPosition;
      return updateAppointmentHierarchy(this.id, {
        hierarchy: [
          {
            id: oldPosition.id,
            order: oldPosition.order
          },
          {
            id: newPosition.id,
            order: newPosition.order
          }
        ]
      });
    },
    async addHierarchy(hierarchyToAdd) {
      const newHierarchy = await addAppointmentHierarchy(this.id, {
        parent_id: hierarchyToAdd.id
      });
      this.hierarchy.push(newHierarchy);
    }
  }
});
