<template>
  <v-card color="section" flat outlined>
    <v-card-title class="text-h4">Refer Settings</v-card-title>
    <v-card-subtitle>
      Change settings for the referral form and the questions that are asked.
    </v-card-subtitle>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="text-h6">Refer Text</v-card-title>
          <v-card-subtitle>
            Allow referring agents to opt-in/out of sending a custom message to
            the client upon referral completion.
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="checkbox-width">
                  <checkbox-field
                    v-model="sendReferText"
                    icon
                    class="mt-0"
                    hide-details
                    data-testid="settings-show-refer-text"
                    :success="Boolean(sendReferText)"
                    @update:model-value="saveAttribute('sendReferText')"
                  >
                    <template #label>
                      Show Send Refer Text Input
                      <active-save-indicator
                        class="ml-2"
                        :controller="
                          savingBuffer.sendReferText.controller.value
                        "
                      />
                    </template>
                  </checkbox-field>
                </div>
              </v-col>
              <v-col cols="12">
                <textarea-field
                  v-model="referText"
                  label="Refer Text"
                  rows="6"
                  :prepend-inner-icon="mdiMessageText"
                  persistent-hint
                  data-testid="settings-refer-text"
                  :disabled="!sendReferText"
                  :success="Boolean(referText)"
                  @update:model-value="saveAttribute('referText')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.referText.controller.value"
                    />
                  </template>
                </textarea-field>
                <v-row>
                  <v-col cols="12" md="6">
                    <strong> Insured & Application Text Substitutions </strong>
                    <ul>
                      <li>[InsuredFirstName]: Insured's first name</li>
                      <li>[InsuredLastName]: Insured's last name</li>
                      <li>[Link]: Link to application</li>
                    </ul>
                  </v-col>
                  <v-col cols="12" md="6">
                    <strong>
                      Agent & Referring Agent Text Substitutions
                    </strong>
                    <ul>
                      <li>
                        [AgentPhoneWork]: Assigned agent's work phone number
                      </li>
                      <li>[AgentEmail]: Assigned agent's email address</li>
                      <li>
                        [AgentSchedulingLink]: Link to agent's scheduling page
                      </li>
                      <li>[AgentFirstName]: Assigned agent's first name</li>
                      <li>[AgentLastName]: Assigned agent's last name</li>
                      <li>[ReferringAgentName]: Referring agent's full name</li>
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="text-h6">Referring Agents</v-card-title>
          <v-card-subtitle>
            If Round Robin agents are present, the list of Round Robin agents is
            shown to allow users to refer to a specific Round Robin agent.
            Allows you to not show the list of Round Robin agents.
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <div class="checkbox-width">
                  <checkbox-field
                    label="Hide Referral Agent Input"
                    data-testid="hide-select-round-robin-agent"
                    readonly
                    hide-details
                    class="pt-0 mt-0"
                    :model-value="hideSelectRoundRobinAgent"
                    @click="confirmHideRoundRobinSelect"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card flat>
          <v-card-title class="text-h6">Refer Questions</v-card-title>
          <v-card-subtitle>
            Add custom questions to the refer form. e.g. "Who were you referred
            by?"
          </v-card-subtitle>
          <v-list nav>
            <v-list-item
              v-for="q in questions"
              :key="q.id"
              :data-testid="`question-${q.id}`"
              @click="showQuestionDialog(q)"
            >
              <template #prepend>
                <v-icon :icon="q.icon" />
              </template>

              <v-list-item-title>
                {{ q.question }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="q.required">
                Required Question
              </v-list-item-subtitle>

              <template #append>
                <v-row class="ma-0">
                  <app-button :icon="mdiPencil" color="accent" />
                  <app-button
                    :icon="mdiDelete"
                    color="error"
                    :data-testid="`question-${q.id}-delete`"
                    @click.stop="confirmQuestionDelete(q)"
                  />
                </v-row>
              </template>
            </v-list-item>
            <v-list-item
              v-if="!questions.length"
              data-testid="no-questions-text"
            >
              <v-list-item-title>
                No Questions defined. Press "Add Question," below, to get
                started!
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-card-actions class="px-4 pb-5">
            <v-menu offset-y>
              <template #activator="{ props }">
                <app-button
                  data-testid="add-question"
                  v-bind="props"
                  class="text-none"
                  color="primary"
                >
                  <v-icon :icon="mdiPlus" /> Add Question
                </app-button>
              </template>
              <v-list>
                <v-list-item
                  data-testid="add-text-question"
                  @click="addTextQuestion"
                >
                  Text Input
                </v-list-item>
                <v-list-item
                  data-testid="add-select-question"
                  @click="addSelectQuestion"
                >
                  Select Input
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="text-h6">Refer to Website</v-card-title>
          <v-card-subtitle>
            After the refer form is completed, the agent is changed to the agent
            of the refer to Quote & Apply Website.
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <quote-and-apply-site-search
                  v-model="referToDomain"
                  label="Refer to Quote & Apply Website"
                  data-testid="settings-refer-to-domain"
                  :success="Boolean(referToDomain)"
                  @update:model-value="saveAttribute('referToDomain')"
                >
                  <template #append-inner>
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer.referToDomain.controller.value"
                    />
                  </template>
                </quote-and-apply-site-search>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import ApprovedDomainQuestionEditor from "@/components/approved-domain/ApprovedDomainQuestionEditor.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import QuoteAndApplySiteSearch from "@/components/shared/QuoteAndApplySiteSearch.vue";

import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { useDialogStore } from "@/stores/dialog";
import { markRaw, onMounted, ref } from "vue";
import { SelectQuestion, TextQuestion } from "@/factories/ApprovedDomain";
import { deleteApprovedDomainQuestion } from "@/api/approved-domain.service";
import { useActiveSave } from "@/composables/active-save.composable";

import { mdiMessageText, mdiPencil, mdiDelete, mdiPlus } from "@mdi/js";

const dialog = useDialogStore();
const approvedDomain = useApprovedDomainView();
const {
  questions,
  id,
  referText,
  sendReferText,
  referToDomain,
  hideSelectRoundRobinAgent
} = storeToRefs(approvedDomain);

const isMounted = ref(false);

const savingBuffer = {
  referText: useActiveSave(),
  sendReferText: useActiveSave(),
  referToDomain: useActiveSave()
};

function addTextQuestion() {
  return showQuestionDialog(TextQuestion());
}
function addSelectQuestion() {
  return showQuestionDialog(SelectQuestion());
}

async function showQuestionDialog(question) {
  const res = await dialog.showDialog({
    component: markRaw(ApprovedDomainQuestionEditor),
    question,
    approvedDomainId: id.value,
    persistent: true,
    scrollable: true
  });
  if (!res?.question) return;

  const index = questions.value.findIndex(v => v.id === res.question.id);

  if (index === -1) questions.value.push(res.question);
  else {
    questions.value.splice(index, 1);
    questions.value.splice(index, 0, res.question);
  }
}

function confirmQuestionDelete(question) {
  return dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    func: async () => {
      await deleteApprovedDomainQuestion(id.value, question.id);
      const index = questions.value.findIndex(i => i.id === question.id);
      questions.value.splice(index, 1);
    },
    title: "Are you sure that you want to delete this question?",
    subtitle: "This cannot be undone."
  });
}

onMounted(() => (isMounted.value = true));

function saveAttribute(attribute, timeout = 300) {
  if (!isMounted.value) return;

  savingBuffer[attribute].debounceUpdate(
    () => approvedDomain.updateApprovedDomain(attribute),
    timeout
  );
}

function confirmHideRoundRobinSelect() {
  const initialValue = hideSelectRoundRobinAgent.value;
  let title = "Hide Referral Agent Input";
  let subtitle = "Please confirm your intent to hide the referral agent input";

  if (initialValue) {
    title = "Show Referral Agent Input";
    subtitle = "Please confirm your intent to show the referral agent input";
  }

  dialog.showDialog({
    component: "ConfirmationDialog",
    title,
    subtitle,
    func: async () => {
      try {
        hideSelectRoundRobinAgent.value = !initialValue;
        await approvedDomain.updateApprovedDomain("hideSelectRoundRobinAgent");
      } catch (e) {
        hideSelectRoundRobinAgent.value = initialValue;
        throw e;
      }
    }
  });
}
</script>
