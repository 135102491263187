function CommissionSplit(model = {}) {
  return {
    id: model?.id || 0,
    agent: SimpleAgent(model?.agent),
    splitPercent: model?.splitPercent || 1
  };
}

export function setCommissionSplitFromRequest(model = {}) {
  const commissionSplit = CommissionSplit();
  commissionSplit.id = model?.id;
  commissionSplit.splitPercent = +model?.split_percent * 100;
  commissionSplit.agent = setSimpleAgentFromRequest(model?.agent);
  return commissionSplit;
}

function SimpleAgent(model = {}) {
  return {
    id: model?.id || 0,
    name: model?.name || "",
    link: model?.link || "",
    model: model?.model || "",
    avatar: model?.avatar || ""
  };
}

function setSimpleAgentFromRequest(model = {}) {
  const agent = SimpleAgent();
  agent.id = model?.id;
  agent.name = model?.name;
  agent.link = model?.type?.link;
  agent.model = model?.type?.model;
  agent.avatar = model?.avatar_url;
  return agent;
}
