<template>
  <v-row class="ma-0">
    <v-col cols="12" class="px-0">
      <v-card
        v-if="canCreateParentQuestion || parentQuestion"
        variant="outlined"
      >
        <!-- Parent Questions -->
        <v-card-title> Parent Question: <v-spacer /> </v-card-title>
        <v-list v-if="parentQuestion" class="pa-3">
          <v-list-item link @click="emit('view', parentQuestion.id)">
            <v-list-item-title>
              {{ parentQuestion.id }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="activeField.requiredParentValue">
              This question is displayed if
              <strong class="text-accent">
                {{ activeField.requiredParentValue }}
              </strong>
              is selected
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              Missing required value
            </v-list-item-subtitle>

            <template v-if="!fieldHasPositionalData(parentQuestion)" #append>
              <v-row class="pa-3" align="center">
                <v-icon class="mr-1" :icon="mdiFileHidden" /> Field not on PDF
              </v-row>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <!-- Child Questions -->

    <v-col
      v-if="canCreateChildQuestions || childQuestions.length"
      cols="12"
      class="px-0"
    >
      <v-card variant="outlined">
        <v-card-title>
          <v-row class="ma-0">
            Child Questions: <v-spacer />
            <mapped-form-view-aql-actions
              v-if="canCreateChildQuestions"
              is-child
              :form-id="formId"
            />
          </v-row>
        </v-card-title>
        <v-list v-if="childQuestions.length" class="pa-3">
          <v-list-item
            v-for="question in childQuestions"
            :key="question.id"
            link
            @click="emit('view', question.id)"
          >
            <v-list-item-title>
              {{ question.id }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="question.requiredParentValue">
              If
              <strong class="text-accent">
                {{ question.requiredParentValue }}
              </strong>
              is selected
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              Missing required value
            </v-list-item-subtitle>

            <template v-if="!fieldHasPositionalData(question)" #append>
              <v-row class="pa-3" align="center">
                <v-icon class="mr-1" :icon="mdiFileHidden" /> Field not on PDF
              </v-row>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import MappedFormViewAqlActions from "@/components/form-mapping/MappedFormViewAqlActions.vue";

import { mdiFileHidden } from "@mdi/js";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps({
  formId: { type: [String, Number], required: true }
});
const emit = defineEmits(["view"]);

const formStore = useMappedFormStore(props.formId);

const { activeField, fields, readonly } = storeToRefs(formStore);

const canCreateChildQuestions = computed(() => {
  if (readonly.value) return false;
  return !activeField.value?.referenceField;
});
const canCreateParentQuestion = computed(() => {
  if (readonly.value) return false;
  if (parentQuestion.value) return false;
  return !activeField.value?.referenceField;
});
const parentQuestion = computed(() => {
  if (!activeField.value?.parentQuestion) return null;
  return fields.value.by_id[activeField.value?.parentQuestion];
});
const childQuestions = computed(() => {
  const childQuestions = [];
  if (!activeField.value?.id) return childQuestions;

  fields.value.ids.forEach(id => {
    const isParent =
      `${fields.value.by_id[id].parentQuestion}` === `${activeField.value.id}`;

    if (isParent) childQuestions.push(fields.value.by_id[id]);
  });

  return childQuestions;
});
function fieldHasPositionalData(field) {
  return Boolean(field?.coordinates?.length);
}
</script>
