<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="todo-table"
    no-data-text="No To-Dos Found!"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0 pa-3" align="center">
        <h1 class="text-h5">To-Dos</h1>
        <v-spacer />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              data-testid="todo-table-refresh"
              variant="text"
              density="comfortable"
              class="table-btn"
              :icon="mdiRefresh"
              v-bind="templateProps"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <v-divider />
      <table-filter
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.title`]="{ item }">
      <v-row
        :class="dueIndicator(item.additional)"
        class="pl-1 ma-0 h-100 align-center"
      >
        <a class="truncate-400 pointer" @click="showTodo(item.additional)">
          {{ item.title }}
        </a>
      </v-row>
    </template>

    <template #[`item.created`]="{ item }">
      <timestamp-formatter :model-value="item.created" />
    </template>

    <template #[`item.followUpAt`]="{ item }">
      <timestamp-formatter :model-value="item.followUpAt" />
    </template>

    <template #[`item.complete`]="{ item }">
      <app-button
        variant="text"
        density="comfortable"
        data-testid="toggle-complete"
        :color="item.complete ? 'primary' : null"
        :icon="item.complete ? mdiCheckCircle : mdiCheckboxBlankCircleOutline"
        @click="
          markTodoAsComplete(item.additional.id, item.additional.complete)
        "
      />
    </template>

    <template #[`item.actions`]="{ item }">
      <app-button
        :icon="mdiPencil"
        color="accent"
        variant="text"
        density="comfortable"
        data-testid="edit-todo"
        @click="editTodo(item.additional.id)"
      />
      <app-button
        :icon="mdiDelete"
        color="error"
        variant="text"
        density="comfortable"
        data-testid="delete-todo"
        @click="deleteToDo(item.additional.id)"
      />
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import ToDoItemDetailsDialog from "@/components/todo/ToDoItemDetailsDialog.vue";
import ToDoItemDialog from "@/components/todo/ToDoItemDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useTableStore } from "@/stores/table";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { deleteTodo, getTodos, updateTodo } from "@/api/todos.service";
import { markRaw } from "vue";
import { useDialogStore } from "@/stores/dialog";
import {
  mdiCheckCircle,
  mdiCheckboxBlankCircleOutline,
  mdiRefresh,
  mdiPencil,
  mdiDelete
} from "@mdi/js";

useHead({ title: "To-Dos" });

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

const user = useUserStore();
const { todoTable } = storeToRefs(useTableStore());

function getTableHeaders() {
  const headers = [
    new TableHeader({
      text: "Title",
      value: "title",
      map: "title",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Assignee",
      value: "assignee",
      map: "assignee",
      sortFilterMap: [
        { key: "assignee_id", value: "id" },
        { key: "assignee_type", value: "type" }
      ],
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Subject",
      value: "subject",
      map: "subject",
      sortFilterMap: [
        { key: "subject_id", value: "id" },
        { key: "subject_type", value: "type" }
      ],
      sortKey: "subject",
      displayMap: "title",
      ...TableHeader.IS_GLOBAL_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Created",
      value: "created",
      map: "createdAt",
      sortFilterMap: "created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUpAt",
      map: "followUpAt",
      sortFilterMap: "follow_up_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Completed",
      value: "complete",
      map: "complete",
      sortFilterMap: "complete",
      selectableOptions: [
        { title: "Yes", value: "true" },
        { title: "No", value: "false" }
      ],
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ];

  if (user.isGroupThreePlus) {
    headers.push(
      new TableHeader({
        text: "Include Global To-Dos",
        value: "view",
        map: "view",
        checkboxValues: {
          true: "all",
          false: null
        },
        hideDisplayValue: true,
        ...TableHeader.IS_ADDITIONAL,
        ...TableHeader.IS_QUERY_FILTER,
        ...TableHeader.IS_CHECKBOX_TYPE,
        ...TableHeader.IS_FILTERABLE
      })
    );
  }
  return headers;
}

function initializeTableSettings() {
  table.ingestFromStore({
    filter: todoTable.value.filter,
    options: todoTable.value.options,
    defaultFilter: { complete: "false", assignee: user.loginable },
    defaultOptions: TableOptions({
      sortBy: [{ key: "followUpAt", order: "asc" }]
    })
  });
}

async function markTodoAsComplete(id, complete) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    scrollable: true,
    persistent: true,
    title: `Mark To-Do as ${complete ? "Incomplete" : "Complete"}`,
    subtitle: "Please confirm your intent",
    func: async () => {
      await updateTodo(id, { complete: !complete });
      await getData();
    }
  });
}

async function editTodo(id) {
  const res = await dialog.showDialog({
    component: markRaw(ToDoItemDialog),
    scrollable: true,
    persistent: true,
    modelValue: { id }
  });

  if (res?.id) getData();
}

function deleteToDo(id) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    scrollable: true,
    persistent: true,
    title: "Delete To-Do",
    subtitle: "Are you sure you want to delete this To-Do?",
    func: async () => {
      await deleteTodo(id);
      dialog.closeDialog();
      getData();
    }
  });
}

async function getData() {
  const additionalFilter = {};
  if (!table.filter.value.view) additionalFilter.view = "my";
  try {
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  todoTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  Object.keys(filter).forEach(k => {
    if (filter[k] === null) delete filter[k];
  });
  table.filter.value = filter;
  todoTable.value.filter = filter;
  table.resetPage();
  getData();
}

function showTodo(todo) {
  dialog.showDialog({
    id: todo.id,
    title: todo.title,
    component: markRaw(ToDoItemDetailsDialog)
  });
}

const today = new Date();
function dueIndicator(task) {
  if (task.complete) return;
  if (task.followUpAt) {
    const followUpAtDate = new Date(task.followUpAt);

    if (followUpAtDate < today) {
      return "overdue";
    } else if (followUpAtDate.toDateString() === today.toDateString()) {
      return "due-today";
    }
  }
  return "upcoming";
}

const table = useTable({
  headers: getTableHeaders(),
  getData: getTodos,
  shouldIncludeCancelToken: true
});

initializeTableSettings();
</script>

<style lang="scss">
.overdue {
  border-left: 5px solid rgb(var(--v-theme-error));
}
.due-today {
  border-left: 5px solid rgb(var(--v-theme-warning));
}
.upcoming {
  border-left: 5px solid rgb(var(--v-theme-info));
}
</style>
