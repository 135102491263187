<template>
  <v-card flat tile>
    <v-row align="center" class="ma-0">
      <v-col>
        <span>
          <a
            data-testid="card-domain"
            :href="`https://${domain}`"
            target="_blank"
          >
            https://{{ domain }}
          </a>
        </span>
        <br />
        Domain
      </v-col>
      <v-col>
        <router-link
          data-testid="card-ownable"
          :to="{
            name: ownable.typeModel === 'Agent' ? 'AgentView' : 'AgencyView',
            params: {
              id: ownable.id
            }
          }"
        >
          {{ ownable.name }}
        </router-link>
        <br />
        Agent
      </v-col>
      <v-spacer />
      <v-col>
        <v-row justify="end" class="ma-0">
          <avatar-edit
            :id="id"
            :show-avatar="showAvatar"
            :avatar="avatarUrl"
            type="ApprovedDomain"
            :deletable="Boolean(avatarUrl)"
            editable
            @avatar-update="displayAvatar"
            @avatar-delete="hideAvatar"
            @avatar-error="hideAvatar"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import AvatarEdit from "@/components/shared/AvatarEdit.vue";
import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { nextTick, ref, watch } from "vue";

const approvedDomainView = useApprovedDomainView();

const { useOwnersAvatar, avatar, assignable, domain, ownable, id } =
  storeToRefs(approvedDomainView);

const avatarUrl = ref(null);
const showAvatar = ref(true);

function hideAvatar() {
  if (useOwnersAvatar.value) updateToCustomAvatar();
  showAvatar.value = false;
}

function displayAvatar(newUrl) {
  if (useOwnersAvatar.value) updateToCustomAvatar();
  avatar.value = newUrl;
  showAvatar.value = true;
}

function updateToCustomAvatar() {
  useOwnersAvatar.value = false;
  approvedDomainView.updateApprovedDomain("useOwnersAvatar");
}

function generateAvatarUrl() {
  let uri = avatar.value;
  if (useOwnersAvatar.value) uri = assignable.value.avatar_url;
  return uri || null;
}

watch(
  [useOwnersAvatar, avatar],
  () => {
    showAvatar.value = false;
    nextTick(() => {
      avatarUrl.value = generateAvatarUrl();
      showAvatar.value = true;
    });
  },
  { immediate: true }
);
</script>
