<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="advisor-profile-payments-table"
    item-value="additional.id"
    :mobile="null"
    mobile-breakpoint="sm"
    hide-default-footer
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #[`item.createdDate`]="{ item }">
      <timestamp-formatter :model-value="item.createdDate" parser="sole-day" />
    </template>

    <template #[`item.premium`]="{ item }">
      <currency-formatter :model-value="item.premium" />
    </template>
    <template #[`item.commission`]="{ item }">
      <currency-formatter :model-value="item.commission" />
      <span class="text-grey">
        (<percentage-formatter :model-value="item.additional.percent" />)
      </span>
    </template>
    <template #bottom>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <app-button
          data-testid="advisor-profile-payments-table-view-all"
          color="primary"
          class="text-none"
          variant="text"
          :prepend-icon="mdiOpenInApp"
          :text="`View ${name}'s Payments`"
          @click="viewAll"
        />
      </v-row>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import PercentageFormatter from "@/components/shared/formatters/PercentageFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useTableStore } from "@/stores/table";

import { emailPayments, getPayments } from "@/api/payments.service";
import { parseErrorMessage } from "@/util/helpers";

import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { mdiOpenInApp } from "@mdi/js";

const router = useRouter();
const snackbar = useSnackbarStore();
const user = useUserStore();
const tableStore = useTableStore();
const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);

const table = useTable({
  getData: getPayments,
  emailData: emailPayments,
  shouldIncludeCancelToken: true,
  options: TableOptions({
    sortBy: [{ key: "createdDate", order: "desc" }]
  }),
  headers: [
    new TableHeader({
      text: "Insured",
      value: "insured",
      map: "commission.appointmentCase.insured.name"
    }),
    new TableHeader({
      text: "Paid By",
      value: "paidBy",
      map: "commission.payor.name"
    }),
    new TableHeader({
      text: "Premium",
      value: "premium",
      map: "premium"
    }),
    new TableHeader({
      text: "Commission",
      value: "commission",
      map: "dollar",
      sortFilterMap: "payments.dollar",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Created",
      value: "createdDate",
      map: "createdDate",
      sortFilterMap: "payments.created_at",
      ...TableHeader.IS_SORTABLE
    })
  ]
});

async function getData() {
  try {
    const additionalFilter = {
      assigned_payable_id: id.value,
      assigned_payable_type: type.value,
      view: "all"
    };

    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function viewAll() {
  const paymentFilter = {
    paidTo: tableFilterData.value
  };
  if (user.isGroupFour) {
    paymentFilter.view = "all";
  }

  tableStore.paymentsTable.filter = paymentFilter;
  router.push({
    name: "Commissions",
    query: { page: "payments" }
  });
}
</script>
