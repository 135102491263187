<template>
  <v-fade-transition mode="out-in">
    <v-col v-if="loading" key="loader">
      <v-row dense>
        <v-col cols="12">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="table" />
        </v-col>
      </v-row>
    </v-col>
    <v-row v-else key="loaded" class="w-100 ma-0" dense>
      <v-col cols="12" lg="12" class="pb-0">
        <personnel-view-card />
      </v-col>
      <v-col cols="12" class="py-0">
        <personnel-view-actions />
      </v-col>
      <v-col cols="12" lg="12" class="pt-0">
        <v-card flat tile>
          <v-tabs
            v-model="activeTab"
            show-arrows
            class="no-bottom-radius"
            color="primary"
          >
            <v-tab
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              class="text-none"
              :data-testid="`tab-${tab.title}`"
            >
              <v-icon class="pr-1" :icon="tab.icon" />
              {{ tab.title }}
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-window v-model="activeTab" touchless>
            <v-tabs-window-item
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              :eager="tab.eager"
            >
              <component :is="tab.component" v-bind="tab.componentProps" />
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card>
      </v-col>
    </v-row>
  </v-fade-transition>
</template>

<script setup>
import PersonnelViewActions from "@/components/personnel/PersonnelViewActions.vue";
import PersonnelViewCard from "@/components/personnel/PersonnelViewCard.vue";
import PersonnelViewDetails from "@/components/personnel/PersonnelViewDetails.vue";
import PersonnelViewChat from "@/components/personnel/PersonnelViewChat.vue";

import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { usePersonnelView } from "@/stores/personnel-view";
import { useHead } from "@unhead/vue";
import { onBeforeUnmount, ref, watch, computed, markRaw } from "vue";
import { useRouter } from "vue-router";
import { mdiAccount, mdiChat } from "@mdi/js";
import { useUserStore } from "@/stores/user";
import { useFullEmailViewer } from "@/composables/full-email.composable";

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  page: {
    type: String,
    default: "details"
  },
  highlightNote: {
    type: Number,
    required: false,
    default: null
  }
});

const router = useRouter();
const head = useHead({ title: "Personnel Viewer" });

const snackbar = useSnackbarStore();
const personnelView = usePersonnelView();
const instance = useInstanceStore();
const user = useUserStore();

const { breadcrumb } = storeToRefs(instance);
const { name } = storeToRefs(personnelView);

const activeTab = ref(0);
const loading = ref(false);

const tabs = computed(() => [
  {
    title: "Details",
    page: "details",
    icon: mdiAccount,
    component: markRaw(PersonnelViewDetails),
    componentProps: { readonly: !user.isGroupTwoPlus }
  },
  {
    title: "Chat",
    page: "chat",
    icon: mdiChat,
    component: markRaw(PersonnelViewChat),
    eager: true
  }
]);

async function getPersonnel() {
  loading.value = true;
  try {
    await personnelView.initialize(props.id);
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;

    if (props.highlightNote) {
      router.replace({ query: { "highlight-note": undefined } });
      const fullEmailViewer = useFullEmailViewer(props.highlightNote);
      fullEmailViewer.retrieveFullEmail();
    }
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: "Unable to locate personnel...Redirecting to home.",
      timeout: 6000
    });
    router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

onBeforeUnmount(() => {
  breadcrumb.value = "";
});

watch(
  name,
  () => {
    breadcrumb.value = name.value;
    head.patch({ title: name.value });
  },
  { deep: true }
);

getPersonnel();
</script>
