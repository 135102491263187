import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/marketing_managers";

export const updateMarketingManager = (id, marketing_manager) => {
  return getHttpClient().put(`/${baseUrl}/${id}`, {
    marketing_manager
  });
};

export async function getExternalMarketingManagers() {
  const { data } = await getHttpClient().get(`${baseUrl}/external`);
  return data.marketing_managers;
}

export function updateLink(marketingManagerId, body) {
  return getHttpClient().put(`${baseUrl}/${marketingManagerId}`, body);
}
