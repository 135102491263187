<template>
  <v-card flat tile>
    <v-card-text class="pa-0">
      <v-row class="ma-0" dense align="center">
        <v-col cols="12" data-testid="insured">
          <v-row justify="center" justify-sm="start" class="ma-0 pa-3">
            <div>
              <strong style="font-size: 1.2rem"> {{ insured }} </strong>
              <br />
              Insured
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
      <card-items :items="cardDetails" />
    </v-card-text>
  </v-card>
</template>

<script setup>
import CardItems from "@/components/shared/card-items/CardItems.vue";

import { computed } from "vue";
import {
  routerLinkItem,
  textItem
} from "@/components/shared/card-items/card-items";
import { storeToRefs } from "pinia";
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
import {
  currencyFormat,
  getRouterLink,
  heightText,
  timestampFormatter
} from "@/util/helpers";

const {
  insured,
  advisor,
  status,
  typeOfInsurance,
  deathBenefit,
  height,
  weight,
  birthdate
} = storeToRefs(useImpairedRiskQuoteView());

const cardDetails = computed(() => {
  const details = [];

  details.push(
    routerLinkItem({
      title: "Advisor",
      text: advisor.value.name,
      to: getRouterLink(advisor.value.type, advisor.value.id)
    })
  );

  details.push(textItem({ title: "Status", text: status.value }));
  details.push(
    textItem({ title: "Type of Insurance", text: typeOfInsurance.value })
  );

  details.push(
    textItem({
      title: "Death Benefit",
      text: currencyFormat(deathBenefit.value, 0)
    })
  );

  if (height.value || weight.value) {
    const text = [];
    if (height.value) text.push(heightText(height.value));
    if (weight.value) text.push(`${weight.value}lbs.`);
    details.push(
      textItem({
        title: "Build",
        text: text.join(" ")
      })
    );
  }

  if (birthdate.value) {
    details.push(
      textItem({
        title: "Birthdate",
        text: timestampFormatter(birthdate.value, "sole-day")
      })
    );
  }

  return details;
});
</script>
