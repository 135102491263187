<template>
  <v-row>
    <v-col cols="12" md="6">
      <date-input v-bind="props.dateProps" v-model="date" />
    </v-col>
    <v-col cols="12" md="6">
      <autocomplete-field
        v-bind="props.timeProps"
        v-model="time"
        :prepend-inner-icon="mdiClockOutline"
        :menu-props="{ offsetY: true, auto: true }"
        :items="AVAILABLE_TIMES"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import DateInput from "@/components/shared/DateInput.vue";
import { mdiClockOutline } from "@mdi/js";
import { format, parse } from "date-fns";
import {  ref, watch, toRef } from "vue";

const AVAILABLE_TIMES = (() => {
  const times = [];
  for (let i = 0; i < 25; i++) {
    const hour = (i % 12) + 1;
    const ampm = i < 11 ? "AM" : "PM";
    times.push(`${hour}:00${ampm}`);
    times.push(`${hour}:15${ampm}`);
    times.push(`${hour}:30${ampm}`);
    times.push(`${hour}:45${ampm}`);
  }
  return times;
})();

const emit = defineEmits(["update:model-value", "update:date", "update:time"]);

const props = defineProps({
  dateProps: {
    type: Object,
    required: false,
    default: () => {}
  },
  timeProps: {
    type: Object,
    required: false,
    default: () => {}
  },
  modelValue: {
    type: [Date, String],
    required: false,
    default: null
  }
});

const propValue = toRef(props, "modelValue");

const date = ref(null);
const time = ref(null);
const dateTime = ref(null);

function updateDateTime() {
  syncHints();
  try {
    if (!date.value || !time.value) dateTime.value = null;
    else {
      dateTime.value = parse(
        `${date.value} ${time.value}`,
        "yyyy-MM-dd h:mma",
        new Date()
      );
    }
    if (propValue.value === dateTime.value) return;
    emit("update:model-value", dateTime.value);
  } catch (e) {
    dateTime.value = null;
  }
}

function updateDateAndTime() {
  if (propValue.value === null && (date.value || time.value)) return;
  let prevDate = null;
  let prevTime = null;
  let prevDateTime = null;

  if (propValue.value) {
    try {
      const followUp = new Date(propValue.value);
      prevDate = format(followUp, "yyyy-MM-dd");
      prevTime = format(followUp, "h:mma");
      dateTime.value = propValue.value;
    } catch (e) {
      prevDate = null;
      prevTime = null;
      prevDateTime = null;
    }
  }
  date.value = prevDate;
  time.value = prevTime;
  dateTime.value = prevDateTime;
  syncHints();
}

function syncHints() {
  if (date.value) emit("update:date", date.value);
  if (time.value) emit("update:time", time.value);
}

watch(propValue, updateDateAndTime, { immediate: true });
watch([date, time], updateDateTime, { immediate: true, deep: true });
</script>
