import { useUserStore } from "@/stores/user";
export const syncWithLocalStoragePlugin = ({ store, options }) => {
  if (!options.syncWithLocalStorage) return;

  const user = useUserStore();
  const storageKey = `${user.group}-${user.loginable.type}-${user.loginable.id}-${store.$id}`;

  try {
    const localState = localStorage.getItem(storageKey);
    if (localState) {
      const storedState = JSON.parse(localState);
      store.$patch(storedState);
    }
  } catch (e) {
    // do nothing
  }

  store.$subscribe(() => {
    try {
      localStorage.setItem(storageKey, JSON.stringify(store.$state));
    } catch (e) {
      // do nothing
    }
  });
};
