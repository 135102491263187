<template>
  <v-card flat tile>
    <v-card-text>
      <v-data-table
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        must-sort
        data-testid="build-charts-table"
        :mobile="null"
        mobile-breakpoint="md"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :loading="table.loading.value"
        :search="search"
        :footer-props="table.footerProps.value"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0">
            <span class="text-h5"> Build Charts </span>
            <v-spacer />
            <text-field
              v-model="search"
              data-lpignore="true"
              label="Search"
              :prepend-inner-icon="mdiMagnify"
              hide-details
              data-testid="build-charts-table-search"
            />
            <v-tooltip location="bottom">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  variant="text"
                  class="table-btn"
                  density="comfortable"
                  v-bind="templateProps"
                  data-testid="build-charts-table-refresh"
                  :disabled="table.loading.value"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
        </template>
        <template #[`item.actions`]="{ item }">
          <template v-if="isGroupFour">
            <app-button
              :icon="mdiPencil"
              color="accent"
              density="comfortable"
              variant="text"
              data-testid="edit-chart"
              @click="editBuildChart(item.additional)"
            />
            <app-button
              :icon="
                item.additional.complete ? mdiCheckDecagram : mdiDecagramOutline
              "
              variant="text"
              density="comfortable"
              :data-testid="
                item.additional.complete ? 'is-complete' : 'is-incomplete'
              "
              :color="item.additional.complete ? 'orange' : 'grey'"
              @click="toggleCompleteness(item.additional)"
            />
          </template>
          <app-button
            v-if="item.additional.documentUrl"
            :icon="mdiDownload"
            density="comfortable"
            variant="text"
            color="primary"
            data-testid="download-document"
            :href="item.additional.documentUrl"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import UploadDocumentDialog from "@/dialogs/UploadDocumentDialog.vue";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import {
  buildChartsIndex,
  updateBuildChart,
  uploadBuildChartDocument
} from "@/api/build-charts.service";
import { useUserStore } from "@/stores/user";
import { getDocumentUrl } from "@/api/documents.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { useTable } from "@/composables/table.composable";
import { markRaw, ref } from "vue";
import { useDialogStore } from "@/stores/dialog";
import { TableOptions } from "@/classes/data-table/TableOptions";
import {
  mdiMagnify,
  mdiRefresh,
  mdiPencil,
  mdiCheckDecagram,
  mdiDecagramOutline,
  mdiDownload
} from "@mdi/js";
import { useSearchItemsCache } from "@/stores/search-items-cache";

useHead({ title: "Builds" });

const search = ref("");

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const searchItemsCache = useSearchItemsCache();
const user = useUserStore();

const { buildCharts } = storeToRefs(searchItemsCache);
const { isGroupFour } = storeToRefs(user);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Name",
      value: "name",
      map: "name",
      sortFilterMap: "name",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Gender",
      value: "gender",
      map: "gender",
      sortFilterMap: "gender",
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  ],
  options: TableOptions({ sortBy: [{ key: "name", order: "asc" }] }),
  getData: async () => {
    const items = await buildChartsIndex();
    const mapped = items.map(v => ({
      ...v,
      documentUrl: v.documentUid ? getDocumentUrl(v.documentUid) : null
    }));
    return { items: mapped };
  }
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function toggleCompleteness({ id, name, complete }) {
  const toggledValue = !complete;

  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Mark ${name} as ${toggledValue ? "Complete" : "Incomplete"}`,
    subtitle: "Please confirm that you are intentionally making this change",
    showErrorMessage: true,
    func: async () => {
      await updateBuildChart(id, toggledValue);
      buildCharts.value.dirty = true;
      await getData();
    }
  });
}

function editBuildChart({ id }) {
  if (!isGroupFour.value) return;
  dialog
    .showDialog({
      component: markRaw(UploadDocumentDialog),
      uploadFunction: ({ file }) => uploadBuildChartDocument(id, file),
      single: true
    })
    .then(document => {
      if (!document) return;
      this.getData();
    });
}
</script>
