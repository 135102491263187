<template>
  <v-card color="section" tile>
    <v-col cols="12">
      <v-sheet class="pa-3 rounded">
        <h3 class="ml-3">
          Analytics
          <a href="https://docs.back9ins.com/#analytics" target="_blank">
            <v-icon color="primary" :icon="mdiInformation" />
          </a>
        </h3>
        <v-row class="pa-3" dense>
          <v-col cols="12" lg="6">
            <text-field
              v-model="googleAnalyticsId"
              data-lpignore="true"
              :prepend-inner-icon="mdiChartAreaspline"
              label="Google Analytics ID"
              persistent-hint
              hint=" "
              data-testid="analytics-ga"
              :success="Boolean(googleAnalyticsId)"
              @update:model-value="saveAttribute('googleAnalyticsId')"
            >
              <template #message>
                Report Quote & Apply traffic to Google Analytics.
                <a href="https://docs.back9ins.com"> Click for more details</a>
              </template>
              <template #append-inner>
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.googleAnalyticsId.controller.value"
                />
              </template>
            </text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <text-field
              v-model="googleTagManagerId"
              data-lpignore="true"
              :prepend-inner-icon="mdiTag"
              label="Google Tag Manager ID"
              data-testid="analytics-gtm"
              :success="Boolean(googleTagManagerId)"
              @update:model-value="saveAttribute('googleTagManagerId')"
            >
              <template #append-inner>
                <active-save-indicator
                  class="ml-2"
                  :controller="savingBuffer.googleTagManagerId.controller.value"
                />
              </template>
            </text-field>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <approved-domain-statistics />
  </v-card>
</template>

<script setup>
import ApprovedDomainStatistics from "@/components/approved-domain/ApprovedDomainStatistics.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { storeToRefs } from "pinia";

import { mdiInformation, mdiChartAreaspline, mdiTag } from "@mdi/js";
import { useActiveSave } from "@/composables/active-save.composable";
import { nextTick, onMounted, ref } from "vue";

const approvedDomain = useApprovedDomainView();

const { googleTagManagerId, googleAnalyticsId } = storeToRefs(approvedDomain);

const savingBuffer = {
  googleTagManagerId: useActiveSave(),
  googleAnalyticsId: useActiveSave()
};

const isMounted = ref(false);

onMounted(() => nextTick(() => (isMounted.value = true)));

function saveAttribute(attribute, timeout = 300) {
  if (!isMounted.value) return;
  savingBuffer[attribute].debounceUpdate(
    () => approvedDomain.updateApprovedDomain(attribute),
    timeout
  );
}
</script>
