<template>
  <v-img
    v-if="showAvatar && avatar"
    style="border-radius: 5px; height: 100px; width: 300px"
    contain
    data-testid="avatar-edit"
    :src="avatar"
    @error="emit('avatar-error')"
  >
    <div
      v-if="editable"
      style="
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 8px;
      "
    >
      <div
        style="
          width: 200px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        "
      >
        <app-button
          data-testid="edit-avatar"
          variant="text"
          density="comfortable"
          :icon="mdiPencil"
          color="accent"
          @click="editAvatar(true)"
        />
      </div>
    </div>
  </v-img>
  <v-row v-else align="center" justify="center">
    <v-col class="px-7">
      <h3>
        No Avatar
        <app-button
          v-if="editable"
          variant="text"
          density="comfortable"
          color="primary"
          :icon="mdiUpload"
          @click="editAvatar(false)"
        />
      </h3>
    </v-col>
  </v-row>
</template>

<script setup>
import ChangeAvatarDialog from "@/dialogs/ChangeAvatarDialog.vue";
import { mdiPencil, mdiUpload } from "@mdi/js";
import { useDialogStore } from "@/stores/dialog";
import { markRaw } from "vue";
const props = defineProps({
  showAvatar: {
    default: true,
    type: Boolean
  },
  avatar: { type: String, default: null },
  id: { type: Number, default: null },
  deletable: Boolean,
  type: { type: String, default: null },
  editable: {
    default: true,
    type: Boolean
  }
});

const emit = defineEmits(["avatar-update", "avatar-delete", "avatar-edit"]);

const dialog = useDialogStore();

async function editAvatar(isDeletable = true) {
  const res = await dialog.showDialog({
    component: markRaw(ChangeAvatarDialog),
    type: props.type,
    id: props.id,
    deletable: isDeletable && props.deletable
  });
  if (res?.deleted) {
    emit("avatar-delete");
  } else if (res?.updated) {
    emit("avatar-update", res.newUrl);
  }
}
</script>
