<template>
  <v-tooltip v-if="props.fullEmail" location="top">
    <template #activator="{ props: templateProps }">
      <app-button
        v-bind="templateProps"
        size="small"
        icon
        density="comfortable"
        variant="text"
        data-testid="inspect-full-email"
        color="primary"
        :loading="fullEmailViewer.gettingFullEmail.value"
        @click="fullEmailViewer.retrieveFullEmail"
      >
        <v-icon size="14" :icon="mdiEmail" />
      </app-button>
    </template>
    <span> Emailed - Inspect Full Email </span>
  </v-tooltip>
  <v-tooltip v-else location="top">
    <template #activator="{ props: templateProps }">
      <v-icon
        v-bind="templateProps"
        style="margin: 2px"
        size="14"
        class="default-icon"
        :icon="mdiEmail"
      />
    </template>
    <span> Emailed </span>
  </v-tooltip>
</template>

<script setup>
import { mdiEmail } from "@mdi/js";

import { useFullEmailViewer } from "@/composables/full-email.composable";
const props = defineProps({
  fullEmail: Boolean,
  messageId: { type: Number, required: true }
});

const fullEmailViewer = useFullEmailViewer(props.messageId);
</script>
