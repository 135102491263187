<template>
  <v-card class="ma-3">
    <v-card-title class="text-h5">Create a Payment</v-card-title>
    <v-divider />
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12" md="3">
          <statement-search
            v-model="payment.statement"
            v-bind="statementValidation"
            data-testid="statement-search"
          />
        </v-col>
        <v-col cols="12" md="3">
          <policy-search
            v-model="payment.policy"
            v-bind="policyValidation"
            data-testid="policy-search"
          />
        </v-col>
        <v-col cols="12" md="3">
          <currency-input
            v-model="payment.premium"
            label="Premium"
            include-decimals
            include-negative
            :prepend-inner-icon="mdiCurrencyUsd"
            v-bind="premiumValidation"
            data-testid="premium-input"
          />
        </v-col>
        <v-col cols="12" md="3">
          <currency-input
            v-model="payment.override"
            include-decimals
            include-negative
            label="Override"
            :prepend-inner-icon="mdiCurrencyUsd"
            v-bind="overrideValidation"
            data-testid="override-input"
          />
        </v-col>
        <v-col cols="12" md="4">
          <autocomplete-field
            v-model="payment.commissionType"
            label="Commission Type"
            placeholder="Select Commission Type"
            :prepend-inner-icon="mdiSack"
            :items="COMMISSION_TYPES"
            v-bind="commissionTypeValidation"
            data-testid="commission-type-select"
          />
        </v-col>
        <v-col cols="12" md="4">
          <text-field
            v-model="payment.percent"
            label="Percent (Optional)"
            data-lpignore="true"
            :prepend-inner-icon="mdiPercent"
            data-testid="percent"
            :success="Boolean(payment.percent)"
          />
        </v-col>
        <v-col cols="12" md="4">
          <autocomplete-field
            v-model="payment.agent"
            return-object
            label="Select Agent"
            :item-title="formatAgent"
            :items="payment?.policy?.appointmentCases || []"
            :prepend-inner-icon="mdiAccount"
            v-bind="agentValidation"
            data-testid="agent-select"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <app-button
        class="text-none"
        color="primary"
        data-testid="create-payment"
        :loading="loading"
        @click="save"
      >
        <v-icon class="mr-1" :icon="mdiPlus" /> Create Payment
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import PolicySearch from "@/components/shared/PolicySearch.vue";
import StatementSearch from "@/components/shared/StatementSearch.vue";

import { computedValidation, parseErrorMessage } from "@/util/helpers";
import { createPayment } from "@/api/payments.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { Payment, PaymentToCreateRequest } from "@/factories/Payment";
import { COMMISSION_TYPES } from "@/data/transaction-data";

import {
  mdiCurrencyUsd,
  mdiSack,
  mdiPercent,
  mdiAccount,
  mdiPlus
} from "@mdi/js";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";

useHead({ title: "Create Payment" });

const snackbar = useSnackbarStore();

const loading = ref(false);
const payment = ref(Payment({ commissionType: "First Year" }));

const v$ = useVuelidate(
  {
    payment: {
      policy: { required: v => Boolean(v?.id) },
      statement: { required: v => Boolean(v?.id) },
      premium: { required: Boolean },
      override: { required: Boolean },
      commissionType: { required: Boolean },
      agent: { required: v => Boolean(v?.id) }
    }
  },
  { payment },
  { $scope: null, $autoDirty: true }
);

const statementValidation = computedValidation(v$.value.payment.statement, {
  required: "Required"
});
const policyValidation = computedValidation(v$.value.payment.policy, {
  required: "Required"
});
const premiumValidation = computedValidation(v$.value.payment.premium, {
  required: "Required"
});
const overrideValidation = computedValidation(v$.value.payment.override, {
  required: "Required"
});
const commissionTypeValidation = computedValidation(
  v$.value.payment.commissionType,
  {
    required: "Required"
  }
);
const agentValidation = computedValidation(v$.value.payment.agent, {
  required: "Required"
});

function formatAgent(item) {
  return `${item.owner.name} - ${item.splitPercent * 100}% - ${
    item.marketingManager.name
  }`;
}

async function save() {
  const isValid = v$.value.$validate();
  if (!isValid) return;
  loading.value = true;
  try {
    await createPayment({ payment: PaymentToCreateRequest(payment.value) });
    snackbar.showSuccessSnackbar({
      message: "Successfully Created Payment",
      timeout: 5000
    });
    payment.value = Payment({ statement: payment.value.statement });
    v$.value.$reset();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Unable to create payment: ${parseErrorMessage(e)}`,
      timeout: 5000
    });
  } finally {
    loading.value = false;
  }
}
</script>
