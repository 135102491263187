<template>
  <v-card>
    <v-card-title> {{ editing ? "Edit" : "Add" }} Payout</v-card-title>
    <v-card-text>
      <select-field
        v-model="payout.compBuilder"
        return-object
        data-testid="comp-builder-select"
        item-title="name"
        :prepend-inner-icon="mdiWrench"
        label="Comp Builder"
        :items="compBuilders"
        v-bind="compBuilderValidation"
        :loading="fetchingCompBuilders"
      />
      <select-field
        v-model="payout.payor"
        return-object
        data-testid="payor-select"
        label="Payor"
        item-title="name"
        :prepend-inner-icon="mdiBank"
        v-bind="payorValidation"
        :items="payors"
      />
      <select-field
        v-model="payout.commissionType"
        data-testid="commission-type-select"
        label="Commission Type"
        :prepend-inner-icon="mdiCube"
        :items="COMMISSION_TYPES"
        v-bind="commissionTypeValidation"
      />
      <text-field
        v-model.number="payout.percent"
        data-testid="percent-input"
        data-lpignore="true"
        label="Percent"
        :prepend-inner-icon="mdiPercent"
        v-bind="percentValidation"
      />
      <text-field
        v-model.number="payout.startYear"
        data-testid="start-year-input"
        label="Start Year"
        data-lpignore="true"
        :prepend-inner-icon="mdiCalendarStart"
        v-bind="startYearValidation"
      />
      <text-field
        v-model.number="payout.endYear"
        data-testid="end-year-input"
        data-lpignore="true"
        label="End Year"
        :prepend-inner-icon="mdiCalendarEnd"
        v-bind="endYearValidation"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <app-button
        variant="outlined"
        color="grey"
        class="text-none"
        :disabled="loading"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        data-testid="save"
        class="text-none"
        color="primary"
        :loading="loading"
        @click="save"
      >
        {{ editing ? "Save" : "Create" }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import {
  Payout,
  PayoutToUpdateRequest,
  PayoutToCreateRequest
} from "@/factories/Product";
import { parseErrorMessage, validationComputeV2 } from "@/util/helpers";
import { compBuilderSearch } from "@/api/search.service";
import { createPayout, updatePayout } from "@/api/payouts.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { COMMISSION_TYPES } from "@/data/transaction-data";

import {
  mdiWrench,
  mdiBank,
  mdiCube,
  mdiPercent,
  mdiCalendarStart,
  mdiCalendarEnd
} from "@mdi/js";

const props = defineProps({
  modelValue: { type: Object, required: false, default: () => {} },
  payors: { type: Array, required: true },
  productId: { type: Number, required: true },
  carrierId: { type: Number, required: true }
});

const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const payout = ref(Payout(props.modelValue));

const compBuilders = ref([]);
const fetchingCompBuilders = ref(false);
const loading = ref(false);
const editing = Boolean(payout.value.id);

const v$ = useVuelidate(
  {
    payout: {
      compBuilder: { required: v => Boolean(v?.id) },
      payor: { required: v => Boolean(v?.id) },
      commissionType: { required: v => Boolean(v) },
      percent: { required: v => Boolean(v) },
      startYear: { required: v => Boolean(v) },
      endYear: { required: v => Boolean(v) }
    }
  },
  { payout },
  { $scope: false, $autoDirty: false }
);

const compBuilderValidation = computed(() => {
  return validationComputeV2(v$.value.payout.compBuilder, {
    required: "Required"
  });
});
const payorValidation = computed(() => {
  return validationComputeV2(v$.value.payout.payor, { required: "Required" });
});
const commissionTypeValidation = computed(() => {
  return validationComputeV2(v$.value.payout.commissionType, {
    required: "Required"
  });
});
const percentValidation = computed(() => {
  return validationComputeV2(v$.value.payout.percent, { required: "Required" });
});
const startYearValidation = computed(() => {
  return validationComputeV2(v$.value.payout.startYear, {
    required: "Required"
  });
});
const endYearValidation = computed(() => {
  return validationComputeV2(v$.value.payout.endYear, { required: "Required" });
});

async function save() {
  if (loading.value) return;
  loading.value = true;

  const isValid = await v$.value.$validate();
  if (!isValid) return;

  try {
    await (editing ? update() : create());
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

async function update() {
  await updatePayout(payout.value.id, PayoutToUpdateRequest(payout.value));
  snackbar.showSuccessSnackbar({
    message: "Payout has been updated",
    timeout: 6000
  });
  dialog.closeDialog({ payout: payout.value });
}

async function create() {
  const params = PayoutToCreateRequest(payout.value, props.productId);
  const newPayout = await createPayout(params);
  dialog.closeDialog({ payout: newPayout });
}

async function getCompBuilders() {
  fetchingCompBuilders.value = true;
  try {
    compBuilders.value = await compBuilderSearch(props.carrierId);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    fetchingCompBuilders.value = false;
  }
}

getCompBuilders();
</script>
