import { getHttpClient } from "@/http-client";
import { setLeadFromRequest } from "@/factories/Lead";
const baseUrl = "api/boss/leads";

function LeadTableItem(model = {}) {
  return {
    name: model?.name || null,
    email: model?.email || null,
    phone: model?.phone || null,
    state: model?.state || null,
    manager: model?.manager || null,
    followUp: model?.followUp || null,
    category: model?.category || null,
    id: model?.id || null
  };
}

function setLeadTableItemFromRequest(raw = {}) {
  const item = LeadTableItem();
  if (!raw) return item;
  item.id = raw.id;
  item.name = raw.name;
  item.email = raw.email;
  item.phone = raw.phone_work;
  item.state = raw.state;
  item.manager = raw.marketing_manager?.name;
  item.followUp = raw.follow_up_date;
  item.category = raw.category;
  return item;
}

let cancelToken = null;
export async function getLeads(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return {
    items: response.data.leads.map(setLeadTableItemFromRequest),
    meta: response.data.meta
  };
}
export async function createLead(lead) {
  const { data } = await getHttpClient().post(baseUrl, { lead });
  return data.lead.id;
}
export async function getLead(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setLeadFromRequest(data.lead);
}
export function destroyLead(id) {
  return getHttpClient().delete(`${baseUrl}/${id}`);
}
export function saveLead(id, lead) {
  return getHttpClient().put(`${baseUrl}/${id}`, { lead });
}
