import {
  setAffiliationFromRequest,
  setRequestFromAffiliation
} from "@/factories/AffiliationFactory";
import { getHttpClient } from "@/http-client";

const baseURL = "api/boss/affiliations";

export const createAffiliation = affiliations => {
  const affiliationsReq = affiliations.map(setRequestFromAffiliation);
  return getHttpClient().post(baseURL, { affiliations: affiliationsReq });
};
export const updateAffiliation = affiliation => {
  return getHttpClient().put(
    `${baseURL}/${affiliation.id}`,
    setRequestFromAffiliation(affiliation)
  );
};
export const deleteAffiliation = id => {
  return getHttpClient().delete(`${baseURL}/${id}`);
};
export const getAffiliations = async ({ q } = {}) => {
  const params = new URLSearchParams();
  if (q) params.append("q", q);

  const { data } = await getHttpClient().get(baseURL, { params });

  const affiliations = data.map(setAffiliationFromRequest);

  affiliations.sort((a, b) => a.name.localeCompare(b.name));
  return affiliations;
};
