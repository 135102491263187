<template>
  <v-row dense>
    <v-col cols="12">
      <textarea-field
        v-model="description"
        data-testid="input-description"
        :prepend-inner-icon="mdiText"
        label="Description"
        placeholder="Consider including height, weight, medical concerns, medications, specific products and/or the overall goal of insurance (i.e. permanent insurance with LTC rider)."
        :success="descriptionValidation.success"
        :error-messages="descriptionValidation.errorMessages"
      />
    </v-col>
    <v-col cols="12">
      <file-drag-and-drop
        v-model="files"
        data-testid="input-files"
        label="Upload Document (optional)"
        multiple
        :success="Boolean(files.length)"
        :error-messages="filesValidation.errorMessages"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import { computedValidation } from "@/util/helpers";
import { useQuoteRequestStore } from "@/stores/quote-request";
import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { mdiText } from "@mdi/js";

const quoteRequest = useQuoteRequestStore();

const { files, description } = storeToRefs(quoteRequest);

const v$ = useVuelidate(
  {
    files: {
      allSized: v => v.every(file => file.size > 0)
    },
    description: {
      required: v => !!v
    }
  },
  { files, description },
  { $autoDirty: true, $scope: "quote-request" }
);

const filesValidation = computedValidation(v$.value.files, [
  {
    key: "allSized",
    message:
      "Please confirm these files have data or try re-uploading all files"
  }
]);

const descriptionValidation = computedValidation(v$.value.description, [
  { key: "required", message: "Required" }
]);
</script>
