<template>
  <div>
    <div class="checkbox-width">
      <checkbox-field
        v-model="lockCommissions"
        icon
        hide-details
        data-testid="commission-lock-toggle"
        label="Lock Commissions"
        class="mt-1"
        :success="lockCommissions"
        :disabled="!canLockCommissions"
        @update:model-value="store.updateCommissionLock"
      />
    </div>
    <div data-testid="commission-lock-explanation">
      {{ commissionLockExplanation }}
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import { computed,  ref, watch } from "vue";
import { storeToRefs } from "pinia";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useSettingsViewStore } from "@/stores/settings-view";
const props = defineProps({
  isViewingSelf: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});
const user = useUserStore();

let store;
const canLockCommissions = ref(false);
if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
  const { assignment } = storeToRefs(store);

  const setAdvisorIsDownline = () => {
    let isDownline = false;
    if (user.isGroupTwoPlus) isDownline = true;
    else if (props.isViewingSelf) isDownline = false;
    else {
      isDownline =
        assignment.value?.id === user.loginable.id &&
        user.loginable.type === "Agency";
    }
    canLockCommissions.value = isDownline;
  };

  watch(assignment, () => setAdvisorIsDownline());

  setAdvisorIsDownline();
} else {
  const settings = useSettingsViewStore();
  canLockCommissions.value = user.isGroupTwoPlus || !props.isViewingSelf;
  store = useAgencySettingsStore(settings.currentAgencyId);
}

const { lockCommissions } = storeToRefs(store);

const commissionLockExplanation = computed(() => {
  let namePossessive = `${store.name}'s`;
  let baseText = `You can only modify ${namePossessive} commission lock if they assign their commissions to you. `;

  if (user.isGroupTwoPlus) {
    baseText = "Group 2+ can modify anyone's commission lock. ";
  } else if (props.isViewingSelf) {
    namePossessive = "your";
    baseText = "You cannot modify your own commission lock. ";
  } else if (props.module === AGENCY_SETTINGS) {
    baseText = "";
  } else if (canLockCommissions.value && store.assignment?.name) {
    baseText = `${store.assignment.name} can modify ${namePossessive} commission lock. `;
  }

  return `${baseText}Locking commissions will prevent changes to ${namePossessive} Commission Rates, Commission Splits, and Commission Assignment.`;
});
</script>
