import { getHttpClient } from "@/http-client";
const baseURL = "api/boss/questionnaires";
let cancelToken = null;

function QuestionnaireTableItem(model = {}) {
  return {
    name: model?.name,
    uid: model?.uid
  };
}

function setQuestionnaireTableItemFromRequest(req = {}) {
  const item = QuestionnaireTableItem();

  item.name = req?.name;
  item.uid = req?.uid;

  return item;
}

export async function getQuestionnaires() {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseURL, {
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return {
    items: response.data.questionnaires.map(
      setQuestionnaireTableItemFromRequest
    )
  };
}
