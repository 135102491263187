<template>
  <v-card class="pt-6 pa-3 bg-surface" flat variant="outlined" >
    <div class="px-4 justify-center flex-row">
      <v-img
        v-if="theme.current.value.dark"
        key="dark"
        :src="props.darkImage"
        height="100"
        style="max-width: 80%"
        contain
      />
      <v-img
        v-else
        key="light"
        :src="props.image"
        height="100"
        style="max-width: 80%"
        contain
      />
    </div>
    <v-card-title>
      {{ props.text }}
    </v-card-title>
    <v-card-text :data-testid="`${props.text}-subtitle`" class="pb-0">
      {{ props.subtitle }}
    </v-card-text>
    <v-card-text>
      <v-row v-if="props.solid" class="ma-0 flex-nowrap" align="center">
        <div class="mr-3" :data-testid="`${props.text}-body`">
          {{ props.modelValue }}
        </div>
        <app-button
          :data-testid="`${props.text}-delete`"
          :icon="mdiDelete"
          color="error"
          class="mb-1"
          variant="text"
          density="comfortable"
          v-on="props.listeners"
        />
      </v-row>
      <div v-else>{{ props.modelValue }}</div>
    </v-card-text>
    <v-card-actions v-if="!props.solid">
      <app-button
        :data-testid="`${props.text}-action`"
        class="text-none"
        color="primary"
        variant="text"
        density="comfortable"
        v-on="props.listeners"
      >
        {{ props.actionText }}
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { mdiDelete } from "@mdi/js";

import { useTheme } from "vuetify/lib/framework.mjs";

const props = defineProps({
  solid: Boolean,
  image: { type: String, default: "", required: false },
  darkImage: { type: String, default: "", required: false },
  text: { type: String, default: "", required: false },
  subtitle: { type: String, default: "", required: false },
  modelValue: { type: String, default: "", required: false },
  listeners: { type: Object, default: () => {}, required: false },
  actionText: { type: String, default: "", required: false }
});

const theme = useTheme();
</script>
