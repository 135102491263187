import { getHttpClient } from "@/http-client";
import {
  setPayPeriodFromRequest,
  setPayPeriodTableItemFromRequest
} from "@/factories/PayPeriod";

const baseUrl = "/api/boss/pay_periods";

export const updatePayPeriodEft = (payPeriodId, eftId) => {
  return getHttpClient().put(`${baseUrl}/${payPeriodId}`, {
    pay_period: {
      eft_id: eftId
    }
  });
};

export async function getPayPeriods(params, cancelToken) {
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  const { data } = res;

  if (!data?.pay_periods) return;

  return {
    items: data.pay_periods.map(setPayPeriodTableItemFromRequest),
    meta: data.meta,
    stats: data.meta.stats
  };
}

export async function getPayPeriodData(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setPayPeriodFromRequest(data.pay_period);
}

export async function payPayPeriod(id, memo = null) {
  let body = undefined;
  if (memo) body = { memo };
  return getHttpClient().post(`${baseUrl}/${id}/pay`, body);
}

export function getPayPeriodCsv(id) {
  return `${baseUrl}/${id}/download`;
}

export function getPayPeriodPdf(id) {
  return `${baseUrl}/${id}/download.pdf`;
}

export async function updatePayPeriod(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, { pay_period: body });
}
