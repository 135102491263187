<template>
  <v-menu
    :min-width="300"
    :width="300"
    :close-on-content-click="false"
    offset-y
    top
  >
    <template #activator="{ props: templateProps }">
      <text-field
        v-bind="templateProps"
        v-model="color"
        :data-testid="props.dataTestid"
        :label="props.label"
        readonly
        :hide-details="props.hideDetails"
        :hint="props.hint || ' '"
        persistent-hint
        :class="{
          'color-picker-button-container': props.dense,
          'color-picker-button-container-large': !props.dense
        }"
        :success="colorValidation.success"
        :error-messages="colorValidation.errorMessages"
      >
        <template #prepend-inner>
          <app-button iconv-bind="templateProps" variant="text" icon class="color-picker-button">
            <div
              :style="{ backgroundColor: color }"
              class="color-picker-preview"
            ></div>
          </app-button>
        </template>
        <template #append-inner>
          <active-save-indicator
            :controller="savingBuffer.color.controller.value"
          />
        </template>

        <template #message="{ message }">
          <v-row class="ma-0">
            <template v-if="message.trim()">{{ message }} </template>
            <v-spacer />
            <a
              v-if="props.defaultColor && color !== props.defaultColor"
              data-testid="reset"
              @click="color = props.defaultColor"
            >
              Reset to default color?
            </a>
          </v-row>
        </template>
      </text-field>
    </template>
    <v-color-picker
      v-model="color"
      hide-mode-switch
      mode="hex"
      :modes="['hex']"
    />
  </v-menu>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { useActiveSave } from "@/composables/active-save.composable";
import useVuelidate from "@vuelidate/core";
import { validationComputeV2 } from "@/util/helpers";
const props = defineProps({
  hideDetails: Boolean,
  modelValue: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  hint: {
    type: String,
    required: false,
    default: ""
  },
  defaultColor: {
    type: String,
    required: false,
    default: null
  },
  dataTestid: {
    type: String,
    required: false,
    default: "color-input"
  },
  dense: {
    type: Boolean,
    default: true
  }
});
const emit = defineEmits(["update:model-value", "save"]);

const savingBuffer = { color: useActiveSave() };
const color = ref(props.modelValue.toUpperCase());

const v$ = useVuelidate(
  {
    color: {
      valid: v => /^#([0-9a-f]{6})$/i.test(v)
    }
  },
  {
    color
  },
  {
    $autoDirty: true,
    $scope: true
  }
);

const colorValidation = computed(() => {
  const model = v$.value.color;
  return validationComputeV2(model, [
    { key: "valid", message: "Must be 6 characters long" }
  ]);
});

watch(
  color,
  () => {
    emit("update:model-value", color.value);
    if (!colorValidation.value.success) return;
    emit("save", savingBuffer.color);
  },
  { deep: true }
);
</script>

<style lang="scss">
.color-picker-preview {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.color-picker-button-container {
  .color-picker-button {
    height: 25px;
    width: 25px;
  }
  .v-input__control {
    .v-input__slot {
      .v-input__prepend-inner {
        margin-top: 0;
      }
    }
  }
}

.color-picker-button-container-large {
  .color-picker-button {
    height: 30px;
    width: 30px;
  }
  .v-input__control {
    .v-input__slot {
      .v-input__prepend-inner {
        margin-top: 12px;
      }
    }
  }
}
</style>
