<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    :mobile="null"
    mobile-breakpoint="sm"
    must-sort
    data-testid="advisor-profile-advisors-table"
    hide-default-footer
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #[`item.name`]="{ item }">
      <v-tooltip location="top">
        <template #activator="{ props: tooltipProps }">
          <router-link
            v-bind="tooltipProps"
            class="text-none"
            color="primary"
            variant="flat"
            size="small"
            :to="{
              name: 'AgentView',
              params: {
                id: item.additional.id
              }
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <span> View {{ item.name }}'s Profile </span>
      </v-tooltip>
    </template>

    <template #[`item.lastSubmit`]="{ item }">
      <timestamp-formatter :model-value="item.lastSubmit" />
    </template>
    <template #[`item.ytdInforce`]="{ item }">
      <currency-formatter :model-value="item.ytdInforce" :decimal-length="0" />
    </template>
    <template #[`item.pending`]="{ item }">
      <currency-formatter :model-value="item.pending" :decimal-length="0" />
    </template>

    <template #bottom>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <app-button
          data-testid="advisor-profile-advisors-table-view-all"
          color="primary"
          class="text-none"
          variant="text"
          :prepend-icon="mdiOpenInApp"
          :text="`View ${name}'s Agents`"
          @click="viewAll"
        />
      </v-row>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";

import { getAdvisors } from "@/api/advisors.service";

import { useSnackbarStore } from "@/stores/snackbar";

import { useTable } from "@/composables/table.composable";
import { storeToRefs } from "pinia";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { useTableStore } from "@/stores/table";
import { useRouter } from "vue-router";
import { mdiOpenInApp } from "@mdi/js";

const tableStore = useTableStore();
const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);
const router = useRouter();
const snackbar = useSnackbarStore();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Advisor",
      value: "name",
      map: "name",
      sortFilterMap: "name"
    }),
    new TableHeader({
      text: "YTD Inforce",
      value: "ytdInforce",
      map: "ytd",
      sortFilterMap: "ytd_inforce",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Pending",
      value: "pending",
      map: "pending",
      sortFilterMap: "pending",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Last Submit",
      value: "lastSubmit",
      map: "lastSubmit",
      sortFilterMap: "last_submit",
      ...TableHeader.IS_SORTABLE
    })
  ],
  getData: getAdvisors,
  options: TableOptions({
    sortBy: [{ key: "ytdInforce", order: "desc" }]
  })
});

async function getData() {
  const additionalFilter = {
    shared_with_id: id.value,
    shared_with_type: type.value,
    view: "all",
    model: "agents"
  };
  try {
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function viewAll() {
  tableStore.advisorsTable.filter = {
    additionalAdvisor: tableFilterData.value
  };

  router.push({ name: "Tables", query: { page: "advisors" } });
}
</script>
