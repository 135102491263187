<template>
  <v-card
    color="accent"
    :height="height"
    :style="{ overflow: inGroup ? 'auto !important' : 'hidden' }"
  >
    <template v-if="!inGroup">
      <v-card-title class="px-4 text-white"> BackNine News Feed </v-card-title>
      <v-card-subtitle class="text-white">
        View our latest articles!
      </v-card-subtitle>
    </template>
    <v-card-text class="pa-0">
      <v-card
        id="scroll-list"
        flat
        style="overflow: auto !important"
        :style="{ height: itemsHeight }"
        color="transparent"
      >
        <div v-if="fetching" key="loader">
          <v-card v-for="(n, i) in 4" :key="i" class="ma-3" elevation="6">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
          <v-card class="ma-3" elevation="6">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
          <v-card class="ma-3" elevation="6">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
          <v-card class="ma-3" elevation="6">
            <v-skeleton-loader type="list-item-three-line" />
          </v-card>
        </div>

        <div v-else-if="articles.length" id="list" key="items">
          <feed-item
            v-for="(article, index) in articles"
            :key="index"
            data-testid="article-item"
            :class="{ 'mb-4': index === articles.length - 1 }"
            :to="article.routerLink"
          >
            <template #type> Article </template>
            <template #subject> {{ article.name }} </template>
            <template #subtitle>
              <v-row class="ma-0 mb-3 flex-nowrap" align="center">
                <v-avatar size="32" class="mr-3">
                  <v-img :src="article.avatar" />
                </v-avatar>
                <div :class="{ 'text-grey': theme.current.value.dark }">
                  {{ article.author }}
                  <br />
                  <span class="text-grey">
                    {{ article.timeToRead }} min read
                  </span>
                </div>
              </v-row>
            </template>
            <template #content> {{ article.preview }} </template>
            <template #c2a> Read this Article </template>
          </feed-item>
          <feed-item data-testid="view-all" :to="{ name: 'HelpCenter' }">
            <template #subject> View Help Center </template>
            <template #subtitle> Look for more articles </template>
            <template #c2a> Check out the Help Center </template>
          </feed-item>
        </div>
        <v-card
          v-else
          key="no-items"
          class="ma-3"
          elevation="6"
          data-testid="no-items"
        >
          <v-card-title
            class="mb-3"
            style="font-weight: 500; letter-spacing: 0.3px; font-size: 1.25rem"
          >
            No Recent Updates
          </v-card-title>
        </v-card>
      </v-card>
    </v-card-text>
    <v-row
      v-if="canScroll"
      key="more-to-see"
      data-testid="scroll-indicator"
      style="position: absolute; bottom: 8px; right: 12px"
      class="text-center w-100 text-white accent pb-1"
      align="center"
      justify="center"
    >
      <div class="ml-1 pb-1" style="font-size: 12px; position: relative">
        <div style="display: grid; position: absolute; left: -15px">
          <v-icon size="10" style="color: inherit" :icon="mdiMouse" />
          <v-icon size="10" style="color: inherit" :icon="mdiChevronDown" />
        </div>
        Scroll for more
      </div>
    </v-row>
  </v-card>
</template>

<script setup>
import FeedItem from "@/components/home/FeedItem.vue";
import { getArticles } from "@/api/help-desk.service";
import { mdiMouse, mdiChevronDown } from "@mdi/js";
import { computed, ref, nextTick } from "vue";
import { useTheme } from "vuetify/lib/framework.mjs";

import { cropOrEllipsis } from "@/util/helpers";

import MarkdownIt from "markdown-it";
const md = new MarkdownIt();

const props = defineProps({
  height: { type: [String, Number], required: true },
  inGroup: Boolean
});

const articles = ref([]);
const fetching = ref(false);
const canScroll = ref(false);
const theme = useTheme();

const itemsHeight = computed(() => {
  if (canScroll.value) {
    return props.inGroup ? "calc(30rem - 76px)" : "calc(30rem - 122px)";
  }
  return props.inGroup ? "calc(30rem - 48px)" : "calc(30rem - 86px)";
});

async function fetchArticles() {
  try {
    fetching.value = true;
    const items = await getArticles({
      sort: { updated_at: "desc" },
      published: true
    });

    articles.value = items.map(i => ({
      ...i,
      routerLink: {
        name: "HelpCenterArticleViewer",
        params: { id: i.id }
      },
      preview: generatePreview(i)
    }));

    nextTick(() => {
      nextTick(() => {
        const visibleList = document.getElementById("scroll-list").offsetHeight;
        const listFullHeight =
          document.getElementById("list")?.offsetHeight || 0;

        canScroll.value = visibleList <= listFullHeight;
      });
    });
  } catch (e) {
    // still do nothing
  } finally {
    fetching.value = false;
  }
}

function generatePreview(i) {
  const div = document.createElement("div");
  div.innerHTML = md.render(i.content);
  return cropOrEllipsis(div.innerText);
}

fetchArticles();
</script>
