<template>
  <div>
    <h2 class="text-h6 mb-2">{{ props.title }}</h2>
    <p v-if="props.subtitle" class="text-body-2">
      {{ props.subtitle }}
    </p>
    <checkbox-field
      v-model="enabledModel"
      data-testid="enable-income"
      label="Would you like to illustrate distributions?"
      :disabled="props.disabled"
    />
    <v-fade-transition mode="out-in">
      <v-row v-if="showAges" align="start">
        <v-col cols="12" md="4">
          <integer-input
            v-model="startModel"
            label="Income Start Age"
            :prepend-inner-icon="mdiCalendarStart"
            data-testid="income-start-age"
            :disabled="props.disabled"
            :min="props.min"
            :max="props.max"
            :success="
              props.startValidation.success &&
              ![null, undefined].includes(startModel)
            "
            :error-messages="props.startValidation.errorMessages"
            @update:model-value="saveStart('start')"
          >
            <template #append-inner>
              <active-save-indicator
                :controller="savingBuffer.start.controller.value"
              />
            </template>
          </integer-input>
        </v-col>
        <v-fade-transition mode="out-in">
          <v-col v-if="endModel !== -1" key="income-until-age" cols="12" md="4">
            <integer-input
              v-model="endModel"
              label="Income End Age"
              :prepend-inner-icon="mdiCalendarEnd"
              data-testid="income-end-age"
              :disabled="props.disabled"
              :min="props.min"
              :max="props.max"
              :success="
                props.endValidation.success &&
                ![null, undefined].includes(endModel)
              "
              :error-messages="props.endValidation.errorMessages"
              @update:model-value="saveEnd('end')"
            >
              <template #append-inner>
                <active-save-indicator
                  :controller="savingBuffer.end.controller.value"
                />
              </template>
            </integer-input>
          </v-col>
        </v-fade-transition>
        <v-col key="income-for-life" cols="12" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="endModel"
              :success="
                props.endValidation.success &&
                ![null, undefined].includes(endModel)
              "
              data-testid="income-for-life"
              label="Income for Life"
              class="mt-1"
              hide-details
              :disabled="props.disabled"
              :true-value="-1"
              :false-value="null"
              @update:model-value="saveEnd('forLife')"
            >
              <template #label>
                Income for Life
                <active-save-indicator
                  :controller="savingBuffer.forLife.controller.value"
                />
              </template>
            </checkbox-field>
          </div>
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>
<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import IntegerInput from "@/components/shared/IntegerInput.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed,  ref, toRefs, watch } from "vue";
import { mdiCalendarStart, mdiCalendarEnd } from "@mdi/js";
const props = defineProps({
  disabled: Boolean,
  title: { type: String, required: true },
  subtitle: { type: String, required: false, default: null },
  enabled: Boolean,
  min: { type: Number, required: true },
  max: { type: Number, required: true },
  start: { type: Number, required: false, default: null },
  startValidation: { type: Object, required: true },
  end: { type: Number, required: false, default: null },
  endValidation: { type: Object, required: true },
  startAgeSave: { type: Function, required: false, default: null },
  endAgeSave: { type: Function, required: false, default: null }
});

const emit = defineEmits(["update:start", "update:end", "update:enabled"]);

const startModel = ref(props.start);
const endModel = ref(props.end);
const enabledModel = ref(props.enabled);

const savingBuffer = {
  start: useActiveSave(),
  end: useActiveSave(),
  forLife: useActiveSave()
};

const showAges = computed(() => enabledModel.value);

const { start: startProp, end: endProp, enabled: enabledProp } = toRefs(props);

watch(startProp, v => {
  if (v !== startModel.value) startModel.value = v;
});

watch(startModel, v => {
  if (v !== props.start) emit("update:start", v);
});

watch(endProp, v => {
  if (v !== endModel.value) endModel.value = v;
});

watch(endModel, v => {
  if (v !== props.start) emit("update:end", v);
});

watch(enabledProp, v => {
  if (v !== enabledModel.value) enabledModel.value = v;
});

watch(enabledModel, v => {
  if (v !== props.enabled) emit("update:enabled", v);
});

function saveStart(key) {
  if (props.startAgeSave && props.startValidation.success) {
    savingBuffer[key].debounceUpdate(() => {
      if (props.startValidation.success) return props.startAgeSave();
    });
  }
}

function saveEnd(key) {
  if (props.endAgeSave && props.endValidation.success) {
    savingBuffer[key].debounceUpdate(() => {
      if (props.endValidation.success) return props.endAgeSave();
    });
  }
}
</script>
