<template>
  <v-card>
    <v-card-title> Marketing Manager Production Credit </v-card-title>
    <v-divider />
    <v-data-table-server
      v-model:sort-by="table.options.value.sortBy"
      v-model:items-per-page="table.options.value.itemsPerPage"
      v-model:page="table.options.value.page"
      :headers="table.tableHeaders.value"
      :items="table.mappedItems.value"
      :items-length="table.itemsLength.value"
      :loading="table.loading.value"
      :items-per-page-options="table.itemsPerPageOptions"
      data-testid="marketing-manager-production-credit-report"
      no-data-text="Unable to load results"
      :mobile="null"
      mobile-breakpoint="sm"
    >
      <template #top>
        <table-filter
          always-show-all-filters
          color="transparent"
          :model-value="table.filter.value"
          :headers="table.filterHeaders.value"
          :loading="table.loading.value"
          @update="updateFilter"
        />
      </template>

      <template #bottom>
        <table-footer
          v-model:page="table.options.value.page"
          v-model:items-per-page="table.options.value.itemsPerPage"
          :items-per-page-options="table.itemsPerPageOptions"
          :items-length="table.itemsLength.value"
        />
      </template>

      <template #[`item.production`]="{ item }">
        <currency-formatter
          :model-value="item.production"
          :decimal-length="0"
        />
      </template>
      <template #[`item.caseCount`]="{ item }">
        <number-formatter :model-value="item.caseCount" />
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";

import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";

import { getMarketingManagersProductionCreditReport } from "@/api/reports.service";
import { useTable } from "@/composables/table.composable";
import { DATE_FILTERS } from "@/constants/date-filter.constants";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";

const PLAN_TYPES = [
  { title: "Accidental Death", value: "Accidental Death" },
  { title: "Critical Illness", value: "Critical Illness" },
  { title: "DIA", value: "DIA" },
  { title: "Disability", value: "Disability" },
  { title: "FIA", value: "FIA" },
  { title: "Final Expense", value: "Final Expense" },
  { title: "Fixed", value: "Fixed" },
  { title: "Guaranteed Issue Whole Life", value: "GIWL" },
  { title: "IUL", value: "IUL" },
  { title: "LTC", value: "LTC" },
  { title: "MYGA", value: "MYGA" },
  { title: "SPIA", value: "SPIA" },
  { title: "Term", value: "TRM" },
  { title: "Term - Non Medical", value: "TRM - Non Medical" },
  { title: "Term - ROP", value: "TRM - ROP" },
  { title: "UL", value: "UL" },
  { title: "VUL", value: "VUL" },
  { title: "Whole Life", value: "Whole Life" },
  {
    title: "Whole Life - Non Participating",
    value: "Whole Life - Non Participating"
  },
  { title: "Other", value: "Other" }
];

const table = useTable({
  headers: [
    new TableHeader({
      text: "Marketing Manager",
      value: "name",
      map: "name",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Cases",
      value: "caseCount",
      map: "caseCount",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Production Credit",
      value: "production",
      map: "production",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "Timeframe",
      value: "timeframe",
      sortFilterMap: "timeframe",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_DATE_FILTER_TYPE
    }),
    new TableHeader({
      text: "Product Types",
      value: "product_types",
      sortFilterMap: "product_types",
      selectableOptions: PLAN_TYPES,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    })
  ],
  options: TableOptions({ sortBy: [{ key: "production", order: "desc" }] }),
  filter: {
    timeframe: {
      type: DATE_FILTERS.LAST_12_MONTHS.value,
      value: DATE_FILTERS.LAST_12_MONTHS.fn()
    }
  },
  getData: getMarketingManagersProductionCreditReport,
  localSort: true,
  shouldIncludeCancelToken: true
});

const snackbar = useSnackbarStore();
async function getData() {
  table.loading.value = true;
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function updateFilter(value) {
  table.filter.value = value;
  getData();
}

getData();
</script>
