import { getHttpClient } from "@/http-client";

const baseURL = "api/boss/reference";

// returns an array of strings
export async function getProductSolves() {
  const { data } = await getHttpClient().get(`${baseURL}/solves`);
  data.sort((a, b) => a.localeCompare(b));
  return data;
}
