<template>
  <v-card flat tile height="100%" width="100%" color="section" class="pa-0">
    <v-card-title class="pt-2 pb-2">
      <v-row dense align="center">
        <v-col cols="12">
          <v-row class="ma-0">
            <mapped-form-view-aql-copy-button
              v-if="isCopiedForm && !isReadonly"
              :form-id="formId"
            />
            <autocomplete-field
              label="Current Field"
              solo
              flat
              dense
              hide-details
              data-testid="current-field-select"
              :prepend-inner-icon="mdiHelpNetwork"
              :model-value="currentFieldId"
              :items="items"
              @update:model-value="formStore.changeActiveField"
            />
            <mapped-form-view-aql-actions
              v-if="formStatus === 'Incomplete' && !isCopiedForm && !isReadonly"
              :form-id="formId"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- only change rectangle if on pdf -->
    <v-card-text v-if="activeField.id">
      <mapped-form-view-aql v-if="activeField" :form-id="formId" />
      <mapped-form-view-aql-relations
        v-if="isVerbatimQuestion"
        :key="currentFieldId + 'relations'"
        :form-id="formId"
        @view="formStore.changeActiveField"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import MappedFormViewAql from "@/components/form-mapping/MappedFormViewAql.vue";
import MappedFormViewAqlRelations from "@/components/form-mapping/MappedFormViewAqlRelations.vue";
import MappedFormViewAqlActions from "@/components/form-mapping/MappedFormViewAqlActions.vue";
import MappedFormViewAqlCopyButton from "@/components/form-mapping/MappedFormViewAqlCopyButton.vue";

import { mdiHelpNetwork } from "@mdi/js";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import {
  VERBATIM_QUESTION,
  applicationQuestionDisplayText
} from "@/factories/FormMapping";
import { computed } from "vue";
import { useFormMappingView } from "@/stores/form-mapping-view";

const props = defineProps({
  formId: { type: [String, Number], required: true }
});

const { copyingFromFormId, isReadonly } = storeToRefs(useFormMappingView());

const isCopiedForm = computed(() => copyingFromFormId.value === props.formId);

const formStore = useMappedFormStore(props.formId);
const {
  positionallySortedFields,
  status: formStatus,
  activeField,
  currentFieldId
} = storeToRefs(formStore);

const items = computed(() => {
  return positionallySortedFields.value.map(v => ({
    title: applicationQuestionDisplayText(v),
    value: v.id
  }));
});

const isVerbatimQuestion = computed(
  () => activeField.value?.applicationQuestion?.name === VERBATIM_QUESTION
);
</script>
