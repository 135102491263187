<template>
  <div class="card-item">
    <template v-if="props.title">
      <span class="text-grey lighten-2"> {{ props.title }} </span>
      <br />
    </template>
    <a v-bind="aTagProps" style="font-weight: normal">
      {{ props.text }}
    </a>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  title: { type: String, required: false, default: null },
  href: { type: String, required: true },
  text: { type: String, required: true },
  dataOutboundType: { type: String, required: false, default: null },
  dataOutboundNumber: { type: String, required: false, default: null },
  dataOutboundId: { type: [String, Number], required: false, default: null },
  linkProps: { type: Object, required: false, default: () => {} }
});

const aTagProps = computed(() => {
  const boundProps = { ...props.linkProps };
  if (props.href) {
    boundProps.href = props.href;
  }
  if (props.dataOutboundType) {
    boundProps["data-outbound-type"] = props.dataOutboundType;
  }
  if (props.dataOutboundId) {
    boundProps["data-outbound-id"] = props.dataOutboundId;
  }
  if (props.dataOutboundNumber) {
    boundProps["data-outbound-number"] = props.dataOutboundNumber;
  }
  return boundProps;
});
</script>
