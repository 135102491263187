import { getHttpClient } from "@/http-client";
const baseUrl = "api/boss/efts";
import { serializeObject } from "@/util/helpers";
import { setEftFromRaw, setRawFromEft } from "@/factories/EftFactory";

export async function createNewEft(eft) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject({ eft: setRawFromEft(eft) })
  );

  return setEftFromRaw(data.eft);
}

export function setActiveEft(eft) {
  return getHttpClient().put(`${baseUrl}/${eft.id}`, {
    eft: { current: 1 }
  });
}
