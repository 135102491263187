<template>
  <v-card class="confirmation-dialog">
    <v-card-title data-testid="title"> {{ title }} </v-card-title>
    <v-card-subtitle
      class="pt-3"
      style="white-space: pre-line"
      data-testid="subtitle"
    >
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text v-if="html && prewrap" data-testid="prewrap-html">
      <pre>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="sanitize(html)" />
      </pre>
    </v-card-text>
    <v-card-text v-else-if="html" data-testid="html" :class="htmlWrapperClass">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="sanitize(html)"></div>
    </v-card-text>
    <v-card-actions class="px-5">
      <checkbox-field
        v-if="checkboxText"
        v-model="checkboxChecked"
        data-testid="checkbox"
        :label="checkboxText"
        dense
      />
      <v-spacer />
      <app-button
        v-if="!hideCancel"
        variant="outlined"
        class="text-none"
        data-testid="cancel-button"
        :disabled="loading"
        @click="cancelAction"
      >
        {{ cancelText ? cancelText : "Cancel" }}
      </app-button>
      <app-button
        v-if="warningText"
        class="text-none"
        color="warning"
        data-testid="warning-button"
        :disabled="loading"
        @click="warningAction"
      >
        {{ warningText }}
      </app-button>

      <confirmation-dialog-confirm-button
        v-if="secondaryText"
        :loading="loading"
        :confirm-href="secondaryHref || ''"
        data-testid="secondary-button"
        @click="confirmAction(true)"
      >
        {{ secondaryText }}
      </confirmation-dialog-confirm-button>
      <confirmation-dialog-confirm-button
        :loading="loading"
        :confirm-href="confirmHref || ''"
        data-testid="confirm-button"
        @click="confirmAction(false)"
      >
        {{ confirmText || "Confirm" }}
      </confirmation-dialog-confirm-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmationDialogConfirmButton from "@/dialogs/ConfirmationDialogConfirmButton.vue";
import { sanitize } from "@/html-sanitizer";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
export default {
  components: {
    ConfirmationDialogConfirmButton
  },
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },

    confirmText: { type: String, default: null },
    confirmHref: { type: String, default: null, required: false },
    func: { type: Function, default: () => {} },

    secondaryText: { type: String, default: null },
    secondaryHref: { type: String, default: null },
    secondaryFunc: { type: Function, default: () => {} },

    checkboxText: { type: String, default: null },
    cancelText: { type: String, default: null },
    hideCancel: Boolean,
    html: { type: String, default: null },
    prewrap: Boolean,
    warningText: { type: String, default: null },
    htmlWrapperClass: { type: String, default: null }
  },
  data() {
    return {
      loading: false,
      checkboxChecked: false,
      sanitize
    };
  },

  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    warningAction() {
      this.closeDialog({ warning: true });
    },
    async confirmAction(secondary = false) {
      const body = { confirm: true, secondary };
      if (this.checkboxText) {
        body.checkboxChecked = this.checkboxChecked;
      }

      let func = this.func;
      if (secondary) func = this.secondaryFunc;

      if (!func) return this.closeDialog(body);

      this.loading = true;
      try {
        await func(body);
        this.closeDialog(body);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    cancelAction() {
      this.closeDialog({
        checkboxChecked: this.checkboxChecked,
        confirm: false
      });
    }
  }
};
</script>
