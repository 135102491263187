<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="form-mapping-forms-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row class="ma-0" align="center" dense>
        <h1 class="text-h5">Forms</h1>
        <v-spacer />
        <div>
          <v-tooltip location="top">
            <template #activator="{ props: templateProps }">
              <app-button
                :icon="mdiRefresh"
                variant="text"
                density="comfortable"
                class="table-btn"
                data-testid="form-mapping-forms-refresh"
                v-bind="templateProps"
                @click="getData"
              />
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template #activator="{ props: templateProps }">
              <app-button
                :icon="mdiPlusCircle"
                variant="text"
                density="comfortable"
                class="table-btn"
                data-testid="form-mapping-forms-create"
                v-bind="templateProps"
                @click="openCreateFormDialog"
              />
            </template>
            <span>Create Form</span>
          </v-tooltip>
        </div>
      </v-row>
      <table-filter
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.form`]="{ item }">
      <router-link
        :to="{
          name: 'FormMappingEditor',
          params: { id: item.additional.id }
        }"
      >
        {{ item.form }}
      </router-link>
    </template>
    <template #[`item.category`]="{ item }">
      <friendly-form-category :model-value="item.category" />
    </template>
    <template #[`item.updatedAt`]="{ item }">
      <timestamp-formatter :model-value="item.updatedAt" />
    </template>
    <template #[`item.followUpAt`]="{ item }">
      <timestamp-formatter :model-value="item.followUpAt" />
    </template>
    <template #[`item.actions`]="{ item }">
      <app-button
        :icon="mdiDownload"
        color="primary"
        variant="text"
        density="comfortable"
        data-testid="download"
        :loading="item.additional.downloading"
        v-bind="downloadDocument(item.additional)"
      />
      <app-button
        v-if="item.additional.status === 'Incomplete'"
        :icon="mdiDelete"
        variant="text"
        density="comfortable"
        color="error"
        data-testid="delete"
        @click="deleteRow(item)"
      />
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import FriendlyFormCategory from "@/components/shared/FriendlyFormCategory.vue";

import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";


import { STATES } from "@/data/states";
import { formFilterOptions } from "@/data/filter-options";

import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";

import TableHeader from "@/classes/data-table/TableHeader";

import { deleteForm, getForms, getFormPdfUrl } from "@/api/forms.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import {
  mdiRefresh,
  mdiPlusCircle,
  mdiDownload,
  mdiDelete,
  mdiFileDocument,
  mdiDomain,
  mdiCity,
  mdiListStatus,
  mdiWrench
} from "@mdi/js";

import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

const dialog = useDialogStore();
const snackbar = useSnackbarStore();
const router = useRouter();
const { mdAndDown } = useDisplay();

const table = useTable({
  headers: [
    new TableHeader({
      text: "Form",
      value: "form",
      map: "name",
      sortFilterMap: "forms.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      icon: mdiFileDocument
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      displayMap: "text",
      sortKey: "forms.category",
      sortFilterMap: [{ key: "forms.category", value: "value" }],
      ...TableHeader.IS_FORM_CATEGORY_SEARCH,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrierName",
      sortFilterMap: "carriers.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      icon: mdiDomain
    }),
    new TableHeader({
      text: "State",
      value: "states",
      map: "stateCount",
      sortFilterMap: "forms.state",
      selectableOptions: STATES.map(val => ({
        title: val.value,
        value: val.value
      })),
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      icon: mdiCity
    }),
    new TableHeader({
      text: "Updated",
      value: "updatedAt",
      map: "updatedAt",
      sortFilterMap: "forms.updated_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Follow Up",
      value: "followUpAt",
      map: "followUpAt",
      sortFilterMap: "forms.follow_up_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: formFilterOptions,
      sortFilterMap: "status",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      icon: mdiListStatus
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: ""
    }),
    new TableHeader({
      text: "Contracting",
      value: "contracting",
      map: "contracting",
      sortFilterMap: "contracting",
      icon: mdiWrench,
      selectableOptions: [
        { title: "Yes", value: "true" },
        { title: "No", value: "false" }
      ],
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Form Set",
      sortFilterMap: [{ key: "form_set_id", value: "id" }],
      displayMap: "name",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FORM_SET_SEARCH,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "With States",
      sortFilterMap: "with_states",
      value: "with_states",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Advisor",
      sortFilterMap: [
        { key: "ownable_id", value: "id" },
        { key: "ownable_type", value: "type" }
      ],
      value: "advisor",
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL
    })
  ],
  getData: getForms
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

async function openCreateFormDialog() {
  const res = await dialog.showDialog({
    component: "CreateFormDialog",
    persistent: true
  });
  if (res?.id) {
    router.push({ name: "FormMappingEditor", params: { id: res.id } });
  }
}

async function deleteRow(row) {
  const res = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: `Delete ${row.form}?`,
    subtitle: "This cannot be undone",
    func: () => deleteForm(row.additional.id)
  });

  if (res?.confirm) getData();
}

function downloadDocument({ id }) {
  return downloadFileAsLink(getFormPdfUrl(id), mdAndDown.value);
}
</script>
