<template>
  <v-data-table-server
    key="table"
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    class="pa-0"
    no-data-text="No websites found, click add to get started."
    must-sort
    dense
    :mobile="null"
    mobile-breakpoint="sm"
    data-testid="quote-and-apply-websites-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row class="ma-0 pa-3" align="center" dense>
        <h1 class="text-h5">Widgets & Websites</h1>
        <v-spacer />
        <slot name="header" />
        <v-tooltip location="top">
          <template #activator="{ props: templateProps }">
            <app-button
              :icon="mdiRefresh"
              v-bind="templateProps"
              class="table-btn"
              variant="text"
              density="comfortable"
              data-testid="refresh"
              @click="getData"
            />
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-col cols="12">
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="table.updateFilter($event, getData)"
          />
        </v-col>
      </v-row>
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.domain`]="{ item }">
      <v-tooltip location="bottom">
        <template #activator="{ props: templateProps }">
          <app-button
            variant="flat"
            class="text-none"
            size="small"
            style="height: 32px"
            color="primary"
            v-bind="templateProps"
            :prepend-icon="mdiCogs"
            :to="{
              name: 'ApprovedDomains',
              params: { id: item.additional.id }
            }"
          >
            <div
              :class="{
                'truncate-400': mdAndUp,
                'truncate-200': smAndDown
              }"
            >
              {{ item.domain }}
            </div>
          </app-button>
        </template>
        <span>Customize Settings for {{ item.domain }}</span>
      </v-tooltip>
    </template>
    <template #[`item.startPage`]="{ item }">
      {{ formatStartPage(item.startPage) }}
    </template>
    <template #[`item.actions`]="{ item }">
      <v-tooltip location="bottom">
        <template #activator="{ props: templateProps }">
          <app-button
            :icon="mdiOpenInNew"
            v-bind="templateProps"
            color="primary"
            target="_blank"
            variant="text"
            density="comfortable"
            data-testid="go-to-website"
            :href="`//${item.domain}`"
          />
        </template>
        <span>Go to {{ item.domain }}</span>
      </v-tooltip>
      <v-tooltip location="bottom">
        <template #activator="{ props: templateProps }">
          <span v-bind="templateProps">
            <app-button
              variant="text"
              density="comfortable"
              :icon="item.additional.domainKey ? mdiCodeTags : mdiClock"
              :color="item.additional.domainKey ? 'green' : 'grey'"
              data-testid="quote-and-apply-snippet"
              :disabled="!item.additional.domainKey"
              @click="codeDialog(item.additional)"
            />
          </span>
        </template>
        <span>
          {{
            item.additional.domainKey ? "Open Quote & Apply Snippet" : "Pending"
          }}
        </span>
      </v-tooltip>
      <v-tooltip location="bottom">
        <template #activator="{ props: templateProps }">
          <app-button
            :icon="mdiDelete"
            color="error"
            variant="text"
            density="comfortable"
            v-bind="templateProps"
            data-testid="delete-website"
            @click="deleteWebsite(item.additional)"
          />
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
  </v-data-table-server>
</template>

<script setup>
import TableFooter from "@/components/shared/data-table/TableFooter.vue";
import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableHeader from "@/classes/data-table/TableHeader";

import CopyDialog from "@/dialogs/CopyDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import {
  archiveApprovedDomain,
  getApprovedDomains
} from "@/api/approved-domain.service";

import { useTable } from "@/composables/table.composable";
import { markRaw } from "vue";
import { parseErrorMessage } from "@/util/helpers";
import { startPageItems } from "@/data/filter-options";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

import {
  mdiRefresh,
  mdiCogs,
  mdiOpenInNew,
  mdiCodeTags,
  mdiClock,
  mdiDelete
} from "@mdi/js";
import { useDisplay } from "vuetify/lib/framework.mjs";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const { mdAndUp, smAndDown } = useDisplay();

const table = new useTable({
  getData: getApprovedDomains,
  shouldIncludeCancelToken: true,
  text: "Websites",
  headers: [
    new TableHeader({
      text: "Website",
      value: "domain",
      map: "domain",
      sortFilterMap: "domain",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Agent",
      value: "advisorName",
      map: "advisorName",
      sortFilterMap: [{ key: "agent_id", value: "id" }],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Commissions Assigned To",
      value: "appointmentAssignmentOwnableName",
      map: "appointmentAssignmentOwnableName",
      sortFilterMap: [
        { key: "appointment_assignment_ownable_id", value: "id" },
        { key: "appointment_assignment_ownable_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Start Page",
      value: "startPage",
      map: "startPage",
      sortFilterMap: "start_page",
      selectableOptions: startPageItems,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: ""
    })
  ]
});

if (user.isGroupTwoPlus) {
  table.headers.value.push(
    new TableHeader({
      text: "View",
      value: "view",
      map: "view",
      selectableOptions: [
        { title: "My Websites", value: "my" },
        { title: "All Websites", value: "all" }
      ],
      sortFilterMap: "view",
      filterOrder: 1,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_MANDATORY
    }),
    new TableHeader({
      text: "Pricing Plan",
      value: "pricingPlan",
      sortFilterMap: [{ key: "pricing_plan_id", value: "id" }],
      ...TableHeader.IS_PRICING_PLAN_SEARCH_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL
    }),
    new TableHeader({
      text: "Show Archived Sites",
      value: "archived",
      sortFilterMap: "archived",
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL
    })
  );
}
if (user.isGroupTwoPlus) {
  table.filter.value = { view: "my" };
}
if (user.isGroupThreePlus) {
  table.filter.value = { view: "all" };
}

async function getData() {
  try {
    const additionalFilter = {};
    if (!user.isGroupTwoPlus) additionalFilter.view = "my";
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      color: "error"
    });
  }
}

function codeDialog(item) {
  dialog.showDialog({
    component: markRaw(CopyDialog),
    text: item.htmlSnippet,
    title: "Quote & Apply Snippet",
    subtitle: "Just copy and paste this into your site!"
  });
}

function deleteWebsite(item) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Do you want to delete the website ${item.domain}?`,
    subtitle:
      "This cannot be undone and will cause the website to no longer work",
    func: async () => {
      await archiveApprovedDomain(item.id);
      snackbar.showSuccessSnackbar({
        message: `Successfully deleted ${item.domain}`,
        timeout: 5000
      });
      await getData();
    }
  });
}

function formatStartPage(value) {
  return startPageItems.find(item => item.value === value)?.title;
}
</script>
