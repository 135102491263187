<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12">
          <text-field
            v-model="search"
            label="Search Carriers"
            :prepend-inner-icon="mdiMagnify"
          />
        </v-col>
        <v-col
          v-for="(business, index) in shownBusinesses"
          :key="index"
          cols="12"
          xl="3"
          lg="4"
          md="4"
          sm="6"
          align="center"
        >
          <v-card
            min-height="22rem"
            flat
            :data-testid="`carrier-${business.text}-group`"
          >
            <v-img
              :src="business.carriers[0].avatar"
              contain
              height="150"
              width="250"
            />
            <v-card-text>
              <v-col
                v-for="carrier in business.carriers"
                :key="carrier.text"
                cols="12"
                class="pa-1"
              >
                <v-switch
                  v-model="carrier.value"
                  inset
                  color="primary"
                  class="mt-0 switch"
                  :data-testid="`carrier-${carrier.text}-toggle`"
                  :disabled="savingBuffer[carrier.id].saving"
                  @update:model-value="updateCarrierAvailability(carrier)"
                >
                  <template #label>
                    {{ carrier.text }}
                    <active-save-indicator
                      class="ml-2"
                      :controller="savingBuffer[carrier.id].controller.value"
                    />
                  </template>
                </v-switch>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useActiveSave } from "@/composables/active-save.composable";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
import { storeToRefs } from "pinia";
import { computed, nextTick, onMounted, ref } from "vue";
import { mdiMagnify } from "@mdi/js";

const businesses = ref([]);
const search = ref("");
const savingBuffer = {};

const isMounted = ref(false);
onMounted(() => nextTick(() => (isMounted.value = true)));

const approvedDomain = useApprovedDomainView();
const { carriers } = storeToRefs(approvedDomain);

const groups = {};

// parentId is not the carrier id, rather something else
carriers.value.forEach(carrier => {
  savingBuffer[carrier.id] = useActiveSave();

  if (carrier.parentId) {
    if (!groups[`p_${carrier.parentId}`]) {
      groups[`p_${carrier.parentId}`] = {
        avatar: null,
        carriers: [],
        text: null
      };
    }
    groups[`p_${carrier.parentId}`].carriers.push(carrier);
  } else {
    groups[`c_${carrier.id}`] = {
      avatar: carrier.avatar,
      carriers: [carrier],
      text: carrier.text
    };
  }
});

const cleanedBusinesses = [];

Object.keys(groups).forEach(key => {
  const carriers = groups[key].carriers.toSorted((a, b) =>
    a.text.localeCompare(b.text)
  );
  const [{ avatar, text }] = groups[key].carriers;
  cleanedBusinesses.push({ carriers, avatar, text });
});

cleanedBusinesses.sort((a, b) => a.text.localeCompare(b.text));

businesses.value = cleanedBusinesses;

const shownBusinesses = computed(() => {
  if (!search.value) return businesses.value;

  const loweredSearch = search.value.toLowerCase();
  return businesses.value.filter(business =>
    business.carriers.some(carrier =>
      carrier.text.toLowerCase().includes(loweredSearch)
    )
  );
});

function updateCarrierAvailability(carrier) {
  if (!isMounted.value) return;
  savingBuffer[carrier.id].update(() =>
    approvedDomain.updateApprovedDomainCarrier(carrier.id, carrier.value)
  );
}
</script>
