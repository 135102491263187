<template>
  <v-card flat tile>
    <v-card-text>
      <v-data-table-server
        v-model:sort-by="table.options.value.sortBy"
        v-model:items-per-page="table.options.value.itemsPerPage"
        v-model:page="table.options.value.page"
        data-testid="build-table"
        class="transparent-data-table"
        :mobile="null"
        mobile-breakpoint="sm"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :items-length="table.itemsLength.value"
        :loading="table.loading.value"
        :footer-props="table.footerProps.value"
        :items-per-page-options="table.itemsPerPageOptions"
        @update:options="getData"
      >
        <template #top>
          <v-row class="ma-0">
            <span class="text-h5"> Height & Weight </span>
            <v-spacer />
            <v-tooltip location="bottom">
              <template #activator="{ props: templateProps }">
                <app-button
                  :icon="mdiRefresh"
                  variant="text"
                  class="table-btn"
                  density="comfortable"
                  v-bind="templateProps"
                  data-testid="build-table-refresh"
                  :disabled="table.loading.value"
                  @click="getData"
                />
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <v-tooltip v-if="user.isGroupFour" location="bottom">
              <template #activator="{ props: templateProps }">
                <app-button
                  v-bind="templateProps"
                  variant="text"
                  class="table-btn"
                  density="comfortable"
                  :icon="mdiPlusCircle"
                  data-testid="build-table-create"
                  :disabled="table.loading.value"
                  @click="createBuild"
                />
              </template>
              <span>Create Build</span>
            </v-tooltip>
          </v-row>
          <table-filter
            :model-value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #bottom>
          <table-footer
            v-model:page="table.options.value.page"
            v-model:items-per-page="table.options.value.itemsPerPage"
            :items-per-page-options="table.itemsPerPageOptions"
            :items-length="table.itemsLength.value"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <app-button
            :icon="mdiPencil"
            variant="text"
            density="comfortable"
            color="accent"
            data-testid="edit"
            @click="editBuild(item.additional)"
          />
          <app-button
            :icon="mdiDelete"
            variant="text"
            density="comfortable"
            color="error"
            data-testid="delete"
            @click="destroyBuild(item.additional)"
          />
        </template>
      </v-data-table-server>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AddBuildDialog from "@/components/builds/AddBuildDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";

import { ratingOptions } from "@/data/filter-options";
import { parseErrorMessage } from "@/util/helpers";
import { deleteBuild, getBuilds } from "@/api/builds.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { mdiRefresh, mdiPlusCircle, mdiPencil, mdiDelete } from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { markRaw } from "vue";

const user = useUserStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const table = useTable({
  getData: getBuilds,
  shouldIncludeCancelToken: true,
  headers: [
    new TableHeader({
      text: "Build Chart",
      value: "build",
      map: "buildChart.name",
      sortFilterMap: "build_charts.name",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Inches",
      value: "inches",
      map: "inches",
      sortFilterMap: "builds.inches",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Rating",
      value: "rating",
      map: "rating",
      selectableOptions: ratingOptions,
      sortFilterMap: "builds.rating",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Gender",
      value: "gender",
      map: "buildChart.gender",
      selectableOptions: ["Male", "Female", "Unisex"],
      sortFilterMap: "build_charts.gender",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE
    }),
    new TableHeader({
      text: "Min Weight",
      value: "min",
      map: "minWeight",
      ...TableHeader.IS_STRING_FILTER_TYPE
    }),
    new TableHeader({
      text: "Max Weight",
      value: "max",
      map: "maxWeight",
      ...TableHeader.IS_STRING_FILTER_TYPE
    })
  ]
});
if (user.isGroupFour) {
  table.headers.value.push(
    new TableHeader({
      text: "Actions",
      value: "actions",
      map: "actions"
    })
  );
}

function destroyBuild(build) {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to permanently delete this Build?",
    subtitle: "This cannot be undone",
    func: async () => {
      await deleteBuild(build.id);
      getData();
    }
  });
}

async function createBuild() {
  const res = await dialog.showDialog({
    component: markRaw(AddBuildDialog),
    persistent: true,
    scrollable: true
  });
  if (res?.created) getData();
}

async function editBuild(modelValue) {
  const res = await dialog.showDialog({
    component: markRaw(AddBuildDialog),
    persistent: true,
    modelValue
  });
  if (res?.created) getData();
}

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}
</script>
