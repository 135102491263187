<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="{ title, component, href, class: itemClass } in items"
      :key="title"
      :readonly="Boolean(href)"
    >
      <v-expansion-panel-title v-if="href" hide-actions class="pa-0">
        <a :href="href" target="_blank" class="pa-3 pl-6">
          {{ title }}
          <v-icon
            size="small"
            :icon="mdiOpenInNew"
            color="primary"
            style="margin-bottom: 1px"
          />
        </a>
      </v-expansion-panel-title>
      <template v-else>
        <v-expansion-panel-title> {{ title }}</v-expansion-panel-title>
        <v-expansion-panel-text class="no-expansion-panel-content-padding">
          <component :is="component" :class="itemClass" />
        </v-expansion-panel-text>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import BuildsTable from "@/components/builds/BuildsTable.vue";
import BuildChartsTable from "@/components/builds/BuildChartsTable.vue";
import ImpairedRiskQuotesTable from "@/components/impaired-risk-quote/ImpairedRiskQuotesTable.vue";
import QuestionnairesTable from "@/components/underwriting/QuestionnairesTable.vue";
import SmokingTypesTable from "@/components/underwriting/SmokingTypesTable.vue";
import SmokerChartsTable from "@/components/underwriting/SmokerChartsTable.vue";

import { useUserStore } from "@/stores/user";
import { useHead } from "@unhead/vue";
import { mdiOpenInNew } from "@mdi/js";
import { markRaw } from "vue";

useHead({ title: "Underwriting" });

const user = useUserStore();

const items = [
  {
    title: "Carrier Underwriting Requirements",
    href: "https://prodinfo.ipipeline.com/search?GAID=2479"
  },
  {
    title: "Nicotine & Cannabis",
    component: markRaw(SmokingTypesTable)
  },
  {
    title: "Height & Weight",
    component: markRaw(BuildsTable)
  }
];

if (user.isGroupThreePlus) {
  items.push(
    {
      title: "Smoker Charts",
      component: markRaw(SmokerChartsTable),
      class: "pa-3"
    },
    {
      title: "Build Charts",
      component: markRaw(BuildChartsTable)
    }
  );
}

if (user.loginable.is_case_manager) {
  items.push(
    {
      title: "Impaired Risk Questionnaires",
      component: markRaw(QuestionnairesTable)
    },
    {
      title: "Impaired Risk Quotes",
      component: markRaw(ImpairedRiskQuotesTable),
      class: "pa-3"
    }
  );
}
items.sort((a, b) => a.title.localeCompare(b.title));
</script>
